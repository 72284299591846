import React from "react";
import Modal from "../modal/modal";

import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  TwitterShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  EmailIcon,
} from "react-share";
import "./shareModal.css";
// import HelmetMetaData from "./HelmetMetaData";

const ShareModal = ({ subject, message, url, open, onRequestClose }) => {
  const hashTag = "l3arn";

  return (
    <Modal
      isOpen={open}
      // onAfterOpen={afterOpenModal}
      onRequestClose={() => {
        onRequestClose && onRequestClose();
      }}
      // contentLabel="Create new event"
    >
      <div className="flex flex-col items-center justify-center">
        <span className="text-bold text-lg py-4">Share</span>
        <div className="flex flex-row justify-center items-center">
          <div className="Demo__some-network">
            <FacebookShareButton
              url={url ?? window.location.href}
              quote={message}
              hashtag={hashTag}
              className="Demo__some-network__share-button"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </div>
          {/* <div className="Demo__some-network">
                  <FacebookMessengerShareButton
                    url={window.location.href}
                    appId="1333697653636658"
                    className="Demo__some-network__share-button"
                  >
                    <FacebookMessengerIcon size={32} round />
                  </FacebookMessengerShareButton>
                </div> */}

          <div className="Demo__some-network">
            <TwitterShareButton
              url={url ?? window.location.href}
              title={message}
              hashtags={[hashTag]}
              className="Demo__some-network__share-button"
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>

            <div className="Demo__some-network__share-count">&nbsp;</div>
          </div>
          <div className="Demo__some-network">
            <WhatsappShareButton
              url={url ?? window.location.href}
              title={message}
              separator=":: "
              className="Demo__some-network__share-button"
            >
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>

            <div className="Demo__some-network__share-count">&nbsp;</div>
          </div>

          <div className="Demo__some-network">
            <LinkedinShareButton
              url={url ?? window.location.href}
              className="Demo__some-network__share-button"
            >
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
          </div>
          <div className="Demo__some-network">
            <EmailShareButton
              url={url ?? window.location.href}
              subject={subject}
              body={message}
              className="Demo__some-network__share-button"
            >
              <EmailIcon size={32} round />
            </EmailShareButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShareModal;
