import React, { useState } from "react";
import { auth } from "../../config/firebase";
import commonColor from "../../theme/commonColor";
import SwotknotLogo from "../../assets/images/learn-logo-colour.svg";
import owl from "../../assets/images/owl.jpg";
import { useNavigate, Link } from "react-router-dom";
import {
  sendPasswordResetEmail
} from 'firebase/auth';
const PasswordReset = () => {
  let navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
  const [error, setError] = useState(null);
  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(value);
    }
  };

  const sendResetEmail = async(event) => {
    console.log("Sending reset email to", email);
    event && event.preventDefault();
    await sendPasswordResetEmail(auth,email)
      .then(() => {
        console.log("sendPasswordResetEmail returned", email);
        setEmailHasBeenSent(true);
        console.log("Sent reset email to", email);
      })
      .catch(() => {
        setError("Error resetting password");
      });

    setTimeout(() => {
      setEmailHasBeenSent(false);
    }, 3000);
  };

  return (
    <>
      <div className="w-full h-screen overflow-y-scroll h-screen-ios min-h-screen-ios">
        <div className="xl:w-1/3 lg:w-1/2 w-full pt-8 lg:float-left h-1/2 lg:h-full items-center justify-center flex xl:bg-white lg:bg-transparent ">
          <div className="bg-white/75 xl:bg-white/100 rounded-lg">
            <div
              style={{
                display: `grid`,
                gridTemplateColumns: `repeat(24, 1fr)`,
                gridTemplateRows: `1fr`,
                gridColumnGap: `0px`,
                gridRowGap: `0px`,
              }}
            >
              <div
                style={{
                  gridArea: `1 / 1 / 2 / 25`,
                  backgroundColor: "transparent",
                }}
              >
                <div className=" py-3 flex justify-center items-center lg:h-1/6 h-1/10 bg-transparent">
                  <img
                    src={SwotknotLogo}
                    alt="IQ2Logo"
                    className="w-40 pt-2 bg-transparent cursor-pointer mt-4 sm:mt-0"
                    onClick={() => {
                      navigate("/sign-in");
                    }}
                  />
                </div>
                <div className="pt-8 text-center">
                  Enter your email to receive a reset password link.
                </div>
              </div>
            </div>

            <div className=" pb-4 ">
              {error && (
                <div className="py-4 bg-red-600 w-full text-white text-center mb-3">
                  {error}
                </div>
              )}
              {emailHasBeenSent && (
                <div className="py-4 bg-green-400 w-full text-white text-center mb-3">
                  An email has been sent to you!
                </div>
              )}
              <form onSubmit={sendResetEmail}>
                <div className="w-full ">
                  <div>
                    <input
                      type="email"
                      className="my-1 py-2 w-full white px-4 text-gray-800  rounded-md placeholder-gray-800 mb-4"
                      style={{
                        backgroundColor: commonColor.babyBlue,
                      }}
                      name="userEmail"
                      value={email}
                      placeholder="email"
                      id="userEmail"
                      onChange={onChangeHandler}
                    />
                  </div>
                  <div className="self-center bg-transparent justify-center flex">
                    <button
                      className="bg-[#A93823] hover:bg-[#801b17] text-white py-2 px-4 rounded-md w-[9rem]"
                      // style={{
                      //   backgroundColor: commonColor.deepBlue
                      // }}
                      type="submit"
                    >
                      send email
                    </button>
                  </div>
                </div>
              </form>
              <div className="pt-10 text-center">
                Suddenly remembered?{" "}
                <Link
                  to="/sign-in"
                  className="text-blue-500 hover:text-blue-600"
                >
                  Log in now!
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="h-1/2 lg:h-full xl:w-2/3 lg:w-1/2 w-full  lg:float-right hidden md:block">
          <div
            className="h-full bg-sea flex flex-col justify-around"
            style={{
              boxShadow: `0 10px 25px 0 rgba(0, 0, 0, 0.6)`,
              borderRadius: 25,
            }}
          >
            <div className="flex h-full justify-center items-end">
              <div className="w-full h-full">
                <img
                  className="object-cover w-full h-full"
                  src={owl}
                  alt="owl_img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PasswordReset;
