import React, { useState, useEffect } from "react";
import knotSm from "../../../assets/images/learn-small.png";
import { rejectRefund, approveRefund } from "../../../services/api";
import UserInfoPanel from "../../tutor/profile/userInfoPanel";
import LoaderModal from "../../common/modal/loaderModal";
import Modal from "../../common/modal/modal";
import SwotButton from "../../common/buttons/swotbutton";
import Swal from "sweetalert2";
import "./styles.css";

const AmbassadorsListItem = ({ ambassador }) => {
  const [loading, setLoading] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const showambassadorDetails = () => {
    setShowInfo(!showInfo);
    console.log("showing ambassador info..", showInfo);
  };

  return (
    <div className="w-full bg-transparent flex  flex-col justify-start focus:outline-none pb-4 ">
      <div
        className="w-full shadow-lg rounded-2xl bg-white h-full flex flex-col items-start justify-center md:flex-row md:items-center md:justify-start md:flex-wrap text-xs p-3"
        style={{
          // width: "60%",
          maxWidth: "60rem",
          minHeight: "80px",
        }}
      >
        <div className="flex items-center justify-start flex-wrap">
          <div className="text-center pr-4">
            <b>{ambassador.id}</b>
          </div>
          <div className="text-center pr-4">{ambassador.username}</div>
          <div className="text-center pr-4">{ambassador.email}</div>
        </div>
        <div className="flex items-center justify-start py-1">
          <div className="text-start pr-4">
            | <b>Affiliate: </b>
            {ambassador.affilate_code}
          </div>
        </div>
        <button
          className="focus:outline-none hover:shadow-outline bg-blue-600 py-2 px-4
             text-white mx-2 rounded-2xl"
          onClick={() => showambassadorDetails()}
        >
          Show affilates
        </button>
      </div>
      {showInfo && (
        <div
          style={{
            maxWidth: "60rem",
            marginTop: "10px",
            borderRadius: "15px",
            overflow: "scroll",
            background: "white",
          }}
        >
          <table>
            <thead>
              <tr>
                <th>id</th>
                <th>name</th>
                <th>email</th>
                <th>role</th>
                <th>phone</th>
                <th>status</th>
                <th>commission recevied</th>
                <th>course name</th>
                <th>enrolled date</th>
                <th>refer type</th>
              </tr>
            </thead>
            <tbody>
              {ambassador.ambassadors_ref.map((ref) => (
                <tr>
                  <td>{ref.id}</td>
                  <td>{ref.name}</td>
                  <td>{ref.email}</td>
                  <td>{ref.role}</td>
                  <td>{ref.phone}</td>
                  <td>{ref.status}</td>
                  <td>{ref.commission_recevied}</td>
                  <td>{ref.course_name}</td>
                  <td>{ref.enrolled_date}</td>
                  <td>{ref.refer_type}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <LoaderModal isOpen={loading} />
    </div>
  );
};

export default AmbassadorsListItem;
