import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import UserActions from "../../../redux/user";
import UserInfoPanel from "../../tutor/profile/userInfoPanel";
import TutorTypePanel from "../../tutor/profile/tutorTypePanel";
import WalletPanel from "./walletPanel";
import FooterView from "../../common/footerView";

const StudentProfile = () => {
  return (
    <div className="h-full w-full flex justify-start flex-wrap overflow-y-scroll">
      <div className="w-full xl:w-1/2 flex flex-col max-w-5xl">
        <div
          className="w-full px-2 max-h-64 min-h-33r"
          style={{
            // minHeight: "33rem",
            // maxHeight: "48rem",
          }}
        >
          <UserInfoPanel />
        </div>
        {/* <div className="w-full px-2 pt-8">
          <TutorTypePanel />
        </div> */}
      </div>
      <div className=" max-h-full w-full xl:w-1/2 flex flex-col max-w-5xl mt-12 md:mt-0">
        <div
          className="w-full max-h-full px-2 pt-8 xl:pt-0 xl:max-h-36 min-h-33r xl:h-44r"
          style={
            {
              // minHeight: "33rem",
              // maxHeight: "48rem",
            }
          }
        >
          <WalletPanel />
        </div>
      </div>

      <div className="w-full"> <FooterView /> </div>
    </div>
  );
};

export default StudentProfile;
