import React, { useEffect, useState } from "react";
import { fetchCurrentUser, signUpUser } from "../../services/userManagement";
import { login } from "../../services/authentication";
import { connect, ConnectedProps } from "react-redux";
import UserActions from "../../redux/user";
import commonColor from "../../theme/commonColor";
import { redirectBasedOnStatus } from "../../services/navigation/navigation";
import SwotknotLogo from "../../assets/images/learn-logo-colour.svg";
import singInImg from "../../assets/images/singIn_Img.jpg";
import winSrc from "../../assets/images/win.png";
import drawSrc from "../../assets/images/draw.png";
import loseSrc from "../../assets/images/lose.png";
import gameSrc from "../../assets/images/game.png";
import GoogleButton from "../../components/authentication/buttons/googleButton";
import FacebookButton from "../../components/authentication/buttons/facebookButton";
import HowToSignUpButton from "../../components/authentication/buttons/howToSignUpButton";
import { ReactComponent as Eye } from "../../assets/icons/eye.svg";
import { ReactComponent as Hidden } from "../../assets/icons/invisible.svg";
import { Link, useNavigate } from "react-router-dom";
import { historyState } from "../../services/navigation/history";

const SignIn = (props) => {
  let navigate = useNavigate();

  console.log("SignIn -  props", props);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [error, setError] = useState(null);
  const { saveUser } = props;
  const [winner, setWinner] = useState();

  useEffect(() => {
    historyState.navigate = navigate;
  }, [navigate]);

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  // useEffect(() => {
  //   // console.log("SignIn - signingOut..")
  //   //auth.signOut()
  // }, [])

  useEffect(() => {
    console.log("useEffect - login error:", error);
    //auth.signOut()
  }, [error]);

  // const signInWithEmailAndPasswordHandler =
  //   async (event, email, password) => {
  //     event.preventDefault();
  //     auth.signInWithEmailAndPassword(email, password)
  //       .then(async userCredential => {

  //         console.log("signInWithEmailAndPassword - authenticated user: ", userCredential.user)

  //         await saveUserInAppState(userCredential.user)
  //         console.log("signInWithEmailAndPassword - navigating to dashboard...")
  //         navigate("/dashboard")
  //       })
  //       .catch(error => {
  //         setError("You either have filled in wrong details or need to first register.");
  //         console.error("Error signing in with password and email", error);
  //       });
  //   };

  const saveUserInAppState = async (authUser) => {
    console.log("saveUserInAppState - auth user: ", authUser);

    const userSnapshot = await fetchCurrentUser();
    const fetchedUser = userSnapshot?.data();
    console.log("saveUserInAppState - user fetched: ", fetchedUser);
    if (!fetchedUser) {
      const newUser = await signUpUser(
        { ...authUser, photoUrl: authUser.photoURL },
        saveUser
      );
      redirectBasedOnStatus();
    } else {
      console.log("saveUserInAppState - saveUser");
      saveUser(fetchedUser);
      if (fetchedUser.status === "pending") {
        console.log("saveUserInAppState - navigate to onboarding");
        navigate("/on-boarding");
      } else {
        // console.log("saveUserInAppState - navigate");
        // redirectBasedOnStatus();
        // navigate("/dashboard")
        // historyState.navigate('/das', { replace: true });
      }
    }
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    }
  };

  let imageSrc = gameSrc;
  switch (winner) {
    case "D":
      imageSrc = drawSrc;
      break;
    case "O":
      imageSrc = loseSrc;
      break;
    case "X":
      imageSrc = winSrc;
      break;
    default:
      break;
  }

  return (
    <div className="w-full h-screen overflow-y-hidden h-screen-ios min-h-screen-ios flex justify-center items-center">
      <div className="xl:w-1/3 lg:w-1/2 w-full pt-8 lg:float-left lg:h-full items-center justify-center flex xl:bg-white lg:bg-transparent ">
        <div className="bg-white/75 xl:bg-white/100 rounded-lg w-9/12 md:w-8/12 sm:w-10/12">
          <div
            style={{
              display: `grid`,
              gridTemplateColumns: `repeat(24, 1fr)`,
              gridTemplateRows: `1fr`,
              gridColumnGap: `0px`,
              gridRowGap: `0px`,
            }}
          >
            <div
              className="mt-6 sm:mt-0"
              style={{
                gridArea: `1 / 1 / 2 / 25 `,
                backgroundColor: "transparent",
              }}
            >
              <div className=" py-3 flex justify-center items-center lg:h-1/6 h-1/10 bg-transparent">
                <img
                  src={SwotknotLogo}
                  alt="IQ2Logo"
                  className="w-40 pt-2 bg-transparent cursor-pointer "
                  onClick={() => {
                    navigate("/");
                  }}
                />
              </div>
            </div>
          </div>

          <div className=" py-4 ">
            {error && (
              <div className="py-4  w-full text-red-500 text-center mb-3">
                {error}
              </div>
            )}
            <form>
              <div className="w-full ">
                <div className="w-full flex flex-col">
                  <div>
                    <input
                      type="email"
                      className="my-1 py-2 w-full white px-4 text-gray-800  rounded-md placeholder-gray-800 "
                      style={{
                        backgroundColor: commonColor.babyBlue,
                      }}
                      name="userEmail"
                      value={email}
                      placeholder="email"
                      id="userEmail"
                      onChange={(event) => onChangeHandler(event)}
                    />
                  </div>

                  <div className="relative">
                    <input
                      type={passwordShown ? "text" : "password"}
                      className="my-1 py-2 w-full placeholder-gray-800 px-4 text-gray-800  rounded-md relative"
                      style={{
                        backgroundColor: commonColor.babyBlue,
                      }}
                      name="userPassword"
                      value={password}
                      placeholder="password"
                      id="userPassword"
                      onChange={(event) => onChangeHandler(event)}
                    />
                    <button
                      className="px-2 py-3 focus:outline-none absolute left-full top-0"
                      onClick={(event) => {
                        event.preventDefault();
                        togglePasswordVisibility();
                      }}
                    >
                      {passwordShown ? (
                        <Eye
                          className="rounded-full h-6 w-6 hover:bg-gray-200 bg-transparent"
                          style={{
                            fill: commonColor.deepBlue,
                          }}
                        />
                      ) : (
                        <Hidden
                          className="rounded-full h-6 w-6 hover:bg-gray-200 bg-transparent"
                          style={{
                            fill: commonColor.deepBlue,
                          }}
                        />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>

            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-10 justify-center items-center my-10 ">
              <button
                className="bg-[#A93823] hover:bg-[#801b17] text-white py-2 px-4 rounded-md w-[9rem]"
                onClick={(event) => {
                  event.preventDefault();
                  login(
                    "email",
                    { email: email, password: password },
                    saveUserInAppState,
                    setError
                  );
                }}
                py-4
                bg-red-600
                w-full
                text-white
                text-center
                mb-3
              >
                log in
              </button>
              <button className="bg-[#528EAE] hover:bg-[#3f6e88] text-white py-2 px-4 rounded-md w-[9rem]">
                <Link to="/on-boarding" className="text-white">
                  sign up
                </Link>
              </button>
            </div>
            <div className="text-center mb-10">
              <p>
                <Link
                  to="/password-reset"
                  className="text-black hover:text-blue-900 underline "
                >
                  Forgot password?
                </Link>
              </p>
            </div>
            <div className="w-full flex flex-col items-center mx-auto">
              <div className="w-full ">
                <div>
                  <div className="w-full pb-4">
                    <GoogleButton
                      onClick={() =>
                        login("google", null, saveUserInAppState, setError)
                      }
                    />
                  </div>
                  <div className="w-full pb-4 md:pb-12">
                    <FacebookButton
                      onClick={() =>
                        login("facebook", null, saveUserInAppState, setError)
                      }
                    />
                  </div>
                  <div className="w-full visible lg:hidden">
                    <HowToSignUpButton
                      onClick={() => {
                        navigate("/video-tutorials");
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-1/2 lg:h-full xl:w-2/3 lg:w-1/2 w-full  lg:float-right lg:block hidden">
        <div
          className="h-full  bg-sea flex flex-col justify-around relative"
          style={{
            boxShadow: `0 10px 25px 0 rgba(0, 0, 0, 0.6)`,
            borderRadius: 25,
            // backgroundSize: "cover",
            // backgroundImage: `url('${process.env.PUBLIC_URL}/images/sea.jpg')`
          }}
        >
          <img className="object-cover" src={singInImg} alt="singInImg" />
          <button className="bg-[#A93823] hover:bg-[#b85c4b] text-white py-2 px-4 rounded-md w-[12rem] absolute bottom-12 right-8 ">
            {/* todo add link to a new page */}
            <Link to="/video-tutorials">how to sign up?</Link>
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);

// const connector = connect(mapStateToProps, mapDispatchToProps);

// type PropsFromRedux = ConnectedProps<typeof connector>;

// export default connector(SignIn);
