import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import UserActions from "../../../../redux/user";
import MainPageWithMenu from "../../../../components/common/MainPageTemplate/mainPageWithMenu";
import CourseSetup from "./courseWizard/courseSetup";

export const CreateCourse = ({ user, location }) => {
  const [currentView, setCurrentView] = useState("courseList");
  const [courseId, setCourseId] = useState();
  const [viewMode, setViewMode] = useState("create");

  const renderCurrentView = () => {
    return <CourseSetup 
    mode={"create"}/>;
  };

  return (
    // <LoaderOverlay loading={loading}>
    <MainPageWithMenu user={user} menuSelected={"library"}>
      <div
        className=" h-full"
        style={{
          borderRadius: "1.5rem",
          width: "100%",
        }}
      >
        {renderCurrentView()}
      </div>
    </MainPageWithMenu>
    // </LoaderOverlay>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCourse);
