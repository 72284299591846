import React, { useState } from "react";
import { ReactComponent as ReadingGlasses } from "../../../assets/icons/light-eyeglasses-.svg";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";

import "./sideArticle.css";
import RenderContentfulDocument from "../../common/contentful/renderDocument";
import commonColor from "../../../theme/commonColor";

const SideArticle = ({ item, index }) => {
  const [showLess, setShowLess] = useState(false);

  const renderedBody = RenderContentfulDocument(item.fields.content);

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <div
        key={item.sys.id}
        className="w-full flex flex-col overflow-hidden  items-center justify-center m-4 bg-white"
        style={{
          maxWidth: "33rem",
          minHeight:"33rem",
          maxHeight:"33rem",
          boxShadow: `0px 5px 10px 0 rgba(0, 0, 0, 0.6)`,
          borderRadius: 25,
        }}
      >
        {item.fields.thumbnail && (
          <div className="w-full h-56 flex justify-center items-center overflow-hidden">
            <img
              src={item.fields.thumbnail?.fields?.file.url}
              loading="lazy"
              alt="l3arn"
              className="h-full w-full object-cover overflow-hidden"
            />
          </div>
        )}
        {item.fields?.hashtags && item.fields?.hashtags.length > 0 && (
  <div
    className="w-full px-1 py-1 text-xs flex flex-wrap"
    style={{
      color: commonColor.deepGreen,
      height: "3rem", // Set a fixed height (adjust as needed)
      overflow: "hidden", // Hide overflowing content
      textOverflow: "ellipsis", // Add ellipsis for overflowing text
    }}
  >
    {item.fields?.hashtags
      .map((h) => `#${h?.fields?.name}`)
      .join(" ")
      .replaceAll("##", "#")}
  </div>
)}
        {item.fields.title && (
          <div className=" px-3 py-2 text-base font-bold">
            {item.fields.title}
          </div>
        )}
        {item.fields.content && (
          <div className="w-full  px-3 py-2  text-xs flex-grow flex flex-col">
            <div
              className={`max-h-40 whitespace-pre-wrap ${
                showLess === true
                  ? "overflow-y-scroll"
                  : "clamp-5-lines overflow-hidden"
              }`}
              //Note:'Key' must change when showMore/showLess changes, in order to update 'dangerouslySetInnerHtML'
              key={
                index +
                "-" +
                item.fields.content +
                "-" +
                (showLess ? "expanded" : "collapsed")
              }
              // dangerouslySetInnerHTML={markup()}
            >
              {renderedBody}
            </div>

            <div className="w-full flex justify-end">
              <button
                className="text-xs"
                onClick={() => {
                  setShowLess(!showLess);
                }}
              >
                {showLess === true ? "Show less.." : "Show more.."}
              </button>
            </div>
          </div>
        )}
     <div className="flex justify-between space-x-2 items-center py-2 px-3 max-h-28">
  {item.fields?.source?.fields?.sourceUrl ? (
    // Render the ReadingGlasses icon with the link if sourceUrl exists
    <a href={item.fields.source.fields.sourceUrl} target="_blank">
      <ReadingGlasses
        style={{
          height: "2.5rem",
          overflow: "hidden",
        }}
      />
    </a>
  ) : (
    // Render the ReadingGlasses icon without a link if sourceUrl is not available
    <div>
      <ReadingGlasses
        style={{
          height: "2.5rem",
          overflow: "hidden",
          // opacity: 0.5, // Optional: Make it semi-transparent if there's no link
          cursor: "not-allowed", // Optional: Show a disabled cursor
        }}
      />
    </div>
  )}
</div>

      </div>
    </div>
  );
};

export default SideArticle;
