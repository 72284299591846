import React, { useState } from "react";
import Swotbutton from "../../components/common/buttons/swotbutton";
import ChatUserModel from "../models/chatUser";
import { ChatService } from "../services/chatService";
import ChatUserWidget from "./ChatUserWidget";

const NewMessageComponent = ({
  toUserDetails,
  myUserId,
  close
}: {
  toUserDetails: ChatUserModel;
  myUserId: string;
  close: () => void;
}) => {
  const [message, setMessage] = useState<string>();
  const [messageSending, setMessageSending] = useState<boolean>(false);
  const chatService = new ChatService();

  const sendMessage = async () => {
    if(!messageSending && message && message.length > 0) {
      setMessageSending(true);
      const result = await chatService.sendMessage(myUserId, toUserDetails.uid, message);
      setMessageSending(false);
      if(result) {
        setMessage("");
        close && close();
      }
    }
  };

  return (
    <div className="w-full p-2">
      <div className="w-full flex-col justify-start">
        <div className="w-full"></div>
        <div className="w-full pr-10">
          {toUserDetails &&  <ChatUserWidget user={toUserDetails}/> }
        </div>
        <div className="w-full">
          <textarea
            rows={4}
            className="w-full bg-gray-300 appearance-none border-2 focus:outline-none focus:border-opacity-100 rounded-lg"
            style={{
              resize: "none",
              paddingLeft: "12px",
              paddingTop: "5px",
            }}
            value={message}
            placeholder={"Enter the message.."}
            onChange={(event) => {
              setMessage(event.target.value);
            }}
          />
        </div>
        <div className="w-full flex flex-row justify-between sm:justify-end p-2">
          <Swotbutton text="Cancel" 
          style={{
            backgroundColor: 'rgb(134 127 129)'
          }} onClick={() => {
            close && close();
          }} />
          <Swotbutton text="Send" className="ml-2" loading={messageSending} onClick={() => {
            sendMessage();
          }} />
        </div>
      </div>
    </div>
  );
};

export default NewMessageComponent;


