import React, { useState, useEffect } from "react";
import knotSm from "../../../assets/images/learn-small.png";
import { rejectRefund, approveRefund } from "../../../services/api";
import UserInfoPanel from "../../tutor/profile/userInfoPanel";
import LoaderModal from "../../common/modal/loaderModal";
import Modal from "../../common/modal/modal";
import SwotButton from "../../common/buttons/swotbutton";
import Swal from "sweetalert2";

const BetatesterListItem = ({ betatester }) => {
  const [loading, setLoading] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const showBetatesterDetails = () => {
    setShowInfo(!showInfo);
    console.log("showing betatester info..", showInfo);
  };

  return (
    <div className="w-full bg-transparent flex  flex-col justify-start focus:outline-none pb-4 ">
      <div
        className="w-full shadow-lg rounded-2xl bg-white h-full flex flex-col items-start justify-center md:flex-row md:items-center md:justify-start md:flex-wrap text-xs p-3"
        style={{
          // width: "60%",
          maxWidth: "60rem",
          minHeight: "80px",
        }}
      >
        <div className="flex items-center justify-start flex-wrap">
          <div className="text-center pr-4">
            <b>{betatester.id}</b>
          </div>
          <div className="text-center pr-4">{betatester.name}</div>
          <div className="text-center pr-4">{betatester.email}</div>
          <div className="text-center pr-4">{betatester.dob}</div>
          <div className="text-center pr-4">{betatester.country}</div>
        </div>
        <div className="flex items-center justify-start py-1">
          <div className="text-start pr-4">
            | <b>Answer: </b>
            {betatester.education_issue}
          </div>
        </div>
        {/* <button
                    className="focus:outline-none hover:shadow-outline bg-blue-600 py-2 px-4
             text-white mx-2 rounded-2xl"
                    onClick={() => showBetatesterDetails()}
                >
                    Show Info
                </button> */}
      </div>
      {showInfo && (
        <div
          style={{
            maxWidth: "50rem",
            // marginTop: "90px",
            // width: "54%",
            //height: "55vh",
            // position: "absolute",
            transition: "opacity 1s",
            display: "flex",
          }}
          className="flex flex-col items-center justify-start"
        >
          <div className="w-full flex flex-col justify-start items-center shadow-lg rounded-2xl bg-white px-3">
            <div className="flex flex-col justify-start items-center">
              <p className="text-md">
                <b> Betatester Details</b>
              </p>
              <br />
              <p>
                <b>Betatester Id:</b> {betatester.id}
              </p>
              <p>
                <b>Name:</b> {betatester.name}
              </p>
              <p>
                <b>Email:</b> {betatester.email}
              </p>
              <p>
                <b>DOB:</b> {betatester.dob}
              </p>
              <p>
                <b>Country:</b> {betatester.country}
              </p>
              <p>
                <b>Education Sector Issues:</b> {betatester.education_issue}
              </p>
            </div>
          </div>
        </div>
      )}

      <LoaderModal isOpen={loading} />
    </div>
  );
};

export default BetatesterListItem;
