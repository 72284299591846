import React from "react";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate, Navigate } from "react-router-dom";
import UserActions from "../../redux/user";
import SignIn from "../../screens/signin/signin";
import { auth, setOffline } from "../../config/firebase";
import { useUserAuth } from "../../services/userManagement";
import CustomFadeLoader from "../common/CustomFadeLoader";
import { signOut } from "firebase/auth";
import commonColor from "../../theme/commonColor";
import BoxLoader from "../common/BoxLoader";

const PrivateRoute = ({ as: Component, ...props }) => {
  const navigate = useNavigate();
  const { user, saveUser } = props;
  
  const authUser = useUserAuth(true);

  const signOutUser = () => {
    console.log("Signing out...");
    setOffline();
    signOut(auth);
    saveUser(null);
    navigate("/", { repalce: true });
  };

  console.log("PrivateRoute - user", user);

  return (
    <div>
      {user && authUser ? (
        <Component navigate={navigate} {...props} signOut={signOutUser} />
      ) : user ? (
        <div className="h-screen w-screen">
          <div className="w-full h-full flex justify-center bg-white text-black items-center">
            <CustomFadeLoader loading={true} color={commonColor.deepGreen} />
          </div>
        </div>
      ) : (
        <SignIn />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
