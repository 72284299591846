
export const createCSV = (dataList, filename) => {
  if (dataList && dataList.length > 0) {
    var keys = Object.keys(dataList[0]);
    keys = dataList.reduce((p, e) => {
      try {
        return [...new Set([...p, ...Object.keys(e)])];
      } catch (ex) {
        return p;
      }
    }, []);
  }

  let rows = dataList.map((element) =>
    keys.map((k) => element[k] ?? "").join(",")
  );
  rows = [keys.join(",")].concat(rows);

  let csvContent = "data:text/csv;charset=utf-8," + rows.join("\n");
  var encodedUri = encodeURI(csvContent);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", filename + ".csv");
  document.body.appendChild(link); // Required for FF
  link.click();
};
