import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getCourseListOfTutor } from "../../../services/api";
import Swotbutton from "../../../components/common/buttons/swotbutton";
import CourseListItem from "../../../components/student/dashboard/CourseListItem";
import { NavigateFunction, useNavigate } from "react-router-dom";
import FooterView from "../../../components/common/footerView";

const CourseList = ({ setCurrentView, user, setCourseId, setViewMode }) => {
  const navigate = useNavigate();
  const [allCourses, setAllCourses] = useState([]);
  const [coursesInit, setCoursesInit] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState();

  useEffect(() => {
    if (!coursesInit) {
      getAllCourses();
    }
  }, [coursesInit]);

  useEffect(() => {}, [selectedCourse, allCourses]);

  useEffect(() => {
    console.log("CourseList - useEffect -  courses found", allCourses);
  }, [allCourses]);

  const getAllCourses = async () => {
    const result = await getCourseListOfTutor(user.uid);
    if (result) {
      console.log("CourseList - useEffect - got courses", result.data);
      // TODO: studentsEnroled: "0", is also coming back from the api. do we want to show it?
      setSelectedCourse(result.data.courses[0]);
      setAllCourses(result.data.courses);
      setCoursesInit(true);
    }
  };

  const renderCourses = () => {
    console.log(
      "CourseList - renderCourses -  courses found list",
      allCourses.length
    );
    if (allCourses.length > 0) {
      /**
       *  "courseId": 2,
                  "nameFull": "marketing for apples",
                  "nameShort": "mark apple",
                  "subjectArea": "Marketing",
                  "photoUrl": "ajajkajajajajaj",
                  "tutorUsername": "Tasos"
       */
      return allCourses.map((course) => {
        return (
          <CourseListItem
            key={course.courseId}
            course={course}
            selected={course.courseId === selectedCourse.courseId}
            setSelected={setSelectedCourse}
          />
        );
      });
    } else if (!coursesInit) {
      // return <div>Fetching courses...</div>;
      return <div></div>;
    } else {
      return <></>;
    }
  };

  return (
    <div className="h-full w-full flex flex-col justify-between">
      <div className="flex justify-end  px-4 py-4 lg:px-8 lg:pb-2">
        <Swotbutton
          text={"Add Course"}
          onClick={() => {
            // setCurrentView("createCourse");
            navigate("/create-course");
            // setViewMode("create");
          }}
        />
      </div>
      <div
        className="w-full flex-grow flex justify-center items-center pb-8"
        style={
          {
            // minHeight: "20rem",
          }
        }
      >
        {selectedCourse ? (
          <div
            className="w-full md:w-8/12 2k:w-1/4 h-full flex  bg-white flex-col rounded-3xl darker_shadow overflow-hidden"
            style={
              {
                // minWidth: "10rem",
                // maxWidth: "30rem",
              }
            }
          >
            <button
              className="w-full h-2/3 bg-blue-500 focus:outline-none"
              style={{
                backgroundImage: `url(${
                  selectedCourse.photoUrl ?? selectedCourse.videoThumbnailUrl
                })`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                minHeight: "10rem",
              }}
              onClick={() => {
                console.log("Go to course page", selectedCourse);
                //setCourseId(selectedCourse.courseId);
                // setCurrentView("courseDetails");
                navigate(`/courses/${selectedCourse.courseId}`);
              }}
            />
            <div className="w-full h-1/3 flex justify-between">
              <div className="h-full flex flex-col w-full md:w-72 lg:w-96">
                <div
                  className="py-1 px-4 text-lg"
                  style={{
                    color: "black",
                    minHeight: "2rem",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {/* {selectedCourse.nameShort} */}
                  {selectedCourse.nameFull}
                </div>
                <div
                  className="px-4 flex text-sm"
                  style={{
                    color: "black",
                  }}
                >
                  {selectedCourse.subject + " - " + selectedCourse.subjectArea}
                </div>
                <div
                  className="px-4 flex text-sm"
                  style={{
                    color: "black",
                  }}
                >
                  {selectedCourse.rate && `$${selectedCourse.rate}`}
                </div>
                {/* <div
                   className="px-4 flex text-sm"
                   style={{
                     color: "black",
                   }}
                >
                 Title: {selectedCourse.nameFull}
                </div>
                 */}
                {selectedCourse.time && (
                  <div
                    className="px-4 flex text-sm"
                    style={{
                      color: "black",
                    }}
                  >
                    Time: {selectedCourse.time}
                  </div>
                )}
                {selectedCourse.day && (
                  <div
                    className="px-4 flex text-sm"
                    style={{
                      color: "black",
                    }}
                  >
                    Day: {selectedCourse.day}
                  </div>
                )}

                {/* <button
                  className="px-4 flex text-sm focus:outline-none"
                  style={{
                    color: "black",
                  }}
                  onClick={() => {
                    console.log("Go to tutor", selectedCourse.tutorFirebaseId);
                  }}
                >
                  Tutor: {user.username}
                </button> */}
                <div
                  className="px-4 flex text-sm mt-2"
                  style={{
                    paddingTop: "10px",
                    color: "black",
                    height: "6rem",
                    overflow: "scroll",
                    textOverflow: "ellipsis",
                  }}
                >
                  {selectedCourse.summary}
                </div>
              </div>
              <div className="h-full w-1/2 flex flex-col justify-end items-end pr-4 pb-4">
                <Swotbutton
                  text={"Edit"}
                  onClick={() => {
                    console.log("Edit course pressed", selectedCourse);
                    // setCurrentView("createCourse");
                    // setViewMode("edit");
                    // setCourseId(selectedCourse.courseId);
                    navigate(`/edit-course/${selectedCourse.courseId}`);
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="text-white">
            There are no courses at the moment...
          </div>
        )}
      </div>
      <div
        className={`w-full flex justify-start items-start overflow-x-scroll overflow-y-hidden pb-2 ${
          allCourses.length > 0 ? "min-h-36" : ""
        }`}
      >
        {renderCourses()}
      </div>

      <FooterView />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CourseList);
