import React, { useEffect, useState } from "react";
import moment from "moment";
import Swotbutton from "../common/buttons/swotbutton";
import { ACCOUNT_TYPES } from "../../config/constants";
import ContentViewComponent from "../../h5p/components/ContentViewComponent";
import H5pDetail from "../../h5p/h5pDetail";
import { useNavigate } from "react-router-dom";

const H5PContentSection = ({ user, content, courseShortName, playContent }) => {
  const navigate = useNavigate();

  const handleClick = (contentId) => {
    console.log("content clicked: " + contentId);
    playContent(<H5pDetail contentId={contentId} />);
  };

  const row = (item) => {
    return (
      <div key={item.content_id} className="w-full p-4 flex justify-between">
        <div>
          <span className="font-bold text-sm">{item?.title}</span> -{" "}
          <span className="text-xs">{item.library_name}</span>
        </div>{" "}
        <Swotbutton
          text="Play"
          onClick={() => {
            handleClick(item.content_id);
          }}
        />
      </div>
    );
  };

  return (
    <>
      <div className="h-full w-full flex flex-col justify-start">
        <div className="w-full h-full flex flex-col flex-grow items-start justify-start p-4 overflow-y-scroll">
          {content.length === 0 ? (
            <div className="w-full h-full flex flex-col items-center">
              {user?.accountType?.toLowerCase() ===
              ACCOUNT_TYPES.TUTOR.toLowerCase() ? (
                <>
                  <div className="w-full text-center">
                    You have not added any content yet. l3arn, with H5P provides
                    you with the opportunity to create content that will enhance
                    your student's experience.
                  </div>
                  <Swotbutton
                    onClick={() => {
                      navigate("/content-management");
                    }}
                    text={"Add Content"}
                  />
                </>
              ) : (
                <>No content yet.</>
              )}
            </div>
          ) : (
            content.map((item) => {
              return row(item);
            })
          )}
        </div>

        {/* {content.length === 0 && (
          <div className="absolute right-0 bottom-0 pl-3 pt-3 mr-2 mb-2">
            <Swotbutton onClick={() => {}} text={"Add Content"} />
          </div>
        )} */}
      </div>
    </>
  );
};

export default H5PContentSection;
