import React from "react";
import UserModel from "../../../redux/redux-models/reduxUser";
import { IChatMessage, MessageType } from "../../models/chat_models";
import ChatUserModel from "../../models/chatUser";
import GenericProfilePhoto from "../../../assets/images/tutor_base.jpg";
import { ReactComponent as ImageIcon } from "../../../assets/images/image-icon.svg";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./ChatMessageComponent.css";

const ChatMessageComponent = ({
  message,
  user,
  otherUser,
  openImage,
}: {
  message: IChatMessage;
  user: UserModel;
  otherUser?: ChatUserModel;
  openImage?: (imageUrl: string) => void;
}) => {
  const isMine = message.sentBy === user.uid;

  return (
    <div className="p-2">
      {isMine ? (
        <div className="w-full flex justify-end">
          <div className="max:w-9/10 lg:max-w-3/5 flex flex-row-reverse justify-start items-start bg-white">
            <div className="w-10 h-10 m-2">
              <img
                className="w-auto rounded-full w-full h-full"
                style={{ height: "100%", objectFit: "cover" }}
                src={user?.photoUrl ? user!.photoUrl : GenericProfilePhoto}
                alt="userPhoto"
              />
            </div>
            <div
              className={`max-w-full flex flex-col pt-2 justify-start items-end  ${
                message.isLocal === true ? "send-local" : "send"
              }`}
            >
              <span className="text-xs italic">
                {moment.unix(message.sentAt).format("MM/DD/YYYY hh:mm a")}
              </span>
              {message.type === MessageType.IMAGE ? (
                <ChatImageComponent
                  imageUrl={message.messageText}
                  openImage={openImage}
                />
              ) : (
                <ChatTextComponent text={message.messageText} />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full">
          <div className="max:w-9/10 lg:max-w-3/5 flex flex-row justify-start items-start bg-white">
            <div className="w-10 h-10 m-2">
              <img
                className="w-auto rounded-full w-full h-full"
                style={{ height: "100%", objectFit: "cover" }}
                src={
                  otherUser?.photoUrl
                    ? otherUser!.photoUrl
                    : GenericProfilePhoto
                }
                alt="userPhoto"
              />
            </div>
            <div className="max-w-full flex flex-col pt-2 justify-start items-start receive">
              <span className="text-sm font-bold">
                {otherUser?.firstName} {otherUser?.lastName}
              </span>
              <span className="text-xs italic">
                {moment.unix(message.sentAt).format("MM/DD/YYYY hh:mm a")}
              </span>

              {message.type === MessageType.IMAGE ? (
                <ChatImageComponent
                  imageUrl={message.messageText}
                  openImage={openImage}
                />
              ) : (
                <ChatTextComponent text={message.messageText} />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatMessageComponent;

const ChatTextComponent = ({ text }: { text: string }) => {
  return <p className="text-sm max:w-full break-all">{text}</p>;
};
const ChatImageComponent = ({
  imageUrl,
  openImage,
}: {
  imageUrl: string;
  openImage?: (imageUrl: string) => void;
}) => {
  return (
    <div className="max:w-full">
      {imageUrl ? (
        <div
          className="cursor-pointer"
          onClick={() => {
            openImage && openImage(imageUrl);
          }}
        >
          <LazyLoadImage
            alt={"image"}
            placeholder={<ImageIcon className="w-20 h-20" />}
            width={200}
            src={imageUrl} // use normal <img> attributes as props
          />
        </div>
      ) : (
        <ImageIcon
          className="contain rounded-xl h-20 w-20"
          style={{
            width: "auto",
            overflow: "hidden",
            objectFit: "contain",
          }}
        />
      )}
    </div>
  );
};
