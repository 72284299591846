import { useEffect, useState, useReducer } from "react";
import { connect } from "react-redux";
import Select from "../../components/common/input/select";
import contentful from "../../config/contentful";
import MainPageWithMenu from "../../components/common/MainPageTemplate/mainPageWithMenu";
import Swotbutton from "../../components/common/buttons/swotbutton";
import knotSm from "../../assets/images/learn-small.png";
import { useNavigate } from "react-router-dom";

import CustomFadeLoader from "../../components/common/CustomFadeLoader";
import { ReactComponent as FAQ } from "../../assets/icons/faq.svg";
import { ReactComponent as LearningObjectIcon } from "../../assets/icons/learning_object.svg";
import { ReactComponent as RequirementsIcon } from "../../assets/icons/requirements.svg";
import { ReactComponent as KeywordsIcon } from "../../assets/icons/keywords.svg";
import { ReactComponent as FreeIcon } from "../../assets/icons/free.svg";
import { ReactComponent as PurposeIcon } from "../../assets/icons/purpose.svg";
import commonColor from "../../theme/commonColor";
import Swal from "sweetalert2";
import "./advanceTools.css";
import Masonry from "react-masonry-css";
import EmptyNewsMessage from "../../components/tutor/tools/emptyNewsMessage";

const capitalize = (s) =>
  (s && s.trim() !== "" && s.trim()[0].toUpperCase() + s.trim().slice(1)) || "";

const dataKeys = [
  "keywords",
  "subjectArea",
  "learningObjects",
  "purpose",
  "cost",
  "requirements",
  "tutorialAvailable",
];

const initialState = () => {
  var data = {};
  dataKeys.forEach((key, ind) => {
    if (key !== "keywords") {
      const valueItem = dataOpts[key];
      const options = valueItem.options.map((v, i) => {
        return {
          value: v,
          label:
            typeof v === "boolean"
              ? { true: "Yes", false: "No" }[v]
              : capitalize(v),
        };
      });
      data[key] = { title: valueItem.title, options: options };
    }
  });
  return data;
};

const AdvanceTools = ({ user }) => {
  const navigate = useNavigate();
  let [loading, setLoading] = useState(true);
  let [isSearch, setIsSearch] = useState(false);
  const [content, setContent] = useState([]);
  const [featuredContent, setFeaturedContent] = useState([]);

  const reducerStateFn = (state, action) => {
    if (action.key) {
      if (action.key === "reset") {
        return action.value;
      }
      return { ...state, ...{ [action.key]: action.value } };
    }
  };

  const [stateData, dispatchState] = useReducer(reducerStateFn, initialState());

  const saveSearchProperty = (key, value, step) => {
    let data = stateData[key] ?? {};
    data.currentValue = value;
    dispatchState({ key, value: data });
  };

  const getContentQueryParamsForRole = () => {
    let params = {};
    if (user.accountType) {
      params["fields.role.sys.contentType.sys.id"] = "role";
      params[
        "fields.role.fields.name[in]"
      ] = `public,${user.accountType.toLowerCase()}`;
    }
    return params;
  };

  const getContentQueryParams = () => {
    let params = {};
    if (
      stateData["keyword"]?.currentValue &&
      stateData["keyword"]?.currentValue.trim() !== ""
    ) {
      // const keywords = stateData["keyword"].currentValue.trim().split(",");
      // params["fields.keyword[match]"] = keywords.map((v) => v.trim());
    }
    if (stateData["cost"]?.currentValue?.value) {
      params["fields.isPaid"] =
        stateData["cost"].currentValue.value.toLowerCase() === "premium";
    }

    // if (stateData["requirements"]?.currentValue?.value) {
    // params["fields.requirements[match]"] =
    // stateData["requirements"].currentValue.value;
    // }

    if (stateData["requirements"]?.currentValue?.length > 0) {
      let requirements = [];
      requirements = stateData["requirements"]?.currentValue.map(
        (i) => i.value
      );

      params["fields.requirements.sys.contentType.sys.id"] = "requirements";
      params["fields.requirements.fields.name[in]"] = requirements.toString();
    }

    if (stateData["tutorialAvailable"]?.currentValue?.value !== null) {
      params["fields.tutorialAvailable"] =
        stateData["tutorialAvailable"].currentValue.value;
    }
    return params;
  };

  const hasSearchEntry = () => {
    if (
      (stateData["keyword"]?.currentValue ?? "").trim() !== "" ||
      stateData["learningObjects"]?.currentValue?.value ||
      stateData["subjectArea"]?.currentValue?.value ||
      stateData["purpose"]?.currentValue?.value
    ) {
      return true;
    }
    return false;
  };

  const getDataForContentType = async ({ contentType }) => {
    const response = await contentful.getEntries({ content_type: contentType });
    if (response && response.items && response.items.length > 0) {
      const items = response.items.map((item, idx) => {
        return item.fields.name;
      });

      let data = stateData[contentType];
      data.options = items.map((v, i) => {
        return { value: v, label: capitalize(v) };
      });
      dispatchState({ key: contentType, value: data });
    }
  };

  const getFeaturedContent = async () => {
    setLoading(true);
    let params = getContentQueryParamsForRole();
    params.content_type = "tools";
    params["fields.featured"] = true;
    try {
      const response = await contentful.getEntries(params);
      setFeaturedContent(handleContentResponse(response));
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const searchContent = async () => {
    let query = { content_type: "tools" };
    let params = getContentQueryParams();
    if (Object.keys(params) === 0 && !hasSearchEntry()) {
      setIsSearch(false);
      return;
    }

    setIsSearch(true);
    query = { ...query, ...params, ...getContentQueryParamsForRole() };
    setLoading(true);
    try {
      const response = await contentful.getEntries(query);

      let data = handleContentResponse(response);
      data = filterSearchContent(data);
      setContent(data);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const handleContentResponse = (response) => {
    if (response && response.items && response.items.length > 0) {
      return response.items.map((item, idx) => {
        let fields = item.fields;
        if (fields.learningObjects && fields.learningObjects.fields) {
          fields.learningObjects = fields.learningObjects.fields.name;
        } else {
          fields.learningObjects = null;
        }
        if (fields.subjectArea && fields.subjectArea.fields) {
          fields.subjectArea = fields.subjectArea.fields.name;
        } else {
          fields.subjectArea = null;
        }
        if (fields.toolMedia && fields.toolMedia.fields) {
          fields.imageUrl = fields.toolMedia.fields.file?.url;
        } else {
          fields.imageUrl = null;
        }
        if (fields.purpose && fields.purpose.fields) {
          fields.purpose = fields.purpose.fields.name;
        } else {
          fields.purpose = null;
        }
        if (fields.requirements && fields.requirements.fields) {
          fields.requirements = fields.requirements.fields.name;
        } else {
          fields.requirements = null;
        }
        return fields;
      });
    } else {
      return [];
    }
  };

  const filterSearchContent = (data) => {
    let dataFiltered = data;
    if (dataFiltered?.length > 0) {
      const keywords = (stateData["keyword"]?.currentValue ?? "").trim();
      if (keywords !== "") {
        const keywordItems = stateData["keyword"].currentValue
          .trim()
          .split(",")
          .map((e) => e.toLowerCase());
        dataFiltered = dataFiltered.filter((item) => {
          if (item.keywords) {
            let itemKeywords = item.keywords.trim().split(",");
            itemKeywords = itemKeywords.map((e) => e.toLowerCase()).toString();
            return (
              keywordItems.find(
                (k) => itemKeywords.match(k) || k.match(itemKeywords)
              ) !== null
            );
          } else {
            return false;
          }
        });
      }
      const learningObject = stateData["learningObjects"]?.currentValue?.value;
      if (learningObject && learningObject !== "") {
        dataFiltered = dataFiltered.filter(
          (item) =>
            item.learningObjects.toLowerCase() === learningObject.toLowerCase()
        );
      }

      const subjectArea = stateData["subjectArea"]?.currentValue?.value;
      if (subjectArea && subjectArea !== "") {
        dataFiltered = dataFiltered.filter(
          (item) => item.subjectArea.toLowerCase() === subjectArea.toLowerCase()
        );
      }

      const purpose = stateData["purpose"]?.currentValue?.value;
      if (purpose && purpose !== "") {
        dataFiltered = dataFiltered.filter(
          (item) => item.purpose.toLowerCase() === purpose.toLowerCase()
        );
      }
    }
    // if (requirements && requirements.length > 0) {
    //   dataFiltered = dataFiltered.filter(
    //     (item) =>
    //       requirements.find(
    //         (k) => item.requirements && item.requirements.match(k)
    //       ) !== null
    //   );
    // }

    return dataFiltered;
  };

  useEffect(() => {
    getDataForContentType({ contentType: "subjectArea" });
    getDataForContentType({ contentType: "learningObjects" });
    getDataForContentType({ contentType: "requirements" });
    getDataForContentType({ contentType: "purpose" });
    getFeaturedContent();
  }, []);

  if (user?.accountType !== "Tutor") {
    navigate("/dashboard");
    return null;
  }

  const breakpointColumnsObj = {
    default: 5,
    1900: 4,
    1450: 3,
    1100: 2,
    768: 1,
  };

  const masonryView = (data) => {
    return (
      <div className="w-full lg:px-4">
        <div
          className="pt-5 overflow-y-scroll float-right flex justify-end xl:justify-center"
          style={{
            width: "100%",
          }}
        >
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid pt-4"
            columnClassName="my-masonry-grid_column"
          >
            {data.map((tut, tidx) => (
              <div className="w-full py-2">
                <ItemBigCell key={tidx} item={tut} />
              </div>
            ))}
          </Masonry>
        </div>
      </div>
    );
  };

  return (
    <MainPageWithMenu user={user} menuSelected={"findTutors"}>
      <div className="w-full h-full flex flex-col justify-start">
        {!user.quizDone ? (
          <div className="w-full h-full flex flex-col justify-center items-center">
            <div
              className="bg-white p-5 w-full max-w-screen-sm h-full lg:max-h-1/2 flex flex-col justify-center"
              style={{
                boxShadow: `0 10px 25px 0 rgba(0, 0, 0, 0.6)`,
                borderRadius: 25,
                //height: '70%',
              }}
            >
              <div className="w-full h-full  flex flex-col justify-center items-center">
                <div className="text-center">
                  Please do the quiz to help us match you with great tutors.
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full h-full flex flex-col justify-start overflow-y-scroll">
            <div
              className="bg-white p-5 w-full max-w-screen-sm flex flex-col justify-start self-center"
              style={{
                boxShadow: `0 10px 25px 0 rgba(0, 0, 0, 0.6)`,
                borderRadius: 25,
                //height: '70%',
              }}
            >
              <div className="w-full flex flex-col items-center justify-start">
                <div className="w-full flex flex-col justify-around">
                  <div className="text-center">Tools and learning objects</div>

                  <div className="flex flex-col justify-start items-center p-2">
                    <div className="w-full flex flex-row justify-center">
                      <div className="text-sm w-1/3 flex flex-row justify-end items-center text-right">
                        {"Use commas to separate more than one keyword"}
                      </div>
                      <div className="w-2/3 flex flex-row justify-start px-3 pr-3 py-1">
                        <input
                          className="w-full bg-white appearance-none border-2 focus:outline-none focus:border-opacity-100 py-1 px-2"
                          id="keywords"
                          name="keywords"
                          type="text"
                          placeholder={"Keywords"}
                          value={stateData["keywords"]}
                          onChange={(event) =>
                            saveSearchProperty("keyword", event.target.value)
                          }
                        />
                      </div>
                    </div>

                    {dataKeys.map((key) => {
                      if (key === "keyword" || !stateData[key]) {
                        return null;
                      }
                      const valueItem = stateData[key];
                      return (
                        <DropdownWidget
                          title={valueItem.title}
                          value={valueItem.currentValue}
                          onChange={(value) => {
                            saveSearchProperty(key, value);
                          }}
                          options={valueItem.options}
                          isMulti={key === "requirements"}
                          info={
                            key === "learningObjects"
                              ? "A learning object is a digital, educational resource that helps the student in a learning event. This is something that can be reused. They can include videos, podcasts, graphics, presentations, crosswords. Choose the ones that best match your tutoring style."
                              : null
                          }
                        />
                      );
                    })}

                    <div className="w-full flex justify-end">
                      <Swotbutton
                        className="min-w-24"
                        text={"Search"}
                        // disabled={subjectAreas.length === 0}
                        shadow={true}
                        onClick={async () => {
                          await searchContent();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {loading ? (
              <CustomFadeLoader loading={loading} />
            ) : (
              <div className="w-full flex flex-col justify-start items-start p-2">
                {isSearch ? (
                  !content || content.length === 0 ? (
                    // <div className="self-center italic text-sm text-center">
                    //   No tutors found yet.
                    // </div>

                    <div className="w-full flex flex-col justify-start items-center pt-8 pb-24 ">
                      <div
                        className="rounded overflow-hidden shadow-lg bg-white w-full max-w-screen-sm flex flex-col justify-start self-center"
                        style={{
                          boxShadow: `0 10px 25px 0 rgba(0, 0, 0, 0.6)`,
                          borderRadius: 25,
                        }}
                      >
                        <EmptyNewsMessage
                          searchText={stateData["keyword"]?.currentValue}
                        ></EmptyNewsMessage>
                      </div>
                    </div>
                  ) : (
                    // <div className="w-full flex flex-col justify-start items-start p-2">
                    //   <div className="w-full flex flex-col justify-start items-center">
                    //     <p className="text-white">
                    //       <EmptyNewsMessage
                    //         searchText={stateData["keyword"]?.currentValue}
                    //       >
                    //       </EmptyNewsMessage>
                    //     </p>
                    //   </div>
                    // </div>
                    // <div className="w-full flex flex-row justify-center items-start gap-2 flex-wrap p-2">
                    //   {content.map((tut, tidx) => (
                    //     <ItemCell key={tidx} item={tut} />
                    //   ))}
                    // </div>

                    masonryView(content)
                  )
                ) : !featuredContent || featuredContent.length === 0 ? (
                  // <div className="self-center italic text-sm text-center">
                  //   No tutors found yet.
                  // </div>
                  <div className="w-full flex flex-col justify-start items-start p-2">
                    <div className="w-full flex flex-col justify-start items-center">
                      <p className="text-white">No data found</p>
                    </div>
                  </div>
                ) : (
                  // <div className="w-full flex flex-row justify-center items-start gap-2 flex-wrap p-2">
                  //   {featuredContent.map((tut, tidx) => (
                  //     <ItemCell key={tidx} item={tut} />
                  //   ))}
                  // </div>

                  masonryView(featuredContent)
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </MainPageWithMenu>
  );
};

const DropdownWidget = ({
  title,
  placeholder,
  defaultValue,
  value,
  onChange,
  options,
  isMulti,
  info,
}) => {
  return (
    <div className="w-full flex flex-row justify-center p-1">
      <div className="text-sm w-1/3 flex flex-row justify-end items-center text-right">
        {title}
      </div>
      <div className="w-2/3 flex flex-row justify-end px-2">
        <Select
          placeholder={placeholder ?? "Select"}
          className="w-full text-sm"
          closeMenuOnSelect={true}
          isClearable={true}
          value={value}
          isMulti={isMulti}
          onChange={(value) => {
            onChange(value);
          }}
          options={options}
        />
        {info && (
          <button
            className={`p-1 hover:shadow-xl focus:outline-none rounded-lg w-9`}
            onClick={() => {
              Swal.fire({ title: "Info", text: info, icon: "info" });
            }}
          >
            <FAQ
              style={{
                fill: commonColor.deepGreen,
              }}
            />
          </button>
        )}
      </div>
    </div>
  );
};

/*
const ItemCell = ({ key, item }) => {
  var firstLine = item.nameOfTool;
  var secondLine = item.subjectArea;
  var thirdLine = item.toolExplanation?.trim();

  // if (item.isPaid) {
  secondLine = secondLine && secondLine !== "" ? secondLine + ", " : secondLine;
  secondLine = secondLine + (item.isPaid ? "Premium" : "Free");
  // }
  return (
    <div
      className="w-full sm:w-60 h-60 max-w-100 flex-shrink-0 overflow-hidden p-2 cursor-pointer"
      onClick={() => {
        console.log("selected tutor:", item);
        if (item?.appUrl) {
          window.open(item?.appUrl, "_blank", "noopener,noreferrer");
        }

        // if (
        //   tutor.relatedCourses[0].courseId
        // ) {
        //   navigate("courses", {
        //     state: {
        //       courseId: tutor.relatedCourses[0].courseId,
        //     },
        //   });
        // } else {
        //   console.log("navigating to tutor profile", tutor);
        // }
      }}
    >
      <div
        className="w-full h-full relative bg-white overflow-hidden"
        style={{
          borderRadius: 10,
          boxShadow: `1px 1px 10px 0px rgba(0,0,0,0.6)`,
        }}
      >
        <img
          src={item.imageUrl ? item.imageUrl : knotSm}
          loading="lazy"
          alt={item.nameOfTool ?? ""}
          className="h-full w-full object-cover"
        />
        <div className="w-full absolute p-2 bottom-0 bg-white/75">
          <div className="w-full flex flex-col justify-start items-start">
            <span className="text-sm">{firstLine}</span>
            <div className="w-full h-full flex flex-row justify-between items-center">
              <span className="text-sm">{secondLine}</span>
              {item.matchPercentage && (
                <span className="text-sm text-red-500">
                  {item.matchPercentage.toFixed(0)}%
                </span>
              )}
            </div>
            {thirdLine && thirdLine !== "" && (
              <div className="multiline-elipsis">
                <span className="text-xs">{thirdLine}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
*/
const ItemBigCell = ({ key, item }) => {
  // featured:true
  // isPaid:false
  // tutorialAvailable:false

  return (
    <div
      className="w-full  flex-shrink-0 overflow-hidden p-2 cursor-pointer"
      onClick={() => {
        console.log("selected tutor:", item);
        if (item?.appUrl) {
          window.open(item?.appUrl, "_blank", "noopener,noreferrer");
        }
      }}
    >
      <div
        className="w-full min-h-40 relative bg-white overflow-hidden"
        style={{
          borderRadius: 10,
          boxShadow: `1px 1px 10px 0px rgba(0,0,0,0.6)`,
        }}
      >
        <div className="w-full h-40 relative overflow-hidden">
          <img
            src={item.imageUrl ? item.imageUrl : knotSm}
            loading="lazy"
            alt={item.nameOfTool ?? "empty Image"}
            className="h-40 w-full object-cover advanceToolImage"
          />

          <div className="w-full absolute p-2 bottom-0 bg-white/75">
            <div className="w-full flex flex-col justify-start items-start">
              <div className="w-full h-full flex flex-row justify-between items-center">
                <span className="text-sm font-bold break-all">
                  {item.subjectArea}
                </span>
                <FreeIcon className="w-6 h-6 mr-2 ml-8" />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col justify-start items-startbg-white px-2">
          <span className="text-md pt-2 pb-4 break-words font-bold text-text_gray">
            {" "}
            {item.nameOfTool}{" "}
          </span>
          <span className="text-sm break-words">
            {" "}
            {item.toolExplanation?.trim()}{" "}
          </span>
        </div>
        <div className="w-full flex flex-col justify-start items-start p-2">
          <div className="flex justify-start items-center">
            <LearningObjectIcon className="w-4 h-4 mr-2" />
            <span className="text-sm break-words">
              {capitalize(item.learningObjects)}
            </span>
          </div>
          <div className="flex justify-start items-center">
            <PurposeIcon className="w-4 h-4 mr-2" />
            <span className="text-sm break-words">
              {capitalize(item.purpose)}
            </span>
          </div>
          <div className="flex justify-start items-center">
            <RequirementsIcon className="w-4 h-4 mr-2" />
            <span className="text-sm break-words">
              {capitalize(item.requirements)}
            </span>
          </div>
          <div className="flex justify-start items-center">
            <KeywordsIcon className="w-4 h-4 mr-2" />
            <span className="text-sm break-words">
              {capitalize(item.keywords)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceTools);

const dataOpts = {
  learningObjects: {
    title: "Learning Object type",
    options: [
      "images",
      "videos",
      "interactive content",
      "games",
      "arts and crafts",
      "music",
      "observational",
      "experimental",
      "books",
      "activities",
      "memory",
      "audio books",
      "card games",
      "reading",
      "quiz",
      "assignments",
      "presentation",
      "multimedia creations",
    ],
  },
  subjectArea: {
    title: "Subject Area",
    options: [],
  },
  purpose: {
    title: "Purpose",
    options: [],
  },
  cost: {
    title: "Cost",
    options: ["free", "premium"],
  },
  requirements: {
    title: "Requirements",
    options: [],
  },
  tutorialAvailable: {
    title: "Tutorial Available",
    options: [true, false],
  },
};
