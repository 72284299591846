import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import Swotbutton from "./buttons/swotbutton";


const Uploader = ({
  showButton,
  buttonText,
  dropzoneStyle,
  dropzoneClassName,
  uploadCallback,
  children,
}) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        uploadCallback(file);
      });
    },
    [uploadCallback]
  );
  const {
    open,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, noClick: true, noKeyboard: true });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...dropzoneStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept, dropzoneStyle]
  );

  return (
    <div {...getRootProps({ style })} className={dropzoneClassName}>
      <input {...getInputProps()} />
      {isDragActive ? <p>Drop the files here ...</p> : <>{children}</>}
      {showButton && (
        <div className="absolute right-0 bottom-0 pl-3 pt-3 mr-2">
          <Swotbutton onClick={open} text={buttonText} />
        </div>
      )}
    </div>
  );
};

export default Uploader;

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
};

const activeStyle = {
  borderColor: "#2196f3",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  alignItems: "center",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};
