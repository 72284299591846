import React, { useEffect, useState, CSSProperties } from "react";
import commonColor from "../../../theme/commonColor";
import FooterView from "../../common/footerView";
import clientManagement from "../../../config/contentfulManagement";
import { useCSVReader } from 'react-papaparse';
import { Line } from 'rc-progress';
import Swal from "sweetalert2";

const ImporterDashboard = () => {
    const { CSVReader } = useCSVReader();

    const [space, setSpace] = useState();
    const [environment, setEnvironment] = useState();
    const [publish, setPublish] = useState(false);
    const [inprogress, setInprogress] = useState(false);
    const [process, setProcess] = useState(0);


    useEffect(() => {
        const initC = async () => {
            const space = await clientManagement.getSpace('iudj7tr28avp');
            const environment = await space.getEnvironment('master');
            setSpace(space);
            setEnvironment(environment);
        };
        initC();
    }, []);


    const addingContentfulData = async (chuck) => {

        setProcess(0);
        const header = chuck[0];
        const data = chuck.slice(1, chuck.length - 1);
        console.log("Entries:", data);
        let content_model = ''


        if (header.length === 2 && header[0] === "title" && header[1] === "blogBody") {
            content_model = 'blogPost'
        }
        else if (header.length === 2 && header[0] === "title" && header[1] === "content") {
            content_model = 'post'
        }
        else if (header.length === 1 && header[0] === "title") {
            content_model = 'testModel';
        }
        else if (header.length === 2&& header[0] === "question" && header[1] === "answer") {
            content_model = 'faq';
        }
        else if (header.length === 7&& header[0] === "nameOfTool" && header[1] === "toolExplanation") {
            content_model = 'tools';
        }
        else {
            Swal.fire({ title: "Failed!", text: "Not able to find content type", icon: "error", confirmButtonText: "OK" });
            return;
        }


        Swal.fire({
            title: "Record Loaded!",
            text: `${data.length} Entries of ${content_model} loaded! do you want it to upload to contentful?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
        }).then(async (e) => {
            console.log(e.dismiss);
            if (e.dismiss !== "cancel") {
                setInprogress(true);
                let processItem = 0;
                for await (const row of data) {
                    const entry = await addEntry(row, content_model);
                    console.log(entry);

                    if (publish) {
                        entry.publish();
                    }
                    processItem++;
                    console.log((processItem / data.length) * 100);
                    setProcess((processItem / data.length) * 100);
                }
                setInprogress(false);
                Swal.fire({ title: "Success!", text: "Upload completed!", icon: "success", confirmButtonText: "OK" });
            }

        });

    }



    async function addEntry(row, content_model) {

        let fields = {};
        if (content_model === 'testModel') {
            fields = {
                title: {
                    'en-US': row[0]
                }
            }
        }

        else if (content_model === 'faq') {

            fields = {
                "question": {
                    "en-US": row[0]
                  },
                  "answer": {
                    "en-US": [
                        row[1]
                    ]
                  }
            }
        
        }

        else if (content_model === 'post') {
            fields = {
                title: {
                    'en-US': row[0],
                },
                content: {
                    "en-US": {
                        "data": {
                        },
                        "content": [
                            {
                                "data": {
                                },
                                "content": [
                                    {
                                        "data": {
                                        },
                                        "marks": [
                                        ],
                                        "value": row[1],
                                        "nodeType": "text"
                                    }
                                ],
                                "nodeType": "paragraph"
                            }

                        ],
                        "nodeType": "document"
                    }
                },
            }

        }

        else if (content_model === 'blogPost') {
            fields = {
                title: {
                    'en-US': row[0],
                },
                blogBody: {
                    "en-US": {
                        "data": {
                        },
                        "content": [
                            {
                                "data": {
                                },
                                "content": [
                                    {
                                        "data": {
                                        },
                                        "marks": [
                                        ],
                                        "value": row[1],
                                        "nodeType": "text"
                                    }
                                ],
                                "nodeType": "paragraph"
                            }

                        ],
                        "nodeType": "document"
                    }
                },
            }

        }
        else if(content_model === "tools"){
            fields = {
                  "nameOfTool": {
                    "en-US": row[0]
                  },
                  "toolExplanation": {
                    "en-US": row[1]
                  },
                  "keywords": {
                    "en-US": row[2]
                  },
                  "appUrl": {
                    "en-US": row[3]
                  },
                  "tutorialAvailable": {
                    "en-US":  (row[4] === 'TRUE')
                  },
                  "featured": {
                    "en-US": (row[5] === 'TRUE')
                  },
                  "isPaid": {
                    "en-US": (row[6] === 'TRUE')
                  }
            }

        }
        else {
            return;
        }


        return await environment.createEntry(content_model, {
            fields: fields
        }).catch(e => {
            console.log(e);
            Swal.fire({ title: "Failed!", text: "Model validation issue", icon: "error", confirmButtonText: "OK" })
        });
    }


    return (
        <div
            className="w-full rounded-2xl p-4 h-full flex flex-col">
            {/*
            <div className="flex justify-between items-middle">
                <div className="text-white">Data Importer Dashboard</div>
                <button
                    className="border-white p-4 border-2 text-white rounded-2xl focus:outline-none"
                    onClick={() => {
                        // alert("Hello")
                        testContentful();
                    }}
                >
                    Refresh
                </button>
            </div>
                */}

            <div className="p-4 flex flex-col flex-grow">
                <div className="text-white">Import Contentful Data</div>
                {!inprogress &&
                    <div className="flex">
                        <div className="flex-auto">
                            <CSVReader
                                onUploadAccepted={(results) => {
                                    addingContentfulData(results.data);
                                }}
                            >
                                {({
                                    getRootProps,
                                    acceptedFile,
                                    ProgressBar,
                                    getRemoveFileProps,
                                }) => (
                                    <>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            marginBottom: 10,
                                        }}>
                                            <button type='button' {...getRootProps()} style={{
                                                background: '#fa005e',
                                                color: 'white',
                                                width: '20%'
                                            }}>
                                                Browse file
                                            </button>
                                            <div style={{
                                                border: '1px solid #ccc',
                                                height: 45,
                                                lineHeight: 2.5,
                                                paddingLeft: 10,
                                                width: '80%',
                                                background: 'white',
                                                color: 'black'
                                            }}>
                                                {acceptedFile && acceptedFile.name}
                                            </div>
                                            <button {...getRemoveFileProps()} style={{
                                                borderRadius: 0,
                                                padding: '0 20px',
                                                background: 'red',
                                                color: 'white'
                                            }}>
                                                Remove
                                            </button>
                                        </div>
                                        <ProgressBar style={{
                                            backgroundColor: 'red',
                                        }} />
                                    </>
                                )}
                            </CSVReader>
                        </div>

                        <div className="flex-auto">
                            <div style={{ width: '80px', padding: '5px' }}>
                                <b style={{ color: 'white' }}> Publish</b> <input type="checkbox" defaultChecked={publish} onChange={() => setPublish(!publish)} />
                            </div>
                        </div>
                    </div>}
                {inprogress && <Line percent={process} strokeWidth={2} strokeColor="#fa005e" />}
                <FooterView />
            </div>
        </div>
    );
};

export default ImporterDashboard;