import React, { useState } from "react";
import Swotbutton from "../../components/common/buttons/swotbutton";
import commonColor from "../../theme/commonColor";
import ResponsiveCard from "../common/ResponsiveCard";

const LandingCard = ({
  title,
  content,
  contentComponent,
  image,
  buttonsHorizontal,
  buttonStyles,
  buttons,
  imageOptions,
  onButtonClick,
}) => {
  const imageSrc = image;

  return (
    <div className="flex flex-col w-full 2k:w-1/4 xl:w-1/3 mb-10 lg:max-w-3/5 max-w-3xl">
      <div>
        <span className="text-center align-text-top md:text-base text-sm text-gray-700 h-12">
          {title}
        </span>
      </div>
      <ResponsiveCard
        className="md:max-w-1/2 lg:max-w-3xl md:self-center lg:self-auto  justify-start"
        calculateHeight={calculateHeight}
      >
        {/* <div className={`flex flex-col flex-1 py-2 lg:px-4 sm:px-10 px-6`}> */}
        <div
          className={`flex flex-col flex-1 py-2 lg:px-4 sm:px-2 px-6 h-full `}
        >
          {/* <div className="flex flex-1 flex-col 2xl:px-8 xl:p-4 px-4 xl:pb-0 md:pb-0 pb-0 leading-4 text-gray-700 md:text-sm text-xs justify-start"> */}
          <div className="flex flex-1 flex-col px-4 xl:p-0 2xl:px-8 xl:pb-0 md:pb-0 pb-0 leading-4 text-gray-700 md:text-sm text-xs justify-start m-auto">
            {contentComponent ? (
              contentComponent
            ) : (
              <span>
                <NewlineText text={content.paragraph} />
              </span>
            )}
            {content.checklist && (
              <div className="py-2 w-full">
                {content.checklist &&
                  content.checklist.items.map((e, i) => (
                    <div key={i} className="flex items-center w-full py-1">
                      <span
                        className="text-xl pr-1"
                        style={{
                          fontFamily: "emoji",
                          color: content.checklist.color,
                        }}
                      >
                        &#10003;
                      </span>
                      {e}
                    </div>
                  ))}
              </div>
            )}
          </div>
          <div
            className={`flex  flex-col justify-end items-center mb-1 pb-5 ${
              buttonsHorizontal === true ? "lg:flex-row lg:justify-between" : ""
            }`}
            style={{
              marginBottom: "-1.5rem",
            }}
          >
            {buttons &&
              buttons.map((button, idx) => (
                <div key={idx} className="pb-4 pt-2 w-full text-center">
                  {button.icon && !button.to ? (
                    <button
                      className="shadow-lg py-1 px-8 text-xs hover:shadow-outline min-w-24 sm:min-w-1/2 md:min-w-2/5 lg:min-w-2/5 xl:min-w-1/4
                    focus:outline-none"
                      style={{
                        ...{
                          borderRadius: "1.5rem",
                          color: "white",
                          backgroundColor: button.color || commonColor.deepPink,
                          textAlign: "center",
                          // boxShadow: shadow
                          //   ? `5px 5px 8px 2px rgba(0, 0, 0, 0.6)`
                          //   : "none",
                        },
                        ...(buttonStyles ?? {}),
                      }}
                      onClick={() => {
                        onButtonClick && onButtonClick(button);
                      }}
                    >
                      <div
                        className="flex flex-col items-center justify-start"
                        style={{ textAlign: "center" }}
                      >
                        <img
                          src={button.icon}
                          alt="btnIcon"
                          style={{
                            height: "1.5rem",
                          }}
                        />
                      </div>
                    </button>
                  ) : (
                    <Swotbutton
                      to={button.to}
                      text={button.text ?? ""}
                      icon={button.icon}
                      style={buttonStyles}
                      bgColor={button.color || commonColor.deepPink}
                      onClick={button.onclick}
                    >
                      {/* {button.icon && (
                        <img
                          src={button.icon}
                          style={{
                            height: "1.5rem",
                          }}
                        />
                      )} */}
                    </Swotbutton>
                  )}
                </div>
              ))}
          </div>
        </div>
      </ResponsiveCard>
    </div>
  );
};

export default LandingCard;

export const NewlineText = (props) => {
  let text = props.text;
  return text.split("\n").map((str) => <p>{str}</p>);
};

export const calculateHeight = (size) => {
  let w = size.width;
  let heightCalculated = 0;
  if (window.innerWidth >= 2048) {
    // 2k
    heightCalculated = 600 + Math.floor((window.innerWidth - 1680) / 40) * 10;
  } else if (window.innerWidth > 1680) {
    // 2.5 xl
    heightCalculated = 625 + Math.floor((window.innerWidth - 1680) / 30) * 10;
  } else if (window.innerWidth >= 1536) {
    // 2xl
    heightCalculated = 580 + Math.floor((window.innerWidth - 1536) / 30) * 10;
  } else if (window.innerWidth >= 1280) {
    // xl
    heightCalculated = 610 - Math.floor((window.innerWidth - 1280) / 64) * 10;
  } else if (window.innerWidth >= 1024) {
    // lg
    heightCalculated = 560 + Math.floor((1280 - window.innerWidth) / 33) * 10;
  } else {
    w = 0;
    heightCalculated = 0;
  }
  return heightCalculated > 800 ? 800 : heightCalculated;
};
