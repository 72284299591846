import { useState, useEffect } from "react";
import { connect } from "react-redux";
import UserModel from "../redux/redux-models/reduxUser";
import { getH5pContentById } from "../services/api";
import ContentViewComponent from "./components/ContentViewComponent";
import { IContentListEntry } from "./services/ContentService";

// import HelmetMetaData from "../../components/common/share/HelmetMetaData";

const H5pDetail = ({ contentId }: {user: UserModel, contentId: String}) => {
  //TODO: read params. If user is loggedIn, show the loggedIn version of blog page
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  const [content, setContent] = useState<IContentListEntry>();

  useEffect(() => {
   if (contentId) {
      //TODO: fetch content from server
      fetchData(contentId);
    }
  }, [contentId]);

  const fetchData = async (contentId) => {
    const result = await getH5pContentById(contentId);
    const data = result?.data?.data;
    console.log("H5P Data:", contentId, data);
    if (data) {
      setLoading(false);
      setContent({
        contentId: contentId,
        mainLibrary: data.libraryName,
        title: data.title
      });
    }
  };

  return (
    <div className="w-full h-full flex flex-col" style={{ padding: "15px" }}>
      {/* <div className="w-full flex">
        <Swotbutton text="Back" />
      </div> */}

      {content && (
        <div className="w-full flex flex-col overflow-y-scroll">
          <ContentViewComponent data={content} />
        </div>
      )}
      {loading && <div>Loading.. Please wait..</div>}

      {errorMessage && <div>{errorMessage}</div>}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = () => ({
  //   saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(H5pDetail);
