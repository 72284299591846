import React from "react";

const Circle = (props) => {
  var circleStyle = {
    padding: props.padding ?? 2,
    margin: props.margin ?? 4,
    display: "inline-block",
    // position:'absolute',
    backgroundColor: props.bgColor,
    borderRadius: "50%",
    width: props.radius ?? 15,
    height: props.radius ?? 15,
    left: 0,
    top: 0,
  };
  return <div style={circleStyle}></div>;
};

export default Circle;
