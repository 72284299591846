import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Select from "../../components/common/input/select";
import Checkbox from "../../components/common/input/checkbox";
import {
  getContentTypeOptions,
  findTutors,
  getFeaturedTutors,
} from "../../services/api";

import MainPageWithMenu from "../../components/common/MainPageTemplate/mainPageWithMenu";
import Swotbutton from "../../components/common/buttons/swotbutton";
import knotSm from "../../assets/images/learn-small.png";
import readingBook from "../../assets/images/reading-book.png";
import { languagesOpts } from "../../config/languages";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { css } from "@emotion/react";
import FooterView from "../../components/common/footerView";
import UserActions from "../../redux/user";

import CustomFadeLoader from "../../components/common/CustomFadeLoader";
import EmptyNewsMessage from "../../components/tutor/tools/emptyNewsMessage";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import bg1 from "../../assets/images/tutorBGImages/1.png";
import bg2 from "../../assets/images/tutorBGImages/2.png";
import bg3 from "../../assets/images/tutorBGImages/3.png";
import bg4 from "../../assets/images/tutorBGImages/4.png";
import bg5 from "../../assets/images/tutorBGImages/5.png";
import bg6 from "../../assets/images/tutorBGImages/6.png";
import bg7 from "../../assets/images/tutorBGImages/7.png";
import { ACCOUNT_TYPES } from "../../config/constants";
import { updateUser } from "../../services/userManagement";

const courseConductOptions = [
  { value: "online", label: "Online" },
  { value: "offline", label: "Offline" },
  { value: "hybrid", label: "Hybrid" },
  // { value: "asynchronous", label: "Asynchronous" },
  // { value: "face-2-face", label: "Face to face" },
];

const tutoringContentTypeOpts = [
  { value: "curriculum", label: "School Subjects" },
  { value: "extra curriculum", label: "Skill Training" },
];
const override = css`
  display: block;
  margin: 2rem auto;
  border-color: white;
`;

const SearchTutors = ({ user, saveUser }) => {
  const navigate = useNavigate();

  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#ffffff");

  // Images
  const [bgImages, setBgImages] = useState([
    { img: bg1 },
    { img: bg2 },
    { img: bg3 },
    { img: bg4 },
    { img: bg5 },
    { img: bg6 },
    { img: bg7 },
  ]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1690,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },

      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  const [tutoringContentType, setTutoringContentType] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [contentTypeMaterial, setContentTypeMaterial] = useState({});
  const [isSpecialEdu, setisSpecialEdu] = useState(false);
  const [subjectAreas, setSubjectAreas] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [subjectAreaOptions, setSubjectAreaOptions] = useState([]);
  const [courseName, setCourseName] = useState();
  const [tutorName, setTutorName] = useState();
  const [language, setLanguage] = useState([]);
  const [rate, setRate] = useState(null);
  const [matchingTutors, setMatchingTutors] = useState([]);
  const [featuredTutors, setFeaturedTutors] = useState([]);
  // const [courseConduct, setCourseConduct] = useState("online");
  const [courseConduct, setCourseConduct] = useState([]);
  const [searchClicked, setSearchClicked] = useState(false);
  const [dataForContentType, setDataForContentType] = useState({});

  useEffect(() => {
    const getData = async (contentType) => {
      const data = await getDataForContentType(contentType);
      var contentTypes = dataForContentType;
      contentTypes[contentType.value] = data;
      setDataForContentType(contentTypes);
      if (
        (!tutoringContentType && contentType.value === "curriculum") ||
        tutoringContentType.value === contentType.value
      ) {
        setContentTypeMaterial(data ? data : {});
        displaySubjects(data ? data : {});
      }
    };
    if (tutoringContentType && dataForContentType[tutoringContentType.value]) {
      setContentTypeMaterial(
        dataForContentType[tutoringContentType.value]
          ? dataForContentType[tutoringContentType.value]
          : {}
      );
      displaySubjects(
        dataForContentType[tutoringContentType.value]
          ? dataForContentType[tutoringContentType.value]
          : {}
      );
    }
    if (!tutoringContentType || !dataForContentType["curriculum"]) {
      getData({ value: "curriculum" });
    }
    if (!tutoringContentType || !dataForContentType["extra curriculum"]) {
      getData({ value: "extra curriculum" });
    }
  }, [tutoringContentType]);

  useEffect(() => {
    displaySubjectAreas(subjects);
  }, [subjects]);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const data = await fetchFeaturedTutors();
      setLoading(false);
      setFeaturedTutors(data ? data : []);
    };
    getData();
  }, []);

  const getDataForContentType = async (contentType) => {
    // Call BE for content subjets, areas
    let response = await getContentTypeOptions(
      contentType ? contentType.value === "curriculum" : true
    );
    // save to local state

    if (response && response.ok) {
      //setContentTypeMaterial(response.data)
      return response.data;
    } else {
      return null;
    }
  };

  const fetchFeaturedTutors = async () => {
    const data = await getFeaturedTutors();
    const tutors = data?.data?.data;

    if (tutors) {
      return [
        { type: "Featured Tutors", tutors: tutors.featured ?? [] },
        { type: "New Tutors", tutors: tutors.recent ?? [] },
      ];
    } else {
      return null;
    }
  };

  const displaySubjects = (data) => {
    let subjectOptions = [];
    let chosenSubjectAreaOptions = [];

    if (!data.subjects) {
      return;
    }

    for (const [key, value] of Object.entries(data.subjects)) {
      //console.log(`displaySubjects - found subjects ${key}:`, value);
      const subjectAreaOptions = value.areas.map((area) => {
        return { value: area, label: area };
      });
      //console.log(`displaySubjects - subjectAreaOptions:`, subjectAreaOptions);
      subjectOptions.push({ value: key, label: key });
      let subjectAreas = {
        label: key,
        options: subjectAreaOptions,
      };
      chosenSubjectAreaOptions.push(subjectAreas);
    }
    setSubjectOptions(subjectOptions);
    //setSubjectAreaOptions(chosenSubjectAreaOptions);
  };

  const displaySubjectAreas = (subjects) => {
    console.log(
      "displaySubjectAreas - subjects:",
      subjects,
      contentTypeMaterial
    );

    let chosenSubjectAreaOptions = [];
    subjects.forEach((sub) => {
      const subject = contentTypeMaterial.subjects[sub.value];
      // console.log("displaySubjectAreas - selected subject:", sub, subject);
      if (subject) {
        let areaOptions = contentTypeMaterial.subjects[sub.value].areas.map(
          (area) => {
            return { value: area, label: area };
          }
        );
        let subjectAreas = {
          label: sub.value,
          options: areaOptions,
        };
        chosenSubjectAreaOptions.push(subjectAreas);
      }
    });

    setSubjectAreaOptions(chosenSubjectAreaOptions);
  };

  const displayMatchingTutor = () => {
    return (
      <>
        <div>
          <p className="text-white  pl-24 text-lg">Search Results</p>
        </div>
        <div className="w-full flex flex-row justify-center items-start gap-2 flex-wrap p-4">
          <div className="container w-11/12 lg:w-10/12 2xl:w-11/12 ">
            {console.log("this is slider")}
            <Slider {...settings}>
              {console.log(
                "population search result of",
                matchingTutors.length
              )}
              {matchingTutors.map((tut, tidx, bgImage) => {
                console.log("map: ", tut, tidx);

                return <Article key={tut.firebaseId} tutor={tut} />;
              })}
            </Slider>
          </div>
        </div>
      </>
    );
  };

  const displayFeaturedTutors = () => {
    const hasSearch =
      subjectAreas &&
      subjectAreas.length > 0 &&
      rate &&
      isSpecialEdu &&
      courseConduct;
    if (hasSearch || !featuredTutors || featuredTutors.length <= 0) {
      return (
        <div className="self-center italic text-sm text-center">
          No tutors found yet.
        </div>
      );
    }

    return (
      <div className="w-full h-full">
        {featuredTutors.map((value, index) => {
          return (
            <div
              key={index}
              className="w-full flex flex-col items-center sm:items-start justify-start gap gap-4 pt-8 mb-2"
            >
              <span className="text-white sm:pl-24 pl-0 text-lg">
                {value.type}
              </span>
              {/* <div className="w-full flex flex-row items-center justify-start overflow-x-scroll gap-x-4"> */}

              <div className="w-full flex flex-row items-center justify-center gap-x-4 flex-wrap">
                <div className="container w-11/12 lg:w-10/12 2xl:w-11/12 ">
                  <Slider {...settings}>
                    {value.tutors.map((tutor, idx, bgImage) => {
                      return (
                        <Article key={idx} tutor={tutor} featured={true} />
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <MainPageWithMenu user={user} menuSelected={"findTutors"}>
      <div className="w-full h-full flex flex-col justify-start">
        {!user.quizDone ? (
          <div className="w-full h-full flex flex-col justify-center items-center">
            <div
              className="bg-white p-5 w-full max-w-screen-sm h-full lg:max-h-1/2 flex flex-col justify-center"
              style={{
                boxShadow: `0 10px 25px 0 rgba(0, 0, 0, 0.6)`,
                borderRadius: 25,
                //height: '70%',
              }}
            >
              <div className="w-full h-full flex flex-col justify-center items-center">
                <div className="text-center">
                  Please do the quiz to help us match you with great tutors.
                </div>
                <Swotbutton
                  text="Take Quiz"
                  onClick={async ()  =>  {
                    if (user.accountType === ACCOUNT_TYPES.SWOT_BUDDY) {
                      const updatedUser = {
                        ...user,
                        swotBuddySetup: "personalized",
                      };
                      saveUser(updatedUser);
                      await updateUser(updatedUser, true);
                    }
                    navigate("/dashboard");
                  }}
                />
              </div>
            </div>
            <FooterView />
          </div>
        ) : (
          <div className="w-full h-full flex flex-col justify-start overflow-y-scroll">
            <div
              className="bg-white p-5 w-full max-w-screen-sm flex flex-col justify-start self-center"
              style={{
                boxShadow: `0 10px 25px 0 rgba(0, 0, 0, 0.6)`,
                borderRadius: 25,
                //height: '70%',
              }}
            >
              <div className="w-full flex flex-col items-center justify-start">
                <div className="w-full flex flex-col justify-around">
                  <div className="text-center mb-2">
                    Find the tutor that matches your needs
                  </div>

                  <div className="flex flex-col justify-start items-center p-2">
                    <div className="w-full flex flex-row justify-center pb-1.5">
                      <div className="text-sm w-1/3 flex flex-row items-center">
                        {"Course Type"}
                      </div>
                      <div className="w-2/3 flex flex-row justify-start px-2">
                        <Select
                          placeholder={"Select"}
                          className="w-full text-sm"
                          closeMenuOnSelect={true}
                          defaultValue={tutoringContentTypeOpts[0]}
                          value={
                            tutoringContentType
                              ? tutoringContentType
                              : tutoringContentTypeOpts[0]
                          }
                          onChange={(value) => {
                            console.log("selected subjects:", value);
                            setTutoringContentType(value);
                            //getDataForContentType(value)

                            // whenever we change tutoringContentType we need to reset other dropdowns
                            setSubjects([]);
                            setSubjectAreas([]);
                          }}
                          options={tutoringContentTypeOpts}
                        />
                      </div>
                    </div>

                    <div className="w-full flex flex-row justify-center pb-1.5">
                      <div className="text-sm w-1/3 flex flex-row  items-center">
                        {"Subjects"}
                      </div>
                      <div className="w-2/3 flex flex-row justify-start px-2">
                        <Select
                          placeholder={"Select"}
                          className="w-full text-sm"
                          defaultValue={subjects}
                          closeMenuOnSelect={true}
                          value={subjects}
                          onChange={(value) => {
                            console.log("selected subjects:", value);
                            setSubjects(value ? [value] : []);
                            setSubjectAreas([]);
                            const subjectsArray = value
                              ? [value].map((x) => x.value)
                              : [];
                            console.log("subject to be saved:", subjectsArray);
                            // handleChange('subjects', subjectsArray)
                          }}
                          //isMulti
                          options={subjectOptions}
                        />
                      </div>
                    </div>

                    <div className="w-full flex flex-row justify-center pb-1.5">
                      <div className="text-sm w-1/3 flex flex-row  items-center">
                        {"Subject Areas"}
                      </div>
                      <div className="w-2/3 flex flex-row justify-start px-2">
                        <Select
                          placeholder={"Select"}
                          className="w-full text-sm"
                          defaultValue={subjectAreas}
                          value={subjectAreas}
                          options={subjectAreaOptions}
                          isFormatGroupLabelUsed
                          // isMulti
                          closeMenuOnSelect={true}
                          onChange={(value) => {
                            console.log("selected subject Areas:", value);
                            setSubjectAreas([value]);
                          }}
                        />
                      </div>
                    </div>

                    <div className="w-full flex flex-row justify-center pb-1.5">
                      <div className="text-sm w-1/3 flex flex-row items-center">
                        {"Course conduct"}
                      </div>
                      <div className="w-2/3 flex flex-row justify-start px-2">
                        <Select
                          placeholder={"Select"}
                          className="w-full text-sm"
                          value={courseConduct}
                          //value={courseConductOptions[0]}
                          onChange={(value) => {
                            console.log("selected course conduct:", value);
                            setCourseConduct(value);
                          }}
                          options={courseConductOptions}
                        />
                      </div>
                    </div>

                    <div className="w-full flex flex-row justify-center pb-1.5">
                      <div className="text-sm w-1/3 flex flex-row items-center">
                        {"Course Name"}
                      </div>
                      <div className="w-2/3 flex flex-row justify-start px-2">
                        <input
                          className="w-full bg-white border-[1px] appearance-none border-gray-300 focus:outline-none focus:border-opacity-100 py-2 px-2 text-sm"
                          id="coursename"
                          name="coursename"
                          type="text"
                          placeholder={"Course name"}
                          value={courseName ?? ""}
                          onChange={(event) =>
                            setCourseName(event.target.value)
                          }
                        />
                      </div>
                    </div>

                    <div className="w-full flex flex-row justify-center pb-1.5">
                      <div className="text-sm w-1/3 flex flex-row items-center">
                        {"Tutor Name"}
                      </div>
                      <div className="w-2/3 flex flex-row justify-start px-2">
                        <input
                          className="w-full bg-white border-[1px] appearance-none border-gray-300 focus:outline-none focus:border-opacity-100 py-2 px-2 text-sm"
                          id="tutorname"
                          name="tutorname"
                          type="text"
                          placeholder={"Tutor name"}
                          value={tutorName ?? ""}
                          onChange={(event) => setTutorName(event.target.value)}
                        />
                      </div>
                    </div>

                    <div className="w-full flex flex-row justify-center pb-1.5">
                      <div className="text-sm w-1/3 flex flex-row items-center">
                        {"Course Language"}
                      </div>
                      <div className="w-2/3 flex flex-row justify-start px-2">
                        {/* <input
                          className="w-full bg-white appearance-none border-2 focus:outline-none focus:border-opacity-100 py-1 px-2"
                          id="courseLanguage"
                          name="courseLanguage"
                          type="text"
                          placeholder={"Course language"}
                          value={language}
                          onChange={(event) => setLanguage(event.target.value)}
                        /> */}
                        <Select
                          className="w-full text-sm"
                          placeholder={"Select"}
                          value={language}
                          onChange={(value) => {
                            setLanguage(value);
                          }}
                          options={languagesOpts}
                        />
                      </div>
                    </div>

                    <div className="w-full flex flex-row justify-center pb-1.5">
                      <div className="text-sm w-1/3 flex flex-row  items-center">
                        {"Max Hourly Rate (in $)"}
                      </div>
                      <div className="w-2/3 flex flex-row justify-start px-2">
                        <input
                          className="w-full bg-white border-[1px] border-gray-300 appearance-none focus:outline-none focus:border-opacity-100 py-2 px-2 text-sm"
                          id="rate"
                          name="rate"
                          type="number"
                          min="1"
                          placeholder="rate"
                          value={rate ?? ""}
                          onChange={(event) => {
                            event.target.value = Math.abs(event.target.value);
                            setRate(event.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="w-full flex flex-row justify-center pt-2">
                      <div className="text-sm w-1/3 flex flex-row  items-center">
                        {"Special Education"}
                      </div>
                      <div className="w-2/3 flex flex-row justify-start px-2 py-1">
                        <Checkbox
                          isSelected={isSpecialEdu}
                          onCheckboxChange={(changeEvent) => {
                            setisSpecialEdu(!isSpecialEdu);
                            // handleChange("specialEducation", !isSpecialEdu)
                          }}
                          key={"isSpecialEdu"}
                        />
                      </div>
                    </div>

                    <div className="w-full flex justify-end">
                      <Swotbutton
                        className="min-w-24"
                        text={"Search"}
                        // disabled={subjectAreas.length === 0}
                        shadow={true}
                        onClick={async () => {
                          let subject = "";
                          if (subjects.length > 0) subject = subjects[0].value;

                          let selectRate = rate;
                          if (rate == null || rate == "") selectRate = -1;

                          const payload = {
                            subjectArea: subjectAreas[0]?.value || "",
                            preferredRate: selectRate,
                            specialEducation: isSpecialEdu,
                            registration: courseConduct?.value,
                            courseName: courseName?.trim(),
                            tutorName: tutorName?.trim(),
                            language: language.value,
                            subject: subject,
                            subjectType: tutoringContentType.value,
                          };
                          console.log("Find tutors!", payload);
                          // call API
                          setLoading(true);
                          let response = await findTutors(user.uid, payload);
                          setLoading(false);
                          // save to local state
                          console.log(
                            "getDataForContentType - response",
                            response
                          );
                          if (response && response.ok) {
                            setSearchClicked(true);
                            //setContentTypeMaterial(response.data)
                            const fetchedTutors = response.data?.tutors;

                            //setMatchingTutors(mockTutors);

                            setMatchingTutors(fetchedTutors);
                          } else {
                            return null;
                          }
                        }}
                      />{" "}
                      {searchClicked && (
                        <Swotbutton
                          style={{
                            marginLeft: "10px",
                          }}
                          bgColor="gray"
                          text={"Clear"}
                          onClick={() => {
                            setSearchClicked(false);
                            setMatchingTutors([]);
                            setSubjects([]);
                            setisSpecialEdu(false);
                            setSubjectAreas([]);
                            setCourseName("");
                            setTutorName("");
                            setLanguage([]);
                            setTutoringContentType([]);
                            setCourseConduct([]);
                            setRate("");
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {loading ? (
              <div className="w-full flex justify-center items-center mt-4">
                <CustomFadeLoader loading={loading} color="#FFFFFF" />
              </div>
            ) : (
              <div className="w-full flex flex-col justify-start items-start p-2">
                {!matchingTutors || matchingTutors.length === 0 ? (
                  // <div className="self-center italic text-sm text-center">
                  //   No tutors found yet.
                  // </div>
                  <div className="w-full flex flex-col justify-start items-start p-2">
                    {searchClicked ? (
                      <div className="w-full flex flex-col justify-start items-center">
                        {/* <p className="text-white">No results found</p> */}
                        <div
                          className="rounded overflow-hidden shadow-lg bg-white w-full max-w-screen-sm flex flex-col justify-start self-center"
                          style={{
                            boxShadow: `0 10px 25px 0 rgba(0, 0, 0, 0.6)`,
                            borderRadius: 25,
                          }}
                        >
                          <EmptyNewsMessage />
                        </div>
                      </div>
                    ) : (
                      displayFeaturedTutors()
                    )}
                  </div>
                ) : (
                  displayMatchingTutor()
                )}
              </div>
            )}
            <FooterView />
          </div>
        )}
      </div>
    </MainPageWithMenu>
  );
};

const Article = ({ tutor }) => {
  const articleRef = useRef(null);
  let navigate = useNavigate();
  if (tutor.relatedCourses[0].videoThumb == "undefined") {
    tutor.relatedCourses[0].videoThumb = null;
  }

  useEffect(() => {
    const article = articleRef.current;
    const handleMove = (e) => {
      const { x, y, width, height } = article.getBoundingClientRect();
      const cx = x + width / 2;
      const cy = y + height / 2;
      const { pageX, pageY } = e;

      const dx = (cx - pageX) / (width / 2);
      const dy = (cy - pageY) / (height / 2);

      article.style.transform = `rotateX(${10 * dy * -1}deg) rotateY(${
        10 * dx
      }deg)`;
    };

    const handleOut = () => {
      article.style.transform = "initial";
    };

    article.addEventListener("mousemove", handleMove);
    article.addEventListener("mouseout", handleOut);

    return () => {
      article.removeEventListener("mousemove", handleMove);
      article.removeEventListener("mouseout", handleOut);
    };
  }, []);

  return (
    <div
      className="flex justify-center items-center h-[35rem] pt-7"
      style={{ perspective: "1000px" }}
      onClick={() => {
        if (
          tutor.relatedCourses &&
          tutor.relatedCourses.length > 0 &&
          tutor.relatedCourses[0].courseId
        ) {
          navigate(`/courses/${tutor.relatedCourses[0].courseId}`);
        } else {
        }
      }}
    >
      <div className="card_article" ref={articleRef}>
        <figure>
          <img
            src={tutor.photoUrl ? tutor.photoUrl : knotSm}
            loading="lazy"
            alt={tutor.username ? tutor.username : "tutor UserName Image"}
            className=""
          />
        </figure>
        <div>
          <p>
            {" "}
            <span className="px-2 text-base pt-2">
              <span className="font-semibold">Tutor Name: </span>
              <br />
              {tutor.name ? tutor.name : ""}
            </span>
          </p>

          <p>
            <span className="px-2 text-base">
              <span className="font-semibold ellipsis"> </span>
              <span className="courseNameWrap w-40 overflow-hidden text-overflow-ellipsis whitespace-nowrap">
                {tutor.relatedCourses && tutor.relatedCourses.length > 0
                  ? tutor.relatedCourses[0].courseFullName
                  : ""}
                <b>
                  {" "}
                  {tutor.relatedCourses && tutor.relatedCourses.length > 0
                    ? " (" + tutor.relatedCourses[0].courseShortName + ")"
                    : ""}
                </b>
              </span>
              <br />
            </span>
            <br />
            <span className="px-2 text-base">
              <span className="font-semibold">Hourly Rate: </span>
              <br />
              {tutor.relatedCourses && tutor.relatedCourses.length > 0
                ? tutor.relatedCourses[0].rate != "0"
                  ? tutor.relatedCourses[0].rate + "$"
                  : "free"
                : ""}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SearchTutors);
