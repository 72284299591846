import React, { useState, useEffect, useReducer } from "react";
import Swal from "sweetalert2";
import imageCompression from "browser-image-compression";
import moment from "moment";
import Dropzone from "react-dropzone";
import { arrayBufferToB64, readFile } from "../../../../../utils/fileUtils";
import { ReactComponent as VideoIcon } from "../../../../../assets/icons/video-icon.svg";
import VideoSnapshot from "video-snapshot";
import commonColor from "../../../../../theme/commonColor";

const CourseStep4 = ({
  user,
  saveProperty,
  currentStep,
  courseDetails,
  mode,
  stateData,
}) => {
  // variables:-------------------------------------------------
  const reducerStateFn = (state, action) => {
    if (action.key) {
      if (action.key === "reset") {
        return action.value;
      }
      if (action.key === "shortName") {
        // isDataValid(false);
      }
      const updatedV = { ...state, ...{ [action.key]: action.value } };
      return updatedV;
    }
  };

  const [localState, dispatchReducer] = useReducer(reducerStateFn, {});

  // initialize:--------------------------------------------
  useEffect(() => {
    updateLocalData("reset", {
      errorMessage: null,

      photoUrl: stateData?.photoUrl ?? null,
      photoFile: stateData?.photoFile ?? null,
      photoInfo: stateData?.photoInfo ?? null,
      previewUploadedPhoto: stateData?.previewUploadedPhoto ?? false,
      photoOK: stateData?.photoOK ?? false,
      photoError: stateData?.photoError ?? false,
      submissionError: stateData?.submissionError ?? false,

      videoUrl: stateData?.videoUrl ?? null,
      videoThumbnailUrl: stateData?.videoThumbnailUrl ?? null,
      videoFile: stateData?.videoFile ?? null,
      videoInfo: stateData?.videoInfo ?? null,
      videoThumbnail: stateData?.videoThumbnail ?? null,
      videoOK: stateData?.videoOK ?? false,
      videoError: stateData?.videoError ?? false,
      videoUploadError: stateData?.videoUploadError ?? false,
    });

    saveProperty("step4Completed", true);
  }, []);

  if (currentStep !== 4) {
    return null;
  }
  // functions:------------------------------------------------
  const handleChange = (key, value) => {
    saveProperty(key, value);
    // updateLocalData(key, value);
    updateLocalData(key, value);
  };

  const updateLocalData = (key, value) => {
    dispatchReducer({ key, value });
  };

  //--------Image/Video---------------------//
  const storePhoto = (photo, file) => {
    handleChange("photoInfo", { type: photo.type, path: photo.path });
    handleChange("photoFile", file);
    handleChange("photoOK", true);
  };

  const storeVideo = (video, file) => {
    handleChange("videoInfo", { type: video.type, path: video.path });
    handleChange("videoFile", file);
    handleChange("videoOK", true);
  };

  const handleCourseVideoInput = async (acceptedFiles) => {
    console.log("handleCoursePhotoInput - input files", acceptedFiles);
    const toBeUploaded = acceptedFiles[0];
    if (toBeUploaded.type.includes("video")) {
      handleChange("videoError", false);
      const imgSizeMB = toBeUploaded.size / 1024 / 1024;
      if (imgSizeMB > 30) {
        //if file is above max required file, prompt to user and return
        Swal.fire({
          title: "Max Size Limit",
          text: "You can not upload video above 30MB.",
          confirmButtonText: "OK",
        });
        return;
      }
      readFile(toBeUploaded, storeVideo);
      const snapshoter = new VideoSnapshot(toBeUploaded);
      const previewSrc = await snapshoter.takeSnapshot();
      handleChange("videoThumbnail", previewSrc);
    } else {
      handleChange("videoError", true);
      handleChange("videoOK", false);
      console.log("handleCourseVideoInput - file is NOT Photo, ERROR");
    }
  };

  const handleCoursePhotoInput = async (acceptedFiles) => {
    console.log("handleCoursePhotoInput - input files", acceptedFiles);
    let toBeUploaded = acceptedFiles[0];
    if (toBeUploaded.type.includes("image")) {
      handleChange("photoError", false);

      //compress profile if required
      const imgSize = toBeUploaded.size / 1024 / 1024;
      const maxSizeMB = 2;
      if (imgSize > maxSizeMB) {
        var options = {
          maxSizeMB: maxSizeMB,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(toBeUploaded, options);
        console.log(
          `compressed profileImage size = ${compressedFile.size / 1024 / 1024}`
        );
        compressedFile.path = toBeUploaded.path;
        toBeUploaded = compressedFile;
      }

      readFile(toBeUploaded, storePhoto);
    } else {
      handleChange("photoError", true);
      handleChange("photoOK", false);
      console.log("handleCoursePhotoInput - file is NOT Photo, ERROR");
    }
  };
  //-----------------//
  // widgets:--------------------------------------------------
  const errorWidgets = () => {
    return (
      <div className="w-full flex flex-col justify-start">
        {localState?.errorMessage && (
          <div className="text-sm bg-red-500 w-full text-white text-center mb-1">
            {localState?.errorMessage}
          </div>
        )}
      </div>
    );
  };
  //
  // main body:------------------------------------------------
  return (
    <div className="h-full flex flex-col justify-start">
      <span className="p-4 text-sm flexyar justify-center align-center">
        This is <span className="font-semibold underline">optional</span> .
        Adding a picture and/or a video, will give your students a better
        understanding of the tutor you are.
      </span>
      {errorWidgets()}
      <div className="w-full h-full flex flex-col justify-start sm:justify-center overflow-y-scroll">
        <div className="w-full flex flex-col sm:flex-row justify-center  align-center py-2">
          <div className="w-full sm:w-1/2 flex flex-col justify-center align-center px-2">
            <div className="w-full flex justify-center text-gray-700 text-sm ">
              Course Photo
            </div>
            <div className="w-full flex justify-center">
              <div className="flex flex-row  px-2 py-1 cursor-pointer">
                <Dropzone onDrop={handleCoursePhotoInput}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div
                        className="w-32 h-32 text-gray-500 flex flex-col items-center justify-center cursor-pointer focus:outline-none rounded-lg"
                        style={{
                          backgroundColor: commonColor.deepGreen,
                        }}
                        {...getRootProps()}
                      >
                        <input
                          className="focus:outline-none"
                          {...getInputProps()}
                        />
                        <div className="text-xs text-center w-full text-white">
                          {!localState?.photoOK &&
                            !localState?.photoUrl &&
                            "Upload Photo"}
                        </div>
                        {localState?.photoOK &&
                          localState?.photoFile &&
                          localState?.photoInfo ? (
                          <img
                            src={arrayBufferToB64(
                              localState?.photoFile,
                              localState?.photoInfo?.type
                            )}
                            alt={"User Course Photo"}
                            className="m-auto"
                          />
                        ) : localState?.photoUrl ? (
                          <img
                            src={localState?.photoUrl}
                            alt={"Already Uploaded Course Photo"}
                            className="m-auto"
                          />
                        ) : null}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-1/2 flex flex-col justify-center align-center px-2">
            <div className="w-full flex justify-center text-gray-700 text-sm">
              Course Video
            </div>
            <div
              className="w-full flex justify-center text-gray-700"
              style={{ fontSize: "0.65rem" }}
            >
              *upto 30MB
            </div>
            <div className="w-full flex justify-center">
              <div className="flex flex-row px-2 py-1 cursor-pointer">
                <Dropzone onDrop={handleCourseVideoInput}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div
                        className="w-32 h-32 text-gray-500 flex flex-col items-center justify-center cursor-pointer focus:outline-none rounded-lg"
                        style={{
                          backgroundColor: commonColor.deepGreen,
                        }}
                        {...getRootProps()}
                      >
                        <input
                          className="focus:outline-none"
                          {...getInputProps()}
                        />
                        <div className="text-xs text-center w-full text-white">
                          {!localState?.videoOK &&
                            !localState?.videoUrl &&
                            "Upload Video"}
                        </div>
                        {localState?.videoOK &&
                          localState?.videoFile &&
                          localState?.videoInfo ? (
                          <div className="flex justify-center items-center w-full h-full">
                            <div className="relative w-full h-full">
                              <img
                                src={localState?.videoThumbnail}
                                alt={"User Course Video"}
                                className="m-auto absolute"
                                style={{
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                }}
                              />
                              <VideoIcon
                                className="absolute"
                                // style={{ width: "90%", height: "90%" }}
                                style={{
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                }}
                              />
                            </div>
                          </div>
                        ) : localState?.videoUrl ? (
                          <div className="flex justify-center items-center w-full h-full">
                            <div className="relative w-full h-full">
                              <img
                                src={localState?.videoThumbnailUrl}
                                alt={"User Course Video"}
                                className="m-auto absolute"
                                style={{
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                }}
                              />
                              <VideoIcon
                                className="absolute"
                                // style={{ width: "90%", height: "90%" }}
                                style={{
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                }}
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseStep4;
