import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import contentful from "../../../config/contentful";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import rightArrow from "../assets/right-arrow.svg";
import leftArrow from "../assets/back.svg";
import Popup from "../components/blogPopup";
import { ReactComponent as RightArrow } from "../assets/whySwotknotSvgs/animated-right-arrow.svg";
import RenderContentfulDocument from "../../../components/common/contentful/renderDocument";
import { useTransition } from "@react-spring/web";

const NewBlogDesign = ({ user, showPopup }) => {
  let navigate = useNavigate();

  const [content, setContent] = useState([]);
  const [total, setTotal] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const pageLimit = 10;
  const [popupContent, setPopupContent] = useState(null);

  function BlogNextArrow(props) {
    const { onClick } = props;
    return (
      <button
        className="rounded-full bg-gray-50 shadow-xl drop-shadow-lg place-items-center w-10 h-10 p-3 absolute z-5 | -top-12 right-[42%] | lg:right-[44%]"
        onClick={onClick}
      >
        <img src={rightArrow} alt="Next" />
      </button>
    );
  }

  function BlogPrevArrow(props) {
    const { onClick } = props;
    return (
      <button
        className="rounded-full bg-gray-50 shadow-xl drop-shadow-lg place-items-center w-10 h-10 p-3 absolute z-5 | -top-12 right-[52%] | lg:right-[50%]"
        onClick={onClick}
      >
        <img src={leftArrow} alt="Prev" />
      </button>
    );
  }

  const settings = {
    dots: true,
    centerMode: true,
    infinite: true,
    centerPadding: "20px",
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    autoplay: false,
    autoplayspeed: 5000,
    nextArrow: <BlogNextArrow />,
    prevArrow: <BlogPrevArrow />,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          nextArrow: <BlogNextArrow />,
          prevArrow: <BlogPrevArrow />,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          nextArrow: <BlogNextArrow />,
          prevArrow: <BlogPrevArrow />,
        },
      },
    ],
  };

  useEffect(() => {
    getContent();
  }, []);

  const getContent = async () => {
    try {
      setLoadingMore(true);
      const skip = content.length;
      const response = await contentful.getEntries({
        content_type: "blogPost",
        limit: pageLimit,
        skip: skip,
        order: "-sys.createdAt", // Order by createdAt in descending order
      });

      if (response) {
        setContent([...content, ...(response.items ?? [])]);
        setTotal(response.total);
      }
    } catch (e) {
      console.log(e);
    }
    setLoadingMore(false);
  };

  const openPopup = (item) => {
    // document.body.style.overflow = "hidden";
    // setPopupContent(item);
    showPopup({
      image: item.fields.blogMedia[0]?.fields?.file?.url,
      title: item.fields?.title,
      text: item.fields?.blogBody,
    });
  };

  const closePopup = (item) => {
    // document.body.style.overflow = "auto";
    // setPopupContent(null);
    showPopup(null);
  };

  // const transitions = useTransition(popupContent, null, {
  //   from: { opacity: 0 },
  //   enter: { opacity: 1 },
  //   leave: { opacity: 0 }
  // });

  const renderContent = (items) => {
    return (
      <div className="relative">
        <Slider {...settings}>
          {items.map(function (item) {
            const blogTitle = item.fields?.title;
            const blogMediaUrl = item.fields.blogMedia[0]?.fields?.file?.url;
            const blogCreatedAt = item.fields.blogMedia[0]?.sys?.createdAt;
            const date = new Date(blogCreatedAt);
            const formattedDate = date.toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "long",
              year: "numeric",
            });

            return (
              <div
                key={item.sys.id}
                className="w-full flex flex-col items-center justify-center m-4 cursor-pointer rounded-lg shadow-md relative h-[26rem] partTen_box "
                onClick={() => openPopup(item)}
              >
                {blogMediaUrl && (
                  <div className="w-full mb-1">
                    <img
                      className="object-cover h-[11rem] w-full rounded-t-lg"
                      src={blogMediaUrl}
                      loading="lazy"
                      alt="l3arn"
                    />
                  </div>
                )}

                {formattedDate && (
                  <div className="px-3 py-2 text-sm font-semibold text-gray-400">
                    {formattedDate}
                  </div>
                )}
                {blogTitle && (
                  <div className="px-3 py-2 text-base font-semibold text-gray-500">
                    {blogTitle}
                  </div>
                )}

                <button className="rounded-xl bg-white shadow-xl place-items-center w-10 h-10 p-3 mt-2 absolute bottom-2 left-2">
                  <RightArrow className="svg-icon" alt="right_arrow_icon" />
                </button>
                {item.fields.source && (
                  <div
                    className=" flex justify-between space-x-2 items-center py-2 px-3"
                    style={{ maxHeight: "2rem" }}
                  ></div>
                )}
              </div>
            );
          })}
        </Slider>
        {/* {transitions.map(
        ({ item, key, props }) =>
          item && (
            <Popup
            image={item.fields.blogMedia[0]?.fields?.file?.url}
            title={item.fields?.title}
            text={item.fields?.blogBody}
            onClose={closePopup}
          />
          )
      )} */}
        {popupContent && (
          <Popup
            image={popupContent.fields.blogMedia[0]?.fields?.file?.url}
            title={popupContent.fields?.title}
            text={popupContent.fields?.blogBody}
            onClose={closePopup}
          />
        )}
      </div>
    );
  };

  return (
    <div className="xl:px-5 w-full h-fit flex flex-col justify-start relative">
      <div></div>
      {renderContent(content)}
    </div>
  );
};

export default NewBlogDesign;
