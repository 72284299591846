import { useEffect, useState } from "react";
import { auth, firestore, generateUserDocument } from "../config/firebase";
import { collection, doc, setDoc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { ACCOUNT_TYPES, STRIPE_REG_STATUSES } from "../config/constants";
import { saveUserOnBoardingAccount } from "./api";
import { parseLivingLocation } from "./location";
import { setUser } from "../context/user/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import UserActions from "../redux/user";

export const startOnBoarding = () => { };

export const isUserAuthenticated = () => {
  const user = auth.currentUser;
  console.log("isUserAuthenticated - auth:", user, auth);
  if (auth.currentUser) {
    console.log(
      "isUserAuthenticated - retrieved user from firebase auth:",
      user
    );
    return true;
  } else {
    // No user is signed in.
    console.log("isUserAuthenticated - No user found in firebase Auth");
    return false;
  }
};

export const fetchCurrentUser = async () => {
  const user = auth.currentUser;
  console.log("fetchCurrentUser - fetched auth user:", user);

  if (user) {
    const userInfo = await getDoc(doc(collection(firestore, "users"), user.uid));
    console.log("fetchCurrentUser - Provider:", user.providerData[0].providerId);
    userInfo.providerId = user.providerData[0].providerId;
    console.log("fetchCurrentUser - firestore user", userInfo.data());
    return userInfo;
  } else {
    return null;
  }
};

export const updateUser = async (user, merge = false) => {
  if (user) {
    await setDoc(doc(collection(firestore, "users"), user.uid), user, { "merge": merge });
    console.log("updateUser - saved firestore user", user);
    return user;
  } else {
    return null;
  }
};

export const signUpUser = async (authUser, saveUserToState, status) => {
  const newUser = {
    name: authUser.displayName ? authUser.displayName : authUser.name,
    email: authUser.email,
    photoUrl: authUser.photoUrl ? authUser.photoUrl : authUser.photoURL,
    uid: authUser.uid,
    status: status ? status : "pending",
    username: authUser.username ? authUser.username : null,
  };
  console.log("signUpUser - will create doc in firestore:", newUser);
  const firestoreUser = await generateUserDocument(newUser);
  saveUserToState && (await saveUserToState(firestoreUser));

  return firestoreUser;
};

export const onBoardUser = async (user) => {
  try {
    const userDict = { ...user };
    userDict.status = "activation-pending";

    if (userDict.accountType === ACCOUNT_TYPES.TUTOR) {
      userDict.stripeStatus = STRIPE_REG_STATUSES.NONE;
    }

    if (userDict.livingPlace) {
      const loc = parseLivingLocation(userDict.livingPlace);
      userDict.cityName = loc.cityName;
      userDict.stateCode = loc.stateCode;
      userDict.countryName = loc.countryName;
    }

    if (userDict.schoolInfo) {
      console.log("parsing schoolInfo...", userDict.schoolInfo);
      let lightInfo = {};
      lightInfo.placeId = userDict.schoolInfo.place_id;
      lightInfo.name = userDict.schoolInfo.name;

      const routes = userDict.schoolInfo.address_components.filter((x) =>
        x.types.includes("route")
      );
      lightInfo.route = routes.length > 0 && routes[0].long_name;

      const localities = userDict.schoolInfo.address_components.filter((x) =>
        x.types.includes("locality")
      );
      lightInfo.locality = localities.length > 0 && localities[0].long_name;
      // lightInfo.neighborhood = userDict.schoolInfo.address_components
      //   .filter(x => x.types.includes("neighborhood"))
      const states = userDict.schoolInfo.address_components.filter((x) =>
        x.types.includes("administrative_area_level_1") || x.types.includes("administrative_area_level_2")
      );
      lightInfo.stateName = states.length > 0 && states[0].long_name;

      const cityNames = userDict.schoolInfo.address_components.filter((x) =>
        x.types.includes("locality")
      );
      lightInfo.cityName = cityNames.length > 0 && cityNames[0].long_name;

      const countryNames = userDict.schoolInfo.address_components.filter((x) =>
        x.types.includes("country")
      );
      lightInfo.countryName =
        countryNames.length > 0 && countryNames[0].short_name;

      console.log("School - lightInfo - ", lightInfo);
      userDict.schoolInfo = lightInfo;
    }

    console.log("userDict:", userDict);

    const response = await saveUserOnBoardingAccount(userDict);

    console.log("saveUserOnBoardingAccount - response", response);

    if (response.ok) {
      await updateUser(userDict);
      return userDict;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
    //throw new Error('Error updating suggested profile')
  }
};

/*const findAddressComponent = (addComps, type) => {
  return addComps.filter((x) => x.types.includes(type));
};*/

/**
 * Custom hook to get userAuth
 */
let _authUser;
export const useUserAuth = (clearLocalUser) => {
  const [authUser, setAuthUser] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      // const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setAuthUser(authUser);
        _authUser = authUser;
      } else {
        setAuthUser(null);
        _authUser = null;
        if (clearLocalUser) {
          // clearLocalUser();
          dispatch(UserActions.saveUserRequest(null))
        }
      }
      // authUser ? setAuthUser(authUser) : setAuthUser(null);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  if (_authUser) {
    return _authUser;
  }

  return authUser;
};
