import React, { useEffect, useState } from "react";
import moment, { now } from "moment";
import { connect } from "react-redux";
import { ACCOUNT_TYPES } from "../../config/constants";

const CustomToolbar = (props) => {

const isToday = () => {
  var dateNow = new Date()
 return props.date.getDate() === dateNow.getDate() &&
  props.date.getMonth() === dateNow.getMonth() &&
  props.date.getYear() === dateNow.getYear()
}
  const goToBack = () => {
    props.onNavigate("PREV");
  };

  const goToNext = () => {
    props.onNavigate("NEXT");
  };

  const goToCurrent = () => {
    props.onNavigate("TODAY");
  };

  const label = () => {
    const date = moment(props.date);
    return (
      <span>
         <span>{moment(date).format('ddd')}</span>
        <span> {date.format("MMM")}</span>
        <b> {date.format("DD")}</b>
        {/* <span> {date.format("YYYY")}</span> */}
      </span>
    );
  };

  return (
    <div className={`w-full flex justify-between items-center flex-wrap items-wrap pb-2`}>
    {props.user && props.user.accountType === ACCOUNT_TYPES.TUTOR && <div className="mb-4 pr-4">
      <p className="text-sm italic">
      Select timeslots you are available to teach.
      </p>
    </div>}
      <div className={`flex justify-start`} style={{
        borderRadius: "4px",
        border: "1px solid #e2e8f0"
        
      }}>
        <button className={`label-date px-2 py-1`} onClick={goToBack}>
          Back
        </button>
        <button className={`btn-current px-2 py-1 ${ isToday() ? "bg-gray-300" : "bg-white"}`} onClick={goToCurrent} style={{
        borderLeft: "1px solid #e2e8f0",
        borderRight: "1px solid #e2e8f0"
      }}>
          Today
        </button>
        <button className={"btn-next px-2 py-1"} onClick={goToNext}>
          Next
        </button>
      </div>
      <div className="flex-grow flex flex-row justify-center items-center">
      <label className={"label-date"}>{label()}</label>
      </div>
    </div>
  );
};

// export default Customtoolbar;
const mapStateToProps = (state) => ({
  user: state.user.user,
});


const mapDispatchToProps = (dispatch) => ({
  // saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomToolbar);
