import { useState } from "react";
import Dropzone from "react-dropzone";
import FileViewer from "react-file-viewer";
import ReactPlayer from "react-player/lazy";

import { ReactComponent as Photofile } from "../../../assets/icons/portraits.svg";
import { ReactComponent as Resume } from "../../../assets/icons/resume-and-cv.svg";
import { ReactComponent as VideoIntro } from "../../../assets/icons/phone-camera.svg";

import { ReactComponent as Faq } from "../../../assets/icons/faq-why-we.svg";
import ReactTooltip from "react-tooltip";

import commonColor from "../../../theme/commonColor";
import { uploadFilePromise } from "../../../services/fileServices";
import { readFile } from "../../../utils/fileUtils";
import CircularProgressBar from "../../../components/common/CircularProgressBar/CircularProgressBar";
import imageCompression from "browser-image-compression";

import Swal from "sweetalert2";
import Swotbutton from "../../../components/common/buttons/swotbutton";
import { useWindowSize } from "../../../utils/uiUtils";
import Modal from "../../../components/common/modal/modal";
import WebcamWidget from "../../../components/tutor/profile/webcamWidget";

const Step3 = ({ saveProperty, currentStep, user }) => {
  const [photoError, setPhotoError] = useState(false);
  const [photoOK, setPhotoOK] = useState(false);
  const [photoUploadProgress, setPhotoUploadProgress] = useState(0);
  const [photoUploadError, setPhotoUploadError] = useState(false);
  const [resumeError, setResumeError] = useState(false);
  const [resumeUploadProgress, setResumeUploadProgress] = useState(0);
  const [resumeUploadError, setResumeUploadError] = useState(false);
  const [resumeOK, setResumeOK] = useState(false);
  const [videoUploadProgress, setVideoUploadProgress] = useState(0);
  const [videoError, setVideoError] = useState(false);
  const [videoUploadError, setVideoUploadError] = useState(false);
  const [videoOK, setVideoOK] = useState(false);
  const [tutorPhotoUrl, setTutorPhotoUrl] = useState();
  const [tutorVideo, setTutorVideo] = useState();
  const [tutorCV, setTutorCV] = useState();
  const [openRecorder, setOpenRecorder] = useState(false);
  const [width, height] = useWindowSize();
  if (currentStep !== 3) {
    return null;
  }

  const uploadPhoto = async (photo, file) => {
    // uplaod photo to firestore:
    try {
      setTutorPhotoUrl(null);
      const result = await uploadFilePromise(
        file,
        photo.type,
        photo.path,
        `profile_photo`,
        `userData/${user.uid}/profile`,
        (progress) => {
          setPhotoUploadProgress(Math.floor(progress));
        }
      );
      setPhotoUploadProgress(0);
      console.log("handleProfilePhotoInput - upload result:", result);
      setPhotoOK(true);
      setPhotoUploadError(false);
      saveProperty("photoUrl", result.downloadUrl);
      setTutorPhotoUrl(result.downloadUrl);
    } catch (e) {
      console.error("handleProfilePhotoInput - uploading error", e);
      setPhotoUploadError(true);
      setPhotoOK(false);
    }
  };

  const uploadCV = async (cv, file) => {
    try {
      setTutorCV(null);
      const result = await uploadFilePromise(
        file,
        cv.type,
        cv.path,
        `resume`,
        `userData/${user.uid}/profile`,
        (progress) => {
          setResumeUploadProgress(Math.floor(progress));
        }
      );
      setResumeUploadProgress(0);
      console.log("uploadCV - upload result:", result);
      setResumeOK(true);
      setResumeUploadError(false);
      saveProperty("resumeUrl", result.downloadUrl);
      setTutorCV(result.downloadUrl);
    } catch (e) {
      console.error("uploadCV - uploading error", e);
      setResumeUploadError(true);
      setResumeOK(false);
    }
  };

  const uploadVideo = async (video, file) => {
    try {
      setTutorVideo(null);
      const result = await uploadFilePromise(
        file,
        video.type,
        video.path,
        `videoIntro`,
        `userData/${user.uid}/profile`,
        (progress) => {
          setVideoUploadProgress(Math.floor(progress));
        }
      );

      setVideoUploadProgress(0);

      // const result = await uploadPhotoPromise(
      //   file,
      //   video.type,
      //   video.path,
      //   `videoIntro`,
      //   `userData/${user.uid}/profile`
      // );
      console.log("uploadVideo - upload result:", result);
      setVideoOK(true);
      setVideoUploadError(false);
      saveProperty("introduceVideoUrl", result.downloadUrl);
      setTutorVideo(result.downloadUrl);
    } catch (e) {
      console.error("uploadVideo - uploading error", e);
      setVideoUploadError(true);
      setVideoOK(false);
    }
  };

  const handleProfilePhotoInput = async (acceptedFiles) => {
    console.log("handleProfilePhotoInput - input files", acceptedFiles);
    let toBeUploaded = acceptedFiles[0];
    if (toBeUploaded.type.includes("image")) {
      setPhotoError(false);

      console.log(
        "handleProfilePhotoInput - file is Photo, OK, will read file."
      );
      //compress profile if required
      const imgSize = toBeUploaded.size / 1024 / 1024;
      const maxSizeMB = 1;
      if (imgSize > maxSizeMB) {
        var options = {
          maxSizeMB: maxSizeMB,
          useWebWorker: true,
        };
        let compressedFile = await imageCompression(toBeUploaded, options);
        console.log(
          `compressed profileImage size = ${compressedFile.size / 1024 / 1024}`
        );
        compressedFile.path = toBeUploaded.path;
        toBeUploaded = compressedFile;
      }
      readFile(toBeUploaded, uploadPhoto);
    } else {
      setPhotoError(true);
      setPhotoOK(false);
      console.log("handleProfilePhotoInput - file is NOT Photo, ERROR");
    }
  };

  const handleCVInput = (acceptedFiles) => {
    console.log("handleCVInput - input files", acceptedFiles);
    const toBeUploaded = acceptedFiles[0];
    if (toBeUploaded.type.includes("pdf")) {
      setResumeError(false);

      console.log("handleCVInput - file is document, OK, will read file.");
      readFile(toBeUploaded, uploadCV);
    } else {
      setResumeError(true);
      setResumeOK(false);
      console.log("handleCVInput - file is NOT document, ERROR");
    }
  };

  const handleVideoIntroInput = (acceptedFiles) => {
    console.log("handleVideoIntroInput - input files", acceptedFiles);
    const toBeUploaded = acceptedFiles[0];
    if (toBeUploaded.type.includes("video")) {
      setVideoError(false);
      console.log("handleVideoIntroInput - file is video, OK, will read file.");
      const imgSizeMB = toBeUploaded.size / 1024 / 1024;
      if (imgSizeMB > 30) {
        //if file is above max required file, prompt to user and return
        Swal.fire({
          title: "Max Size Limit",
          text: "You can not upload video above 30MB.",
          confirmButtonText: "OK",
        });
        return;
      }
      readFile(toBeUploaded, uploadVideo);
    } else {
      setVideoError(true);
      setVideoOK(false);
      console.log("handleVideoIntroInput - file is NOT video, ERROR");
    }
  };

  // const handleChange = (key, value) => {
  //   saveProperty(key, value)
  // };
  const onError = (e) => {
    console.log(e, "error in file-viewer");
  };

  const renderPhotoBorderColor = () => {
    if (photoUploadProgress > 0) {
      return commonColor.deepGreen;
    } else if (photoError || photoUploadError) {
      return commonColor.error;
    } else if (photoOK) {
      // return commonColor.correct;
      return "#ffffff";
    } else {
      return commonColor.deepGreen;
    }
  };

  const renderCVBorderColor = () => {
    if (resumeUploadProgress > 0) {
      return commonColor.deepGreen;
    } else if (resumeError || resumeUploadError) {
      return commonColor.error;
    } else if (resumeOK) {
      // return commonColor.correct;
      return "#ffffff";
    } else {
      return commonColor.deepGreen;
    }
  };

  const renderVideoBorderColor = () => {
    if (videoUploadProgress > 0) {
      return commonColor.deepGreen;
    } else if (videoError || videoUploadError) {
      return commonColor.error;
    } else if (videoOK) {
      // return commonColor.correct;
      return "#ffffff";
    } else {
      return commonColor.deepGreen;
    }
  };

  const vidIntroPopup = () => {
    Swal.fire({
      title: "Why do we need this?",
      html: "<p>The short 1 min video will introduce your personality and will also be used for students to see how their new best tutor will look. </br></br> We want to ensure you are not a robot, although being a tutor sometimes feels like being one! By adding a photo, we can place an image to a name; your CV will impress us and allow us to verify your credentials.  </br></br> All this information is only used for verification purposes and part of your profile; l3arn will not use this for any other purpose, as indicated in the Privacy Policy you have agreed to.</p>",
      // text: "The short 1 min video will introduce your personality and will also be used for students to see how their new best tutor will look. \n We want to ensure you are not a robot, although being a tutor sometimes feels like being one! By adding a photo, we can place an image to a name; your CV will impress us and allow us to verify your credentials.  \n All this information is only used for verification purposes and part of your profile; l3arn will not use this for any other purpose, as indicated in the Privacy Policy you have agreed to.",
      confirmButtonText: "OK",
    });
  };

  return (
    <div
      className="h-full w-full flex flex-col lg:px-10 overflow-y-scroll"
      // style={{ marginTop: "-5rem" }}
      // style={{
      //   overflowY: 'scroll',
      //   height: '460px'
      // }}
    >
      <div className="w-full flex justify-end mt-auto">
        {/* <p className="text-center italic">Nearly there!</p> */}

        <p className="text-center italic flex-grow pl-4 pr-1">
          Tell us a little bit about yourself
        </p>

        <div className="flex justify-end">
          <Faq
            onClick={vidIntroPopup}
            data-tip
            data-for="explain"
            className="self-center w-8 h-8"
            style={{
              fill: commonColor.deepPink,
              backgroundColor: "white",
              // height: "3rem",
              // width: "2.5rem",
              aspectRatio: 1,
              // marginBottom: "-1rem",
              // zIndex: 10,
              float: "right",
              cursor: "pointer",
              userSelect: "none",
            }}
          />
          <ReactTooltip id="explain" place="top" delayShow={500}>
            why do we need this?
          </ReactTooltip>
        </div>
      </div>

      {photoError && (
        <div className="text-sm bg-red-500 w-full text-white text-center mb-1">
          {"Photo upload only accepts .png or .jpg files."}
        </div>
      )}
      {photoUploadError && (
        <div className="text-sm bg-red-500 w-full text-white text-center mb-1">
          {"Photo upload error, please try again."}
        </div>
      )}

      {resumeError && (
        <div className="text-sm bg-red-500 w-full text-white text-center mb-1">
          {"Resume upload only accepts .pdf files."}
        </div>
      )}
      {resumeUploadError && (
        <div className="text-sm bg-red-500 w-full text-white text-center mb-1">
          {"Resume upload error, please try again."}
        </div>
      )}

      {videoError && (
        <div className="text-sm bg-red-500 w-full text-white text-center mb-1">
          {"Video upload only accepts video files."}
        </div>
      )}
      {videoUploadError && (
        <div className="text-sm bg-red-500 w-full text-white text-center mb-1">
          {"Video upload error, please try again."}
        </div>
      )}

      {/* {(tutorPhotoUrl ||
        tutorCV ||
        tutorVideo ||
        videoUploadProgress > 0 ||
        photoUploadProgress > 0 ||
        resumeUploadProgress > 0) && (
        <div className="w-full flex  flex-wrap lg:flex-row space-x-2 space-y-2 justify-around items-center pb-10">
          <div className="flex flex-col justify-center w-32 h-40">
            {photoUploadProgress > 0 && !tutorPhotoUrl && (
              <CircularProgressBar
                strokeWidth="5"
                sqSize="100"
                percentage={photoUploadProgress}
              />
            )}
            {tutorPhotoUrl && (
              <img
                src={tutorPhotoUrl}
                alt={""}
                className="center"
                style={{
                  maxWidth: "160px",
                  maxHeight: "200px",
                }}
              />
            )}
          </div>
          <div className="flex flex-col justify-center w-32 h-40">
            {resumeUploadProgress > 0 && !tutorCV && (
              <CircularProgressBar
                strokeWidth="5"
                sqSize="100"
                percentage={resumeUploadProgress}
              />
            )}
            {tutorCV && (
              <FileViewer
                fileType={"pdf"}
                filePath={tutorCV}
                onError={onError}
                errorComponent={() => <div>Error with selected file.</div>}
              />
            )}
          </div>
          <div className="flex flex-col justify-center w-32 h-40">
            {videoUploadProgress > 0 && !tutorVideo && (
              <CircularProgressBar
                strokeWidth="5"
                sqSize="100"
                percentage={videoUploadProgress}
              />
            )}
            {tutorVideo && (
              <ReactPlayer
                url={tutorVideo}
                light={true}
                width={"100%"}
                height={"100%"}
                onError={onError}
              />
            )}
          </div>
        </div>
      )}  */}

      <div
        // className="w-full flex  flex-wrap lg:flex-row  justify-around items-center pb-4 mb-auto"
        className="w-full flex flex-wrap lg:flex-row  justify-around items-center"
        // style={{ marginBottom: "-6rem" }}
      >
        <div className="flex flex-col justify-center w-32 h-64">
          <div
            className="text-sm flex justify-start h-10"
            style={{
              marginBottom: "1rem",
            }}
          >
            {"Upload your photo"}
          </div>
          <div className="flex flex-col justify-center h-40">
            <Photofile
              className="self-center"
              style={{
                fill: commonColor.deepGreen,
                backgroundColor: "white",
                height: "3rem",
                width: "3rem",
                aspectRatio: 1,
                marginBottom: "-1.5rem",
                borderRadius: "50%",
                padding: "5px",
                zIndex: 10,
              }}
            />
            <Dropzone onDrop={handleProfilePhotoInput}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div
                    className="w-32 h-32 border flex items-center focus:outline-none"
                    style={{
                      color: "white",
                      backgroundColor: renderPhotoBorderColor(),
                    }}
                    {...getRootProps()}
                  >
                    <input
                      className="focus:outline-none"
                      {...getInputProps()}
                    />
                    <div className="text-xs text-center w-full h-full flex justify-center items-center">
                      {photoUploadProgress > 0 && !tutorPhotoUrl ? (
                        <CircularProgressBar
                          strokeWidth="5"
                          sqSize="100"
                          percentage={photoUploadProgress}
                          fillColor={"#ffffff"}
                          strokeColor={commonColor.deepPink}
                        />
                      ) : tutorPhotoUrl ? (
                        <img
                          src={tutorPhotoUrl}
                          alt="Tutor PhotoUrl"
                          className="center max-h-full max-w-full"
                          // style={{
                          //   maxWidth: "160px",
                          //   maxHeight: "200px",
                          // }}
                        />
                      ) : photoOK ? (
                        "Done!"
                      ) : (
                        "Drag and Drop\nor touch to upload"
                      )}
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </div>

        <div className="flex flex-col justify-center w-32 h-64">
          <div
            className="text-sm flex justify-start h-10"
            style={{
              marginBottom: "1rem",
            }}
          >
            {"Upload your resume"}
          </div>
          <div className="flex flex-col justify-center h-40">
            <Resume
              className="self-center"
              style={{
                fill: commonColor.deepGreen,
                backgroundColor: "white",
                height: "3rem",
                width: "3rem",
                aspectRatio: 1,
                marginBottom: "-1.5rem",
                borderRadius: "50%",
                padding: "5px",
                zIndex: 10,
              }}
            />
            <Dropzone onDrop={handleCVInput}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div
                    className="w-32 h-32 border flex items-center focus:outline-none"
                    style={{
                      color: "white",
                      backgroundColor: renderCVBorderColor(),
                    }}
                    {...getRootProps()}
                  >
                    <input
                      className="focus:outline-none"
                      {...getInputProps()}
                    />

                    <div className="text-xs text-center w-full h-full flex justify-center items-center">
                      {resumeUploadProgress > 0 && !tutorCV ? (
                        <CircularProgressBar
                          strokeWidth="5"
                          sqSize="100"
                          percentage={resumeUploadProgress}
                          fillColor={"#ffffff"}
                          strokeColor={commonColor.deepPink}
                        />
                      ) : tutorCV ? (
                        <FileViewer
                          fileType={"pdf"}
                          filePath={tutorCV}
                          onError={onError}
                          errorComponent={() => (
                            <div>Error with selected file.</div>
                          )}
                        />
                      ) : resumeOK ? (
                        "Done!"
                      ) : (
                        "Drag and Drop\nor touch to upload"
                      )}
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </div>

        <div className="flex flex-col justify-center w-32 h-64">
          <div
            className="text-sm flex justify-start h-10"
            style={{
              marginBottom: "1rem",
            }}
          >
            <p>
              1 min introduction video about
              <br />
              yourself
            </p>
          </div>

          <div className="flex flex-col justify-center h-40 mt-2">
            <VideoIntro
              className="self-center"
              style={{
                fill: commonColor.deepGreen,
                backgroundColor: "white",
                height: "3rem",
                width: "3rem",
                aspectRatio: 1,
                marginBottom: "-1.5rem",
                borderRadius: "50%",
                padding: "5px",
                zIndex: 10,
              }}
            />
            <Dropzone onDrop={handleVideoIntroInput}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div
                    className="w-32 h-32 border flex items-center focus:outline-none"
                    style={{
                      color: "white",
                      backgroundColor: renderVideoBorderColor(),
                    }}
                    {...getRootProps()}
                  >
                    <input
                      className="focus:outline-none"
                      {...getInputProps()}
                    />

                    <div className="text-xs text-center w-full h-full flex justify-center items-center">
                      {videoUploadProgress > 0 && !tutorVideo ? (
                        <CircularProgressBar
                          strokeWidth="5"
                          sqSize="100"
                          percentage={videoUploadProgress}
                          fillColor={"#ffffff"}
                          strokeColor={commonColor.deepPink}
                        />
                      ) : tutorVideo ? (
                        <ReactPlayer
                          url={tutorVideo}
                          light={true}
                          width={"100%"}
                          height={"100%"}
                          onError={onError}
                        />
                      ) : videoOK ? (
                        "Done!"
                      ) : (
                        "Drag and Drop\nor touch to upload"
                      )}
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </div>
      </div>

      <div
        className={`w-full flex  flex-wrap lg:flex-row items-center  ${
          width > 461 ? "justify-end" : "justify-center"
        } pb-4 mb-auto`}
      >
        <div
          className={` ${
            width > 461 ? "sm:w-1/3" : ""
          } flex flex-col items-center`}
        >
          <div className="w-32 flex flex-col items-center">
            <p className="text-xs"> Don't you have one ready?</p>
            <Swotbutton
              text="Create now"
              onClick={() => {
                setOpenRecorder(true);
              }}
            />
          </div>
        </div>
      </div>

      <Modal
        isOpen={openRecorder}
        onRequestClose={() => {
          setOpenRecorder(false);
        }}
        isCloseButtonShown={true}
      >
        <WebcamWidget
          callback={(videoBlob) => {
            setOpenRecorder(false);
            if (videoBlob) {
              handleVideoIntroInput([videoBlob]);
            }
          }}
        />
      </Modal>
    </div>
  );
};

export default Step3;
