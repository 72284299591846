import React from "react";
import commonColor from "../../../theme/commonColor";
import { css } from "@emotion/react";
import { ClimbingBoxLoader,ClipLoader, BeatLoader, PulseLoader} from "react-spinners";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: white;
`;

const LoaderButton = ({
color= "#ffffff",
  bgColor = commonColor.deepPink,
  style,
  className,
  shadow = false
}) => {

  return (
    <div
      className={` shadow-lg py-2 px-4 text-xs hover:shadow-outline  
      focus:outline-none flex items-center justify-center ${className ? className : ""}` }
      style={
        style
          ? {
              borderRadius: "1.5rem",
              backgroundColor: bgColor,
              boxShadow: `5px 5px 15px 5px rgba(0, 0, 0, 0.6)`,
              ...{ ...style },
            }
          : {
              borderRadius: "1.5rem",
              backgroundColor: bgColor,
               boxShadow: shadow ? `5px 5px 8px 2px rgba(0, 0, 0, 0.6)` : "none",
            }
      }
      disabled={true}
    >
      {
          <PulseLoader 
          color={color}
          loading={true}
          css={override}
          size={5}
          margin={2}
          />
      }
    </div>
  );
};

export default LoaderButton;
