import React, { useState, useEffect } from "react";
import { getContentTypeOptions } from "../../../services/api";
import Checkbox from "../../../components/common/input/checkbox";
import Select from "../../../components/common/input/select";
import Swal from "sweetalert2";
import defaultCurriculumData from "./defaultCurriculumData";

const Step1 = ({ saveProperty, currentStep }) => {
  /*const mockCoursesData = {
    type: "curriculum",
    subjects: {
      physics: {
        name: "physics",
        areas: ["thermodynamics", "gravity", "impact", "torque"],
      },
      math: {
        name: "math",
        areas: [
          "calculus",
          "algebra",
          "basic",
          "linear algebra",
          "analysis I",
          "analysis II",
        ],
      },
    },
  };
*/
  const [tutoringContentType, setTutoringContentType] = useState("non"
    // {
    // value: "curriculum",
    // label: "Curriculum",
    // }
  );
  const [subjects, setSubjects] = useState([]);
  const [contentTypeMaterial, setContentTypeMaterial] = useState(
    defaultCurriculumData
  );
  const [isSpecialEdu, setisSpecialEdu] = useState(false);

  useEffect(() => {
    const getData = async () => {
      console.log(`useEffect 1 - tutoringContentType:`, tutoringContentType);
      const data = await getDataForContentType(tutoringContentType);
      setContentTypeMaterial(data ? data : {});
      displaySubjects(data ? data : {});
    };

    getData();
  }, [tutoringContentType]);

  useEffect(() => {
    console.log(`useEffect 2 - changed subjects:`, subjects);
    displaySubjectAreas(subjects);
  }, [subjects]);

  const displaySubjects = (data) => {
    let subjectOptions = [];
    let chosenSubjectAreaOptions = [];

    if (!data.subjects) {
      return;
    }

    for (const [key, value] of Object.entries(data.subjects)) {
      //console.log(`displaySubjects - found subjects ${key}:`, value);
      const subjectAreaOptions = value.areas.map((area) => {
        return { value: area, label: area };
      });
      //console.log(`displaySubjects - subjectAreaOptions:`, subjectAreaOptions);
      subjectOptions.push({ value: key, label: key });
      let subjectAreas = {
        label: key,
        options: subjectAreaOptions,
      };
      chosenSubjectAreaOptions.push(subjectAreas);
    }
   // subjectOptions.push({ value: "other", label: "Other" });
    setSubjectOptions(subjectOptions);
    setSubjectAreaOptions(chosenSubjectAreaOptions);
  };

  const displaySubjectAreas = (subjects) => {
    console.log(
      "displaySubjectAreas - subjects:",
      subjects,
      contentTypeMaterial
    );
    let defaultSubAreas =[];

    let chosenSubjectAreaOptions = [];
    subjects.forEach((sub) => {
      const subject = contentTypeMaterial?.subjects[sub?.value];
      console.log("displaySubjectAreas - selected subject:", sub, subject);
      if (subject) {
        let areaOptions = contentTypeMaterial.subjects[sub.value].areas.map(
          (area) => {
            return { value: area, label: area };
          }
        );
        let subjectAreas = {
          label: sub.value,
          options: areaOptions,
        };
        chosenSubjectAreaOptions.push(subjectAreas);
        defaultSubAreas.push(areaOptions[0])

      }
    });

     //Set Default subSubject
        setSubjectAreas(defaultSubAreas);
        const subjectAreasArray = defaultSubAreas ? defaultSubAreas.map((x) => x.value) : [];
        console.log("subject Areas to be saved:", subjectAreasArray);
        handleChange("subjectAreas", subjectAreasArray);
      //

    setSubjectAreaOptions(chosenSubjectAreaOptions);
  };

  const tutoringRegistrationOpts = [
    { value: "online", label: "Online" },
    // { value: "asynchronous", label: "Asynchronous" },
    // { value: "face-2-face", label: "Face to face" },
  ];

  const tutoringContentTypeOpts = [
    { value: "curriculum", label: "School Subjects" },
    { value: "extra curriculum", label: "Skill Training" },
  ];

  const tutoringPreferenceOpts = [
    { value: "groups", label: "Groups" },
    { value: "individuals", label: "Individuals" },
  ];

  /*const mockSubjects = [
    { value: "physics", label: "physics" },
    { value: "math", label: "math" },
    { value: "literature", label: "literature" },
    { value: "english", label: "english" },
    { value: "gymnastics", label: "gymnastics" },
    { value: "chemistry", label: "chemistry" },
  ];*/

  const [tutoringRegistration, setTutoringRegistration] = useState("online");
  const [tutoringPreference, setTutoringPreference] = useState("groups");

  const [subjectAreas, setSubjectAreas] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [subjectAreaOptions, setSubjectAreaOptions] = useState([]);

  if (currentStep !== 1) {
    return null;
  }

  const handleChange = (key, value) => {
    saveProperty(key, value);
  };

  const getDataForContentType = async (contentType) => {
    // Call BE for content subjets, areas
    let response = await getContentTypeOptions(
      contentType ? contentType.value === "curriculum" : true
    );
    // save to local state
    console.log("getDataForContentType - response", response);
    if (response && response.ok) {
      //setContentTypeMaterial(response.data)
      return response.data;
    } else {
      return null;
    }
  };

  return (
    <div className="h-full flex flex-col justify-start">
      <div className="w-full flex justify-center pt-2 md:pt-4 mt-auto">
        <p className="text-center italic">Tell us about your teaching style, you can always change<br></br> these later in your profile settings.</p>
      </div>

      <div className="w-full flex flex-row justify-center pt-2 md:pt-4">
        <div className="text-sm w-1/3 flex flex-row justify-end items-center">
          {"I usually teach:"}
        </div>
        <div className="w-2/3 flex flex-row justify-start px-1 md:px-2">
          <Select
            placeholder={"Select"}
            className="lg:w-4/5 w-full text-sm"
            defaultValue={tutoringRegistrationOpts[0]}
            value={
              tutoringRegistration
                ? tutoringRegistration
                : tutoringRegistrationOpts[0]
            }
            onChange={(value) => {
              setTutoringRegistration(value);
              handleChange("tutoringRegistration", value.value);
            }}
            options={tutoringRegistrationOpts}
          />
        </div>
      </div>

      <div className="w-full flex flex-row justify-center pt-2 md:pt-4">
        <div className="text-sm w-1/3 flex flex-row justify-end items-center">
          {"These are mostly:"}
        </div>
        <div className="w-2/3 flex flex-row justify-start px-1 md:px-2">
          <Select
            placeholder={"Select"}
            className="lg:w-4/5 w-full text-sm"
            defaultValue={tutoringContentTypeOpts[0]}
            value={
              tutoringContentType
                ? tutoringContentType
                : tutoringContentTypeOpts[0]
            }
            onChange={(value) => {
              console.log("selected subjects:", value);
              setTutoringContentType(value);
              setSubjects([]);
              //getDataForContentType(value)
            }}
            options={tutoringContentTypeOpts}
          />
        </div>
      </div>

      {tutoringContentType !== "non" &&
        <div className="w-full flex flex-row justify-center pt-2 md:pt-4">
          <div className="text-sm w-1/3 flex flex-col justify-center items-end">
            {"They include subjects:"}<br />
            <span className="text-xs" >* you can choose 3 max</span>
          </div>
          <div className="w-2/3 flex flex-row justify-start px-1 md:px-2">
            <Select
              placeholder={"Select"}
              className="lg:w-4/5 w-full text-sm"
              defaultValue={subjects}
              value={subjects}
              closeMenuOnSelect={false}
              onChange={(value) => {
                value = value ? value : []
                console.log("selected subjects:", value);

                if (value.length <= 3) {
                  setSubjects(value);
                  const subjectsArray = value ? value.map((x) => x.value) : [];
                  console.log("subject to be saved:", subjectsArray);
                  handleChange("subjects", subjectsArray);
                }
                else {
                  Swal.fire({ title: "Failed!", text: "Although we would love you to teach as many subjects you want, for setup purposes, please let us know your top three", icon: "error", confirmButtonText: "OK" });
                  console.log(subjects, value);
                }
              }}
              isMulti
              options={subjectOptions}
            />
          </div>
        </div>
      }
      
      {/* <div className="w-full flex flex-row justify-center">
        <div className="text-sm w-1/3 flex flex-row justify-end items-center">
          {"Subject Areas"}
        </div>
        <div className="w-2/3 flex flex-row justify-start px-2">
          <Select
            placeholder={"Select"}
            className="lg:w-4/5 w-full text-sm"
            defaultValue={subjectAreas}
            value={subjectAreas}
            options={subjectAreaOptions}
            isFormatGroupLabelUsed
            isMulti
            closeMenuOnSelect={false}
            onChange={(value) => {
              value = value ? value : []
              console.log("selected subject Areas:", value);
                
              if (value.length <= 5) {
                setSubjectAreas(value);
                const subjectAreasArray = value ? value.map((x) => x.value) : [];
                console.log("subject Areas to be saved:", subjectAreasArray);
                handleChange("subjectAreas", subjectAreasArray);
              }
              else {
                Swal.fire({ title: "Failed!", text: "You can not select more than 5 subject areas", icon: "error", confirmButtonText: "OK" });
              }
            }}
          />
        </div>
      </div>  */}

      <div className="w-full flex flex-row justify-center pt-2 md:pt-4">
        <div className="text-sm w-1/3 flex flex-row justify-end items-center">
          {"I prefer to teach to:"}
        </div>
        <div className="w-2/3 flex flex-row justify-start px-2">
          <Select
            placeholder={"Select"}
            className="lg:w-4/5 w-full text-sm"
            value={tutoringPreference}
            onChange={(value) => {
              setTutoringPreference(value);
              handleChange("tutoringPreference", value.value);
            }}
            options={tutoringPreferenceOpts}
          />
        </div>
      </div>
      <div className="w-full flex flex-row justify-center  py-2 md:py-4 mb-auto">
        <div className="text-sm w-1/3 flex flex-row justify-end items-center"></div>
        <div className="w-2/3 flex flex-row justify-start px-1 md:px-1">
          <Checkbox
            label={" I can support special education needs."}
            isSelected={isSpecialEdu}
            onCheckboxChange={(changeEvent) => {
              setisSpecialEdu(!isSpecialEdu);
              handleChange("specialEducation", !isSpecialEdu);
            }}
            key={"isSpecialEdu"}
          />
        </div>
      </div>
    </div>
  );
};

export default Step1;
