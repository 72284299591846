import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

export const createHourlyTimeslots = (start, end, type, title) => {

  const range = moment.range(start, end);

  const rangeBy = range.by('minutes', { step: 60 });

  let res = Array.from(rangeBy).map(m => ({
    'type': type,
    'start': m.toDate(),
    'end': m.add(1, 'h').toDate(),
    title: title
  }))
    .filter(m => moment(m.end).isSameOrBefore(end))


if(res.length === 0) {
  //const rangeBy1 = range.by('minutes', { step: 59 });
  const minutesDiff = parseInt(Math.abs(start.getTime() - end.getTime()) / (1000 * 60) % 60);
  if(end.getHours() === 23 && end.getMinutes() === 59 && minutesDiff === 59) {
    const res1 =  {
      'type': type,
      'start': start,
      'end': end,
      title: title
    }
    res.push(res1)
    console.log("createHourlyTimeslots - result", res1)

  }
}
  console.log("createHourlyTimeslots - result", res)

  return res

}

export const create30MinutesTimeslots = (start, end) => {

  const range = moment.range(start, end);

  const rangeBy = range.by('minutes', { step: 30 });

  let res = Array.from(rangeBy).map(m => ({
    'start': m.toDate(),
    'end': m.add(1, 'm').toDate(),
  }))
    .filter(m => moment(m.end).isSameOrBefore(end))


if(res.length === 0) {
  //const rangeBy1 = range.by('minutes', { step: 59 });
  const minutesDiff = parseInt(Math.abs(start.getTime() - end.getTime()) / (1000 * 60) % 60);
  if(end.getHours() === 23 && end.getMinutes() === 59 && minutesDiff === 29) {
    const res1 =  {
      'start': start,
      'end': end
    }
    res.push(res1)
    console.log("create30MinutesTimeslots - result", res1)

  }
}
  console.log("create30MinutesTimeslots - result", res)

  return res

}

export const eventsOverlap = (evt1, evt2) => {

  const event1start = moment(evt1.start)
  const event2start = moment(evt2.start)
  const event1end = moment(evt1.end)
  const event2end = moment(evt2.end)

  const isOverlap = event1start.isSame(moment(event2start))
    || event1end.isSame(event2end) ||
    (event1start.isSameOrBefore(event2start) && event1end.isAfter(event2start)) ||
    (event2start.isSameOrBefore(event1start) && event2end.isAfter(event1start))

  return isOverlap
}