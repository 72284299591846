import { isUserAuthenticated, fetchCurrentUser } from "../userManagement";
import { auth } from "../../config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { store } from "../../App";
import UserActions from "../../redux/user";
import { historyState } from "./history";
import { ACCOUNT_TYPES } from "../../config/constants";

export const redirectBasedOnStatus = (user, navigate) => {

  console.log("redirectBasedOnStatus - init ", window.location);
  if (window.location.pathname === "/setNewPassword") {
    return;
  }

  if (isUserAuthenticated()) {
    fetchCurrentUser().then((userSnapshot) => {
      if (userSnapshot.exists()) {
        const user = userSnapshot.data();

        let provider = '';

        switch (userSnapshot.providerId) {
          case "facebook.com":
            provider = "facebook";
            break;
          case "google.com":
            provider = "google";
            break;
          default:
            provider = "custom";
        }

        const prevLocation = window.location.pathname;
        const wasNotLoggedIn = prevLocation.includes("sign-in") || prevLocation.includes("sign-in") || prevLocation === "/";
        const wasActicatingIn = prevLocation.toLowerCase().includes("activation");

        console.log("redirectBasedOnStatus - navigate to last known SK uri / wasNotLoggedIn", prevLocation, wasNotLoggedIn);

        console.log("redirectBasedOnStatus - user", user);
        console.log("startup - loggedIn - user", user);
        console.log("user provider", provider);
        console.log("wasNotLoggedIn", wasNotLoggedIn);
        console.log("prevLocation", prevLocation);

        // if(user.status === "activation-pending") {
          // saveUserInAppState();
          // console.log("redirectBasedOnStatus - Don't navigate");
          // Navigate("/activation");
        // } else

         if (!wasNotLoggedIn && !user.email && user.status && user.status !== "pending" &&  provider === "facebook") {
          //Handle if social facebook-login don't have email-id
          console.log("redirectBasedOnStatus - navigate('/dashboard')");
          // redirect("/dashboard");
          if (user.accountType === ACCOUNT_TYPES.SWOT_BUDDY && (user.quizDone || user.swotBuddySetup === "default")) {
            historyState.navigate('/swot-buddy', { replace: true });
          } else {
          historyState.navigate('/dashboard', { replace: true });
          }
        } else if (user.status === "pending" && (provider === "google" || provider === "facebook")) {
            saveUserInAppState();
            if(prevLocation.toLocaleLowerCase().includes("on-boarding")) {
              console.log("redirectBasedOnStatus - Don't navigate");
            } else {
              console.log("redirectBasedOnStatus - navigate to onboarding");
              historyState.navigate("/on-boarding");
            }
        } else if (user.status === "active") {
          if (wasNotLoggedIn || wasActicatingIn) {
            console.log("redirectBasedOnStatus - navigate('/dashboard')");
            // redirect("/dashboard");
            if (user.accountType === ACCOUNT_TYPES.SWOT_BUDDY && (user.quizDone || user.swotBuddySetup === "default")) {
              historyState.navigate('/swot-buddy', { replace: true });
            } else {
              historyState.navigate('/dashboard', { replace: true });
            }
          } else {
            console.log("redirectBasedOnStatus - prevLocation");
            historyState.navigate(prevLocation);
          }
        } else if (user.status === "activation-pending") {
          console.log(
            "redirectBasedOnStatus - user is currently in activation-pending, redirect to activation prompt."
          );
          historyState.navigate("/activation", { replace: true });
        } else if (user.status === "pending") {
          console.log("redirectBasedOnStatus - navigate to onboarding");
          historyState.navigate("/on-boarding", { replace: true });
        } else if (!wasNotLoggedIn && (provider === "google" || provider === "facebook")) {
          console.log("redirectBasedOnStatus - navigate('/dashboard')");
          if (user.accountType === ACCOUNT_TYPES.SWOT_BUDDY && (user.quizDone || user.swotBuddySetup === "default")) {
            historyState.navigate('/swot-buddy', { replace: true });
          } else {
            historyState.navigate('/dashboard', { replace: true });
          }
          // redirect("/dashboard");
        }
      } else {
        console.log(
          "redirectBasedOnStatus - no user found in collection, DO NOTHING"
        );
        //navigate("/sign-in")
      }
    });
  } else {
    console.log("redirectBasedOnStatus - user not authenticated, DO NOTHING");
    //navigate("/")
  }
};

const saveUserInAppState = (fetchedUser) => {
  try{
    console.log("navigation - saveUserInAppState ");
  const reduxUser = store.getState()?.user?.user;
  console.log("navigation - saveUserInAppState - reduxUser: ", reduxUser);
  // const reduxDispatch = store.dispatch();
  const saveUser = (user) => store.dispatch(UserActions.saveUserRequest(user));
  saveUser({...fetchedUser, ...(reduxUser ? reduxUser : {})});

  } catch (e) {
    console.log("navigation - saveUserInAppState -Error - ", e);
  }
}

console.log("navigation - activating onAuthStateChanged...");
onAuthStateChanged(auth, function (authUser) {
  console.log("onAuthStateChanged - authUser", authUser);
  if (authUser) {
    redirectBasedOnStatus();
  }
});
