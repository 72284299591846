import React, { CSSProperties, ReactNode } from "react";
import ReactModal from "react-modal";
import closeSrc from "../../../assets/images/close-icon.png";

// for some reason react-modal doesn't like it if I pass
// overlayClassName='flex' so I needed to use inline styles
const modalStyles = {
  overlay: {
    display: "flex",
    zIndex: 9999,
    backgroundColor: "rgba(0,0,0,0.3)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0",
    backgroundColor: "transparent",
    transitionAll: ".3s ease-out",
    // textAlign: "center"
  },
};

// const Modal = ({
//   isOpen,
//   onRequestClose,
//   isCloseButtonShown = true,
//   className,
//   children,
// }) => {
//   return (
//     <ReactModal
//       isOpen={isOpen}
//       onRequestClose={onRequestClose}
//       className={`bg-white border-0 w-full md:w-1/2 h-3/4 m-auto flex justify-center relative p-4 shadow-2xl ${className}`}
//       style={{ ...modalStyles }}
//     >
//       {isCloseButtonShown && (
//         // TODO this needs to be an icon
//         <div
//           className="absolute cursor-pointer top-0 right-0 p-4"
//           style={{ zIndex: 9999 }}
//         >
//           <img
//             src={closeSrc}
//             className="w-3"
//             alt="close"
//             onClick={onRequestClose}
//           />
//         </div>
//       )}
//       {children}
//     </ReactModal>
//   );
// };

const Modal = ({
  isOpen,
  onRequestClose,
  onAfterClose,
  isCloseButtonShown = false,
  className,
  setMaxSize = false,
  children,
  bgColor,
  modalRef
}: {
  isOpen: boolean;
  onRequestClose: Function;
  onAfterClose?: Function;
  isCloseButtonShown: boolean;
  className?: String;
setMaxSize: boolean;
  children?: ReactNode;
  bgColor?: String;
  modalRef?:  React.MutableRefObject<any>;
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onAfterClose={onAfterClose}
      // className={`bg-white border-0  min-h-20 p-8 md:min-w-60 md:min-h-40 flex flex-col justify-center items-center relative shadow-2xl ${className}`}
      style={{ ...modalStyles }}
      appElement={document.getElementById("root") || undefined}
    >
      <div
        //className={`bg-white border-0 md:max-w-2/3 lg:max-w-1/2 md:min-w-80 md:min-h-40  shadow-lg rounded-lg space-y-2 m-auto relative ${className}`}
        ref={modalRef}
        className={`${
          bgColor ? bgColor : "bg-white"
        } border-0  min-w-4/5 sm:min-w-3/4  md:min-h-40  ${
          setMaxSize && "sm:max-w-70v md:max-w-60v lg:max-w-5v"
        } shadow-lg rounded-lg space-y-2 m-auto relative ${className}`}
      >
        <div
          className={`min-h-20 p-4 md:p-6 lg:p-12 flex flex-col justify-center items-center`}
        >
           <div
              className="absolute cursor-pointer top-0 right-0 p-4"
              style={{ zIndex: 9999 }}
            >
              <img
                src={closeSrc}
                className="w-8"
                alt="close"
                onClick={() => onRequestClose()}
              />
            </div>
          {children}
        </div>
      </div>
    </ReactModal>
  );
};

export const QuickModal = ({
  isOpen,
  onRequestClose,
  isCloseButtonShown = false,
  className,
  title,
  message,
  firstButtonTitle,
  secondButtonTitle,
  onFirstButtonClick,
  onSecondButtonClick,
  firstButtonStyle,
  secondButtonStyle,
  firstButtonClassName,
  secondButtonClassName,
  modalRef,
  children,
}: {
  isOpen: boolean;
  onRequestClose: Function;
  isCloseButtonShown: boolean;
  className?: String;
  title?: String;
  message?: String;
  firstButtonTitle?: String;
  secondButtonTitle?: String;
  onFirstButtonClick?: Function;
  onSecondButtonClick?: Function;
  firstButtonStyle?: CSSProperties;
  secondButtonStyle?: CSSProperties;
  firstButtonClassName?: String;
  secondButtonClassName?: String;
  modalRef?:  React.MutableRefObject<any>;
  children: ReactNode;
}) => {
  const buttonDefaultStyle: CSSProperties = {
    border: "1px solid gray",
  };

  return (
    <Modal
      isOpen={isOpen}
      modalRef={modalRef}
      onRequestClose={onRequestClose}
      isCloseButtonShown={isCloseButtonShown}
      className={className}
      setMaxSize={true}
    >
      {title && (
        <div className="text-center">
          <label className="font-bold text-black text-center">{title}</label>
        </div>
      )}
      {message && <p className="text-black whitespace-pre-line">{message}</p>}

      {children}

      <div className="text-center mt-4 ">
        {(firstButtonTitle || secondButtonTitle) && (
          <div className="flex flex-col justify-start items-center">
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 items-center self-center justify-start md:justify-center p-2">
              {firstButtonTitle ? (
                <button
                  className={`min-w-16 px-4 py-1 rounded-md ${firstButtonClassName}`}
                  style={
                    firstButtonStyle ? firstButtonStyle : buttonDefaultStyle
                  }
                  onClick={() => onFirstButtonClick && onFirstButtonClick()}
                >
                  {firstButtonTitle}
                </button>
              ) : (
                ""
              )}
              {secondButtonTitle ? (
                <button
                  className={`min-w-16 px-4 py-1 rounded-md ${secondButtonClassName}`}
                  style={
                    secondButtonStyle ? secondButtonStyle : buttonDefaultStyle
                  }
                  onClick={() => onSecondButtonClick && onSecondButtonClick()}
                >
                  {secondButtonTitle}
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default Modal;
