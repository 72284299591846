import moment from "moment";
import {
  doc,
  collection,
  getDoc,
  setDoc,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import { firestore } from "../../config/firebase";
import { SuperCall } from "typescript";
import { uploadFilePromise } from "../../services/fileServices";
import ChatUserModel from "../models/chatUser";
import {
  ChatType,
  IChatGroup,
  IChatGroupMessage,
  IChatMessage,
  MessageType,
} from "../models/chat_models";
import { throws } from "assert";
import { group } from "console";
export interface IChatService {
  sendMessage(
    sentBy: string,
    sentTo: string,
    messageText: string,
    currentGroupId?: string
  ): Promise<boolean>;

  sendMessageToGroup(
    sentBy: string,
    currentGroupId: string,
    messageText: string
  ): Promise<boolean>;

  sendImageMessage(
    sentBy: string,
    sentTo: string,
    photo: any,
    file: any,
    progressCallback: (progress: number) => void,
    currentGroupId?: string
  ): Promise<boolean>;

  sendImageMessageToGroup(
    sentBy: string,
    currentGroupId: string,
    photo: any,
    file: any,
    progressCallback: (progress: number) => void
  ): Promise<boolean>;

  createGroup(
    userId: string,
    gropMembers: ChatUserModel[],
    title: string
  ): Promise<boolean>;

  listenToGroups(userId: String, callback: (groups: IChatGroup[]) => void);

  listenToActiveChat(
    userId: string,
    groupId: string,
    callback: (messages: IChatMessage[]) => void
  );

  unSubscribeMessageListeners(): void;

  unSubscribeListeners(): void;
}

const CHAT_GROUP = "chat-group";
const CHAT_MESSAGES = "chat-message";
const CHAT_USER = "chat-user";
const MESSAGES = "messages";

export class ChatService implements IChatService {
  chatGroups: IChatGroup[];
  chatUsers: ChatUserModel[];
  chatMessages: IChatMessage[];

  unsubscribeChatGroups: any;
  unsubscribeActiveChat: any;

  constructor() {
    this.chatGroups = [];
    this.chatUsers = [];
    this.chatMessages = [];
  }

  /**
   * Unsubscribe all the firebase-subscriptions
   */
  unSubscribeListeners = async () => {
    this.unsubscribeChatGroups && this.unsubscribeChatGroups();
    this.unSubscribeMessageListeners();
  };

  unSubscribeMessageListeners = async () => {
    this.unsubscribeActiveChat && this.unsubscribeActiveChat();
  };

  /**
   * 1. add message to messages by groupId
   * 2. update latest message in group
   * 3.
   */

  /**
   *
   * @param sentBy my userId
   * @param sentTo userId of the receiver
   * @param messageText message to send
   * @param currentGroupId groupId if these two users have already a chat.
   * @returns
   */
  sendMessage = async (
    sentBy: string,
    sentTo: string,
    messageText: string,
    currentGroupId?: string
  ): Promise<boolean> => {
    var result = false;
    if (messageText.trim().length > 0) {
      const message: IChatMessage = {
        messageText: messageText,
        type: MessageType.TEXT,
        sentAt: moment().unix(),
        sentBy: sentBy,
        sentTo: sentTo,
      };

      var isNewGroup = false;
      if (!currentGroupId || currentGroupId?.trim() === "") {
        currentGroupId = this.composeGroupId(sentBy, sentTo);
        isNewGroup = !(await this.checkIfGroupExists(currentGroupId));
      }

      result = await this.addMessageToFirestore(
        sentBy,
        message,
        currentGroupId!.trim(),
        isNewGroup,
        ChatType.PRIVATE,
        sentTo
      );
    }
    return result;
  };

  /**
   *
   * @param sentBy my userId
   * @param currentGroupId groupId id.
   * @param messageText message to send
   * @returns
   */
  sendMessageToGroup = async (
    sentBy: string,
    currentGroupId: string,
    messageText: string
  ): Promise<boolean> => {
    var result = false;
    if (messageText.trim().length > 0) {
      const message: IChatMessage = {
        messageText: messageText,
        type: MessageType.TEXT,
        sentAt: moment().unix(),
        sentBy: sentBy,
      };

      var isNewGroup = false;
      if (!currentGroupId || currentGroupId?.trim() === "") {
        return false;        
      }

      result = await this.addMessageToFirestore(
        sentBy,
        message,
        currentGroupId!.trim(),
        isNewGroup,
        ChatType.GROUP
      );
    }
    return result;
  }

  /**
   *
   * @param userId my user Id
   * @param callback will be used to return the updated Live data
   * @returns
   */
  listenToGroups = async (
    userId: String,
    callback: (groups: IChatGroup[]) => void
  ) => {
    //If already subscribed, then unsubscribe it first
    this.unsubscribeChatGroups && this.unsubscribeChatGroups();

    //subscribe to live-datachage
    onSnapshot(
      this.queryGroupsByUserId(userId),
      // this.unsubscribeChatGroups = this.queryGroupsByUserId(userId).onSnapshot(
      { includeMetadataChanges: true },
      async (snapshot) => {
        var source = snapshot.metadata.hasPendingWrites ? "Local" : "Server";
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            //TODO: add to the group
            console.log("New chatGroup: ", change.doc.data());
            this.addGroup(change.doc.data());
          }
          if (change.type === "modified") {
            console.log("Modified city: ", change.doc.data());
            this.updateGroup(change.doc.data());
          }
          if (change.type === "removed") {
            //TODO: remove form group
            console.log("Removed city: ", change.doc.data());
            this.removeGroup(change.doc.data());
          }
        });

        await this.resolveUsers(userId);
        callback(this.chatGroups);
      }
    );
    return;
  };

  /**
   *
   * @param userId my user Id
   * @param groupId chat-group Id
   * @param callback will be used to return the updated Live data
   * @returns
   */
  listenToActiveChat = async (
    userId: string,
    groupId: string,
    callback: (messages: IChatMessage[]) => void
  ) => {
    //If already subscribed, then unsubscribe it first
    this.unsubscribeActiveChat && this.unsubscribeActiveChat();
    this.chatMessages = [];

    //subscribe to live-datachage
    this.unsubscribeActiveChat = onSnapshot(
      query(this.queryMessagesByGroupId(groupId)),
      { includeMetadataChanges: true },
      async (snapshot) => {
        // this.unsubscribeActiveChat = this.queryMessagesByGroupId(
        //   groupId
        // ).onSnapshot({ includeMetadataChanges: true }, async (snapshot) => {
        var isLocal = snapshot.metadata.hasPendingWrites;
        // if (source !== "Local") {
        snapshot
          .docChanges({ includeMetadataChanges: true })
          .forEach((change) => {
            if (change.type === "added") {
              //TODO: add to the group
              console.log("New message: ", change.doc.data());
              this.addMessage(change.doc.data(), isLocal);
            } else if (change.type === "modified") {
              //TODO: add to the group
              console.log("New message: ", change.doc.data());
              this.updateMessage(change.doc.data());
            }
          });
        // }

        callback(this.chatMessages);
      }
    );
    return;
  };

  /**
   *
   * @param userId my user Id
   * @param groupMembers chat-group members
   * @param title group title
   * @returns
   */
  createGroup = async (
    userId: string,
    gropMembers: ChatUserModel[],
    title: string
  ): Promise<boolean> => {
    const groupRef = doc(this.chatGroupRef());

    var groupChat: IChatGroup = {
      id: groupRef.id,
      type: ChatType.GROUP,
      title: title,
      createdAt: moment().unix(),
      modifiedAt: moment().unix(),
      recentMessage: null,
      members: [...gropMembers.flatMap((m) => m.uid), userId],
      createdBy: userId,
      seen: true,
    };

    var result = false;
    await setDoc(doc(this.chatGroupRef(), groupRef.id), groupChat, {
      merge: true,
    })
      .then(function (docRef) {
        result = true;
      })
      .catch(function (error) {
        // reject(error);
        result = false;
      });
    return result;
  };

   /**
   *
   * @param userId my user Id
   * @param recentMessage recent message
   * @returns
   */
   updateGroupchatGroup = async (
    userId: string,
    groupId: string,
    recentMessage: IChatMessage,
  ): Promise<boolean> => {

    var data: IChatGroupMessage;
    data = {
      recentMessage: recentMessage,
      seen: false,
    };

    var result = false;
    await setDoc(doc(this.chatGroupRef(), groupId), data, {
      merge: true,
    })
      .then(function (docRef) {
        result = true;
      })
      .catch(function (error) {
        // reject(error);
        result = false;
      });
    return result;
  };
  /**
   * update group details of the chat.
   */
  async updateChatGroup(
    sentBy: string,
    sentTo: string,
    groupId: string,
    recentMessage: IChatMessage,
    isNewGroup: boolean
  ) {
    //create group
    var data: IChatGroupMessage;
    data = {
      recentMessage: recentMessage,
      seen: false,
    };

    if (isNewGroup) {
      var d: IChatGroup = {
        id: groupId,
        type: ChatType.PRIVATE,
        createdAt: moment().unix(),
        modifiedAt: moment().unix(),
        recentMessage: recentMessage,
        members: [sentBy, sentTo],
        createdBy: sentBy,
        seen: false,
        title: null,
      };
      data = d;
    }

    await setDoc(doc(this.chatGroupRef(), groupId), data, { merge: true })
      .then(function (docRef) {
        //
      })
      .catch(function (error) {
        // reject(error);
      });
  }

  /**
   *
   * @param groupId chat-group Id
   * @returns
   */
  async checkIfGroupExists(groupId) {
    const docRef = doc(this.chatGroupRef(), groupId);
    var chatDoc = await getDoc(docRef);
    return chatDoc?.exists();
  }

  private async addMessageToFirestore(
    sentBy: string,
    message: IChatMessage,
    groupId: string,
    isNewGroup: boolean,
    chatType: ChatType,
    sentTo?: string | null | undefined
  ): Promise<boolean> {
    var result = false;

    var docref = message.id
      ? doc(
          collection(doc(this.chatMessageRef(), groupId.trim()), MESSAGES),
          message.id
        )
      : doc(collection(doc(this.chatMessageRef(), groupId.trim()), MESSAGES));
    message.id = docref.id;

    var ss = new Promise((resolve, reject) => {
      setDoc(docref, message)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });

    var response = await ss;
    if (!response) {
      // if (response && response instanceof firebase.firestore.DocumentReference) {
      //success
      if(chatType === ChatType.PRIVATE) {
      await this.updateChatGroup(
        sentBy,
        sentTo!,
        groupId!.trim(),
        message,
        isNewGroup
      );
      } else {
        await this.updateGroupchatGroup(sentBy, groupId!.trim(), message)
      }
      result = true;
    } else {
      //error
    }
    return result;
  }

  /**
   *
   * @param groupId chat-group id
   */
  async markSeen(groupId) {
    //TODO: update seen status if the message is not mine
    await setDoc(
      doc(this.chatGroupRef(), groupId),
      { seen: true },
      { merge: true }
    )
      .then(function (docRef) {
        //
      })
      .catch(function (error) {
        // reject(error);
      });
  }

  resolveUsers = async (userId) => {
    for (let index = 0; index < this.chatGroups.length; index++) {
      const c = this.chatGroups[index];

      for (let userIndex = 0; userIndex < c.members?.length ?? 0; userIndex++) {
        const memberId = c.members[userIndex];
        if (!c.memberDetails || !c.memberDetails[memberId]) {
          const u = await this.getChatUser(memberId);
          if (u) {
            c.memberDetails = { ...(c.memberDetails ?? {}) };
            c.memberDetails[memberId] = u;
          }
        }
      }

      if (
        c.type == ChatType.PRIVATE &&
        !c.otherUser &&
        c.members &&
        c.members.length > 1
      ) {
        const otherUserId =
          c.members[0] !== userId ? c.members[0] : c.members[1];
        c.otherUser =
          c.memberDetails && c.memberDetails[otherUserId]
            ? c.memberDetails[otherUserId]
            : await this.getChatUser(otherUserId);
      }
    }
  };

  async getChatUser(userId) {
    let user = this.chatUsers.find((u) => u.uid === userId);
    if (!user) {
      try {
        const userDoc = await getDoc(
          doc(collection(firestore, "users"), userId)
        );
        if (userDoc.exists()) {
          console.log("Document data:", userDoc.data());
          const data = userDoc.data();
          if (data !== null && data !== undefined) {
            user = new ChatUserModel({
              uid: userId,
              firstName: data.name,
              lastName: null,
              city: data.cityName,
              accountType: data.accountType,
              photoUrl: data.photoUrl,
            });
            this.chatUsers.push(user);
          }
        } else {
          console.log("No user document!");
        }
      } catch (error) {}
    }
    return user;
  }

  /**
   *
   * @param sentBy my userId
   * @param sentTo userId of the receiver
   * @param photo image to send
   * @param file file
   * @param progressCallback progress of the upload
   * @param currentGroupId groupId if these two users have already a chat.
   * @returns
   */

  sendImageMessage = async (
    sentBy: string,
    sentTo: string,
    photo: any,
    file: any,
    progressCallback: (progress: number) => void,
    currentGroupId?: string
  ): Promise<boolean> => {
    var isNewGroup = false;
    if (!currentGroupId || currentGroupId?.trim() === "") {
      currentGroupId = this.composeGroupId(sentBy, sentTo);
      isNewGroup = !(await this.checkIfGroupExists(currentGroupId));
    }
   return await  this._sendImageMessage(ChatType.PRIVATE, sentBy, currentGroupId, photo, file, progressCallback, isNewGroup, sentTo)
  }

   /**
   *
   * @param sentBy my userId
   * @param currentGroupId groupId if these two users have already a chat.
   * @param photo image to send
   * @param file file
   * @param progressCallback progress of the upload
   * @returns
   */
   sendImageMessageToGroup = async (
    sentBy: string,
    currentGroupId: string,
    photo: any,
    file: any,
    progressCallback: (progress: number) => void
  ): Promise<boolean> => {
    return await  this._sendImageMessage(ChatType.GROUP, sentBy, currentGroupId, photo, file, progressCallback, false, null)
  }


  _sendImageMessage = async (
    type: ChatType,
    sentBy: string,
    currentGroupId: string,
    photo: any,
    file: any,
    progressCallback: (progress: number) => void,
    isNewGroup: boolean,
    sentTo?: string | null,
  ): Promise<boolean> => {
    var result = false;
    // upload photo to firestore:
    try {
      var messageDocRef = doc(
        collection(doc(this.chatMessageRef(), currentGroupId.trim()), MESSAGES)
      );
      const response = await uploadFilePromise(
        file,
        photo.type,
        photo.path,
        `${messageDocRef.id}`,
        `chatData/${currentGroupId}`,
        (progress) => {
          progressCallback && progressCallback(Math.floor(progress));
        }
      );

      progressCallback && progressCallback(0);

      console.log("sendImageMessage - upload result:", response);

      const message: IChatMessage = {
        id: messageDocRef.id,
        messageText: response.downloadUrl,
        type: MessageType.IMAGE,
        sentAt: moment().unix(),
        sentBy: sentBy
      };
      if(sentTo) {
        message.sentTo = sentTo;
      }
      result = await this.addMessageToFirestore(
        sentBy,
        message,
        currentGroupId!.trim(),
        isNewGroup,
        type,
        sentTo
      );

    } catch (e) {
      console.error("handleProfilePhotoInput - uploading error", e);
      // setPhotoUploadError(true);
      // setPhotoOK(false);
    }
    return result;
  };

 
  /*
        chatuserRef() {
      return firestore.collection(CHAT_USER);
    }

    chatMessagesRef(groupId) {
      return firestore.collection(CHAT_MESSAGES).doc(groupId).collection(MESSAGES);
    }

  addGroupToUser(userId, groupId) {
    var washingtonRef = this.chatUserRef(userId);

    // Atomically add a await new region to the "regions" array field.
    var arrUnion = washingtonRef.update({
      "chat-group": firebase.firestore.FieldValue.arrayUnion(),
    });
  }*/

  //------------References and Queries---------------------
  chatGroupRef() {
    return collection(firestore, CHAT_GROUP);
  }

  chatMessageRef() {
    return collection(firestore, CHAT_MESSAGES);
  }

  chatUserRef(userId) {
    return doc(collection(firestore, CHAT_USER), userId);
  }

  queryGroupsByUserId(userId) {
    return query(
      this.chatGroupRef(),
      where("members", "array-contains", userId)
    );
  }

  queryMessagesByGroupId(groupId) {
    return collection(doc(this.chatMessageRef(), groupId), "messages");
  }

  queryUnseenMessagesCount() {
    //TODO: groups which has messages from another user which is not seen yet.
  }

  //---------------Data helper------------------
  //------------Group Chat----------------
  composeGroupId(firstUserId, secondUserId) {
    return firstUserId < secondUserId
      ? firstUserId + "_" + secondUserId
      : secondUserId + "_" + firstUserId;
  }

  addGroup = (data: any) => {
    try {
      const newGroup: IChatGroup = data;
      if (newGroup && newGroup.id) {
        this.chatGroups.push(newGroup);
        this.chatGroups.sort((a, b) =>
          a.recentMessage &&
          b.recentMessage &&
          a.recentMessage.sentAt < b.recentMessage.sentAt
            ? 1
            : -1
        );
      }
    } catch (e) {}
  };

  removeGroup = (data: any) => {
    try {
      const newGroup: IChatGroup = data;
      if (newGroup && newGroup.id) {
        const index = this.chatGroups.findIndex(
          (item) => item.id === newGroup.id
        );
        if (index > -1) {
          this.chatGroups.splice(index, 1);
        }
      }
    } catch (e) {}
  };

  updateGroup = (data: any) => {
    try {
      const newGroup: IChatGroup = data;
      this.removeGroup(newGroup);
      this.addGroup(newGroup);
    } catch (e) {}
  };

  //-------------Chat Messages--------------
  addMessage = (data: any, isLocal: boolean = false) => {
    try {
      const newMessage: IChatMessage = data;
      newMessage.isLocal = isLocal;
      if (newMessage && newMessage.sentBy && newMessage.messageText) {
        this.chatMessages.push(newMessage);
        this.chatMessages.sort((a, b) => (a.sentAt > b.sentAt ? 1 : -1));
      }
    } catch (e) {}
  };

  updateMessage = (data: any) => {
    try {
      const newMessage: IChatMessage = data;
      this.removeMessage(newMessage);
      this.addMessage(newMessage);
    } catch (e) {}
  };

  removeMessage = (data: any) => {
    try {
      const newMessage: IChatMessage = data;
      if (newMessage && newMessage.id) {
        const index = this.chatMessages.findIndex(
          (item) => item.id === newMessage.id
        );
        if (index > -1) {
          this.chatMessages.splice(index, 1);
        }
      }
    } catch (e) {}
  };
}
