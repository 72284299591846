import React from "react";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { ReactComponent as ReadingGlasses } from "../../../assets/icons/light-eyeglasses-.svg";
import { ReactComponent as Knot } from "../../../assets/icons/knot.svg";
import "./mainArticle.css";
import ShowMoreText from "react-show-more-text";
import commonColor from "../../../theme/commonColor";

const MainArticle = ({ item }) => {
  return (
    <div
      key={item.sys.id}
      className="article w-full bg-white rounded-2xl darker_shadow flex flex-col md:flex-row"
      style={{
        // maxWidth: "48rem",
        // maxHeight: "18rem",
        borderRadius: "1rem",
      }}
    >
      {item.fields.thumbnail && (
        <div
          className="image-container flex justify-center flex-shrink-0 items-center w-full md:w-1/2 p-4 rounded-xl m-auto"
          style={{
            maxHeight: "18rem",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              overflow: "hidden",
              height: "16rem",
            }}
          >
            <img
              src={item.fields.thumbnail.fields.file.url}
              loading="lazy"
              alt="l3arn"
              className="contain rounded-xl"
              style={{
                height: "100%",
                width: "auto",
                overflow: "hidden",
                objectFit: "cover",
              }}
            />
          </div>
        </div>
      )}
      <div className="h-full flex-grow md:h-auto md:p-4 md:flex md:flex-col md:justify-start">
        {item.fields.title && (
          <div
            className="font-bold px-3 py-2 text-sm "
            style={{
              maxHeight: "5rem",
            }}
          >
            {item.fields.title}
          </div>
        )}
        {item.fields?.hashtags && item.fields?.hashtags.length > 0 && (
          <div
            className="w-full px-1 py-1 text-xs flex flex-wrap"
            style={{ color: commonColor.deepGreen }}
          >
            {item.fields?.hashtags
              .map((h) => `#${h?.fields?.name}`)
              .join(" ")
              .replaceAll("##", "#")}
          </div>
        )}

        {item.fields.content && (
          <ShowMoreText
            lines={3}
            className="h-auto px-3 py-2 overflow-y-hidden text-xs flex-grow"
            more="Show more"
            less="Show less"
            expanded={false}
            width={650}
            truncatedEndingComponent={"... "}
          >
            <div
              style={{
                maxHeight: "9.5rem",
                overflowY: "scroll",
              }}
              dangerouslySetInnerHTML={{
                __html: documentToHtmlString(item.fields.content),
              }}
            ></div>
          </ShowMoreText>
          // <div
          //   className="flex-grow px-3 py-2 overflow-y-scroll text-xs"
          //   dangerouslySetInnerHTML={{
          //     __html: documentToHtmlString(item.fields.content),
          //   }}
          // />
        )}
        {item.fields.source && (
          <div
            className=" flex justify-center items-center py-2 px-3"
            style={{
              maxHeight: "2.5rem",
            }}
          >
            {/* {item.fields.source.fields &&
            item.fields.source.fields.thumbnail ? (
              <img
                src={item.fields.source.fields.thumbnail?.fields.file.url}
                loading="lazy"
                alt=""
                style={{
                  height: "1.5rem",
                  overflow: "hidden",
                }}
              />
            ) : (
              <Knot
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  overflow: "hidden",
                }}
              />
            )} */}

            {item.fields?.source?.fields?.sourceUrl && (
              <a href={item.fields.source.fields.sourceUrl} target="_blank">
                <ReadingGlasses
                  style={{
                    width: "2rem",
                    height: "2rem",
                    overflow: "hidden",
                  }}
                />
              </a>
            )}
            {/* <img src={item.fields.source.fields.thumbnail?.fields.file.url} alt="" /> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default MainArticle;
