import React, { useState, useEffect } from "react";
import { ReactComponent as LightBulb } from "../../../assets/icons/creative-process.svg";
import { ReactComponent as WomanHair } from "../../../assets/icons/woman-hair.svg";
import { ReactComponent as Headset } from "../../../assets/icons/headset (1).svg";
import { ReactComponent as GradHat } from "../../../assets/icons/grad-hat.svg";
import { ReactComponent as ReadingBook } from "../../../assets/icons/reading-book.svg";

import GenericProfilePhoto from "../../../assets/images/tutor_base.jpg";
import commonColor from "../../../theme/commonColor";
import Swotbutton from "../../common/buttons/swotbutton";
import { getCountryNameByCityName } from "../../../services/location";
import { countriesList } from "../../../services/countrieslist";
import { firestore } from "../../../config/firebase";
import { collection, doc, setDoc, onSnapshot } from "firebase/firestore";
import { ReactComponent as RequestCall } from "../../../assets/images/courses/phone-1.svg";
import { tutorCallRequest } from "../../../services/api";
import swal from "sweetalert2";

function toTitleCase(str) {
  if (str === null || str === undefined) {
    return str;
  }
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const TutorAboutVertical = ({
  user,
  about,
  courseDetails,
  sendMessage,
  registerAlert,
}) => {
  const [isOnline, setIsOnline] = useState(false);
  const [countryName, setCountryName] = useState("");

  const getCountryName = async () => {
    if (about.countryName) {
      let tmpCountryName;

      if (about.countryName !== "NonUSA") {
        tmpCountryName = about.countryName;
      } else {
        let findCountryName = await getCountryNameByCityName(about.cityName);
        console.log("getting Country Name:", findCountryName);
        tmpCountryName = findCountryName;
      }

      let seletedCountryOpt = countriesList.find(
        (o) => o.value === tmpCountryName
      );
      console.log("seletedCountryOpt :", seletedCountryOpt.label);
      setCountryName(seletedCountryOpt.label);
    }
  };

  useEffect(() => {
    getCountryName();
  });

  useEffect(() => {
    if (courseDetails?.tutorFirebaseId) {
      let tutorStatusFirestoreRef = doc(
        firestore,
        "/status/" + courseDetails?.tutorFirebaseId
      );
      onSnapshot(tutorStatusFirestoreRef, function (doc) {
        // tutorStatusFirestoreRef.onSnapshot(function (doc) {
        if (doc?.data()) {
          var isTutorOnline = doc?.data()?.state === "online";
          setIsOnline(isTutorOnline);
        }
      });
    }
  }, [isOnline]);

  const makeCallRequest = () => {
    swal
      .fire({
        title: "Call Request",
        text: "Are you sure you want to send a call request to this tutor?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const baseUrl = window.location.href;
          const payload = {
            fallback: baseUrl,
            tutor_username: courseDetails?.tutorUsername,
            student_username: user?.username,
            tutor_firebase_id: courseDetails?.tutorFirebaseId,
          };
          let res = await tutorCallRequest(payload);

          console.log("payload:", payload, " res:", res);

          if (res?.data?.status === "SUCCESS") {
            await setDoc(
              doc(
                collection(firestore, "call-request"),
                courseDetails?.tutorFirebaseId
              ),
              {
                tutor_meet_link: res?.data?.data.tutor_meet_link,
                student_meet_link: res?.data?.data.student_meet_link,
                student_username: user?.username,
                tutor_username: courseDetails?.tutorUsername,
                state: "pending",
              },
              { merge: true }
            ).then(() => {
              window.location.href = res?.data?.data.student_meet_link;
            });
          } else {
            swal.fire({
              title: "Error",
              text: "Unable to make call request",
              icon: "error",
              showCancelButton: true,
              cancelButtonColor: "#d33",
            });
          }
        }
      });
  };

  return (
    <div
      className="w-full h full flex flex-col darker_shadow sm:flex-row bg-white items-center"
      style={{ borderRadius: "1.5rem" }}
    >
      <div className="tutorInfoPhoto md:w-6/12 w-6/12  sm:pt-2 px-2 py-2 sm:pl-2 mb-4 h-48 lg:h-9/10">
        <img
          className="w-auto object-cover"
          style={{ borderRadius: "25px", height: "100%" }}
          src={
            about && about.tutorPhotoUrl
              ? about.tutorPhotoUrl
              : GenericProfilePhoto
          }
          alt="tutorPhoto"
        />
      </div>

      <div className="w-full pl-4 sm:pl-5 px-2 text-left pb-2">
        {isOnline && (
          <span
            style={{
              float: "left",
              marginRight: "5px",
              marginTop: "5px",
              cursor: "pointer",
            }}
          >
            <RequestCall
              style={{ float: "left" }}
              onClick={makeCallRequest}
              className="w-4"
            />
            <svg
              style={{ float: "left", marginRight: "5px" }}
              height="18"
              width="18"
              className="blinking"
            >
              <circle cx="9" cy="9" r="6" fill="#2ecc71" />
            </svg>
          </span>
        )}

        {!isOnline && (
          <span
            style={{
              cursor: "pointer",
            }}
          >
            <svg
              style={{ float: "left", marginTop: "5px" }}
              height="18"
              width="18"
              className="blinking"
            >
              <circle cx="9" cy="9" r="6" fill="#e74c3c" />
            </svg>
          </span>
        )}

        <p className="mb-2">
          <span className="font-bold text-lg pb-1">About the tutor </span>
        </p>

        <div className="flex flex-col">
          <span className="text-sm" style={{ textTransform: "capitalize" }}>
            <span className="font-bold">Fullname:</span>{" "}
            {toTitleCase(about.firstName)} {toTitleCase(about.lastName)}
          </span>

          {about.email && (
            <span className="text-sm" style={{ textTransform: "capitalize" }}>
              <span className="font-bold">Contact:</span> {about.email}
            </span>
          )}

          <span className="text-sm">
            <span className="font-bold">Students enrolled: </span>
            {about.studentsEnrolled}
          </span>

          <span className="text-sm">
            <span className="font-bold">Courses: </span> {about.courseCount}
          </span>
        </div>

        <div className="text-sm">
          <span className="font-bold ">From: </span>
          <span className="">
            {about.cityName}
            {about.cityName && about.stateCode ? ", " : ""}
            {about.stateCode}
            {", " + countryName}
            <br />
          </span>

          <span className="font-bold">
            {about.teachingStyle ? "Teaching Style" : ""}
          </span>
          <span className="">
            {about.teachingStyle ? about.teachingStyle : ""}
          </span>

          <span className="italic text-xs pt-2">
            Special Education is{" "}
            {about.specialEducation ? "supported" : "not supported"} by{" "}
            {about.firstName}
          </span>
        </div>

        <div className="w-full flex flex-col items-center">
          <div className="p-1">
            <Swotbutton
              text="Send Message"
              onClick={() => {
                if (registerAlert) {
                  registerAlert();
                } else {
                  sendMessage && sendMessage();
                }
              }}
            />
          </div>

          {isOnline && (
            <div className="p-1">
              <Swotbutton
                text="Call"
                onClick={() => {
                  if (registerAlert) {
                    registerAlert();
                  } else {
                    makeCallRequest();
                  }
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TutorAboutVertical;
