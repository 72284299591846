import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import ReactPlayer from "react-player/lazy";
import "./styles.css";
import UserActions from "../../../redux/user";
import Select from "../../common/input/select";
import TimeSlot from "../../calendar/timeSlot";
import {
  getCourseDetails,
  getTutorAbout,
  getStudentEnrolledCourses,
  getCourseStudentCalendarView,
  getCourseContents,
  bookCourseSession,
  getOpenCoursesForStudent,
} from "../../../services/api";
import { capitalize, useWindowSize } from "../../../utils/uiUtils";
import moment from "moment";
import {
  saveBookingPayload,
  getSavedBookingPayload,
  removeSavedBookingPayload,
} from "../../../services/paymentsManagement";
import Swotbutton from "../../../components/common/buttons/swotbutton";
import commonColor from "../../../theme/commonColor";
import StudentList from "../../../components/tutor/studentList";
import StudentCalendar from "../calendar/calendar";
import TutorAbout from "./tutorAbout";
import GenericProfilePhoto from "../../../assets/images/tutor_base.jpg";
import GutterHeader from "../../calendar/gutterHeader";
import AgendaEvent from "../../calendar/agendaEvent";
import CourseContainer from "../../../containers/courseContainer";
import CourseContent from "../../../screens/courses/coursesTutor/CourseContent";
import MaterialUploaderViewer from "../../courses/materialUploaderViewer";
import CourseMaterial from "../../courses/courseMaterial";

import CustomFadeLoader from "../../common/CustomFadeLoader";
import CustomToolbar from "../../calendar/customToolbar";
import TutorAboutVertical from "./TutorAboutVertical";
import Swal from "sweetalert2";
import { ReactComponent as About } from "../../../assets/images/courses/about.svg";
import { ReactComponent as Content } from "../../../assets/images/courses/content.svg";
import { ReactComponent as Resources } from "../../../assets/images/courses/resources.svg";
import { ReactComponent as Schedule } from "../../../assets/images/courses/schedule.svg";
import { ReactComponent as Student } from "../../../assets/images/courses/student.svg";
import FooterView from "../../common/footerView";
import { ACCOUNT_TYPES, EventType } from "../../../config/constants";
import calendar from "../calendar/calendar";
import Modal from "../../common/modal/modal";
import NewMessageComponent from "../../../chat/components/NewMessageComponent";
import ChatUserModel from "../../../chat/models/chatUser";
import { historyState } from "../../../services/navigation/history";

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const CourseLandingPage = ({
  user,
  courseId,
  notifications,
  paymentStatus,
  guid,
  showPopover,
  saveUser
}) => {
  const navigate = useNavigate();
  const [isUserEnrolled, setIsUserEnrolled] = useState(false);
  const [courseDetails, setCourseDetails] = useState();
  const [courseLoaded, setCourseLoaded] = useState(false);
  const [noCoursFound, setNoCoursFound] = useState(false);
  const [studentCourses, setStudentCourses] = useState([]);
  const [myLessons, setMyLessons] = useState([]);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [topSelected, setTopSelected] = useState("Schedule");
  const [bottomSelected, setBottomSelected] = useState();
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [fetchMyCourses, setFetchMyCourses] = useState(courseId ? null : true);
  const [courseCalendarInfo, setCourseCalendarInfo] = useState();
  const [selectedCourseNameShort, setSelectedCourseNameShort] = useState();
  const [tutorAbout, setTutorAbout] = useState();
  const [calendarNeedsRefresh, setCalendarNeedsRefresh] = useState(true);
  const [eligibleSession, setEligibleSession] = useState();
  const [joinSessionUrl, setJoinSessionUrl] = useState();
  const [joinSessionCourseId, setJoinSessionCourseId] = useState();
  const [courseContent, setCourseContent] = useState([]);
  const [courseAssignments, setCourseAssignments] = useState([]);
  const [courseResources, setCourseResources] = useState([]);
  const [width, height] = useWindowSize();
  const [sendMessageToTutor, setSendMessageToTutor] = useState(false);

  useEffect(() => {
    if (selectedCourseId != courseId) {
      setCourseLoaded(false);
      setSelectedCourseId(courseId ? courseId : null);
    }
  }, [courseId]);

  useEffect(() => {
    const bookingApi = async () => {
      if (
        guid &&
        paymentStatus &&
        user?.uid &&
        localStorage.getItem(user.uid)
      ) {
        const s = getSavedBookingPayload(user.uid, guid);
        const savedCourseId = s?.courseId;
        const savedBooking = s?.booking;

        if (savedBooking && paymentStatus === "success") {
          if (!s.popupShown) {
            Swal.fire({
              title: "Success!",
              text: "Payment done for the course!",
              icon: "success",
              confirmButtonText: "OK",
              confirmButtonColor: "#407288",
            });
            s.popupShown = true;
            s.paid = true;
            saveBookingPayload(user.uid, guid, s);
          }
          setTimeout(() => {
            bookPaidCourse(guid, savedBooking, 0);
          }, 1000);
        } else if (savedBooking && paymentStatus === "cancel") {
          Swal.fire({
            title: "Failed!",
            text: "Payment canceled!",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      }
    };

    bookingApi();
  }, [guid]);

  const bookPaidCourse = async (guid, savedBooking, counter) => {
    const response = await bookCourseSession(user.uid, savedBooking);
    if (response && response.status === 200) {
      console.log("Booked session, refreshing", response);
      //remove local storage if success
      removeSavedBookingPayload(user.uid, guid);

      refreshCoursesPage();
    } else {
      console.log("Booking session, error", response?.data);
      if (counter < 3) {
        setTimeout(() => {
          bookPaidCourse(guid, savedBooking, counter + 1);
        }, 1000);
      }
    }
  };

  // useEffect(() => {
  //   const getCourseInfo = async () => {
  //     const result = await getStudentEnrolledCourses(user.uid);
  //     if (result) {
  //       if ((result.data?.courses?.length ?? 0) <= 0) {
  //         setNoCoursFound(true);
  //       } else {
  //         courseId = result.data.courses[0].courseId;
  //         setSelectedCourseId(result.data.courses[0].courseId);
  //       }
  //     }
  //   };

  //   !courseId && getCourseInfo();
  // }, [fetchMyCourses]);

  useEffect(() => {
    const getCurseContent = async () => {
      const result = await getCourseContents(selectedCourseId);
      if (result && result.data && Array.isArray(result.data.data)) {
        console.log(
          "CourseLandingPage - useEffect - got tutorAbout",
          result.data
        );

        setCourseContent(
          result.data.data.filter((i) => i.content_type === "content")
        );
      }
    };
    selectedCourseId && getCurseContent();
  }, [selectedCourseId]);

  useEffect(() => {
    console.log(
      "CourseLandingPage - useEffect - select value ",
      selectedCourseId,
      selectedCourseNameShort,
      courseCalendarInfo,
      courseDetails,
      isUserEnrolled,
      eligibleSession
    );
  }, [
    selectedCourseId,
    selectedCourseNameShort,
    courseCalendarInfo,
    courseDetails,
    isUserEnrolled,
    eligibleSession,
  ]);

  useEffect(() => {
    selectedCourseId && getCalendarInfo();
  }, [selectedCourseId]);

  useEffect(() => {
    console.log(
      "CourseLandingPage - useEffect - notifications updated",
      notifications
    );
    if (notifications) {
      if (notifications.timeStart && notifications.timeEnd) {
        const starttime = moment.unix(notifications.timeStart);
        const endtime = moment.unix(notifications.timeEnd);
        if (moment().isSameOrAfter(starttime) && moment().isBefore(endtime)) {
          setJoinSessionCourseId(notifications.courseId);
          setJoinSessionUrl(notifications.joinUrl);
        }
      }
    }
  }, [notifications]);

  const getCalendarInfo = async () => {
    const result = await getCourseStudentCalendarView(
      selectedCourseId,
      user.uid
    );
    if (result && result.data) {
      console.log(
        "CourseLandingPage - useEffect - getCalendarInfo",
        result.data
      );
      const calendarEvents = [
        ...result.data.courseSessionsBookedByOthersArray,
        ...result.data.sessionAvailableArray,
        ...result.data.studentAllCoursesBookedSessionsArray,
        ...result.data.webinarsArray,
      ];
      console.log(
        "CourseLandingPage - useEffect - calendarEvents",
        calendarEvents
      );
      setCourseCalendarInfo(calendarEvents);
      setCalendarNeedsRefresh(false);
    }
  };

  const getTutorAboutInfo = async () => {
    const result = await getTutorAbout(courseDetails.tutorFirebaseId);
    if (result) {
      console.log(
        "CourseLandingPage - useEffect - got tutorAbout",
        result.data
      );
      setTutorAbout(result.data?.about);
    }
  };

  useEffect(() => {
    if (!isUserEnrolled && courseDetails && !tutorAbout) {
      getTutorAboutInfo();
    }
  }, [isUserEnrolled, courseDetails, tutorAbout]);

  useEffect(() => {
    console.log(
      "CourseDetails - useEffect - selectedCourseId / courseLoaded",
      selectedCourseId,
      courseLoaded
    );
    const getCourseInfo = async () => {
      const result = await getCourseDetails(selectedCourseId);
      if (result && result.data) {
        console.log(
          "CourseDetails - useEffect - got course details",
          result.data
        );
        setCourseDetails(result.data);
        setSelectedCourseNameShort(result.data.nameShort);
        setCourseLoaded(true);
      }
    };

    if (!courseLoaded && selectedCourseId) {
      getCourseInfo();
    }
  }, [selectedCourseId, courseLoaded]);

  useEffect(() => {
    // setTopSelected("Schedule")
    setBottomSelected("About");

    // TODO: getCoursesOfStudent, decide if student is enrolled in course and show the relevant parts.

    if (
      user.accountType === ACCOUNT_TYPES.STUDENT ||
      user.accountType === ACCOUNT_TYPES.SWOT_ADMIN
    ) {
      getStudentCourses();
    }
  }, [selectedCourseId]);

  const getStudentCourses = async () => {
    const result = await getStudentEnrolledCourses(user.uid);
    if (result) {
      console.log(
        "CourseDetails - useEffect - getStudentEnrolledCourses",
        result.data
      );
      if ((result.data?.courses?.length ?? 0) <= 0) {
        setNoCoursFound(true);
      } else {
        setStudentCourses(result?.data?.courses);
      }
    }
  };

  const refreshCoursesPage = () => {
    if (
      user.accountType === ACCOUNT_TYPES.STUDENT ||
      user.accountType === ACCOUNT_TYPES.SWOT_BUDDY
    ) {
      getStudentCourses();
    }
    getCalendarInfo();
  };

  useEffect(() => {
    if (studentCourses && studentCourses.length > 0) {
      let studentLessons = studentCourses.map((course) => {
        const ce = calendarEvents?.find((c) => c.courseId === course.courseId);
        const timeValue = ce ? " - " + ce.day + " " + ce.time : "";
        return { value: course.courseId, label: course.nameShort + timeValue };
      });

      if (calendarEvents) {
        const futureLessons = calendarEvents.reduce(
          (previousValue, currentValue) => {
            const f = studentLessons.find(
              (l) => l.value === currentValue.courseId
            );
            if (f) {
              previousValue.push(f);
            }
            return previousValue;
          },
          []
        );

        const oldLessons = studentLessons.filter((el) => {
          return !futureLessons.find((f) => f.value === el.value);
        });
        studentLessons = [...futureLessons, ...oldLessons];
      }
      setMyLessons(studentLessons);
      console.log(
        "CourseDetails - getStudentCourses - studentCourses + selectedCourseId",
        studentLessons,
        selectedCourseId
      );
      const matchingCourse = studentCourses.filter(
        (c) => c.courseId == selectedCourseId
      );

      if (!courseId && !selectedCourseId) {
        courseId = studentLessons[0].value;
        setSelectedCourseId(studentLessons[0].value);
      }

      console.log(
        "studentCourses - useEffect - matching courses + selectedCourseId", matchingCourse, selectedCourseId
      );
      if (matchingCourse.length > 0) {
        // TODO - is false for DEV purposes, change to TRUE!!!!
        setIsUserEnrolled(true);
      } else {
        setIsUserEnrolled(false);
      }
    } else {
      console.log(
        "studentCourses - useEffect - Empty studentCourses"
      );
    }
  }, [studentCourses, calendarEvents]);

  useEffect(() => {
    const getCourseInfo = async () => {
      const result = await getOpenCoursesForStudent(user.uid);

      if (result?.data?.courses) {
        let courses = result.data.courses;
        console.log("courseLandingPage - useEffect - getCourseInfo", result);
        courses.sort((a, b) => (a.sessionStart > b.sessionStart ? 1 : -1));
        const uniqueCourses = Array.from(
          new Set(courses.map((c) => c.courseId))
        ).map((id) => {
          return courses.find((c) => c.courseId === id);
        });
        const preparedCourses = uniqueCourses.map((course) => {
          const isToday = moment
            .unix(course.sessionStart)
            .isSame(new Date(), "day");
          const day = isToday
            ? "Today"
            : moment.unix(course.sessionStart).format("ddd");
          const time = moment.unix(course.sessionStart).format("LT");
          const start = course.sessionStart;
          const end = course.sessionEnd;
          const title = course.nameShort;
          const type = course.type
            ? course.type
            : EventType.bookedCourseSession;
          const prepCourse = { ...course, day, time, start, end, title, type };
          return prepCourse;
        });
        setCalendarEvents(preparedCourses);
      }
    };

    getCourseInfo();
  }, [user.uid]);

  const renderCourseSessionButton = () => {};

  const renderTopSelectedTab = () => {
    if (topSelected === "Schedule") {
      return (
        <div className="w-full h-full flex justify-center items-center py-4 relative">
          {courseDetails && (
            <StudentCalendar
              user={user}
              handleReminders={false}
              view={"day"}
              views={["day"]}
              course={courseDetails}
              events={courseCalendarInfo}
              refreshCourse={() => refreshCoursesPage()}
              setEligibleSession={setEligibleSession}
              setJoinSessionUrl={setJoinSessionUrl}
              components={
                {
                  // agendaGutterHeader: () => (
                  //   <GutterHeader
                  //     labels={[
                  //       { title: "Time" },
                  //       { title: "Session", colorStyle: "tertiary" },
                  //     ]}
                  //   />
                  // ),
                  // agenda: {
                  //   event: AgendaEvent,
                  // },
                }
              }
            />
          )}
        </div>
      );
    } else if (topSelected === "Tutor") {
      // TODO
      if (!tutorAbout) {
        getTutorAboutInfo();
      }
      return (
        <div
          // className="w-full lg:h-full rounded-2xl shadow-2xl bg-white md:flex-grow"
          className="w-full lg:h-full md:flex-grow"
          style={
            {
              // minHeight: "25rem",
            }
          }
        >
          {tutorAbout && (
            <>
              <div className="w-full h-full sm:hidden">
                {/* <TutorAboutVertical*/}
                <TutorAboutVertical
                  user={user}
                  about={tutorAbout}
                  courseDetails={courseDetails}
                  sendMessage={() => {
                    //TODO: show send Message dialog
                    setSendMessageToTutor(true);
                  }}
                />
              </div>
              <div className="w-full h-full hidden sm:block pt-5 pl-3">
                <TutorAbout
                  user={user}
                  about={tutorAbout}
                  courseDetails={courseDetails}
                  sendMessage={() => {
                    //TODO: show send Message dialog
                    setSendMessageToTutor(true);
                  }}
                />
              </div>
            </>
          )}
        </div>
      );
    } else if (topSelected === "Students") {
      return (
        <div className="w-full h-full flex justify-center items-center pl-8 pr-16 py-12 overflow-y-scroll">
          <StudentList course={courseDetails?.nameShort} />
        </div>
      );
    }
  };

  const renderTutoringPreference = (course) => {
    if (course.tutoringPreference === "specEdu") {
      return <div className="font-bold"> {"Special Education"}</div>;
    } else if (course.tutoringPreference === "individuals") {
      return <div className="font-bold"> {"Individuals"}</div>;
    } else {
      return <div className="font-bold"> {"Groups"}</div>;
    }
  };

  const renderBottomSelectedTab = () => {
    if (bottomSelected === "About") {
      return (
        <div className="w-full h-full flex flex-col justify-start items-start p-4 relative overflow-y-scroll">
          <div className="text-sm md:text-lg break-all">
            {`${courseDetails.subject} - ${courseDetails.subjectArea}`}{" "}
            <span className="pb-2 italic text-sm">
              by{" "}
              {tutorAbout
                ? `${toTitleCase(tutorAbout.firstName)} ${toTitleCase(
                    tutorAbout.lastName
                  )} ${
                    !courseDetails.language
                      ? ""
                      : `taught in  ${toTitleCase(courseDetails.language)}`
                  }`
                : `${courseDetails.tutorUsername}  ${
                    !courseDetails.language
                      ? ""
                      : `taught in ${toTitleCase(courseDetails.language)}`
                  }`}
            </span>
            {courseDetails.rate != 0 ? `, Rate: ${courseDetails.rate}$/hr` : ""}
          </div>
          <div className="flex flex-start text-sm md:text-lg break-all">
            {renderTutoringPreference(courseDetails)}
          </div>
          <div className="text-xs pt-4">{courseDetails.summary}</div>
          {/* <button
            className="focus:outline-none focus:shadow-lg"
            style={{
              bottom: "0.75rem",
              right: "0.75rem",
              position: "absolute",
            }}
          >
            <Edit
              style={{
                width: "1.5rem",
                height: "1.5rem",
                overflow: "hidden",
                fill: commonColor.deepPink,
              }}
            />
          </button> */}
        </div>
      );
    } else if (bottomSelected === "Content") {
      return (
        <div className="w-full h-full flex justify-center items-center p-2 relative">
          {/* No content yet. */}
          {/* <button
            className="focus:outline-none focus:shadow-lg"
            style={{
              bottom: "0.75rem",
              right: "0.75rem",
              position: "absolute",
            }}
          >
            <Edit
              style={{
                width: "1.5rem",
                height: "1.5rem",
                overflow: "hidden",
                fill: commonColor.deepPink,
              }}
            />
          </button> */}
          <CourseContent
            courseId={selectedCourseId}
            items={courseContent}
            noItemsText="No lesson yet."
          />
        </div>
      );
    } else if (bottomSelected === "Resources") {
      return (
        <div className="w-full h-full flex justify-center items-center p-2 relative">
          {/* No resources yet. */}
          {/* <Swotbutton text={"Add Resource"} /> */}
          <MaterialUploaderViewer
            courseId={selectedCourseId}
            uploadPath={`courses/${selectedCourseId}/material/resources`}
            materialType="resources"
            noItemsText="No resources yet."
            itemsExisting={courseResources}
            updateCallback={(materialItems) => {
              setCourseResources(materialItems);
            }}
            readOnly={true}
            lockContent={!isUserEnrolled}
          />
        </div>
      );
    }
  };

  const goToCourseSession = async () => {
    console.log("Going to class!");
    window.open(joinSessionUrl, "_blank");
  };

  const renderCallButton = () => {
    if (
      joinSessionUrl &&
      selectedCourseId &&
      joinSessionCourseId === selectedCourseId
    ) {
      return (
        <div
          className="focus:outline-none focus:shadow-lg"
          style={{
            bottom: "1rem",
            right: "1rem",
            position: "absolute",
          }}
        >
          <Swotbutton text="go to class" onClick={() => goToCourseSession()} />
        </div>
      );
    } else {
      return (
        <div
          disabled
          className="focus:outline-none focus:shadow-lg"
          style={{
            bottom: "1rem",
            right: "1rem",
            position: "absolute",
          }}
        >
          <Swotbutton disabled={true} text="No session yet" />
        </div>
      );
    }
  };

  const quizPendingWidget = () => {
    return (
      <div className="w-full h-full flex flex-col justify-center items-center">
        <div
          className="bg-white p-5 w-full max-w-screen-sm h-full lg:max-h-1/2 flex flex-col justify-center"
          style={{
            boxShadow: `0 10px 25px 0 rgba(0, 0, 0, 0.6)`,
            borderRadius: 25,
            //height: '70%',
          }}
        >
          <div className="w-full h-full flex flex-col justify-center items-center">
            <div className="text-center">
              Please do the quiz to help us match you with great tutors.
            </div>
            <Swotbutton
              text="Take Quiz"
              onClick={() => {
                if (user.accountType === ACCOUNT_TYPES.SWOT_BUDDY) {
                  const updatedUser = {
                    ...user,
                    swotBuddySetup: "personalized",
                  };
                  saveUser(updatedUser);
                }
                navigate("/dashboard");
              }}
            />
          </div>
        </div>
        <FooterView />
      </div>
    );
  };

  return (
    <div className=" h-full w-full flex flex-col justify-between overflow-y-scroll">
      {/* {courseDetails && <CourseContainer courseId={`"${selectedCourseId}"`} />} */}
      {courseDetails && <CourseContainer courseId={`${selectedCourseId}`} />}
      {user && !user.quizDone ? (
        quizPendingWidget()
      ) : courseLoaded ? (
        <div className="h-full w-full flex flex-wrap justify-center pb-4">
          <div className="lg:h-[calc(100%-30px)] w-11/12 lg:w-2/4 xl:w-1/2 flex flex-col">
            <div className="lg:h-7/10 w-full px-2 lg:min-h-0">
              {isUserEnrolled ? (
                <div className=" w-full flex justify-between items-center lg:h-3/20">
                  <div className="w-1/4 text-white px-2">Lesson</div>
                  <div className="w-2/4 py-1">
                    <Select
                      className=" w-full focus:outline-none"
                      isRadiusUsed
                      //value={{ value: selectedCourseId, label: selectedCourseNameShort }}
                      defaultValue={{
                        value: courseDetails?.courseId,
                        label: courseDetails?.nameShort,
                      }}
                      value={
                        selectedCourseNameShort
                          ? {
                              value: selectedCourseId,
                              label: selectedCourseNameShort,
                            }
                          : myLessons[0]
                      }
                      onChange={(value) => {
                        console.log("CourseDetails- select- value", value);
                        setCourseLoaded(false);
                        setSelectedCourseId(value.value);
                        // setTutoringRegistration(value)
                        // handleChange('tutoringRegistration', value.value)
                      }}
                      options={myLessons}
                    />
                  </div>
                </div>
              ) : (
                <div className=" w-full flex justify-between items-center text-white pb-4 mt-2">
                  <div className="pl-4 text-white text-ellipsis">
                    {" "}
                    {courseDetails
                      ? courseDetails.nameFull
                      : "Mock lesson title"}
                  </div>
                </div>
              )}

              <div
                className="w-full  h-60 md:h-80 lg:h-17/20 bg-transparent darker_shadow"
                style={{
                  borderRadius: "1.5rem",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                {courseDetails?.videoUrl &&
                courseDetails?.videoUrl !== "undefined" &&
                courseDetails?.videoUrl !== "null" ? (
                  <ReactPlayer
                    url={courseDetails?.videoUrl}
                    width={"100%"}
                    height={"100%"}
                    style={{
                      borderRadius: 25,
                      backgroundColor: "black",
                    }}
                    playing={true}
                    controls={true}
                    light={
                      courseDetails?.videoThumbnailUrl
                        ? courseDetails?.videoThumbnailUrl
                        : true
                    }
                  />
                ) : courseDetails?.photoUrl ? (
                  <img
                    src={courseDetails.photoUrl}
                    alt="CourseDetailPhoto"
                    width={"100%"}
                    height={"100%"}
                    style={{
                      borderRadius: 25,
                      objectFit: "cover",
                      minWidth: "100%",
                      minHeight: "100%",
                    }}
                  />
                ) : (
                  <ReactPlayer
                    url="https://www.youtube.com/watch?v=saQBW9Xs5eI"
                    width={"100%"}
                    height={"100%"}
                    style={{
                      borderRadius: 25,
                    }}
                  />
                )}

                {renderCallButton()}
              </div>
            </div>

            <div className="lg:h-3/10 w-full px-2 pt-6 lg:pt-2 lg:min-h-0">
              <div
                className="lg:h-1/5 w-full flex justify-start items-end pl-6"
                style={
                  {
                    // height: "15%"
                  }
                }
              >
                <button
                  className="w-1/4 pt-2 px-2 pb-1 text-center text-white text-sm focus:outline-none"
                  style={{
                    backgroundColor:
                      bottomSelected === "About"
                        ? commonColor.deepPink
                        : "transparent",
                    borderTopLeftRadius: "1.5rem",
                    borderTopRightRadius: "1.5rem",
                  }}
                  onClick={() => setBottomSelected("About")}
                >
                  <div className="flex justify-center items-center">
                    <About className="w-4" />
                    <p className="hidden ml-2 md:block">About</p>
                  </div>
                </button>

                <button
                  className="w-1/4 pt-2 px-2 pb-1 text-center text-white text-sm focus:outline-none"
                  style={{
                    backgroundColor:
                      bottomSelected === "Content"
                        ? commonColor.deepPink
                        : "transparent",
                    borderTopLeftRadius: "1.5rem",
                    borderTopRightRadius: "1.5rem",
                  }}
                  onClick={() => setBottomSelected("Content")}
                >
                  <div className="flex justify-center items-center">
                    <Content className="w-4" />
                    <p className="hidden ml-2 md:block">Lesson</p>
                  </div>
                </button>

                <button
                  className="w-1/4 pt-2 px-2 pb-1 text-center text-white text-sm focus:outline-none"
                  style={{
                    backgroundColor:
                      bottomSelected === "Resources"
                        ? commonColor.deepPink
                        : "transparent",
                    borderTopLeftRadius: "1.5rem",
                    borderTopRightRadius: "1.5rem",
                  }}
                  onClick={() => setBottomSelected("Resources")}
                >
                  <div className="flex justify-center items-center">
                    <Resources className="w-4" />
                    <p className="hidden ml-2 md:block">Resources</p>
                  </div>
                </button>
              </div>
              <div
                className="w-full h-60 md-h-80 lg:h-4/5 bg-white darker_shadow flex items-center"
                style={{
                  borderRadius: "1.5rem",
                }}
              >
                {courseDetails && renderBottomSelectedTab()}
              </div>
            </div>
          </div>

          <div
            className="lg:h-[calc(100%-30px)] w-11/12 lg:w-2/4 xl:w-1/2 flex flex-col"
            style={{}}
          >
            {isUserEnrolled && (
              <div className="lg:h-7/10 w-full px-2">
                <div className="w-full flex justify-start items-end pl-6 pt-6 lg:h-3/20">
                  <button
                    className="w-1/4 pt-2 px-2 pb-1 text-center text-white text-sm focus:outline-none"
                    style={{
                      backgroundColor:
                        topSelected === "Schedule"
                          ? commonColor.deepPink
                          : "transparent",
                      borderTopLeftRadius: "1.5rem",
                      borderTopRightRadius: "1.5rem",
                    }}
                    onClick={() => setTopSelected("Schedule")}
                  >
                    <div className="flex justify-center items-center">
                      <Schedule className="w-4" />
                      <p className="hidden ml-2 md:block">Schedule</p>
                    </div>
                  </button>

                  <button
                    className="w-1/4 pt-2 px-2 pb-1 text-center text-white text-sm focus:outline-none"
                    style={{
                      backgroundColor:
                        topSelected === "Tutor"
                          ? commonColor.deepPink
                          : "transparent",
                      borderTopLeftRadius: "1.5rem",
                      borderTopRightRadius: "1.5rem",
                    }}
                    onClick={() => setTopSelected("Tutor")}
                  >
                    <div className="flex justify-center items-center">
                      <Student className="w-4" />
                      <p className="hidden ml-2 md:block">Tutor</p>
                    </div>
                  </button>
                </div>
                <div
                  className={`w-full  bg-white darker_shadow ${
                    topSelected !== "Schedule" ? "overflow-y-scroll" : ""
                  } lg:h-17/20`}
                  style={{
                    borderRadius: "1.5rem",
                    // height: "85%",
                  }}
                >
                  {renderTopSelectedTab()}
                </div>
              </div>
            )}

            {isUserEnrolled && (
              <div
                className="h-3/10 w-full px-2 pt-2 min-h-80 lg:min-h-0"
                style={{}}
              >
                <CourseMaterial
                  courseId={selectedCourseId}
                  user={user}
                  readOnly={true}
                  assignments={courseAssignments}
                  courseShortName={selectedCourseNameShort}
                  showPopover={(content) => {
                    if (content) {
                      // setPopoverContent(content);
                      showPopover && showPopover(content);
                      // setIsOpen(true);
                    }
                  }}
                />
              </div>
            )}

            {!isUserEnrolled && (
              <div className="h-full w-full flex flex-col">
                <div className="w-full h-full flex flex-col px-2 space-y-2 sm:space-y-0">
                  <div className=" w-full lg:h-[40px] flex justify-between items-center text-white pt-4 lg:pt-0 pb-4 mt-2">
                    <div className="pl-4 text-white">The tutor</div>
                  </div>
                  <div className="w-full flex flex-col px-2 lg:h-[calc(100%-40px)] ">
                    <div className="w-full flex flex-col lg:h-[18rem]">
                      <div className="w-full lg:h-full flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 my-2 lg:my-0">
                        <div
                          // className="w-full lg:h-full rounded-2xl shadow-2xl bg-white md:flex-grow"
                          className="w-full lg:h-full md:flex-grow"
                          style={
                            {
                              // minHeight: "25rem",
                            }
                          }
                        >
                          {tutorAbout && (
                            <>
                              <div className="w-full h-full sm:hidden">
                                <TutorAboutVertical
                                  user={user}
                                  about={tutorAbout}
                                  courseDetails={courseDetails}
                                  sendMessage={() => {
                                    setSendMessageToTutor(true);
                                  }}
                                />
                              </div>
                              <div className="w-full h-full hidden sm:block">
                                <TutorAbout
                                  user={user}
                                  about={tutorAbout}
                                  courseDetails={courseDetails}
                                  sendMessage={() => {
                                    setSendMessageToTutor(true);
                                  }}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* <div className="w-full flex-grow pl-2 pr-4 md:h-3/5 xl:h-7/10"> */}
                    <div className="w-full flex-grow lg:h-[calc(100%-18rem)]">
                      {/* <div className="w-full flex-grow Calendar-height"> */}
                      <div className="w-full h-full">
                        {" "}
                        <div className="pl-4 text-white lg:h-[40px]">
                          The tutors calendar
                        </div>
                        <div className="w-full lg:h-[calc(100%-40px)] rounded-2xl bg-white darker_shadow">
                          <StudentCalendar
                            user={user}
                            view={"day"}
                            views={["day"]}
                            course={courseDetails}
                            events={courseCalendarInfo}
                            refreshCourse={() => refreshCoursesPage()}
                            setEligibleSession={setEligibleSession}
                            setJoinSessionUrl={setJoinSessionUrl}
                            calendarClassName="sk-calendar"
                            components={{
                              timeSlotWrapper: (props) => (
                                <TimeSlot {...props} />
                              ),
                              timeGutterHeader: () => (
                                <>
                                  <div className="block">
                                    <GutterHeader
                                      labels={[
                                        {
                                          title: "Time",
                                        },
                                      ]}
                                    />
                                  </div>
                                </>
                              ),
                              day: {
                                header: () => (
                                  <div className="pl-2">
                                    <GutterHeader
                                      labels={[
                                        {
                                          title: "Lesson",
                                          colorStyle: "tertiary",
                                        },
                                      ]}
                                    />
                                  </div>
                                ),
                              },
                              toolbar: CustomToolbar,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <FooterView />
        </div>
      ) : noCoursFound ? (
        <div className="flex h-full justify-center flex-col items-center space-y-4">
          <p className="text-white">No course Found</p>
          <Swotbutton
            text={"Search Course"}
            onClick={() => navigate("/find-tutors")}
          />
        </div>
      ) : (
        <CustomFadeLoader radius="2" loading={true} color="#FFFFFF" />
      )}
      {!courseLoaded && (
        <div className="w-full">
          {" "}
          <FooterView />{" "}
        </div>
      )}

      <Modal
        isOpen={sendMessageToTutor}
        // bgColor="bg-trasparent"
        onRequestClose={() => {
          setSendMessageToTutor(false);
        }}
      >
        <div className="w-90v md:w-70v lg:w-50v max-h-80v text-center">
          {courseDetails?.tutorFirebaseId && (
            <NewMessageComponent
              myUserId={user.uid}
              toUserDetails={
                new ChatUserModel({
                  uid: courseDetails.tutorFirebaseId,
                  firstName: tutorAbout?.firstName,
                  lastName: tutorAbout?.lastName,
                  photoUrl: tutorAbout?.tutorPhotoUrl,
                  city: tutorAbout?.cityName,
                })
              }
              close={() => {
                setSendMessageToTutor(false);
              }}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  notifications: state.notifications.notifications,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseLandingPage);
