import React, { useEffect, useState } from "react";
import commonColor from "../../../theme/commonColor";
import SwotButton from "../../common/buttons/swotbutton";
import moment from "moment";
import { requestRefund } from "../../../services/api";
import Modal, { QuickModal } from "../../common/modal/modal";
import LoaderModal from "../../common/modal/loaderModal";
import { getStudentPaymentTransactions } from "../../../services/api";
import Select from "../../../components/common/input/select";
import { createCSV } from "../../../utils/csvGenerator";
import "./styles.css";

// SVGs
import { ReactComponent as Dots } from "../../../assets/icons/three-dots-menu.svg";

const Transactions = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [refundTransaction, setRefundTransaction] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [refundOptionSelected, setRefundOptionSelected] = useState(null);
  const [refundReason, setRefundReason] = useState(null);
  const [refundDisclosure, setRefundDisclosure] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [hiddenState, setHiddenState] = useState(true);

  const refundOptions = [
    {
      value: "Lesson never took place",
      label: "Lesson never took place",
    },
    {
      value: "I could not attend due to sickness",
      label: "I could not attend due to sickness",
    },
    {
      value:
        "The quality of the call was at a level that we needed to cancel the lesson",
      label:
        "The quality of the call was at a level that we needed to cancel the lesson",
    },
    {
      value: "Other",
      label: "Other",
    },
  ];

  // const statusColor = {
  //   succeeded: commonColor.flatGreen,
  //   pending: commonColor.sourCherry,
  //   refund_requested: commonColor.error,
  //   refund_rejected: commonColor.deepPink,
  //   refunded: commonColor.violet,
  // };

  useEffect(() => {
    getTransactions();
  }, []);

  const makeRefundRequest = async () => {
    if (
      !refundTransaction ||
      !refundOptionSelected ||
      (refundOptionSelected.value?.toLowerCase() === "other" && !refundReason)
    ) {
      return;
    }

    setLoading(true);
    const res = await requestRefund(
      refundTransaction.transaction_id,
      refundOptionSelected.value?.toLowerCase() === "other"
        ? refundReason
        : refundOptionSelected.value
    );
    console.log(res);
    if (res.data) {
      if (res.data.status === "SUCCESS") {
        console.log("Getting New Transaction");
        setTimeout(async () => {
          await getTransactions();
          setLoading(false);
        }, 2500);
      } else {
        if (res.data.errorMessage) {
          //TODO: show error
          setErrorMessage(res.data.errorMessage);
        }
        setLoading(false);
      }
    }
  };

  const getTransactions = async () => {
    const res = await getStudentPaymentTransactions(user.uid);
    if (res?.data?.status && res.data.status === "SUCCESS") {
      setTransactions(res.data.data ?? []);
    }
    // console.log("getTransactions: ", res);
  };

  const toggleHidden = (index) => {
    if (hiddenState === index) {
      return setHiddenState(null);
    }
    setHiddenState(index);
  };

  const cell = (trans, index) => {
    // const ammount = trans.amount + trans.commission;
    const ammount = trans.amount;
    const correctAmmount = (Math.round(ammount * 100) / 100).toFixed(2);

    return (
      <div
        key={index}
        className="parent_fee w-full flex flex-col justify-start"
      >
        <div
          className="w-full flex flex-col md:flex-row justify-start md:justify-between my-1 rounded-3xl p-3 shadow-sm"
          style={{
            background: "#ebebeb",
          }}
        >
          <div className="flex md:flex-grow flex-col md:flex-row">
            <div className="md:w-3/12 py-1 px-1 md:px-3 text-sm w-full text-center mb-2 md:mb-0">
              {moment.unix(trans.created_timestamp).format("MM-DD-YYYY")}
            </div>
            <div
              className="md:w-5/12 w-full text-center md:text-right py-1 px-1 md:px-3 text-sm cursor-pointer mb-2 md:mb-0"
              onClick={() => {
                setSelectedTransaction(trans);
              }}
            >
              {trans.course_fullname}
            </div>
            {/* Tutor Fee */}
            {/* <div className="w-2/12 py-1 px-3 text-sm">{trans.amount} (USD)</div> */}
            <div className="md:w-2/12 w-full text-center py-1 px-1 md:px-3 md:ml-3 text-sm md:text-right mb-2 md:mb-0">
              {correctAmmount}
            </div>
            <div
              className="md:w-2/12 w-full text-center nd:text-right py-auto px-1 md:px-3 flex justify-center z-10 cursor-pointer mb-2 md:mb-0"
              onClick={() => {
                toggleHidden(index);
              }}
              key={index}
            >
              <Dots
                className="w-2/12 h-6"
                style={{
                  fill: commonColor.deepGreen,
                }}
              />
            </div>
          </div>

          <div className="w-full md:w-24 flex flex-row items-center">
            <div className="w-full flex justify-center md:justify-center items-center">
              {trans.status === "succeeded" && (
                <SwotButton
                  text={"Refund"}
                  bgColor={commonColor.deepPink}
                  style={{
                    minWidth: "5rem",
                  }}
                  onClick={() => {
                    setRefundDisclosure(true);
                    setRefundTransaction(trans);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="w-full px-8 py-2">
          <div className="w-full bg-gray-300" style={{ height: "1px" }}></div>
        </div>
        {hiddenState === index ? (
          <div className="w-11/12 flex py-3 flex-wrap flex-col justify-end content-end">
            <div className="w-full md:w-4/12 ml-1 flex justify-start pb-2">
              <h4 className="font-bold">
                Tutor Fee: <span className="font-normal">{trans.amount}</span>{" "}
              </h4>
            </div>
            <div className="w-full md:w-4/12 ml-1 flex justify-start pb-2">
              <h4 className="font-bold">
                SK Commission:{" "}
                <span className="font-normal">{trans.commission}</span>{" "}
              </h4>
            </div>
          </div>
        ) : null}
      </div>
    );

    // <div
    //   className="w-full flex justify-between my-1 rounded-3xl"
    //   style={{
    //     backgroundColor: "white",
    //   }}
    // >
    //   <div className="w-3/12  py-1 px-3  text-sm">Date / time</div>
    //   <div className="w-4/12  py-1 px-3  text-sm">Description</div>
    //   <div className="w-3/12  py-1 px-3  text-sm">Amount (USD)</div>
    // </div>
  };

  return (
    <div className="w-full h-full flex flex-col justify-between items-center p-4">
      <div className="w-full flex flex-col justify-start items-start pt-4 ">
        <div className="w-full flex my-4 px-3 md:px-0 md:pr-24">
          <div className="w-full flex-grow flex justify-center">
            <div className="w-3/12 pr-1 md:pr-3 text-white text-sm">
              <div
                // className="w-3/12 md:w-2/12 rounded-3xl ml-2 py-1 mx-1 md:mx-3 px-2 text-white text-sm"
                className="max-w-full rounded-3xl md:ml-2 py-1 px-2 text-white text-sm text-center"
                style={{
                  backgroundColor: commonColor.deepBlue,
                }}
              >
                Date
              </div>
            </div>
            <div className="w-5/12 text-white text-sm pr-1 md:pr-3">
              <div
                // className="w-5/12 md:w-4/12 rounded-3xl py-1 px- text-white text-sm px-2  mx-1 md:mx-3"
                className=" max-w-full rounded-3xl py-1 text-white text-sm px-2 text-center"
                style={{
                  backgroundColor: commonColor.deepBlue,
                }}
              >
                Description
              </div>
            </div>
            {/* <div
              className="w-2/12 rounded-3xl py-1 px-2 text-white text-sm"
              style={{
                backgroundColor: commonColor.deepBlue,
              }}
            >
              Tutor Fee $
            </div> */}
            <div className="w-4/12 md:w-2/12 pr-1">
              <div
                // className="w-4/12 md:w-2/12 rounded-3xl py-1 px-2 text-white text-sm mx-1 md:mx-3"
                className="max-w-full transactionHeader rounded-3xl py-1 px-2 text-white text-sm mr-1 text-center"
                style={{
                  backgroundColor: commonColor.deepBlue,
                }}
              >
                Total $
              </div>
            </div>
          </div>
          {/* <div
            className="hidden md:block w-24"
            style={
              {
                // backgroundColor: commonColor.deepBlue,
              }
            }
          ></div> */}
        </div>
      </div>
      <div className="w-full flex flex-col justify-start flex-grow items-center pb-4 overflow-y-scroll">
        {transactions.map((trans, index) => cell(trans, index))}
        {!transactions.length ? (
          <div className="p-10 mt-4 text-center">
            You have not had any paid lessons till now. Once you start paying
            for your classes, you will find all transactions to track what you
            are paying.{" "}
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className={`w-full flex justify-around p-2 ${
          transactions && transactions.length > 0 ? "block" : "hidden"
        }`}
      >
        <SwotButton
          text={"Download"}
          onClick={() => {
            createCSV(transactions, "student_transactions");
          }}
        />
        {/* <SwotButton
          text={"Full details"}
          onClick={() => console.log("Transactions full details!")}
          bgColor={commonColor.deepBlue}
        /> */}
      </div>
      <Modal
        isOpen={
          selectedTransaction !== null && selectedTransaction !== undefined
        }
        onRequestClose={() => {
          setSelectedTransaction(null);
        }}
      >
        {selectedTransaction && (
          <div className="flex flex-col">
            <div className="flex">
              <div>
                <p>
                  <b>Transaction Id:</b> {selectedTransaction.transaction_id}
                </p>
              </div>
            </div>
            <div className="flex">
              <div>
                <p>
                  <b>Date/Time:</b>{" "}
                  {moment
                    .unix(selectedTransaction.created_timestamp)
                    .toString()}
                </p>
              </div>
            </div>
            <br />
            <div className="flex">
              <div>
                <p>
                  <b>Status:</b> {selectedTransaction.status}
                </p>
              </div>
            </div>

            {selectedTransaction.refund_reason && (
              <div className="flex">
                <div>
                  <p>
                    <b>Refund Reason:</b> {selectedTransaction.refund_reason}
                  </p>
                </div>
              </div>
            )}
            <div className="flex">
              <div>
                <p>
                  <b>Tutor Email:</b> {selectedTransaction.tutor_email}
                </p>
              </div>
            </div>
            <div className="flex">
              <div>
                <p>
                  <b>Course Id:</b> {selectedTransaction.course_id}
                </p>
              </div>
            </div>
            <div className="flex">
              <div>
                <p>
                  <b>Course Name:</b> {selectedTransaction.course_fullname}
                </p>
              </div>
            </div>
            <div className="flex">
              <div>
                <p>
                  <b>Course Shortname:</b>{" "}
                  {selectedTransaction.course_shortname}
                </p>
              </div>
            </div>
            {selectedTransaction.refund_response &&
              selectedTransaction.status === "refund_rejected" && (
                <div className="flex">
                  <div>
                    <p>
                      <b>Refund Rejection Reason:</b>{" "}
                      {selectedTransaction.refund_response}
                    </p>
                  </div>
                </div>
              )}
          </div>
        )}
      </Modal>

      <Modal
        isOpen={refundTransaction !== null && refundTransaction != undefined}
        onRequestClose={() => {
          setRefundTransaction(null);
          setRefundDisclosure(null);
          setRefundOptionSelected(null);
        }}
      >
        {refundDisclosure && (
          <div className="flex flex-col">
            <div
              className="flex"
              style={{
                justifyContent: "center",
                fontSize: "20px",
                marginBottom: "10px",
              }}
            >
              <span> Refund Disclosure</span>
            </div>

            <div className="flex">
              <div>
                <p>
                  <b>
                    In case a refund is requested, the following terms apply:
                    &nbsp;
                  </b>
                </p>
              </div>
            </div>

            <div className="flex py-4">
              <div>
                <li>Only the Tutor Fee is refundable.</li>
                <li>SK fee and Stripe charges are not refundable.</li>
                <li>SK is an abbreviation for l3arn.</li>
              </div>
            </div>

            <div className="w-full flex flex-row justify-center py-2">
              <SwotButton
                text={"Continue"}
                onClick={() => {
                  setRefundDisclosure(null);
                }}
              />
            </div>
          </div>
        )}

        {refundTransaction && !refundDisclosure && (
          <div className="flex flex-col">
            <div
              className="flex"
              style={{
                justifyContent: "center",
                fontSize: "20px",
                marginBottom: "10px",
              }}
            >
              <span> Refund Payment</span>
            </div>

            <div className="flex">
              <div>
                <p>
                  <b>Transaction Id: </b>{" "}
                </p>
              </div>
              <div>{refundTransaction.transaction_id}</div>
            </div>

            <div className="w-full flex flex-col justify-center py-2">
              <div className="text-white text-sm w-full flex flex-row justify-end items-center">
                {"Why you want refund?"}
              </div>
              <div className="w-full flex flex-row justify-start px-2">
                <Select
                  className="w-full"
                  defaultValue={refundOptions[0]}
                  value={
                    refundOptionSelected
                    // ? refundOptionSelected
                    // : refundOptions[0]
                  }
                  onChange={(value) => {
                    setRefundOptionSelected(value);
                  }}
                  options={refundOptions}
                />
              </div>
            </div>

            <div className="w-full flex flex-row justify-center py-2">
              <div className="min-h-20 w-full flex flex-row justify-start px-2 py-1">
                {refundOptionSelected &&
                  refundOptionSelected.value?.toLowerCase() === "other" && (
                    <textarea
                      rows={4}
                      className="w-full bg-white appearance-none border-2 focus:outline-none focus:border-opacity-100 py-1 px-2 rounded-lg"
                      value={refundReason}
                      placeholder={
                        "Enter a paragraph describing reason for refund"
                      }
                      onChange={(event) => {
                        setRefundReason(event.target.value);
                      }}
                    />
                  )}
              </div>
            </div>
            <div className="w-full flex flex-row justify-center py-2">
              <SwotButton
                text={"Request Refund"}
                disabled={
                  !refundOptionSelected ||
                  (refundOptionSelected.value?.toLowerCase() === "other" &&
                    !refundReason)
                }
                onClick={() => {
                  makeRefundRequest();
                  setRefundTransaction(null);
                  setRefundDisclosure(null);
                  setRefundOptionSelected(null);
                  setRefundReason(null);
                }}
              />
            </div>
          </div>
        )}
      </Modal>

      <QuickModal
        isOpen={errorMessage !== null && errorMessage != undefined}
        onRequestClose={() => {
          setErrorMessage(null);
        }}
        isCloseButtonShown={true}
        title={"Error"}
        message={errorMessage}
      />

      <LoaderModal
        isOpen={loading}
        onRequestClose={() => {
          setLoading(false);
        }}
      />
    </div>
  );
};

export default Transactions;
