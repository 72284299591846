import React, { useState, useEffect } from 'react'
import SetupWizard from "../../../components/tutor/setup/setupWizard"
import Swotbutton from "../../../components/common/buttons/swotbutton"


const Step2 = ({ saveProperty, currentStep, user, onNext }) => {
  console.log("step2", user)
  const [wizardStarted, setWizardStarted] = useState(false)
  const [wizardDone, _] = useState(false)
  const [wizardData, setWizardData] = useState({})

  const handleSkip = () => {
    onNext();
  };

  useEffect(() => {

  }, [wizardStarted])

  if (currentStep !== 2) {
    return null
  }

  // const handleChange = (key, value) => {
  //   saveProperty(key, value)
  // };

  const quizDoneHandler = () => {
    // TODO: enable/disable show/hide stuff depending on if wizard is done.
  }


  return (
    <div className="h-full flex flex-col justify-around lg:px-10"
      style={{
        // overflowY: 'scroll',
        // height: '460px'
      }}>
      {
        user.quizDone ?
          <div className=" w-full  p-4 my-4 mx-2 h-full flex flex-col justify-center items-center">
            <div className="text-center">Thank you for taking the time to answer these questions! You can now move to step 3</div>
          </div>
          :
          <div className="w-full h-full">
            {wizardStarted && !wizardDone &&
              <SetupWizard onQuizDone={quizDoneHandler} data={wizardData} updateData={(updatedData) => {
                setWizardData(updatedData)
              }} />
            }
            {!wizardStarted && !wizardDone &&
              <div className=" w-full  p-4 my-4 mx-2 h-full flex flex-col justify-center items-center">
                <div className="text-center">We are excited to have you on board; tell us about your teaching habits and styles. By taking this quiz, our AI will help students, that match your teaching style, find you. This means that students will all be of the same learning types when you have groups.</div>
                <div className="w-full flex justify-center items-center py-4">
                  <Swotbutton
                    className={"px-4"}
                    text={"Take Teaching Style Quiz"}
                    onClick={() => setWizardStarted(true)}
                    disabled={false}
                  />
                </div>
                  <Swotbutton
                    className={"px-4"}
                    text={"Skip"}
                    onClick={handleSkip}
                    style={{
                      minWidth: "8rem",
                    }}
                  />
              </div>
            }
          </div>
      }


      {/* {
        (wizardStarted && wizardDone) &&
        <div className=" w-full  p-4 my-4 mx-2 h-full flex flex-col justify-center items-center">


          <div className="text-center">Thank you for taking the time to answer these questions!</div>


        </div>

      } */}



      {/* <div className="w-full flex flex-col justify-center">
        <div className="text-sm flex justify-start">
          {"What is your teaching style and education philosophy?"}
        </div>
        <textarea
          rows={4}
          className="w-full bg-white appearance-none border-2 focus:outline-none 
        focus:border-opacity-100 py-1 px-2"
          value={teachingStyle}
          onChange={(event) => {
            setTeachingStyle(event.target.value)
            handleChange("teachingStyle", event.target.value)
          }}
        />
      </div>
      <div className="w-full flex flex-col justify-center">
        <div className="text-sm flex justify-start">
          {"What is the most innovative teaching method you use?"}
        </div>
        <textarea
          rows={4}
          className="w-full bg-white appearance-none border-2 focus:outline-none 
        focus:border-opacity-100 py-1 px-2"
          value={teachingMethod}
          onChange={(event) => {
            setTeachingMethod(event.target.value)
            handleChange("teachingMethod", event.target.value)
          }}
        />
      </div> */}

    </div>
  )
}


export default Step2
