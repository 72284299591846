import React, { useState } from "react";
import { TermsAndPrivacyPolicy } from "../../components/common/consent";
import FooterViewVerson from "../../components/common/footerView";

import { Link } from "react-router-dom";
import learnlogo from "../../assets/images/learn-logo-colour.svg";


const videoLinks = [
  "rqOckh4goaE", // Introduction
  "J0xBh8WS5y0", // Part 1
  "9vRxroSn9SI", // Part 2
  "FhlM9Xv9Tgg", // Part 3
  "jONYycmyQYE", // Part 4
  "Hyx5yTcCyfs", // Part 5
  "cexmeA67PI4", // Part 6
  "A3klOhmlvlE", // Part 7
  "r5lPNpTeKmw", // Part 8 – 9
  "cP1w3PjWjps", // Part 10 – 12
  "L47iEV3W5og", // Part 13
  "cBGp-eBH9xE", // Part 14
  "rb5eNY1Kcpw", // Part 15
  "NChDnBNzRpU", // Part 16
  "SziRLb9qL7U", // Part 17 – 18
  "tASQYv5vwrg", // Part 19 – 21
  "mPq2lKQyTdY", // Part 22 – 26
  "Kbcm7Q0Ydf4", // Part 27 – 31
  "EuZbdozRBhQ", // Part 32
  "9q_jtl1JWzg", // Thank you
];

const VideoTutorials = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const replayVideo = () => {
    // Simply reload the iframe to replay the video
    document.getElementById("videoPlayer").src += "";
  };

  const nextVideo = () => {
    // Check if there's a next video in the list
    if (currentVideoIndex < videoLinks.length - 1) {
      setCurrentVideoIndex(currentVideoIndex + 1);
    }
  };

  const previousVideo = () => {
    // Check if there's a previous video in the list
    if (currentVideoIndex > 0) {
      setCurrentVideoIndex(currentVideoIndex - 1);
    }
  };

  return (
    <div className=" bg-[#001F3F] flex flex-col justify-between min-h-screen overflow-y-scroll">
      {/* logo */}
      <div className="mx-auto mt-2 md:mt-4 md:ml-10">
        <Link to="/sign-in">
          <img
            className="w-60 h-auto 2k:w-[20rem]"
            src={learnlogo}
            alt="l3arn-logo"
          />
        </Link>
      </div>

      <div className="w-full text-center py-4 mt-20 flex items-center justify-center flex-col">
        <div className="mx-auto w-full  flex items-center justify-center">
          <iframe
            className="lg:w-9/12 lg:h-[900px] | w-10/12  h-[500px] | 2k:w-10/12 2k:h-[1100px]"
            id="videoPlayer"
            src={`https://www.youtube.com/embed/${videoLinks[currentVideoIndex]}?rel=0`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        <div className="w-full mt-4 flex items-center justify-center gap-4 md:flex-row flex-col">
          <button
            onClick={previousVideo}
            className={`text-white py-2 px-4 rounded-md w-[10rem] 2k:w-[14rem] ${
              currentVideoIndex === 0
                ? "bg-gray-500 cursor-not-allowed"
                : "bg-[#A03D68] hover:bg-[#b9517e]"
            }`}
            disabled={currentVideoIndex === 0}
          >
            Previous Video
          </button>
          <button
            onClick={replayVideo}
            className="bg-[#B7BADD] hover:bg-[#8689a8] text-white py-2 px-4 rounded-md w-[10rem] 2k:w-[14rem] "
          >
            Replay Video
          </button>
          <button
            onClick={nextVideo}
            className="bg-[#A03D68] hover:bg-[#b9517e] text-white py-2 px-4 rounded-md w-[10rem] 2k:w-[14rem] "
          >
            Next Video
          </button>
        </div>
      </div>

      <div className="mt-20">
        <div
          id="terms-and-conditions"
          className="text-white w-full text-center py-2"
        >
          <TermsAndPrivacyPolicy className="text-white 2k:text-lg" />
        </div>
        <FooterViewVerson className="text-white 2k:text-lg" />
      </div>
    </div>
  );
};

export default VideoTutorials;
