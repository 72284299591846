export const loadertexts = [
    "Summoning creativity particles, hold tight!",
    "Beaming up ideas from the inspiration cosmos!",
    "Spinning the gears in the genius machine, one moment!",
    "Assembling your masterpiece, stay tuned!",
    "The wizards of wordcraft are at work. Hang tight!",
    "Cooking up a storm of creativity, bear with us!",
    "Inventing the future, just a moment please!",
    "Hang on! We're mining your words from the creativity quarry!",
    "Fishing for the best words in the vocabulary ocean!",
    "Sourcing words from the prose galaxy, one moment!",
    "Your request is being woven into a tapestry of text. Patience, please!",
    "Hunting for the best phrases in the jungle of jargon, hold on!",
    "Conjuring up a symphony of sentences. Wait for the grand reveal!",
    "Planting seeds of ideas, waiting for them to sprout!",
    "Hitching a ride on the thought train, arriving soon!",
    "Winding up the idea factory. Your order will be ready shortly!",
    "Weaving your request into a sonnet of solutions. Hang on!",
    "Brewing a potion of productivity, sit tight!",
    "Stirring up a storm in the pot of possibility, bear with us!",
    "Your brilliant bundle of words is in the word-wrapping zone, almost there!",
    "Cranking the creativity engine, hold on to your hat!",
    "Sailing on the sea of syntax. Your treasure of text is being discovered!",
    "Riding the wave of wisdom, your product will surf in shortly!",
    "Stargazing for stellar sentences, keep tight!"
];
