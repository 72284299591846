import moment from "moment";
import React, { useState } from "react";
import MaskedField from "react-masked-field";
// import { signupBeta } from "../../services/api";
import Swal from "sweetalert2";
import Modal from "../../../components/common/modal/modal";
import commonColor from "../../../theme/commonColor";
import Swotbutton from "../../../components/common/buttons/swotbutton";
import { signupBeta } from "../../../services/api";

const CompanyContactModal = ({ isOpen, onRequestClose, showLoader }) => {
  const [company, setCompany] = useState();
  const [fullname, setFullname] = useState();
  const [email, setEmail] = useState();
  const [notes, setNotes] = useState();
  //   const [showFormModal, setShowFormModal] = useState(false);
  const [fullnameValidationError, setFullnameValidationError] = useState(null);
  const [loading, setLoading] = useState(false);

  //---Fullname validation----------//
  const isFullnameValid = () => {
    if (!fullname || fullname.length === 0) {
      return null;
    }
    var pattern = new RegExp(/^[a-zA-Z\s]*$/);
    if (fullname.length < 3 || !pattern.test(fullname)) {
      return false;
    }
    return true;
  };

  const validateFullname = () => {
    setFullnameValidationError(null);
    if (isFullnameValid() === false) {
      setFullnameValidationError("Please enter a valid name.");
    }
  };

  const renderFullnameBorderColor = () => {
    var validation = isFullnameValid();
    return !validation
      ? "#e2e8f0"
      : validation === false
      ? commonColor.error
      : commonColor.correct;
  };

  const handleFullnameChange = (event) => {
    setFullnameValidationError(null);
    const tempFullname = event.target.value;
    setFullname(tempFullname);
    // saveProperty("name", tempFullname);
  };

  //---------Email validations------------//
  const isEmailValid = () => {
    if (!email || email.length === null || email.length === undefined) {
      return null;
    }

    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    return pattern.test(email);
  };

  const validateEmail = () => {
    var validation = isEmailValid();
    // setEmailOk(false);
    console.log("validateEmail -  email", email);
    if (!validation) {
      // setEmailError(false);
      // setEmailExists(false);
      return;
    }
    if (validation === false) {
      // setEmailError(true);
    } else {
      // setEmailError(false);
      // auth.fetchSignInMethodsForEmail(email).then((providers) => {
      //   if (providers.length === 0) {
      //     setEmailExists(false);
      //     setEmailOk(true);
      //     // this email hasn't signed up yet
      //     saveProperty("email", email);
      //   } else {
      //     // has signed up
      //     setEmailExists(true);
      //   }
      // });
    }
  };

  const handleEmailChange = (event) => {
    console.log("handleEmailChange - ", event.target.value);

    const emailSoFar = event.target.value;
    if (!emailSoFar || emailSoFar.length === 0) {
      // setEmailError(false);
      // setEmailExists(false);
    }
    setEmail(emailSoFar);
  };

  const renderEmailBorderColor = () => {
    const validation = isEmailValid();
    if (validation === false) {
      return commonColor.error;
    } else if (validation === true) {
      return commonColor.correct;
    } else {
      return "#e2e8f0";
    }
  };

 

  //----Validation Company-------//
  const validateCompany = () => {
    // setFullnameValidationError(null);
    if (isvalidCompany()) {
      // setFullnameValidationError("Please enter a valid name.");
    }
  };

  const isvalidCompany = () => {
    return !company || company.trim().length === 0
      ? null
      : company.trim().length > 3;
  };
  const handleCompanyChange = (event) => {
    // setFullnameValidationError(null);
    const tempCompanyname = event.target.value;
    setCompany(tempCompanyname);
    // saveProperty("name", tempFullname);
  };

  const renderCompanyBorderColor = () => {
    var validation = isvalidCompany();
    return !(validation)
      ? "#e2e8f0"
      : validation === false
      ? commonColor.error
      : commonColor.correct;
  };

  //--------//

  const isDataValid = () => {
    return (
      isFullnameValid() && isEmailValid() && isvalidCompany()
    );
  };

  const submitClicked = async () => {
    if (!isDataValid()) {
      return;
    }

    // setShowFormModal(false);

    let payload = {
      name: fullname,
      email: email,
      company: company,
      notes: notes ?? "",
    };

    // onSubmit && onSubmit(payload);
    onRequestClose && onRequestClose();
    showLoader && showLoader(true);
    const result = await signupBeta(payload);
    console.log("Result object:", result);
     // Extract and log the specific message if it exists
     const message = result?.data?.data?.message;
     console.log("Response message:", message);

    // var result = {ok: true, data: { status: "SUCCESS", data: {message: "This is test response. Please fix the API."}}}
    showLoader && showLoader(false);
    console.log("reponds is " + result.data.data?.message);
    if (result && result.ok && result.data && result.data.status === "SUCCESS") {
      Swal.fire({
        title: "Success!",
        text:
          result.data.data?.message ??
          "You have successfully submitted details. We will contact you soon.",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      Swal.fire({
        title: "Failed!",
        text:
          result.data?.errorMessage ?? "Failed to submit. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        onRequestClose && onRequestClose();
      }}
    >
      <div className="w-80v lg:w-70v xl:w-50v lg:p-12 xl:p-16 2k:p-20">
        <div className="w-full flex-col flex justify-start overflow-y-scroll">
          <div className="w-full flex justify-center mb-8">
            <p className="text-center italic">Tell us a little bit about yourself</p>
          </div>

          <div className="w-full flex flex-col justify-center flex-wrap">
            <div className="w-full flex flex-col items-start sm:flex-row sm:items-center justify-center">
              {/* <div className="text-sm lg:w-1/3 w-2/5 flex flex-row justify-end items-center"> */}
              <div className="text-sm w-32 md:w-40 xl:w-60 flex flex-row justify-start sm:justify-end items-center">
                Name
              </div>
              {/* <div className="lg:w-2/3 w-3/5 flex flex-row justify-start px-2 py-1"> */}
              <div className="w-full flex flex-row justify-start sm:px-2 py-1">
                <input
                  className="lg:w-4/5 w-full bg-white appearance-none border-2 focus:outline-none 
               focus:border-opacity-100 py-1 px-2"
                  style={{
                    borderColor: renderFullnameBorderColor(),
                  }}
                  id="name"
                  name="name"
                  type="text"
                  placeholder={"Enter your full name"}
                  value={fullname}
                  onBlur={validateFullname}
                  onChange={handleFullnameChange}
                />
              </div>
            </div>

            <div className="w-full flex flex-col items-start sm:flex-row sm:items-center justify-center">
              <div className="text-sm w-32 md:w-40 xl:w-60 flex flex-row justify-start sm:justify-end items-center">
                {/* <div className="text-sm lg:w-1/3 w-2/5 flex flex-row justify-end items-center"> */}
                Email
              </div>
              {/* <div className="lg:w-2/3 w-3/5 flex flex-row justify-start px-2 py-1"> */}
              <div className="w-full flex flex-row justify-start sm:px-2 py-1">
                <input
                  className="lg:w-4/5 w-full bg-white appearance-none border-2 focus:outline-none 
               focus:border-opacity-100 py-1 px-2"
                  style={{
                    borderColor: renderEmailBorderColor(),
                  }}
                  id="email"
                  name="email"
                  type={"email"}
                  placeholder={"Enter your email"}
                  value={email}
                  onBlur={validateEmail}
                  onChange={handleEmailChange}
                />
              </div>
            </div>

            <div className="w-full flex flex-col items-start sm:flex-row sm:items-center justify-center">
              {/* <div className="text-sm lg:w-1/3 w-2/5 flex flex-row justify-end items-center"> */}
              <div className="text-sm w-32 md:w-40 xl:w-60 flex flex-row justify-start sm:justify-end items-center">
                Company
              </div>
              {/* <div className="lg:w-2/3 w-3/5 flex flex-row justify-start px-2 py-1"> */}
              <div className="w-full flex flex-row justify-start sm:px-2 py-1">
                <input
                  className="lg:w-4/5 w-full bg-white appearance-none border-2 focus:outline-none 
               focus:border-opacity-100 py-1 px-2"
                  style={{
                    borderColor: renderCompanyBorderColor(),
                  }}
                  id="company"
                  name="company"
                  type="text"
                  placeholder={"Enter your company"}
                  value={company}
                  onBlur={validateCompany}
                  onChange={handleCompanyChange}
                />
              </div>
            </div>

            <div className="w-full flex flex-col items-start sm:flex-row sm:items-center justify-center">
              <div className="w-32 md:w-40 xl:w-60"></div>
              <div className="w-full flex flex-col justify-start sm:px-2 py-1">
                <div className="w-full lg:w-4/5 text-sm flex flex-row justify-start items-center py-1 px-2">
                  What do you need?
                </div>
                <textarea
                  rows={4}
                  className="w-full lg:w-4/5 bg-white appearance-none border-2 focus:outline-none focus:border-opacity-100 py-1 px-2 rounded-lg"
                  placeholder={
                    "Please share your needs with us"
                  }
                  onChange={(event) => {
                    setNotes(event.target.value);
                  }}
                />
              </div>
            </div>

            <div className="w-full flex flex-row justify-center items-center">
              <Swotbutton
                text="Submit"
                disabled={!isDataValid()}
                onClick={() => {
                  submitClicked();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CompanyContactModal;
