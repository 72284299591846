import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  saveCalendarRequest: ['calendar'],
  saveCalendarSuccess: ['calendar'],
  saveCalendarFailure: ['error']
});

export const CalendarTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  calendar: null,
  fetching: false,
  error: null
});

/* ------------- Reducers ------------- */

// we're attempting to save calendar 
export const request = state => state.merge({ fetching: true, error: null });

// we've successfully saved calendar 
export const success = (state, { calendar }) =>
  state.merge({ fetching: false, error: null, calendar });

// we've had a problem saving calendar 
export const failure = (state, { error }) =>
  state.merge({ fetching: false, error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SAVE_CALENDAR_REQUEST]: request,
  [Types.SAVE_CALENDAR_SUCCESS]: success,
  [Types.SAVE_CALENDAR_FAILURE]: failure
});

/* ------------- Selectors ------------- */
