import Config from '../config/debugConfig';
import Reactotron from 'reactotron-react-js'
//import url from 'url';
import Immutable from 'seamless-immutable';
import { reactotronRedux as reduxPlugin } from 'reactotron-redux';
import sagaPlugin from 'reactotron-redux-saga';
import apisaucePlugin from 'reactotron-apisauce';

let reactotron
console.log(`Using Reactotron: ${Config.useReactotron}`);
if (Config.useReactotron) {

  //const { hostname } = url.parse(NativeModules.SourceCode.scriptURL);

  // // https://github.com/infinitered/reactotron for more options!
  reactotron = Reactotron
    .configure({ name: 'swotKnot' })
    .use(reduxPlugin({ onRestore: Immutable }))
    .use(sagaPlugin())
    .use(apisaucePlugin())
    .connect();

  // Let's clear Reactotron on every time we load the app
  Reactotron.clear();
  // Totally hacky, but this allows you to not both importing reactotron-react-native
  // on every file.  This is just DEV mode, so no big deal.
  console.tron = Reactotron;
}

export default reactotron;
