import { useEffect, useReducer } from "react";

// Hook
export default function useHightCalculated(calculateHeight) {
    const simulateSlowNetworkRequest = () =>
      new Promise((resolve) => setTimeout(resolve, 500));
  
    const sizeReducer = (state, windowSize) => {
      if (!calculateHeight || !windowSize || !windowSize.width) {
        return 0;
      }
      return calculateHeight(windowSize);
    };
  
    const [heightCalculated, sizeDispatch] = useReducer(sizeReducer, {
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        sizeDispatch({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      let isCancelled = false;
  
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      simulateSlowNetworkRequest().then(() => {
        if (!isCancelled) {
          handleResize();
        }
      });
  
      handleResize();
      // Remove event listener on cleanup
      return () => {
        isCancelled = true;
        window.removeEventListener("resize", handleResize);
      };
    }, []); // Empty array ensures that effect is only run on mount
    return heightCalculated;
  }
  