import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import UserActions from "../../redux/user";
import { createStripeAccount } from "../../services/api";

import MainPageWithMenu from "../../components/common/MainPageTemplate/mainPageWithMenu";
import TutorDashboard from "../../components/tutor/dashbaord/tutorDashboard";
import AdminDashboard from "../../components/admin/adminDashboard";
import StudentDashboard from "../../components/student/studentDashboard";
import StudentSetupWizard from "../../components/student/setup/setupWizard";

import TutorRejected from "../../components/tutor/tutorRejected";
import TutorSetupPrompt from "../../components/tutor/tutorSetupPrompt";
import TutorPending from "../../components/tutor/tutorPending";
import GuestTutorDashboard from "../../components/guest/tutor/GuestTutorDashboard";
import GuestStudentDashboard from "../../components/guest/student/GuestStudentDashboard";
import { ACCOUNT_TYPES } from "../../config/constants";
import SwotBuddySetUp from "../../components/student/swotBuddy/swotBuddySetUp";

const DashboardPage = ({ user, saveUser, signOut }) => {
  const [isStripeSetupPressed, setIsStripeSetupPressed] = useState(false);

  const setupStripeAccount = async () => {
    setIsStripeSetupPressed(true);
    console.log("setupStripeAccount - for user", user.uid);
    createStripeAccount(user.uid).then((response) => {
      console.log("setupStripeAccount - response:", response.data);
      console.log(
        "setupStripeAccount - navigate to:",
        response.data.accountLinkUrl
      );
      window.location = response.data.accountLinkUrl;
    });
  };

  const renderTutorFlow = () => {
    if (user.setupStatus === "approved") {
      return <TutorDashboard />;
    } else if (user.setupStatus === "rejected") {
      return <TutorRejected />;
    } else if (user.setupStatus === "pending") {
      return <TutorPending />;
    } else {
      return <TutorSetupPrompt />;
    }
  };

  const renderStudentFlow = () => {
    if (user.quizDone) {
      return <StudentDashboard />;
    } else {
      const wizard = () => (
        <>
          <div className="w-full h-full flex justify-center items-center">
            <div className="w-full px-2 max-w-xl h-full lg:h-7/10 lg:w-7/10">
              <div className="w-full h-full flex justify-center items-center">
                <StudentSetupWizard />
              </div>{" "}
            </div>{" "}
          </div>
        </>
      );
      return wizard();
    }
  };

  const renderSwotBuddyFlow = () => {
    if (user.quizDone) {
      //TODO: change to
      return <StudentDashboard />;
    } else if (user.swotBuddySetup == "personalized") {
      const wizard = () => (
        <>
          <div className="w-full h-full flex justify-center items-center">
            <div className="w-full px-2 max-w-xl h-full lg:h-7/10 lg:w-7/10">
              <div className="w-full h-full flex justify-center items-center">
                <StudentSetupWizard />
              </div>{" "}
            </div>{" "}
          </div>
        </>
      );
      return wizard();
    } else if (user.swotBuddySetup == "default") {
      //TODO: change to
      return <StudentDashboard />;
    } else {
      return <SwotBuddySetUp />;
    }
  };

  // const { photoURL, displayName, email } = user;
  return (
    <MainPageWithMenu user={user} menuSelected={"home"}>
      {(user.accountType === ACCOUNT_TYPES.TUTOR ||
        user.accountType === ACCOUNT_TYPES.GUEST_TUTOR) &&
        renderTutorFlow()}

      {user.accountType === ACCOUNT_TYPES.STUDENT && renderStudentFlow()}

      {user.accountType === ACCOUNT_TYPES.SWOT_BUDDY && renderSwotBuddyFlow()}

      {user.accountType === ACCOUNT_TYPES.SWOT_ADMIN && <AdminDashboard />}

      {/* {user.accountType === "GuestTutor" && <GuestTutorDashboard />} */}

      {user.accountType === ACCOUNT_TYPES.GUEST_STUDENT && (
        <GuestStudentDashboard />
      )}
    </MainPageWithMenu>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
