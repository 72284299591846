import React, { memo } from "react";

const Card = memo(({ children, className, style }) => {
  return (
    <div
      className={`xl:mx-10 mx-6 flex flex-col sk-shadow-xl ${
        className ? className : ""
      }`}
      style={{
        borderRadius: 25,
        ...{ ...style },
      }}
    >
      {children}
    </div>
  );
});

export default Card;
