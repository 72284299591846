import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  saveAuthUserRequest: ['authUser'],
  saveAuthUserSuccess: ['authUser'],
  saveAuthUserFailure: ['error']
});

export const AuthUserTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  authUser: null,
  fetching: false,
  error: null
});

/* ------------- Reducers ------------- */

// we're attempting to save authUser 
export const request = state => state.merge({ fetching: true, error: null });

// we've successfully saved authUser 
export const success = (state, { authUser }) =>
  state.merge({ fetching: false, error: null, authUser });

// we've had a problem saving authUser 
export const failure = (state, { error }) =>
  state.merge({ fetching: false, error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SAVE_AUTH_USER_REQUEST]: request,
  [Types.SAVE_AUTH_USER_SUCCESS]: success,
  [Types.SAVE_AUTH_USER_FAILURE]: failure
});

/* ------------- Selectors ------------- */
