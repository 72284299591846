import React from "react";
import playBtn from "./play.svg";

const HowToSignUpButton = ({ onClick = () => {} }) => {
  return (
    <button
      className="flex rounded-md border w-full justify-center items-center p-2 bg-white hover:bg-blue-100 h-12"
      onClick={onClick}
      style={style}
    >
      <div className="w-8 p-1">
        <img src={playBtn} alt="howToSignUpButton" />
      </div>
      <div className="pl-2">How to Sign up?</div>
    </button>
  );
};

const style = {};

export default HowToSignUpButton;
