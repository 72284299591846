import { useSpring, useChain, useSpringRef, animated } from "@react-spring/web";

function Preloader({ onCompletion }) {
  const springs = useSpring({
    from: { y: "0%", opacity: 1 },
    to: { y: "-100%", opacity: 0.1 },
    delay: 2000,
    config: {
      duration: 500,
    },
    onResolve: () => {
      onCompletion();
    },
  });

  const springBarRef = useSpringRef();
  const springBar = useSpring({
    ref: springBarRef,
    from: { transform: "scale(0)" },
    to: { transform: "scale(1)" },
    config: {
      duration: 1500,
    },
  });

  const springBarHideRef = useSpringRef();
  const springBarHide = useSpring({
    ref: springBarHideRef,
    from: { opacity: 1 },
    to: { opacity: 0 },
    delay: 500,
    config: {
      duration: 500,
    },
  });

  useChain([springBarRef, springBarHideRef]);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <animated.div
        style={{
          width: "100%",
          height: "100%",
          background: "rgb(255, 250, 250)",
          ...springs,
        }}
      />

      <div
        style={{ width: "60%", height: "1.5px", overflow: "hidden" }}
        className="absolute bottom-1/2"
      >
        <animated.div
          style={{
            backgroundColor: "rgba(156,57,103,1)",
            height: "100%",
            width: "100%",
            transformOrigin: "left",
            ...springBar,
            ...springBarHide,
          }}
        />
      </div>
      {/* </animated.div> */}
    </div>
  );
}

export default Preloader;
