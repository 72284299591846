import React, { memo, useState, useEffect } from "react";

import MaterialUploaderViewer from "./materialUploaderViewer";
import CourseRecording from "./courseRecording";
import commonColor from "../../theme/commonColor";
import {
  getStudentRecording,
  getTutorRecording,
  getH5pContentByCourse,
} from "../../services/api";
import H5PContentSection from "./h5pContentSection";
import { SHOW_H5P } from "../../../src/config/constants";
import { ReactComponent as Assignments } from "../../assets/icons/assignments.svg";
import { ReactComponent as Recordings } from "../../assets/icons/recordings.svg";
import { ReactComponent as H5P } from "../../assets/icons/h5p.svg";

const CourseMaterial = ({
  courseId,
  courseShortName,
  user,
  readOnly,
  assignments,
  showPopover,
}) => {
  const [selected, setSelected] = useState("assignments");
  const [recordings, setRecordings] = useState([]);
  const [h5pContent, setH5pContent] = useState([]);

  useEffect(() => {
    console.log("USER:", user, "courseId", courseId);
  });

  useEffect(() => {
    if (selected === "recordings") {
      console.log("Getting Recording");
      courseId && getRecording();
    } else if (selected === "h5p") {
      courseId && getH5pData();
    }
  }, [selected]);

  const getRecording = async () => {
    if (user) {
      if (user.accountType === "Tutor") {
        console.log("Getting Tutor Recording");
        const result = await getTutorRecording(courseId);
        if (result && result.data && Array.isArray(result.data.data)) {
          console.log("Recording", result.data.data);
          setRecordings(result.data.data);
        }
      } else {
        console.log("Getting Student Recording");
        const result = await getStudentRecording(courseId, user.uid);
        if (result && result.data && Array.isArray(result.data.data)) {
          console.log("Recording", result.data.data);
          setRecordings(result.data.data);
        }
      }
    }
  };

  const getH5pData = async () => {
    if (!h5pContent || h5pContent.length === 0) {
      console.log("Getting course h5pContent");
      const result = await getH5pContentByCourse(courseId);
      if (result && result.data && Array.isArray(result.data.data)) {
        console.log("h5pContent", result.data.data);
        setH5pContent(result.data.data);
      }

      //Testing
      // const d = [
      //   {
      //     contentId: "1355418867",
      //     mainLibrary: "H5P.Summary",
      //     title: "Test questions4",
      //   },
      //   {
      //     contentId: "1724249746",
      //     title: "test",
      //     mainLibrary: "H5P.MultiChoice",
      //   },
      //   {
      //     contentId: "2399407203",
      //     title: "Test questions",
      //     mainLibrary: "H5P.TrueFalse",
      //   },
      // ];
      // setH5pContent(d)
      //
    }
  };

  return (
    <div className="h-full w-full px-2 pt-2">
      <div className="h-1/5 w-full flex justify-start items-end pl-6">
        <button
          className="w-1/4 pt-2 px-2 pb-1 text-center text-white text-sm focus:outline-none"
          style={{
            backgroundColor:
              selected === "assignments" ? commonColor.deepPink : "transparent",
            borderTopLeftRadius: "1.5rem",
            borderTopRightRadius: "1.5rem",
          }}
          onClick={() => setSelected("assignments")}
        >
          <div className="flex justify-center items-center">
            <Assignments className="w-4" />
            <p className="hidden ml-2 md:block">Assignments</p>
          </div>
        </button>

        <button
          className="w-1/4 pt-2 px-2 pb-1 text-center text-white text-sm focus:outline-none"
          style={{
            backgroundColor:
              selected === "recordings" ? commonColor.deepPink : "transparent",
            borderTopLeftRadius: "1.5rem",
            borderTopRightRadius: "1.5rem",
          }}
          onClick={() => setSelected("recordings")}
        >
          <div className="flex justify-center items-center">
            <Recordings className="w-4" />
            <p className="hidden ml-2 md:block">Recordings</p>
          </div>
        </button>
        {SHOW_H5P && (
          <button
            className="w-1/4 pt-2 px-2 pb-1 text-center text-white text-sm focus:outline-none"
            style={{
              backgroundColor:
                selected === "h5p" ? commonColor.deepPink : "transparent",
              borderTopLeftRadius: "1.5rem",
              borderTopRightRadius: "1.5rem",
            }}
            onClick={() => setSelected("h5p")}
          >
            <div className="flex justify-center items-center">
              <H5P className="w-4" />
              <p className="hidden ml-2 md:block">H5P</p>
            </div>
          </button>
        )}
      </div>

      <div
        className="w-full h-4/5 bg-white darker_shadow p-4 relative"
        style={{
          borderRadius: "1.5rem",
        }}
      >
        {selected === "assignments" && (
          <MaterialUploaderViewer
            courseId={courseId}
            uploadPath={`courses/${courseId}/material/assignments`}
            materialType="assignments"
            addButtonText="Add Assignment"
            noItemsText="No assignments yet."
            readOnly={readOnly}
            itemsExisting={assignments}
          />
        )}

        {selected === "recordings" && (
          <CourseRecording
            recordings={recordings}
            noItemsText="No recording yet."
            courseShortName={courseShortName}
          />
        )}

        {selected === "h5p" && (
          <H5PContentSection
            user={user}
            content={h5pContent}
            courseShortName={courseShortName}
            playContent={(widget) => {
              showPopover && showPopover(widget);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default memo(CourseMaterial);
