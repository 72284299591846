import { createRoomForSession } from "./api";

export const createBBBRoom = async (payload, tutorId) => {
  const result = await createRoomForSession(payload, tutorId);
  console.log("createRoomForSession api result:", result)
  if (result.ok) {
    console.log("createBBBRoom - created", result.data);
  }
  return result.data?.url;
};
