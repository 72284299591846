import { useState, useEffect } from "react";

import {
  getCourseDetails,
  getCourseListOfTutor,
  getCourseContents,
  addCourseContent,
  updateCourseContent,
  deleteCourseContent,
} from "../../services/api";

import { capitalize } from "../../utils/uiUtils";

import TutorCalendar from "../../components/tutor/calendar";
import Select from "../../components/common/input/select";

import commonColor from "../../theme/commonColor";
import StudentList from "../../components/tutor/studentList";
import ReactPlayer from "react-player/lazy";
import CourseMaterial from "../../components/courses/courseMaterial";
import EditButton from "../../components/common/buttons/editButton";
import MaterialUploaderViewer from "../../components/courses/materialUploaderViewer";
import CourseContent from "./coursesTutor/CourseContent";
import Modal from "../../components/common/modal/modal";
import { QuickModal } from "../../components/common/modal/modal";
import CourseContentEditor from "./coursesTutor/CourseContentEditor";

import CustomFadeLoader from "../../components/common/CustomFadeLoader";
import { ReactComponent as Edit } from "../../assets/icons/editing.svg";

import { ReactComponent as About } from "../../assets/images/courses/about.svg";
import { ReactComponent as Content } from "../../assets/images/courses/content.svg";
import { ReactComponent as Resources } from "../../assets/images/courses/resources.svg";
import { ReactComponent as Schedule } from "../../assets/images/courses/schedule.svg";
import { ReactComponent as Student } from "../../assets/images/courses/student.svg";
import { NavigateFunction, useNavigate } from "react-router-dom";
import FooterView from "../../components/common/footerView";

const mockLessons = [
  { value: "lesson1", label: "lesson1" },
  { value: "lesson2", label: "lesson2" },
];

const CourseDetails = ({
  user,
  courseId,
  showPopover
}) => {
  const navigate = useNavigate();
  const [courseDetails, setCourseDetails] = useState();
  const [courseLoaded, setCourseLoaded] = useState(false);
  const [myLessons, setMyLessons] = useState(mockLessons);
  const [topSelected, setTopSelected] = useState();
  const [bottomSelected, setBottomSelected] = useState();
  const [selectedCourseId, setSelectedCourseId] = useState(courseId);
  const [selectedCourseNameShort, setSelectedCourseNameShort] = useState();
  const [showCourseContentModal, setShowCourseContentModal] = useState(false);
  const [editingContentIndex, setEditingContentIndex] = useState(-1);
  const [deletingContentId, setDeletingContentId] = useState(-1);
  const [courseContent, setCourseContent] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    console.log(
      "CourseDetails - useEffect - select value",
      selectedCourseId,
      selectedCourseNameShort,
      courseDetails
    );
  }, [selectedCourseId, selectedCourseNameShort, courseDetails]);

  useEffect(() => {
    const getCourseInfo = async () => {
      const result = await getCourseDetails(selectedCourseId);
      if (result && result.data) {
        console.log(
          "CourseDetails - useEffect - got course details",
          result.data
        );
        if (result.data.tutorFirebaseId && result.data.tutorFirebaseId != user.uid) {
          //Show Course details of Another Tutor
          navigate(`/courses/${selectedCourseId}`, {
            state: {
              courseSummary: true
            },
          });
        } else {
          setCourseDetails(result.data);
          setSelectedCourseNameShort(result.data.nameShort);
          setCourseLoaded(true);
        }
      }
    };

    if (!courseLoaded) {
      getCourseInfo();
    }
  }, [selectedCourseId, courseLoaded]);

  useEffect(() => {
    setTopSelected("Schedule");
    setBottomSelected("About");

    const getCoursesOfTutor = async () => {
      const result = await getCourseListOfTutor(user.uid);
      if (result) {
        console.log("CourseDetails - useEffect - got courses", result.data);
        const tutorCourses = result.data.courses.map((course) => {
          return { value: course.courseId, label: course.nameShort };
        });
        setMyLessons(tutorCourses);
      }
    };

    getCoursesOfTutor();
  }, []);

  useEffect(() => {
    selectedCourseId && getContents();
  }, [selectedCourseId]);

  const closeModal = () => {
    setShowCourseContentModal(false);
  };

  //----------------Add/Update/Delete course content-------------------//
  const saveContentHandler = async (
    courseId,
    title,
    description,
    contentId
  ) => {
    closeModal();
    if (courseId && (title || description)) {
      const item = {
        title: title,
        body: description,
        path: "",
        type: "content",
        course_id: courseId,
      };
      if (contentId) {
        //Update
        setCourseContent(
          courseContent.map((e) => {
            if (e.id === contentId) {
              e.title = item.title;
              e.body = item.body;
            }
            return e;
          })
        );

        // var contentItems = courseContent;
        // const index = contentItems.findIndex(e => e.id === contentId);
        // if(index > -1) {
        //   contentItems[index].title = item.title;
        //   contentItems[index].body = item.body;
        //   setCourseContent(contentItems);
        // }
        updateContent(contentId, item);
      } else {
        //Add
        setCourseContent([...courseContent, item]);
        addContent(item);
      }
    }
  };

  const addContentHandler = async () => {
    setEditingContentIndex(-1);
    setShowCourseContentModal(true);
  };

  const editContentHandler = (index) => {
    const item = courseContent[index];
    if (item && item.id) {
      setEditingContentIndex(index);
      setShowCourseContentModal(true);
    }
  };

  const deleteContentHandler = async (index) => {
    setEditingContentIndex(-1);
    const item = courseContent[index];
    if (item && item.id) {
      setDeletingContentId(item.id);
    }
  };

  const getContents = async () => {
    const result = await getCourseContents(selectedCourseId);
    if (result && result.data && Array.isArray(result.data.data)) {
      console.log(
        "CourseLandingPage - useEffect - got tutorAbout",
        result.data
      );

      let d = result.data.data.filter((i) => i.content_type === "content");
      let sortedData = d.sort(
        (a, b) => (a.created_at ?? 0) - (b.created_at ?? 0)
      );
      setCourseContent(sortedData);
    }
  };

  const addContent = async (item) => {
    const result = await addCourseContent(item);
    if (result && result.data && result.data.status === "SUCCESS") {
      getContents();
    }
  };

  const updateContent = async (contentId, item) => {
    const result = await updateCourseContent(contentId, item);
    if (result && result.data && result.data.status === "SUCCESS") {
      getContents();
    }
  };

  const deleteContent = async () => {
    const contentId = deletingContentId;
    setDeletingContentId(-1);
    setCourseContent(courseContent.filter((e) => e.id !== contentId));
    const result = await deleteCourseContent(contentId);
    if (result && result.data && result.data.status === "SUCCESS") {
      getContents();
    }
  };
  ///-------  ---------//

  const renderTopSelectedTab = () => {
    if (topSelected === "Schedule") {
      return (
        <div className="w-full h-full flex justify-center items-center py-4 relative">
          <TutorCalendar
            user={user}
            view={"day"}
            views={["day"]}
            course={courseDetails}
          />
        </div>
      );
    } else if (topSelected === "Students") {
      return (
        <div className="w-full h-full flex justify-center items-center pl-8 pr-16 py-12 overflow-y-scroll">
          <StudentList course={courseDetails?.nameShort} />
        </div>
      );
    }
  };

  const renderTutoringPreference = (course) => {
    if (course.tutoringPreference === "specEdu") {
      return <div className="font-bold"> {"Special Education"}</div>;
    } else if (course.tutoringPreference === "individuals") {
      return <div className="font-bold"> {"Individuals"}</div>;
    } else {
      return <div className="font-bold"> {"Groups"}</div>;
    }
  };

  const renderBottomSelectedTab = () => {
    return (
      <>
        <div
          className={`w-full h-full flex flex-col justify-start items-start p-4 relative ${bottomSelected !== "About" ? "hidden" : ""
            }`}
        >
          <div
            className={`w-full flex flex-grow flex-col justify-start items-start overflow-y-scroll`}
          >
            <div className="text-lg">
              {`${courseDetails.subject} - ${courseDetails.subjectArea}`}{" "}
              <span className="pb-2 italic text-sm">
                {`taught in  ${capitalize(courseDetails.language)}`}
              </span>
              {courseDetails.rate != 0 ? `, Rate: ${courseDetails.rate}$/hr` : ''}
            </div>
            <div className="flex flex-start">
              {renderTutoringPreference(courseDetails)}
            </div>
            <div className="text-xs mb-8 pt-4 w-full break-words">
              {courseDetails.summary}
            </div>
          </div>
          <div
            style={{ position: "absolute", bottom: ".75rem", right: ".75rem" }}
          >
            <EditButton
              onclick={() => {
                console.log("Edit course pressed", courseDetails);
                navigate(`/edit-course/${courseDetails.courseId}`);
              }}
            />
          </div>
        </div>
        <div
          className={`w-full h-full flex justify-center items-center p-2 relative ${bottomSelected !== "Content" ? "hidden" : ""
            }`}
        >
          <CourseContent
            courseId={selectedCourseId}
            items={courseContent}
            noItemsText="No course lesson found."
            addClick={addContentHandler}
            editClick={editContentHandler}
            deleteClick={deleteContentHandler}
          />
        </div>
        <div
          className={`w-full h-full flex justify-center items-center p-2 relative ${bottomSelected !== "Resources" ? "hidden" : ""
            }`}
        >
          <MaterialUploaderViewer
            courseId={selectedCourseId}
            uploadPath={`courses/${selectedCourseId}/material/resources`}
            materialType="resources"
            addButtonText="Add Resource"
            noItemsText="No resources yet."
          />
        </div>
      </>
    );
  };

  return (
    <div className="h-full w-full relative">
      <div className=" h-full w-full flex flex-col justify-between absolute">
        {courseLoaded ? (
          <div className=" h-full w-full flex flex-wrap justify-center">
            <div className="h-7/10 w-11/12 lg:w-3/4 xl:w-1/2 px-2">
              <div
                className=" w-full flex justify-between items-center"
                style={{
                  height: "15%",
                }}
              >
                <div className="w-1/4 text-white px-2">Course</div>
                <div className="w-3/4 py-1 flex items-center justify-between">
                  <Select
                    className=" w-full focus:outline-none"
                    isRadiusUsed
                    //value={{ value: selectedCourseId, label: selectedCourseNameShort }}
                    defaultValue={{
                      value: courseDetails?.courseId,
                      label: courseDetails?.nameShort,
                    }}
                    value={
                      selectedCourseNameShort
                        ? {
                          value: selectedCourseId,
                          label: selectedCourseNameShort,
                        }
                        : myLessons[0]
                    }
                    onChange={(value) => {
                      console.log("CourseDetails- select- value", value);
                      setCourseLoaded(false);
                      setSelectedCourseId(value.value);
                      // navigate('/courses/detail:${value.value}')
                      // history.push(`/courses/detail/${value.value}`);
                      navigate(`/courses/${value.value}`);
                      // setTutoringRegistration(value)
                      // handleChange('tutoringRegistration', value.value)
                    }}
                    options={myLessons}
                  />
                  <div className="editCourseButtonStatic pl-6 pt-1 pr-3">
                    <EditButton
                      // courseDetails={courseDetails}
                      // setCurrentView={setCurrentView}
                      // setCourseId={setCourseId}
                      // setViewMode={setViewMode}
                      onclick={() => {
                        console.log("Edit course pressed", courseDetails);
                        // setCurrentView("createCourse");
                        // setViewMode("edit");
                        // setCourseId(courseDetails.courseId);
                        navigate(`/edit-course/${courseDetails.courseId}`);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className="w-full  bg-transparent darker_shadow"
                style={{
                  borderRadius: "1.5rem",
                  height: "85%",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                {courseDetails?.videoUrl &&
                  courseDetails?.videoUrl !== "undefined" &&
                  courseDetails?.videoUrl !== "null" ? (
                  <ReactPlayer
                    url={courseDetails?.videoUrl}
                    width={"100%"}
                    height={"100%"}
                    style={{
                      borderRadius: 25,
                      backgroundColor: "black",
                    }}
                    playing={true}
                    controls={true}
                    light={
                      courseDetails?.videoThumbnailUrl
                        ? courseDetails?.videoThumbnailUrl
                        : true
                    }
                  />
                ) : courseDetails?.photoUrl ? (
                  <img
                    src={courseDetails.photoUrl}
                    width={"100%"}
                    height={"100%"}
                    style={{
                      borderRadius: 25,
                      objectFit: "cover",
                      minWidth: "100%",
                      minHeight: "100%",
                    }}
                  />
                ) : (
                  <ReactPlayer
                    url="https://www.youtube.com/watch?v=saQBW9Xs5eI"
                    width={"100%"}
                    height={"100%"}
                    style={{
                      borderRadius: 25,
                    }}
                  />
                )}
              </div>
            </div>

            {/* <div className="h-7/10 w-11/12 lg:w-3/4 xl:w-1/2 px-2"> */}
            <div className="max-h-full xl:h-7/10 w-11/12 lg:w-3/4 xl:w-1/2 px-2 xl:pb-2">
              <div
                className="w-full h-12 xl:h-[15%] flex justify-start items-end pl-6"
                style={{
                }}
              >
                <button
                  className="w-1/4 pt-2 px-2 pb-1 text-center text-white text-sm focus:outline-none"
                  style={{
                    backgroundColor:
                      topSelected === "Schedule"
                        ? commonColor.deepPink
                        : "transparent",
                    borderTopLeftRadius: "1.5rem",
                    borderTopRightRadius: "1.5rem",
                  }}
                  onClick={() => setTopSelected("Schedule")}
                >
                  <div className="flex justify-center items-center">
                    <Schedule className="w-4" />
                    <p className="hidden ml-2 md:block">Schedule</p>
                  </div>
                </button>

                <button
                  className="w-1/4 pt-2 px-2 pb-1 text-center text-white text-sm focus:outline-none"
                  style={{
                    backgroundColor:
                      topSelected === "Students"
                        ? commonColor.deepPink
                        : "transparent",
                    borderTopLeftRadius: "1.5rem",
                    borderTopRightRadius: "1.5rem",
                  }}
                  onClick={() => setTopSelected("Students")}
                >
                  <div className="flex justify-center items-center">
                    <Student className="w-4" />
                    <p className="hidden ml-2 md:block">Student(s)</p>
                  </div>
                </button>
              </div>
              <div
                className="w-full h-[calc(100%-3rem)] xl:h-[85%]  bg-white darker_shadow overflow-y-scroll"
                style={{
                  borderRadius: "1.5rem",
                }}
              >
                {renderTopSelectedTab()}
              </div>
            </div>

            <div className="h-60 md:h-80 lg:h-96 xl:h-3/10 w-11/12 lg:w-3/4 xl:w-1/2 px-2 pt-2 xl:pb-4">
              <div
                className="h-1/5 w-full flex justify-start items-end pl-6"
                style={
                  {
                    // height: "15%"
                  }
                }
              >
                <button
                  className="w-1/4 pt-2 px-2 pb-1 text-center text-white text-sm focus:outline-none"
                  style={{
                    backgroundColor:
                      bottomSelected === "About"
                        ? commonColor.deepPink
                        : "transparent",
                    borderTopLeftRadius: "1.5rem",
                    borderTopRightRadius: "1.5rem",
                  }}
                  onClick={() => {
                    setIsOpen(true);
                    setBottomSelected("About");
                  }}
                >
                  <div className="flex justify-center items-center">
                    <About className="w-4" />
                    <p className="hidden ml-2 md:block">About</p>
                  </div>
                </button>

                <button
                  className="w-1/4 pt-2 px-2 pb-1 text-center text-white text-sm focus:outline-none"
                  style={{
                    backgroundColor:
                      bottomSelected === "Content"
                        ? commonColor.deepPink
                        : "transparent",
                    borderTopLeftRadius: "1.5rem",
                    borderTopRightRadius: "1.5rem",
                  }}
                  onClick={() => setBottomSelected("Content")}
                >
                  <div className="flex justify-center items-center">
                    <Content className="w-4" />
                    <p className="hidden ml-2 md:block">Lesson plan</p>
                  </div>
                </button>

                <button
                  className="w-1/4 pt-2 px-2 pb-1 text-center text-white text-sm focus:outline-none"
                  style={{
                    backgroundColor:
                      bottomSelected === "Resources"
                        ? commonColor.deepPink
                        : "transparent",
                    borderTopLeftRadius: "1.5rem",
                    borderTopRightRadius: "1.5rem",
                  }}
                  onClick={() => setBottomSelected("Resources")}
                >
                  <div className="flex justify-center items-center">
                    <Resources className="w-4" />
                    <p className="hidden ml-2 md:block">Resources</p>
                  </div>
                </button>
              </div>

              <div
                className="w-full h-4/5 bg-white darker_shadow flex items-center"
                style={{
                  borderRadius: "1.5rem",
                }}
              >
                {courseDetails && renderBottomSelectedTab()}
              </div>
            </div>

            <div className="h-60 md:h-80 lg:h-96 xl:h-3/10 w-11/12 lg:w-3/4 xl:w-1/2 xl:pb-4">
              <CourseMaterial
                courseId={selectedCourseId}
                user={user}
                courseShortName={selectedCourseNameShort}
                showPopover={(content) => {
                  if (content) {
                    // setPopoverContent(content);
                    showPopover && showPopover(content);
                    // setIsOpen(true);
                  }
                }}
              />
            </div>

            <div className="w-full xl:hidden">
              <FooterView />
            </div>
          </div>
        ) : (
          <div className="flex h-full justify-center flex-col items-center space-y-2">
            <p className="text-white italic">Loading course...</p>
            <CustomFadeLoader loading={true} />
          </div>
        )}

        {!courseLoaded && (
          <div className="w-full">
            {" "}
            <FooterView />{" "}
          </div>
        )}

        <Modal
          isOpen={showCourseContentModal}
          // onAfterOpen={afterOpenModal}
          onRequestClose={() => closeModal()}
        // contentLabel="Create new event"
        >
          {
            <CourseContentEditor
              courseId={selectedCourseId}
              contentItem={
                editingContentIndex > -1
                  ? courseContent[editingContentIndex]
                  : null
              }
              onSave={saveContentHandler}
            />
          }
        </Modal>
        <QuickModal
          isOpen={deletingContentId !== -1}
          title={"Delete Course Content Item"}
          message={"You are about to delete a course content item."}
          onRequestClose={() => setDeletingContentId(-1)}
          firstButtonTitle={"Delete"}
          secondButtonTitle={"Cancel"}
          onFirstButtonClick={() => deleteContent()}
          onSecondButtonClick={() => {
            setDeletingContentId(-1);
          }}
        />
      </div>
      {/* <div
        className="h-full w-full bg-white absolute"
        style={{
          zIndex: !isOpen ? 0 : 10,
          // left: !isOpen ? "1000px" : "0",
          // right: "0",
          // bottom: "0",
          // left: "0",
          opacity: !isOpen ? "0" : "1",
          transition: "all .2s",
          visibility: !isOpen ? "hidden" : "visible",
        }}
      >
        <div className="h-full w-full flex flex-col justify-start">
          <div className="w-full flex justify-start">
          <Swotbutton
            text="close"
            onClick={() => {
              setIsOpen(false);
              setPopoverContent(undefined);
            }}
          />
          </div>
          <div>
            {popoverContent}
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default CourseDetails;
