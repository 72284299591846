import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import FooterView from "../../components/common/footerView";
import MainPageWithMenu from "../../components/common/MainPageTemplate/mainPageWithMenu";
import StudentProfile from "../../components/student/profile/StudentProfile";
import TutorProfile from "../../components/tutor/profile/TutorProfile";
import TutorSetupPrompt from "../../components/tutor/tutorSetupPrompt";
import { ACCOUNT_TYPES } from "../../config/constants";

const ProfilePage = ({ user, location }) => {
  const { photoUrl, displayName, email } = user;

  const renderCurrentView = () => {
    if (user.accountType === "Tutor") {
      if (!(user.setupStatus)) {
        return <TutorSetupPrompt />;
      }
      return <TutorProfile />;
      //return <TutorProfile location={location}/>;
    } else if (user.accountType === ACCOUNT_TYPES.STUDENT || user.accountType === ACCOUNT_TYPES.SWOT_BUDDY) {
      return <StudentProfile />;
    } else {
      return (
        <div className="w-full h-full flex flex-col justify-end">
          <FooterView />
        </div>
      );
    }
  };

  return (
    <MainPageWithMenu user={user} location={location}>
      {user && renderCurrentView()}
    </MainPageWithMenu>
  );
};
const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
