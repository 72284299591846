import React, { useState, useEffect } from "react";
import Checkbox from "../../common/input/checkbox";
import commonColor from "../../../theme/commonColor";
import SwotButton from "../../common/buttons/swotbutton";
import { deleteAccountRequest, getUserSettings, updateUserSettings } from "../../../services/api";
import {
  TermsCheckbox,
  TermsLink,
  GDPR,
  PrivacyPolicy,
} from "../../common/consent";
import { QuickModal } from "../../common/modal/modal";
import swal from "sweetalert2";

const Settings = ({ user }) => {
  const [allowDesktopNotifications, setAllowDesktopNotifications] = useState();
  const [isProfilePrivate, setIsProfilePrivate] = useState();
  const [allowContactViaEmail, setAllowContactViaEmail] = useState();
  const [initialLoad, setInitialLoad] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  /**
   * {
    "allowDesktopNotifications": true,
    "privateProfile": false,
    "allowEmailContact": true
}
   */

  useEffect(() => {
    const getSettings = async () => {
      const response = await getUserSettings(user.uid);
      if (response && response.data) {
        console.log(`useEffect  - got user settings:`, response.data);
        setAllowDesktopNotifications(response.data.allowDesktopNotifications);
        setIsProfilePrivate(response.data.privateProfile);
        setAllowContactViaEmail(response.data.allowEmailContact);
        setInitialLoad(true);
      }
    };

    getSettings();
  }, []);

  useEffect(() => {
    const updateSettings = async () => {
      const reslt = await updateUserSettings(user.uid, {
        allowDesktopNotifications,
        privateProfile: isProfilePrivate,
        allowEmailContact: allowContactViaEmail,
      });
      console.log(reslt);
    };
    if (initialLoad) {
      updateSettings();
    }
  }, [
    isProfilePrivate,
    allowContactViaEmail,
    allowDesktopNotifications,
    user.uid,
    initialLoad,
  ]);

  async function deleteAccountRequestHandler() {
    try {
      console.log(user.uid);
      setOpenModal(false);

      let res = await deleteAccountRequest(user.uid);
      console.log(res);

      if (res.data.status === "SUCCESS") {
        swal.fire({
          title: "Success!",
          text: "Account Delete Request Submitted",
          icon: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "#407288",
        });
      }
      else if (res.data.status === "ERROR") {
        swal.fire({
          title: "Unable to Request Account Delete",
          icon: "error",
          cancelButtonColor: "#d33",
        });
      }
    } catch (e) {
      swal.fire({
        title: "Unable to Request Account Delete",
        icon: "error",
        cancelButtonColor: "#d33",
      });
    }
  }

  return (
    <div className="h-full w-full flex flex-col justify-start items-center space-y-8">
      <div className="w-full lg:w-3/4 flex flex-row justify-start px-2">
        <Checkbox
          label={" Allow notifications"}
          isSelected={allowDesktopNotifications}
          onCheckboxChange={(changeEvent) => {
            setAllowDesktopNotifications(!allowDesktopNotifications);
          }}
          key={"allowDesktopNotifications"}
        />
      </div>
      {/* <div className="w-2/3 lg:w-3/4 flex flex-row justify-start px-2">
        <Checkbox
          label={" Private profile"}
          isSelected={isProfilePrivate}
          onCheckboxChange={(changeEvent) => {
            setIsProfilePrivate(!isProfilePrivate);
          }}
          key={"isProfilePrivate"}
        />
      </div> */}
      <div className="w-full lg:w-3/4 flex flex-row justify-start px-2">
        <Checkbox
          label={" Allow contact via email"}
          isSelected={allowContactViaEmail}
          onCheckboxChange={(changeEvent) => {
            setAllowContactViaEmail(!allowContactViaEmail);
          }}
          key={"allowContactViaEmail"}
        />
      </div>

      <div className="w-full lg:w-3/4 float-left lg:float-right flex justify-start px-2">
        <TermsCheckbox checked={true} disabled={true} />
        <div className="text-sm">
          Agreed on <TermsLink />, <PrivacyPolicy />.{" "}
        </div>
      </div>

      <div className="w-full lg:w-3/4 flex flex-row justify-start px-2 flex-wrap items-center">
        <div className="mr-4 text-sm">Do you want to delete your profile? </div>
        <div className="flex w-full justify-center pt-4">
          <div>
            <SwotButton text="Delete" onClick={() => {
              setOpenModal(true)
            }} />
          </div>
        </div>
      </div>

      {/* <SwotButton
        text={"Delete my account"}
        onClick={() => console.log("Delete my account pressed!")}
      /> */}
      <QuickModal
        isOpen={openModal}
        onRequestClose={() => {
          setOpenModal(false);
        }}
        title={"Alert!"}
        message={"Your profile will be deleted. Do you wanna proceed?"}
        firstButtonTitle={"Cancel"}
        secondButtonTitle={"Delete"}
        onFirstButtonClick={() => {
          setOpenModal(false)
        }}
        onSecondButtonClick={() => {
          deleteAccountRequestHandler()
        }}
        // secondButtonClassName={"bg-red-600 text-white"}
        secondButtonStyle={{
          "color": "white",
          "background-color": commonColor.deepPink,
          border: "1px solid white"
        }}
      ></QuickModal>
    </div>
  );
};

export default Settings;
