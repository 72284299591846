export const languages = [
    {
      value: "ab",
      label: "Abkhaz",
      nativeName: "аҧсуа"
    },
    {
      value: "aa",
      label: "Afar",
      nativeName: "Afaraf"
    },
    {
      value: "af",
      label: "Afrikaans",
      nativeName: "Afrikaans"
    },
    {
      value: "ak",
      label: "Akan",
      nativeName: "Akan"
    },
    {
      value: "sq",
      label: "Albanian",
      nativeName: "Shqip"
    },
    {
      value: "am",
      label: "Amharic",
      nativeName: "አማርኛ"
    },
    {
      value: "ar",
      label: "Arabic",
      nativeName: "العربية"
    },
    {
      value: "an",
      label: "Aragonese",
      nativeName: "Aragonés"
    },
    {
      value: "hy",
      label: "Armenian",
      nativeName: "Հայերեն"
    },
    {
      value: "as",
      label: "Assamese",
      nativeName: "অসমীয়া"
    },
    {
      value: "av",
      label: "Avaric",
      nativeName: "авар мацӀ, магӀарул мацӀ"
    },
    {
      value: "ae",
      label: "Avestan",
      nativeName: "avesta"
    },
    {
      value: "ay",
      label: "Aymara",
      nativeName: "aymar aru"
    },
    {
      value: "az",
      label: "Azerbaijani",
      nativeName: "azərbaycan dili"
    },
    {
      value: "bm",
      label: "Bambara",
      nativeName: "bamanankan"
    },
    {
      value: "ba",
      label: "Bashkir",
      nativeName: "башҡорт теле"
    },
    {
      value: "eu",
      label: "Basque",
      nativeName: "euskara, euskera"
    },
    {
      value: "be",
      label: "Belarusian",
      nativeName: "Беларуская"
    },
    {
      value: "bn",
      label: "Bengali",
      nativeName: "বাংলা"
    },
    {
      value: "bh",
      label: "Bihari",
      nativeName: "भोजपुरी"
    },
    {
      value: "bi",
      label: "Bislama",
      nativeName: "Bislama"
    },
    {
      value: "bs",
      label: "Bosnian",
      nativeName: "bosanski jezik"
    },
    {
      value: "br",
      label: "Breton",
      nativeName: "brezhoneg"
    },
    {
      value: "bg",
      label: "Bulgarian",
      nativeName: "български език"
    },
    {
      value: "my",
      label: "Burmese",
      nativeName: "ဗမာစာ"
    },
    {
      value: "ca",
      label: "Catalan; Valencian",
      nativeName: "Català"
    },
    {
      value: "ch",
      label: "Chamorro",
      nativeName: "Chamoru"
    },
    {
      value: "ce",
      label: "Chechen",
      nativeName: "нохчийн мотт"
    },
    {
      value: "ny",
      label: "Chichewa; Chewa; Nyanja",
      nativeName: "chiCheŵa, chinyanja"
    },
    {
      value: "zh",
      label: "Chinese",
      nativeName: "中文 (Zhōngwén), 汉语, 漢語"
    },
    {
      value: "cv",
      label: "Chuvash",
      nativeName: "чӑваш чӗлхи"
    },
    {
      value: "kw",
      label: "Cornish",
      nativeName: "Kernewek"
    },
    {
      value: "co",
      label: "Corsican",
      nativeName: "corsu, lingua corsa"
    },
    {
      value: "cr",
      label: "Cree",
      nativeName: "ᓀᐦᐃᔭᐍᐏᐣ"
    },
    {
      value: "hr",
      label: "Croatian",
      nativeName: "hrvatski"
    },
    {
      value: "cs",
      label: "Czech",
      nativeName: "česky, čeština"
    },
    {
      value: "da",
      label: "Danish",
      nativeName: "dansk"
    },
    {
      value: "dv",
      label: "Divehi; Dhivehi; Maldivian;",
      nativeName: "ދިވެހި"
    },
    {
      value: "nl",
      label: "Dutch",
      nativeName: "Nederlands, Vlaams"
    },
    {
      value: "en",
      label: "English",
      nativeName: "English"
    },
    {
      value: "eo",
      label: "Esperanto",
      nativeName: "Esperanto"
    },
    {
      value: "et",
      label: "Estonian",
      nativeName: "eesti, eesti keel"
    },
    {
      value: "ee",
      label: "Ewe",
      nativeName: "Eʋegbe"
    },
    {
      value: "fo",
      label: "Faroese",
      nativeName: "føroyskt"
    },
    {
      value: "fj",
      label: "Fijian",
      nativeName: "vosa Vakaviti"
    },
    {
      value: "fi",
      label: "Finnish",
      nativeName: "suomi, suomen kieli"
    },
    {
      value: "fr",
      label: "French",
      nativeName: "français, langue française"
    },
    {
      value: "ff",
      label: "Fula; Fulah; Pulaar; Pular",
      nativeName: "Fulfulde, Pulaar, Pular"
    },
    {
      value: "gl",
      label: "Galician",
      nativeName: "Galego"
    },
    {
      value: "ka",
      label: "Georgian",
      nativeName: "ქართული"
    },
    {
      value: "de",
      label: "German",
      nativeName: "Deutsch"
    },
    {
      value: "el",
      label: "Greek, Modern",
      nativeName: "Ελληνικά"
    },
    {
      value: "gn",
      label: "Guaraní",
      nativeName: "Avañeẽ"
    },
    {
      value: "gu",
      label: "Gujarati",
      nativeName: "ગુજરાતી"
    },
    {
      value: "ht",
      label: "Haitian; Haitian Creole",
      nativeName: "Kreyòl ayisyen"
    },
    {
      value: "ha",
      label: "Hausa",
      nativeName: "Hausa, هَوُسَ"
    },
    {
      value: "he",
      label: "Hebrew (modern)",
      nativeName: "עברית"
    },
    {
      value: "hz",
      label: "Herero",
      nativeName: "Otjiherero"
    },
    {
      value: "hi",
      label: "Hindi",
      nativeName: "हिन्दी, हिंदी"
    },
    {
      value: "ho",
      label: "Hiri Motu",
      nativeName: "Hiri Motu"
    },
    {
      value: "hu",
      label: "Hungarian",
      nativeName: "Magyar"
    },
    {
      value: "ia",
      label: "Interlingua",
      nativeName: "Interlingua"
    },
    {
      value: "id",
      label: "Indonesian",
      nativeName: "Bahasa Indonesia"
    },
    {
      value: "ie",
      label: "Interlingue",
      nativeName: "Originally called Occidental; then Interlingue after WWII"
    },
    {
      value: "ga",
      label: "Irish",
      nativeName: "Gaeilge"
    },
    {
      value: "ig",
      label: "Igbo",
      nativeName: "Asụsụ Igbo"
    },
    {
      value: "ik",
      label: "Inupiaq",
      nativeName: "Iñupiaq, Iñupiatun"
    },
    {
      value: "io",
      label: "Ido",
      nativeName: "Ido"
    },
    {
      value: "is",
      label: "Icelandic",
      nativeName: "Íslenska"
    },
    {
      value: "it",
      label: "Italian",
      nativeName: "Italiano"
    },
    {
      value: "iu",
      label: "Inuktitut",
      nativeName: "ᐃᓄᒃᑎᑐᑦ"
    },
    {
      value: "ja",
      label: "Japanese",
      nativeName: "日本語 (にほんご／にっぽんご)"
    },
    {
      value: "jv",
      label: "Javanese",
      nativeName: "basa Jawa"
    },
    {
      value: "kl",
      label: "Kalaallisut, Greenlandic",
      nativeName: "kalaallisut, kalaallit oqaasii"
    },
    {
      value: "kn",
      label: "Kannada",
      nativeName: "ಕನ್ನಡ"
    },
    {
      value: "kr",
      label: "Kanuri",
      nativeName: "Kanuri"
    },
    {
      value: "ks",
      label: "Kashmiri",
      nativeName: "कश्मीरी, كشميري‎"
    },
    {
      value: "kk",
      label: "Kazakh",
      nativeName: "Қазақ тілі"
    },
    {
      value: "km",
      label: "Khmer",
      nativeName: "ភាសាខ្មែរ"
    },
    {
      value: "ki",
      label: "Kikuyu, Gikuyu",
      nativeName: "Gĩkũyũ"
    },
    {
      value: "rw",
      label: "Kinyarwanda",
      nativeName: "Ikinyarwanda"
    },
    {
      value: "ky",
      label: "Kirghiz, Kyrgyz",
      nativeName: "кыргыз тили"
    },
    {
      value: "kv",
      label: "Komi",
      nativeName: "коми кыв"
    },
    {
      value: "kg",
      label: "Kongo",
      nativeName: "KiKongo"
    },
    {
      value: "ko",
      label: "Korean",
      nativeName: "한국어 (韓國語), 조선말 (朝鮮語)"
    },
    {
      value: "ku",
      label: "Kurdish",
      nativeName: "Kurdî, كوردی‎"
    },
    {
      value: "kj",
      label: "Kwanyama, Kuanyama",
      nativeName: "Kuanyama"
    },
    {
      value: "la",
      label: "Latin",
      nativeName: "latine, lingua latina"
    },
    {
      value: "lb",
      label: "Luxembourgish, Letzeburgesch",
      nativeName: "Lëtzebuergesch"
    },
    {
      value: "lg",
      label: "Luganda",
      nativeName: "Luganda"
    },
    {
      value: "li",
      label: "Limburgish, Limburgan, Limburger",
      nativeName: "Limburgs"
    },
    {
      value: "ln",
      label: "Lingala",
      nativeName: "Lingála"
    },
    {
      value: "lo",
      label: "Lao",
      nativeName: "ພາສາລາວ"
    },
    {
      value: "lt",
      label: "Lithuanian",
      nativeName: "lietuvių kalba"
    },
    {
      value: "lu",
      label: "Luba-Katanga",
      nativeName: ""
    },
    {
      value: "lv",
      label: "Latvian",
      nativeName: "latviešu valoda"
    },
    {
      value: "gv",
      label: "Manx",
      nativeName: "Gaelg, Gailck"
    },
    {
      value: "mk",
      label: "Macedonian",
      nativeName: "македонски јазик"
    },
    {
      value: "mg",
      label: "Malagasy",
      nativeName: "Malagasy fiteny"
    },
    {
      value: "ms",
      label: "Malay",
      nativeName: "bahasa Melayu, بهاس ملايو‎"
    },
    {
      value: "ml",
      label: "Malayalam",
      nativeName: "മലയാളം"
    },
    {
      value: "mt",
      label: "Maltese",
      nativeName: "Malti"
    },
    {
      value: "mi",
      label: "Māori",
      nativeName: "te reo Māori"
    },
    {
      value: "mr",
      label: "Marathi (Marāṭhī)",
      nativeName: "मराठी"
    },
    {
      value: "mh",
      label: "Marshallese",
      nativeName: "Kajin M̧ajeļ"
    },
    {
      value: "mn",
      label: "Mongolian",
      nativeName: "монгол"
    },
    {
      value: "na",
      label: "Nauru",
      nativeName: "Ekakairũ Naoero"
    },
    {
      value: "nv",
      label: "Navajo, Navaho",
      nativeName: "Diné bizaad, Dinékʼehǰí"
    },
    {
      value: "nb",
      label: "Norwegian Bokmål",
      nativeName: "Norsk bokmål"
    },
    {
      value: "nd",
      label: "North Ndebele",
      nativeName: "isiNdebele"
    },
    {
      value: "ne",
      label: "Nepali",
      nativeName: "नेपाली"
    },
    {
      value: "ng",
      label: "Ndonga",
      nativeName: "Owambo"
    },
    {
      value: "nn",
      label: "Norwegian Nynorsk",
      nativeName: "Norsk nynorsk"
    },
    {
      value: "no",
      label: "Norwegian",
      nativeName: "Norsk"
    },
    {
      value: "ii",
      label: "Nuosu",
      nativeName: "ꆈꌠ꒿ Nuosuhxop"
    },
    {
      value: "nr",
      label: "South Ndebele",
      nativeName: "isiNdebele"
    },
    {
      value: "oc",
      label: "Occitan",
      nativeName: "Occitan"
    },
    {
      value: "oj",
      label: "Ojibwe, Ojibwa",
      nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ"
    },
    {
      value: "cu",
      label: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
      nativeName: "ѩзыкъ словѣньскъ"
    },
    {
      value: "om",
      label: "Oromo",
      nativeName: "Afaan Oromoo"
    },
    {
      value: "or",
      label: "Oriya",
      nativeName: "ଓଡ଼ିଆ"
    },
    {
      value: "os",
      label: "Ossetian, Ossetic",
      nativeName: "ирон æвзаг"
    },
    {
      value: "pa",
      label: "Panjabi, Punjabi",
      nativeName: "ਪੰਜਾਬੀ, پنجابی‎"
    },
    {
      value: "pi",
      label: "Pāli",
      nativeName: "पाऴि"
    },
    {
      value: "fa",
      label: "Persian",
      nativeName: "فارسی"
    },
    {
      value: "pl",
      label: "Polish",
      nativeName: "polski"
    },
    {
      value: "ps",
      label: "Pashto, Pushto",
      nativeName: "پښتو"
    },
    {
      value: "pt",
      label: "Portuguese",
      nativeName: "Português"
    },
    {
      value: "qu",
      label: "Quechua",
      nativeName: "Runa Simi, Kichwa"
    },
    {
      value: "rm",
      label: "Romansh",
      nativeName: "rumantsch grischun"
    },
    {
      value: "rn",
      label: "Kirundi",
      nativeName: "kiRundi"
    },
    {
      value: "ro",
      label: "Romanian, Moldavian, Moldovan",
      nativeName: "română"
    },
    {
      value: "ru",
      label: "Russian",
      nativeName: "русский язык"
    },
    {
      value: "sa",
      label: "Sanskrit (Saṁskṛta)",
      nativeName: "संस्कृतम्"
    },
    {
      value: "sc",
      label: "Sardinian",
      nativeName: "sardu"
    },
    {
      value: "sd",
      label: "Sindhi",
      nativeName: "सिन्धी, سنڌي، سندھی‎"
    },
    {
      value: "se",
      label: "Northern Sami",
      nativeName: "Davvisámegiella"
    },
    {
      value: "sm",
      label: "Samoan",
      nativeName: "gagana faa Samoa"
    },
    {
      value: "sg",
      label: "Sango",
      nativeName: "yângâ tî sängö"
    },
    {
      value: "sr",
      label: "Serbian",
      nativeName: "српски језик"
    },
    {
      value: "gd",
      label: "Scottish Gaelic; Gaelic",
      nativeName: "Gàidhlig"
    },
    {
      value: "sn",
      label: "Shona",
      nativeName: "chiShona"
    },
    {
      value: "si",
      label: "Sinhala, Sinhalese",
      nativeName: "සිංහල"
    },
    {
      value: "sk",
      label: "Slovak",
      nativeName: "slovenčina"
    },
    {
      value: "sl",
      label: "Slovene",
      nativeName: "slovenščina"
    },
    {
      value: "so",
      label: "Somali",
      nativeName: "Soomaaliga, af Soomaali"
    },
    {
      value: "st",
      label: "Southern Sotho",
      nativeName: "Sesotho"
    },
    {
      value: "es",
      label: "Spanish; Castilian",
      nativeName: "español, castellano"
    },
    {
      value: "su",
      label: "Sundanese",
      nativeName: "Basa Sunda"
    },
    {
      value: "sw",
      label: "Swahili",
      nativeName: "Kiswahili"
    },
    {
      value: "ss",
      label: "Swati",
      nativeName: "SiSwati"
    },
    {
      value: "sv",
      label: "Swedish",
      nativeName: "svenska"
    },
    {
      value: "ta",
      label: "Tamil",
      nativeName: "தமிழ்"
    },
    {
      value: "te",
      label: "Telugu",
      nativeName: "తెలుగు"
    },
    {
      value: "tg",
      label: "Tajik",
      nativeName: "тоҷикӣ, toğikī, تاجیکی‎"
    },
    {
      value: "th",
      label: "Thai",
      nativeName: "ไทย"
    },
    {
      value: "ti",
      label: "Tigrinya",
      nativeName: "ትግርኛ"
    },
    {
      value: "bo",
      label: "Tibetan Standard, Tibetan, Central",
      nativeName: "བོད་ཡིག"
    },
    {
      value: "tk",
      label: "Turkmen",
      nativeName: "Türkmen, Түркмен"
    },
    {
      value: "tl",
      label: "Tagalog",
      nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔"
    },
    {
      value: "tn",
      label: "Tswana",
      nativeName: "Setswana"
    },
    {
      value: "to",
      label: "Tonga (Tonga Islands)",
      nativeName: "faka Tonga"
    },
    {
      value: "tr",
      label: "Turkish",
      nativeName: "Türkçe"
    },
    {
      value: "ts",
      label: "Tsonga",
      nativeName: "Xitsonga"
    },
    {
      value: "tt",
      label: "Tatar",
      nativeName: "татарча, tatarça, تاتارچا‎"
    },
    {
      value: "tw",
      label: "Twi",
      nativeName: "Twi"
    },
    {
      value: "ty",
      label: "Tahitian",
      nativeName: "Reo Tahiti"
    },
    {
      value: "ug",
      label: "Uighur, Uyghur",
      nativeName: "Uyƣurqə, ئۇيغۇرچە‎"
    },
    {
      value: "uk",
      label: "Ukrainian",
      nativeName: "українська"
    },
    {
      value: "ur",
      label: "Urdu",
      nativeName: "اردو"
    },
    {
      value: "uz",
      label: "Uzbek",
      nativeName: "zbek, Ўзбек, أۇزبېك‎"
    },
    {
      value: "ve",
      label: "Venda",
      nativeName: "Tshivenḓa"
    },
    {
      value: "vi",
      label: "Vietnamese",
      nativeName: "Tiếng Việt"
    },
    {
      value: "vo",
      label: "Volapük",
      nativeName: "Volapük"
    },
    {
      value: "wa",
      label: "Walloon",
      nativeName: "Walon"
    },
    {
      value: "cy",
      label: "Welsh",
      nativeName: "Cymraeg"
    },
    {
      value: "wo",
      label: "Wolof",
      nativeName: "Wollof"
    },
    {
      value: "fy",
      label: "Western Frisian",
      nativeName: "Frysk"
    },
    {
      value: "xh",
      label: "Xhosa",
      nativeName: "isiXhosa"
    },
    {
      value: "yi",
      label: "Yiddish",
      nativeName: "ייִדיש"
    },
    {
      value: "yo",
      label: "Yoruba",
      nativeName: "Yorùbá"
    },
    {
      value: "za",
      label: "Zhuang, Chuang",
      nativeName: "Saɯ cueŋƅ, Saw cuengh"
    }
  ];
 
 export const languagesOpts = [
    {
       value:"abkhaz",
       label:"Abkhaz"
    },
    {
       value:"afar",
       label:"Afar"
    },
    {
       value:"afrikaans",
       label:"Afrikaans"
    },
    {
       value:"akan",
       label:"Akan"
    },
    {
       value:"albanian",
       label:"Albanian"
    },
    {
       value:"amharic",
       label:"Amharic"
    },
    {
       value:"arabic",
       label:"Arabic"
    },
    {
       value:"aragonese",
       label:"Aragonese"
    },
    {
       value:"armenian",
       label:"Armenian"
    },
    {
       value:"assamese",
       label:"Assamese"
    },
    {
       value:"avaric",
       label:"Avaric"
    },
    {
       value:"avestan",
       label:"Avestan"
    },
    {
       value:"aymara",
       label:"Aymara"
    },
    {
       value:"azerbaijani",
       label:"Azerbaijani"
    },
    {
       value:"bambara",
       label:"Bambara"
    },
    {
       value:"bashkir",
       label:"Bashkir"
    },
    {
       value:"basque",
       label:"Basque"
    },
    {
       value:"belarusian",
       label:"Belarusian"
    },
    {
       value:"bengali",
       label:"Bengali"
    },
    {
       value:"bihari",
       label:"Bihari"
    },
    {
       value:"bislama",
       label:"Bislama"
    },
    {
       value:"bosnian",
       label:"Bosnian"
    },
    {
       value:"breton",
       label:"Breton"
    },
    {
       value:"bulgarian",
       label:"Bulgarian"
    },
    {
       value:"burmese",
       label:"Burmese"
    },
    {
       value:"catalan; valencian",
       label:"Catalan; Valencian"
    },
    {
       value:"chamorro",
       label:"Chamorro"
    },
    {
       value:"chechen",
       label:"Chechen"
    },
    {
       value:"chichewa; chewa; nyanja",
       label:"Chichewa; Chewa; Nyanja"
    },
    {
       value:"chinese",
       label:"Chinese"
    },
    {
       value:"chuvash",
       label:"Chuvash"
    },
    {
       value:"cornish",
       label:"Cornish"
    },
    {
       value:"corsican",
       label:"Corsican"
    },
    {
       value:"cree",
       label:"Cree"
    },
    {
       value:"croatian",
       label:"Croatian"
    },
    {
       value:"czech",
       label:"Czech"
    },
    {
       value:"danish",
       label:"Danish"
    },
    {
       value:"divehi; dhivehi; maldivian;",
       label:"Divehi; Dhivehi; Maldivian;"
    },
    {
       value:"dutch",
       label:"Dutch"
    },
    {
       value:"english",
       label:"English"
    },
    {
       value:"esperanto",
       label:"Esperanto"
    },
    {
       value:"estonian",
       label:"Estonian"
    },
    {
       value:"ewe",
       label:"Ewe"
    },
    {
       value:"faroese",
       label:"Faroese"
    },
    {
       value:"fijian",
       label:"Fijian"
    },
    {
       value:"finnish",
       label:"Finnish"
    },
    {
       value:"french",
       label:"French"
    },
    {
       value:"fula; fulah; pulaar; pular",
       label:"Fula; Fulah; Pulaar; Pular"
    },
    {
       value:"galician",
       label:"Galician"
    },
    {
       value:"georgian",
       label:"Georgian"
    },
    {
       value:"german",
       label:"German"
    },
    {
       value:"greek, modern",
       label:"Greek, Modern"
    },
    {
       value:"guaraní",
       label:"Guaraní"
    },
    {
       value:"gujarati",
       label:"Gujarati"
    },
    {
       value:"haitian; haitian creole",
       label:"Haitian; Haitian Creole"
    },
    {
       value:"hausa",
       label:"Hausa"
    },
    {
       value:"hebrew (modern)",
       label:"Hebrew (modern)"
    },
    {
       value:"herero",
       label:"Herero"
    },
    {
       value:"hindi",
       label:"Hindi"
    },
    {
       value:"hiri motu",
       label:"Hiri Motu"
    },
    {
       value:"hungarian",
       label:"Hungarian"
    },
    {
       value:"interlingua",
       label:"Interlingua"
    },
    {
       value:"indonesian",
       label:"Indonesian"
    },
    {
       value:"interlingue",
       label:"Interlingue"
    },
    {
       value:"irish",
       label:"Irish"
    },
    {
       value:"igbo",
       label:"Igbo"
    },
    {
       value:"inupiaq",
       label:"Inupiaq"
    },
    {
       value:"ido",
       label:"Ido"
    },
    {
       value:"icelandic",
       label:"Icelandic"
    },
    {
       value:"italian",
       label:"Italian"
    },
    {
       value:"inuktitut",
       label:"Inuktitut"
    },
    {
       value:"japanese",
       label:"Japanese"
    },
    {
       value:"javanese",
       label:"Javanese"
    },
    {
       value:"kalaallisut, greenlandic",
       label:"Kalaallisut, Greenlandic"
    },
    {
       value:"kannada",
       label:"Kannada"
    },
    {
       value:"kanuri",
       label:"Kanuri"
    },
    {
       value:"kashmiri",
       label:"Kashmiri"
    },
    {
       value:"kazakh",
       label:"Kazakh"
    },
    {
       value:"khmer",
       label:"Khmer"
    },
    {
       value:"kikuyu, gikuyu",
       label:"Kikuyu, Gikuyu"
    },
    {
       value:"kinyarwanda",
       label:"Kinyarwanda"
    },
    {
       value:"kirghiz, kyrgyz",
       label:"Kirghiz, Kyrgyz"
    },
    {
       value:"komi",
       label:"Komi"
    },
    {
       value:"kongo",
       label:"Kongo"
    },
    {
       value:"korean",
       label:"Korean"
    },
    {
       value:"kurdish",
       label:"Kurdish"
    },
    {
       value:"kwanyama, kuanyama",
       label:"Kwanyama, Kuanyama"
    },
    {
       value:"latin",
       label:"Latin"
    },
    {
       value:"luxembourgish, letzeburgesch",
       label:"Luxembourgish, Letzeburgesch"
    },
    {
       value:"luganda",
       label:"Luganda"
    },
    {
       value:"limburgish, limburgan, limburger",
       label:"Limburgish, Limburgan, Limburger"
    },
    {
       value:"lingala",
       label:"Lingala"
    },
    {
       value:"lao",
       label:"Lao"
    },
    {
       value:"lithuanian",
       label:"Lithuanian"
    },
    {
       value:"luba-katanga",
       label:"Luba-Katanga"
    },
    {
       value:"latvian",
       label:"Latvian"
    },
    {
       value:"manx",
       label:"Manx"
    },
    {
       value:"macedonian",
       label:"Macedonian"
    },
    {
       value:"malagasy",
       label:"Malagasy"
    },
    {
       value:"malay",
       label:"Malay"
    },
    {
       value:"malayalam",
       label:"Malayalam"
    },
    {
       value:"maltese",
       label:"Maltese"
    },
    {
       value:"māori",
       label:"Māori"
    },
    {
       value:"marathi (marāṭhī)",
       label:"Marathi (Marāṭhī)"
    },
    {
       value:"marshallese",
       label:"Marshallese"
    },
    {
       value:"mongolian",
       label:"Mongolian"
    },
    {
       value:"nauru",
       label:"Nauru"
    },
    {
       value:"navajo, navaho",
       label:"Navajo, Navaho"
    },
    {
       value:"norwegian bokmål",
       label:"Norwegian Bokmål"
    },
    {
       value:"north ndebele",
       label:"North Ndebele"
    },
    {
       value:"nepali",
       label:"Nepali"
    },
    {
       value:"ndonga",
       label:"Ndonga"
    },
    {
       value:"norwegian nynorsk",
       label:"Norwegian Nynorsk"
    },
    {
       value:"norwegian",
       label:"Norwegian"
    },
    {
       value:"nuosu",
       label:"Nuosu"
    },
    {
       value:"south ndebele",
       label:"South Ndebele"
    },
    {
       value:"occitan",
       label:"Occitan"
    },
    {
       value:"ojibwe, ojibwa",
       label:"Ojibwe, Ojibwa"
    },
    {
       value:"old church slavonic, church slavic, church slavonic, old bulgarian, old slavonic",
       label:"Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic"
    },
    {
       value:"oromo",
       label:"Oromo"
    },
    {
       value:"oriya",
       label:"Oriya"
    },
    {
       value:"ossetian, ossetic",
       label:"Ossetian, Ossetic"
    },
    {
       value:"panjabi, punjabi",
       label:"Panjabi, Punjabi"
    },
    {
       value:"pāli",
       label:"Pāli"
    },
    {
       value:"persian",
       label:"Persian"
    },
    {
       value:"polish",
       label:"Polish"
    },
    {
       value:"pashto, pushto",
       label:"Pashto, Pushto"
    },
    {
       value:"portuguese",
       label:"Portuguese"
    },
    {
       value:"quechua",
       label:"Quechua"
    },
    {
       value:"romansh",
       label:"Romansh"
    },
    {
       value:"kirundi",
       label:"Kirundi"
    },
    {
       value:"romanian, moldavian, moldovan",
       label:"Romanian, Moldavian, Moldovan"
    },
    {
       value:"russian",
       label:"Russian"
    },
    {
       value:"sanskrit (saṁskṛta)",
       label:"Sanskrit (Saṁskṛta)"
    },
    {
       value:"sardinian",
       label:"Sardinian"
    },
    {
       value:"sindhi",
       label:"Sindhi"
    },
    {
       value:"northern sami",
       label:"Northern Sami"
    },
    {
       value:"samoan",
       label:"Samoan"
    },
    {
       value:"sango",
       label:"Sango"
    },
    {
       value:"serbian",
       label:"Serbian"
    },
    {
       value:"scottish gaelic; gaelic",
       label:"Scottish Gaelic; Gaelic"
    },
    {
       value:"shona",
       label:"Shona"
    },
    {
       value:"sinhala, sinhalese",
       label:"Sinhala, Sinhalese"
    },
    {
       value:"slovak",
       label:"Slovak"
    },
    {
       value:"slovene",
       label:"Slovene"
    },
    {
       value:"somali",
       label:"Somali"
    },
    {
       value:"southern sotho",
       label:"Southern Sotho"
    },
    {
       value:"spanish; castilian",
       label:"Spanish; Castilian"
    },
    {
       value:"sundanese",
       label:"Sundanese"
    },
    {
       value:"swahili",
       label:"Swahili"
    },
    {
       value:"swati",
       label:"Swati"
    },
    {
       value:"swedish",
       label:"Swedish"
    },
    {
       value:"tamil",
       label:"Tamil"
    },
    {
       value:"telugu",
       label:"Telugu"
    },
    {
       value:"tajik",
       label:"Tajik"
    },
    {
       value:"thai",
       label:"Thai"
    },
    {
       value:"tigrinya",
       label:"Tigrinya"
    },
    {
       value:"tibetan standard, tibetan, central",
       label:"Tibetan Standard, Tibetan, Central"
    },
    {
       value:"turkmen",
       label:"Turkmen"
    },
    {
       value:"tagalog",
       label:"Tagalog"
    },
    {
       value:"tswana",
       label:"Tswana"
    },
    {
       value:"tonga (tonga islands)",
       label:"Tonga (Tonga Islands)"
    },
    {
       value:"turkish",
       label:"Turkish"
    },
    {
       value:"tsonga",
       label:"Tsonga"
    },
    {
       value:"tatar",
       label:"Tatar"
    },
    {
       value:"twi",
       label:"Twi"
    },
    {
       value:"tahitian",
       label:"Tahitian"
    },
    {
       value:"uighur, uyghur",
       label:"Uighur, Uyghur"
    },
    {
       value:"ukrainian",
       label:"Ukrainian"
    },
    {
       value:"urdu",
       label:"Urdu"
    },
    {
       value:"uzbek",
       label:"Uzbek"
    },
    {
       value:"venda",
       label:"Venda"
    },
    {
       value:"vietnamese",
       label:"Vietnamese"
    },
    {
       value:"volapük",
       label:"Volapük"
    },
    {
       value:"walloon",
       label:"Walloon"
    },
    {
       value:"welsh",
       label:"Welsh"
    },
    {
       value:"wolof",
       label:"Wolof"
    },
    {
       value:"western frisian",
       label:"Western Frisian"
    },
    {
       value:"xhosa",
       label:"Xhosa"
    },
    {
       value:"yiddish",
       label:"Yiddish"
    },
    {
       value:"yoruba",
       label:"Yoruba"
    },
    {
       value:"zhuang, chuang",
       label:"Zhuang, Chuang"
    }
 ]