import { auth, googleAuth, facebookAuth } from '../config/firebase';
import { signInWithPopup, signInWithEmailAndPassword, fetchSignInMethodsForEmail, linkWithCredential, OAuthProvider, getAuth } from 'firebase/auth';
import Swal from "sweetalert2";
import { Navigate, redirect } from 'react-router-dom';
// import router from "@router/index";


export const signInWithGoogle = (callback, setError) => {
  signInWithPopup(auth, googleAuth)
    .then(result => {
      var user = result.user;
      console.log("signInWithGoogle", user)
      callback && callback(user);
    })
    .catch(function (error) {
      // Handle Errors here.

      console.error("signInWithGoogle ERROR", error)
      handleAccountExistWithDiffCred(error, setError)
    });
};

export const signInWithFacebook = (callback, setError) => {
  console.log("getting into facebook");
  signInWithPopup(auth, facebookAuth)
    .then(function (result) {
      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      //var token = result.credential.accessToken;
      // The signed-in user info.
      var user = result.user;
      console.log("signInWithFacebook", user)
      callback && callback(user);
      // ...
    }).catch(function (error) {
      // Handle Errors here.
      // var errorCode = error.code;
      // var errorMessage = error.message;
      // // The email of the user's account used.
      // var email = error.email;
      // // The firebase.auth.AuthCredential type that was used.
      // var credential = error.credential;
      // ...
      console.error("signInWithFacebook ERROR", error)
      handleAccountExistWithDiffCred(error, setError)
    });
};

const getProviderForProviderId = (providerStr) => {
  if (providerStr.toLowerCase().includes("facebook")) {
    return facebookAuth
  } else if (providerStr.toLowerCase().includes("google")) {
    return googleAuth
  }
}

export const login = (loginType, payload, saveUserInAppState, setError) => {
  console.log('authenication - login:', loginType)
  try {
    if (loginType === 'email') {
      return signInWithEmailAndPasswordHandler(payload.email, payload.password,
        saveUserInAppState, setError)
    } else if (loginType === 'facebook') {
      return signInWithFacebook(saveUserInAppState, setError)
    } else if (loginType === 'google') {
      return signInWithGoogle(saveUserInAppState, setError)
    }
  } catch (error) {
    return {
      status: 'error',
      error: error.message
    }
  }
}

export const signInWithEmailAndPasswordHandler = (email, password, saveUserInAppState, setError) => {
  console.log("signInWithEmailAndPassword - input ", email, password)
  // event.preventDefault();
  signInWithEmailAndPassword(auth, email, password)
    .then(userCredential => {

      console.log("signInWithEmailAndPassword - authenticated user: ", userCredential.user)

      saveUserInAppState(userCredential.user)
      console.log("signInWithEmailAndPassword - navigating to dashboard...")
      redirect("/dashboard")


    })
    .catch(error => {
      console.error("signInWithEmailAndPassword - Error signing in with password and email", error);
      handleAccountExistWithDiffCred(error, setError)
      setError("You either have filled in wrong details or need to register first.");

    });
};


export const handleAccountExistWithDiffCred = async (error, showErrorMessage) => {
  console.log("handling already exists account");

  if (error.code === 'auth/account-exists-with-different-credential') {
    // Step 2.
    // User's email already exists.
    // The pending Google credential.
    const credential = OAuthProvider.credentialFromError(error)
    var pendingCred = error.credential || credential;
    // The provider account's email address.
    var email = error.email || error?.customData.email;
    // Get sign-in methods for this email.
    fetchSignInMethodsForEmail(auth, email).then(function (methods) {

      console.log("handleAccountExistWithDiffCred - fetchSignInMethodsForEmail ", methods)
      // Step 3.
      // If the user has several sign-in methods,
      // the first method in the list will be the "recommended" method to use.
      if (methods[0] === 'password') {
        // Asks the user their password.
        // In real scenario, you should handle this asynchronously.
        // showErrorMessage("This email is already used with a user/password login.")
        Swal.fire({ title: "Failed!", text: "This email is already used with a user/password login", icon: "error", confirmButtonText: "OK" });
        return
        // var password = promptUserForPassword(); // TODO: implement promptUserForPassword.
        // auth.signInWithEmailAndPassword(email, password).then(function(result) {
        //   // Step 4a.
        //   return result.user.linkWithCredential(pendingCred);
        // }).then(function() {
        //   // Google account successfully linked to the existing Firebase user.
        //   goToApp();
        // });
        // return;
      }

      console.log("handleAccountExistWithDiffCred - getProviderForProviderId ", methods)
      // All the other cases are external providers.
      // Construct provider object for that provider.
      // TODO: implement getProviderForProviderId.
      const provider = getProviderForProviderId(methods[0]);
      // At this point, you should let the user know that they already has an account
      // but with a different provider, and let them validate the fact they want to
      // sign in with this provider.
      // Sign in to provider. Note: browsers usually block popup triggered asynchronously,
      // so in real scenario you should ask the user to click on a "continue" button
      // that will trigger the signInWithPopup.


      const callback = signInWithPopup(auth, provider).then(function (result) {
        // Remember that the user may have signed in with an account that has a different email
        // address than the first one. This can happen as Firebase doesn't control the provider's
        // sign in flow and the user is free to login using whichever account they own.
        // Step 4b.
        // Link to Google credential.
        // As we have access to the pending credential, we can directly call the link method.
        linkWithCredential(result.user, pendingCred).then(function (usercred) {
          // Google account successfully linked to the existing Firebase user.
          redirect("/dashboard")
        });
      }).catch(function (error) {
        console.log("error:", error);
        if (error.code === 'auth/popup-blocked') {
          Swal.fire({ title: "Failed!", text: "Please allow popup to proceed login", icon: "error", confirmButtonText: "OK" });
          return
        }
      })


      if (provider) {

        window.alert(
          `There's already a ${methods[0]} account associated with your email.`,
          [{ text: 'Continue', onPress: () => callback(provider) }],
          { cancelable: false }
        )
      }

    });
  } else  if (error.code === 'auth/popup-blocked') {
    Swal.fire({ title: "Failed!", text: "Please allow popup to proceed login", icon: "error", confirmButtonText: "OK" });
    return
  }
}

// const redirectLoginMethod = async (user, navigation) => {
//   let callback = null
//   switch (user.provider) {
//     case 'Facebook':
//       callback = signInWithFacebook
//       break
//     case 'Google':
//       callback = signInWithGoogle
//       break
//     default:
//       break
//   }
//   if (callback) {

//   }
// }