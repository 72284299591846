import { TourProvider, useTour } from "@reactour/tour";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as AdaLogo } from "../../../assets/images/ada-logo.svg";
import { ReactComponent as InboxIcon } from "../../../assets/images/inbox.svg";
import { ReactComponent as ChatpromptIcon } from "../../../assets/images/chat-prompt.svg";
import AdaBackgroundLandscape from "../../../assets/images/ada_bgr_landscape.jpeg";
import AdaBackgroundMobile from "../../../assets/images/ada_bgr_mobile.jpeg";
import learnWhite from "../../../assets/images/l3arn_logo.svg";
import { STRIPE_REG_STATUSES } from "../../../config/constants";
import { auth, firestore, setOffline } from "../../../config/firebase";
import CalendarRemindersActions from "../../../redux/calendarReminders";
import UnreadMessagesActions from "../../../redux/unreadMessages";
import NotificationsActions from "../../../redux/notifications";
import RemindersActions from "../../../redux/reminders";
import UserActions from "../../../redux/user";
import { fetchCurrentUser, updateUser } from "../../../services/userManagement";
import commonColor from "../../../theme/commonColor";
import { useWindowSize } from "../../../utils/uiUtils";
import { QuickModal } from "../modal/modal";
import SideMenu from "../sideMenu/sideMenu";
import { registerStyles } from "@emotion/utils";
import tourSteps from "../tourOverlay/tourSteps";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { signOut } from "firebase/auth";
import { doc, collection, query, onSnapshot, where } from "firebase/firestore";

import "./mainPageWithMenu.css";
import {
  Close,
  Navigation,
  handleOnClickMask,
} from "../tourOverlay/tourOverlayComponents";
import Swotbutton from "../buttons/swotbutton";
import { historyState } from "../../../services/navigation/history";
import {
  getUserSubscriptionStatus,
  subscriptionStatusCheck,
} from "../../../services/subscriptionManagement";

const MainPageWithMenu = (props) => {
  // todo

  const { user, menuSelected = "default" } = props;

  const [width, height] = useWindowSize();
  let navigate = useNavigate();
  let location = useLocation();
  const { hash, pathname, search } = location;

  const [logoutModal, setLogoutModal] = useState(false);
  const logoutModalRef = useRef();
  // const searchParams = new URLSearchParams(props?.location?.search);
  let [searchParams, _] = useSearchParams();
  let { stripe } = useParams();

  const { setIsOpen } = useTour();
  const [isShowingMore, setIsShowingMore] = useState(false);

  const [lockToSubscription, setLockToSubscription] = useState(false);

  const [localUnreadMessages, setLocalUnreadMessages] = useState([]);
  const [isTourOpen, setIsTourOpen] = useState(false);

  const userNotificationsRef = doc(
    collection(firestore, "user-notifications"),
    props.user.uid
  );

  const userRemindersRef = doc(
    collection(firestore, "user-reminders"),
    props.user.uid
  );

  const userCalendarRemindersRef = collection(
    doc(collection(firestore, "user-reminders"), props.user.uid),
    "calendar-events"
  );

  const userChatRef = query(
    query(
      collection(firestore, "chat-group"),
      where("members", "array-contains", props.user.uid),
      where("seen", "==", false)
    )
  );

  useEffect(() => {
    historyState.navigate = navigate;
  }, [navigate]);

  useEffect(() => {
    console.log("props.childern " + props.children?.length ?? 0);
  }, [props.children]);

  useEffect(() => {
    console.log("MainPageWithMenu - subscribing for notifications...");
    let unsubscribeNotifications = onSnapshot(
      userNotificationsRef,
      (snapshot) => {
        const updatedNotifications = snapshot.data();
        console.log("MainPageWithMenu - notif snapshot", updatedNotifications);
        props.saveNotifications(updatedNotifications);
      }
    );

    let unsubscribeReminders = onSnapshot(userRemindersRef, (snapshot) => {
      const updatedReminders = snapshot.data();
      console.log("MainPageWithMenu - reminders snapshot", updatedReminders);
      updatedReminders && props.saveReminders([updatedReminders]);
    });

    props.saveUnreadMessages([]);

    let unsubscribeChats = onSnapshot(userChatRef, (snapshot) => {
      var unreadMessages = localUnreadMessages;
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added" || change.type === "modified") {
          const chatGroup = change.doc.data();
          if (chatGroup?.recentMessage?.sentBy !== props.user.uid) {
            if (unreadMessages.length > 0) {
              const index = unreadMessages.findIndex(
                (item) => item.id === chatGroup.id
              );
              if (index > -1) {
                unreadMessages.splice(index, 1);
              }
            }
            unreadMessages.push(chatGroup);
          }
        }
      });

      props.saveUnreadMessages(unreadMessages);
      setLocalUnreadMessages(unreadMessages);
      console.log(
        "MainPageWithMenu - unread chat count: ",
        unreadMessages.length
      );
    });

    const dd = Math.round(new Date().getTime() / 1000);

    let unsubscribeCalendarReminders = onSnapshot(
      query(userCalendarRemindersRef, where("start", ">=", dd)),
      (snapshot) => {
        let updatedReminders = [];
        let d = snapshot.docs;
        snapshot.forEach((doc) => {
          updatedReminders.push(doc.data());
        });
        console.log(
          "MainPageWithMenu - calendar reminders snapshot",
          updatedReminders
        );
        props.saveCalendarReminders(updatedReminders);
      }
    );

    saveUserInAppState();

    return () => {
      console.log("MainPageWithMenu - unsubscribing from notifications.");
      unsubscribeNotifications();
      unsubscribeReminders();
      unsubscribeCalendarReminders();
      unsubscribeChats();
    };
  }, []);

  const saveUserInAppState = async () => {
    try {
      const userSnapshot = await fetchCurrentUser();
      const fetchedUser = userSnapshot.data();
      if (fetchedUser && props.saveUser) {
        props.saveUser({
          ...{ swotBuddySetup: props.user.swotBuddySetup },
          ...fetchedUser,
        });
      }
    } catch (e) {
      console.log("Error - ", e);
    }
  };

  useEffect(() => {
    console.log("screen size changed", width, height);
  }, [width, height]);

  useEffect(() => {
    if (logoutModal) {
      setTimeout(function () {
        if (logoutModalRef && logoutModalRef.current) {
          const wrapper = logoutModalRef.current;
          var cc = wrapper.className;
          cc = cc.replace(" logout-animation", "");
          wrapper.className = cc + " logout-animation";
        }
      }, 10);
    } else {
      if (logoutModalRef && logoutModalRef.current) {
        const wrapper = logoutModalRef.current;
        var cc = wrapper.className;
        wrapper.className = cc.replace(" logout-animation", "");
      }
    }

    // logout-animation
  }, [logoutModal]);

  const renderBackgroundStyle = () => {
    let style = {};
    if (
      props.user.accountType === "GuestTutor" ||
      props.user.accountType === "GuestStudent"
    ) {
      style.backgroundImage =
        width >= 1024
          ? `url(${AdaBackgroundLandscape})`
          : `url(${AdaBackgroundMobile})`;
      style.backgroundSize = "contain";
      style.backgroundPosition = "center";
      if (width >= 1024) {
        style.minHeight = "45rem";
      }
    } else {
      if (width >= 1536) {
        style.minHeight = "51rem";
      } else if (width >= 1024) {
        style.minHeight = "45rem";
      }
    }
    return style;
  };

  useEffect(() => {
    console.log("MainPageWithMenu -  notifications redux changed", props.state);
  }, [props.state]);

  const MsgWithProfileLink = (msg) => (
    <div>
      <Link to="/profile">{msg}</Link>
    </div>
  );

  useEffect(() => {
    // console.log(
    //   "MainPageWithMenu -  User's stripe status",
    //   props.user.stripeStatus
    // );
    if (
      props.user.accountType.toLowerCase() === "tutor" &&
      !props.user.ngoUser &&
      props.user.stripeStatus &&
      props.user.stripeStatus !== STRIPE_REG_STATUSES.APPROVED &&
      !searchParams.get("stripe")
    ) {
      if (
        (props.user.accountType === "Tutor" ||
          props.user.accountType === "GuestTutor") &&
        props.user.setupStatus
      ) {
        // const { type, msg } = subscriptionStatusCheck(props.user.stripeStatus);
        // toast(MsgWithProfileLink(msg), { type: toast.TYPE[type] });
      }
    }
  }, [props.user.stripeStatus]);

  useEffect(() => {
    if (user?.subcriptionStatus != null) {
      //if user is not in profile screen, navigate to profile screen
      if (
        user?.subcriptionStatus == false &&
        (user?.trial_remaining ?? 0) <= 0
      ) {
        setLockToSubscription(true);
        if (pathname != "profile") {
          navigate("/profile");
        }
      } else {
        setLockToSubscription(false);
      }

      console.log("User in useEffect", user);

      const { type, msg } = subscriptionStatusCheck(
        user?.subcriptionStatus,
        user?.trial,
        user?.trial_remaining,
        user?.subscription_remaining
      );
      if (msg) {
        toast(MsgWithProfileLink(msg), { type: toast.TYPE[type] });
      }
    }
  }, [user?.subcriptionStatus]);

  useEffect(() => {
    const checkStatus = async (userId, accountType, user) => {
      try {
        console.log('mainpage userId', userId, accountType)
        const result = await getUserSubscriptionStatus(userId, accountType);
        // console.log("Subscription -  useEffect - checking subscription status", result.data.data);
        if (result?.data) {
          if (result.data.status === "ERROR") {
            console.error(
              "Subscription -  useEffect - checking subscription status Error",
              result
            );
          } else {
            if (result.data.data) {
              // setStripeAccount(result.data.data);
              console.log('Result data in mainpage', result.data.data)

              const { status, subscription, trial, remaining, trial_remaining } = result.data.data;

              const { type, msg } = subscriptionStatusCheck(status, trial, trial_remaining, remaining)
              if (msg) {
                toast(MsgWithProfileLink(msg), { type: toast.TYPE[type] });
              }

              if (subscription != null) {
                // if (user.subcriptionStatus !== subscription) {
                var updatedUser = {
                  ...user,
                  uid: userId,
                  subcriptionStatus: subscription,
                  subscription_remaining: remaining ?? 0,
                  trial_remaining: trial_remaining ?? 0,
                  trial: trial ?? false,
                };

                //upate local user state
                props.saveUser(updatedUser);
                //update the firestore
                updateUser(updatedUser, true);
                // }
              }
            }
          }
        }
      } catch (e) { }
    };
    if ((user?.accountType, user?.uid) && user?.accountType !== undefined && user?.accountType !== null) {
      checkStatus(user?.uid, user?.accountType, user);
      // console.log("Subscription Countries Option", stripeCountries);
    }
  }, [user?.accountType]);

  const allowToNavigate = () => {
    if (
      (props.user.accountType === "Tutor" ||
        props.user.accountType === "GuestTutor") &&
      (!props.user.setupStatus || props.user.setupStatus == "pending")
    ) {
      console.log("Can't do that!"); // Print a message to console that the action is prohibited
      const type = toast.TYPE.ERROR;
      let msg =
        "Please complete the tutor setup before navigating to your profile.";
      if (props.user.setupStatus == "pending")
        msg = "Please wait for approval before navigating to your profile.";

      toast(msg, { type: toast.TYPE[type] }); // Displays an error toast on the page instead of navigating to "profile"
      return false;
    } else {
      return true;
    }
  };

  const renderLogo = () => {
    if (
      props.user.accountType === "GuestTutor" ||
      props.user.accountType === "GuestStudent"
    ) {
      return (
        <AdaLogo
          style={{
            width: width > 1024 ? "14rem" : "12rem",
            paddingRight: width > 1024 ? "6rem" : "2rem",
            paddingTop: "2rem",
          }}
          className="cursor-pointer"
          onClick={() => {
            navigate("/dashboard");
          }}
        />
      );
    }
  };

  const popoverWidget = () => (
    <div
      className="absolute w-full h-full rounded-2xl lg:rounded-3xl"
      style={{
        backgroundColor: "#ffffff",
        zIndex: !props.popoverContent ? 0 : 10,
        // left: !isOpen ? "1000px" : "0",
        // right: "0",
        // bottom: "0",
        // left: "0",
        opacity: !props.popoverContent ? "0" : "1",
        transition: "all .2s",
        visibility: !props.popoverContent ? "hidden" : "visible",
      }}
    >
      <div className="flex flex-col justify-start w-full h-full">
        {props.popoverContent}
      </div>
    </div>
  );

  return (
    <TourProvider
      steps={[]}
      components={{ Close, Navigation }}
      onClickMask={handleOnClickMask}
      afterOpen={(target) => {
        console.log("waseem: After open");
        setIsTourOpen(true);
      }}
      nextButton={({
        Button,
        currentStep,
        stepsLength,
        setIsOpen,
        setCurrentStep,
        steps,
      }) => {
        const last = currentStep === stepsLength - 1;
        return last ? (
          <Swotbutton
            onClick={() => {
              if (last) {
                setIsOpen(false);
              } else {
                setCurrentStep((s) => (s === steps?.length - 1 ? 0 : s + 1));
              }
            }}
            text={last ? "Done" : null}
          />
        ) : (
          <Button
            onClick={() => {
              if (last) {
                setIsOpen(false);
              } else {
                setCurrentStep((s) => (s === steps?.length - 1 ? 0 : s + 1));
              }
            }}
          >
            {last ? "Close!" : null}
          </Button>
        );
      }}
      beforeClose={(target) => {
        console.log("waseem: before close");
        setIsTourOpen(false);
      }}
    >
      <div
        // className="flex flex-col items-center w-full h-screen lg:flex-row h-screen-ios min-h-screen-ios"
        className="flex flex-col-reverse items-center w-full h-screen lg:flex-row min-h-screen-ios gradient-background"
        style={renderBackgroundStyle()}
      >
        <div
          // className="flex flex-col items-center justify-center flex-shrink-0 float-left px-4 pt-4 NavBar-width lg:h-full // lg:px-0"
          className="flex flex-col items-center justify-center flex-shrink-0 float-left px-4 pt-2 pb-4 NavBar-width NavBar-height lg:mb-0"
        >
          <SideMenu
            key={props.menuSelected == "library" ? Date() : props.menuSelected}
            user={props.user}
            selected={props.menuSelected}
            unreadChatCount={props.unreadMessages?.length ?? 0}
            isTourOpen={isTourOpen}
            lockToSubscription={lockToSubscription}
            logout={() => {
              setLogoutModal(true);
            }}
          />
        </div>

        {/* <div className="flex flex-col flex-grow h-full min-h-0 Contentview-width lg:pr-4"> */}
        <div className="flex flex-col min-h-0 Contentview-width lg:pr-4 Contentview-height">
          {/* <div className="flex items-center justify-between flex-shrink-0 w-full h-16 pt-4 pr-2"> */}
          <div
            style={
              width >= 1024 ? { paddingRight: "3.5rem" } : { paddingRight: "0" }
            }
            className="flex items-center justify-center pt-2 pb-4 lg:justify-between"
          >
            <div className="w-auto">
              <img
                src={learnWhite}
                alt="IQ2White"
                className="hidden h-8 mr-2 bg-transparent cursor-pointer md:h-10 lg:h-12 lg:block"
                onClick={() => {
                  navigate("/dashboard");
                }}
              />
            </div>
            <div
              style={{
                borderRadius: "1.5rem",
                overflow: "hidden",
              }}
              className=" Contentview-title-height flex flex-shrink-0 justify-center lg:justify-end items-center px-2 py-2 | menu_shadow bg-white ml-2 "
            >
              <div className="flex items-center px-4 text-sm text-white">
                <div
                  className="text-black multiline-elipsis-2"
                  style={{
                    wordBreak: "break-word",
                  }}
                >
                  <p className="text-sm">
                    <span className="font-semibold"> Hello</span>{" "}
                    {props.user.username ?? props.user.name}
                  </p>
                </div>
                {/* <span>
                &nbsp;(
                <span
                  onClick={() => {
                    setLogoutModal(true);
                  }}
                  style={{}}
                  className={"cursor-pointer text-red-500 font-bold text-base"}
                >
                  Logout
                </span>
                )
              </span> */}
              </div>

              {props.user.setupStatus === "rejected" ? (
                ""
              ) : (
                <div className="flex items-center">
                  <div className="flex items-center">
                    {props.menuSelected !== "inbox" && (
                      // <Swotbutton
                      //   text="inbox"
                      //   onClick={() => {
                      //     navigate("/profile/messages");
                      //   }}
                      // />
                      <div className="relative flex items-center justify-center w-10 h-10 cursor-pointer">
                        <InboxIcon
                          fill="rgb(26,93,119)"
                          stroke="white"
                          className="absolute w-8 h-8 mx-1 cursor-pointer md:mx-2"
                          onClick={() => {
                            if (allowToNavigate()) {
                              navigate("/profile/messages");
                            }
                          }}
                        />
                        {(props.unreadMessages?.length ?? 0) > 0 && (
                          <div
                            className="absolute top-0 right-0 w-3 h-3 rounded-full lg:w-4 lg:h-4"
                            style={{ backgroundColor: commonColor.deepPink }}
                          ></div>
                        )}
                      </div>
                    )}
                    {renderLogo()}
                  </div>

                  <div className="flex items-center">
                    <div className="relative flex items-center justify-center w-10 h-10 cursor-pointer">
                      <ChatpromptIcon
                        fill="rgb(26,93,119)"
                        stroke="white"
                        className="absolute w-8 h-8 mx-1 cursor-pointer md:mx-2"
                        onClick={() => {
                          if (allowToNavigate()) {
                            navigate("/chat-prompt");
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="relative w-full Contentview-content-height">
            <div
              className={`w-full h-full ${!props.disableScroll ? "overflow-y-scroll" : "overflow-y-hidden"
                } min-h-0 absolute`}
            >
              {props.children}
            </div>
            {popoverWidget()}
          </div>
        </div>
        <ToastContainer />
        <QuickModal
          isOpen={logoutModal}
          onRequestClose={() => {
            setLogoutModal(false);
          }}
          modalRef={logoutModalRef}
          isCloseButtonShown={false}
          title={"Logout"}
          message={"Are you sure you want to exit?"}
          firstButtonTitle={"Yes"}
          secondButtonTitle={"Cancel"}
          firstButtonClassName={
            "text-red-500 hover:text-red-600 border-red-500 hover:border-red-600 border p-2 rounded-lg focus:outline-none"
          }
          secondButtonClassName={
            "text-gray-700 hover:text-gray-800 border-gray-700 hover:border-gray-800 border p-2 rounded-lg focus:outline-none"
          }
          firstButtonStyle={{}}
          secondButtonStyle={{}}
          onFirstButtonClick={() => {
            setLogoutModal(false);
            props.saveUser(null);
            setOffline();
            signOut(auth);
            navigate("/", { repalce: true });
          }}
          onSecondButtonClick={() => {
            setLogoutModal(false);
          }}
        ></QuickModal>
      </div>
    </TourProvider>
  );
};

const mapStateToProps = (state) => ({
  notifications: state.notifications.notifications,
  reminders: state.reminders.reminders,
  unreadMessages: state.unreadMessages.unreadMessages,
});

const mapDispatchToProps = (dispatch) => ({
  saveNotifications: (notifications) =>
    dispatch(NotificationsActions.saveNotificationsRequest(notifications)),
  saveReminders: (reminders) =>
    dispatch(RemindersActions.saveRemindersRequest(reminders)),
  saveCalendarReminders: (reminders) =>
    dispatch(CalendarRemindersActions.saveCalendarRemindersRequest(reminders)),
  saveUnreadMessages: (unreadMessages) =>
    dispatch(UnreadMessagesActions.saveUnreadMessagesRequest(unreadMessages)),
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainPageWithMenu);
