import React from "react";
import UserModel from "../../../redux/redux-models/reduxUser";
import { IChatService } from "../../services/chatService";
import { ChatType, IChatGroup, IChatMessage } from "../../models/chat_models";
import Swotbutton from "../../../components/common/buttons/swotbutton";
import ChatUserWidget from "../ChatUserWidget";
import "./ActiveChatComponent.css";
import closeSrc from "../../../assets/images/close-icon.png";
import ChatMessageComponent from "./ChatMessageComponent";
import { ReactComponent as ImageIcon } from "../../../assets/images/image-icon.svg";
import Dropzone from "react-dropzone";
import imageCompression from "browser-image-compression";
import { readFile } from "../../../utils/fileUtils";
import Modal from "../../../components/common/modal/modal";
import LoaderModal from "../../../components/common/modal/loaderModal";
import ChatGroupWidget from "../ChatGroupWidget";

class ActiveChatComponent extends React.Component<{
  user: UserModel;
  chatGroup: IChatGroup;
  chatService: IChatService;
  chatMessages: IChatMessage[];
  onClose?: () => void;
  // onEdit: (content: IContentListEntry) => void;
}> {
  constructor(props: {
    user: UserModel;
    chatGroup: IChatGroup;
    chatService: IChatService;
    chatMessages: IChatMessage[];
    onClose?: () => void;
    // onEdit: (content: IContentListEntry) => void;
  }) {
    super(props);

    this.state = {
      chatGroup: props.chatGroup,
      isLoading: false,
      isDataUpdating: true,
      chatMessages: [],
      chatMessagesCount: 0,
      messageText: undefined,
      sendingMessage: false,
      imageOpened: undefined,
      uploadProgress: 0,
    };

    this.chatService = props.chatService;
    this.chatRef = React.createRef();
  }

  public state: {
    chatGroup: IChatGroup;
    isLoading: boolean;
    isDataUpdating: boolean;
    chatMessages: IChatMessage[];
    chatMessagesCount: number;
    messageText?: string;
    sendingMessage: boolean;
    imageOpened?: string;
    uploadProgress: number;
  };

  protected chatService: IChatService;
  protected chatRef?: React.RefObject<any>;

  public async componentDidMount(): Promise<void> {
    // this.subscribeFirebaseListeners();
  }

  componentWillUnmount(): void {
    //this.chatService.unSubscribeListeners();
  }

  componentDidUpdate(
    prevProps: Readonly<{
      user: UserModel;
      chatGroup: IChatGroup;
      chatService: IChatService;
      chatMessages: IChatMessage[];
      onClose?: (() => void) | undefined;
    }>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    if (this.state.chatMessagesCount !== this.props.chatMessages.length) {
      this.setState({ chatMessagesCount: this.props.chatMessages.length });
      this.scrollToBottom();
    }
  }

  handleSubmit = async (event) => {
    if (this.props.chatGroup.type == ChatType.GROUP) {
      this.sendGroupMessage();
    } else {
      this.sendPrivateMessage();
    }
  };

  handleChange = (event) => {
    this.setState({ messageText: event.target.value });
  };

  render() {
    return (
      <div className="w-full h-full relative">
        <div
          className="w-full chat-header-height flex justify-between items-center"
          style={{
            borderBottomStyle: "solid",
            borderBottomColor: "coral",
            borderBottomWidth: "1px",
          }}
        >
          {this.props.chatGroup.type == ChatType.GROUP ? (
            <ChatGroupWidget
              group={this.props.chatGroup}
              me={this.props.user}
            />
          ) : (
            this.props.chatGroup.otherUser && (
              <ChatUserWidget user={this.props.chatGroup.otherUser} />
            )
          )}
          {this.props.onClose && (
            <img
              src={closeSrc}
              className="w-8 ml-4 mr-2 cursor-pointer"
              alt="close"
              onClick={() => {
                this.props.onClose && this.props.onClose();
              }}
            />
          )}
        </div>

        <ul
          className="w-full chat-body-height flex flex-col overflow-y-scroll absolute pb-16 pl-2"
          id="chatList"
          ref={this.chatRef}
        >
          {this.props.chatMessages.map((data, index) => (
            <ChatMessageComponent
              key={index}
              message={data}
              user={this.props.user}
              otherUser={
                this.props.chatGroup.type === ChatType.PRIVATE
                  ? this.props.chatGroup.otherUser
                  : this.props.chatGroup.memberDetails
                  ? this.props.chatGroup.memberDetails![data.sentBy]
                  : undefined
              }
              openImage={(imageUrl) => {
                this.setState({ imageOpened: imageUrl });
              }}
            />
          ))}
        </ul>
        <div className="w-full absolute bottom-0 bg-white rounded-2xl">
          <div className="w-full">
            <div className="w-full flex flex-reverse items-center pl-2">
              <input
                className="textarea input flex-grow pl-2 min-h-10 md:min-h-12 mb-1 md:mb-2"
                type="text"
                placeholder="Enter your message..."
                onChange={this.handleChange}
                value={this.state.messageText}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.handleSubmit(e);
                  }
                }}
              />
              <div className="ml-4 mr-2 mb-2 flex items-center justify-center">
                {/* <ImageIcon
                  className="cursor-pointer h-8 mx-1"
                  onClick={() => {
                    this.sendImage();
                  }}
                /> */}
                {this.imagePickerWidget()}
                <div className="min-w-24">
                  <Swotbutton
                    text="Send"
                    loading={this.state.sendingMessage}
                    onClick={this.handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          isOpen={
            this.state.imageOpened !== undefined &&
            this.state.imageOpened !== null
          }
          onRequestClose={() => {
            this.setState({ imageOpened: undefined });
          }}
          setMaxSize={true}
          isCloseButtonShown={true}
        >
          <div className="lg:w-50v h-80v text-center flex flex-col overflow-y-scroll">
            <div className="mt-auto mb-auto">
              <img
                src={this.state.imageOpened}
                alt="opened"
                className="m-auto"
              />
            </div>
          </div>
        </Modal>
        <LoaderModal
          isOpen={this.state.uploadProgress > 0}
          message={`Uploading..${this.state.uploadProgress}%`}
          onRequestClose={null}
        />
      </div>
    );
  }

  sendPrivateMessage = async () => {
    const chatGroup = this.props.chatGroup;
    const messageText = this.state.messageText;
    if (
      chatGroup.otherUser?.uid &&
      messageText &&
      messageText.trim().length > 0
    ) {
      this.setState({ sendingMessage: true });
      // event.preventDefault();
      const response = await this.chatService.sendMessage(
        this.props.user.uid,
        chatGroup.otherUser?.uid,
        messageText,
        chatGroup.id
      );
      this.setState({ sendingMessage: false });
      if (response) {
        this.setState({ messageText: "" });
        // event.target.reset();
      }
    }
  };

  sendGroupMessage = async () => {
    const chatGroup = this.props.chatGroup;
    const messageText = this.state.messageText;
    if (messageText && messageText.trim().length > 0) {
      this.setState({ sendingMessage: true });
      // event.preventDefault();
      const response = await this.chatService.sendMessageToGroup(
        this.props.user.uid,
        chatGroup.id,
        messageText
      );
      this.setState({ sendingMessage: false });
      if (response) {
        this.setState({ messageText: "" });
        // event.target.reset();
      }
    }
  };

  scrollToBottom = () => {
    if (this.chatRef?.current) {
      this.chatRef.current.scrollTo({
        top: this.chatRef.current?.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  imagePickerWidget = () => {
    return (
      <div className="flex flex-col justify-center h-8 items-center">
        <Dropzone accept={["image/*"]} onDrop={this.handleProfilePhotoInput}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div
                className="flex flex-col items-center cursor-pointer focus:outline-none"
                {...getRootProps()}
              >
                <ImageIcon className="self-center cursor-pointer h-8 mx-1" />
                <input className="focus:outline-none" {...getInputProps()} />
                <div className="text-xs text-center w-full">
                  {/* {photoOK && "Done!"} */}
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    );
  };

  handleProfilePhotoInput = async (acceptedFiles) => {
    console.log("handleProfilePhotoInput - input files", acceptedFiles);
    let toBeUploaded = acceptedFiles[0];
    if (toBeUploaded && toBeUploaded.type.includes("image")) {
      //setPhotoError(false);

      console.log(
        "handleProfilePhotoInput - file is Photo, OK, will read file."
      );
      //compress profile if required
      const imgSize = toBeUploaded.size / 1024 / 1024;
      const maxSizeMB = 2;
      if (imgSize > maxSizeMB) {
        var options = {
          maxSizeMB: maxSizeMB,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(toBeUploaded, options);
        console.log(
          `compressed profileImage size = ${compressedFile.size / 1024 / 1024}`
        );
        toBeUploaded = compressedFile;
      }

      readFile(toBeUploaded, this.uploadPhoto);
    } else {
      // setPhotoError(true);
      // setPhotoOK(false);
      console.log("handleProfilePhotoInput - file is NOT Photo, ERROR");
    }
  };

  // TODO this is still using tutor endpoints, maybe rename them or separate them
  uploadPhoto = async (photo, file) => {
    // upload photo to firestore:
    if (this.props.chatGroup.type == ChatType.GROUP) {
      this.uploadGroupPhoto(photo, file);
    } else {
      this.uploadPrivatePhoto(photo, file);
    }
  };

  uploadPrivatePhoto = async (photo, file) => {
    // upload photo to firestore:

    const chatGroup = this.props.chatGroup;

    if (chatGroup.otherUser?.uid) {
      this.setState({ sendingMessage: true });
      const response = await this.chatService.sendImageMessage(
        this.props.user.uid,
        chatGroup.otherUser?.uid,
        photo,
        file,
        (progress) => {
          //Progress
          this.setState({ uploadProgress: progress });
        },
        chatGroup.id
      );

      this.setState({ uploadProgress: 0 });

      this.setState({ sendingMessage: false });
    }
  };

  uploadGroupPhoto = async (photo, file) => {
    // upload photo to firestore:
    const chatGroup = this.props.chatGroup;

    this.setState({ sendingMessage: true });
    const response = await this.chatService.sendImageMessageToGroup(
      this.props.user.uid,
      chatGroup.id,
      photo,
      file,
      (progress) => {
        //Progress
        this.setState({ uploadProgress: progress });
      }
    );

    this.setState({ uploadProgress: 0 });

    this.setState({ sendingMessage: false });
  };
}

export default ActiveChatComponent;
