import moment from "moment";
import React, { useState } from "react";
import Swotbutton from "../../components/common/buttons/swotbutton";
import commonColor from "../../theme/commonColor";
import MaskedField from "react-masked-field";
import { signupBeta } from "../../services/api";
import Swal from "sweetalert2";
import Modal from "../../components/common/modal/modal";

const BetaSignupModal = ({ isOpen, onRequestClose, showLoader }) => {
  const [birthday, setBirthday] = useState();
  const [isBirthdayValid, setIsBirthdayValid] = useState(false);
  const [country, setCountry] = useState();
  const [fullname, setFullname] = useState();
  const [email, setEmail] = useState();
  const [educationIssue, setEducationIssue] = useState();
  //   const [showFormModal, setShowFormModal] = useState(false);
  const [fullnameValidationError, setFullnameValidationError] = useState(null);
  const [loading, setLoading] = useState(false);

  //---Fullname validation----------//
  const isFullnameValid = () => {
    if (!fullname || fullname.length === 0) {
      return null;
    }
    var pattern = new RegExp(/^[a-zA-Z\s]*$/);
    if (fullname.length < 3 || !pattern.test(fullname)) {
      return false;
    }
    return true;
  };

  const validateFullname = () => {
    setFullnameValidationError(null);
    if (isFullnameValid() === false) {
      setFullnameValidationError("Please enter a valid name.");
    }
  };

  const renderFullnameBorderColor = () => {
    var validation = isFullnameValid();
    return !validation
      ? "#e2e8f0"
      : validation === false
      ? commonColor.error
      : commonColor.correct;
  };

  const handleFullnameChange = (event) => {
    setFullnameValidationError(null);
    const tempFullname = event.target.value;
    setFullname(tempFullname);
    // saveProperty("name", tempFullname);
  };

  //---------Email validations------------//
  const isEmailValid = () => {
    if (!email || email.length === null || email.length === undefined) {
      return null;
    }

    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    return pattern.test(email);
  };

  const validateEmail = () => {
    var validation = isEmailValid();
    // setEmailOk(false);
    console.log("validateEmail -  email", email);
    if (!validation) {
      // setEmailError(false);
      // setEmailExists(false);
      return;
    }
    if (validation === false) {
      // setEmailError(true);
    } else {
      // setEmailError(false);
      // auth.fetchSignInMethodsForEmail(email).then((providers) => {
      //   if (providers.length === 0) {
      //     setEmailExists(false);
      //     setEmailOk(true);
      //     // this email hasn't signed up yet
      //     saveProperty("email", email);
      //   } else {
      //     // has signed up
      //     setEmailExists(true);
      //   }
      // });
    }
  };

  const handleEmailChange = (event) => {
    console.log("handleEmailChange - ", event.target.value);

    const emailSoFar = event.target.value;
    if (!emailSoFar || emailSoFar.length === 0) {
      // setEmailError(false);
      // setEmailExists(false);
    }
    setEmail(emailSoFar);
  };

  const renderEmailBorderColor = () => {
    const validation = isEmailValid();
    if (validation === false) {
      return commonColor.error;
    } else if (validation === true) {
      return commonColor.correct;
    } else {
      return "#e2e8f0";
    }
  };

  const validateBirthday = (text) => {
    setIsBirthdayValid(false);
    console.log("handleInputChange  ", text);
    setBirthday(text);
    const proposedBirthday = moment(text, "MM/DD/YYYY");
    if (moment(text, "MM/DD/YYYY", true).isValid()) {
      // check date should be at most 18 years before now and at least 95 years before.

      let minAge, maxAge;
      minAge = moment().subtract(2, "years");
      maxAge = moment().subtract(70, "years");

      const isDateAcceptable =
        proposedBirthday.isSameOrBefore(minAge) &&
        proposedBirthday.isSameOrAfter(maxAge);
      console.log(
        "handleInputChange  ",
        isDateAcceptable,
        proposedBirthday,
        minAge,
        maxAge
      );
      setIsBirthdayValid(isDateAcceptable);
    } else {
      setIsBirthdayValid(false);
    }
  };

  const renderBirthdayBorderColor = () => {
    if (birthday && !isBirthdayValid) {
      return commonColor.error;
    } else if (birthday && isBirthdayValid) {
      return commonColor.correct;
    } else {
      return "#e2e8f0";
    }
  };

  //----Validation Country-------//
  const validateCountry = () => {
    // setFullnameValidationError(null);
    if (isvalidCountry()) {
      // setFullnameValidationError("Please enter a valid name.");
    }
  };

  const isvalidCountry = () => {
    return !country || country.trim().length === 0
      ? null
      : country.trim().length > 3;
  };
  const handleCountryChange = (event) => {
    // setFullnameValidationError(null);
    const tempCountryname = event.target.value;
    setCountry(tempCountryname);
    // saveProperty("name", tempFullname);
  };

  const renderCountryBorderColor = () => {
    var validation = isvalidCountry();
    return !(validation)
      ? "#e2e8f0"
      : validation === false
      ? commonColor.error
      : commonColor.correct;
  };

  //--------//

  const isDataValid = () => {
    return (
      isFullnameValid() && isEmailValid() && isBirthdayValid && isvalidCountry()
    );
  };

  const signupClicked = async () => {
    if (!isDataValid()) {
      return;
    }

    // setShowFormModal(false);

    let payload = {
      name: fullname,
      email: email,
      dob: birthday,
      country: country,
      education_issue: educationIssue ?? "",
    };

    // onSubmit && onSubmit(payload);
    onRequestClose && onRequestClose();
    showLoader && showLoader(true);
    const result = await signupBeta(payload);
    showLoader && showLoader(false);

    if (result && result.ok && result.data && result.data.status === "SUCCESS") {
      Swal.fire({
        title: "Success!",
        text:
          result.data.data?.message ??
          "You have been added as beta tester. You may have received an email.",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      Swal.fire({
        title: "Failed!",
        text:
          result.data?.errorMessage ?? "Failed to signup. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        onRequestClose && onRequestClose();
      }}
    >
      <div className="w-80v lg:w-70v xl:w-50v lg:p-12 xl:p-16 2k:p-20">
        <div className="w-full flex-col flex justify-start overflow-y-scroll">
          <div className="w-full flex justify-center mb-8">
            <p className="text-center italic">Tell us a little bit about yourself</p>
          </div>

          <div className="w-full flex flex-col justify-center flex-wrap">
            <div className="w-full flex flex-col items-start sm:flex-row sm:items-center justify-center">
              {/* <div className="text-sm lg:w-1/3 w-2/5 flex flex-row justify-end items-center"> */}
              <div className="text-sm w-32 md:w-40 xl:w-60 flex flex-row justify-start sm:justify-end items-center">
                Name
              </div>
              {/* <div className="lg:w-2/3 w-3/5 flex flex-row justify-start px-2 py-1"> */}
              <div className="w-full flex flex-row justify-start sm:px-2 py-1">
                <input
                  className="lg:w-4/5 w-full bg-white appearance-none border-2 focus:outline-none 
               focus:border-opacity-100 py-1 px-2"
                  style={{
                    borderColor: renderFullnameBorderColor(),
                  }}
                  id="name"
                  name="name"
                  type="text"
                  placeholder={"Enter your full name"}
                  value={fullname}
                  onBlur={validateFullname}
                  onChange={handleFullnameChange}
                />
              </div>
            </div>

            <div className="w-full flex flex-col items-start sm:flex-row sm:items-center justify-center">
              <div className="text-sm w-32 md:w-40 xl:w-60 flex flex-row justify-start sm:justify-end items-center">
                {/* <div className="text-sm lg:w-1/3 w-2/5 flex flex-row justify-end items-center"> */}
                Email
              </div>
              {/* <div className="lg:w-2/3 w-3/5 flex flex-row justify-start px-2 py-1"> */}
              <div className="w-full flex flex-row justify-start sm:px-2 py-1">
                <input
                  className="lg:w-4/5 w-full bg-white appearance-none border-2 focus:outline-none 
               focus:border-opacity-100 py-1 px-2"
                  style={{
                    borderColor: renderEmailBorderColor(),
                  }}
                  id="email"
                  name="email"
                  type={"email"}
                  placeholder={"Enter your email"}
                  value={email}
                  onBlur={validateEmail}
                  onChange={handleEmailChange}
                />
              </div>
            </div>

            <div className="w-full flex flex-col items-start sm:flex-row sm:items-center justify-center">
              {/* <div className="text-sm lg:w-1/3 w-2/5 flex flex-row justify-end items-center"> */}
              <div className="text-sm w-32 md:w-40 xl:w-60 flex flex-row justify-start sm:justify-end items-center">
                Date of Birth
              </div>
              {/* <div className="lg:w-2/3 w-3/5 flex flex-row justify-start px-2 py-1"> */}
              <div className="w-full flex flex-row justify-start sm:px-2 py-1">
                <MaskedField
                  className="border-2 py-1 px-2 lg:w-4/5 w-full"
                  placeholder={"mm/dd/yyyy"}
                  style={{
                    borderColor: renderBirthdayBorderColor(),
                  }}
                  mask="99/99/9999"
                  onComplete={(date) => {
                    validateBirthday(date);
                    // setBirthdayComplete(true);
                  }}
                  onChange={(event) => validateBirthday(event.target.value)}
                  value={birthday}
                />
              </div>
            </div>

            <div className="w-full flex flex-col items-start sm:flex-row sm:items-center justify-center">
              {/* <div className="text-sm lg:w-1/3 w-2/5 flex flex-row justify-end items-center"> */}
              <div className="text-sm w-32 md:w-40 xl:w-60 flex flex-row justify-start sm:justify-end items-center">
                Country
              </div>
              {/* <div className="lg:w-2/3 w-3/5 flex flex-row justify-start px-2 py-1"> */}
              <div className="w-full flex flex-row justify-start sm:px-2 py-1">
                <input
                  className="lg:w-4/5 w-full bg-white appearance-none border-2 focus:outline-none 
               focus:border-opacity-100 py-1 px-2"
                  style={{
                    borderColor: renderCountryBorderColor(),
                  }}
                  id="country"
                  name="country"
                  type="text"
                  placeholder={"Enter your country"}
                  value={country}
                  onBlur={validateCountry}
                  onChange={handleCountryChange}
                />
              </div>
            </div>

            <div className="w-full flex flex-col items-start sm:flex-row sm:items-center justify-center">
              <div className="w-32 md:w-40 xl:w-60"></div>
              <div className="w-full flex flex-col justify-start sm:px-2 py-1">
                <div className="w-full lg:w-4/5 text-sm flex flex-row justify-start items-center py-1 px-2">
                  What is your biggest issue you would like to solve in
                  education?
                </div>
                <textarea
                  rows={4}
                  className="w-full lg:w-4/5 bg-white appearance-none border-2 focus:outline-none focus:border-opacity-100 py-1 px-2 rounded-lg"
                  placeholder={
                    "Please share your thoughts with us"
                  }
                  onChange={(event) => {
                    setEducationIssue(event.target.value);
                  }}
                />
              </div>
            </div>

            <div className="w-full flex flex-row justify-center items-center">
              <Swotbutton
                text="Sign Up"
                disabled={!isDataValid()}
                onClick={() => {
                  signupClicked();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BetaSignupModal;
