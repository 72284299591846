import { useSpring, animated, easings } from "@react-spring/web";
import RenderContentfulDocument from "../../../components/common/contentful/renderDocument";

function Popup({ image, title, text, isRichContent, onClose }) {
  const backdrop = useSpring({
    from: { opacity: 0 },
    to: {
      opacity: 1,
      config: {
        easing: easings.easeInOutBack,
        duration: 1000,
      },
    },
  });

  const modal = useSpring({
    from: { opacity: 0, scale: 0.8, y: "50px" },
    to: { opacity: 1, scale: 1, y: "0px", config: { duration: 500 } },
  });

  return (
    <animated.div
      className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[999]"
      style={backdrop}
      onClick={onClose}
    >
      <animated.div
        style={modal}
        className="bg-white rounded-lg shadow-lg flex flex-col w-11/12 lg:w-7/12 2k:w-8/12 h-[85vh] md:h-[95vh]"
      >
        <div className="flex items-center justify-center mb-4 h-3/6 p-4 pt-6 ">
          <img
            src={image}
            alt={image}
            className="h-full w-auto object-cover object-top shadow-xl rounded-xl drop-shadow-md"
          />
        </div>

        <div className="max-w-full h-auto max-h-3/6 flex flex-col items-center justify-start py-6 px-14 overflow-y-scroll">
          <div className="text-2xl font-bold text-gray-600 text-center mb-6 mt-2">
            {title}
          </div>
          <p className="text-base md:text-lg text-gray-500 text-center px-2">
            {isRichContent ? RenderContentfulDocument(text) : text}
          </p>
        </div>
      </animated.div>
    </animated.div>
  );
}

export default Popup;
