import React from "react";
import ReactModal from "react-modal";
import { FadeLoader } from "react-spinners";

const LoaderModal = ({
  isOpen,
  message,
  onRequestClose,
  size = 12,
  backgroundColor = "rgba(0, 0, 0, 0.3)",
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          backgroundColor: backgroundColor,
        },
        content: {
          border: "0px",
          background: "rgba(0, 0, 0, 0)",
        },
      }}
    >
      <div className="flex justify-center items-center w-full h-full">
        <div className="flex flex-col items-center">
          {message && (
            <div className="text-white text-lg font-semibold">{message}</div>
          )}
          <FadeLoader loading={true} size={size} />
        </div>
      </div>
    </ReactModal>
  );
};
export default LoaderModal;
