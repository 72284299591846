import React from "react";
import { NavigateFunction, useNavigate, Navigate } from "react-router-dom";
import ContentListComponent from "./components/ContentListComponent";
import {
  ContentService,
  IContentListEntry,
  IContentService,
} from "./services/ContentService";
import UserActions from "../redux/user";

import "./H5pApp.css";
import MainPageWithMenu from "../components/common/MainPageTemplate/mainPageWithMenu";
import { connect, ConnectedProps } from "react-redux";
import { ReduxState } from "../redux/redux-models/redux_state";
import UserModel from "../redux/redux-models/reduxUser";
import CreateContentComponent from "./components/CreateContentComponent";
import { ACCOUNT_TYPES } from "../config/constants";
import Swotbutton from "../components/common/buttons/swotbutton";
import commonColor from "../theme/commonColor";
import H5pDetail from "./h5pDetail";
import { historyState } from "../services/navigation/history";
interface Props extends PropsFromRedux {
  navigate: NavigateFunction;
}
class H5pApp extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    // this.contentService = new ContentService("https://h5p-content-server.herokuapp.com/h5p");
    this.contentService = new ContentService("");
    this.state = {
      componentListKey: Date.now(),
      courses: [],
      popoverWidget: null,
      notAuthorized:
        this.props.user.accountType?.toLowerCase() !==
        ACCOUNT_TYPES.TUTOR.toLowerCase(),
    };

    if (this.state.notAuthorized) {
      if (props.navigate) {
        props.navigate("/dashboard");
        // Navigate({ to: "/dashboard" });
      } else if (historyState.navigate) {
        (historyState.navigate as unknown as NavigateFunction)("/dashboard");
      }
    } 
  }

  public state: {
    componentListKey: number;
    courses: [];
    popoverWidget: any;
    notAuthorized: boolean;
  };

  private contentService: IContentService;

  public async componentDidMount(): Promise<void> {}

  render() {
    return this.state.notAuthorized ? null : (
      <MainPageWithMenu
        user={this.props.user}
        menuSelected={"h5p"}
        popoverContent={this.state.popoverWidget}
        closePopover={() => {
          this.closePopover();
        }}
      >
        <div className="App h-full w-full flex flex-col justify-between overflow-y-scroll">
          <div className=" h-full w-full flex flex-wrap justify-center">
            {/* <div className="min-h-40 lg:h-9/10 w-11/12 lg:w-3/4 xl:w-1/2 p-2"> */}
            <div className="min-h-40 lg:h-9/10 w-11/12 lg:w-3/4 p-2">
              <div className="container w-full h-full bg-white rounded-2xl lg:rounded-3xl flex flex-col overflow-y-scroll">
                <div className="w-full flex flex-row justify-between  items-center p-4">
                  <h1 className="">My Content</h1>
                  <Swotbutton
                    text="Create"
                    bgColor={commonColor.sourCherry}
                    onClick={() => {
                      this.createNewContent();
                    }}
                  />
                </div>
                <div className="flex flex-col overflow-y-scroll">
                  <ContentListComponent
                    onPlay={(content) => {
                      this.playContent(content);
                    }}
                    onEdit={(content) => {
                      this.editContent(content);
                    }}
                    key={this.state.componentListKey}
                    user={this.props.user}
                    contentService={this.contentService}
                  />
                </div>
              </div>
            </div>
            {/* <div className="min-h-40 lg:h-9/10 w-11/12 lg:w-3/4 xl:w-1/2 p-2">
              <div className="w-full h-full bg-white rounded-2xl lg:rounded-3xl flex flex-col overflow-y-scroll">
                <CreateContentComponent
                  userId={this.props.user.uid}
                  contentService={this.contentService}
                  onSaved={(newData) => {
                    this.setState({
                      componentListKey: Date.now(),
                    });
                  }}
                />
              </div>
            </div> */}
          </div>
        </div>
      </MainPageWithMenu>
    );
  }

  playContent(content: IContentListEntry) {
    this.showPopover(this.playContentWidget(content));
  }

  editContent(content: IContentListEntry) {
    this.showPopover(this.editContentWidget(content));
  }

  createNewContent() {
    this.showPopover(this.newContentWidget());
  }

  dismissNewContent() {
    this.closePopover();
  }

  newContentWidget() {
    return (
      <div className="w-full h-full bg-white rounded-2xl lg:rounded-3xl flex flex-col items-center overflow-y-scroll">
        <CreateContentComponent
          userId={this.props.user.uid}
          contentService={this.contentService}
          newContent={true}
          onSaved={(newData) => {
            this.setState({
              popoverWidget: null,
              componentListKey: Date.now(),
            });
          }}
          onClose={() => {
            this.dismissNewContent();
          }}
        />
      </div>
    );
  }

  playContentWidget(content: IContentListEntry) {
    return (
      <div className="w-full flex-col justify-start items-center">
        <div className="w-full p-2 flex justify-end">
          <Swotbutton
            text="close"
            onClick={() => {
              this.closePopover();
            }}
          />
        </div>
        <H5pDetail contentId={content.contentId} />
      </div>
    );
  }

  editContentWidget(content: IContentListEntry) {
    return (
      <div className="w-full h-full bg-white rounded-2xl lg:rounded-3xl flex flex-col items-center overflow-y-scroll">
        <CreateContentComponent
          userId={this.props.user.uid}
          contentService={this.contentService}
          newContent={false}
          contentEntry={content}
          onSaved={(newData) => {
            this.setState({
              popoverWidget: null,
              componentListKey: Date.now(),
            });
          }}
          onClose={() => {
            this.dismissNewContent();
          }}
        />
      </div>
    );
  }
  closePopover() {
    this.setState({ popoverWidget: null });
  }

  showPopover(child) {
    this.setState({ popoverWidget: child });
  }
}

function mapStateToProps(state: ReduxState): { user: UserModel } {
  return {
    user: state.user.user,
  };
}

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(H5pApp);
