import placeholderOne from "../assets/unrealshingPotential.png";
import { useState } from "react";
import { isMobileDevice } from "../../../utils/uiUtils";
import { useInView, animated } from "@react-spring/web";

const PartTwo = () => {
  const [ref1, ref1Springs] = useInView(
    () => ({
      from: { x: -100, opacity: 0 },
      to: {
        x: 0,
        opacity: 1,
        config: {
          duration: 500,
        },
      },
    }),
    {
      root: null,
      rootMargin: "-50% 0px",
      once: true,
    }
  );

  const [ref2, imageSprings] = useInView(
    () => ({
      from: {
        rotate: 0,
        config: { duration: 500 },
      },
      to: {
        rotate: -4,
        config: { duration: 500 },
      },
    }),
    {
      root: null,
      rootMargin: "-30% 0px",
      threshold: 0.2,
    }
  );

  const [ref3, ref3Spring] = useInView(
    () => ({
      from: { x: -100, opacity: 0 },
      to: {
        x: 0,
        opacity: 1,
        config: {
          duration: 500,
        },
      },
    }),
    {
      root: null,
      rootMargin: "-30% 0px",
      threshold: 0.2,
      once: true,
    }
  );

  return (
    <div
      id="educate"
      className="flex flex-col-reverse justify-center items-center gap-8 mb-20 lg:mb-40 mt-4 lg:mt-6 lg:flex-row md:justify-center md:items-center md:flex"
    >
      <animated.div ref={ref1} style={ref1Springs}>
        {" "}
        <div>
          <p className="text-3xl text-gray-600 mb-14 text-center">Educate</p>
        </div>
        <div
          ref={ref1}
          className="px-12 lg:px-40 lg:w-full flex flex-col-reverse gap-10 md:gap-0 md:flex-row items-center justify-center"
          style={ref1Springs}
        >
          <div className="w-10/12 md:max-w-fitContent">
            <animated.img
              ref={ref2}
              className="mr-4 w-auto lg:max-h-[25rem] md:max-h-[20rem] rounded-2xl object-cover"
              src={placeholderOne}
              alt="young_girl_with_two_kids_behind_her"
              style={imageSprings}
            />
          </div>

          <animated.div
            ref={ref3}
            className="w-fit ml-0 md:ml-6 mt-10 md:mt-0 flex items-center justify-center flex-col md:block " // Add ml-6 class for left margin
            style={ref3Spring}
          >
            <p className="text-lg md:text-xl lg:text-2xl text-[#F56110] mb-6 w-fit text-center md:text-left">
              Empower Your Teaching with l3arn
            </p>
            <p className="text-xl md:text-2xl lg:text-4xl text-gray-700 mb-5 w-fit text-center md:text-left">
              Where Expertise Meets Innovation
            </p>
            <p className="max-w-lg text-gray-600 lg:text-base text-sm pr-0 md:pr-8 text-center md:text-left">
              Discover how our AI-driven platform transforms the art of
              teaching. With l3arn, educators can effortlessly create,
              customize, and distribute engaging educational content. Our
              intuitive tools streamline lesson planning, allowing you to focus
              more on inspiring students and less on administrative tasks.
              <br />
              <br />
              Dive into a world where technology enhances learning, and every
              lesson is an opportunity to inspire and be inspired. Join us in
              shaping the future of education with smart solutions that make
              teaching not just effective, but extraordinary.
            </p>
          </animated.div>
        </div>
      </animated.div>
    </div>
  );
};

export default PartTwo;
