// Images
import testimonialImage from "../assets/tree.jpg";

// Carousels
import TestimonialCarousel from "../carousels/testimonialCarousel.js";

// Success Stories
const PartNine = () => {
  return (
    <div
      className="text-center w-11/12 lg:w-9/12 mx-auto mb-40"
      id="success_stories"
    >
      <div className="text-center w-full">
        {" "}
        <p className="text-3xl text-gray-600 mb-14">Experience</p>
      </div>

      <div className="flex flex-col lg:flex-row place-items-center place-content-center gap-20 md:gap-32">
        <div>
          <img
            className="w-[300px] h-[300px]  object-cover transform rotate-0 hover:rotate-2 transition-all duration-300"
            src={testimonialImage}
            alt="Testimonial_Image"
          />
        </div>
        {/* TestimonialCarousel */}
        <div>
          <TestimonialCarousel />
        </div>
      </div>
    </div>
  );
};

export default PartNine;
