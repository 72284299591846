
import { useContext, useEffect, useCallback, useState, useLayoutEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext, Location, NavigateFunction, Router  } from 'react-router-dom';
import { createBrowserHistory } from 'history';

// class HistoryModel {
//     navigate: NavigateFunction | null = null;
//     location: Location | null = null;
//     router: import("@remix-run/router").Router | null = null;
// };
// export const historyState: HistoryModel = new HistoryModel();

export const historyState = {
  navigate: null
  // location: null,
  // router: null
};

// export const browserHistory = createBrowserHistory();


// const CustomRouter = ({ history, ...props }) => {
//     const [state, setState] = useState({
//       action: history.action,
//       location: history.location
//     });
  
//     useLayoutEffect(() => history.listen(setState), [history]);
  
//     return (
//       <Router
//         {...props}
//         location={state.location}
//         navigationType={state.action}
//         navigator={history}
//       />
//     );
//   };

// /**
//  * Blocks all navigation attempts. This is useful for preventing the page from
//  * changing until some condition is met, like saving form data.
//  *
//  * @param  blocker
//  * @param  when
//  * @see https://reactrouter.com/api/useBlocker
//  */
// export function useBlocker( blocker, when = true ) {
// 	const { navigator } = useContext( NavigationContext );

// 	useEffect( () => {
// 		if ( ! when ) return;

// 		const unblock = navigator.block( ( tx ) => {
// 			const autoUnblockingTx = {
// 				...tx,
// 				retry() {
// 					// Automatically unblock the transition so it can play all the way
// 					// through before retrying it. TODO: Figure out how to re-enable
// 					// this block if the transition is cancelled for some reason.
// 					unblock();
// 					tx.retry();
// 				},
// 			};

// 			blocker( autoUnblockingTx );
// 		} );

// 		return unblock;
// 	}, [ navigator, blocker, when ] );
// }
// /**
//  * Prompts the user with an Alert before they leave the current screen.
//  *
//  * @param  message
//  * @param  when
//  */
// export function usePrompt( message, when = true ) {
// 	const blocker = useCallback(
// 		( tx ) => {
// 			// eslint-disable-next-line no-alert
// 			if ( window.confirm( message ) ) tx.retry();
// 		},
// 		[ message ]
// 	);

// 	useBlocker( blocker, when );
// }