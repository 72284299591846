import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import UserActions from "../../redux/user";
import MainPageWithMenu from "../../components/common/MainPageTemplate/mainPageWithMenu";
import TutorCalendar from "../../components/tutor/calendar";
import StudentCalendar from "../../components/student/calendar/calendar";
import StudentDashboard from "../../components/student/studentDashboard";
import FooterView from "../../components/common/footerView";

const Calendar = ({ user }) => {
  useEffect(() => {}, [user]);

  return (
    <MainPageWithMenu user={user} menuSelected={"calendar"}>
      {user.accountType === "Tutor" ? <TutorCalendar  fullScreen={true}/> : <StudentDashboard fullScreen={true}/>}
      <FooterView />
    </MainPageWithMenu>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
