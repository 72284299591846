import React from "react";

const FooterView = ({ textColorClass }) => {
  return (
    <div className="w-full flex flex-col justify-center items-center h-[30px] lg:h-[30px] mt-4">
      <div className={`${textColorClass ?? "text-white"} text-sm`}>{`Version ${process.env.REACT_APP_VERSION}`}</div>

    </div>
  );
};

export default FooterView;
