import React, { useState } from "react";

import commonColor from "../../theme/commonColor";
import valuesSrc from "../../assets/images/values.png";
import "./styles.css";
import LandingLayout from "../../components/landing/landingLayout";
import infoIcon from "../../assets/images/quizCards/info-icon.png";
import Slider from "react-slick";
import ReactCardFlip from "react-card-flip";
import useHightCalculated from "../../components/common/hooks/UseHeightCalculated";
import { useWindowSize } from "../../utils/uiUtils";

function importAll(r) {
  var cache = {};
  r.keys().map((item, index) => {
    cache[item.replace("./", "")] = r(item);
  });
  // r.keys().forEach((key) => (cache[key] = r(key)));
  return cache;
}
// Note from the docs -> Warning: The arguments passed to require.context must be literals!
const images = importAll(
  require.context("../../assets/images/team", false, /\.(png|jpe?g|svg)$/)
);

const TheTeam = () => {
  const [flippedIndex, setFlippedIndex] = useState(-1);
  const hightCalculated = useHightCalculated(calculateCardHeight);
  const [width, height] = useWindowSize();

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "green" }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    //</p>autoplay: true,
    arrows: true,
    slidesToShow: width <= 768 ? 1 : width <= 1536 ? 2 : 3,
    slidesToScroll: width <= 768 ? 1 : width <= 1536 ? 2 : 3,
  };

  const toggleFlip = (index) => {
    setFlippedIndex(index);
  };

  const carousalComponent = () => {
    return (
      <div className="w-full sm:pr-8 pl-8 pb-6">
        <Slider {...settings}>
          {content.map((props, key) =>
            renderContent(key, {
              ...{
                ...props,
                ...{ toggleFlip: toggleFlip },
              },
            })
          )}
        </Slider>
      </div>
    );
  };

  const renderContent = (
    key,
    { smartTitle, title, name, image, paragraph, toggleFlip }
  ) => {
    return (
      <div
        key={key}
        // className="flex flex-col w-full items-center  2k:w-1/4 md:w-1/2 lg:w-1/3 xl:w-1/4 max-w-3xl p-4 lg:p-8 xl:p-8 2xl:p-8 2k:p-12"
        className="flex flex-col items-center w-full md:w-1/2  2xl:w-1/3 2k:w-1/4 max-w-3xl p-4 lg:p-8 xl:p-8 2xl:p-8 2k:p-12"
        style={
          {
            // height: cardHeight,
          }
        }
      >
        {/* <ResultCard */}
        <CardView
          index={key}
          smartTitle={smartTitle}
          title={title}
          name={name}
          image={image}
          paragraph={paragraph}
          isFlipped={flippedIndex === key}
          toggleFlip={toggleFlip}
          hightCalculated={hightCalculated}
        />
      </div>
    );
  };

  return (
    <LandingLayout landingTitle="The Team">
      <div className="w-full flex flex-col items-center">
        <div className="w-full lg:w-11/12 2k:w-9/12 lg:mt-4 mt-2 max-w-screen-2k">
          <div className=" sm:pl-24 sm:pr-10 pl-1">
            <p className="whitespace-pre-line text-center hidden sm:block">
              We bring together a diverse team of individual learners. Some have
              learned science, law, engineering, cooking, teaching, programming,
              art, or a combination of these or bits and pieces of, well, almost
              anything you can think of! Together we can go far, far enough to
              change the future of education.
              <br />
              <br />
            </p>

            <p className="whitespace-pre-line text-center">
              "If you want to go fast, go alone. If you want to go far, go
              together" - African Proverb
            </p>
          </div>
          <div className="xl:mx-10 mx-6 mt-8 flex flex-wrap justify-around  max-w-screen-2k">
            {/* {content.map((props, key) => renderContent(key, { ...props }))} */}
            {carousalComponent()}
          </div>
        </div>
      </div>
    </LandingLayout>
  );
};

export default TheTeam;

const CardView = ({
  index,
  smartTitle,
  title,
  name,
  image,
  paragraph,
  isFlipped,
  toggleFlip,
  hightCalculated,
}) => {
  return (
    <div
      className="relative w-full flex justify-center"
      style={{
        height: hightCalculated + "px",
      }}
    >
      <div
        className="relative card w-full h-full flex flex-col"
        style={{
          maxWidth: "350px",
          borderRadius: "35px",
        }}
      >
        <div className="h-full w-full flex flex-col justify-start items-center">
          <div className="aspectratio" data-ratio="1:1">
            <div
              className="w-full h-full"
              style={
                {
                  // borderRadius: "25px",
                }
              }
            >
              <div className="flex flex-col h-full w-full justify-center items-center">
                <img
                  src={image}
                  alt={title}
                  className={"h-full w-full object-cover"}
                  style={{}}
                />
              </div>
            </div>
          </div>

          <div className="flex">
            <span className="text-center align-text-top md:text-base text-sm text-gray-700 pt-3 px-3">
              {name}
            </span>
          </div>
          <div>
            <span className="text-center align-text-top md:text-base text-sm text-gray-500 px-3">
              {smartTitle}
            </span>
          </div>
          <div className="flex">
            <span className="text-center align-text-top md:text-base text-sm text-gray-700 px-3">
              {title}
            </span>
          </div>

          <div className="flex">
            <span className="text-center align-text-top md:text-base italic text-sm text-gray-700 p-3">
              {paragraph}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
const ResultCard = ({
  index,
  smartTitle,
  title,
  name,
  image,
  paragraph,
  isFlipped,
  toggleFlip,
  hightCalculated,
}) => {
  return (
    <>
      <ReactCardFlip
        isFlipped={isFlipped}
        flipDirection="horizontal"
        className="w-full h-full"
      >
        <div
          className="relative w-full flex justify-center"
          style={{
            height: hightCalculated + "px",
          }}
        >
          <div
            className="relative card w-full h-full flex flex-col"
            style={{
              maxWidth: "300px",
            }}
          >
            <div className="h-4/5 w-full flex flex-col justify-start items-center pt-4">
              <div>
                <span className="text-center align-text-top md:text-base text-sm text-gray-500">
                  {smartTitle}
                </span>
              </div>
              <div className="aspectratio" data-ratio="1:1">
                <div className="p-4">
                  <div
                    className="w-full h-full"
                    style={{
                      borderRadius: "17%",
                    }}
                  >
                    <div className="flex flex-col h-full w-full justify-center items-center">
                      <img
                        src={image}
                        alt={title}
                        className={"h-full w-full object-cover"}
                        style={{}}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex">
                <span className="text-center align-text-top md:text-base text-sm text-gray-700 pb-3">
                  {name}
                </span>
              </div>
              <div className="flex">
                <span className="text-center align-text-top md:text-base text-sm text-gray-700 pb-3">
                  {title}
                </span>
              </div>
            </div>

            <div className="absolute w-full bottom-0 flex flex-row justify-end">
              <button
                className="w-3/10"
                style={{
                  outline: "none",
                  boxShadow: "none",
                }}
                onClick={() => {
                  toggleFlip(index);
                }}
              >
                <img
                  src={infoIcon}
                  alt="InfoIcon"
                  className="object-contain w-full"
                />
              </button>
            </div>
          </div>
        </div>

        <div
          className="relative w-full flex justify-center"
          style={{
            height: hightCalculated + "px",
          }}
          onClick={() => {
            toggleFlip(-1);
          }}
        >
          <div
            className="h-full w-full card flex flex-col justify-center items-center"
            style={{
              maxWidth: "300px",
            }}
          >
            <div className="w-full h-full flex flex-col justify-start items-center p-4">
              <div className="w-full h-full flex justify-center items-center flex-col overflow-y-scroll">
                <span className="text-sm sm:text-base">{paragraph}</span>
              </div>
            </div>
          </div>
        </div>
      </ReactCardFlip>
    </>
  );
};

// const settings = {
//   dots: false,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 3,
//   slidesToScroll: 1,
//   //</p>autoplay: true,
//   arrows: true,
// };

const content = [
  {
    smartTitle: "The Boss",
    title: "Co Founder & COO",
    image: images["aletia.png"],
    name: "Aletia Trakakis",
    paragraph: `"It is not the years in your life that count - it is the life in your years" - Abraham Lincoln`,
  },
  {
    smartTitle: "The Captain",
    title: "CMO",
    image: images["spila.png"],
    name: "Spiridoula Trakaki",
    paragraph: `"Perseverance is a virtue" - Japanese proverb`,
  },
  {
    smartTitle: "The Hitman",
    title: "Multimedia & QA Expert",
    image: images["tony.png"],
    name: "Antonio Demetriades",
    paragraph: `“Yesterday I was clever, so I wanted to change the world. Today I am wise, so I am changing myself.” - Rumi`,
  },
  {
    smartTitle: "The Magician",
    title: "UI Lead Developer",
    image: images["waseem.png"],
    name: "Waseem Ahmed",
    paragraph: `DREAM requires effortless Sleep and AIM requires sleepless efforts.... Sleep for Dream and wake up for AIM..... So wake up and pursue your AIM`,
  },
  {
    smartTitle: "The Performer",
    title: "Infrastructure Lead Developer",
    image: images["ali3.png"],
    name: "Chaudhary Asghar Ali",
    paragraph: `“What you seek is seeking you” - Rumi`,
  },
  {
    smartTitle: "The Cameleon",
    title: "Developer",
    image: images["G.png"],
    name: "Gerasimos Trakakis",
    paragraph: `“Not all those who wander are lost” - J.R.R. Tolkien`,
  },
  // {
  //   title: "The Gandalf",
  //   image: images['eric.png'],
  //   name: "Eric Georgiades",
  //   paragraph: ``,
  // },
];

const calculateCardHeight = (size) => {
  let w = size.width;
  let heightCalculated = 0;
  let minHeight = 500;
  if (window.innerWidth >= 2048) {
    // 2k
    heightCalculated = size.height * 0.7;
  } else if (window.innerWidth > 1680) {
    // 2.5 xl
    heightCalculated = size.height * 0.7;
  } else if (window.innerWidth >= 1536) {
    // 2xl
    heightCalculated = size.height * 0.7;
  } else if (window.innerWidth >= 1280) {
    // xl
    heightCalculated = size.height * 0.7;
  } else if (window.innerWidth >= 1024) {
    // lg
    heightCalculated = size.height * 0.7;
  } else if (window.innerWidth >= 768) {
    // md
    heightCalculated = size.height * 0.7;
  } else if (window.innerWidth >= 640) {
    // sm
    heightCalculated = size.height * 0.8;
  } else {
    w = 0;
    heightCalculated = size.height * 0.8;
    if (heightCalculated > size.width * 1.2) {
      heightCalculated = size.width * 1.2;
    }
    minHeight = 0;
  }
  return heightCalculated < minHeight
    ? minHeight
    : heightCalculated > 700
    ? 650
    : heightCalculated;
};
