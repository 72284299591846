import React from "react";
import { connect } from "react-redux";
import MainPageWithMenu from "../../components/common/MainPageTemplate/mainPageWithMenu";
import { useWindowSize } from "../../utils/uiUtils";
import FAQ from "../landing/faq";
import "./insideFAQ.css";

const FAQWithLoggedIn = ({ user }) => {

  const [width, height] = useWindowSize();

  return (
    <MainPageWithMenu user={user} menuSelected={"faqs"} disableScroll={window >= 1024}>
     <div className="w-full h-full flex flex-col justify-start items-center" style={{
       height: '100% !important'
     }}>
      <div className="w-full  Title-height flex justify-center items-center p-8">
        <span className="text-mg text-white">Frequently Asked Questions</span>
      </div>
       <div className="w-full Content-height flex flex-col justify-start"> 
      <FAQ userLoggedIn={true} />
       </div> 
       </div> 
    </MainPageWithMenu>
  );
};

const mapStateToProps = (state) => ({
    user: state.user.user,
  });
  
  const mapDispatchToProps = (dispatch) => ({ });
  
  export default connect(mapStateToProps, mapDispatchToProps)(FAQWithLoggedIn);
  
