export const usStates = [
  { id: 9, stateName: "Massachusetts" },
  { id: 41, stateName: "Mississippi" },
  { id: 15, stateName: "Maryland" },
  { id: 44, stateName: "New Hampshire" },
  { id: 12, stateName: "Michigan" },
  { id: 49, stateName: "Montana" },
  { id: 16, stateName: "Nevada" },
  { id: 18, stateName: "Missouri" },
  { id: 13, stateName: "Minnesota" },
  { id: 31, stateName: "Nebraska" },
  { id: 33, stateName: "New Mexico" },
  { id: 25, stateName: "Utah" },
  { id: 47, stateName: "South Dakota" },
  { id: 29, stateName: "Oklahoma" },
  { id: 19, stateName: "Ohio" },
  { id: 24, stateName: "Rhode Island" },
  { id: 34, stateName: "Alabama" },
  { id: 14, stateName: "Colorado" },
  { id: 50, stateName: "Delaware" },
  { id: 7, stateName: "Georgia" },
  { id: 32, stateName: "Hawaii" },
  { id: 2, stateName: "California" },
  { id: 1, stateName: "New York" },
  { id: 30, stateName: "Connecticut" },
  { id: 3, stateName: "Illinois" },
  { id: 20, stateName: "Indiana" },
  { id: 37, stateName: "Iowa" },
  { id: 28, stateName: "Kentucky" },
  { id: 27, stateName: "Louisiana" },
  { id: 36, stateName: "Kansas" },
  { id: 46, stateName: "Maine" },
  { id: 39, stateName: "Idaho" },
  { id: 45, stateName: "North Dakota" },
  { id: 35, stateName: "South Carolina" },
  { id: 26, stateName: "Tennessee" },
  { id: 17, stateName: "Oregon" },
  { id: 5, stateName: "Texas" },
  { id: 42, stateName: "New Jersey" },
  { id: 21, stateName: "North Carolina" },
  { id: 40, stateName: "Puerto Rico" },
  { id: 10, stateName: "Arizona" },
  { id: 8, stateName: "District of Columbia" },
  { id: 11, stateName: "Washington" },
  { id: 22, stateName: "Virginia" },
  { id: 51, stateName: "Vermont" },
  { id: 48, stateName: "West Virginia" },
  { id: 4, stateName: "Florida" },
  { id: 6, stateName: "Pennsylvania" },
  { id: 0, stateName: "default" },
  { id: 43, stateName: "Alaska" },
  { id: 23, stateName: "Wisconsin" },
  { id: 52, stateName: "Wyoming" },
  { id: 38, stateName: "Arkansas" },
  { id: 100, stateName: "NonUSA" },
];
