import React from 'react'
import { connect } from 'react-redux';

const TutorRejected = ({ user }) => {
  return (

    <div className=" bg-white w-full rounded-lg p-4 my-4 mx-2 h-full flex flex-col justify-center items-center">

      <div className="text-center">We are sorry to inform you that you have been rejected.</div>
      <div className="text-center">For more information please contact support@l3arn.org</div>
    </div>

  )
}

const mapStateToProps = (state) => ({
  user: state.user.user,

})

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(TutorRejected)
