import { ReactComponent as StyleLearning } from "../../assets/images/quizCards/Asset5.svg";

import { ReactComponent as Under6 } from "../../assets/images/ageGroup/under4.svg";
import { ReactComponent as Above6 } from "../../assets/images/ageGroup/6to14.svg";
import { ReactComponent as Over12 } from "../../assets/images/ageGroup/over14.svg";

import { ReactComponent as Under6_1_1 } from "../../assets/images/quizCards/Asset25.svg";
import { ReactComponent as Under6_1_2 } from "../../assets/images/quizCards/Asset26.svg";
import { ReactComponent as Under6_1_3 } from "../../assets/images/quizCards/Asset27.svg";
import { ReactComponent as Under6_1_4 } from "../../assets/images/quizCards/Asset28.svg";
import { ReactComponent as Under6_1_5 } from "../../assets/images/quizCards/Asset29.svg";
import { ReactComponent as Under6_1_6 } from "../../assets/images/quizCards/Asset30.svg";
import { ReactComponent as Under6_2_1 } from "../../assets/images/quizCards/Asset50.svg";
import { ReactComponent as Under6_2_2 } from "../../assets/images/quizCards/Asset51.svg";
import { ReactComponent as Under6_2_3 } from "../../assets/images/quizCards/Asset52.svg";
import { ReactComponent as Under6_2_4 } from "../../assets/images/quizCards/Asset53.svg";
import { ReactComponent as Under6_3_1 } from "../../assets/images/quizCards/Asset45.svg";
import { ReactComponent as Under6_3_2 } from "../../assets/images/quizCards/Asset46.svg";
import { ReactComponent as Under6_3_3 } from "../../assets/images/quizCards/Asset47.svg";
import { ReactComponent as Under6_3_4 } from "../../assets/images/quizCards/Asset48.svg";
import { ReactComponent as Under6_3_5 } from "../../assets/images/quizCards/Asset49.svg";

import { ReactComponent as Over12_1_1 } from "../../assets/images/quizCards/Asset4.svg";
import { ReactComponent as Over12_1_2 } from "../../assets/images/quizCards/Asset1.svg";
import { ReactComponent as Over12_1_3 } from "../../assets/images/quizCards/Asset10.svg";
import { ReactComponent as Over12_1_4 } from "../../assets/images/quizCards/Asset11.svg";
import { ReactComponent as Over12_1_5 } from "../../assets/images/quizCards/Asset12.svg";
import { ReactComponent as Over12_2_1 } from "../../assets/images/quizCards/Asset2.svg";
import { ReactComponent as Over12_2_2 } from "../../assets/images/quizCards/Asset3.svg";
import { ReactComponent as Over12_2_3 } from "../../assets/images/quizCards/Asset6.svg";
import { ReactComponent as Over12_2_4 } from "../../assets/images/quizCards/Asset23.svg";
import { ReactComponent as Over12_3_1 } from "../../assets/images/quizCards/Asset38.svg";
import { ReactComponent as Over12_3_2 } from "../../assets/images/quizCards/Asset37.svg";
import { ReactComponent as Over12_3_3 } from "../../assets/images/quizCards/Asset13.svg";
import { ReactComponent as Over12_3_4 } from "../../assets/images/quizCards/Asset14.svg";
import { ReactComponent as Over12_4_1 } from "../../assets/images/quizCards/Asset17.svg";
import { ReactComponent as Over12_4_2 } from "../../assets/images/quizCards/Asset18.svg";
import { ReactComponent as Over12_4_3 } from "../../assets/images/quizCards/Asset19.svg";
import { ReactComponent as Over12_4_4 } from "../../assets/images/quizCards/Asset20.svg";
import { ReactComponent as Over12_4_5 } from "../../assets/images/quizCards/Asset21.svg";
import { ReactComponent as Over12_4_6 } from "../../assets/images/quizCards/Asset22.svg";
import { ReactComponent as Over12_5_1 } from "../../assets/images/quizCards/Asset15.svg";
import { ReactComponent as Over12_5_2 } from "../../assets/images/quizCards/Asset16.svg";

import { ReactComponent as Above6_1_1 } from "../../assets/images/quizCards/Asset33.svg";
import { ReactComponent as Above6_1_2 } from "../../assets/images/quizCards/Asset31.svg";
import { ReactComponent as Above6_1_3 } from "../../assets/images/quizCards/Asset32.svg";
import { ReactComponent as Above6_2_1 } from "../../assets/images/quizCards/Asset34.svg";
import { ReactComponent as Above6_2_2 } from "../../assets/images/quizCards/Asset35.svg";
import { ReactComponent as Above6_2_3 } from "../../assets/images/quizCards/Asset36.svg";
import { ReactComponent as Above6_3_1 } from "../../assets/images/quizCards/Asset2.svg";
import { ReactComponent as Above6_3_2 } from "../../assets/images/quizCards/Asset3.svg";
import { ReactComponent as Above6_3_3 } from "../../assets/images/quizCards/Asset6.svg";
import { ReactComponent as Above6_3_4 } from "../../assets/images/quizCards/Asset23.svg";
import { ReactComponent as Above6_4_1 } from "../../assets/images/quizCards/Asset54.svg";
import { ReactComponent as Above6_4_2 } from "../../assets/images/quizCards/Asset55.svg";
import { ReactComponent as Above6_4_3 } from "../../assets/images/quizCards/Asset56.svg";
import { ReactComponent as Above6_4_4 } from "../../assets/images/quizCards/Asset57.svg";
import { ReactComponent as Above6_4_5 } from "../../assets/images/quizCards/Asset58.svg";
import { ReactComponent as Above6_5_1 } from "../../assets/images/quizCards/Asset39.svg";
import { ReactComponent as Above6_5_2 } from "../../assets/images/quizCards/Asset40.svg";
import { ReactComponent as Above6_5_3 } from "../../assets/images/quizCards/Asset41.svg";
import { ReactComponent as Above6_5_4 } from "../../assets/images/quizCards/Asset42.svg";
import { ReactComponent as Above6_5_5 } from "../../assets/images/quizCards/Asset43.svg";
import { ReactComponent as Above6_5_6 } from "../../assets/images/quizCards/Asset44.svg";

import { ReactComponent as PhysicalIcon } from "../../assets/images/quizCards/physical.svg";
import { ReactComponent as SocialIcon } from "../../assets/images/quizCards/social.svg";
import { ReactComponent as LunerIcon } from "../../assets/images/quizCards/luner.svg";
import { ReactComponent as NaturalisticIcon } from "../../assets/images/quizCards/naturalistic.svg";
import { ReactComponent as AudioIcon } from "../../assets/images/quizCards/audio.svg";
import { ReactComponent as VisualIcon } from "../../assets/images/quizCards/visual.svg";
import { ReactComponent as VerbalIcon } from "../../assets/images/quizCards/verbal.svg";
import { ReactComponent as LogicalIcon } from "../../assets/images/quizCards/logical.svg";

const CardSVG = {
  StyleLearning,
  Under6,
  Above6,
  Over12,
  Under6_1_1,
  Under6_1_2,
  Under6_1_3,
  Under6_1_4,
  Under6_1_5,
  Under6_1_6,
  Under6_2_1,
  Under6_2_2,
  Under6_2_3,
  Under6_2_4,
  Under6_3_1,
  Under6_3_2,
  Under6_3_3,
  Under6_3_4,
  Under6_3_5,
  Over12_1_1,
  Over12_1_2,
  Over12_1_3,
  Over12_1_4,
  Over12_1_5,
  Over12_2_1,
  Over12_2_2,
  Over12_2_3,
  Over12_2_4,
  Over12_3_1,
  Over12_3_2,
  Over12_3_3,
  Over12_3_4,
  Over12_4_1,
  Over12_4_2,
  Over12_4_3,
  Over12_4_4,
  Over12_4_5,
  Over12_4_6,
  Over12_5_1,
  Over12_5_2,
  Above6_1_1,
  Above6_1_2,
  Above6_1_3,
  Above6_2_1,
  Above6_2_2,
  Above6_2_3,
  Above6_3_1,
  Above6_3_2,
  Above6_3_3,
  Above6_3_4,
  Above6_4_1,
  Above6_4_2,
  Above6_4_3,
  Above6_4_4,
  Above6_4_5,
  Above6_5_1,
  Above6_5_2,
  Above6_5_3,
  Above6_5_4,
  Above6_5_5,
  Above6_5_6,
  AudioIcon,
  VisualIcon,
  VerbalIcon,
  LogicalIcon,
  PhysicalIcon,
  SocialIcon,
  LunerIcon,
  NaturalisticIcon,
};

const questionValues = (a, vi, ver, lg, p, s, lo, n) => {
  return {
    audio: a,
    visual: vi,
    verbal: ver,
    logical: lg,
    physical: p,
    social: s,
    loner: lo,
    naturalistic: n,
  };
};

const QuestionersAgeSelection = {
  key: "age_selection",
  title: ``,
  type: "select",
  questions: [
    {
      key: "under_6",
      title: "under 6",
      imageIcon: CardSVG.Under6,
    },
    {
      key: "above_6",
      title: "6 - 12",
      imageIcon: CardSVG.Above6,
    },
    {
      key: "over_12",
      title: "over 12",
      imageIcon: CardSVG.Over12,
    },
  ],
};

const questionaires_Under_6 = [
  {
    key: "under6_1",
    title:
      "What type of games does your child enjoy\nmost? You can choose as many as you like.",
    questions: [
      {
        key: "under6_1_1",
        title: "Lego, other construction\n toys, and jigsaw puzzles.",
        imageIcon: CardSVG.Under6_1_1,
        questionValue: questionValues(0, 4, 0, 0, 0, 0, 4, 0),
      },
      {
        key: "under6_1_2",
        title: "Sports or other physical\n activities.",
        imageIcon: CardSVG.Under6_1_2,
        questionValue: questionValues(0, 0, 0, 0, 4, 0, 0, 4),
      },
      {
        key: "under6_1_3",
        title: "Word games.",
        imageIcon: CardSVG.Under6_1_3,
        questionValue: questionValues(0, 0, 8, 0, 0, 0, 0, 0),
      },
      {
        key: "under6_1_4",
        title: "Games that involve\n outdoor activities.",
        imageIcon: CardSVG.Under6_1_4,
        questionValue: questionValues(0, 0, 0, 0, 0, 0, 0, 8),
      },
      {
        key: "under6_1_5",
        title:
          "Activities that involve\n interactions with others,\n such as fantasy games.",
        imageIcon: CardSVG.Under6_1_5,
        questionValue: questionValues(0, 0, 0, 0, 0, 8, 0, 0),
      },
      {
        key: "qunder6_1_6",
        title: "Activities that can be\n done alone.",
        imageIcon: CardSVG.Under6_1_6,
        questionValue: questionValues(0, 0, 0, 0, 0, 0, 8, 0),
      },
    ],
  },
  {
    key: "under6_2",
    title: "When is your child's \nbody language most expressive?",
    questions: [
      {
        key: "under6_2_1",
        title: "When he/she is trying to explain something.",
        imageIcon: CardSVG.Under6_2_1,
        questionValue: questionValues(0, 0, 0, 0, 4, 4, 0, 0),
      },
      {
        key: "under6_2_2",
        title: "During most conversations.",
        imageIcon: CardSVG.Under6_2_2,
        questionValue: questionValues(0, 0, 2.666, 0, 2.666, 2.666, 0, 0),
      },
      {
        key: "under6_2_3",
        title: "Sometimes when he/she is feeling at ease.",
        imageIcon: CardSVG.Under6_2_3,
        questionValue: questionValues(
          1.333,
          1.333,
          1.333,
          1.333,
          0,
          0,
          1.333,
          1.333
        ),
      },
      {
        key: "under6_2_4",
        title: "My child is not expressive.",
        imageIcon: CardSVG.Under6_2_4,
        questionValue: questionValues(0, 0, 0, 0, 0, 0, 4, 4),
      },
    ],
  },
  {
    key: "under6_3",
    title: "My child excels at the following:",
    questions: [
      {
        key: "under6_3_1",
        title: "Recognizing and\n remembering people.",
        imageIcon: CardSVG.Under6_3_1,
        questionValue: questionValues(0, 4, 0, 0, 0, 4, 0, 0),
      },
      {
        key: "under6_3_2",
        title: "Recognizing and recalling places and things.",
        imageIcon: CardSVG.Under6_3_2,
        questionValue: questionValues(0, 2.666, 0, 0, 2.666, 2.666, 0, 0),
      },
      {
        key: "under6_3_3",
        title: "Has an excellent memory for names, dates and trivia.",
        imageIcon: CardSVG.Under6_3_3,
        questionValue: questionValues(0, 0, 8, 0, 0, 0, 0, 0),
      },
      {
        key: "under6_3_4",
        title: "Understanding abstract things and their connections.",
        imageIcon: CardSVG.Under6_3_4,
        questionValue: questionValues(0, 0, 0, 8, 0, 0, 0, 0),
      },
      {
        key: "under6_3_5",
        title: "Music and rhythm.",
        imageIcon: CardSVG.Under6_3_5,
        questionValue: questionValues(8, 0, 0, 0, 0, 0, 0, 0),
      },
    ],
  },
];

const questionaires_Above_6 = [
  {
    key: "above6_1",
    title: "When do you take notes?",
    questions: [
      {
        key: "above6_1_1",
        title: "I usually take notes when an exam is approaching.",
        imageIcon: CardSVG.Above6_1_2,
        questionValue: questionValues(
          1.333,
          1.333,
          1.333,
          1.333,
          1.333,
          1.333,
          0,
          0
        ),
      },
      {
        key: "above6_1_2",
        title: "I never take notes in class, way to bored!",
        imageIcon: CardSVG.Above6_1_3,
        questionValue: questionValues(1.6, 0, 0, 1.6, 1.6, 0, 1.6, 1.6),
      },
      {
        key: "above6_1_3",
        title: "I take notes everywhere!",
        imageIcon: CardSVG.Above6_1_1,
        questionValue: questionValues(0, 4, 4, 0, 0, 0, 0, 0),
      },
    ],
  },
  {
    key: "above6_2",
    title: "Yaay! Finally, no homework or school!\nWhat are you going to do?",
    questions: [
      {
        key: "above6_2_1",
        title: "Go out or just hang out\n with my friends.",
        imageIcon: CardSVG.Above6_2_1,
        questionValue: questionValues(0, 2, 2, 0, 2, 2, 0, 0),
      },
      {
        key: "above6_2_2",
        title: "Stay home, in my room and just do my thing.",
        imageIcon: CardSVG.Above6_2_2,
        questionValue: questionValues(1.6, 0, 1.6, 1.6, 1.6, 0, 1.6, 0),
      },
      {
        key: "above6_2_3",
        title: "Spend time with my pets.",
        imageIcon: CardSVG.Above6_2_3,
        questionValue: questionValues(0, 0, 0, 0, 0, 0, 0, 0),
      },
    ],
  },
  {
    key: "above6_3",
    title: "If you could teach a class,\n how would you do it?",
    questions: [
      {
        key: "above6_3_1",
        title: "By using videos to keep students interested.",
        imageIcon: CardSVG.Above6_3_1,
        questionValue: questionValues(4, 0, 0, 0, 0, 0, 4, 0),
      },
      {
        key: "above6_3_2",
        title: "Using roleplaying to give a better understanding.",
        imageIcon: CardSVG.Above6_3_2,
        questionValue: questionValues(0, 0, 0, 4, 0, 4, 0, 0),
      },
      {
        key: "above6_3_3",
        title: "By playing games.",
        imageIcon: CardSVG.Above6_3_3,
        questionValue: questionValues(0, 2.666, 0, 2.666, 0, 0, 2.666, 0),
      },
      {
        key: "above6_3_4",
        title:
          "By reading to the class and answering any questions they might have.",
        imageIcon: CardSVG.Above6_3_4,
        questionValue: questionValues(0, 2.666, 2.666, 2.666, 0, 0, 0, 0),
      },
    ],
  },
  {
    key: "above6_4",
    title:
      "When do you find yourself paying attention\nin class, even if its just for a few minutes?",
    questions: [
      {
        key: "above6_4_1",
        title: "When our teacher uses images!",
        imageIcon: CardSVG.Above6_4_1,
        questionValue: questionValues(0, 2.666, 0, 2.666, 0, 0, 0, 2.666),
      },
      {
        key: "above6_4_2",
        title: "When our homework is announced.",
        imageIcon: CardSVG.Above6_4_2,
        questionValue: questionValues(0, 0, 2.666, 0, 0, 2.666, 2.666, 0),
      },
      {
        key: "above6_4_3",
        title: "When the teacher rhymes to remember important information.",
        imageIcon: CardSVG.Above6_4_3,
        questionValue: questionValues(4, 0, 4, 0, 0, 0, 0, 0),
      },
      {
        key: "above6_4_4",
        title: "Shares the information through a song.",
        imageIcon: CardSVG.Above6_4_4,
        questionValue: questionValues(8, 0, 0, 0, 0, 0, 0, 0),
      },
      {
        key: "above6_4_5",
        title:
          "When there are activities and movements to explain something new.",
        imageIcon: CardSVG.Above6_4_5,
        questionValue: questionValues(0, 0, 0, 0, 2.666, 2.666, 0, 2.666),
      },
    ],
  },
  {
    key: "above6_5",
    title: "It's class time.\nWhat do you usually do?",
    questions: [
      {
        key: "above6_5_1",
        title: "I notice the teachers outfit. - The Fashion Icon.",
        imageIcon: CardSVG.Above6_5_1,
        questionValue: questionValues(0, 4, 0, 0, 0, 4, 0, 0),
      },
      {
        key: "above6_5_2",
        title:
          "I notice the change in the teachers voice -The Sherlock Holmes student.",
        imageIcon: CardSVG.Above6_5_2,
        questionValue: questionValues(4, 0, 0, 0, 0, 4, 0, 0),
      },
      {
        key: "above6_5_3",
        title:
          "I notice the facts the\n teacher gives\n - The Prototype student.",
        imageIcon: CardSVG.Above6_5_3,
        questionValue: questionValues(0, 0, 4, 4, 0, 0, 0, 0),
      },
      {
        key: "above6_5_4",
        title: "Doodle in my notebook - \n The Piccaso.",
        imageIcon: CardSVG.Above6_5_4,
        questionValue: questionValues(0, 4, 0, 0, 0, 0, 4, 0),
      },
      {
        key: "above6_5_5",
        title: "Fall asleep -\nThe Nocturnal student.",
        imageIcon: CardSVG.Above6_5_5,
        questionValue: questionValues(0, 0, 0, 0, 2.666, 0, 2.666, 2.666),
      },
      {
        key: "above6_5_6",
        title: "Day dream\n - Luna Lovegood.",
        imageIcon: CardSVG.Above6_5_6,
        questionValue: questionValues(0, 0, 0, 0, 2.666, 0, 2.666, 2.666),
      },
    ],
  },
];

const questionaires_Over_12 = [
  {
    key: "over12_1",
    title: "What kind of student are you?",
    questions: [
      {
        key: "over12_1_1",
        title: "The kind that takes notes.",
        imageIcon: CardSVG.Over12_1_1,
        questionValue: questionValues(0, 0, 0, 0, 4, 0, 0, 4),
      },
      {
        key: "over12_1_2",
        title: "I can't concentrate for long periods!",
        imageIcon: CardSVG.Over12_1_2,
        questionValue: questionValues(0, 2.666, 0, 2.666, 0, 0, 2.666, 0),
      },
      {
        key: "over12_1_3",
        title: "The student that asks\n questions!",
        imageIcon: CardSVG.Over12_1_3,
        questionValue: questionValues(2, 0, 2, 2, 0, 2, 0, 0),
      },
      {
        key: "over12_1_4",
        title:
          "I cannot sit still in class. I am always moving, shaking my leg, waiting for recess.",
        imageIcon: CardSVG.Over12_1_4,
        questionValue: questionValues(0, 4, 0, 0, 0, 0, 4, 0),
      },
      {
        key: "over12_1_5",
        title: "Doodling most of the time or just daydreaming.",
        imageIcon: CardSVG.Over12_1_5,
        questionValue: questionValues(0, 0, 0, 0, 4, 0, 0, 4),
      },
    ],
  },
  {
    key: "over12_2",
    title: "If you could teach a class,\nhow would you do it?",
    questions: [
      {
        key: "over12_2_1",
        title: "By using videos to keep students interested.",
        imageIcon: CardSVG.Over12_2_1,
        questionValue: questionValues(2.666, 2.666, 0, 0, 0, 0, 2.666, 0),
      },
      {
        key: "over12_2_2",
        title: "Using roleplaying to give a better understanding.",
        imageIcon: CardSVG.Over12_2_2,
        questionValue: questionValues(0, 0, 0, 0, 4, 4, 0, 0),
      },
      {
        key: "over12_2_3",
        title: "By playing games.",
        imageIcon: CardSVG.Over12_2_3,
        questionValue: questionValues(0, 0, 0, 2.666, 2.666, 0, 0, 2.666),
      },
      {
        key: "over12_2_4",
        title:
          "By reading to the class and answering any questions they might have.",
        imageIcon: CardSVG.Over12_2_4,
        questionValue: questionValues(0, 0, 2.666, 0, 2.666, 0, 0, 2.666),
      },
    ],
  },
  {
    key: "over12_3",
    title: "It is the dreadful exam period!\n How do you study?",
    questions: [
      {
        key: "over12_3_1",
        title: "Get motivated and organize your study material in time.",
        imageIcon: CardSVG.Over12_3_1,
        questionValue: questionValues(0, 2, 2, 2, 2, 2, 0, 2),
      },
      {
        key: "over12_3_2",
        title:
          "I have a solid plan, I wait untill the very last minute untill anxiety kicks in and then study.",
        imageIcon: CardSVG.Over12_3_2,
        questionValue: questionValues(0, 0, 0, 0, 8, 0, 0, 0),
      },
      {
        key: "over12_3_3",
        title:
          "I can't study by myself! I usually study with my classmates or with my friends.",
        imageIcon: CardSVG.Over12_3_3,
        questionValue: questionValues(2.666, 0, 0, 0, 2.666, 2.666, 0, 0),
      },
      {
        key: "over12_3_4",
        title:
          "I study in isolation. I find it way to distractive when studying with others.",
        imageIcon: CardSVG.Over12_3_4,
        questionValue: questionValues(0, 2.666, 0, 2.666, 0, 0, 2.666, 0),
      },
    ],
  },
  {
    key: "over12_4",
    title: "What are your hobbies?",
    questions: [
      {
        key: "over12_4_1",
        title: "I love playing any strategy games.",
        imageIcon: CardSVG.Over12_4_1,
        questionValue: questionValues(0, 2.666, 0, 2.666, 0, 0, 2.666, 0),
      },
      {
        key: "over12_4_2",
        title: "Any outdoor activities.",
        imageIcon: CardSVG.Over12_4_2,
        questionValue: questionValues(0, 0, 0, 0, 4, 0, 0, 4),
      },
      {
        key: "over12_4_3",
        title: "Anything involving sports.",
        imageIcon: CardSVG.Over12_4_3,
        questionValue: questionValues(0, 0, 0, 0, 8, 0, 0, 0),
      },
      {
        key: "over12_4_4",
        title: "I love reading books.",
        imageIcon: CardSVG.Over12_4_4,
        questionValue: questionValues(0, 2, 2, 2, 0, 0, 2, 0),
      },
      {
        key: "over12_4_5",
        title: "Watching movies.",
        imageIcon: CardSVG.Over12_4_5,
        questionValue: questionValues(
          1.333,
          1.333,
          1.333,
          1.333,
          0,
          1.333,
          1.333,
          0
        ),
      },
      {
        key: "over12_4_6",
        title: "I usually just scroll through TikTok, Instagram, Facebook etc.",
        imageIcon: CardSVG.Over12_4_6,
        questionValue: questionValues(0, 0, 0, 0, 0, 8, 0, 0),
      },
    ],
  },
  {
    key: "over12_5",
    title: "Do you find it difficult\n to stay focused in class?",
    questions: [
      {
        key: "over12_5_1",
        title: "I barely made it through this quiz. What do you think?",
        imageIcon: CardSVG.Over12_5_1,
        questionValue: questionValues(0, 2.666, 0, 2.666, 0, 2.666, 0, 0),
      },
      {
        key: "over12_5_2",
        title: "Only when I find the class boring.",
        imageIcon: CardSVG.Over12_5_2,
        questionValue: questionValues(1.6, 0, 1.6, 0, 1.6, 0, 1.6, 1.6),
      },
    ],
  },
];

const ResultItems = {
  visual: {
    icon: VisualIcon,
    title: "The Visual Learner",
    message:
      "You tend to notice details that others don't seem to see at first glance. You will remember one's face instead of their name. You are known to be creative and a bit of a daydreamer. You express yourself through your creative ways. If you are someone, who is visually orientated, then you prefer using images and pictures when you learn. You like to organize the information, and you usually have good communication skills.",
  },
  audio: {
    icon: AudioIcon,
    title: "The Auditory Learner",
    message:
      "You are known for your sense of rhythm. You either sing, play an instrument, or recognize sounds of different instruments. When you memorize a number, you will say it loud and might even make a tune to remember it. You are known to have an excellent memory with spoken information. Over and above,  you have enhanced your listening skills. When it comes to oral presentations or exams, you thrive as you have a natural gift of changing your tone when needed and are good at telling stories!",
  },
  physical: {
    icon: PhysicalIcon,
    title: "The Physical Learner",
    message:
      "Your style is also referred to as the multisensory style, as your learning process begins from hearing or seeing something you have learned. It is then completed by you trying to do it on your own. You are dynamic and usually have difficulty coping in a traditional classroom environment. You find it difficult to concentrate for long periods. You thrive in any classes that require a practical skill set, including subjects with labs (i.e., scientific). You can be very athletic or like to dance, anything that keeps them moving!",
  },
  verbal: {
    icon: VerbalIcon,
    title: "The Verbal Learner",
    message:
      "You like both written and spoken words. You love languages and are fond of reading.  You easily express yourself both verbally and in the written word. You are known to be bookworms but also enjoy the sound of words, like in rhymes and tongue twisters. You take pride in having a rich vocabulary and try to learn new words regularly and often like to show off your verbal skills by incorporating these new words into your everyday dialog.",
  },
  logical: {
    icon: LogicalIcon,
    title: "The Logical Learner",
    message:
      "You have a specific way of dealing with problems, creating schedules, and sticking to a to-do list. You are a scientist by heart, as you can support your  opinion with analytical examples. People consider you very rational! You usually find it easy to follow the rules and protocol.  Not always, but most of the time, you enjoy working with numbers and are good at it with the ability to solve complex calculations. usually, you remember mathematical formulas, trigonometry and have the ability to do mathematics in your head. You might like strategy games such as chess, backgammon, or pc games such as Age of Empires.",
  },
  social: {
    icon: SocialIcon,
    title: "The Social Learner",
    message:
      "You are the person people come to when they need advice or just a good time, and you, in return, will listen, give advice when required, and usually enjoy this role that you have! You are a natural leader, with people attracted to your outgoing glamour personality. You shine when it comes to social interactions. You learn better when you are studying with your friends or when in a group listening to your peers. Get some ideas jumping off from everyone. You like games that require interaction, such as card games, and if you are into sports, you will usually prefer team sports such as basketball, baseball, or football.",
  },
  loner: {
    icon: LunerIcon,
    title: "The Interpersonal Learner",
    message:
      "You have a unique way of thinking and may have a journal or a diary. Spending time by yourself is your way of charging up when you are tired or need a break from reality. You have your hobbies and prefer to go on holiday by yourself away from crowds. Your best friend is often yourself as you feel you know yourself; you seek self-development and will choose to spend hours solving a problem that could be easily solved by talking to someone about it and brainstorm. You are a goal setter and someone that has a plan.",
  },
  naturalistic: {
    icon: NaturalisticIcon,
    title: "The Naturalist Learner",
    message:
      "You are aware of cloud formations, types of trees and possess a natural aptitude for understanding nature. You thrive in situations where you can explore, be active, feel things examine, and observe different elements. As one can imagine, you prefer practical work to write an essay and be surrounded by four walls. Your hobbies might include anything that involves outdoor activities such as hiking, taking pictures of nature, and having, in general, a deep sense of responsibility towards the earth. Once you have a telescope, a magnifying glass, binoculars, a notebook to take notes of their observations, the world becomes your oyster!",
  },
};

const sumValues = (questionier) => {
  let r = questionValues(0, 0, 0, 0, 0, 0, 0, 0);
  questionier.forEach((item) => {
    item.questions.forEach((question) => {
      const q = question.questionValue;
      r = questionValues(
        r.audio + q.audio,
        r.visual + q.visual,
        r.verbal + q.verbal,
        r.logical + q.logical,
        r.physical + q.physical,
        r.social + q.social,
        r.loner + q.loner,
        r.naturalistic + q.naturalistic
      );
    });
  });
  return r;
};

const QuestionaireSet = {
  under_6: questionaires_Under_6,
  above_6: questionaires_Above_6,
  over_12: questionaires_Over_12,
};

const QuestionaireSummationSet = {
  under_6: sumValues(questionaires_Under_6),
  above_6: sumValues(questionaires_Above_6),
  over_12: sumValues(questionaires_Over_12),
};

const calculateResult = (ageKey, answers) => {
  const total = QuestionaireSummationSet[ageKey];
  let r = questionValues(0, 0, 0, 0, 0, 0, 0, 0);
  Object.values(answers).forEach((answerObj) => {
    Object.values(answerObj).forEach((answer) => {
      const a = answer;
      r = questionValues(
        r.audio + a.audio,
        r.visual + a.visual,
        r.verbal + a.verbal,
        r.logical + a.logical,
        r.physical + a.physical,
        r.social + a.social,
        r.loner + a.loner,
        r.naturalistic + a.naturalistic
      );
    });
  });
  const answerPercentage = questionValues(
    (r.audio / total.audio) * 100,
    (r.visual / total.visual) * 100,
    (r.verbal / total.verbal) * 100,
    (r.logical / total.logical) * 100,
    (r.physical / total.physical) * 100,
    (r.social / total.social) * 100,
    (r.loner / total.loner) * 100,
    (r.naturalistic / total.naturalistic) * 100
  );
  return answerPercentage;
};

const getResultItem = (answerPercentages) => {
  let result = ResultItems["audio"];
  let lastValue = 0;
  let lastKey = "audio";
  Object.entries(answerPercentages).map(([key, value]) => {
    if (value > lastValue) {
      lastValue = value;
      lastKey = key;
      result = ResultItems[key];
    }
  });
  return { item: result, lastKey: lastKey };
};

export default CardSVG;
export {
  QuestionersAgeSelection,
  QuestionaireSet,
  QuestionaireSummationSet,
  calculateResult,
  getResultItem,
};
