import React, { useState } from "react";
import { connect } from "react-redux";
import UserType from "./steps/userType";
import SignUpMethod from "./steps/signUp";
import UserInfo from "./steps/userInfo";
import LocationInfo from "./steps/locationInfo";
import Animations from "./progress/animations";
import UserActions from "../../redux/user";
import { auth } from "../../config/firebase";
import { createUserWithEmailAndPassword, signOut } from "firebase/auth";
import { signUpUser } from "../../services/userManagement";
import LearnLogo from "../../assets/images/learn-logo-colour.svg";
import Swotbutton from "../common/buttons/swotbutton";
import LoaderOverlay from "../common/LoaderOverlay";
import {
  ConsentScript,
  submitConsent,
  TermsAndPrivacyPolicy,
} from "../common/consent";
import { STRIPE_REG_STATUSES } from "../../config/constants";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { historyState } from "../../services/navigation/history";

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 1,
      username: "",
      grade: "",
      accountType: "",
      livingPlace: null,
      schoolInfo: null,
      birthDay: null,
      gender: null,
      locationInfo: false,
      signUp: false,
      userInfo: false,
      userType: false,
      canMoveOn: false,
      obpDone: false,
      showLoader: false,
    };
  }

  setCanMoveOn = (screen, flag) => {
    this.setState({
      [screen]: flag,
      canMoveOn: flag,
    });
  };

  // navigate = useNavigate();
  // checkCanMoveOn = () => {
  //   return (this.state.currentStep === 1 && this.state.userType)
  //     || (this.state.currentStep === 2 && this.state.signUp)
  //     || (this.state.currentStep === 3 && this.state.userInfo)
  //     || (this.state.currentStep === 4 && this.state.locationInfo)
  // }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  savePlace = (type, place) => {
    this.setState({
      [type]: place,
    });
  };

  saveProperty = (key, value) => {
    console.log("saveProperty -", key, value);
    this.setState({
      [key]: value,
    });
    console.log("STATE", this.state);
  };

  handleSubmit = () => {
    console.log("handleSubmit was activated!");
    // window.location.search property returns the query string part of the current URL
    const queryParams = new URLSearchParams(window.location.search);
    const affilateCode = queryParams.get("affilate");

    console.log("affilateCode:", affilateCode);

    // disable the DONE button
    this.setCanMoveOn("locationInfo", false);
    this.setState({
      obpDone: true,
      showLoader: true,
    });
    const {
      username,
      grade,
      accountType,
      birthday,
      livingPlace,
      schoolInfo,
      gender,
      name,
    } = this.state;
    const { user, onBoardNewUser } = this.props;

    const existingUser = {
      ...user,
      username,
      grade,
      accountType,
      birthday,
      livingPlace,
      schoolInfo: schoolInfo,
      gender,
    };

    if (name) {
      existingUser.name = name;
    }

    if (existingUser.accountType === "Tutor") {
      existingUser.stripeStatus = STRIPE_REG_STATUSES.NONE;
    }

    if (existingUser.accountType === "Parent") {
      existingUser.accountType = "Student";
    }

    if (affilateCode) {
      existingUser.affilateCode = affilateCode.trim();
    }

    console.log("existingUser:", existingUser);

    if (!this.props.user && this.state.password) {
      // save temp user to redux to prevent redirecting to signin
      this.props.saveUser(existingUser);

      // signUp user
      this.signUpWithEmailPass().then((registeredUser) => {
        submitConsent(
          registeredUser.uid,
          registeredUser.email,
          registeredUser.name
        );
        this.setState({ showLoader: false });
        onBoardNewUser({ ...registeredUser, ...existingUser });
      });
    } else {
      this.setState({ showLoader: false });
      onBoardNewUser(existingUser);
    }
  };

  signUpWithEmailPass = async () => {
    const { email, password, name, username } = this.state;
        // Creating New User Here Signup with Email

    const { user } = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const newUser = { ...user, name, username };
    // generateUserDocument(user, { displayName, status: "pending" });
    return await signUpUser(newUser, this.props.saveUser);
  };

  _next = () => {
    let currentStep = this.state.currentStep;
    console.log("Wizard - NEXT - current step", currentStep, this.props.user);
    if (currentStep === 1 && this.props.user) {
      currentStep = 2;
    }

    currentStep = currentStep >= 3 ? 4 : currentStep + 1;
    this.setState({
      currentStep: currentStep,
      canMoveOn: false,
    });
  };

  _prev = () => {
    let currentStep = this.state.currentStep;
    console.log(
      "Wizard - Previous - current step",
      currentStep,
      this.props.user
    );
    if (currentStep === 3 && this.props.user) {
      currentStep = 2;
    }
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep,
      canMoveOn: true,
    });
  };

  _cancel = () => {
    let currentStep = this.state.currentStep;
    console.log("Wizard - Cancel - current step", currentStep, this.props.user);
    if (currentStep === 1) {
      //TODO: logout
      this.props.saveUser(null);
      signOut(auth);
      if (this.props.navigate) {
        this.props.navigate(-1);
      } else {
        historyState.navigate(-1);
      }
    }
  };
  /*
   * the functions for our button
   */
  cancelButton() {
    let currentStep = this.state.currentStep;
    if (currentStep === 1) {
      return (
        <div className="w-1/2 flex flex-col justify-center items-start">
          <Swotbutton text={"Cancel"} onClick={this._cancel} disabled={false} />
        </div>
      );
    }
    return null;
  }

  previousButton() {
    let currentStep = this.state.currentStep;
    if (currentStep !== 1) {
      return (
        <div className="w-1/2 flex flex-col justify-center items-start">
          <Swotbutton
            text={"Previous"}
            onClick={this._prev}
            disabled={this.state.obpDone}
          />
        </div>
      );
    }
    // return <div className="w-1/2" />;
    return null;
  }

  nextButton() {
    let currentStep = this.state.currentStep;
    if (currentStep < 4) {
      return (
        <div className="w-1/2 flex flex-col justify-center items-end">
          <Swotbutton
            text={"Next"}
            onClick={this._next}
            disabled={!this.state.canMoveOn}
          />
        </div>
      );
    }
    return null;
  }

  doneButton() {
    let currentStep = this.state.currentStep;
    if (currentStep === 4) {
      return (
        <div className="w-1/2 flex flex-col justify-center items-end">
          <Swotbutton
            text={"Done"}
            onClick={this.handleSubmit}
            disabled={!this.state.canMoveOn || this.state.obpDone}
          />
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <LoaderOverlay loading={this.state.showLoader}>
        <React.Fragment>
          <ConsentScript />
          {/* <div className="w-full h-screen flex lg:flex-row flex-col items-center h-screen-ios min-h-screen-ios"> */}
          <div className="w-full h-screen flex lg:flex-row flex-col items-center min-h-screen-ios">
            {/* <div
              className="md:w-1/5 sm:w-2/6 w-2/5 pt-1 xl:w-1/4 lg:w-1/4 xl:h-screen xl:h-screen-ios xl:min-h-screen-ios
          flex bg-white lg:float-left flex-col justify-center "
            >
              <Animations currentStep={this.state.currentStep} />
            </div> */}
            {/* <div className="w-full xl:w-3/4 lg:w-3/4 flex-row flex lg:h-screen lg:h-screen-ios lg:min-h-screen-ios lg:float-right items-center h-7/10"> */}
            <div className="w-full h-full lg:h-screen lg:h-screen-ios lg:min-h-screen-ios flex-row flex lg:float-right items-center">
              <div className="w-full h-full flex flex-col items-center justify-center overflow-y-scroll">
                <div className="w-full max-w-7xl h-90v md:h-80v lg:h-4/5 flex justify-center items-center py-5 min-h-80">
                  <div
                    className="bg-white w-11/12 lg:w-2/3 xl:w-2/3 h-full lg:h-4/5 px-5 flex flex-col justify-center"
                    style={{
                      boxShadow: `0 10px 25px 0 rgba(0, 0, 0, 0.6)`,
                      borderRadius: 25,
                      //height: '70%',
                    }}
                  >
                    <div className="text-center h-full">
                      <div className="flex justify-center items-center lg:h-1/6 h-1/10">
                        <img
                          src={LearnLogo}
                          alt="Logo"
                          className="xl:h-[6.5rem] md:h-[5rem] h-[4.5rem] w-auto pt-2 pb-1 bg-transparent"
                        />
                      </div>

                      <div className="w-full lg:h-5/6 h-9/10 flex flex-col justify-around">
                        {/* 
        render the form steps and pass required props in
      */}
                        <div className="h-3/5 lg:h-7/10">
                          <UserType
                            currentStep={this.state.currentStep}
                            saveProperty={this.saveProperty}
                            setCanMoveOn={this.setCanMoveOn}
                          />
                          <SignUpMethod
                            currentStep={this.state.currentStep}
                            saveProperty={this.saveProperty}
                            next={this._next}
                            setCanMoveOn={this.setCanMoveOn}
                          />

                          <UserInfo
                            currentStep={this.state.currentStep}
                            saveProperty={this.saveProperty}
                            handleChange={this.handleChange}
                            setCanMoveOn={this.setCanMoveOn}
                            accountType={this.state.accountType}
                          />

                          <LocationInfo
                            currentStep={this.state.currentStep}
                            handleChange={this.handleChange}
                            handleSubmit={this.handleSubmit}
                            password={this.state.password}
                            savePlace={this.savePlace}
                            saveProperty={this.saveProperty}
                            setCanMoveOn={this.setCanMoveOn}
                            accountType={this.state.accountType}
                          />
                        </div>

                        <div
                          className="w-full flex flex-row justify-between bottom-auto py-2"
                          style={{ height: "14%" }}
                        >
                          {this.cancelButton()}
                          {this.previousButton()}
                          {this.nextButton()}
                          {this.doneButton()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="flex xl:w-3/4 lg:w-3/4 items-end justify-center pt-10 lg:-ml-16 p-2 text-center text-sm"> */}
                <div className="w-full xl:w-3/4 lg:w-3/4 flex items-end md:items-center justify-center p-2 text-center text-sm">
                  <TermsAndPrivacyPolicy />
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </LoaderOverlay>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Wizard);
