import React, { useState, useEffect } from "react";
import knotSm from "../../assets/images/learn-small.png";
import { setTutorApprovalStatus, setNgoUserStatus } from "../../services/api";
import UserInfoPanel from "../tutor/profile/userInfoPanel";
import swal from "sweetalert2";

const TutorListItem = ({ tutor, expandedTutorId, setExpandedTutor }) => {
  const [approvalStatus, setApprovalStatus] = useState(tutor.approved);
  const [ngoUserState, setNgoUserState] = useState(tutor.ngoUser);

  // const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    tutor.uid = tutor.firebaseId;
    tutor.accountType = "Tutor";
    console.log("TutorListItem - useEffect", approvalStatus, tutor);
  }, [approvalStatus]);

  const approve = async () => {
    setApprovalStatus(true);
    setTutorApprovalStatus(tutor.firebaseId, { approve: true });
  };

  const reject = async () => {
    setApprovalStatus(false);
    setTutorApprovalStatus(tutor.firebaseId, { approve: false });
  };

  const setNgoUser = async () => {
    swal
      .fire({
        title: "Set NGO User",
        text: "Are you sure you want to set this tutor as NGO user(offering free course)?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm!",
      })
      .then((result) => {
        if (result.isConfirmed) {
          setNgoUserStatus(tutor.firebaseId, { ngoUser: true });
          setNgoUserState(true);
        }
      });
  };

  const showTutorInfo = () => {
    // setShowInfo(!showInfo);
    // console.log("showing tutor info..", showInfo);
    setExpandedTutor &&
      setExpandedTutor(
        expandedTutorId === tutor?.firebaseId ? null : tutor?.firebaseId
      );
  };

  const renderApprovalButtons = () => {
    console.log("TutorListItem - renderApprovalButtons", approvalStatus);
    if (tutor.approved || approvalStatus) {
      // if (approvalStatus) {

      return (
        <div
          className={`ml-auto text-center ${
            approvalStatus ? "text-green-500" : "text-red-500"
          }`}
        >
          {`Status: ${approvalStatus ? "Approved" : "Rejected"}`}
        </div>
      );
    } else {
      return (
        <div className=" flex justify-between items-center ml-auto">
          <button
            className="focus:outline-none hover:shadow-outline bg-green-600 py-2 px-4
             text-white mx-2 rounded-2xl"
            onClick={() => approve()}
          >
            Approve
          </button>
          <button
            className="focus:outline-none hover:shadow-outline bg-red-600 py-2 px-4
             text-white mx-2 rounded-2xl"
            onClick={() => reject()}
          >
            Reject
          </button>
        </div>
      );
    }
  };

  return (
    <div className="w-full bg-transparent flex flex-col justify-start focus:outline-none pb-4">
      {/* <div className="w-full bg-transparent flex  focus:outline-none h-1/6 pb-4 "> */}
      <div
        className="shadow-lg rounded-2xl bg-white h-full text-xs px-3 py-2 flex flex-col md:flex-row justify-start items-start md:items-center"
        // className="shadow-lg rounded-2xl bg-white  h-full flex justify-start items-center text-xs px-3"
        style={{
          // width: "60%",
          maxWidth: "60rem",
          // height: "80px",
          // marginTop: "1rem",
        }}
      >
        <div className="flex justify-start">
          <div className=" h-full flex items-center px-4">
            <img
              // class="contain"
              src={tutor.photoUrl ? tutor.photoUrl : knotSm}
              alt={""}
              style={{}}
              className="rounded-full shadow-lg h-12 w-12 bg-white object-cover"
            />
          </div>
          <div className="text-center pr-4 flex justify-center items-center">
            {tutor.firstName}
          </div>
          <div className="text-center pr-4 flex justify-center items-center">
            {tutor.lastName}
          </div>
        </div>
        <div className="flex flex-row justify-end md:justify-between flex-grow pt-2 md:pt-0">
          <button
            className="focus:outline-none hover:shadow-outline bg-blue-600 py-2 px-4
             text-white mx-2 rounded-2xl"
            onClick={() => showTutorInfo()}
          >
            Show Info
          </button>

          <button
            className="focus:outline-none hover:shadow-outline bg-purple-700 py-2 px-4
             text-white mx-2 rounded-2xl"
            onClick={() => setNgoUser()}
            disabled={ngoUserState}
          >
            Set NGO User
          </button>

          {renderApprovalButtons(tutor.approved)}
        </div>
      </div>
      {expandedTutorId === tutor?.firebaseId && (
        <div
          style={{
            maxWidth: "50rem",
            // marginTop: "90px",
            // width: "54%",
            // height: "55vh",
            // position: "absolute",
            transition: "opacity 1s",
            display: "flex",
          }}
        >
          <UserInfoPanel tutor={tutor} viewOnly={true} />
        </div>
      )}
    </div>
  );
};

export default TutorListItem;

/**
 *
 *{
        "firstName": "uri",
        "lastName": "uri",
        "username": "uri",
        "photoUrl": "gegegege",
        "email": "evafeiadis@gmail.com",
        "introduceVideoUrl": "gegegege",
        "resumeUrl": "gegegege",
        "teachingMethod": "rgrgrgrgr",
        "teachingStyle": "gegegegege",
        "specialEducation": false,
        "approved": false,
        "registration": "online",
        "preference": "group",
        "subjects": [
            {
                "subject": "English",
                "area": "American literature",
                "tutoringCurriculum": "curriculum"
            },
            {
                "subject": "English",
                "area": "British literature",
                "tutoringCurriculum": "curriculum"
            }
        ]
    },
 */
