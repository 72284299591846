import React, { useState } from "react";
import "./index.css";

function AccordionItem({ item, index, selected, setSelected }) {
  const toggle = () => {
    setSelected(selected === index ? null : index);
  };

  return (
    <div className="background-[#f0ebe1] mb-[5px] px-5 py-3 rounded-md bg-white">
      <div
        className="text-gray-700 flex flex-row-reverse justify-end items-center cursor-pointer"
        onClick={toggle}
      >
        <p className="text-[14px] ml-2 md:ml-4 ">{item.title}</p>
        <span className="text-gray-600">{selected === index ? "-" : ">"}</span>
      </div>
      <div
        className={`text-gray-600 text-sm content ${
          selected === index ? "show" : ""
        }`}
      >
        <p className="text-[12px]">{item.text}</p>
      </div>
    </div>
  );
}

export default function Accordion({ data }) {
  const [selected, setSelected] = useState(null);

  return (
    <div>
      {data.map((item, i) => (
        <AccordionItem
          key={i}
          item={item}
          index={i}
          selected={selected}
          setSelected={setSelected}
        />
      ))}
    </div>
  );
}
