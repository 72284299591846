import React, { useEffect, useState } from "react";
import Swotbutton from "../../common/buttons/swotbutton";
import commonColor from "../../../theme/commonColor";
import creditCard from "../../../assets/images/swotknot-card.png";
import {
  stripeRegisterAccount,
  getTutorStripeStatus,
} from "../../../services/paymentsManagement";
import { getUserSubscriptionStatus } from "../../../services/subscriptionManagement";
import { ACCOUNT_TYPES, STRIPE_REG_STATUSES } from "../../../config/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DebitCard from "../../payments/debitCard/debitCard";
import { getStripeCountriesList } from "../../../services/api";
import Select from "../../common/input/select";
import UserType from "../../onBoarding/steps/userType";

const SubscriptionBoard = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [planSubscribed, setPlanSubscribed] = React.useState(false);
  const [stripeLink, setStripeLink] = React.useState(null);
  const [monthlyPlanLink, setMonthlyPlanLink] = React.useState(null);
  const [annualPlanLink, setAnnualPlanLink] = React.useState(null);
  const [stripeAccount, setStripeAccount] = React.useState();
  const [stripeCountries, setStripeCountries] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState();
  const [userType, setUserType] = useState(null);
  const [trialPeriod, setTrialPeriod] = useState(false);
  const [trialPeriodRemaining, setTrialPeriodRemaining] = useState(0);
  const [subscriptionRemaining, setSubscriptionRemaining] = useState(0);
  const [subscriptionType, setSubscriptionType] = useState(null);

  useEffect(() => {
    if (props?.user?.accountType) {
      if (
        props.user.accountType === ACCOUNT_TYPES.TUTOR ||
        props.user.accountType === ACCOUNT_TYPES.GUEST_TUTOR
      ) {
        setUserType("tutor");
      } else {
        setUserType("student");
      }
    }
    
  }, [props?.user?.accountType]);

  useEffect(() => {
    const getData = async () => {
      if (stripeCountries.length === 0) {
        // console.log("getting countries list for stripe");
        await getStripeCountriesListData();
      }
    };
    getData();
  }, [stripeCountries]);

  useEffect(() => {
    const checkStatus = async (userType) => {
      try {
        console.log('subscriptionbaord userId', props.user.uid, userType)
        const result = await getUserSubscriptionStatus(
          props.user.uid,
          userType
        );
        console.log(result)
        if (result?.data) {
          if (result.data.status === "ERROR") {
            console.error(
              "Subscription -  useEffect - checking subscription status Error",
              result
            );
          } else {
            // console.log(
            //   "Subscription -  useEffect - checking subscription status SUCCESS",
            //   result
            // );
            if (result.data.data) {
              console.log('Result data in subscriptionboard', result.data.data)
              // setStripeAccount(result.data.data);
              if (result.data.data.subscription) {
                setPlanSubscribed(result.data.data.subscription);
              }
              if (result.data.data.monthly_plan) {
                setMonthlyPlanLink(result.data.data.monthly_plan);
              }
              if (result.data.data.annual_plan) {
                setAnnualPlanLink(result.data.data.annual_plan);
              }
              setTrialPeriod(result.data.data?.trial ?? false);
              setTrialPeriodRemaining(result.data.data?.trial_remaining ?? 0);
              setSubscriptionType(result.data.data?.type);
              setSubscriptionRemaining(result.data.data?.remaining ?? 0);
            }
          }
        }
      } catch (e) { }
    };
    if (userType !== undefined && userType !== null) {
      checkStatus(userType);
      // console.log("Subscription Countries Option", stripeCountries);
    }
  }, [userType]);

  const getStripeCountriesListData = async (contentType) => {
    let response = await getStripeCountriesList();
    // console.log("getStripeCountriesListData - response", response.data?.data);
    if (response.data?.status === "SUCCESS") {
      setStripeCountries(response.data?.data);
    } else {
      return null;
    }
  };

  const approvedView = () => {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full md:justify-between">
        <div className="w-full pl-12">
          {props.user.stripeStatus === STRIPE_REG_STATUSES.INACTIVE
            ? "Some information is missing. Update your stripe account."
            : "Stripe account information"}
        </div>
        <div className="flex items-center justify-center w-full my-4 md:h-full md:my-0">
          {/* <img
            style={{
              maxWidth: "18rem",
              maxHeight: "16rem",
            }}
            src={creditCard}
            alt={""}
          /> */}
          <DebitCard
            accountId={stripeAccount?.account_id}
            name={stripeAccount?.name}
            createdAt={stripeAccount?.created}
          />
        </div>
        <div className="flex justify-center w-full">
          <div className="flex space-x-1 sm:space-x-2 lg:space-x-4 2xl:space-x-12">
            <Swotbutton
              text={"View Stripe Account"}
              onClick={() => {
                /*TODO*/
                if (stripeLink) {
                  window.open(stripeLink, "_blank", "noopener,noreferrer");
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const tutorSubscriptionCard = (planType) => {
    return (
      <div className="w-full p-4">
        <div className="flex flex-col w-full p-4 border border-gray-300 border-solid rounded-md item-center">
          <div className="font-bold text-center">
            {planType === "yearly" ? "Yearly" : "Monthly"}
          </div>
          <div className="text-center">
            You will be charged&nbsp;
            <span className="font-bold text-sx">
              {planType === "yearly" ? "$80" : "$9.99"}
            </span>
            &nbsp;per {planType === "yearly" ? "Year" : "Month"}.
          </div>
          <div className="flex justify-center w-full">
            <Swotbutton
              className="mx-8 max-w-60"
              text={"Subscribe"}
              onClick={() => {
                if (planType === "yearly") {
                  if (annualPlanLink) {
                    window.open(
                      annualPlanLink,
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }
                } else if (monthlyPlanLink) {
                  window.open(monthlyPlanLink, "_blank", "noopener,noreferrer");
                }
                /*TODO Add registration link*/
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const studentSubscriptionCard = (planType) => {
    return (
      <div className="w-full p-4">
        <div className="flex flex-col w-full p-4 border border-gray-300 border-solid rounded-md item-center">
          <div className="font-bold text-center">
            {planType === "yearly" ? "Yearly" : "Monthly"}
          </div>
          <div className="text-center">
            You will be charged&nbsp;
            <span className="font-bold text-sx">
              {planType === "yearly" ? "$40" : "$4.99"}
            </span>
            &nbsp;per {planType === "yearly" ? "Year" : "Month"}.
          </div>
          <div className="flex justify-center w-full">
            <Swotbutton
              className="mx-8 max-w-60" 
              text={"Subscribe"}
              onClick={() => {
                console.log('Clicked button')
                if (planType === "yearly") {
                  if (annualPlanLink) {
                    window.open(
                      annualPlanLink,
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }
                } else if (monthlyPlanLink) {
                  window.open(monthlyPlanLink, "_blank", "noopener,noreferrer");
                }
                /*TODO Add registration link*/
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  if (stripeLink) {
    return approvedView();
  } else {
    switch (props.user.stripeStatus) {
      case STRIPE_REG_STATUSES.APPROVED:
        return approvedView();
      case STRIPE_REG_STATUSES.INACTIVE:
        return approvedView();
      case STRIPE_REG_STATUSES.PENDING:
        return (
          <div className="flex flex-col items-center justify-between w-full h-full">
            <div className="w-full pl-12 m-auto text-center">
              Stripe account registration pending...
            </div>
          </div>
        );
      case STRIPE_REG_STATUSES.INCOMPLETE:
        return (
          <div className="flex flex-col items-center justify-center w-full h-full">
            <div className="w-full pl-12 m-auto text-center">
              Stripe account registration is not completed.
            </div>
            <div className="flex justify-around w-1/2">
              <Swotbutton
                text={"Continue stripe setup"}
                onClick={() => {
                  /*TODO Add registration link*/
                  setLoading(true);
                  stripeRegisterAccount(props.user.uid).finally(() =>
                    setLoading(false)
                  );
                }}
                loading={loading}
              />
            </div>
          </div>
        );
      default:
        return (
          <div className="flex flex-col items-center w-full h-full pt-6 overflow-x-hidden">
            {trialPeriod === true && trialPeriodRemaining > 0 && (
              <div className="w-full mb-2 font-bold text-center">
                You are on trial period.
              </div>
            )}
            {planSubscribed === true ? (
              <div className="w-full p-4">
                <div className="mb-16 text-center">
                  <p className="text-xl font-semibold text-green-600">You are subscribed!</p>
                </div>
                <div className="grid grid-cols-2 gap-4 py-4 mb-6 border-t border-b">
                  <div className="text-lg text-gray-700">Plan:</div>
                  <div className="text-lg font-bold text-gray-900 capitalize">{subscriptionType}</div>
                  <div className="text-lg text-gray-700">Remaining Days:</div>
                  <div className="text-lg font-bold text-gray-900">{subscriptionRemaining}</div>
                </div>
                <div className="mt-4">
                  <p className="text-sm text-gray-600">
                    Thank you for being a valued subscriber. Enjoy uninterrupted access to all our features and services. If you have any questions or need assistance, feel free to contact our support team.
                  </p>
                </div>
              </div>
            ) : (
              <>
                <div className="w-full mb-2 text-center">
                  Choose Your Subscription Plan!
                </div>
                {props.user.accountType === ACCOUNT_TYPES.TUTOR ||
                  props.user.accountType === ACCOUNT_TYPES.GUEST_TUTOR ? (
                  <div className="flex flex-col justify-around w-full md:flex-row">
                    {monthlyPlanLink && (
                      <div className="w-full md:w-1/2">
                        {tutorSubscriptionCard("monthly")}
                      </div>
                    )}
                    {annualPlanLink && (
                      <div className="w-full md:w-1/2">
                        {tutorSubscriptionCard("yearly")}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="flex flex-col justify-around w-full md:flex-row">
                    {monthlyPlanLink && (
                      <div className="w-full md:w-1/2">
                        {studentSubscriptionCard("monthly")}
                      </div>
                    )}
                    {annualPlanLink && (
                      <div className="w-full md:w-1/2">
                        {studentSubscriptionCard("yearly")}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        );
    }
  }
};

export default SubscriptionBoard;
