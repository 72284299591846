import React from "react";
import LandingLayout from "../../components/landing/landingLayout";
import LandingCard from "../../components/landing/landingCard";
import commonColor from "../../theme/commonColor";
import BulbImage_4 from "../../assets/images/information/bulbs-4.svg";
import BulbImage_5 from "../../assets/images/information/bulbs-5.svg";
import BulbImage_6 from "../../assets/images/information/bulbs-6.svg";

const About = () => {
  return (
    <LandingLayout landingTitle="Who we are">
      <div className="flex flex-wrap justify-around sm:pl-24 sm:pr-10 pl-1 max-w-screen-2k">
        {content.map((props) => (
          <LandingCard key={props.title} {...props} />
        ))}
      </div>
    </LandingLayout>
  );
};

export default About;

const getStyledContent = (index) => {
  switch (index) {
    case 1:
      return (
        <div
          className="w-full textCardNormal"
          style={
            {
              // lineHeight: "1.2rem",
              // fontSize: "1.2rem",
            }
          }
        >
          <span className="font-bold">l3arn values:</span>
          <p className="pt-4">
            <span className="textCardLarge">respect</span>,{" "}
            <span className="font-bold italic">creativity</span>, honesty,{" "}
            <span className="textCardLarge italic">integrity</span>,{" "}
            <span className="textCardLarge">compassion</span> and{" "}
            <span className="font-bold textCardLarge">passion</span>, getting a
            little uncomfortable, imagination, empathy,{" "}
            <span className="textCardLarge">inclusivity</span>, believing in
            your ideas, vision, stick to it ness, family,{" "}
            <span className="italic textCardLarge">community</span>, friends,{" "}
            <span className="font-bold">pushing to the next level,</span> being
            a good <span className="textCardLarge">global citizen</span> all
            while honoring you and what you value.
          </p>
        </div>
      );
    case 2:
      return (
        <div
          className="w-full flex flex-col justify-center textCardNormal"
          style={
            {
              // lineHeight: "1.2rem",
              // fontSize: "1.2rem",
            }
          }
        >
          <p>
            l3arn will provide an <br></br>
            <span className="font-bold textCardLarge">
              ever-evolving platform
            </span>{" "}
            to meet and support the needs of educators, students, and families
            from all walks of life and all corners of the globe for all levels
            of&nbsp;
            <span className="textCardMedium">education and learning</span> for
            any type of organization, academic, business and institutional using
            virtual, AI and digital tools to enable remote mobile work and
            learning and increase the productivity of education whenever and
            under whatever circumstances.
          </p>
        </div>
      );
    case 3:
      return (
        <div
          className="w-full flex flex-col justify-center textCardNormal"
          style={
            {
              // lineHeight: "1.2rem",
              // fontSize: "1.2rem",
            }
          }
        >
          {" "}
          <p>
            l3arn is a community{" "}
            <span className="font-bold textCardMedium">
              intelligence based distributed education platform
            </span>{" "}
            designed to engage and support educational opportunities with the
            goal to create <span className="italic">happiness</span>,{" "}
            <span className="font-bold textCardMedium">
              increase knowledge,
            </span>{" "}
            develop <span className="italic">competency</span> and{" "}
            <span className="font-bold textCardMedium">
              positively integrate
            </span>{" "}
            into the <span className="textCardLarge">global community.</span>
          </p>
        </div>
      );
  }
};

const content = [
  {
    title: "The Values",
    image: BulbImage_4,
    contentComponent: getStyledContent(1),
    content: {
      paragraph: `l3arn values: respect, creativity, honesty, integrity, compassion and passion, getting a little uncomfortable, imagination, empathy, inclusivity, believing in your ideas, vision, stick to it ness, family, community, friends, pushing to the next level, being a good global citizen all while honoring you and what you value.`,
    },
  },
  {
    title: "The Vision",
    image: BulbImage_5,
    contentComponent: getStyledContent(2),
    content: {
      paragraph: `l3arn will provide an ever-evolving platform to meet and support the needs of educators, students, and families from all walks of life and all corners of the globe for all levels of education and learning for any type of organization, academic, business and institutional using virtual, AI and digital tools to enable remote mobile work and learning and increase the productivity of education whenever and under whatever circumstances.`,
    },
  },
  {
    title: "The Mission",
    image: BulbImage_6,
    contentComponent: getStyledContent(3),
    content: {
      paragraph: `l3arn is a community intelligence based distributed education platform designed to engage and support educational opportunities with the goal to create happiness, increase knowledge, develop competency and positively integrate into the global community.`,
    },
  },
  // {
  //   title: "The Team",
  //   image: teamSrc,
  //   hoverImage: teamGifSrc,
  //   content: {
  //     paragraph: `If you want to go fast, go alone. If you want to go far, go together - African Proverb

  //   We bring together a diverse team of individual learners. Some have learned science, some law, or engineering or cooking or teaching or programming or art or a combination of these or bits and pieces of well not almost anything you can think of! Together we can go far, far enough to change the future of education.
  //   `,
  //   },
  // },
];
