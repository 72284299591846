import React, { useState, useEffect } from "react";
import knotSm from "../../../assets/images/learn-small.png";
import { rejectRefund, approveRefund } from "../../../services/api";
import UserInfoPanel from "../../tutor/profile/userInfoPanel";
import LoaderModal from "../../common/modal/loaderModal";
import Modal from "../../common/modal/modal";
import SwotButton from "../../common/buttons/swotbutton";
import Swal from "sweetalert2";
import RefundItemDetails from "./refundItemDetails";
// amount:10
// commission:3
// course_id:'234'
// created_timestamp:'1636037683'
// refund_reason:null
// refund_response:null
// status:'refund_requested'
// transaction_id:'pi_3Js7KxQsobSMmqbr0AwVMBN5'
// tutor_email:'wasimshigri@gmail.com'

const RefundListItem = ({ refundItem }) => {
  const [refundStatus, setRefundStatus] = useState(refundItem.status);
  const [rejectionReason, setRejectionReason] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [showRejectionReason, setShowRejectionReason] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // tutor.uid = tutor.firebaseId;
    // tutor.accountType = 'Tutor';
    if (refundStatus) {
      refundItem.status = refundStatus;
    }
    console.log("TutorListItem - useEffect", refundStatus, refundItem);
  }, [refundStatus]);

  const approve = async () => {
    setLoading(true);
    const res = await approveRefund(refundItem.transaction_id);
    setLoading(false);
    console.log(res);
    if (res && res.data && res.data.status === "SUCCESS") {
      setRefundStatus("refunded");
      Swal.fire({
        title: "Success!",
        text: "Refund completed!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else if (res && res.data && res.data.status === "ERROR") {
      Swal.fire({
        title: "Failed!",
        text: res.data?.errorMessage ?? "Refund canceled!",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const reject = async () => {
    setLoading(true);
    const res = await rejectRefund(refundItem.transaction_id, rejectionReason);
    setLoading(false);
    console.log(res);
    if (res && res.data && res.data.status === "SUCCESS") {
      setRefundStatus("rejected");
      Swal.fire({
        title: "Success!",
        text: "Refund rejected!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else if (res && res.data && res.data.status === "ERROR") {
      Swal.fire({
        title: "Failed!",
        text: res.data?.errorMessage ?? "Refund rejection canceled!",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const showRefundDetails = () => {
    setShowInfo(!showInfo);
    console.log("showing refund info..", showInfo);
  };

  const renderApprovalButtons = () => {
    console.log("RefundListItem - renderApprovalButtons", refundStatus);
    if (refundStatus !== "refund_requested") {
      // if (approvalStatus) {

      return (
        <div
          className={`ml-auto text-center ${
            refundStatus === "refunded" ? "text-green-500" : "text-red-500"
          }`}
        >
          {`Status: ${refundStatus === "refunded" ? "Approved" : "Rejected"}`}
        </div>
      );
    } else {
      return (
        <div className=" flex justify-between items-center ml-auto">
          <button
            className="focus:outline-none hover:shadow-outline bg-green-600 py-2 px-4
             text-white mx-2 rounded-2xl"
            onClick={() => approve()}
          >
            Approve
          </button>
          <button
            className="focus:outline-none hover:shadow-outline bg-red-600 py-2 px-4
             text-white mx-2 rounded-2xl"
            onClick={() => setShowRejectionReason(true)}
          >
            Reject
          </button>
        </div>
      );
    }
  };

  return (
    <div className="w-full bg-transparent flex flex-col justify-start focus:outline-none pb-4 ">
      <div
        className="shadow-lg rounded-2xl bg-white  h-full flex 
        justify-start items-center text-xs px-3"
        style={{
          maxWidth: "60rem",
          // width: "60%",
          // height: "80px",
          minHeight: "80px",
        }}
      >
        <div className=" h-full flex items-center px-4">
          {/* <img
            // class="contain"
            src={tutor.photoUrl ? tutor.photoUrl : knotSm}
            alt={""}
            style={{}}
            className="rounded-full shadow-lg h-12 w-12 bg-white object-cover"
          /> */}
        </div>
        <div className="text-center pr-4">
          <b>Student Name: {refundItem.student_name}</b> |
        </div>
        <div className="text-center pr-4">{refundItem.transaction_id}</div>
        <button
          className="focus:outline-none hover:shadow-outline bg-blue-600 py-2 px-4
             text-white mx-2 rounded-2xl"
          onClick={() => showRefundDetails()}
        >
          Show Info
        </button>
        {renderApprovalButtons()}
      </div>
      {showInfo && (
        <div
          style={{
            maxWidth: "50rem",
            // marginTop: "90px",
            // width: "54%",
            //height: "55vh",
            // position: "absolute",
            transition: "opacity 1s",
            display: "flex",
          }}
          className="flex flex-col items-center justify-start"
        >
          <RefundItemDetails refundItem={refundItem} />
        </div>
      )}

      <Modal
        isOpen={showRejectionReason}
        onRequestClose={() => {
          setShowRejectionReason(false);
        }}
      >
        <div className="flex flex-col">
          <div className="flex w-full justify-center">
            <p> Reject Refund</p>
          </div>

          <div className="flex">
            <div>
              <p>Transaction Id: </p>
            </div>
            <div>{refundItem.transaction_id}</div>
          </div>

          <div className="w-full flex flex-row justify-center py-2">
            <div className="w-full flex flex-row justify-start px-2 py-1">
              <textarea
                rows={4}
                className="w-full bg-white appearance-none border-2 focus:outline-none focus:border-opacity-100 py-1 px-2 rounded-lg"
                value={rejectionReason}
                placeholder={
                  "Enter a paragraph describing reason for rejecting refund"
                }
                onChange={(event) => {
                  setRejectionReason(event.target.value);
                }}
              />
            </div>
          </div>
          <div className="w-full flex flex-row justify-center py-2">
            <SwotButton
              text={"Reject Refund"}
              disabled={!rejectionReason}
              onClick={() => {
                setShowRejectionReason(false);
                reject();
              }}
            />
          </div>
        </div>
      </Modal>

      <LoaderModal isOpen={loading} />
    </div>
  );
};

export default RefundListItem;
