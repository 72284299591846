import React, { useEffect, useState } from "react";
import Modal from "../../../components/common/modal/modal";
import { ReactComponent as Del } from "../../../assets/icons/trash.svg";
import { ReactComponent as Edit } from "../../../assets/icons/editing.svg";
import commonColor from "../../../theme/commonColor";
import Swotbutton from "../../../components/common/buttons/swotbutton";

const CourseContent = ({ courseId, items, noItemsText, addClick, editClick, deleteClick }) => {
  const [viewItem, setViewItem] = useState(null);
  const [reset, setReset] = useState(true);

  const addHandler = () => {
    addClick && addClick(courseId)
    setReset(true);
  };

  const deleteHandler = (index) => {
    const item = items[index]
    deleteClick && deleteClick(index)
    setReset(true);
  };

  const editHandler = (index) => {
    const item = items[index]
    editClick && editClick(index)
    setReset(true);
  };

  const updateHandler = async (file) => {
    // await courseMaterialUpload(courseId, file, uploadPath, materialType);
    // setReset(true);
  };

  return (
    <>
        <div className="h-full w-full flex flex-col justify-start">
          <div className="w-full h-full flex flex-col flex-grow items-start justify-start p-4 overflow-y-scroll">
            {items.length === 0
              ? 
              <div className="w-full h-full flex justify-center items-center">
                {noItemsText}
              </div>
              : items.map((item, index) => {
                  return (
                    <ContentCell key={"content_cell_" + index} index={index} title={item.title} detail={item.body} editClick={editClick ? editHandler : null} deleteClick={deleteClick ? deleteHandler : null}/>
                  );
                })}
          </div>
          {addClick && <div className="flex justify-start flex-row-reverse">
              <Swotbutton 
              text={"Add Lesson"}
              onClick={addHandler}
              />
          </div>}
        </div>

      {/* <Modal
        isOpen={!!viewItem}
        onRequestClose={() => {
          setViewItem(null);
        }}
      >
        {viewItem && (
          <div className="w-full h-full text-center">
            <Viewer file={viewItem} />
          </div>
        )}
      </Modal> */}
    </>
  );
};

const ContentCell = ({ title, detail, index, editClick, deleteClick }) => {
  return (
    <div className="w-full relative group"  
    style={{
      marginBottom: "1rem"
    }}>
      <div className="w-full flex pb-1 flex-col items-start justify-start" style={{
        marginBottom: "1rem"
      }}>
        <div className="text-lg" 
        style={{
          width: "100%",
          overflowWrap: "break-word"
        }}>
          <p>{title}</p>
        </div>
        <div className="text-xs"
        style={{
          width: "100%",
          overflowWrap: "break-word"
        }}>
          <p>{detail}</p>
        </div>
      </div>

      <div className="absolute bottom-0 right-0 hidden group-hover:block">
        <div className="flex space-x-2">
        {editClick && <button
            className="focus:outline-none focus:shadow-lg"
            onClick={() => {
              console.log("Edit courseContent pressed");
              editClick(index)
            }}
          >
            <Edit
              style={{
                width: "1.5rem",
                height: "1.5rem",
                overflow: "hidden",
                fill: commonColor.deepPink,
              }}
            />
          </button>}
          {deleteClick && <button
            className="focus:outline-none focus:shadow-lg"
            onClick={() => {
              console.log("Delete course pressed");
              deleteClick(index)
            }}
          >
            <Del
              style={{
                width: "1.5rem",
                height: "1.5rem",
                overflow: "hidden",
                fill: commonColor.deepPink,
              }}
            />
          </button>}
        </div>
      </div>
    </div>
  );
};

export default CourseContent;
