import React from "react";
import { components } from '@reactour/tour'

const Close = ({ onClick }) => {
    return (
      <button
        className="text-2xl sm:text-4xl p-2 rounded-full"
        onClick={onClick}
        style={{ position: 'absolute', right: -2, top: -2 }}
      >
        x
      </button>
    )
  }

  const Navigation = ({styles,setCurrentStep, steps, currentStep, disableDots, nextButton, prevButton, setIsOpen, hideButtons, hideDots, disableAll, rtl, Arrow})  => {
    return (
      <components.Navigation
      styles={styles}
       setCurrentStep = {setCurrentStep}
       steps = {steps}
       currentStep = {currentStep}
        disableDots = {disableDots}
        nextButton = {nextButton}
        prevButton = {prevButton}
        setIsOpen = {setIsOpen}
        hideButtons = {hideButtons}
        hideDots = {true}
       disableAll = {disableAll}
        rtl = {rtl}
       Arrow = {Arrow}
      >
      
      </components.Navigation>
    )
  }

  
  const handleOnClickMask = (clickProps) => {
    if (clickProps.currentStep < clickProps.steps.length) {
      clickProps.setCurrentStep(clickProps.currentStep + 1);
    } else {
      clickProps.setIsOpen(false);
    }
  };

  export {Close,Navigation, handleOnClickMask}