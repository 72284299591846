import React from "react";
import { Helmet } from "react-helmet-async";

export default function SEO({
  title,
  description,
  type,
  name,
  quote,
  image,
  hashtag,
  url,
}) {
  // let currentUrl =url ??  "https://www.swotknot.com" + window.location.pathname;
  let currentUrl = url ?? window.location.href;
  let _quote = quote ?? "";
  let _type = type ?? "website";
  let _title = title ?? "l3arn";
  let _name = name ?? "l3arn";
  let _image =
    image ??
    "https://swotknot.com/static/media/swotknot-logo-trsp.b2629e78020a40b823b5.png";
  let _description = description ?? "A fresh take on education.";
  let _hashtag = hashtag ?? "#l3arn";
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={_description} />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      {/* <meta property="og:type" content={_type} />
<meta property="og:title" content={_title} />
<meta property="og:description" content={_description} /> */}
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:creator" content={_name} />
      <meta name="twitter:card" content={_type} />
      <meta name="twitter:title" content={_title} />
      <meta name="twitter:description" content={_description} />
      {/* End Twitter tags */}
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="csrf_token" content="" />
      <meta property="type" content="website" />
      <meta property="url" content={currentUrl} />
      {/* <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      /> */}
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
      {/* <meta name="_token" content="" /> */}
      <meta name="robots" content="noodp" />
      <meta property="title" content={_title} />
      <meta property="quote" content={_quote} />
      <meta name="description" content={_description} />
      <meta property="image" content={_image} />
      <meta property="og:locale" key="og:locale" content="en_US" />
      <meta property="og:type" key="og:type" content={_type} />
      <meta property="og:title" key="og:title" content={_title} />
      <meta property="og:quote" content={_quote} />
      <meta property="og:hashtag" key="og:hashtag" content={_hashtag} />
      <meta property="og:image" key="og:image" content={_image} />
      <meta content="image/*" property="og:image:type" />
      <meta property="og:url" key="og:url" content={currentUrl} />
      <meta property="og:site_name" content={_name} />
      <meta
        property="og:description"
        key="og:description"
        content={_description}
      />{" "}
    </Helmet>
  );
}
