import React, { useState, useEffect } from "react";
import contentful from "../../config/contentful";
import LandingLayout from "../../components/landing/landingLayout";
import FaqListItem from "../../components/landing/faq/faqListItem";
import commonColor from "../../theme/commonColor";
import ReactPlayer from "react-player";
import Swotbutton from "../../components/common/buttons/swotbutton";
import FooterView from "../../components/common/footerView";
import "./faq.css";

const FAQ = ({ userLoggedIn = false }) => {
  const [selected, setSelected] = useState(0);
  const [videoUrl, setVideoUrl] = useState("https://youtu.be/P6ALaSbwmm8");

  const [content, setContent] = useState([]);

  const getContent = async () => {
    const response = await contentful.getEntries({
      content_type: "faq",
    });

    console.log("FAQ - get content", response);

    let faqs = [];
    response.items.forEach((item, i) => {
      if (item.fields.answer.length === 1) {
        item.fields.answer = item.fields.answer[0];
      }

      faqs.push(item.fields);
    });
    faqs = faqs.reverse();
    console.log("faqs:", faqs);
    setContent(faqs);
  };

  useEffect(() => {
    getContent();
  }, content);

  useEffect(() => { }, [selected]);

  const selectQuestion = (index) => {
    if (content[index].videos) {
      setVideoUrl(content[index].videos[0].url);
    } else {
      setVideoUrl(null);
    }
    setSelected(index);
  };

  const linkify = (text, name) => {
    const urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    let splits = text.split(urlRegex);
    splits = splits.filter(
      (e) => e && e !== "" && e !== "http" && e !== "https" && e !== "mailto"
    );
    return (
      <span>
        {splits.map((e) =>
          e.match(urlRegex) ? (
            <a
              style={{
                color: commonColor.deepGreen,
                textDecoration: "underline",
              }}
              href={e}
              target="_blank"
            >
              {name || e}
            </a>
          ) : (
            e
          )
        )}
      </span>
    );
  };
  const getAnswerView = () => {
    if (content[selected]) {
      return content[selected].videos ? (
        videoView(content[selected])
      ) : (
        <div className="p-4 text-sm">
          {Array.isArray(content[selected].answer) ? (
            <>
              <ul className="list-disc pl-4">
                {content[selected].answer.map((item, index) => (
                  <li key={index}>{linkify(item)}</li>
                ))}
              </ul>
            </>
          ) : (
            <>{linkify(content[selected].answer, content[selected].linkname)}</>
          )}
        </div>
      );
    }
  };

  const videoView = (data) => {
    console.log(data);
    return (
      <div
        className="w-full flex flex-col space-y-2 justify-start"
        style={{
          overflow: "scroll",
          height: (data?.question?.includes("BBB") || data?.question?.includes("Journey")) ? "41rem" : "30rem",
        }}
      >
        <div className="p-4 text-sm">{data?.answer}</div>
        <div className="w-full flex gap-2 flex-wrap justify-center">
          {data?.videos?.map((video, index) => (
            <Swotbutton
              key={index}
              text={video.title}
              bgColor={
                videoUrl === video.url
                  ? commonColor.deepGreen
                  : commonColor.deepPink
              }
              onClick={() => {
                setVideoUrl(video.url);
              }}
            />
          ))}
        </div>

        {videoUrl && videoPlayer(videoUrl)}
      </div>
    );
  };
  const videoPlayer = (url) => {
    return (
      // <div className="flex flex-col flex-grow w-full h-30v lg:h-50v max-w-6xl px-2 lg:min-h-80  md:min-h-96">
      <div className="flex flex-col flex-grow w-full max-w-6xl px-2">
        <div className="aspectratio" data-ratio="16:9">
          <div
            className="h-full video-player bg-black player-height-inside"
            style={{
              // boxShadow: `0 10px 25px 0 rgba(0, 0, 0, 0.6)`,
              borderRadius: 25,
              overflow: "hidden",
              // gridArea: `2 / 1 / 11 / 2`,
            }}
          >
            <ReactPlayer
              url={url}
              width={"100%"}
              height={"100%"}
              style={{
                borderRadius: 25,
              }}
              playing
              muted
              loop
              controls
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full h-full flex flex-col lg:justify-end">
      {/* <div className="w-full h-full faq-content-height flex flex-col justify-start items-center lg:flex-row lg:pb-20 lg:flex-grow"> */}
      <div className="w-full faq-content-height flex flex-col justify-start items-center lg:flex-row lg:flex-grow">
        <div className="w-full  h-full flex flex-col lg:flex-row justify-start lg:justify-center  items-center md:pt-6  lg:w-1/2 ">
          <div className="flex flex-col justify-start lg:justify-start items-center w-full lg:h-full  lg:overflow-y-scroll">
            {content.map((props, index) => (
              // <div className="w-full md:w-3/4 lg:w-1/2 px-2 md:px-4">
              <div
                key={index}
                className="w-full md:w-3/4 px-2 md:px-4"
              >
                <FaqListItem
                  text={props.question}
                  index={index}
                  selected={selected}
                  onClick={selectQuestion}
                  whiteCell={userLoggedIn}
                />
                {selected === index && (
                  <div className="lg:hidden bg-white rounded-3xl shadow-xl flex flex-col justify-center items-center py-4">
                    {getAnswerView()}
                  </div>
                )}
              </div>
            ))}
            <div className="w-full lg:hidden">
              <FooterView />
            </div>
          </div>
        </div>
        <div className="w-1/2 h-full hidden lg:flex flex-col justify-center items-center py-4">
          <div className="w-40v 2k:w-30v bg-white rounded-3xl shadow-2xl flex flex-col justify-center items-center m-4 min-h-60">
            {getAnswerView()}
          </div>
        </div>
      </div>
      {/* <div className="w-full z-10 hidden lg:block absolute bottom-0"> */}
      <div className="w-full faq-footer-height hidden lg:block bottom-0">
        <FooterView />
      </div>
    </div>
  );
};

export default FAQ;
