import React, { useEffect, useState } from "react";
import { ReactComponent as LoadingIcon } from "../../assets//icons/loading_icon.svg";
import { connect } from "react-redux";
import "./styles.css";

// import BoxLoader from "../../components/common/BoxLoader";
import MainPageWithMenu from "../../components/common/MainPageTemplate/mainPageWithMenu";
import Swotbutton from "../../components/common/buttons/swotbutton";

import useHightCalculated from "../../components/common/hooks/UseHeightCalculated";
import ReactCardFlip from "react-card-flip";
// import "./styles.css";

// Images
import infoIcon from "../../assets/images/quizCards/info-icon-m.png";
import ImproveMoodImage from "../../assets/images/mentalHealth/ImproveMood.svg";
import ManageSelfHarm from "../../assets/images/mentalHealth/ManageSelfHarm.svg";
import HealthyBody from "../../assets/images/mentalHealth/HealthyBody.svg";
import SafetyPlan from "../../assets/images/mentalHealth/SafetyPlan.svg";

const MentalHealth = ({ user }) => {
  // const hightCalculated = useHightCalculated(calculateCardHeight);

  const cardContent = [
    {
      title: "Improve your mood",
      cardBackImage: ImproveMoodImage,
      btnurl: "https://r.kokocares.org/swotknot/mood",
      backText: "",
    },
    {
      title: "Manage self-harm",
      cardBackImage: ManageSelfHarm,
      btnurl: "https://r.kokocares.org/swotknot/self-harm",
    },
    {
      title: "Create a healthy body image",
      cardBackImage: HealthyBody,
      btnurl: "https://r.kokocares.org/swotknot/body-image",
    },
    {
      title: "Build a safety plan",
      cardBackImage: SafetyPlan,
      btnurl: "https://r.kokocares.org/swotknot/safety-plan",
    },
  ];

  const Card = ({ bgImage, index }) => {
    const [isFlipped, setIsFlipped] = useState(false);

    return (
      <>
        <ReactCardFlip
          isFlipped={isFlipped}
          flipDirection="horizontal"
          className="m-4"
        >
          {/* Front Card */}
          <div className="relative card flex items-center h-full">
            <div
              className={`flex h-full flex-col items-center justify-center w-full overflow-hidden`}
            >
              {/* Front Card Text */}
              <div className="font-semibold tex-center p-2 w-2/3 ">
                {index === 0 ? (
                  <div>
                    <p className="text-2xl text-center text-mentalHealthBlue">
                      <span className="">Improve </span>
                      <span className=" text-mentalHealthPink"> YOUR </span>
                      <br />
                      <span> mood</span>
                    </p>
                  </div>
                ) : null}

                {index === 1 ? (
                  <div>
                    <p className="text-2xl text-center text-mentalHealthBlue">
                      Manage
                      <span className=" text-mentalHealthPink"> Self-harm</span>
                    </p>
                  </div>
                ) : null}

                {index === 2 ? (
                  <div>
                    <p className="text-2xl text-center text-mentalHealthBlue">
                      Create a{" "}
                      <span className=" text-mentalHealthPink">
                        healthy body{" "}
                      </span>{" "}
                      Image
                    </p>
                  </div>
                ) : null}

                {index === 3 ? (
                  <div>
                    <p className="text-2xl text-center text-mentalHealthBlue">
                      Build a{" "}
                      <span className=" text-mentalHealthPink">Safety</span>{" "}
                      Plan{" "}
                    </p>
                  </div>
                ) : null}
              </div>

              {/* Front Card Image */}
              <div className="w-full grow flex-auto flex items-end relative cardMentalHealth">
                <img className="w-full" src={bgImage} alt="bgImage" />
              </div>
            </div>
            <div className="absolute bottom-0 w-3/12" style={{ right: "-8px" }}>
              <img
                onClick={() => setIsFlipped((prev) => !prev)}
                src={infoIcon}
                className="object-contain w-full "
                alt="infoIcon"
              />
            </div>
          </div>

          {/* Back Card */}
          <div
            className="relative card w-full h-full"
            onClick={() => setIsFlipped((prev) => !prev)}
          >
            <div className="flex flex-col flex-1 py-2 lg:px-6 sm:px-4 px-6 h-full">
              <div className="flex flex-col justify-center items-center p-2 lg:px-20 xl:px-16"></div>
              <div className="flex flex-1 flex-col px-4 xl:p-0 2xl:px-8 xl:pb-0 md:pb-0 pb-0 leading-4 text-gray-700 justify-start">
                {index === 0 ? (
                  <div>
                    <p className="font-semibold mb-5 leading-5  md:text-lg text-xl">
                      Benefits of mental health can include:
                    </p>
                    <ul className="mentalHealthList mb-3 leading-5">
                      <li>Reduction in anxiety.</li>
                      <li>Improved moods.</li>
                      <li>Clearer thinking.</li>
                      <li>A greater sense of calm or inner peace.</li>
                      <li>Increased self-esteem.</li>
                      <li>Reduced risk of depression.</li>
                      <li>Improvements in relationships.</li>
                    </ul>
                    <p className="leading-5">
                      Let us help you to improve your mood, by clicking{" "}
                      <a
                        href="https://r.kokocares.org/swotknot/mood"
                        className="font-bold underline"
                        target="_blank"
                      >
                        here
                      </a>{" "}
                      for our online guidance course.
                    </p>
                  </div>
                ) : null}
                {index === 1 ? (
                  <div>
                    <p className="mb-5 leading-5">
                      “Self-kindness entails being warm and understanding toward
                      ourselves when we suffer, fail, or feel inadequate, rather
                      than flagellating ourselves with self-criticism,” write
                      Profs. Neff and Germer.
                    </p>

                    <p className=" leading-5">
                      Easier said than done? You might think so, but luckily our
                      partner Koko has a course that will help you one step at a
                      time to more self kindness. So click{" "}
                      <a
                        href="https://r.kokocares.org/swotknot/self-harm"
                        className="font-bold underline"
                        target="_blank"
                      >
                        here
                      </a>{" "}
                      to get started!
                    </p>
                  </div>
                ) : null}
                {index === 2 ? (
                  <div>
                    <p className="mb-5 leading-5">
                      Having a healthy body image means that you feel good about
                      your body--the way it looks and the way it functions. A
                      healthy body image is crucial to self-confidence,
                      self-acceptance and self-worth. If you don't have a
                      healthy body image, you're more likely to suffer form
                      eating and mood disorders. You can click{" "}
                      <a
                        href="https://r.kokocares.org/swotknot/body-image"
                        className="font-bold underline"
                        target="_blank"
                      >
                        here
                      </a>{" "}
                      to get started and understand more about you're body
                      image.
                    </p>
                  </div>
                ) : null}
                {index === 3 ? (
                  <div>
                    <p className="mb-5 leading-5">
                      A Mental Health Safety Plan is a preventative tool
                      designed to help support those who struggle with mental
                      wellbeing. It may be difficult to think clearly when you
                      feel really low or incredibly overwhelmed. It may be
                      difficult to ignore these feelings. So lets us help you to
                      create your plan{" "}
                      <a
                        href="https://r.kokocares.org/swotknot/safety-plan"
                        className="font-bold underline"
                        target="_blank"
                      >
                        here.
                      </a>{" "}
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </ReactCardFlip>
      </>
    );
  };

  return (
    <MainPageWithMenu user={user} menuSelected={"MentalHealth"}>
      <div className="w-full text-center lg:mt-4 mt-2 mb-2">
        <p className="text-white font-medium">
          At l3arn we take mental health as serious as we take your education.{" "}
          <br />
          Partering with kokocares.org, helps us to provide you with some skills
          set to keep a healthy mind.
        </p>
      </div>
      <div className="w-90v mx-auto flex flex-wrap md:flex-no-wrap justify-center">
        {cardContent.map((item, index) => (
          <div
            key={item.title}
            className="w-full max-w-sm p-4 cardHeight md:w-1/2 lg:w-1/4"
          >
            <Card
              bgImage={item.cardBackImage}
              className="mx-6"
              key={`card-${index}`}
              index={index}
            />
          </div>
        ))}
      </div>
    </MainPageWithMenu>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});

// const calculateCardHeight = (size) => {
//   let w = size.width;
//   let heightCalculated = 0;
//   let minHeight = 550;
//   if (window.innerWidth >= 2048) {
//     // 2k
//     heightCalculated = size.height * 0.9;
//   } else if (window.innerWidth > 1680) {
//     // 2.5 xl
//     heightCalculated = size.height * 0.8;
//   } else if (window.innerWidth >= 1536) {
//     // 2xl
//     heightCalculated = size.height * 0.7;
//   } else if (window.innerWidth >= 1280) {
//     // xl
//     heightCalculated = size.height * 0.7;
//   } else if (window.innerWidth >= 1024) {
//     // lg
//     heightCalculated = size.height * 0.7;
//   } else if (window.innerWidth >= 768) {
//     // md
//     heightCalculated = size.height * 0.7;
//   } else if (window.innerWidth >= 640) {
//     // sm
//     heightCalculated = size.height * 0.7;
//   } else {
//     w = 0;
//     heightCalculated = size.height * 0.8;
//     if (heightCalculated > size.width * 1.2) {
//       heightCalculated = size.width * 1.2;
//     }
//     minHeight = 0;
//   }
//   return heightCalculated < minHeight
//     ? minHeight
//     : heightCalculated > 650
//     ? 650
//     : heightCalculated;
// };

export default connect(mapStateToProps, mapDispatchToProps)(MentalHealth);
