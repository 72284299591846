import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import UserActions from "../../redux/user";
import QuizCardBox from "./quizCardBox";
import { updateQuizResult } from "../../services/api";
import {
  QuestionersAgeSelection,
  QuestionaireSet,
  calculateResult,
  getResultItem,
} from "./quizQuestionaires";

import "./learningStyleQuiz.css";
import ReactGa from "react-ga";

// const alreadyRemoved = [];
// let charactersState = db; // This fixes issues with updating characters state forcing it to use the current state and not the state that was active when the card was created.

const LearningStyleQuiz = ({ user, saveUser }) => {
  const [ageSelected, setAgeSelected] = useState(null);
  const [answers, setAnswers] = useState({});
  const [questionaire, setQuestionaire] = useState(null);
  const [questionaires, setQuestionaires] = useState([]);
  const [resultPercentage, setResultPercentage] = useState(null);
  const [result, setResult] = useState(null);

  useEffect(() => {
    ReactGa.event({
      category: "Button",
      action: "Quiz Started",
    });
    console.log("GA Quiz Started Event Trigger");
  }, []);

  const registerReloadListener = () => {
    window.onbeforeunload = function () {
      return true;
    };
  };
  const unregisterReloadListener = () => {
    window.onbeforeunload = null;
  };

  const scrollToQuiz = () => {
    const element = document.getElementById("learning-stype-quiz");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const swiped = (questionairKey, questionKey, isRightSwipe) => {
    if (questionairKey === "age_selection") {
      const qs = QuestionaireSet[questionKey];
      setAgeSelected(questionKey);
      setQuestionaires(qs);
      setQuestionaire(qs[0]);
      registerReloadListener();

   scrollToQuiz();
      return;
    }
    let updatedAnswer = answers;
    let question = questionaire.questions.filter((q) => q.key === questionKey);
    if (isRightSwipe && question.length > 0) {
      updatedAnswer[questionairKey] = {
        ...updatedAnswer[questionairKey],
        [questionKey]: question[0].questionValue,
      };
      setAnswers(updatedAnswer);
    }
  };

  const allCardsSwiped = (questionairKey) => {
    const index = questionaires.findIndex((q) => q.key === questionairKey);
    if (index === questionaires.length - 1) {
      ///Finish
      generateResult();
      unregisterReloadListener();
    } else if (index > -1 && index < questionaires.length - 1) {
      ///show next questionier
      setQuestionaire(questionaires[index + 1]);
    }
  };

  const generateResult = async () => {
    const resultP = calculateResult(ageSelected, answers);
    setResultPercentage(resultP);

    var apiBody = {
      percentage: resultP,
      age: ageSelected,
      answers: answers,
    };
    const resultItem = getResultItem(resultP);
    setResult(resultItem);
    if (user?.uid) {
      const r = await updateQuizResult(user.uid, { results: apiBody });
      console.log(r);
    }
  };

  return (
    <div id="learning-stype-quiz" className="w-full">
      {ageSelected ? (
        result ? (
          <QuizCardBox
            questionaire={result.item}
            swiped={swiped}
            allCardsSwiped={allCardsSwiped}
            cardType={"result"}
            reset={() => {
              setAgeSelected(null);
              setAnswers({});
              setQuestionaire(null);
              setQuestionaires([]);
              setResult(null);
            }}
            // preventSwipe={['left']}
          />
        ) : (
          !result &&
          questionaire && (
            <QuizCardBox
              questionaire={questionaire}
              swiped={swiped}
              allCardsSwiped={allCardsSwiped}
              cardType={`${result !== null ? "result" : "swipe"}`}
              // preventSwipe={['left']}
            />
          )
        )
      ) : (
        <QuizCardBox
          questionaire={QuestionersAgeSelection}
          swiped={swiped}
          allCardsSwiped={allCardsSwiped}
          cardType={"select"}
          // preventSwipe={['left']}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LearningStyleQuiz);
