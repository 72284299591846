export const readFile = (inputFile, callback) => {
  const reader = new FileReader();

  reader.onabort = () => console.log("file reading was aborted");
  reader.onerror = () => { 
    console.log("file reading has failed"); 
  }
  reader.onload = () => {
    // Do whatever you want with the file contents
    const binaryStr = reader.result;
    console.log("readFile - result:", binaryStr);
    callback && callback(inputFile, binaryStr);
  };
  reader.readAsArrayBuffer(inputFile);
};

export function arrayBufferToB64(arrayBuffer, type) {
  const b64str = btoa(
    new Uint8Array(arrayBuffer).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ""
    )
  );
  const prefix = `data:image/${type};base64, `;
  return `${prefix}${b64str}`;
}
export function b64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}
