import RenderContentfulDocument from "../../../components/common/contentful/renderDocument";
import { useSpring, animated, easings } from "@react-spring/web";

function BlogPopup({ image, title, text, onClose }) {
  const backdrop = useSpring({
    from: { opacity: 0 },
    to: {
      opacity: 1,
      config: {
        easing: easings.easeInOutBack,
        duration: 1000,
      },
    },
  });

  const modal = useSpring({
    from: { opacity: 0, scale: 0.8, y: "50px" },
    to: { opacity: 1, scale: 1, y: "0px", config: { duration: 750} },

  });

  return (
    <animated.div
      className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-500"
      style={backdrop}
      onClick={onClose}
    >
      <animated.div
        style={modal}
        className="bg-white rounded-lg shadow-lg w-11/12 lg:w-8/12 h-[85vh] md:h-[95vh]"
      >
        <div className="flex flex-col items-center justify-center h-full">
          <div className="w-full h-2/5">
            <img
              src={image}
              alt={image}
              className="object-cover w-full h-full rounded-t-lg md:rounded-l-lg"
            />
          </div>
          <div className="w-full h-auto flex-col items-center justify-center py-6 px-10 md:px-14 overflow-y-scroll ">
            <div className="text-2xl font-bold text-gray-600 text-center mb-6 mt-2">
              {title}
            </div>
            <p className="text-base md:text-lg text-gray-500 text-center px-2">
              {RenderContentfulDocument(text)}
            </p>
          </div>
        </div>
      </animated.div>
    </animated.div>
  );
}

export default BlogPopup;
