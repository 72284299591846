import React, { useEffect, useState } from "react";
import Swotbutton from "../../components/common/buttons/swotbutton";
import ChatUserModel from "../models/chatUser";
import { ChatService } from "../services/chatService";
import ChatUserWidget from "./ChatUserWidget";
import { SelectedUserWidget } from "./ChatSearchComponent";

const NewGroupChatComponent = ({
  members,
  myUserId,
  close,
}: {
  members: ChatUserModel[];
  myUserId: string;
  close: () => void;
}) => {
  const chatService = new ChatService();
  const [title, setTitle] = useState<string>();
  const [creatingGroup, setCreatingGroup] = useState<boolean>();
  const [chatMembers, setChatMembers] = useState<ChatUserModel[]>([]);

  useEffect(() => {
    setChatMembers(members);
  }, [members]);

  const createGroup = async () => {
    // close && close();
    if (
      !creatingGroup &&
      title &&
      title.trim().length > 0 &&
      chatMembers.length > 0
    ) {
      setCreatingGroup(true);
      const result = await chatService.createGroup(myUserId, members, title);
      setCreatingGroup(false);
      if (result) {
        setTitle("");
        close && close();
      }
    }
  };

  return (
    <div className="w-full  p-2">
      <div className="w-full flex-col justify-start">
        <div className="w-full">Title:</div>
        <div className="w-full">
          <input
            className="w-full bg-gray-300 appearance-none border-2 focus:outline-none focus:border-opacity-100 rounded-lg"
            value={title}
            placeholder={"title.."}
            onChange={(event) => {
              setTitle(event.target.value);
            }}
          />
        </div>
        <div>Members:</div>
        <div className="w-full max-h-50v flex flex-col overflow-y-scroll">
          {chatMembers.map((m) => {
            return (
              <SelectedUserWidget
              key={m.uid}
                userItem={m}
                pop={(id) => {
                  const items = chatMembers.filter((item) => item.uid !== id);
                  setChatMembers(items);
                }}
              />
            );
          })}
        </div>
        <div className="w-full flex flex-row justify-between sm:justify-end p-2">
          <Swotbutton
            text="Cancel"
            style={{
              backgroundColor: "rgb(134 127 129)",
            }}
            onClick={() => {
              close && close();
            }}
          />
          <Swotbutton
            text="Create"
            className="ml-2"
            disabled={!(title && title.trim().length > 0 && chatMembers.length > 0)}
            loading={creatingGroup}
            onClick={() => {
              createGroup();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NewGroupChatComponent;
