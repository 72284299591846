import { useState, useEffect } from "react";
import LandingLayout from "../../components/landing/landingLayout";
import contentful from "../../config/contentful";
import { connect } from "react-redux";
import { ReactComponent as ShareIcon } from "../../assets/icons/share-icon-blog.svg";
import { ReactComponent as HeartIcon } from "../../assets/icons/heart-icon.svg";
import { ReactComponent as ChatIcon } from "../../assets/icons/chat-icon.svg";
import ShareModal from "../../components/common/share/shareModal";
import { useUserAuth } from "../../services/userManagement";
import RenderContentfulDocument from "../../components/common/contentful/renderDocument";
import SEO from "../../components/common/share/SEO";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import learnlogo from "../../assets/images/learn-logo-colour.svg";
import Button from "../newLanding/components/landingpageButton";
import { TermsAndPrivacyPolicy } from "../../components/common/consent";
import UserActions from "../../redux/user";

import "./styles.css";

// import HelmetMetaData from "../../components/common/share/HelmetMetaData";

const BlogDetail = ({ user }) => {
  //TODO: read params. If user is loggedIn, show the loggedIn version of blog page
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState();
  const [openShare, setOpenShare] = useState(false);
  const [shareSubject, setShareSubject] = useState();
  const [shareMessage, setShareMessage] = useState(
    "Look at what I found! I am sure you will love this course."
  );
  const [shareMedia, setShareMedia] = useState();

  const params = useParams();

  const authUser = useUserAuth(null);
  const received = useLocation().state;
  const navigate = useNavigate();
  const isLoggedIn = () => {
    return user !== null && authUser !== null;
  };

  useEffect(() => {
    getContent();
  }, []);

  const getContent = async () => {
    try {
      setLoading(true);
      const response = await contentful.getEntries({
        content_type: "blogPost",
        "sys.id": params.blogId,
      });

      console.log("blogPost", response);

      if (response && response.items && response.items.length > 0) {
        setContent(response.items[0]);

        const item = response.items[0];
        const blogTitle = item.fields?.title;
        const blogMediaUrl = item.fields?.blogMedia[0]?.fields?.file?.url;
        setShareSubject(blogTitle);
        setShareMedia(blogMediaUrl);
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const renderContent = (item) => {
    if (!item) {
      return <></>;
    }
    console.log("Swotnews - found item", item);
    const blogTitle = item.fields?.title;
    const blogMediaUrl = item.fields?.blogMedia[0]?.fields?.file?.url;

    //let renderedBody = documentToReactComponents(item.fields?.blogBody);
    // console.log(item);
    let renderedBody = RenderContentfulDocument(item.fields?.blogBody);

    return (
      <div className="w-full flex flex-col justify-start ">
        <div
          key={item.sys.id}
          className="w-full flex flex-col overflow-hidden justify-start m-4"
        >
          {blogTitle && (
            <div className=" px-3 py-4 text-xl font-bold w-full flex flex-col overflow-hidden justify-start items-center">
              {blogTitle}
            </div>
          )}

          {blogMediaUrl && (
            <div className="w-full flex justify-center items-center overflow-hidden">
              <img
                src={blogMediaUrl}
                loading="lazy"
                alt="l3arn"
                className="w-full object-cover max-h-50v"
                style={{
                  // width: "100%",
                  overflow: "hidden",
                  // maxHeight: "50%",
                  // minHeight: "30%"
                }}
              />
            </div>
          )}
          <div className="blogDetail mt-6">{renderedBody}</div>
          {item.fields.source && (
            <div
              className="w-full flex justify-between space-x-2 items-center py-2 px-3"
              style={{
                // width: "100%",
                //overflow: "hidden",
                maxHeight: "2.5rem",
                //minHeight: "30%"
              }}
            ></div>
          )}
        </div>
      </div>
    );
  };

  const shareInfo = () => {
    // setShareSubject("I share a blog.");
    setOpenShare(true);
  };

  const detailsBody = (navigate) => (
    <div className="w-full h-full flex flex-col justify-start items-center sm:pl-24 sm:pr-10 pl-1 max-w-screen-2k overflow-scroll">
      <SEO
        // title={content?.fields?.title}
        type="article"
        image={shareMedia}
      />
      {/* <BlogFeed /> */}
      <div className="w-full flex justify-start items-start">
        <Link
          className="focus:outline-none"
          to="/blogs"
          onClick={() => {
            if (received == "blogs") {
              navigate(-1);
            } else {
              navigate("/blogs");
            }
          }}
        >
          <div className=" flex justify-start items-center space-x-4">
            {/* <BackIcon className="h-8 w-8 font-bold text-lg" style={{}} />{" "} */}
            <p className="font-bold" style={{ color: "rgb(3 105 161)" }}>
              Back to Blogs
            </p>
          </div>
        </Link>
      </div>
      {renderContent(content)}
      {content && (
        <div className="w-full  flex justify-end items-center space-x-4">
          <ShareIcon
            className="h-6 w-6 lg:h-8 lg:w-8 cursor-pointer"
            style={{}}
            onClick={() => {
              shareInfo();
            }}
          />
          {isLoggedIn() && (
            <>
              <ChatIcon
                className="h-6 w-6 lg:h-8 lg:w-8 cursor-pointer"
                style={{}}
                onClick={() => {}}
              />
              <HeartIcon
                className="h-6 w-6 lg:h-8 lg:w-8 cursor-pointer"
                style={{}}
                onClick={() => {}}
              />
            </>
          )}
        </div>
      )}
      {/* <div className="flex-grow"></div> */}

      <ShareModal
        subject={shareSubject}
        message={shareMessage}
        url={window.location.href}
        open={openShare}
        onRequestClose={() => {
          setOpenShare(false);
        }}
      />
    </div>
  );

  return (
    <div className="">
      <div class="text-gray-600 body-font border-b-2 border-b-pink-800 w-11/12 mx-auto">
        <div class="flex flex-wrap p-5 flex-col md:flex-row items-center justify-between w-full">
          <a href="/">
            <img className="w-40 h-auto" src={learnlogo} alt="l3arn-logo" />
          </a>
          <p className="text-gray-500 text-3xl">Blog Details</p>
          <Button link="/sign-in" />
        </div>
      </div>

      {/* actual blogs */}
      <div className="w-full h-full flex flex-wrap justify-around sm:pl-10 sm:pr-10 pl-1 max-w-screen-2k">
        {detailsBody(navigate)}
      </div>
      <div className="text-center py-4">
        <TermsAndPrivacyPolicy />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
    saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BlogDetail);
