import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import FileViewer from "react-file-viewer";
import ReactPlayer from "react-player/lazy";
import { ReactComponent as Photofile } from "../../../assets/icons/portraits.svg";
import { ReactComponent as Resume } from "../../../assets/icons/resume-and-cv.svg";
import { ReactComponent as VideoIntro } from "../../../assets/icons/phone-camera.svg";
import commonColor from "../../../theme/commonColor";
import Modal from "../../common/modal/modal";
import { readFile } from "../../../utils/fileUtils";
import CircularProgressBar from "../../../components/common/CircularProgressBar/CircularProgressBar";
import imageCompression from "browser-image-compression";

import {
  uploadFilePromise,
} from "../../../services/fileServices";
import {
  getTutorProfileDocuments,
  updateTutorProfileDocuments,
} from "../../../services/api.js";
import { updateUser } from "../../../services/userManagement";
import Swal from "sweetalert2";
import WebcamWidget from "./webcamWidget";


const AvailableDocuments = {
  Photofile: "Photofile",
  Resume: "Resume",
  VideoIntro: "VideoIntro",
};

const TutorDocuments = ({ user, saveUser, viewOnly }) => {
  const [photoError, setPhotoError] = useState(false);
  const [photoOK, setPhotoOK] = useState(false);
  const [photoUploadProgress, setPhotoUploadProgress] = useState(0);
  const [photoUploadError, setPhotoUploadError] = useState(false);
  const [resumeError, setResumeError] = useState(false);
  const [resumeUploadError, setResumeUploadError] = useState(false);
  const [resumeOK, setResumeOK] = useState(false);
  const [resumeUploadProgress, setResumeUploadProgress] = useState(0);
  const [videoError, setVideoError] = useState(false);
  const [videoUploadError, setVideoUploadError] = useState(false);
  const [videoOK, setVideoOK] = useState(false);
  const [videoUploadProgress, setVideoUploadProgress] = useState(0);
  const [info, setInfo] = useState();
  const [documentClicked, setdocumentClicked] = useState(null);

  useEffect(() => {
    console.log("TutorDocuments - useEffect - info updated", info);
    if (!info) {
      getTutorDocs();
    }
  }, [info, user.uid]);

  const getTutorDocs = async () => {
    const result = await getTutorProfileDocuments(user.uid);
    // const result = { data: {
    //   introduceVideoFileType: "pdf",
    //   introduceVideoUrl: "https://firebasestorage.googleapis.com/v0/b/swotknot-dev.appspot.com/o/userData%2FWMYyHIsBedNXWHdHrNEuGzwqfGC2%2Fprofile%2FvideoIntro.jpg?alt=media&token=c599437d-6133-45b7-8b79-8f295f92174e",
    //   photoFileType: "jpg",
    //   photoUrl: "https://firebasestorage.googleapis.com/v0/b/swotknot-dev.appspot.com/o/userData%2FWMYyHIsBedNXWHdHrNEuGzwqfGC2%2Fprofile%2Fprofile_photo.jpg?alt=media&token=c599437d-6133-45b7-8b79-8f295f92174e",
    //   resumeFileType: "pdf",
    //   resumeUrl: "https://firebasestorage.googleapis.com/v0/b/swotknot-dev.appspot.com/o/userData%2FWMYyHIsBedNXWHdHrNEuGzwqfGC2%2Fprofile%2Fresume.pdf?alt=media&token=c599437d-6133-45b7-8b79-8f295f92174e"
    // }}
    if (result) {
      console.log(
        "TutorDocuments - useEffect - got tutor profile docs",
        result.data
      );
      setInfo(result.data);
    }
  };

  const uploadPhoto = async (photo, file) => {
    // upload photo to firestore:
    try {
      const result = await uploadFilePromise(
        file,
        photo.type,
        photo.path,
        `profile_photo`,
        `userData/${user.uid}/profile`,
        (progress) => {
          setPhotoUploadProgress(Math.floor(progress));
        }
      );
      setPhotoUploadProgress(0);

      console.log("handleProfilePhotoInput - upload result:", result);
      setPhotoOK(true);
      setPhotoUploadError(false);
      let payload = {
        ...info,
      };
      payload.photoUrl = result.downloadUrl;
      payload.photoFileType = result.fileType;
      console.log("updateTutorProfileDocuments - payload", payload);
      setInfo(payload);
      updateTutorProfileDocuments(user.uid, payload);
      updateUser({...user, uid: user.uid, photoUrl: payload.photoUrl }, true);
      if (saveUser && user) {
        const updatedUser = { ...user, ...payload };
        saveUser(updatedUser);
      }
    } catch (e) {
      console.error("handleProfilePhotoInput - uploading error", e);
      setPhotoUploadError(true);
      setPhotoOK(false);
    }
  };

  const uploadCV = async (cv, file) => {
    try {
      const result = await uploadFilePromise(
        file,
        cv.type,
        cv.path,
        `resume`,
        `userData/${user.uid}/profile`,
        (progress) => {
          setResumeUploadProgress(Math.floor(progress));
        }
      );
      setResumeUploadProgress(0);
      console.log("uploadCV - upload result:", result);
      setResumeOK(true);
      setResumeUploadError(false);
      let payload = {
        ...info,
      };

      setInfo(payload);
      payload.resumeUrl = result.downloadUrl;
      payload.resumeFileType = result.fileType;
      console.log("updateTutorProfileDocuments - payload", payload);
      setInfo(payload);
      updateTutorProfileDocuments(user.uid, payload);
    } catch (e) {
      console.error("uploadCV - uploading error", e);
      setResumeUploadError(true);
      setResumeOK(false);
    }
  };

  const uploadVideo = async (video, file) => {
    try {
      const result = await uploadFilePromise(
        file,
        video.type,
        video.path,
        `videoIntro`,
        `userData/${user.uid}/profile`,
        (progress) => {
          setVideoUploadProgress(Math.floor(progress));
        },
        "mp4"
      );
      setVideoUploadProgress(0);

      console.log("uploadVideo - upload result:", result);
      setVideoOK(true);
      setVideoUploadError(false);
      let payload = {
        ...info,
      };
      payload.introduceVideoUrl = result.downloadUrl;
      payload.introduceVideoFileType = result.fileType;
      console.log("updateTutorProfileDocuments - payload", payload);
      setInfo(payload);
      updateTutorProfileDocuments(user.uid, payload);
    } catch (e) {
      console.error("uploadVideo - uploading error", e);
      setVideoUploadError(true);
      setVideoOK(false);
    }
  };

  const handleProfilePhotoInput = async (acceptedFiles) => {
    console.log("handleProfilePhotoInput - input files", acceptedFiles);
    let toBeUploaded = acceptedFiles[0];
    if (toBeUploaded.type.includes("image")) {
      setPhotoError(false);

      console.log(
        "handleProfilePhotoInput - file is Photo, OK, will read file."
      );

      //compress profile if required
      const imgSize = toBeUploaded.size / 1024 / 1024;

      if (imgSize > 5) {
        //if file is above max required file, prompt to user and return
        Swal.fire({
          title: "Max Size Limit",
          text: "You can not upload image above 5MB.",
          confirmButtonText: "OK",
        });
        return;
      }

      const maxSizeMB = 1;
      if (imgSize > maxSizeMB) {
        var options = {
          maxSizeMB: maxSizeMB,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(toBeUploaded, options);
        console.log(
          `compressed profileImage size = ${compressedFile.size / 1024 / 1024}`
        );
        compressedFile.path = toBeUploaded.path;
        toBeUploaded = compressedFile;
      }

      readFile(toBeUploaded, uploadPhoto);
    } else {
      setPhotoError(true);
      setPhotoOK(false);
      console.log("handleProfilePhotoInput - file is NOT Photo, ERROR");
    }
  };

  const handleCVInput = (acceptedFiles) => {
    console.log("handleCVInput - input files", acceptedFiles);
    const toBeUploaded = acceptedFiles[0];
    if (toBeUploaded.type.includes("pdf")) {
      setResumeError(false);
      const resumeSizeMB = toBeUploaded.size / 1024 / 1024;
      if (resumeSizeMB > 5) {
        //if file is above max required file, prompt to user and return
        Swal.fire({
          title: "Max Size Limit",
          text: "You can not file above 5MB.",
          confirmButtonText: "OK",
        });
        return;
      }

      console.log("handleCVInput - file is document, OK, will read file.");
      readFile(toBeUploaded, uploadCV);
    } else {
      setResumeError(true);
      setResumeOK(false);
      console.log("handleCVInput - file is NOT document, ERROR");
    }
  };

  const handleVideoIntroInput = (acceptedFiles) => {
    console.log("handleVideoIntroInput - input files", acceptedFiles);
    const toBeUploaded = acceptedFiles[0];
    if (toBeUploaded.type.includes("video")) {
      setVideoError(false);
      console.log("handleVideoIntroInput - file is video, OK, will read file.");
      const imgSizeMB = toBeUploaded.size / 1024 / 1024;
      if (imgSizeMB > 30) {
        //if file is above max required file, prompt to user and return
        Swal.fire({
          title: "Max Size Limit",
          text: "You can not upload video above 30MB.",
          confirmButtonText: "OK",
        });
        return;
      }
      readFile(toBeUploaded, uploadVideo);
    } else {
      setVideoError(true);
      setVideoOK(false);
      console.log("handleVideoIntroInput - file is NOT video, ERROR");
    }
  };

  const handleCapturedVideo = (videoBlob) => {
    if (videoBlob.type.includes("video")) {
      setVideoError(false);
      console.log("handleVideoIntroInput - file is video, OK, will read file.");
      const imgSizeMB = videoBlob.size / 1024 / 1024;
      if (imgSizeMB > 30) {
        //if file is above max required file, prompt to user and return
        Swal.fire({
          title: "Max Size Limit",
          text: "You can not upload video above 30MB.",
          confirmButtonText: "OK",
        });
        return;
      }
      readFile(videoBlob, uploadVideo);
    } else {
      setVideoError(true);
      setVideoOK(false);
      console.log("handleVideoIntroInput - file is NOT video, ERROR");
    }
  };

  const renderPhotoBorderColor = () => {
    if (photoError || photoUploadError) {
      return commonColor.error;
    } else if (photoOK) {
      return commonColor.correct;
    } else {
      return commonColor.deepGreen;
    }
  };

  const renderCVBorderColor = () => {
    if (resumeError || resumeUploadError) {
      return commonColor.error;
    } else if (resumeOK) {
      return commonColor.correct;
    } else {
      return commonColor.deepGreen;
    }
  };

  const renderVideoBorderColor = () => {
    if (videoError || videoUploadError) {
      return commonColor.error;
    } else if (videoOK) {
      return commonColor.correct;
    } else {
      return commonColor.deepGreen;
    }
  };

  const onError = (e) => {
    console.log(e, "error in file-viewer");
  };

  return (
    <div className="h-full w-full flex flex-col justify-around">

      <Modal
        isOpen={!!documentClicked}
        onRequestClose={() => {
          setdocumentClicked(null);
        }}
        setMaxSize={true}
      >
        {documentClicked === AvailableDocuments.Photofile ? (
          <div className="w-90v md:w-70v lg:w-50v h-80v text-center flex flex-col overflow-y-scroll">
            <div className="mt-auto mb-auto">
              <img
                src={info?.photoUrl ? info.photoUrl : user.photoUrl}
                alt="InfoIcon"
                className="m-auto"
              />
            </div>
          </div>
        ) : documentClicked === AvailableDocuments.Resume && info?.resumeUrl ? (
          // <div className="w-full h-full text-center">
          <div className="w-90v md:w-70v lg:w-50v h-80v text-center">
            <FileViewer
              fileType={
                info.resumeFileType !== "undefined" && info.resumeFileType
                  ? info.resumeFileType
                  : "pdf"
              }
              filePath={info.resumeUrl}
              onError={onError}
            />
          </div>
        ) : documentClicked === AvailableDocuments.VideoIntro ? (
          // <div className="w-full h-full flex justify-center">
          <div className="w-90v md:w-70v lg:w-50v h-80v text-center">
            <ReactPlayer
              url={info.introduceVideoUrl}
              width={"100%"}
              height={"100%"}
              onError={onError}
              playing
              autoPlay
              controls = {true}
            />
          </div>
        ) : null}
      </Modal>
      {photoError && (
        <div className="text-sm bg-red-500 w-full text-white text-center mb-1">
          {"Photo upload only accepts .png or .jpg files."}
        </div>
      )}
      {photoUploadError && (
        <div className="text-sm bg-red-500 w-full text-white text-center mb-1">
          {"Photo upload error, please try again."}
        </div>
      )}

      {resumeError && (
        <div className="text-sm bg-red-500 w-full text-white text-center mb-1">
          {"Resume upload only accepts only .pdf files."}
        </div>
      )}
      {resumeUploadError && (
        <div className="text-sm bg-red-500 w-full text-white text-center mb-1">
          {"Resume upload error, please try again."}
        </div>
      )}

      {videoError && (
        <div className="text-sm bg-red-500 w-full text-white text-center mb-1">
          {"Video upload only accepts video files."}
        </div>
      )}
      {videoUploadError && (
        <div className="text-sm bg-red-500 w-full text-white text-center mb-1">
          {"Video upload error, please try again."}
        </div>
      )}

      <div className="w-full flex flex-col md:flex-row justify-around">
        <div className="flex flex-col justify-between md:max-w-1/4 flex-1">
          <div className="m-auto w-full flex justify-center shadow-2xl max-h-2/3 overflow-y-scroll">
            {photoUploadProgress > 0 ? (
              <div className="flex items-center">
                <CircularProgressBar
                  strokeWidth="5"
                  sqSize="100"
                  percentage={photoUploadProgress}
                />
              </div>
            ) : (
              <div
                className={`flex items-center ${
                  (info?.photoUrl || user.photoUrl) && "cursor-pointer"
                }`}
              >
                <img
                  onClick={() => {
                    setdocumentClicked(AvailableDocuments.Photofile);
                  }}
                  src={info?.photoUrl ? info.photoUrl : user.photoUrl}
                  alt="DocumentsIcon"
                  className="contain w-1/2 md:w-full m-auto"
                />
              </div>
            )}
          </div>
          {!viewOnly && (
            <div className="h-auto md:h-1/3 flex justify-center">
              <div className="flex flex-col justify-center h-32 items-center pt-8 md:pt-0">
                <div className="text-xs pb-2">{"Update your photo"}</div>
                <Dropzone onDrop={handleProfilePhotoInput}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div
                        className="w-32 flex flex-col items-center cursor-pointer focus:outline-none"
                        {...getRootProps()}
                      >
                        <Photofile
                          className="self-center"
                          style={{
                            fill: commonColor.deepGreen,
                            backgroundColor: "white",
                            height: "3rem",
                            width: "2.5rem",
                            aspectRatio: 1,
                            // marginBottom: "-1rem",
                            // zIndex: 10,
                          }}
                        />
                        <input
                          className="focus:outline-none"
                          {...getInputProps()}
                        />
                        <div className="text-xs text-center w-full">
                          {/* {photoOK && "Done!"} */}
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col justify-between md:max-w-1/4 flex-1">
          <div className="m-auto w-full flex justify-center shadow-2xl max-h-2/3 overflow-y-scroll h-48 2xl:h-72">
            <div
              className={`flex flex-col justify-center items-center ${
                info?.resumeUrl && "cursor-pointer"
              } overflow-y-scroll`}
            >
              {/* {info?.resumeUrl && (
            <iframe
              title="CV"
              src={`https://docs.google.com/gview?url=${info.resumeUrl}&embedded=true`}
            />
          )} */}
              {/* <Document
            //file={info?.resumeUrl}
            file={
              "https://firebasestorage.googleapis.com/v0/b/swotknot-dev.appspot.com/o/userData%2F0hwn54JsXwR9ri5f0oelry2qldp2%2Fprofile%2Fresume.pdf?alt=media&token=04b1d8df-e2a2-45df-8433-8c335428189a"
            }
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />
          </Document>
          <p>
            Page {pageNumber} of {numPages}
          </p> */}

              {resumeUploadProgress > 0 ? (
                <div className="flex items-center">
                  <CircularProgressBar
                    strokeWidth="5"
                    sqSize="100"
                    percentage={resumeUploadProgress}
                  />
                </div>
              ) : (
                info?.resumeUrl && (
                  <div
                    onClick={() => {
                      console.log(AvailableDocuments.Resume, info.resumeUrl);
                      setdocumentClicked(AvailableDocuments.Resume);
                    }}
                  >
                    <FileViewer
                      fileType={"pdf"}
                      filePath={info.resumeUrl}
                      onError={onError}
                      errorComponent={() => (
                        <div>Error with selected file.</div>
                      )}
                    />
                  </div>
                )
              )}
            </div>
          </div>
          {!viewOnly && (
            <div className="h-auto md:h-1/3 flex justify-center">
              <div className="flex flex-col justify-center h-32 items-center pt-8 md:pt-0">
                <div className="text-xs pb-2">{"Update your resume"}</div>
                <Dropzone onDrop={handleCVInput}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div
                        className="w-32 flex flex-col items-center cursor-pointer focus:outline-none"
                        {...getRootProps()}
                      >
                        <Resume
                          className="self-center"
                          style={{
                            fill: commonColor.deepPink,
                            backgroundColor: "white",
                            height: "3rem",
                            width: "2.5rem",
                            aspectRatio: 1,
                            //marginBottom: "-1rem",
                            // zIndex: 10,
                          }}
                        />
                        <input
                          className="focus:outline-none"
                          {...getInputProps()}
                        />
                        <div className="text-xs text-center w-full">
                          {resumeOK && "Done!"}
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col justify-between md:max-w-1/4 flex-1">
          <div className="m-auto w-full flex justify-center shadow-2xl max-h-2/3 overflow-y-scroll">
            {videoUploadProgress > 0 ? (
              <div className="flex items-center">
                <CircularProgressBar
                  strokeWidth="5"
                  sqSize="100"
                  percentage={videoUploadProgress}
                />
              </div>
            ) : (
              info?.introduceVideoUrl && (
                <div
                  onClick={() => {
                    setdocumentClicked(AvailableDocuments.VideoIntro);
                  }}
                >
                  <ReactPlayer
                    url={info.introduceVideoUrl}
                    light={true}
                    width={"100%"}
                    height={"100%"}
                    onError={onError}
                    controls = {true}
                  />
                </div>
              )
            )}
            {/* {info?.introduceVideoUrl && (
            <FileViewer
              fileType={
                info.introduceVideoFileType
                  ? info.introduceVideoFileType
                  : "mp4"
              }
              filePath={info.introduceVideoUrl}
              onError={onError}
            />
          )} */}
          </div>
          {!viewOnly && (
            <div className="h-auto md:h-1/3 flex justify-center">
              <div className="flex flex-col justify-center h-32 items-center pt-8 md:pt-0">
                <div className="text-xs pb-2">{"Update your video"}</div>
                <Dropzone onDrop={handleVideoIntroInput}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div
                        className="w-32 flex flex-col items-center cursor-pointer focus:outline-none"
                        {...getRootProps()}
                      >
                        <VideoIntro
                          className="self-center"
                          style={{
                            fill: commonColor.sourCherry,
                            backgroundColor: "white",
                            height: "3rem",
                            width: "2.5rem",
                            aspectRatio: 1,
                            // marginBottom: "-1rem",
                            // zIndex: 10,
                          }}
                        />
                        <input
                          className="focus:outline-none"
                          {...getInputProps()}
                        />
                        <div className="text-xs text-center w-full">
                          {videoOK && "Done!"}
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};


export default TutorDocuments;
