import { CURRENT_RELEASE_MODE, ReleaseMode } from "./constants";

export const saveUserAccount = "action/save-account";

export const setupTutor = (firebaseId) => `account/tutor/${firebaseId}/setup`;

export const deleteAccountRequest = (firebaseId) => `account/delete/request/${firebaseId}`;

export const deleteUser = (firebaseId) => `account/user/delete/${firebaseId}`;

export const getContentTypeOptions = (isCurriculum) =>
  `action/tutoring/curriculum/${isCurriculum}/subjects/areas`;

export const moodleSSOLogin = (email) => `account/${email}/moodle/sso`;

export const resendVerificationEmail = () => `account/verify/resend`;

export const topupWallet = (userId) => `/account/${userId}/swotwallet/top-up`;

export const createStripeAccount = (userId) =>
  `/action/stripe/connected-account/${userId}/update`;

export const confirmWalletTopUp = (userId) =>
  `/account/${userId}/swotwallet/top-up/confirm`;

export const tutorPreferences = (userId) =>
  `/account/tutors/${userId}/preferences`;

export const createCourse = (userId) =>
  `/action/tutor/${userId}/courses/create`;

export const editCourse = (courseId) => `/action/courses/${courseId}/edit`;

export const deleteCourse = (courseId) => `/action/courses/${courseId}`;

export const getCourseListOfTutor = (userId) =>
  `/account/tutors/${userId}/courses`;

export const getCourseDetails = (courseId) => `/account/courses/${courseId}`;

export const getAllCourses = "account/courses/get-all";

export const getCourseExists = (userId) =>
  `/action/tutor/${userId}/course/exist`;

export const getAllCurseContent = (courseId) =>
  `/action/course/allcontent/${courseId}`;

export const courseContent = () => `/action/course/content`;

export const courseContentById = (contentId) =>
  `/action/course/content/${contentId}`;

export const getCourseContents = () => `/action/course/content`;

export const createTutorAvailabilitySlots = (userId) =>
  `/action/tutor/${userId}/availability-slot`;

export const renameTutorAvailabilitySlots = (userId) =>
  `/action/tutor/${userId}/availability-slot/update`;


export const createTutorBulkSlots = () =>
  `/action/add/bulk/slots`;

export const getTutorAvailabilitySlots = (userId) =>
  `/account/tutor/${userId}/availability-slots`;

export const getSessionDetailsTutor = (sessionId) =>
  `/account/tutor/session/${sessionId}/details`;

export const getStudentsForTutor = (tutorId) =>
  `/account/tutor/${tutorId}/students`;

export const setTutorApprovalStatus = (tutorId) =>
  `/action/backoffice/tutor/${tutorId}/approve`;

export const setNgoUser = (tutorId) =>
  `/action/backoffice/tutor/${tutorId}/ngo`;

export const getStudentQuiz = (studentId) =>
  `/account/student/${studentId}/quiz`;

export const postStudentQuiz = (studentId) =>
  `/action/student/${studentId}/quiz/save`;

export const getTutorQuiz = (tutorId) => `/account/tutor/${tutorId}/quiz`;

export const postTutorQuiz = (tutorId) => `/action/tutor/${tutorId}/quiz/save`;

export const getQuizResults = (userId) => `/account/${userId}/quiz/results`;

export const findTutors = (userId) => `/action/student/${userId}/tutor/search`;

export const searchTutors = (searchquery) => `/action/tutor/search/${searchquery}`;

export const getFeaturedTutors = () => `/action/tutor/featured/list`;

export const getTutorAbout = (tutorId) => `/account/tutor/${tutorId}/about`;

export const getOpenCoursesForStudent = (studentId) =>
  `/account/student/${studentId}/courses/open`;

export const getTutorCoursesWithLatestSession = (tutorId) =>
  `/account/tutor/${tutorId}/courses/calendar`;

export const getStudentEnrolledCourses = (studentId) =>
  `/account/student/${studentId}/courses/enrolled`;

export const bookCourseSession = (studentId) =>
  `/action/student/${studentId}/session/book`;

export const getCourseStudentCalendarView = (courseId, studentId) =>
  `/account/student/${studentId}/courses/${courseId}/sessions/calendar`;

export const getCourseCalendarView = (courseId) =>
  `/account/courses/${courseId}/sessions/calendar`;

export const getTutorPersonalInfo = (tutorId) =>
  `/account/tutor/${tutorId}/personal-info`;

export const getTutoringInfo = (tutorId) =>
  `/account/tutor/${tutorId}/tutoring-info`;

export const getTutorProfileDocuments = (tutorId) =>
  `/account/tutor/${tutorId}/documents`;

export const getTutorTeachingPercentages = (tutorId) =>
  `/account/tutor/${tutorId}/learning-styles`;

export const updateTutorPersonalInfo = (tutorId) =>
  `/action/tutor/${tutorId}/personal-info`;

export const updateTutorTutoringInfo = (tutorId) =>
  `/action/tutor/${tutorId}/tutoring-info`;

export const updateTutorProfileDocuments = (tutorId) =>
  `/action/tutor/${tutorId}/documents`;

export const getAllStates = "/states/get-all";

export const getCitiesForState = (state) => `/states/${state}/get-cities`;

export const getUserSettings = (userId) => `/account/${userId}/settings`;

export const updateUserSettings = (userId) => `/action/${userId}/settings`;

export const createRoomForSession = (tutorId) =>
  `/action/tutor/${tutorId}/session/room/create`;

export const studentJoinVideoSession = (userId, sessionId) =>
  `/account/student/${userId}/session/${sessionId}/url`;

export const tutorJoinVideoSession = (sessionId) =>
  `/account/tutor/session/${sessionId}/url`;

export const enrollJoinWebinar = (userId) =>
  `/action/ada/student/${userId}/webinar/enroll/join`;

export const getSessionEnrolledDetails = (sessionId) =>
  `/account/session/${sessionId}/enrolled/details`;

export const updateStudentDocuments = (userId) =>
  `action/student/${userId}/documents`;

export const updateQuizResult = (userId) => `action/student/quiz/${userId}`;

export const getAllFreeCourses = `/account/courses/free/get-all`;

export const getAllTutors = "/account/tutors";

export const getAllUsers = "/account/users/list";

export const searchUsers = (name) => `/account/users/search/${name}`;

export const createWebinar = "/action/webinar/create";

export const deleteWebinar = "/action/webinar/delete";

export const stripeConnect = "/action/payment/accountLink";

export const getStripeStatus = (key) => `/action/payment/isConnect/${key}`;

export const getSubscriptionStatus = (key, userType) => `/action/subscription/${userType}/user/${key}`;

export const getTutorEarningReport = (userId) =>
  `action/payment/tutor/report/${userId}`;


export const getStudentPaymentReport = (userId) =>
  `action/payment/student/report/${userId}`;

export const getTutorPaymentTransactions = (userId) =>
  `action/payment/transaction/tutor/${userId}`;

export const getStudentPaymentTransactions = (userId) =>
  `action/payment/transaction/student/${userId}`;

export const requestRefund = (userId) =>
  `action/payment/refund/request/${userId}`;

export const getRefundRequests = () => `action/payment/refund/requests`;

export const getStripeCountriesList = () => `action/payment/countries/list`;

export const postApproveRefund = (transactionId) =>
  `action/payment/refund/approve/${transactionId}`;

export const postRejectRefund = (transactionId) =>
  `action/payment/refund/reject/${transactionId}`;

export const getCoursePaymentDetails = (courseId) =>
  `/account/courses/${courseId}/payment/detail`;

export const coursePaymentIntent = (courseId, studentId) =>
  `/action/paymentintent/course/${courseId}/student/${studentId}`;

export const courseCheckout = (courseId, studentId) =>
  `/action/checkout/course/${courseId}/student/${studentId}`;


export const tutorCallRequest = () =>
  `/account/tutor/call/request`;

export const createBetaTester = () =>
  `/account/betatester`;

export const getBetaTester = () =>
  `/account/betatester`;

export const getStudentRecording = (courseId, studentId) =>
  `/action/course/${courseId}/recordings/student/${studentId}`;

export const getTutorRecording = (courseId) =>
  `/action/course/${courseId}/recordings/tutor`;


//---------------h5p--------------------------//

export const getH5pContentByTutor = (tutorId) =>
  `/action/course/h5p/allcontent/tutor/${tutorId}`;

export const getH5pContentByCourse = (courseId) =>
  `/action/course/h5p/allcontent/${courseId}`;

export const getH5pContentById = (contentId) =>
  `/action/course/h5p/content/${contentId}`;

export const asignH5pToCourse = () =>
  `/action/course/h5p/content`;

export const removeH5pContentFromcourse = (itemId) =>
  `/action/course/h5p/content/${itemId}`;

export const deleteH5pContent = (contentId) =>
  `/action/remove/h5p/content/${contentId}`;

export const updateH5pCourse = (courseId) =>
  `/action/course/h5p/content/${courseId}`;

export const updateH5pContent = (contentId) =>
  `/action/content/h5p/${contentId}`;

export const createH5pContent = (tutorFirebaseId) =>
  `/action/content/h5p/create/${tutorFirebaseId}`;


//---------------ambassador-------------------//
export const ambassadorLogin = () =>
  `/account/ambassador/login`;

export const ambassadorAdd = () =>
  `/account/ambassador/addref`;

export const ambassadorGetAll = () =>
  `/account/ambassador/getAll`;

export const ambassadorCoupons = () =>
  `/account/ambassador/coupons`;

export const buyCoupons = () =>
  `/action/buddy/coupon`;

export const ambassadorGetAllWithRef = () =>
  `/account/ambassador/getAll/refs`;

export const ambassadorGetById = (refId) =>
  `/account/ambassador/get/${refId}`;

export const ambassadorDeleteById = (refId) =>
  `/account/ambassador/delete/${refId}`;

export const ambassadorUpdateById = (refId) =>
  `/account/ambassador/update/${refId}`;


export const ambassadorGetAffilate = () =>
  `/account/ambassador/affilate`;


export const swotknotBuddy = () => `/action/buddy/completion`;


export let baseURL = "";

if (CURRENT_RELEASE_MODE === ReleaseMode.DEV) {
  baseURL = "https://us-central1-swotknot-dev.cloudfunctions.net/";
}
else if (CURRENT_RELEASE_MODE === ReleaseMode.PROD) {
  baseURL = "https://us-central1-swotknot.cloudfunctions.net/";
  // locally testing
  // baseURL = "http://127.0.0.1:5001/swotknot/us-central1/";
}
