import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import AmbassadorNavBar from "./ambassadorNavBar";
import { ambassadorGetAll } from "../../services/api";
import LoaderModal from "../../components/common/modal/loaderModal";
import commonColor from "../../theme/commonColor";
import Swotbutton from "../../components/common/buttons/swotbutton";

const ViewUsers = () => {
  let navigate = useNavigate();

  const [ambassadors, setAmbassadors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/ambassador");
    } else {
      getAmbassadors();
      // setToken(localStorage.getItem("token"));
    }
  }, []);

  const getAmbassadors = async () => {
    setIsLoading(true);
    const response = await ambassadorGetAll();
    setIsLoading(false);
    if (response?.ok && response.data) {
      setAmbassadors(response.data.reverse());
      console.log("Data received: ", response.data);
    }
  };

  return (
    <div className="w-full h-screen flex flex-col items-center min-h-screen-ios gradient-background ">
      <AmbassadorNavBar />
      <div className="mb-2 w-full flex flex-row justify-between px-8">
        <div className="w-20 hidden md:block"></div>
        <div className="flex justify-center items-center text-white">
          <h2>Leads</h2>
        </div>
        <Swotbutton
          text="Add Lead"
          bgColor={commonColor.deepGreen}
          onClick={() => {
            navigate("/userUpload");
          }}
        />
      </div>
      <div className="w-full flex flex-col overflow-y-scroll px-8">
        <table className="w-full mt-8 border-collapse bg-gray-200 rounded-lg overflow-hidden shadow-lg">
          <thead
            className="text-white"
            style={{
              background: commonColor.deepPink,
            }}
          >
            <tr>
              <th className="p-4 text-start">Role</th>
              <th className="p-4 text-start">Name</th>
              <th className="p-4 text-start">Email</th>

              <th className="p-4 text-start">Phone Number</th>
              <th className="p-4 text-start">Country</th>
              <th className="p-4 text-start">Course</th>
              <th className="p-4 text-start">Status</th>
              <th className="p-4 text-start">Refer Type</th>
            </tr>
          </thead>
          <tbody>
            {ambassadors && ambassadors.length > 0 ? (
              ambassadors.map((item, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}
                >
                  <td className="p-4 border-t border-gray-300">{item.role}</td>
                  <td className="p-4 border-t border-gray-300">{item.name}</td>
                  <td className="p-4 border-t border-gray-300">{item.email}</td>

                  <td className="p-4 border-t border-gray-300">{item.phone}</td>
                  <td className="p-4 border-t border-gray-300">
                    {item.country}
                  </td>
                  <td className="p-4 border-t border-gray-300">
                    {item.course_name}
                  </td>
                  <td className="p-4 border-t border-gray-300">
                    {item.status}
                  </td>
                  <td className="p-4 border-t border-gray-300">
                    {item.refer_type}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colspan="4">
                  <div className="w-full text-black flex justify-center">
                    No data found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <LoaderModal isOpen={isLoading} />
    </div>
  );
};

export default ViewUsers;
