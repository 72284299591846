import { has } from "lodash";
import React from "react";

const EmptyNewsMessage = ({ hashTags, searchTopics, searchText }) => {
  const searchTopicMessage = () => {
    let searchFilters = null;
    if (searchTopics && searchTopics.length > 0 && hashTags) {
      let filteredTags = hashTags
        .filter((h) => searchTopics.includes(h.sys.id))
        .map((hash) => hash?.fields?.name)
        .filter((t) => t !== null && t !== undefined);
      searchFilters = filteredTags?.join(", ");
    }
    if (searchText && searchText.trim() !== "") {
      searchFilters = `${
        searchFilters ? searchFilters + ", " : ""
      } ${searchText.trim()}`;
    }
    return (
      <div className="flex flex-col p-5" 
      >
        <span className="" style={{
            overflowWrap: "break-word",
            wordBreak: "break-word"
        }}>Hmmm, it seems we could not find {searchFilters && searchFilters !== "" ? (<span className="font-bold">"{searchFilters}"</span> ) : ("any results")}
        </span>
        <span>
          Might be because we are still creating or finding content on this, so
          please try again later.{" "}
        </span>
        Or
        <span>1. Please check your spelling</span>
        <span>2. Change your filters</span>
        <span>3. Search for something similar</span>
      </div>
    )
   
  };

  return searchTopicMessage();
};

export default EmptyNewsMessage;
