const TermsAndConditions = () => {
  return (
    <div className="md:my-14 md:mx-48 xl:my-24 xl:mx-80">
      <div className="py-12 px-6 text-sm text-gray-600">
        <p className="text-2xl xl:text-3xl mb-4">Terms and Conditions of <b>l3arn.org</b>
        </p>
        <p>These Terms govern</p>
        <ul>
          <li>the use of this Application, and,</li>
          <li>any other related Agreement or legal relationship with the Owner<br />
            in a legally binding way. Capitalized words are defined in the relevant dedicated section of this document.</li>
        </ul>
        <br />
        <p>The User must read this document carefully. <span className="font-semibold">By using the Application or by clicking to accept or agree to the Terms of Use when this option is made available to the User, the User accepts and agrees to be bound and abide by these Terms of Use and as well as the Privacy Policy, found at <a href="url">this link</a>, incorporated herein by reference.</span> If the User does not want to agree to these Terms of Use or the Privacy Policy, they must not access or use the Application.</p>
        <br />
        <p> <br /></p>
        <p>Although the entire contractual relationship relating to these Products is entered into solely by the Owner and Users, Users acknowledge and agree that, where this Application has been provided to them via the Apple App Store, Apple may enforce these Terms as a third-party beneficiary.</p>
        <p>This Application is provided by:</p>
        <p>
          987 Lost Angel Road, Boulder CO 80302</p>
        <p><span className="font-semibold">Owner contact email:</span> support@l3arn.org
        </p>
        <h2 id="information-about-this_application">Information about this Application</h2>
        <p>l3arn provides a platform service for education.</p>
        <h2 id="what-the-user-should-know-at-a-glance">What the User should know at a glance</h2><ul>
          <li>Please note that some provisions in these Terms may only apply to certain categories of Users. In particular, certain provisions may only apply to Consumers or to those Users that do not qualify as Consumers. Such limitations are always explicitly mentioned within each affected clause. In the absence of any such mention, clauses apply to all Users.</li>
        </ul>
        <ul>
          <li>Minors may access this Application and use its Service only under parental or adult supervision.</li>
        </ul>
        <hr />
        <h2 id="terms-of-use">TERMS OF USE</h2>
        <p>Unless otherwise specified, the terms of use detailed in this section apply generally when using this Application.</p>
        <p>Single or additional conditions of use or access may apply in specific scenarios and in such cases are additionally indicated within this document.</p>
        <p>By using this Application, Users confirm to meet the following requirements:</p>
        <ul>
          <li>There are no restrictions for Users in terms of being Consumers or Business Users;</li>
        </ul>
        <ul>
          <li>Users must be recognized as adult by applicable law;</li>
        </ul>
        <ul>
          <li>Minors may only use this Application under parental or adult supervision;</li>
        </ul>
        <h4>Arbitration</h4>
        <p>In the event the parties are not able to resolve any dispute between them arising out of or concerning these Terms and Conditions, or any provisions hereof, whether in contract, tort, or otherwise at law or in equity for damages or any other relief, then such dispute shall be resolved only by final and binding arbitration pursuant to the Federal Arbitration Act, conducted by a single neutral arbitrator and administered by the American Arbitration Association, or a similar arbitration service selected by the parties, in a location mutually agreed upon by the parties. The arbitrator's award shall be final, and judgment may be entered upon it in any court having jurisdiction. In the event that any legal or equitable action, proceeding or arbitration arises out of or concerns these Terms and Conditions, the prevailing party shall be entitled to recover its costs and reasonable attorney's fees. The parties agree to arbitrate all disputes and claims in regards to these Terms and Conditions or any disputes arising as a result of these Terms and Conditions, whether directly or indirectly, including Tort claims that are a result of these Terms and Conditions. The parties agree that the Federal Arbitration Act governs the interpretation and enforcement of this provision. The entire dispute, including the scope and enforceability of this arbitration provision shall be determined by the Arbitrator. This arbitration provision shall survive the termination of these Terms and Conditions.</p>
        <br />
        <h4>Electronic Communication</h4>
        <p>Visiting l3arn.org or sending emails to l3arn constitutes electronic communications. The User consents to receive electronic communications and agrees that all agreements, notices, disclosures and other communications that are provided to the User electronically, via email and on the Application, satisfy any legal requirement that such communications be in writing.</p>
        <br />
        <h4>Minor Access</h4>
        <p>This Application collects personally identifiable information from children under the age of thirteen. This information is collected in order to create a better user experience.</p>
        <br />
        <p>If a User is under the age of thirteen, they must ask a parent or guardian for permission to use this Application. If parent Users have questions regarding any data collection practices, please contact the Owners using the information provided at the beginning of this Agreement. If the User is under 18, you may use l3arn.org only with permission of a parent or guardian.</p>
        <br />
        <ul>
          <li>Users aren’t located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist-supporting” country;</li>
          <li>Users aren’t listed on any U.S. Government list of prohibited or restricted parties;</li>
        </ul>
        <h3 id="account-registration">Account registration</h3>
        <p>To use the Service Users must register or create a User account, providing all required data or information in a complete and truthful manner.<br />
          Failure to do so will cause unavailability of the Service.</p>
        <p>Users are responsible for keeping their login credentials confidential and safe. For this reason, Users are also required to choose passwords that meet the highest standards of strength permitted by this Application.</p>
        <p>By registering, Users agree to be fully responsible for all activities that occur under their username and password.<br />
          Users are required to immediately and unambiguously inform the Owner via the contact details indicated in this document, if they think their personal information, including but not limited to User accounts, access credentials or personal data, have been violated, unduly disclosed or stolen.</p>
        <h4 id="conditions-for-account-registration">Conditions for account registration</h4>
        <p>Registration of User accounts on this Application is subject to the conditions outlined below. By registering, Users agree to meet such conditions.</p>
        <ul>
          <li>Accounts registered by bots or any other automated methods are not permitted.</li>
        </ul>
        <ul>
          <li>Unless otherwise specified, each User must register only one account.</li>
        </ul>
        <ul>
          <li>Unless explicitly permitted, a User account may not be shared with other persons.</li>
        </ul>
        <h4 id="account-termination">Account termination</h4>
        <p>Users can terminate their account and stop using the Service subject to the conditions and according to the procedures outlined in the relevant section of this Application.</p>
        <h4 id="account-suspension-and-deletion">Account suspension and deletion</h4>
        <p>The Owner reserves the right to suspend or terminate the User's account at any time and without notice, at the Owner's sole discretion, in these cases:</p>
        <ul>
          <li><p>User has violated these Terms; and/or</p></li>
          <li><p>User's access or use of this Application may cause injury to the Owner, other Users or third parties; and/or</p></li>
          <li><p>the use of this Application by the User may cause violation of law or regulations; and/or</p></li>
          <li><p>in case of an investigation by legal action or governmental involvement; and/or</p></li>
          <li><p>the account or its use is deemed to be, at the Owner’s sole discretion inappropriate or offensive or in violation of these Terms.</p></li>
        </ul>
        <p>The suspension or deletion of User accounts shall not entitle Users to any claims for compensation, damages or reimbursement.<br />
          The suspension or deletion of accounts due to causes attributable to the User does not exempt the User from paying any applicable fees or prices.</p>
        <h3 id="content-on-this_application">Content on this Application</h3>
        <p>Unless where otherwise specified or clearly recognizable, all content available on this Application is owned or provided by the Owner or its licensors.</p>
        <p>The Owner undertakes its utmost effort to ensure that the content provided on this Application infringes no applicable legal provisions or third-party rights. However, it may not always be possible to achieve such a result.<br />
          In such cases, without prejudice to any legal prerogatives of Users to enforce their rights, Users are kindly asked to preferably report related complaints using the contact details provided in this document.</p>
        <h4 id="rights-regarding-content-on-this_application-all-rights-reserved">Rights regarding content on this Application - All rights reserved</h4>
        <p>The Owner holds and reserves all intellectual property rights for any such content.</p>
        <p>Users may not therefore use such content in any way that is not necessary or implicit in the proper use of the Service.</p>
        <p>In particular, but without limitation, Users may not copy, download, share (beyond the limits set forth below), modify, translate, transform, publish, transmit, sell, sublicense, edit, transfer/assign to third parties or create derivative works from the content available on this Application, nor allow any third party to do so through the User or their device, even without the User's knowledge.</p>
        <p>Where explicitly stated on this Application, the User may download, copy and/or share some content available through this Application for its sole personal and non-commercial use and provided that the copyright attributions and all the other attributions requested by the Owner are correctly implemented.</p>
        <p>Any applicable statutory limitation or exception to copyright shall stay unaffected.</p>
        <h3 id="content-provided-by-users">Content provided by Users</h3>
        <p>The Owner allows Users to upload, share or provide their own content to this Application.</p>
        <p>By providing content to this Application, Users confirm that they are legally allowed to do so and that they are not infringing any statutory provisions and/or third-party rights.</p>
        <p>Further insights regarding acceptable content can be found inside the respective section on this Application which details the acceptable uses.</p>
        <h4 id="rights-regarding-content-provided-by-users">Rights regarding content provided by Users</h4>
        <p>Users acknowledge and accept that by providing their own content on this Application they grant the Owner a non-exclusive, fully paid-up and royalty-free license to process such content solely for the operation and maintenance of this Application as contractually required.</p>
        <p>To the extent permitted by applicable law, Users waive any moral rights in connection with content they provide to this Application.</p>
        <p>Users acknowledge, accept and confirm that all content they provide through this Application is provided subject to the same general conditions set forth for content on this Application.</p>
        <p>Users are solely liable for any content they upload, post, share, or provide through this Application.</p>
        <p>Users acknowledge and accept that the Owner filters or moderates such content after it has been made available.</p>
        <p>Therefore, the Owner reserves the right to refuse, remove, delete, or block such content at its own discretion and to deny access to this Application to the uploading User without prior notice, if it considers such content to infringe these Terms, any applicable legal provision or third-party right, or to otherwise represent a risk for Users, third parties,  the Owner and/or the availability of the Service.</p>
        <p>The removal, deletion or blocking of content shall not entitle Users that have provided such content or that are liable for it, to any claims for compensation, damages or reimbursement.</p>
        <p>Users agree to hold the Owner harmless from and against any claim asserted and/or damage suffered due to content they provided to or provided through this Application.</p>
        <h4 id="removal-of-content-from-parts-of-this_application-available-through-the-app-store">Removal of content from parts of this Application available through the App Store</h4>
        <p>If the reported content is deemed objectionable, it will be removed within 24 hours and the User who provided the content will be barred from using the Service.</p>
        <h4 id="access-to-provided-content">Access to provided content</h4>
        <p>Content that Users provide to this Application is made available according to the criteria outlined within this section.</p>
        <h5 id="private-content">Private content</h5>
        <p>Private content provided by Users shall stay private and will not be shared with any third parties or accessed by the Owner without the User’s explicit consent.</p>
        <h5 id="content-for-determined-audiences">Content for determined audiences</h5>
        <p>Content meant to be made available to specific audiences may only be shared with such third parties as determined by Users.</p>
        <p>Any personal data, identifier or any other information Users upload in connection with such content (such as a User-ID, avatar or nickname etc.) shall also appear in connection with the content.</p>
        <p>Users may (and are encouraged to) check on this Application to find details of who can access the content they provide.</p>
        <h3 id="access-to-external-resources">Access to external resources</h3>
        <p>Through this Application Users may have access to external resources provided by third parties. Users acknowledge and accept that the Owner has no control over such resources and is therefore not responsible for their content and availability.</p>
        <p>Conditions applicable to any resources provided by third parties, including those applicable to any possible grant of rights in content, result from each such third parties’ terms and conditions or, in the absence of those, applicable statutory law.</p>
        <p>In particular, on this Application Users may see advertisements provided by third parties. The Owner does not control or moderate the advertisements displayed via this Application. If Users click on any such advertisement, they will be interacting with any third party responsible for that advertisement.</p>
        <p>The Owner is not responsible for any matters resulting from such interaction with third parties, such as anything resulting from visiting third-party websites or using third-party content.</p>
        <h3 id="how-to-file-a-takedown-notice-called-a-dmca-notice">How to file a takedown notice (called a “DMCA notice”)</h3>
        <p>If copyright holders or their agents believe that any content on this Application infringes upon their copyrights, they may submit a notification pursuant to the Digital Millennium Copyright Act ("DMCA") by providing the Owner’s Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):</p>
        <ul>
          <li>A physical or electronic signature of a person authorized to act on behalf of the holder of an exclusive right that is allegedly infringed;</li>
          <li>Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site;</li>
          <li>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled and information reasonably sufficient to permit the Owner to locate the material;</li>
          <li>Information reasonably sufficient to permit the Owner to contact the notifying party, such as an address, telephone number, and, if available, an electronic mail;</li>
          <li>A statement that the notifying party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and</li>
          <li>A statement that the information in the notification is accurate, and under penalty of perjury, that the notifying party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
        </ul>
        <p>Failure to comply with all of the requirements outlined above may result in invalidity of the DMCA notice.</p>
        <p>Copyright infringement notifications may be addressed to the Owner’s Copyright Agent at the contact details specified in this document.</p>
        <h3 id="acceptable-use">Acceptable use</h3>
        <p>This Application and the Service may only be used within the scope of what they are provided for, under these Terms and applicable law.</p>
        <p>Users are solely responsible for making sure that their use of this Application and/or the Service violates no applicable law, regulations or third-party rights.</p>
        <p>Therefore, <span className="font-semibold">the Owner reserves the right to take any appropriate measure to protect its legitimate interests including denying Users access to this Application or the Service, terminating contracts, reporting any misconduct performed through this Application or the Service to the competent authorities – such as judicial or administrative authorities - whenever Users are suspected to be in violation of any laws, regulations, third-party rights and/or these Terms, including, but not limited to, by engaging in any of the following activities:</span></p>
        <h4 id="conduct-restrictions">Conduct restrictions</h4>
        <ul>
          <li>pretending to fulfill any possible condition or requirements for accessing this Application and/or using the Services, such as for instance being adult according to law or qualifying as a Consumer;</li>
          <li>concealing their identity or stealing someone else's identity or pretend to be or represent a third-party, if not allowed to do so by such third-party;</li>
          <li>manipulating identifiers to disguise or otherwise conceal the origin of their messages or of the content posted;</li>
          <li>defaming, abusing, harassing, using threatening practices, threatening or violating the legal rights of others in any other way;</li>
          <li>promoting activity that may endanger the User’s life or the life of any other User or lead to physical harm. This includes but is not limited to suicide threats or instigations, intentional physical trauma, the use of illegal drugs, or excessive drinking. Under no circumstance is any User allowed to post any content promoting and/or encouraging and/or showing any self-destructive or violent behavior on this Application;</li>
          <li>probing, scanning or testing the vulnerability of this Application, including the services or any network connected to the website, nor breaching the security or authentication measures on this Application, including the services or any network connected to this Application;</li>
          <li>installing, embedding, uploading or otherwise incorporating any malware into or via this Application;</li>
          <li>using this Application or the technical infrastructure in an abusive, excessive or otherwise inappropriate way (for example: for spamming purposes);</li>
          <li>attempting to disrupt or tamper with the technical infrastructure in a manner that harms or places an undue burden on this Application or the Service;</li>
        </ul>
        <ul>
          <li>pretending to purchase any Products offered via this Application without any real intent to do so;</li>
          <li>failing to pay for Products purchased;</li>
        </ul>
        <ul>
          <li>instigating other Users to complete a transaction started on this Application outside of this Application in order to save any applicable service fees;</li>
        </ul>
        <h4 id="scraping">Scraping</h4>
        <ul>
          <li>adopting any automated process to extract, harvest or scrape information, data and/or content from this Application and all the digital properties thereto related unless where explicitly allowed to do so by the Owner;</li>
        </ul>
        <h4 id="content-restrictions">Content restrictions</h4>
        <ul>
          <li>disseminating or publishing content that is unlawful, obscene, illegitimate, libelous or inappropriate;</li>
          <li>publishing any content that promotes, either directly or indirectly, hate, racism, discrimination, pornography, violence;</li>
          <li>disseminating or publishing any content that is false or may create unjustified alarm;</li>
          <li>using this Application to publish, disseminate or otherwise provide content protected by intellectual property laws, including but not limited to patent, trademark or copyright law, unlawfully and without the legitimate right-holder’s consent;</li>
          <li>using this Application to publish, disseminate or otherwise make available any other content which infringes on any third-party rights, including but not limited to state, military, trade or professional secrets and personal data;</li>
          <li>publishing any content or carrying out any activity that disrupts, interrupts, harms, or otherwise violates the integrity of this Application or another User's experience or devices. Such activities include: spamming, distributing unauthorized advertisements, phishing, defrauding others, spreading malware or viruses etc.;</li>
        </ul>
        <h4 id="user-protection">User protection</h4>
        <ul>
          <li>misappropriating any account in use by another User;</li>
          <li>harvesting or collecting any personally identifying information of other Users including but not limited to their email addresses or contact information, by circumventing the privacy setting of other Users’ accounts on this Application or by any other means;</li>
          <li>using any information relating to other Users, including personal or contact data, for purposes other than those this Application is intended for;</li>
        </ul>
        <h4 id="commercial-use-restrictions">Commercial use restrictions</h4>
        <ul>
          <li>registering or using this Application in order to promote, sell or advertise products or services of any kind in any way;</li>
          <li>indicating or trying to imply in any manner, that a User stands in a qualified relationship with this Application or that this Application has endorsed the User, the User’s products or services or any third party's products and services for any purpose;</li>
        </ul>
        <h3 id="purchase-via-app-store">Purchase via app store</h3>
        <p>This Application or specific Products available for sale on this Application must be purchased via a third-party app store. To access such purchases, Users must follow the instructions provided on the relevant online store (such as "Apple App Store" or "Google Play"), which may vary depending on the particular device in use.</p>
        <p>Unless otherwise specified, purchases done via third-party online stores are also subject to such third-parties’ terms and conditions, which, in case of any inconsistency or conflict, shall always prevail upon these Terms.</p>
        <p>Users purchasing through such third-party online stores must therefore read such terms and conditions of sale carefully and accept them.</p>
        <h3 id="contract-duration">Contract duration</h3><h4 id="subscriptions">Subscriptions</h4>
        <p>Subscriptions allow Users to receive a Product continuously or regularly over time. Details regarding the type of subscription and termination are outlined below.</p>
        <h4 id="subscriptions-handled-via-apple-id">Subscriptions handled via Apple ID</h4>
        <p>Users may subscribe to a Product using the Apple ID associated with their Apple App Store account by using the relevant process on this Application. When doing so, Users acknowledge and accept that</p>
        <ul>
          <li>any payment due shall be charged to their Apple ID account;</li>
          <li>subscriptions are automatically renewed for the same duration unless the User cancels at least 24 hours before the current period expires;</li>
          <li>any and all fees or payments due for renewal will be charged within 24-hours before the end of the current period;</li>
          <li>subscriptions can be managed or cancelled in the Users’ Apple App Store account settings.</li>
        </ul>
        <p>The above shall prevail upon any conflicting or diverging provision of these Terms.</p>
        <h5 id="termination">Termination</h5>
        <p><span className="font-semibold">Subscriptions may be terminated by sending a clear and unambiguous termination notice to the Owner using the contact details provided in this document, or — if applicable — by using the corresponding controls inside this Application.</span></p>
        <p><span className="font-semibold"><span className="font-semibold">DISCLAIMERS AND LIMITATIONS OF LIABILITY</span></span></p>
        <p><span className="font-semibold">• No Reliability for l3arn with the Usage of ChatGPT Output on Reliability and Correctness</span>
          l3arn hereby disclaims any and all warranties, express or implied, regarding the reliability and correctness of the output generated by ChatGPT. l3arn shall not be held liable for any loss or damage resulting from the use or inability to use the output generated by ChatGPT, including but not limited to any errors or inaccuracies in the output.</p>
        <p><span className="font-semibold">• Limitation of Liability</span>
          In no event shall l3arn be liable for any direct, indirect, incidental, special, punitive, or consequential damages arising out of or in connection with the use or inability to use the output generated by ChatGPT, even if l3arn has been advised of the possibility of such damages. This limitation of liability shall apply regardless of the form of action, whether in contract, tort, negligence, strict liability, or otherwise.</p>
        <p><span className="font-semibold">• Indemnification</span>
          You agree to indemnify, defend, and hold harmless l3arn and its affiliates, officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising from or related to your use or misuse of the output generated by ChatGPT.</p>
        <p><span className="font-semibold">• Entire Agreement</span>
          This Terms of Condition document represents the entire agreement between l3arn and you regarding the use of ChatGPT, and supersedes all prior or contemporaneous communications and proposals, whether oral or written, between you and l3arn.</p>
        <p><span className="font-semibold">• Governing Law</span>
          This agreement shall be governed by and construed in accordance with the laws of the jurisdiction where l3arn is located, without giving effect to any principles of conflicts of law. Any legal action or proceeding arising out of or related to this agreement shall be brought exclusively in the courts located in the jurisdiction where l3arn is located.</p>
        <br />
        <br />
        <p><span className="font-semibold"><span className="font-semibold">TUTOR TERMS AND CONDITIONS</span></span></p>
        <p><span className="font-semibold">• Permission to use l3arn’s marketplace. </span>
          Independent Tutor acknowledges that l3arn operates an Internet-based marketplace allowing individuals seeking the services of a tutor (each, a “Student” and collectively, “Students”) to identify and retain the services of individuals who provide tutoring services (each, a “Tutor” and collectively, “Tutors”). By entering into this Agreement, Independent Tutor seeks permission to access l3arn’s marketplace for the express purpose of promoting his/her/their independent business, subject to the terms and conditions set forth in this Agreement. Independent Tutor may not advertise or offer to sell any goods or services for any commercial purpose on the Site that are not directly related to the provision of tutoring services on an independent basis.</p>
        <p><span className="font-semibold">• Tutor status.</span>
          Independent Tutor acknowledges that l3arn provides nothing more than an Internet-based marketplace for prospective Students to identify and retain the services of prospective Tutors. Independent Tutor further acknowledges that l3arn is not directly or indirectly engaging Independent Tutor to render any services whatsoever to l3arn, and that any engagement of Independent Tutor’s services through the l3arn website ("Site") is undertaken exclusively by the Student who has selected Independent Tutor through the Site to provide tutoring services ("Tutor’s Student"). Neither this Agreement, the Terms of Use, nor use of the Site creates an independent contractor, employee/employer, partnership, joint venture, or franchisor-franchisee relationship between Independent Tutor and l3arn. It is the parties' express intention that Independent Tutor will operate an independent business providing tutoring services to Students. For avoidance of doubt, Independent Tutor is not, and shall at no time be deemed under this Agreement or otherwise to be, an independent contractor or employee of l3arn for any purpose whatsoever, including, without limitation, the application of the Fair Labor Standards Act, Federal Insurance Contribution Act, the Social Security Act, the Federal Unemployment Tax Act, the provisions of the Internal Revenue Code, any state or local income tax law, any state workers' compensation laws, any state unemployment insurance law, and any other applicable federal, state, or local law.</p>
        <p><span className="font-semibold">• Non-exclusivity.</span>
          Independent Tutor and l3arn acknowledge that this Agreement is nonexclusive. For avoidance of doubt, during the term of this Agreement, Independent Tutor shall at all times be free to engage in any other work or business, whether or not related to tutoring or other instruction, and whether or not such other work is full-time, part-time, piecemeal, or pursuant to any other scheduling arrangement. Nothing herein precludes Independent Tutor from advertising or providing Independent Tutor’s services to the general public.</p>
        <p><span className="font-semibold">• Hours/Cancellation Fee.</span>
          Independent Tutor shall set its own hours and schedule its own sessions with Students. Independent Tutor shall be solely responsible for determining its own cancellation policy and/or fees (if any) in the event a Student cancels or does not show for a session.</p>
        <p><span className="font-semibold">• Platform Fee.</span>
        The independent tutor shall have the exclusive right to determine their session rate. The tutor acknowledges and agrees that they are not required to pay any fee for utilizing the platform. Instead, students will be charged a 10% fee by the platform for using its services. Independent tutors authorize l3arn to deduct this fee directly from the payments collected from students on their behalf. The tutor agrees not to seek or accept compensation directly from students for services provided through the platform.</p>
        <p><span className="font-semibold">• Payment system.</span>
          Independent Tutor agrees to not attempt to subvert or otherwise avoid same, or to divert funds ordinarily payable to Independent Tutor through l3arn. Prior to commencing tutoring sessions or meeting with any Student for any purpose, Independent Tutor must verify with l3arn that the prospective Student has submitted appropriate billing information to l3arn. l3arn is not responsible for payments for sessions given before a prospective Student has appropriate billing information on file. Upon completion of each tutoring session, Independent Tutor shall (utilizing the Site) report the length of such session, in order to enable l3arn to initiate collection of Independent Tutor’s fees. If requested by a Student, Independent Tutor shall also include a summary of services performed, solely for Student’s use.</p>
        <p><span className="font-semibold">• Expenses.</span>
          Independent Tutor acknowledges that l3arn will not reimburse Independent Tutor for any expenses incurred by Independent Tutor related to Independent Tutor’s rendering of services hereunder, including, without limitation, expenses associated with preparing for and administering sessions, educational and other materials, and transportation.</p>
        <p><span className="font-semibold">• Supplies.</span>
          Independent Tutor acknowledges that l3arn will not provide any supplies, materials, or equipment required by Independent Tutor to complete any services rendered to any Student pursuant to this Agreement.</p>
        <p><span className="font-semibold">• Taxes.</span>
          Independent Tutor is responsible for all federal, state, and/or local taxes due as a result of the services provided hereunder by Independent Tutor. l3arn shall not withhold (or allocate in any other manner) any amount of Independent Tutor’s fees toward any federal, nor state, or local income tax, or toward payroll tax of any kind.</p>
        <p><span className="font-semibold">• Benefits.</span>
          Independent Tutor acknowledges that Independent Tutor is engaged in an independent business, and as such, is not (and shall at no time become) eligible to participate in any pension, health, or other fringe benefits plan of l3arn.</p>
        <p><span className="font-semibold">• Insurance.</span>
          No Workers' Compensation Insurance shall be obtained by l3arn for or on behalf of Independent Tutor. Independent Tutor understands that it is Independent Tutor’s responsibility to comply with any applicable Workers' Compensation Law. Independent Tutor further understands it is Independent Tutor’s responsibility to obtain any other insurance coverage applicable to the services Independent Tutor provides hereunder.</p>
        <p><span className="font-semibold">• Confidentiality.</span>
          Independent Tutor acknowledges that, during the performance of services, Independent Tutor will have access to information relating to the student's identity, address, contact information, and other personal information or requests for services ("Confidential Information"). Independent Tutor agrees that Independent Tutor owes a duty to Tutor’s Students, during the term of this Agreement and thereafter, to hold all such Confidential Information in the strictest confidence and not to disclose it to any person, firm, or corporation or to use it except as necessary in carrying out the services provided hereunder.</p>
        <p><span className="font-semibold">• Tutor documents.</span>
          Independent Tutor acknowledges that a copy of all documents created by or received from Independent Tutor in connection with the services performed hereunder ("Tutor Documents") may be retained by l3arn and used solely for administrative and/or record-keeping purposes.</p>
        <p><span className="font-semibold">• Indemnity and hold harmless clause.</span>
          Independent Tutor agrees to indemnify and hold harmless l3arn, its subsidiaries, affiliates, other independent contractors and their respective directors, officers, employees and agents, from and against any and all claims, losses, expenses, costs, liabilities and damages (including any legal fee and expenses), any and all injuries, whether direct, consequential or incidental in nature, which result from, are connected with or arise out of the performance by Independent Tutor of work performed pursuant to this Agreement, including any act by Independent Tutor inconsistent with Independent Tutor's obligations and duties under this Agreement. Independent Tutor understands that l3arn offers an Internet-based marketplace for prospective Students to connect with prospective Tutors. Accordingly, l3arn makes no representations to Independent Tutor about the suitability, character or background of any l3arn user (including, without limitation, Students or their parents/guardians), nor does l3arn perform any type of background check on users (including, without limitation, Students or their parents/guardians). It is Independent Tutor's sole duty to exercise Independent Tutor’s own judgment, protocols and/or standards when considering whether to engage in tutoring services with a prospective Student. Independent Tutor understands and agrees that it is Independent Tutor’s responsibility to conduct any and all background and reference checks regarding prospective Students and other Site users.</p>
        <p><span className="font-semibold">• Copyright Assignment.</span>
          l3arn does not claim ownership or licensure of content that Independent Tutor provides on the Site including, but not limited to, articles, lessons, worksheets, tutorials, files, testimonials, reviews, video submissions, and blog entries ("Site Content"). Unless otherwise stated herein, any Site Content that Independent Tutor provides in connection with the Site shall be deemed to be provided on a non-confidential basis. l3arn shall be free to use or disseminate such Site Content on an unrestricted basis for any purpose, and Independent Tutor grants l3arn an irrevocable, worldwide, royalty-free, nonexclusive license to use, reproduce, modify, distribute, transmit, display, perform, adapt, resell and publish any Site Content (including in digital form). Independent Tutor represents and warrants that Independent Tutor has proper authorization for the worldwide transfer and processing (among l3arn, its affiliates, and third-party providers) of any information that Independent Tutor may provide on the Site.</p>
        <p><span className="font-semibold">• Assignment.</span>
          Independent Tutor may not assign or transfer this Agreement without the express prior written consent of l3arn.</p>
        <p><span className="font-semibold">• Academic Dishonesty.</span>
          Independent Tutor agrees not to use the Site to engage in academic dishonesty (e.g., completing assignments, writing papers, taking tests on someone’s behalf, or work completion in violation of conduct policies of a school, university, academic institution, or workplace) or other misconduct involving a Student.</p>
        <p><span className="font-semibold">• Deactivation of Platform Use.</span>
          Independent Tutor may deactivate their account at any time. l3arn may deactivate an Independent Tutor’s account for the following reasons:
          (a) Independent Tutor’s failure to meet l3arn’s platform vetting requirements.
          (b) Independent Tutor’s material breach of this Agreement, violation of the l3arn Terms of Use or Tutor Payment Policies, or misuse of the Site.
          (c) Independent Tutor’s misappropriation of Tutor’s Students from the Site for Independent Tutor’s own pecuniary gain.
          (d) Entry of a restraining order or conviction of any felony or misdemeanor involving violence, sexual conduct, conduct involving a minor, abuse, fraud, larceny, or endangerment.
          (e) Independent Tutor’s failure to cooperate in good faith to resolve a Student’s complaint or excessive complaints.
          (f) Independent Tutor’s repeated failure to respond timely to direct contacts through Independent Tutor’s account.
          (g) For any other reason or for no reason, on 30 days’ written notice to Independent Tutor.</p>
        <p>In the event l3arn terminates Independent Tutor’s platform use, l3arn will provide an email notification of deactivation.</p>
        <p><span className="font-semibold">• Amendment.</span>
          This Agreement may only be amended or modified by a writing which makes express reference to this Agreement as the subject of such amendment and which is signed by Independent Tutor and, on behalf of l3arn, by its duly authorized officer. The parties agree that any amendment or modification hereunder may be transacted by electronic means within the meaning of the UETA and Illinois Electronic Commerce Security Act provided that any such amendments or modifications otherwise comply with the requirements in this paragraph.</p>
        <p><span className="font-semibold">• Severability.</span>
          If any term, provision, covenant, or condition of this Agreement or part thereof, or the application thereof to any person, place, or circumstance, shall be held to be invalid, unenforceable, or void, the remainder of this Agreement and such term, provision, covenant, or condition shall remain in full force and effect, and any such invalid, unenforceable, or void term, provision, covenant, or condition shall be deemed, without further action on the part of the parties hereto, modified, amended, and limited to the extent necessary to render the same and the remainder of this Agreement valid, enforceable, and lawful.</p>
        <p><span className="font-semibold">• Incorporation of Terms of Use.</span>
          The l3arn <a href="https://www.iubenda.com/terms-and-conditions/82490855">Terms of Use</a> are hereby incorporated into this Agreement.</p>
        <p><span className="font-semibold">• Governing Law.</span>
          This Agreement shall be governed by and construed in accordance with the substantive laws of the State of Illinois, without regard to conflict of law rules.</p>
        <p><span className="font-semibold">• Arbitration.</span>
          Any dispute between the parties related to this Agreement shall be resolved by binding arbitration in accordance with the Arbitration provision in the l3arn
          <a href="https://www.iubenda.com/terms-and-conditions/82490855">Terms of Use.</a></p>
        <p><span className="font-semibold">• Entire Agreement.</span>
          This Agreement contains the entire agreement and understanding between the parties hereto in respect to the subject matter hereof and supersedes, cancels, and annuls any prior or contemporaneous written or oral agreements, understandings, commitments, and practices between them respecting the subject matter hereof, including all prior agreements, if any, between l3arn and Independent Tutor, which agreement(s) hereby are terminated and shall be of no further force or effect.</p>
        <br />
        <h2 id="liability-and-indemnification">Liability and indemnification</h2><p>Unless otherwise explicitly stated or agreed with Users, the Owner’s liability for damages in connection with the execution of the Agreement shall be excluded, limited and/or reduced to the maximum extent permitted by applicable law.</p>
        <h4 id="indemnification">Indemnification</h4>
        <p>The User agrees to indemnify and hold the Owner and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners and employees harmless from and against any claim or demand ⁠— including but not limited to lawyer's fees and costs ⁠— made by any third party due to or in relation with any culpable use of or connection to the Service, violation of these Terms, infringement of any third-party rights or statutory provision by the User or its affiliates, officers, directors, agents, co-branders, partners and employees to the extent allowed by applicable law.</p>
        <h4 id="limitation-of-liability">Limitation of liability</h4>
        <p>Unless otherwise explicitly stated and without prejudice to applicable statutory product liability provisions, Users shall have no right to claim damages against the Owner (or any natural or legal person acting on its behalf).</p>
        <p>This does not apply to damages to life, health or physical integrity, damages resulting from the breach of an essential contractual obligation such as any obligation strictly necessary to achieve the purpose of the contract, and/or damages resulting from intent or gross negligence, as long as this Application has been appropriately and correctly used by the User.</p>
        <p>Unless damages have been caused by way of intent or gross negligence, or they affect life, health or physical integrity, the Owner shall only be liable to the extent of typical and foreseeable damages at the moment the contract was entered into.</p>
        <p>Notwithstanding the above, the following limitation applies to all Users not qualifying as Consumers:</p>
        <p>In any event of liability, the compensation may not exceed the total payments that have been, will be or would be received by the Owner from the User based on the contract over a period of 12 months, or the period of the duration of the Agreement, if shorter.</p>
        <h3 id="australian-users">Australian Users</h3><h4 id="limitation-of-liability">Limitation of liability</h4>
        <p>Nothing in these Terms excludes, restricts or modifies any guarantee, condition, warranty, right or remedy which the User may have under the Competition and Consumer Act 2010 (Cth) or any similar State and Territory legislation and which cannot be excluded, restricted or modified (non-excludable right). To the fullest extent permitted by law, our liability to the User, including liability for a breach of a non-excludable right and liability which is not otherwise excluded under these Terms of Use, is limited, at the Owner’s sole discretion, to the re-performance of the services or the payment of the cost of having the services supplied again.</p>
        <h3 >US Users</h3><h4 >Disclaimer of Warranties</h4>
        <p><span className="font-semibold">This Application is provided strictly on an “as is” and “as available” basis.  Use of the Service is at Users’ own risk. To the maximum extent permitted by applicable law, the Owner expressly disclaims all conditions, representations, and warranties — whether express, implied, statutory or otherwise, including, but not limited to, any implied warranty of merchantability, fitness for a particular purpose, or non-infringement of third-party rights. No advice or information, whether oral or written, obtained by user from owner or through the Service will create any warranty not expressly stated herein.</span></p>
        <p><span className="font-semibold">Without limiting the foregoing, the Owner, its subsidiaries, affiliates, licensors, officers, directors, agents, co-branders, partners, suppliers and employees do not warrant that the content is accurate, reliable or correct; that the Service will meet Users’ requirements; that the Service will be available at any particular time or location, uninterrupted or secure; that any defects or errors will be corrected; or that the Service is free of viruses or other harmful components. Any content downloaded or otherwise obtained through the use of the Service is downloaded at users own risk and users shall be solely responsible for any damage to Users’ computer system or mobile device or loss of data that results from such download or Users’ use of the Service.</span></p>
        <p><span className="font-semibold">The Owner does not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered by a third party through the Service or any hyperlinked website or service, and the Owner shall not be a party to or in any way monitor any transaction between Users and third-party providers of products or services.</span></p>
        <p><span className="font-semibold">The Service may become inaccessible or it may not function properly with Users’ web browser, mobile device, and/or operating system. The owner cannot be held liable for any perceived or actual damages arising from Service content, operation, or use of this Service.</span></p>
        <p><span className="font-semibold">Federal law, some states, and other jurisdictions, do not allow the exclusion and limitations of certain implied warranties. The above exclusions may not apply to Users. This Agreement gives Users specific legal rights, and Users may also have other rights which vary from state to state. The disclaimers and exclusions under this agreement shall not apply to the extent prohibited by applicable law.</span></p>
        <h4 id="limitations-of-liability">Limitations of liability</h4>
        <p><span className="font-semibold">To the maximum extent permitted by applicable law, in no event shall the Owner, and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees be liable for</span></p>
        <ul>
          <li><span className="font-semibold">any indirect, punitive, incidental, special, consequential or exemplary damages, including without limitation damages for loss of profits, goodwill, use, data or other intangible losses, arising out of or relating to the use of, or inability to use, the Service; and</span></li>
          <li><span className="font-semibold">any damage, loss or injury resulting from hacking, tampering or other unauthorized access or use of the Service or User account or the information contained therein;</span></li>
          <li><span className="font-semibold">any errors, mistakes, or inaccuracies of content;</span></li>
          <li><span className="font-semibold">personal injury or property damage, of any nature whatsoever, resulting from User access to or use of the Service;</span></li>
          <li><span className="font-semibold">any unauthorized access to or use of the Owner’s secure servers and/or any and all personal information stored therein;</span></li>
          <li><span className="font-semibold">any interruption or cessation of transmission to or from the Service;</span></li>
          <li><span className="font-semibold">any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Service;</span></li>
          <li><span className="font-semibold">any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Service; and/or</span></li>
          <li><span className="font-semibold">the defamatory, offensive, or illegal conduct of any User or third party.  In no event shall the Owner, and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees be liable for any claims, proceedings, liabilities, obligations, damages, losses or costs in an amount exceeding the amount paid by User to the Owner hereunder in the preceding 12 months, or the period of duration of this agreement between the Owner and User, whichever is shorter.</span></li>
        </ul>
        <p><span className="font-semibold">This limitation of liability section shall apply to the fullest extent permitted by law in the applicable jurisdiction whether the alleged liability is based on contract, tort, negligence, strict liability, or any other basis, even if company has been advised of the possibility of such damage.</span></p>
        <p><span className="font-semibold">Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, therefore the above limitations or exclusions may not apply to User.  The terms give User specific legal rights, and User may also have other rights which vary from jurisdiction to jurisdiction.  The disclaimers, exclusions, and limitations of liability under the terms shall not apply to the extent prohibited by applicable law.</span></p>
        <h4 id="indemnification">Indemnification</h4>
        <p><span className="font-semibold">The User agrees to defend, indemnify and hold the Owner and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees harmless from and against any and all claims or demands, damages, obligations, losses, liabilities, costs or debt, and expenses, including, but not limited to, legal fees and expenses, arising from</span></p>
        <ul>
          <li><span className="font-semibold">User’s use of and access to the Service, including any data or content transmitted or received by User;</span></li>
          <li><span className="font-semibold">User’s violation of these terms, including, but not limited to, User’s breach of any of the representations and warranties set forth in these terms;</span></li>
          <li><span className="font-semibold">User’s violation of any third-party rights, including, but not limited to, any right of privacy or intellectual property rights;</span></li>
          <li><span className="font-semibold">User’s violation of any statutory law, rule, or regulation;</span></li>
          <li><span className="font-semibold">any content that is submitted from User’s account, including third party access with User’s unique username, password or other security measure, if applicable, including, but not limited to, misleading, false, or inaccurate information;</span></li>
          <li><span className="font-semibold">User’s wilful misconduct; or</span></li>
          <li><span className="font-semibold">statutory provision by User or its affiliates, officers, directors, agents, co-branders, partners, suppliers and employees to the extent allowed by applicable law.</span></li>
        </ul>
        <h2 id="common-provisions">Common provisions</h2><h3 id="no-waiver">No Waiver</h3>
        <p>The Owner’s failure to assert any right or provision under these Terms shall not constitute a waiver of any such right or provision.  No waiver shall be considered a further or continuing waiver of such term or any other term.</p>
        <h3 id="service-interruption">Service interruption</h3>
        <p>To ensure the best possible service level, the Owner reserves the right to interrupt the Service for maintenance, system updates or any other changes, informing the Users appropriately.</p>
        <p>Within the limits of law, the Owner may also decide to suspend or discontinue the Service altogether. If the Service is discontinued, the Owner will cooperate with Users to enable them to withdraw Personal Data or information and will respect Users' rights relating to continued product use and/or compensation, as provided for by applicable law.</p>
        <p>Additionally, the Service might not be available due to reasons outside the Owner’s reasonable control, such as “force majeure” events( infrastructural breakdowns or blackouts etc.).</p>
        <h3 id="service-reselling">Service reselling</h3>
        <p>Users may not reproduce, duplicate, copy, sell, resell or exploit any portion of this Application and of its Service without the Owner’s express prior written permission, granted either directly or through a legitimate reselling programme.</p>
        <h3 id="privacy-policy">Privacy policy</h3>
        <p>To learn more about the use of their Personal Data, Users may refer to the privacy policy of this Application.</p>
        <h3 id="intellectual-property-rights">Intellectual property rights</h3>
        <p>Without prejudice to any more specific provision of these Terms, any intellectual property rights, such as copyrights, trademark rights, patent rights and design rights related to this Application are the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties relating to intellectual property.</p>
        <p>All trademarks — nominal or figurative — and all other marks, trade names, service marks, word marks, illustrations, images, or logos appearing in connection with this Application are, and remain, the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties related to intellectual property.</p>
        <h3 id="changes-to-these-terms">Changes to these Terms</h3>
        <p>The Owner reserves the right to amend or otherwise modify these Terms at any time. In such cases, the Owner will appropriately inform the User of these changes.</p>
        <p>Such changes will only affect the relationship with the User from the date communicated to Users onwards.</p>
        <p>The continued use of the Service will signify the User’s acceptance of the revised Terms. If Users do not wish to be bound by the changes, they must stop using the Service and may terminate the Agreement.</p>
        <p>The applicable previous version will govern the relationship prior to the User's acceptance. The User can obtain any previous version from the Owner.</p>
        <p>If legally required, the Owner will notify Users in advance of when the modified Terms will take effect.</p>
        <h3 id="assignment-of-contract">Assignment of contract</h3>
        <p>The Owner reserves the right to transfer, assign, dispose of by novation, or subcontract any or all rights or obligations under these Terms, taking the User’s legitimate interests into account.
          Provisions regarding changes of these Terms will apply accordingly.</p>
        <p>Users may not assign or transfer their rights or obligations under these Terms in any way, without the written permission of the Owner.</p>
        <h3 id="contacts">Contacts</h3>
        <p>All communications relating to the use of this Application must be sent using the contact information stated in this document.</p>
        <h3 id="severability">Severability</h3>
        <p>Should any provision of these Terms be deemed or become invalid or unenforceable under applicable law, the invalidity or unenforceability of such provision shall not affect the validity of the remaining provisions, which shall remain in full force and effect.</p>
        <h4 id="us-users">US Users</h4>
        <p>Any such invalid or unenforceable provision will be interpreted, construed and reformed to the extent reasonably required to render it valid, enforceable and consistent with its original intent.
          These Terms constitute the entire Agreement between Users and the Owner with respect to the subject matter hereof, and supersede all other communications, including but not limited to all prior agreements, between the parties with respect to such subject matter.
          These Terms will be enforced to the fullest extent permitted by law.</p>
        <h4 id="eu-users">EU Users</h4>
        <p>Should any provision of these Terms be or be deemed void, invalid or unenforceable, the parties shall do their best to find, in an amicable way, an agreement on valid and enforceable provisions thereby substituting the void, invalid or unenforceable parts.<br />
          In case of failure to do so, the void, invalid or unenforceable provisions shall be replaced by the applicable statutory provisions, if so permitted or stated under the applicable law.</p>
        <p>Without prejudice to the above, the nullity, invalidity or the impossibility to enforce a particular provision of these Terms shall not nullify the entire Agreement, unless the severed provisions are essential to the Agreement, or of such importance that the parties would not have entered into the contract if they had known that the provision would not be valid, or in cases where the remaining provisions would translate into an unacceptable hardship on any of the parties.</p>
        <h3 id="governing-law">Governing law</h3>
        <p>These Terms are governed by the law of the place where the Owner is based, as disclosed in the relevant section of this document, without regard to conflict of laws principles.</p>
        <h4 id="prevalence-of-national-law">Prevalence of national law</h4>
        <p>However, regardless of the above, if the law of the country that the User is located in provides for a higher applicable consumer protection standard, such higher standards shall prevail.</p>
        <h3 id="venue-of-jurisdiction">Venue of jurisdiction</h3>
        <p>The exclusive competence to decide on any controversy resulting from or connected to these Terms lies with the courts of the place where the Owner is based, as displayed in the relevant section of this document.</p>
        <h4 id="exception-for-consumers-in-europe">Exception for Consumers in Europe</h4>
        <p>The above does not apply to any Users that qualify as European Consumers, nor to Consumers based in the United Kingdom, Switzerland, Norway or Iceland.</p>
        <div className="one_line_col">
          <div className="box_primary box_10 definitions expand">
            <h2 className="expand-click w_icon_24 icon_ribbon">
              Definitions and legal references
            </h2>
            <div className="expand-content">
              <h4>This Application (or this Application)</h4>
              <p>The property that enables the provision of the Service.</p>
              <h4>Agreement</h4>
              <p>Any legally binding or contractual relationship between the Owner and the User, governed by these Terms.</p>
              <h4>Business User</h4>
              <p>Any User that does not qualify as a Consumer.</p>
              <h4>European (or Europe)</h4>
              <p>Applies where a User, regardless of nationality, is in the EU.</p>
              <h4>Owner (or We)</h4>
              <p>Indicates the natural person(s) or legal entity that provides this Application and/or the Service to Users.</p>
              <h4>Service</h4>
              <p>The service provided by this Application as described in these Terms and on this Application.</p>
              <h4>Terms</h4>
              <p>All provisions applicable to the use of this Application and/or the Service as described in this document, including any other related documents or agreements, and as updated from time to time.</p>
              <h4>User (or You)</h4>
              <p>Indicates any natural person or legal entity using this Application.</p>
              <h4>Consumer</h4>
              <p>Consumer is any User qualifying as such under applicable law.</p>
            </div>
          </div>
        </div>
        <div className="iub_footer">
          <p>
            Latest update: April 16, 2024
          </p>
        </div> {/* /footer */}
      </div>
    </div>
  );
};

export default TermsAndConditions;