import React, { useState } from "react";

import "./styles.css";
import LandingLayout from "../../components/landing/landingLayout";
import Swotbutton from "../../components/common/buttons/swotbutton";
import { signupBeta } from "../../services/api";
import LoaderModal from "../../components/common/modal/loaderModal";
import BetaSignupModal from "./betaSignupModal";
import { ReleaseMode, CURRENT_RELEASE_MODE } from "../../config/constants";
import { NavigateFunction, useNavigate } from "react-router-dom";

import jobPicture from "../../assets/images/jobs.svg";

export default function Jobs() {
  const navigate = useNavigate();
  const [showFormModal, setShowFormModal] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <LandingLayout
      // className="items-center justify-center"
      landingTitle="Jobs"
    >
      <div className="flex flex-col flex-wrap 2xl:w-10/12 md:w-full lg:w-11/12 w-full lg:px-0 px-12 h-full justify-center pt-8">
        <div className="flex lg:flex-wrap flex-col lg:flex-row w-full justify-center items-center ">
          <div className="lg:flex-auto lg:w-4/12 h-full w-5/12 lg:mr-12 mr-0 rounded-2xl shadow-3xl shadow-lg hidden sm:block ">
            <img
              src={jobPicture}
              alt="JobPicture"
              className="w-full h-full rounded-2xl "
            />
          </div>
          <div className="flex flex-auto md:w-10/12 lg:w-7/12  flex-col h-full">
            <div className="mb-5 rounded-2xl shadow-3xl h-max px-4 bg-white py-6 md:px-6 md:py-10 shadow-md">
              <p>
                We are an online platform that includes all tools needed for
                online education, including content creation tools and video
                conferencing software. We are looking for tutors interested in
                using our platform for teaching, whether for their classes or
                institute. You set your rates. Commission rates are just 10 %,
                and you can charge your fee to your students. You have the right
                to your courses.
              </p>
            </div>
            <div className="rounded-2xl shadow-3xl shadow-md h-max px-4 bg-white py-6 md:px-6 md:py-10  flex-1 flex-col relative">
              <div className="flex-1">
                <p>
                  <span className="font-bold">The benefits with l3arn</span>
                  <ul>
                    <li>-Excellent pay (set your own price)</li>
                    <li>
                      -Create your own classes on topics you’re passionate about
                    </li>
                    <li>-Interactive teaching platform and content creation</li>
                    <li>-More market exposure</li>
                    <li>-Flexible (set class size, time schedule)</li>
                  </ul>
                </p>
                <br />
                <p>
                  <span className="font-bold">Requirements</span>
                  <ul>
                    <li>Love teaching</li>
                    <li>Desire to engage and inspire young learners</li>
                  </ul>
                </p>
                <br />
                <p>
                  <span className="font-bold">Application Process</span>
                  <ul>
                    <li>Step 1: Online Application</li>
                    <li>Step 2: Verification</li>
                    <li>Step 3: Acceptance</li>
                  </ul>
                </p>
              </div>

              <div className="flex-1 | lg:mt-5 lg:mr-4 | mt-8 mr-0 | flex justify-center lg:justify-end ">
                <Swotbutton
                  text="Apply Now"
                  style={{
                    padding: ".75rem 3.25rem",
                  }}
                  onClick={() => navigate("/on-boarding")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <BetaSignupModal
        isOpen={!loading && showFormModal}
        onRequestClose={() => {
          setShowFormModal(false);
        }}
        showLoader={(status) => {
          setLoading(status);
        }}
      />
      {/* <BetaSignup onSubmit={onSubmit} /> */}
      {/* </BetaSignup> */}
      <LoaderModal
        isOpen={loading}
        onRequestClose={() => {
          setLoading(false);
        }}
      />
    </LandingLayout>
  );
}
