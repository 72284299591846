import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useLayoutEffect,
} from "react";
import commonColor from "../../../../../theme/commonColor";
import { connect } from "react-redux";
import UserActions from "../../../../../redux/user";
import LearnLogo from "../../../../../assets/images/learn-logo-colour.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/icons/delete1.svg";
import { ReactComponent as BackIcon } from "../../../../../assets/icons/back1.svg";
import closeSrc from "../../../../../assets/images/close-icon.png";
import CourseStep1 from "./courseStep1";
import {
  createCourse,
  editCourse,
  getCourseExists,
  getCourseListOfTutor,
  getCourseDetails,
  deleteCourse,
  createBulkSlots,
} from "../../../../../services/api";
import {
  getTutorStripeStatus,
  stripeRegisterAccount,
} from "../../../../../services/paymentsManagement";
import { STRIPE_REG_STATUSES } from "../../../../../config/constants";
import CourseStep2 from "./courseStep2";
import CourseStep3 from "./courseStep3";
import CourseStep4 from "./courseStep4";
import Swotbutton from "../../../../../components/common/buttons/swotbutton";
import {
  uploadBase64FilePromise,
  uploadFilePromise,
} from "../../../../../services/fileServices";
import Swal from "sweetalert2";
import Modal from "../../../../../components/common/modal/modal";
import LoaderModal from "../../../../../components/common/modal/loaderModal";
import "./courseSetup.css";
import moment from "moment";
import { capitalize } from "../../../../../utils/uiUtils";
import { unstable_usePrompt, useLocation, useNavigate } from "react-router-dom";
import { historyState } from "../../../../../services/navigation/history";

const CourseSetup = ({ user, mode, selectedCourseId }) => {
  const navigate = useNavigate();
  const curLocation = useLocation();
  // let history = useHistory();
  //
  // useStates:-------------------------------------------
  const [currentStep, setCurrentStep] = useState(1);
  const [photoUploadProgress, setPhotoUploadProgress] = useState(0);
  const [videoUploadProgress, setVideoUploadProgress] = useState(0);
  const [submissionError, setSubmissionError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState();
  const [existingShortName, setExistingShortName] = useState();

  const [shouldRegister, setShouldRegister] = useState();
  const [courseDetails, setCourseDetails] = useState();
  const [promtExit, setPromtExit] = useState(false);
  // const curLocation = useLocation();

  const defaultLanguage = { label: "English", value: "english" };

  //
  // init:------------------------------------------------
  useEffect(() => {
    const initStates = {
      stripeLink: null,
      freeCoursesCount: 0,
      isLoading: false,
      loadingMessage: null,
      addingAutoSlots: false,
      addManualSlots: false,
      startTime: 0,
      endTime: 0,
    };
    dispatchState({ key: "reset", value: initStates });
  }, []);

  useEffect(() => {
    // if (!user?.ngoUser && user.stripeStatus === STRIPE_REG_STATUSES.INACTIVE) {
    //   checkStatus();
    // }

    // fetch course details
    if (selectedCourseId && mode === "edit") {
      getCourseInfo(selectedCourseId);
    }

    //For 'edit' mode, registeRelaodListner() is being called after fetching courseDetails
    if (mode !== "edit") {
      registerReloadListener();
    }
  }, []);

  // useEffect(() => {
  //   getFreeCoursesCount();
  // }, [user.uid]);

  //
  // functions:--------------------------------------------
  const shouldEdit = () => {
    return !(mode !== "edit" || !selectedCourseId);
  };

  const isValidUser = () => {
    return !(
      !user ||
      !user.uid ||
      !(user.accountType === "Tutor" || user.accountType === "GuestTutor")
    );
  };

  const registerReloadListener = () => {
    window.onbeforeunload = function () {
      return true;
    };
    setShouldRegister(true);
    setPromtExit(true);
  };

  const unregisterReloadListener = () => {
    window.onbeforeunload = null;
    setShouldRegister(false);
    setPromtExit(false);
  };

  // useEffect(() => {
  //   // const unlisten = globalHistory.listen(({ action, location }) => {
  //   if (shouldRegister && historyState != null && historyState.router != null) {
  //     const unsubscribeRouter = historyState.router.subscribe((state) => {
  //       const { historyAction, location } = state;

  //       if (
  //         (historyAction === "PUSH" &&
  //           isValidUser &&
  //           ((shouldEdit() && !location.pathname.includes("/edit-course/")) ||
  //             (!shouldEdit() &&
  //               !location.pathname.includes("/create-course")))) ||
  //         historyAction === "POP"
  //       ) {
  //         if (!window.confirm("Are you sure you want to exit before saving?")) {
  //           navigate(`${curLocation.pathname}`);
  //         } else {
  //           unregisterReloadListener();
  //         }
  //       }
  //     });

  //     return () => {
  //       unsubscribeRouter();
  //     };
  //   }
  // }, [shouldRegister, historyState?.router]);

  useEffect(() => {
    return () => {
      unregisterReloadListener();
    };
  }, []);

  // useEffect(() => {
  // if (shouldRegister) {
  //     // const unlisten = globalHistory.listen(({ action, location }) => {
  //       if (
  //         (action === "PUSH" &&
  //           isValidUser &&
  //           ((shouldEdit() && !location.pathname.includes("/edit-course/")) ||
  //             (!shouldEdit() &&
  //               !location.pathname.includes("/create-course")))) ||
  //         action === "POP"
  //       ) {
  //         if (
  //           !window.confirm(
  //             "Tessstttt Are you sure you want to exit before saving?"
  //           )
  //         ) {
  //           navigate(`${curLocation.pathname}`);
  //         } else {
  //           unregisterReloadListener();
  //         }
  //       }
  //     });
  //     if (!shouldRegister) {
  //       unlisten();
  //     } else {
  //       return unlisten;
  //     }
  // }, [shouldRegister]);

  //
  //----------------------------------
  const saveProperty = (key, value, step) => {
    dispatchState({ key, value });
  };

const saveStepProperty = (key, value, step) => {
    switch (step) {
      case 1:
        dispatchStep1State({ key, value });
      case 2:
        dispatchStep2State({ key, value });
      case 3:
        dispatchStep3State({ key, value });
      case 4:
        dispatchStep4State({ key, value });
      default:
        dispatchState({ key, value });
    }

    if (
      [
        "step1Completed",
        "step2Completed",
        "step3Completed",
        "step4Completed",
      ].includes(key)
    ) {
      dispatchState({ key, value });
    }
  };

  //--TutorStripe Status Api
  const checkStatus = async () => {
    try {
      const result = await getTutorStripeStatus(user.uid);
      if (result.data.status === "ERROR") {
        console.error(
          "CreateCourse -  useEffect - checking stripe status Error",
          result
        );
      } else {
        if (result.data.data) {
          if (result.data.data.login_link) {
            saveProperty("stripeLink", result.data.data.login_link);
          }
        }
      }
    } catch (e) {}
  };

  //--Tutor ExistingCoursesList Api
  async function getFreeCoursesCount() {
    const result = await getCourseListOfTutor(user.uid);
    if (result && result.data?.courses) {
      console.log("CourseList - useEffect - got courses", result.data);
      const freeCoursesCount = result.data.courses.length;
      saveProperty("freeCoursesCount", freeCoursesCount);
      saveStepProperty("freeCoursesCount", freeCoursesCount, 1);

      if (
        !user?.ngoUser &&
        mode !== "edit" &&
        user.stripeStatus !== STRIPE_REG_STATUSES.APPROVED &&
        freeCoursesCount > 1
      ) {
        Swal.fire({
          icon: "warning",
          confirmButtonColor: "#3085d6",
          title: "Your Stripe Account is not Active",
          text: "You cannot create another course, until you create/update your Stripe account.",
        }).then(() => {
          navigate("/courses");
        });
      }
    }
  }

  const registerStripe = () => {
    navigate("/profile");
    /*setLoading && setLoading(true);
    stripeRegisterAccount(user.uid).finally(() => {
      setLoading && setLoading(true);
    }); */
  };

  const updateStripe = () => {
    if (stateData?.stripeLink) {
      window.open(stateData?.stripeLink, "_blank", "noopener,noreferrer");
    }
  };

  //--CourseInfo Api for EditCourses
  const getCourseInfo = async (courseId) => {
    setLoading && setLoading(true);
    const result = await getCourseDetails(courseId);
    setLoading && setLoading(false);

    if (result?.data && Object.keys(result.data).length > 0) {
      console.log(
        "CourseDetails - useEffect - got course details",
        result.data
      );
      if (result.data.startDate && moment(result.data.startDate)) {
        result.data.startDate = moment(result.data.startDate).format(
          "YYYY[-]MM[-]DD"
        );
      }
      if (result.data.endDate && moment(result.data.endDate)) {
        result.data.endDate = moment(result.data.endDate).format(
          "YYYY[-]MM[-]DD"
        );
      }

      if (result.data.startHour) result.data.startTime = result.data.startHour;

      if (result.data.endHour) result.data.endTime = result.data.endHour;

      if (result.data.autoSlots) {
        result.data.addManualSlots = false;
        result.data.addingAutoSlots = true;
      } else {
        result.data.addManualSlots = true;
        result.data.addingAutoSlots = false;
      }

      setCourseDetails(result.data);

      setExistingShortName(result.data?.nameShort);
      registerReloadListener();
    } else {
      //Navigate back to courses dashboard screen
      unregisterReloadListener();
      navigate("/courses");
    }
  };

  const generateLocalSlots = async (
    tutorDetailsId,
    startDate,
    endDate,
    startTime,
    endTime,
    timezoneOffset
  ) => {
    console.log(
      "generateLocalSlots:",
      tutorDetailsId,
      startDate,
      endDate,
      startTime,
      endTime,
      timezoneOffset
    );
    let allDays = true;

    let workingHourStart = 8;
    if (startTime) {
      workingHourStart = startTime;
    }

    let totalWorkingHour = 12;
    if (endTime) {
      totalWorkingHour = endTime - startTime;
    }

    let start = moment(startDate).startOf("day");
    let end = moment(endDate).startOf("day");

    let duration = moment.duration(end.diff(start));
    let daysdiff = duration.asDays();
    console.log("total daydiff: ", daysdiff);
    let allAvailabilitySlots = [];

    for (let i = 0; i <= daysdiff; i++) {
      let dow = start.day();
      if (allDays || (dow != 0 && dow != 6)) {
        // getting working day
        console.log(start, " day of week: ", dow);
        let slot = { startdate: start.format("YYYY-MM-DD HH:mm:ss"), dow: dow };
        allAvailabilitySlots.push(slot);

        let dayhour = moment(start.format("YYYY-MM-DD HH:mm:ss"));

        dayhour.add(workingHourStart, "hours");
        slot.start = dayhour.format("YYYY-MM-DD HH:mm:ss");
        slot.startTimestamp = dayhour.unix();

        dayhour.add(totalWorkingHour, "hours");
        slot.end = dayhour.format("YYYY-MM-DD HH:mm:ss");
        slot.endTimestamp = dayhour.unix();

        slot.hourSlots = [];
        let hourlap = 3600;
        let starthour = slot.startTimestamp;
        let endhour = starthour;

        while (endhour < slot.endTimestamp) {
          endhour = endhour + hourlap;
          slot.hourSlots.push({ start: starthour, end: endhour });
          starthour = endhour;
        }
      }
      start.add(1, "days");
    }

    console.log("allAvailabilitySlots:", allAvailabilitySlots);
    let payload = {
      tutorDetailsId: tutorDetailsId,
      allAvailabilitySlots: allAvailabilitySlots,
    };
    console.log(JSON.stringify(payload));

    const result = await createBulkSlots(payload);
    console.log("tutor bulk slots response" + JSON.stringify(result?.data));
  };

  useEffect(() => {
    if (courseDetails) {
      saveStepProperty("nameFull", courseDetails.nameFull, 1);
      saveStepProperty("nameShort", courseDetails.nameShort, 1);
      saveStepProperty("summary", courseDetails.summary, 1);
      saveStepProperty("rate", courseDetails.rate, 1);

      saveStepProperty("startTime", courseDetails.startTime, 3);
      saveStepProperty("endTime", courseDetails.endTime, 3);
      saveStepProperty("addManualSlots", courseDetails.addManualSlots, 3);
      saveStepProperty("addingAutoSlots", courseDetails.addingAutoSlots, 3);

      saveStepProperty(
        "language",
        courseDetails.language && courseDetails.language !== "undefined"
          ? {
              label: capitalize(courseDetails.language),
              value: courseDetails.language,
            }
          : defaultLanguage,
        1
      );

      const tutoringContentTypeOpts = [
        { value: "curriculum", label: "School Subjects" },
        { value: "extra curriculum", label: "Skill Training" },
      ];

      saveStepProperty(
        "tutoringContentType",
        courseDetails.curriculum === false
          ? tutoringContentTypeOpts[1]
          : tutoringContentTypeOpts[0],
        1
      );

      saveStepProperty(
        "tutoringRegistration",
        {
          label: capitalize(courseDetails.registration),
          value: courseDetails.registration,
        },
        2
      );

      saveStepProperty(
        "subjects",
        [
          {
            value: courseDetails.subject,
            label: courseDetails.subject,
          },
        ],
        2
      );

      saveStepProperty(
        "subjectAreas",
        [
          {
            value: courseDetails.subjectArea,
            label: courseDetails.subjectArea,
          },
        ],
        2
      );

      saveStepProperty(
        "tutoringPreference",
        {
          value: courseDetails.tutoringPreference,
          label: capitalize(courseDetails.tutoringPreference),
        },
        2
      );

      const newStartDate = new Date(courseDetails.startDate);
      const startDate_Formated = moment(newStartDate).format("YYYY[-]MM[-]DD");
      saveStepProperty("startDate", newStartDate, 3);
      saveStepProperty("formattedStartDate", startDate_Formated, 3);

      const newEndDate = new Date(courseDetails.endDate);
      const endDate_Formated = moment(newEndDate).format("YYYY[-]MM[-]DD");
      saveStepProperty("endDate", newEndDate, 3);
      saveStepProperty("formattedEndDate", endDate_Formated, 3);

      saveStepProperty("minStartDate", newStartDate, 3);

      saveStepProperty(
        "photoUrl",
        courseDetails.photoUrl === "undefined" ? null : courseDetails.photoUrl,
        4
      );

      saveStepProperty(
        "videoThumbnailUrl",
        courseDetails.videoThumbnailUrl === "undefined"
          ? null
          : courseDetails.videoThumbnailUrl,
        4
      );

      saveStepProperty(
        "videoUrl",
        courseDetails.videoUrl === "undefined" ? null : courseDetails.videoUrl,
        4
      );

      saveStepProperty("step1Completed", true, 1);
      saveStepProperty("step2Completed", true, 2);
      saveStepProperty("step3Completed", true, 3);
      saveStepProperty("step4Completed", true, 4);
      saveProperty("doneBtnEnabled", true);
    }
  }, [courseDetails]);
  //
  // submit:------------------------------------------------------------
  const onSubmit = async () => {
    console.log(stateData)
    if (!stateData?.doneBtnEnabled) {
      return;
    }
    setLoading && setLoading(true);

    if (await checkNameShort()) {
      //TODO: short name exist
      setLoading && setLoading(false);
      Swal.fire({
        title: "Shortname exists!",
        text: "Shortname already exists. Please use a different name",
      });
      return;
    }

    // if the photo has been updated, we need the download url
    const mediaUrls = await uploadMedia(
      courseDetails?.courseId,
      courseDetails,
      step4State
    );

    //let date = new Date();
    //let timezoneOffset = date.getTimezoneOffset();

    let arr = [];
    for (let i = 0; i < 365; i++) {
      let d = new Date();
      d.setDate(i);
      let newoffset = d.getTimezoneOffset();
      arr.push(newoffset);
    }
    let DST = Math.min.apply(null, arr);
    let nonDST = Math.max.apply(null, arr);

    let timezoneOffset = DST;

    let isNgoUser = user?.ngoUser;

    let payload = {
      subjectArea:
        step2State?.subjectAreas && step2State?.subjectAreas[0]?.value,
      nameFull: step1State?.nameFull,
      nameShort: step1State?.nameShort,
      summary: step1State?.summary,
      visibility: "public",
      // photoUrl: updatedCoursePhotoUrl ? updatedCoursePhotoUrl : photoUrl,
      photoUrl: mediaUrls.photoUrl,
      tutoringRegistration: step2State?.tutoringRegistration?.value,
      tutoringPreference: step2State?.tutoringPreference?.value,
      startDate: step3State?.formattedStartDate,
      endDate: step3State?.formattedEndDate,
      addManualSlots: step3State?.addManualSlots,
      addingAutoSlots: step3State?.addingAutoSlots,
      startTime: step3State?.startTime,
      endTime: step3State?.endTime,
      timezoneOffset: timezoneOffset,
      specialEducation:
        step2State?.tutoringPreference === "specEdu" ? true : false,
      type: isNgoUser ? "free" : step1State?.rate ? "paid" : "free", // TODO
      rate: isNgoUser ? 0 : step1State?.rate,
      videoThumbnailUrl: mediaUrls.videoThumbnailUrl,
      videoUrl: mediaUrls.videoUrl,
      language: step1State?.language?.value ?? defaultLanguage.value,
    };
    console.log("creating course:", payload);

    // in the case where the short name exists, we don't do anything
    // if (!nameShortExists) {
    if (!(await checkNameShort())) {
      unregisterReloadListener();

      // call API
      if (mode === "edit") {
        const result = await editCourse(payload, selectedCourseId);
        console.log("course edit response" + result);

        if (result?.status === 200) {
          setExistingShortName(payload.nameShort);
          showAlertAndDismiss("Success", "Course details updated successfully");
          if (result.data?.autoSlots) {
            generateLocalSlots(
              result.data?.tutorDetailsId,
              result.data?.startDate,
              result.data?.endDate,
              result.data?.startTime,
              result.data?.endTime,
              result.data?.timezoneOffset
            );
          }
        }
      } else {
        const result = await createCourse(payload, user.uid);
        console.log("course Create response" + result);

        let data = result?.data;
        if (result?.status !== 200) {
          setLoading && setLoading(false);
          Swal.fire({
            title: "Error",
            text: `${
              result && result.data
                ? result?.data
                : "Faild to create a course. Please try again"
            }`,
          });
          return;
        }
        //Upload media if exists
        const mediaUrls = await uploadMedia(data.courseId, data, step4State);
        if (mediaUrls.videoUrl || mediaUrls.photoUrl) {
          payload.courseId = data.courseId;
          payload.videoUrl = mediaUrls.videoUrl;
          payload.videoThumbnailUrl = mediaUrls.videoThumbnailUrl;
          payload.photoUrl = mediaUrls.photoUrl;
          const rr = await editCourse(payload, data.courseId);
          console.log("course Editted response" + rr);
        }

        if (result.data?.autoSlots) {
          generateLocalSlots(
            result.data?.tutorDetailsId,
            result.data?.startDate,
            result.data?.endDate,
            result.data?.startTime,
            result.data?.endTime,
            result.data?.timezoneOffset
          );
        }

        if (result?.status === 200) {
          Swal.fire({
            title: "Success!",
            text: "Course created successfully",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            navigate("/courses");
          });

          //showAlertAndDismiss("Success", "Course created successfully")
        }
      }
      setLoading && setLoading(false);
    } else {
      setLoading && setLoading(false);
    }
  };

  const showAlertAndDismiss = (title, message) => {
    Swal.fire({
      title: title,
      text: message,
    }).then(() => {
      navigate("/courses");
    });
  };
  const checkNameShort = async () => {
    const { nameShort, nameShortExists } = stateData;

    // only send the request if the short name exists
    // and it is not the same as the already saved one
    if (nameShort && nameShort !== courseDetails?.nameShort) {
      const result = await getCourseExists(nameShort, user.uid);
      const exists = result?.data?.exist;
      console.log(`checkNameShort:`, nameShort, exists);
      saveProperty("nameShortExists", exists);
      return exists;
    }
    return nameShortExists ?? false;
  };

  // uploads file to firestore and returns download url
  const uploadCoursePhoto = async (courseId, photoFile, photoInfo) => {
    if (!courseId) {
      return;
    }
    try {
      const result = await uploadFilePromise(
        photoFile,
        photoInfo.type,
        photoInfo.path,
        `course_photo`,
        `userData/${user.uid}/courses/${courseId}/`,
        (progress) => {
          setPhotoUploadProgress(Math.floor(progress));
        }
      );
      setPhotoUploadProgress(0);

      console.log("uploadCoursePhoto - upload result:", result);
      setSubmissionError(false);
      return result.downloadUrl;
    } catch (e) {
      console.error("uploadCoursePhoto - uploading error", e);
      setSubmissionError(true);
    }
  };

  const uploadCourseVideoThumbnail = async (courseId, file) => {
    if (!courseId) {
      return;
    }
    try {
      const result = await uploadBase64FilePromise(
        file,
        "image/jpeg",
        `course_video_thumbnail`,
        "jpg",
        `userData/${user.uid}/courses/${courseId}/`
      );
      return result.downloadUrl;
    } catch (e) {
      console.error("uploadCourseVideoThumbnail - uploading error", e);
      return null;
    }
  };

  const uploadCourseVideo = async (courseId, videoFile, videoInfo) => {
    try {
      const result = await uploadFilePromise(
        videoFile,
        videoInfo.type,
        videoInfo.path,
        `course_video`,
        `userData/${user.uid}/courses/${courseId}/`,
        (progress) => {
          setVideoUploadProgress(Math.floor(progress));
        }
      );
      setVideoUploadProgress(0);

      console.log("uploadVideo - upload result:", result);
      // setVideoOK(true);
      // setVideoUploadError(false);
      setSubmissionError(false);

      // payload.introduceVideoFileType = result.fileType;
      return result.downloadUrl;
    } catch (e) {
      console.error("uploadVideo - uploading error", e);
      // setVideoUploadError(true);
      // setVideoOK(false);
      setSubmissionError(true);
    }
  };

  const uploadMedia = async (courseId, courseDetails, mediaData) => {
    let updatedCourseVideoUrl =
      courseId && mediaData.videoOK && mediaData.videoFile
        ? await uploadCourseVideo(
            courseId,
            mediaData.videoFile,
            mediaData.videoInfo
          )
        : courseDetails?.videoUrl;
    updatedCourseVideoUrl =
      updatedCourseVideoUrl === "undefined" ? null : updatedCourseVideoUrl;
    saveStepProperty("videoUrl", updatedCourseVideoUrl, 4);

    let updatedCourseVideoThumbnailUrl =
      courseId && mediaData.videoOK && mediaData.videoThumbnail
        ? await uploadCourseVideoThumbnail(courseId, mediaData?.videoThumbnail)
        : courseDetails?.videoThumbnailUrl;
    updatedCourseVideoThumbnailUrl =
      updatedCourseVideoThumbnailUrl === "undefined"
        ? null
        : updatedCourseVideoThumbnailUrl;
    saveStepProperty("videoThumbnailUrl", updatedCourseVideoThumbnailUrl, 4);

    let updatedCoursePhotoUrl =
      courseId &&
      mediaData.photoOK &&
      mediaData.photoFile &&
      mediaData.photoInfo
        ? await uploadCoursePhoto(
            courseId,
            mediaData.photoFile,
            mediaData.photoInfo
          )
        : courseDetails?.photoUrl;
    updatedCoursePhotoUrl =
      updatedCoursePhotoUrl === "undefined" ? null : updatedCoursePhotoUrl;
    saveStepProperty("photoUrl", updatedCoursePhotoUrl, 4);

    return {
      videoUrl: updatedCourseVideoUrl
        ? updatedCourseVideoUrl
        : step4State?.videoUrl,
      videoThumbnailUrl: updatedCourseVideoThumbnailUrl
        ? updatedCourseVideoThumbnailUrl
        : step4State?.videoThumbnailUrl,
      photoUrl: updatedCoursePhotoUrl
        ? updatedCoursePhotoUrl
        : step4State?.photoUrl,
    };
  };

  useEffect(() => {
    if (videoUploadProgress > 0) {
      setLoadingMessage(`Uploading video: ${videoUploadProgress}%`);
    } else if (photoUploadProgress > 0) {
      setLoadingMessage(`Uploading photo: ${photoUploadProgress}%`);
    } else {
      setLoadingMessage(null);
    }
  }, [videoUploadProgress, photoUploadProgress]);

  //
  //delete course:-----------------------------------------------------
  const deleteCourseWait = async () => {
    try {
      setLoading && setLoading(true);
      let res = await deleteCourse(selectedCourseId);
      setLoading && setLoading(false);
      if (res.data.status === "SUCCESS") {
        // window.location.reload();
        unregisterReloadListener();
        setTimeout(() => {
          navigate("/courses");
        }, 500);
      } else if (res.data.status === "ERROR") {
        Swal.fire({
          title: "Unable to Delete Course",
          // This message was res.data.errorMessage, I had to change it to the below
          text: "You cannot delete this course now, as there is an already paid session. Please first cancel the session and then try to delete the course.",
          icon: "error",
          cancelButtonColor: "#d33",
        });
      }
    } catch (e) {
      Swal.fire({
        title: "Error",
        text: "There has been an error, please try again",
        icon: "error",
        showCancelButton: true,
        cancelButtonColor: "#d33",
      });
    }
  };

  const approveCourseDelete = () => {
    Swal.fire({
      title: "Course Deletion",
      text: "Are you sure you want to delete this course?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCourseWait();
      } else {
        Swal.fire({
          icon: "info",
          title: "Cancelled",
          text: "Course was not deleted",
        });
      }
    });
  };

  //
  //reducers:----------------------------------------------------------
  const reducerStateFn = (state, action) => {
    if (action.key) {
      if (action.key === "reset") {
        return action.value;
      }
      return { ...state, ...{ [action.key]: action.value } };
    }
  };

  const [step1State, dispatchStep1State] = useReducer(reducerStateFn, {});
  const [step2State, dispatchStep2State] = useReducer(reducerStateFn, {});
  const [step3State, dispatchStep3State] = useReducer(reducerStateFn, {});
  const [step4State, dispatchStep4State] = useReducer(reducerStateFn, {});
  // const [courseState, dispatchCourseState] = useReducer(reducerCourseFn, {});
  const [stateData, dispatchState] = useReducer(reducerStateFn, {});
  // const [mediaStateData, dispatchMediaState] = useReducer(reducerMediaFn, {});

  useEffect(() => {
    const doneBtnEnable =
      stateData?.step1Completed === true &&
      stateData?.step2Completed === true &&
      stateData?.step3Completed === true &&
      stateData?.step4Completed === true;
    if (stateData?.doneBtnEnabled !== doneBtnEnable) {
      dispatchState({ key: "doneBtnEnabled", value: doneBtnEnable });
    }
    
  }, [stateData]);

  //
  // widgets:---------------------------------------------

  const stepButtons = () => {
    return (
      <div className="stepsContainer h-full rounded-full flex lg:flex-col justify-around items-center">
        {[1, 2, 3, 4].map((value, index) => {
          return (
            <div
              key={index}
              className="w-16 sm:w-20 h-16 sm:h-20 content-center text-center grid grid-cols-1 grid-rows-1"
            >
              <div className="w-full h-full flex flex-col lg:flex-row justify-end items-center col-start-1 row-start-1">
                <div
                  className="semi-circle content-center text-center"
                  style={{
                    backgroundColor:
                      [
                        step1State?.step1Completed,
                        step2State?.step2Completed,
                        step3State?.step3Completed,
                        step4State?.step4Completed,
                      ][value - 1] === true
                        ? commonColor.deepGreen
                        : commonColor.disable,
                  }}
                ></div>
              </div>

              <div className="w-full h-full p-2 flex flex-row justify-end items-center col-start-1 row-start-1">
                <button
                  className="w-full h-full focus:outline-none rounded-full bg-white content-center text-center text-3xl font-bold cursor-pointer"
                  style={{
                    borderColor: commonColor.deepGreen,
                    backgroundColor:
                      currentStep === value ? commonColor.deepPink : "white",
                    color: currentStep === value ? "white" : "black",
                  }}
                  onClick={() => {
                    setCurrentStep(value);
                  }}
                >
                  {value}
                </button>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderCurrentStep = () => {
    if (currentStep === 1)
      return (
        <CourseStep1
          key={1}
          user={user}
          currentStep={currentStep}
          saveProperty={(key, value) => {
            saveStepProperty(key, value, 1);
          }}
          mode={mode}
          courseDetails={courseDetails}
          stateData={step1State}
          oldNameShort={existingShortName}
        />
      );
    else if (currentStep === 2)
      return (
        <CourseStep2
          key={2}
          user={user}
          currentStep={currentStep}
          saveProperty={(key, value) => {
            saveStepProperty(key, value, 2);
          }}
          mode={mode}
          courseDetails={courseDetails}
          stateData={step2State}
        />
      );
    else if (currentStep === 3)
      return (
        <CourseStep3
          key={3}
          user={user}
          saveProperty={(key, value) => {
            saveStepProperty(key, value, 3);
          }}
          currentStep={currentStep}
          mode={mode}
          courseDetails={courseDetails}
          stateData={step3State}
        />
      );
    else if (currentStep === 4)
      return (
        <CourseStep4
          key={4}
          user={user}
          saveProperty={(key, value) => {
            saveStepProperty(key, value, 4);
          }}
          currentStep={currentStep}
          mode={mode}
          courseDetails={courseDetails}
          stateData={step4State}
        />
      );
  };

  // const stripeStatusCheck = () => {
  //   return (
  //     user.stripeStatus !== STRIPE_REG_STATUSES.APPROVED && (
  //       <div className="w-full py-1 px-2 text-orange-500 flex justify-center">
  //         {user.stripeStatus === STRIPE_REG_STATUSES.INACTIVE ? (
  //           <div>
  //             Some information is missing in your stripe account. You need to
  //             update your
  //             <span
  //               className="cursor-pointer pl-1"
  //               style={{ color: commonColor.violet }}
  //               onClick={() => updateStripe()}
  //             >
  //               Stripe Account
  //             </span>
  //           </div>
  //         ) : user.stripeStatus === STRIPE_REG_STATUSES.INCOMPLETE ? (
  //           <div>
  //             Your stripe account is incomplete. You need to complete your
  //             <span
  //               className="cursor-pointer pl-1"
  //               style={{ color: commonColor.violet }}
  //               onClick={() => registerStripe()}
  //             >
  //               Stripe Account
  //             </span>
  //           </div>
  //         ) : stateData?.freeCoursesCount ? (
  //           {
  //             0: (
  //               <div>
  //                 You can create up to 2 free courses, then you will need to
  //                 create a
  //                 <span
  //                   className="cursor-pointer pl-1"
  //                   style={{ color: commonColor.violet }}
  //                   onClick={() => registerStripe()}
  //                 >
  //                   Stripe Account
  //                 </span>
  //               </div>
  //             ),
  //             1: (
  //               <div>
  //                 This is the last free course you can create, then you will
  //                 need to create a
  //                 <span
  //                   className="cursor-pointer pl-1"
  //                   style={{ color: commonColor.violet }}
  //                   onClick={() => registerStripe()}
  //                 >
  //                   Stripe Account
  //                 </span>
  //               </div>
  //             ),
  //           }[stateData?.freeCoursesCount] || (
  //             <div>
  //               You cannot create more than two free courses, you need to create
  //               a
  //               <span
  //                 className="cursor-pointer pl-1"
  //                 style={{ color: commonColor.violet }}
  //                 onClick={() => registerStripe()}
  //               >
  //                 Stripe Account
  //               </span>
  //             </div>
  //           )
  //         ) : (
  //           <div>
  //             You cannot create more than two free courses, you need to create a
  //             <span
  //               className="cursor-pointer pl-1"
  //               style={{ color: commonColor.violet }}
  //               onClick={() => registerStripe()}
  //             >
  //               Stripe Account
  //             </span>
  //           </div>
  //         )}
  //       </div>
  //     )
  //   );
  // };

  const actionButtons = () => {
    return (
      <div className="w-full h-full flex flex-row justify-between lg:justify-around lg:space-x-4 items-center">
        <>
          <Swotbutton
            text={"Cancel"}
            bgColor={commonColor.disable}
            className={"py-2 hidden md:block"}
            onClick={() => {
              unregisterReloadListener();
              setTimeout(() => {
                navigate("/courses");
                // navigate(-1);
              }, 500);
            }}
            style={{}}
          />
          <></>
          {/* <BackIcon
            className="w-10 h-10 md:hidden"
            onClick={() => {
              unregisterReloadListener();
              setTimeout(() => {
                //  navigate(-1);
                navigate("/courses");
              }, 500);
            }}
          /> */}

          <img
            src={closeSrc}
            className="w-10 h-10 md:hidden"
            alt="close"
            onClick={() => {
              unregisterReloadListener();
              setTimeout(() => {
                //  navigate(-1);
                navigate("/courses");
              }, 500);
            }}
          />
        </>
        <Swotbutton
          className={"py-2"}
          text={mode === "edit" ? "Save" : "Create"}
          bgColor={
            mode === "edit" ? commonColor.deepGreen : commonColor.deepPink
          }
          onClick={onSubmit}
          style={{}}
          disabled={
            !stateData?.doneBtnEnabled 
            // ||
            // (!user?.ngoUser &&
            //   mode !== "edit" 
            //   &&
            //   user.stripeStatus !== STRIPE_REG_STATUSES.APPROVED &&
            //   stateData?.freeCoursesCount > 1
            // )
          }
        />

        {selectedCourseId && (
          <>
            <Swotbutton
              text="Delete"
              className={"py-2 hidden md:block"}
              onClick={() => approveCourseDelete()}
              style={{
                minWidth: "8rem",
              }}
            />
            <DeleteIcon
              className="h-10 w-8 md:hidden"
              onClick={() => approveCourseDelete()}
            />
          </>
        )}
        {/* {showDeleteBtn()} */}
      </div>
    );
  };
  // main body:--------------------------------------------
  return (
    <div
      className="w-full h-full flex justify-center items-center"
      style={
        {
          // backgroundColor: commonColor.deepGreen,
        }
      }
    >
      {unstable_usePrompt({
        when: promtExit,
        message: "Are you sure you want to navigate?",
      })}
      <div className="w-full h-full xl:w-3/4 lg:w-3/4 flex-col flex lg:h-full gap-y-2 lg:gap-y-4 items-center px-4">
        <div className="max-w-lg mt-auto">
          <p className="text-white text-center">
            Complete each step by clicking on the numbers
          </p>
        </div>
        <div className="w-full  max-w-3xl h-5/6 lg:h-4/5 flex flex-row  justify-center items-center">
          <div className="gridParent z-0 w-full h-full">
            {stepButtons()}

            <div
              className="formContainer  h-full lg:px-5 lg:py-2 pt-10 lg:pt-5 pb-4 px-4 bg-white"
              style={{
                boxShadow: `0 10px 25px 0 rgba(0, 0, 0, 0.6)`,
                borderRadius: 25,
                // overflowY: "hidden",
              }}
            >
              <div className=" h-full md:pt-0 pt-10">
                <div className="course-setup-main-section">
                  <div className="w-full h-full flex flex-col justify-end p-0 lg:px-5 lg:py-2 ">
                    <div className="h-full">
                      {/* Add steps here */}
                      {renderCurrentStep()}
                    </div>
                    <div>
                      <p className="italic text-sm">
                        * The first three steps fields are mandatory to create the course.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="footer">
                  <div className="h-full w-full flex items-center justify-center">
                    {actionButtons()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="mb-auto">
           {!user?.ngoUser && stripeStatusCheck()} 
          </div> */}
      </div>

      <Modal
        isOpen={stateData?.previewUploadedPhoto}
        onRequestClose={() => {
          saveProperty("previewUploadedPhoto", false);
        }}
      >
        <img
          src={step3State?.photoUrl}
          alt={"UploadedCoursePhoto"}
          className="m-auto"
        />
      </Modal>
      <LoaderModal isOpen={loading} message={loadingMessage} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseSetup);

const courseDataModel = [
  "subjectArea",
  "nameFull",
  "nameShort",
  "summary",
  "visibility",
  "photoUrl",
  "tutoringRegistration",
  "tutoringPreference",
  "startDate",
  "endDate",
  "specialEducation",
  "rate",
  "videoThumbnailUrl",
  "videoUrl",
];
