import React, { useEffect } from "react";
import  LoadingOverlay  from "@ronchalant/react-loading-overlay";
import { css } from "@emotion/react";
import { ClimbingBoxLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 2rem auto;
  border-color: white;
`;


const LoaderOverlay = (props) => {

  return (
  <LoadingOverlay
    active={props.loading}
    fadeSpeed={200}
    spinner={
      <ClimbingBoxLoader
        color={"#ffffff"}
        loading={true}
        css={override}
        size={10}
      />
    }
    text={props.text}>
     {props.children}
  </LoadingOverlay>
  )
};

export default LoaderOverlay
