import React, { useState, useEffect, memo } from "react";
import { Element } from "react-scroll";
import { TermsAndPrivacyPolicy } from "../../components/common/consent";
import FooterViewVerson from "../../components/common/footerView";
import { useLocation, useNavigate } from "react-router-dom";
import { historyState } from "../../services/navigation/history";

import "./newLanding.css";
import Menu from "./components/menu";
import PartOne from "./parts/partOne";
import PartTwo from "./parts/partTwo";
import PartThree from "./parts/partThree";
import PartFour from "./parts/partFour";
import PartFive from "./parts/partFive";
import PartSix from "./parts/partSix";
import PartSeven from "./parts/partSeven";
import PartEight from "./parts/partEight";
import PartNine from "./parts/partNine";
import PartTen from "./parts/partTen";
import Preloader from "./parts/preloader";
import { isMobileDevice } from "../../utils/uiUtils";
import DotRing from "../../components/common/customCursor/dotRing";
import { useSpring, animated } from "@react-spring/web";
import Popup from "./components/popup";

const Layout = memo(({ loading }) => {
  const startMenuAnimation = useSpring({
    from: { opacity: 0, y: 20 },
    to: loading
      ? {}
      : {
          opacity: 1,
          y: 0,
          config: { duration: 1000 },
          delay: 0.25,
        },
  });

  const [isMobileView, setIsMobileView] = useState(isMobileDevice);
  const [popupContent, setPopupContent] = useState(null);
  const [popupElementId, setPopupElementId] = useState(null);
  const received = useLocation().state;

  useEffect(() => {
    if (received === "blogs") {
      scrollToElement("blogs", 1500, "instant");
    }
  }, [received]);
  const openPopup = (content) => {
    document.body.style.overflow = "hidden";
    setPopupContent(content);
  };

  const closePopup = () => {
    setPopupContent(null);
    document.body.style.overflow = "auto";
    if (popupElementId) {
      scrollToElement(popupElementId, 1000, "instant");
      setPopupElementId(null);
    }
  };

  const scrollToElement = (elementId, delay, behavior) => {
    const element = document.getElementById(elementId);
    if (element) {
      if (delay) {
        element.scrollIntoView({ behavior: behavior ?? "smooth" });
        setTimeout(function () {
          element.scrollIntoView({ behavior: behavior ?? "smooth" });
        }, delay);
      } else {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <>
      <div className="flex overflow-x-hidden">
        {/* Menu */}
        <Menu className="" />
        {/* main Content */}
        <div className="new-landing-layout md:w-[80%] w-full md:ml-[20%] ml-0">
          {" "}
          <animated.div
            className="flex mb-10 md:mb-20 md:h-fit max-w-full"
            // initial={isMobileView ? {} : { opacity: 0, y: 20 }}
            // animate={isMobileView ? null : startMenuAnimation}
            style={startMenuAnimation}
          >
            <Element name="home" className="element w-full">
              {" "}
              <PartOne
                loading={loading}
                // startContentAnimation={
                //   isMobileView ? null : startContentAnimation
                // }
                id="home_landing"
              />
            </Element>
          </animated.div>
          <Element name="explore_learning" className="element">
            <PartThree
              id="explore_learning"
              showPopup={(content) => {
                openPopup(content);
              }}
            />
          </Element>
          <Element name="educate" className="element">
            <PartTwo id="educate" />
          </Element>
          <Element name="innovation_hub" className="element">
            <PartFour
              id="enterprise"
              showPopup={(content) => {
                openPopup(content);
              }}
            />
          </Element>
          {/* Carousel */}
          {/* <Element name="your_journey" className="element">
            <PartFive id="your_journey" />
          </Element> */}
          <Element name="unlock_potential" className="element">
            <PartSix id="unlock_potential" />
          </Element>
          <Element name="join_now" className="element">
            <PartEight id="enroll" />
          </Element>
          <Element name="success_stories" className="element">
            <PartNine id="success_stories" />
          </Element>
          <Element name="blogs" className="element">
            <PartTen
              id="blogs"
              showPopup={(content) => {
                openPopup({ ...content, isRichContent: true });
                // scrollToElement("blogs", 500);
                setPopupElementId("blogs");
              }}
            />
          </Element>
          {/* i can put here part seven for enroll */}
          <Element name="your_tutors" className="element">
            <PartSeven id="your_tutors" />
          </Element>
          {/* Footer */}
          <div id="terms-and-conditions" className="w-full text-center py-4">
            <TermsAndPrivacyPolicy />
          </div>
          <FooterViewVerson textColorClass={"black"} />
          {/* End of Footer */}
        </div>
      </div>
      {popupContent && (
        <Popup
          image={popupContent.image}
          title={popupContent.title}
          text={popupContent.text}
          isRichContent={popupContent.isRichContent}
          onClose={closePopup}
        />
      )}
    </>
  );
});

function App() {
  const [loading, setLoading] = useState(true);
  // const menuControls = useAnimation();
  // const contentControls = useAnimation();

  const handlePreloaderDone = () => {
    setLoading(false);
  };
  let navigate = useNavigate();
  useEffect(() => {
    historyState.navigate = navigate;
  }, [navigate]);

  return (
    <>
      <DotRing />
      {loading && <Preloader onCompletion={handlePreloaderDone} />}
      <Layout
        className="new_landing_layout"
        loading={loading}
        // startMenuAnimation={menuControls}
        // startContentAnimation={contentControls}
      />
    </>
  );
}

export default App;
