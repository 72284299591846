import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MainPageWithMenu from "../../components/common/MainPageTemplate/mainPageWithMenu";
import UploadDashboard from "./dashboards/uploadDashboard.js";

const UserUploadDashboard = ({ user }) => {
  console.log("Betatester", user);

  return (
    <UploadDashboard />
    // <MainPageWithMenu user={user} menuSelected={"betatester"}>
    //   {/* UploadDashboard */}
    //   <h2>Hello from upload Dashboard</h2>
    // </MainPageWithMenu>
  );
};
const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserUploadDashboard);
