import React from "react";
import { useNavigate } from "react-router-dom";

const LandingpageButton = ({ link }) => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate(link)}
      className=" bg-[rgba(156,57,103,1)] mt-6 w-fit px-6 py-2 text-base text-white font-medium rounded-md shadow-md"
    >
      Log in
    </button>
  );
};

export default LandingpageButton;
