import { useState, useEffect } from "react";
import { searchUsers } from "../../services/api";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import commonColor from "../../theme/commonColor";
import closeSrc from "../../assets/images/close-icon.png";
import { BeatLoader } from "react-spinners";
import Swotbutton from "../../components/common/buttons/swotbutton";
import ChatUserModel from "../models/chatUser";

const ChatSearchComponent = ({ user, onCancel, onSubmit }) => {
  const [allData, setAllData] = useState([]);
  const [dropdownData, setDropdownData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchText, setSearchText] = useState<string>();
  const [searching, setSearching] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());
  const searchDelay = 500; //miliseconds

  useEffect(() => {
    abortController.abort();
    if (!searchText || searchText.trim() == "") {
      setDropdownData([]);
    } else {
      const controller = new AbortController();
      setAbortController(controller);
      search(searchText.trim(), controller.signal);
    }
  }, [searchText]);

  const search = async (searchString, abortSignal) => {
    const timeout = setTimeout(() => {
      if (!abortSignal.aborted) {
        setSearching(true);
        apiCall(searchString, abortSignal.aborted);
      }
    }, searchDelay);

    abortSignal.addEventListener("abort", () => {
      clearTimeout(timeout);
    });

    const apiCall = async (searchString, abortSignal) => {
      const result = await searchUsers(searchString);
      console.log(result);

      if (searchString == searchText) {
        if (result?.data?.data && !abortSignal.aborted) {
          //TODO: set data
          //  var dataList = result.data.products.map((p) => {
          //     return {"label": p.title, "value": p.id}
          //   });
          if (Array.isArray(result.data.data)) {
            setAllData(result.data.data);
          } else {
            setAllData([]);
          }
        }
        setSearching(false);
      }
    };
  };

  useEffect(() => {
    const selectedIds: any[] = [...selectedItems.map((i) => i["uid"]), user.uid];
    const filteredData = allData.filter((d) => !selectedIds.includes(d["uid"]));
    setDropdownData(filteredData);
  }, [selectedItems, allData]);

  const handleSelect = (value) => {
    const item = dropdownData.find((item) => item["uid"] === value);
    if (item) {
      setSelectedItems([...selectedItems, ...[item]]);
    }
  };

  const handleCombobocChange = (e) => {
  };
  const handleInput = (e) => {
    setSearchText(e.target.value);
  };
  return (
    <div className="w-80v md:w-70v lg:w-50v h-40v text-center max-h-full flex flex-col items-center">
      <div className="w-full p-2 flex justify-between">
        <Swotbutton
          text="Cancel"
          bgColor={commonColor.error}
          onClick={() => {
            // this.closePopover();
            onCancel();
          }}
        />
        <Swotbutton
          text="Next"
          disabled={(selectedItems?.length ?? 0) == 0}
          bgColor={
            (selectedItems?.length ?? 0) == 0
              ? commonColor.disable
              : commonColor.deepGreen
          }
          onClick={() => {
            // this.closePopover();
            onSubmit(selectedItems);
          }}
        />
      </div>
      <div className="w-full h-full flex flex-col items-center relative">
        <div className="py-2">Search chat members</div>
        <Combobox
          onSelect={handleSelect}
          aria-labelledby="sk-places"
          className={`w-full max-h-40v`}
          onChange={handleCombobocChange}
          openOnFocus={true}
        >
          <ComboboxInput
            value={searchText}
            onChange={handleInput}
            disabled={false}
            className="border border-gray-500 border-solid"
            style={{
              borderColor: commonColor.deepBlue,
            }}
          />
          <ComboboxPopover
            className="overflow-y-scroll"
            portal={false}
            style={{
              position: "absolute",
              zIndex: 100,
              marginTop: "0rem",
              width: "100%",
              maxHeight: "100%",
            }}
          >
            <hr />
            <ComboboxList>
              {searching ? (
                <div className="w-full h-10 flex justify-center items-center">
                  <BeatLoader
                    size={6}
                    loading={true}
                    color={commonColor.deepGreen}
                  />
                </div>
              ) : (dropdownData?.length ?? 0) > 0 ? (
                dropdownData.map(
                  ({ uid, firstName, lastName, accountType }) => (
                    <ComboboxOption key={uid} value={uid}>
                      <div className="flex flex-col lg:flex-row lg:items-center">
                        <div className="font-bold">
                          {firstName} {lastName ?? ""}
                        </div>
                        <span className="text-xs" style={{ color: commonColor.deepPink }}>
                          ({accountType})
                        </span>
                      </div>
                    </ComboboxOption>
                  )
                )
              ) : (
                <p
                  style={{
                    margin: 0,
                    color: "#454545",
                    padding: "0.25rem 1rem 0.75rem 1rem",
                    fontStyle: "italic",
                  }}
                >
                  {
                     (searchText?.trim().length ?? 0) == 0 ? "Start typing to search.." :  "No results :("
                  }
                </p>
              )}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
        {(selectedItems?.length ?? 0) > 0 && (
          <div className="p-4 max-h-full">
            <SelectedContainer
              items={selectedItems}
              pop={(id) => {
                const items = selectedItems.filter(
                  (item) => item["uid"] !== id
                );
                setSelectedItems(items);
              }}
            />
          </div>
        )}
        <div></div>
      </div>
    </div>
  );
};

const SelectedContainer = ({ items, pop }) => {
  return (
    <div className="w-full max-h-full overflow-y-scroll flex flex-wrap">
      {items.map((item) => (
        <SelectedUserWidget key={item.id} userItem={item} pop={pop} />
      ))}
    </div>
  );
};

const SelectedUserWidget = ({ userItem, pop }) => {
  return (
    <div className="p-1">
      <div className="flex items-center bg-[#40728855] rounded">
        <div className="flex flex-col items-start lg:flex-row lg:items-center">
          <div className="text-xs px-1">
            {userItem.title ??
              `${userItem.firstName ?? ""} ${userItem.lastName ?? ""}`}
          </div>
          <span className="text-xs" style={{ color: commonColor.deepPink }}>
            ({userItem.accountType})
          </span>
        </div>
        <div className="cursor-pointer p-1">
          <img
            src={closeSrc}
            className="w-4"
            alt="close"
            onClick={() => {
              pop(userItem.uid);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatSearchComponent;
export { SelectedUserWidget };
