const commonColor = {
  deepGreen: "#407288",

  deepBlue: "#4D5171",

  sourCherry: "#993B64",

  deepPink: "#FA005E", //(250,0,94) //(0.98,0, 0.36)

  violet: "#B6B9DF",

  correct: "#68d391",

  error: "#fc8181",

  disable: "#85889c",

  lilac: "#993B64",
  // calendar

  availableSlot: "#5bad31",

  spotifyGreen: "#1DB954",

  flatGreen: "#27ae60",

  babyBlue: "#EAF2FB",
};

export default commonColor;
