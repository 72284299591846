import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Modal, { QuickModal } from "../../common/modal/modal";
import "../../common/calendar/calendar.css";
import commonColor from "../../../theme/commonColor";
import {
  bookCourseSession,
  studentJoinVideoSession,
  enrollJoinWebinar,
  getSessionEnrolledDetails,
} from "../../../services/api";
import { saveBookingPayload } from "../../../services/paymentsManagement";
import { connect } from "react-redux";
import { ACCOUNT_TYPES, EventType } from "../../../config/constants";
import Swotbutton from "../../common/buttons/swotbutton";
import knotSm from "../../../assets/images/learn-small.png";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { updateRefreshCalendar } from "../../../services/notifications";
import { addReminder, deleteReminder } from "../../../services/reminders";
import { coursePayment } from "../../../services/paymentsManagement";
import StripeWrapper from "../../payments/stripeWrapper";
import { ClimbingBoxLoader } from "react-spinners";
import LoaderModal from "../../common/modal/loaderModal";
import Swal from "sweetalert2";
import { create30MinutesTimeslots } from "../../../services/calendar";

const StudentCalendar = ({
  user,
  handleReminders,
  view,
  views,
  course,
  events,
  refreshCourse,
  setEligibleSession,
  setJoinSessionUrl,
  calendarClassName,
  toolbar,
  components,
  notifications,
  reminders,
  fullScreen,
  lessonSelection,
}) => {
  const navigate = useNavigate();
  // const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showQuickModal, setShowQuickModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedRange, setSelectedRange] = useState();
  const [selectedEvent, setSelectedEvent] = useState();
  const [selectedSession, setSelectedSession] = useState();
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [reminderEvents, setReminderEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [currentSession, setcurrentSession] = useState();
  const [joinUrl, setJoinUrl] = useState();
  const [groupDetails, setGroupDetails] = useState();
  const [reminderTitle, setReminderTitle] = useState();
  const [reminderDescription, setReminderDescription] = useState();
  const [showReminderModel, setShowReminderModel] = useState(false);
  const [checkoutUrl, setCheckoutUrl] = useState(null);
  // const [freeCourse, setFreeCourse] = useState(false);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  moment.locale("en", {
    week: {
      dow: 1, //Monday is the first day of the week.
    },
  });
  const localizer = momentLocalizer(moment);

  const [bookingPayload, setBookingPayload] = useState();
  //const paymentEvent = React.useRef();
  const [paymentLoading, setPaymentLoading] = useState(false);
  // const [clickedEvent, setClickedEvent] = useState();

  const [stripeAccount, setStripeAccount] = useState();

  useEffect(() => {
    console.log("StudentCalendar - useEffect - course", course);
  }, [user?.uid, course]);

  useEffect(() => {
    console.log("StudentCalendar - useEffect - events", events);
    if (events) {
      const calEvents = events.map((e) => {
        return {
          ...e,
          start: moment.unix(e.start).toDate(),
          end: moment.unix(e.end).toDate(),
          title: addEventTitle(e),
          preference: e.preference,
        };
      });
      console.log("StudentCalendar - useEffect - calendar events", calEvents);
      setCalendarEvents(calEvents);
    }
  }, [events]);

  useEffect(() => {
    console.log("useEffect - Student Calendar -  reminders", reminders);
    if (handleReminders === true && reminders && reminders.length) {
      const rEvents = reminders.map((rem) => {
        return {
          start: moment.unix(rem.start).toDate(),
          end: moment.unix(rem.end).toDate(),
          title: rem.shortName,
          type: EventType.reminder,
          reminderId: rem.id,
          description: rem.description,
        };
      });

      setReminderEvents(rEvents);
    }
  }, [reminders]);

  useEffect(() => {
    let updatedEvents = [];
    if (calendarEvents && calendarEvents.length > 0) {
      updatedEvents = [...calendarEvents];
    }
    if (reminderEvents && reminderEvents.length > 0) {
      updatedEvents = [...updatedEvents, ...reminderEvents];
    }
    setAllEvents(updatedEvents);
  }, [reminderEvents, calendarEvents]);

  useEffect(() => {
    handleCalendarEvents();
  }, [calendarEvents]);

  useEffect(() => {
    handleCalendarEvents();
    if (notifications?.refreshCalendar) {
      refreshCourse && refreshCourse();
      //TODO: set refreshCalendar: false
      updateRefreshCalendar(user?.uid, false);
    }
  }, [notifications]);

  const handleCalendarEvents = () => {
    // When calendar Events change, check if there is anyone eligible for the student to jump in a call.
    const sessionNow = calendarEvents.filter((evt) =>
      decideIfSessionIsCallEligible(evt)
    );
    if (sessionNow && sessionNow.type === EventType.bookedCourseSession) {
      console.log(
        "StudentCalendar - useEffect - found eligible session",
        sessionNow
      );
      setcurrentSession(sessionNow);
      setEligibleSession(sessionNow);
      if (notifications?.roomCreated && notifications?.joinUrl) {
        setJoinUrl(notifications.joinUrl);
        // setJoinSessionUrl(response.data?.url);
      }
    }
  };

  useEffect(() => {
    console.log(
      "useEffect - selectedSession + joinUrl",
      selectedSession,
      joinUrl
    );
    if (!joinUrl && selectedSession) {
      // get joinUrl for student
      getSessionUrl(selectedSession);
    }
  }, [joinUrl, selectedSession]);

  useEffect(() => {}, [currentSession]);

  const makePayment = () => {
    window.open(checkoutUrl, "_self", "noopener,noreferrer");
  };

  const getSessionUrl = async (session) => {
    const response = await studentJoinVideoSession(
      user?.uid,
      session.sessionId
    );
    if (response.ok) {
      console.log("getSessionUrl - url", response.data);
      setJoinUrl(response.data?.url);
      setJoinSessionUrl(response.data?.url);
    }
  };

  const decideIfSessionIsCallEligible = (session) => {
    // check moment now with the start event time.
    return (
      moment().isSameOrAfter(session.start) && moment().isBefore(session.end)
    );
  };

  const renderPreference = (pref) => {
    return pref === "specEdu" ? "Special Education" : pref;
  };

  const checkIfClick = (start, end) => {
    const slots = create30MinutesTimeslots(start, end);
    console.log("StudentCalendar - checkIfClick", slots);
    if (slots.length == 1) {
      return true;
    }
  };

  const checkAddReminder = (slot) => {
    const dNow = Math.round(new Date().getTime() / 1000);
    const dStart = Math.round(slot.start.getTime() / 1000);
    if (dStart >= dNow) {
      setSelectedRange(slot);
      setReminderTitle(null);
      setReminderDescription(null);
      setShowReminderModel(true);
    }
  };

  const selectSlot = (slot) => {
    if (!user) {
      //TODO:  register request
      registerCourseAlert();
      return;
    }
    console.log("TutorCalendar - selectSlot", slot);
    if (
      (user.accountType === ACCOUNT_TYPES.STUDENT ||
        user.accountType === ACCOUNT_TYPES.SWOT_BUDDY) &&
      handleReminders === true &&
      (slot.action === "doubleClick" ||
        slot.action === "click" ||
        slot.action === "select") &&
      slot.start &&
      slot.end
    ) {
      if (checkIfClick(slot.start, slot.end)) {
        checkAddReminder(slot);
      }
    }
  };

  const saveReminder = () => {
    setShowReminderModel(false);
    const reminderId = moment(selectedRange.start).format("YYYY-MM-DD-HH-mm");

    var reminder = {
      id: reminderId,
      shortName: reminderTitle,
      description: reminderDescription,
      start: Math.round(selectedRange.start.getTime() / 1000),
      end: Math.round(selectedRange.end.getTime() / 1000),
      type: "calendar_reminder",
    };

    //TODO: check if this reminder exists already
    addReminder(user.uid, reminder, reminderId);
    console.log("");
  };

  const removeReminder = () => {
    if (selectedEvent.reminderId) {
      let updatedEvents = reminderEvents.filter(
        (evt) =>
          !(
            evt.type === EventType.reminder &&
            evt.reminderId === selectedEvent.reminderId
          )
      );

      setReminderEvents(updatedEvents);
      //remove reminder from server
      deleteReminder(user.uid, selectedEvent.reminderId);
      setShowModal(false);
    }
  };

  const addEventTitle = (event) => {
    if (
      event.type === EventType.bookedCourseSession ||
      event.type === EventType.groupCourseSession
    ) {
      return event.title
        ? event.title
        : `${event.courseName} - ${renderPreference(event.preference)}`;
      // return (course && evtCourseId === course.courseId) || !course
      //   ? "Your booked session"
      //   : "Other course booked";
    }
    if (event.type === EventType.tutorAvailabilitySlot) {
      return `Book a session on ${course.nameShort}`;
    }
    if (
      event.type === EventType.courseSessionBookedByOthers &&
      event.preference &&
      event.preference === "groups"
    ) {
      return `Book: ${
        event.title
          ? event.title
          : `${event.courseName} - ${renderPreference(event.preference)}`
      } - Partially booked`;
    }
    if (event.type === EventType.courseSessionBookedByOthers) {
      return `${
        event.title
          ? event.title
          : `${event.courseName} - ${renderPreference(event.preference)}`
      } - Already fully booked`;
    }
    if (event.type === EventType.webinar) {
      return `Lesson: ${
        event.title
          ? event.title
          : `${event.courseName} - ${renderPreference(event.preference)}`
      }`;
    }
  };

  const styleEvent = (type, evtCourseId) => {
    let style = {};
    if (type === EventType.reminder) {
      style.backgroundColor = "rgb(46, 113, 213, 0.7)";
      style.color = "white";
      style.borderColor = "rgb(46, 113, 213, 0.9)";
    }

    if (!course) {
      return style;
    }

    if (type === EventType.bookedCourseSession) {
      style.backgroundColor = "white";
      style.color =
        evtCourseId === course.courseId
          ? "rgb(250, 0, 94, 0.8)"
          : "rgb(250, 0, 94, 0.8)";
      style.borderColor =
        evtCourseId === course.courseId
          ? "rgb(250, 0, 94, 0.8)"
          : "rgb(250, 0, 94, 0.8)";
    }
    if (type === EventType.tutorAvailabilitySlot) {
      // style.backgroundColor = commonColor.deepGreen;
      style.backgroundColor = "rgb(64, 114, 136, 0.7)";
      style.color = "white";
      style.borderColor = "rgb(64, 114, 136, 0.7)";
    }
    if (type === EventType.courseSessionBookedByOthers) {
      // style.backgroundColor = commonColor.violet;
      style.backgroundColor = "rgb(182, 185, 223, 0.7)";
      style.color = "white";
      style.borderColor = "rgb(182, 185, 223, 0.7)";
    }
    if (type === EventType.webinar) {
      // style.backgroundColor = commonColor.sourCherry;
      style.backgroundColor = "rgb(153, 59, 100, 0.7)";
      style.color = "white";
      style.borderColor = "rgb(153, 59, 100, 0.7)";
    }

    return style;
  };

  const eventPropGetter = (event, start, end, isSelected) => {
    let newStyle = {
      borderRadius: "1rem",
      borderWidth: 2,
      margin: 2,
      padding: 2,
      fontSize: "0.7rem",
      flex: 1,
      // border: 0,
      // borderWidth: 0,
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      ...styleEvent(event.type, event.courseId),
    };

    // if (event.isMine) {
    //   newStyle.backgroundColor = "lightgreen"
    // }

    return {
      // className: "available-timeslot",
      style: newStyle,
    };
  };

  const bookingApi = async () => {
    setShowQuickModal(false);
    setLoading(true);
    const response = await bookCourseSession(user.uid, bookingPayload);
    setLoading(false);
    if (response) {
      console.log("Booked session, refreshing", response);
      refreshCourse && refreshCourse();
      //TODO: set refreshCalendar: false
      // updateRefreshCalendar(user.uid, false)
    }
    // setSelectedEvent(clickedEvent);
  };

  const paymentOnSuccess = async (message) => {
    //handleSelectedEvent(paymentEvent.current);

    const response = await bookCourseSession(user.uid, bookingPayload);
    if (response) {
      console.log("Booked session, refreshing", response);
      refreshCourse && refreshCourse();
      //TODO: set refreshCalendar: false
      // updateRefreshCalendar(user.uid, false)
    }
    setPaymentLoading(false);
    setShowPaymentModal(false);
    //alert(message);
  };

  const paymentOnError = (message) => {
    setPaymentLoading(false);
    //paymentEvent.current = {};
    //alert(message);
  };

  const registerCourseAlert = () => {
    Swal.fire({
      title: "Register",
      text: "You need to sign-in/sign-up to interact.",
      icon: "info",
      denyButtonText: "SignIn",
      denyButtonColor: "#407288",
      confirmButtonText: "SignUp",
      confirmButtonColor: "#407288",
      showDenyButton: true,
      showCancelButton: false,
      showConfirmButton: true,
    }).then((result) => {
      if (result.isDenied) {
        navigate("/sign-in");
      } else if (result.isConfirmed) {
        navigate("/on-boarding");
      }
    });
  };

  const handleSelectedEvent = async (event, e) => {
    if (!user) {
      //TODO: reigter User
      registerCourseAlert();
      return;
    }
    console.log("selected event:", event, course);
    console.log(user.accountType);

    if (
      user.accountType !== ACCOUNT_TYPES.STUDENT &&
      user.accountType !== ACCOUNT_TYPES.SWOT_BUDDY
    ) {
      //A tutor can't book a session for another tutor.
      return;
    }

    if (event.type === EventType.reminder) {
      if (reminders && reminders.length > 0 && event.reminderId) {
        const reminderFound = reminders.find((r) => r.id === event.reminderId);
        if (reminderFound) {
          setSelectedEvent(event);
          setShowModal(true);
        }
      }
    } else if (course) {
      if (event.type === EventType.tutorAvailabilitySlot) {
        console.log("Booking a session", user.uid, course);
        const payload = {
          courseId: course.courseId,
          bookEvent: {
            title: `${course.nameShort} - ${course.tutoringPreference}`,
            start: moment(event.start).unix(),
            end: moment(event.end).unix(),
            type:
              course.tutoringPreference === "groups"
                ? EventType.groupCourseSession
                : EventType.bookedCourseSession,
          },
        };
        setSelectedEvent(event);
        setBookingPayload(payload);
        setCheckoutUrl(null);

        // In case of paid course student should pay the turor
        // in order to be able to book the session.
        if (course.rate > 0) {
          setShowPaymentModal(true);
          //paymentEvent.current = event;
          const guid = crypto.randomUUID();

          setCheckoutLoading(true);
          const coursePaymentResult = await coursePayment(
            course.courseId,
            user.uid,
            guid,
            { ...payload.bookEvent, ...{ courseId: course.courseId } }
          );
          setCheckoutLoading(false);

          if (
            coursePaymentResult?.ok &&
            coursePaymentResult?.data?.status === "SUCCESS"
          ) {
            //save the booking details on localStorage, so that we can use it to book course after succesful payment.
            if (coursePaymentResult.data.data?.bookable === false) {
              setCheckoutUrl(null);
              setShowPaymentModal(false);
              Swal.fire({
                title: "Booking Failed",
                text: `${
                  coursePaymentResult.data.data.message ??
                  "An Error occured. Please try again."
                }`,
                icon: "warning",
                confirmButtonColor: "#407288",
              });
            } else if (coursePaymentResult.data.data?.payable === false) {
              setCheckoutUrl(null);
              setShowPaymentModal(false);
              setShowQuickModal(true);
            } else {
              saveBookingPayload(user.uid, guid, {
                courseId: course.courseId,
                booking: payload,
              });
              setCheckoutUrl(coursePaymentResult.data.data.checkout_url);
            }
            return;
          } else {
            console.error(
              coursePaymentResult?.data?.errorMessage || "Payment intent Error",
              coursePaymentResult
            );
          }
        } else {
          setShowQuickModal(true);
          return;
        }
      }

      if (
        event.type === EventType.courseSessionBookedByOthers &&
        event.preference &&
        event.preference === "groups"
      ) {
        groupCourseSessionBooking(course, event);
        // console.log(
        //   "Clicked in group session booked by others - will book",
        //   event
        // );
        // console.log("Booking a session", user.uid, course);
        // const payload = {
        //   courseId: course.courseId,
        //   bookEvent: {
        //     title: `${course.nameShort} - ${course.tutoringPreference}`,
        //     start: moment(event.start).unix(),
        //     end: moment(event.end).unix(),
        //     type: EventType.groupCourseSession,
        //     groupSessionId: event.sessionId,
        //   },
        // };

        // const response = await bookCourseSession(user.uid, payload);
        // if (response) {
        //   console.log("Booked session, refreshing", response);
        //   refreshCourse && refreshCourse();
        //   //updateRefreshCalendar(user.uid, false)
        // }
        // setSelectedEvent(event);
        // //setSelectedSession(event);
        return;
      } else {
        console.log("Cannot book an already booked session.");
      }

      if (
        (event.type === EventType.bookedCourseSession ||
          event.type === EventType.groupCourseSession ||
          event.type === EventType.webinar) &&
        event.courseId === course.courseId
      ) {
        console.log("Clicked my booked session", event);
        setSelectedSession(event);
        setShowModal(true);
        if (event.type === EventType.groupCourseSession) {
          // fetch details for group session
          const response = await getSessionEnrolledDetails(event.sessionId);
          if (response.ok) {
            console.log("getSessionEnrolledDetails", response.data);
            setGroupDetails(response.data);
          }
        }
        setSelectedEvent(event);
        setSelectedSession(event);
        //TODO: add a cancel session logic
      }
    } else {
      // todo: add redirection to course page from dashboard.
      setSelectedEvent(event);
      setShowModal(true);
      if (event.courseId) {
        lessonSelection && lessonSelection(event.courseId);
      }
    }
  };

  const groupCourseSessionBooking = async (course, event) => {
    console.log("Clicked in group session booked by others - will book", event);
    console.log("Booking a session", user.uid, course);
    const payload = {
      courseId: course.courseId,
      bookEvent: {
        title: `${course.nameShort} - ${course.tutoringPreference}`,
        start: moment(event.start).unix(),
        end: moment(event.end).unix(),
        type: EventType.groupCourseSession,
        groupSessionId: event.sessionId,
      },
    };

    setShowPaymentModal(true);

    const guid = crypto.randomUUID();

    setCheckoutLoading(true);

    const coursePaymentResult = await coursePayment(
      course.courseId,
      user.uid,
      guid,
      { ...payload.bookEvent, ...{ courseId: course.courseId } }
    );
    setCheckoutLoading(false);

    if (
      coursePaymentResult?.ok &&
      coursePaymentResult?.data?.status === "SUCCESS"
    ) {
      //save the booking details on localStorage, so that we can use it to book course after succesful payment.
      if (coursePaymentResult.data.data?.bookable === false) {
        setShowPaymentModal(false);
        Swal.fire({
          title: "Booking Failed",
          text: `${
            coursePaymentResult.data.data.message ??
            "An Error occured. Please try again."
          }`,
          icon: "warning",
          confirmButtonColor: "#407288",
        });
      } else if (coursePaymentResult.data.data?.payable === false) {
        // setShowPaymentModal(false);
        // setShowQuickModal(true);

        setCheckoutLoading(true);

        const response = await bookCourseSession(user.uid, payload);
        if (response) {
          console.log("Booked session, refreshing", response);
          refreshCourse && refreshCourse();
        }

        setCheckoutLoading(false);
        setShowPaymentModal(false);
        setSelectedEvent(event);
      } else {
        saveBookingPayload(user.uid, guid, {
          courseId: course.courseId,
          booking: payload,
        });
        setCheckoutUrl(coursePaymentResult.data.data.checkout_url);
      }
      return;
    } else {
      setShowPaymentModal(false);
      console.error(
        coursePaymentResult?.data?.errorMessage || "Payment intent Error",
        coursePaymentResult
      );
    }
  };

  const enrollAndJoinWebinar = async () => {
    console.log("enrollAndJoinWebinar", selectedSession);
    const result = await enrollJoinWebinar(user.uid, {
      sessionId: selectedSession.sessionId,
    });
    console.log("enrollAndJoinWebinar - result", result);
    if (result.ok) {
      if (!result.data.studentLink || result.data.studentLink == undefined) {
        //TODO: link is not created by tutor yet
      } else {
        window.open(result.data.studentLink, "_blank");
      }
    }
  };

  const renderGroupEnrolledList = () => {
    const enrolled = groupDetails.enrolled;
    if (enrolled && enrolled.length > 0) {
      return (
        <div className="p-2 w-4/5">
          {enrolled.map((tut) => (
            <button
              className="w-full bg-transparent flex justify-between focus:outline-none h-16 pb-4"
              onClick={() => {
                console.log("selected student:", tut);
                console.log("navigating to student profile", tut);
              }}
            >
              <div
                className=" h-full"
                style={{
                  width: "25%",
                }}
              >
                <img
                  src={tut.photoUrl ? tut.photoUrl : knotSm}
                  alt="tutorPhoto"
                  className="rounded-full shadow-lg h-full bg-white"
                />
              </div>

              <div
                className="bg-gray-400  h-full flex justify-between items-center text-xs px-3 rounded"
                style={{
                  width: "70%",
                }}
              >
                <div className="text-center">{tut.username}</div>
              </div>
            </button>
          ))}
        </div>
      );
    } else {
      return <div>No details available yet.</div>;
    }
  };

  /**
   * "session": {
          "firstName": "Anthony",
          "lastName": " Demetriades",
          "photoUrl": "siwqdjwdwdjwdwo",
          "firebaseId": "kDq3xlLcL3Vln07EYELvppt4UeH3",
          "registration": "online",
          "status": "pending"
      }
   */

  const renderReminderModelContent = () => {
    return (
      <div>
        <div className="w-full h-full flex flex-col justify-around py-2 space-y-2">
          <div className="text-center px-4 py-4 text-sm  italic ">
            Set Reminder
            <br />
            <br />
            Start: {selectedRange && moment(selectedRange.start).format("LLL")}
            <br />
            End: {selectedRange && moment(selectedRange.end).format("LLL")}
          </div>
          <div className="w-full">
            <input
              className="w-full bg-white appearance-none border-2 focus:outline-none 
   focus:border-opacity-100 py-2 px-2 m-0"
              id="title"
              name="title"
              type="text"
              placeholder={"Enter title"}
              value={reminderTitle}
              onChange={(event) => {
                setReminderTitle(event.target.value);
              }}
            />
          </div>
          <div className="w-full">
            <input
              className="w-full flex-grow bg-white appearance-none border-2 focus:outline-none 
   focus:border-opacity-100 py-2 px-2 m-0"
              id="description"
              name="description"
              type="text"
              placeholder={"Enter description"}
              value={reminderDescription}
              onChange={(event) => {
                setReminderDescription(event.target.value);
              }}
            />
          </div>
          <div className="flex justify-around sm:justify-around px-4 py-2 flex-col space-y-2 sm:space-y-0 sm:space-x-4 sm:flex-row">
            {/* {course && ( */}
            <button
              className={`${
                reminderTitle && reminderTitle.trim().length > 0
                  ? "text-green-500 hover:text-green-600 border-green-500 hover:border-green-600"
                  : "text-gray-300 hover:text-gray-300 border-gray-300"
              } border p-2 rounded-lg focus:outline-none`}
              onClick={() => {
                saveReminder();
              }}
              disabled={!reminderTitle || reminderTitle.trim().length === 0}
            >
              Set Reminder
            </button>
            {/* )} */}
            <button
              className="text-black-500 hover:text-black-600 border-black-500 hover:border-black-600 border p-2 rounded-lg focus:outline-none"
              onClick={() => {
                setShowReminderModel(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderSessionModal = () => {
    console.log(
      "renderSessionModal - selectedSession",
      selectedSession,
      selectedEvent,
      currentSession
    );
    if (selectedEvent && selectedEvent.type === EventType.reminder) {
      return (
        <div className="w-full h-full flex flex-col justify-around">
          <div className="text-center px-4 pt-4 text-sm">
            Reminder: {selectedEvent.title}
          </div>
          <div className="text-center px-4 pb-4 text-sm italic">
            {selectedEvent.description ?? ""}
          </div>
          <div className="flex justify-around sm:justify-around px-4 py-4 flex-col space-y-2 sm:space-y-0 sm:space-x-4 sm:flex-row">
            <button
              className="text-red-500 hover:text-red-600 
border-red-500  hover:border-red-600 border p-2 rounded-lg focus:outline-none"
              onClick={() => removeReminder()}
            >
              Remove
            </button>
            <button
              className="text-gray-700 hover:text-gray-800 
border-gray-700  hover:border-gray-800 border p-2 rounded-lg focus:outline-none"
              onClick={() => {
                setShowModal(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      );
    }

    if (course && selectedSession) {
      return (
        <div
          className="h-full flex flex-col justify-start items-center"
          style={{
            zIndex: 10001,
          }}
        >
          <button className="my-4 text-center font-bold focus:outline-none">
            {selectedSession.courseName}
          </button>
          <div className="w-full my-4 flex flex-col justify-start">
            <div>From: {moment(selectedSession.start).format("LLL")}</div>
            <div>To: {moment(selectedSession.end).format("LLL")}</div>
          </div>
          {selectedSession.type === EventType.groupCourseSession && (
            <div className="w-full my-4 flex flex-col justify-start items-start">
              <div className="font-bold">Students to attend:</div>
              <div className="flex w-full flex-col max-h-40v overflow-y-scroll">
                {renderGroupEnrolledList()}
              </div>
            </div>
          )}
          <div className="w-full my-4 flex flex-row justify-end">
            {/* <div>
              With: {selectedSession.firstName} {selectedSession.lastName}
            </div> */}
            {renderModalAction("Lesson has not started yet.")}
          </div>
          {/* <div className="w-full my-4 flex flex-row justify-between">
            <div>Status: {selectedSession.status} </div>
            <div>To be conducted: {selectedSession.registration} </div>
          </div> */}
        </div>
      );
    } else if (selectedEvent) {
      return (
        <div
          className="h-full flex flex-col justify-start items-center"
          style={{
            zIndex: 10001,
          }}
        >
          <button className="my-4 text-center font-bold focus:outline-none">
            Course: {selectedEvent.nameShort}
          </button>
          <div className="w-full my-4 flex flex-col justify-between">
            <div>From: {moment(selectedEvent.start).format("LLL")}</div>
            <div>To: {moment(selectedEvent.end).format("LLL")}</div>
          </div>
          {selectedEvent.type === EventType.groupCourseSession && (
            <div className="w-full my-4 flex flex-row justify-between">
              Students to attend: SOON
            </div>
          )}
          <div className="w-full my-4 flex flex-row justify-end italic">
            {/* <div>
              With: {selectedSession.firstName} {selectedSession.lastName}
            </div> */}
            <button
              onClick={() =>
                // navigate("/courses", {
                //   state: {
                //     courseId: selectedEvent.courseId,
                //   },
                // })
                navigate(`/courses/${selectedEvent.courseId}`)
              }
              style={{
                color: commonColor.deepGreen,
                textDecoration: "underline",
              }}
            >
              Go to course page for details.
            </button>
          </div>
          {/* <div className="w-full my-4 flex flex-row justify-between">
            <div>Status: {selectedSession.status} </div>
            <div>To be conducted: {selectedSession.registration} </div>
          </div> */}
        </div>
      );
    } else {
      return (
        <div className="h-full flex flex-col justify-center items-center">
          <div className="text-center font-bold focus:outline-none">
            No course session available.
          </div>
        </div>
      );
    }
  };

  const renderModalAction = (defaultMessage) => {
    const eligible = decideIfSessionIsCallEligible(selectedSession);
    if (selectedSession.type === EventType.webinar) {
      return eligible ? (
        <Swotbutton
          text={"Enroll & join"}
          onClick={() => enrollAndJoinWebinar()}
        />
      ) : (
        <div className="italic"> Nothing to do yet.</div>
      );
    } else if (joinUrl && eligible) {
      // return <Swotbutton text={"Join Call"} onClick={() => joinCall()} />;
      return (
        <div>
          {/* <Link
                target="_blank"
                to={joinUrl}
                style={{ overflowX: "visible", color: "green" }}
              >
                Join Call
              </Link> */}
          <a
            href={joinUrl}
            style={{ overflowX: "visible", color: "green" }}
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
          >
            Join Lesson
          </a>
        </div>
      );
    } else {
      return (
        <div>
          {defaultMessage ? defaultMessage : "Nothing to do at the moment."}
        </div>
      );
    }
  };

  const { agendaGutterHeader: AgendaGutterHeader } = components;

  if (components?.agenda?.event) {
    const Event = components.agenda.event;
    components.agenda.event = (props) => (
      <Event onClick={handleSelectedEvent} {...props} />
    );
  }

  return (
    <div
      className={`container w-full bg-white rounded-3xl p-4  ${
        !fullScreen ? "h-full" : ""
      } 
    flex flex-col justify-start overflow-y-hidden`}
    >
      <div className={`w-full h-full pt-1 pb-2`}>
        {/* <div className="w-full overflow-y-scroll py-2"> */}
        {view === "day" && AgendaGutterHeader && <AgendaGutterHeader />}
        <Calendar
          localizer={localizer}
          defaultDate={new Date()}
          defaultView={view ? view : "week"}
          events={allEvents}
          // style={{ height: "100vh" }}
          selectable={true}
          scrollToTime={new Date()}
          //scrollToTime={moment.now()}
          // step={60}
          // timeslots={1}
          onSelectSlot={selectSlot}
          onSelectEvent={handleSelectedEvent}
          eventPropGetter={eventPropGetter}
          views={views ? views : ["month", "day", "week"]}
          className={calendarClassName}
          toolbar={toolbar}
          components={components}
        />
      </div>

      <Modal
        isOpen={showModal}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setShowModal(false)}
        // contentLabel="Create new event"
      >
        {selectedEvent &&
        (selectedEvent.type === EventType.reminder ||
          selectedEvent.type === EventType.groupCourseSession ||
          selectedEvent.type === EventType.groupCourseSession ||
          selectedEvent.type === EventType.webinar) ? (
          renderSessionModal()
        ) : (
          <div className="w-full h-full flex flex-col justify-around">
            <div className="text-center px-4 py-4 text-sm  italic ">
              Nothing to do yet.
            </div>
          </div>
        )}
      </Modal>
      <QuickModal
        isOpen={showQuickModal}
        title={bookingPayload?.bookEvent?.title}
        message={"You are about to schedule course for the given period."}
        onRequestClose={() => setShowQuickModal(false)}
        firstButtonTitle={"Schedule"}
        secondButtonTitle={"Cancel"}
        onFirstButtonClick={() => bookingApi()}
        onSecondButtonClick={() => setShowQuickModal(false)}
      >
        <div className="w-full my-4 flex flex-col justify-start">
          <div>From: {moment(selectedEvent?.start).format("LLL")}</div>
          <div>To: {moment(selectedEvent?.end).format("LLL")}</div>
        </div>
      </QuickModal>
      <Modal
        isOpen={showPaymentModal}
        onRequestClose={() => setShowPaymentModal(false)}
        onAfterClose={() => {
          setCheckoutLoading(false);
        }}
        isCloseButtonShown={true}
      >
        {course && (
          <>
            <div className="w-full my-4 flex flex-col justify-start">
              <div>Course: {course.nameShort}</div>
              <div>Tutor: {course.tutorUsername}</div>
              <div>From: {moment(selectedEvent?.start).format("LLL")}</div>
              <div>To: {moment(selectedEvent?.end).format("LLL")}</div>
              <div>Price: {course.rate} USD</div>
              {checkoutUrl && (
                <Swotbutton
                  text={"Make Payment"}
                  onClick={() => {
                    makePayment();
                  }}
                />
              )}
            </div>

            <div>
              {checkoutLoading && (
                <ClimbingBoxLoader
                  css={{
                    background: "rgba(255,255,255,0.8)",
                    position: "absolute",
                    display: "block",
                    width: "100%",
                    height: "100%",
                    bottom: "0",
                    left: "0",
                    "z-index": "9999",
                  }}
                />
              )}
            </div>
          </>
        )}
      </Modal>
      <Modal
        isOpen={showReminderModel}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => {
          setShowReminderModel(false);
        }}
        // contentLabel="Create new event"
      >
        {renderReminderModelContent()}
      </Modal>
      <LoaderModal
        isOpen={loading}
        onRequestClose={() => {
          setLoading(false);
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  notifications: state.notifications.notifications,
  reminders: state.calendarReminders.reminders,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(StudentCalendar);
