import React from "react";
import "../newLanding.css";
import placeholderOne from "../assets/enroll_image.png";

import { useInView, animated } from "@react-spring/web";

const PartFour = ({ showPopup }) => {
  const [ref1, ref1Springs] = useInView(
    () => ({
      from: { x: -100, opacity: 0 },
      to: {
        x: 0,
        opacity: 1,
        config: {
          duration: 500,
        },
      },
    }),
    {
      root: null,
      rootMargin: "-20% 0px",
      once: true,
    }
  );

  const [ref2, imageSprings] = useInView(
    () => ({
      from: {
        rotate: 0,
        config: { duration: 500 },
      },
      to: {
        rotate: -4,
        config: { duration: 500 },
      },
    }),
    {
      root: null,
      rootMargin: "-15% 0px",
      threshold: 0.2,
    }
  );

  const [ref3, ref3Spring] = useInView(
    () => ({
      from: { x: -100, opacity: 0 },
      to: {
        x: 0,
        opacity: 1,
        config: {
          duration: 500,
        },
      },
    }),
    {
      root: null,
      rootMargin: "-20% 0px",
      threshold: 0.2,
      once: true,
    }
  );

  return (
    <div className="inline-block w-full" id="enterprise">
      <div className="flex flex-col-reverse justify-center items-center gap-8 mb-24 lg:mb-40 mt-16 lg:mt-20 lg:flex-row md:justify-center md:items-center md:flex">
        <animated.div ref={ref1} style={ref1Springs}>
          <div className="mb-10">
            <p className="text-3xl text-gray-600 mb-14 text-center">
              Enterprise
            </p>
          </div>
          <div
            ref={ref1}
            className="px-12 lg:px-40 lg:w-full flex flex-col-reverse gap-10 md:gap-0 md:flex-row items-center justify-center"
            style={ref1Springs}
          >
            <animated.div
              ref={ref3}
              className="w-fit ml-0 md:ml-6 mt-10 md:mt-0 flex items-center justify-center flex-col md:block mr-10"
              style={ref3Spring}
            >
              <p className="text-lg md:text-xl lg:text-2xl text-[#F56110] mb-6 w-fit text-center md:text-left"></p>
              <p className="text-xl md:text-2xl lg:text-4xl text-gray-700 mb-5 w-fit text-center md:text-left">
                l3arn: Empowering Smart Education
              </p>
              <p className="max-w-lg text-gray-600 lg:text-base text-sm pr-0 md:pr-8 text-center md:text-left">
                Empower your educational institution with l3arn’s smart learning
                solutions. Our Enterprise package offers tailored services to
                inspire students and staff, fostering a culture of innovation
                and intelligence.
              </p>
            </animated.div>
            <div className="w-10/12 md:max-w-fitContent">
              <animated.img
                ref={ref2}
                className="mr-4 w-auto lg:max-h-[25rem] md:max-h-[20rem] rounded-2xl object-cover"
                src={placeholderOne}
                alt="young_girl_with_two_kids_behind_her"
                style={imageSprings}
              />
            </div>
          </div>
        </animated.div>
      </div>
    </div>
  );
};

export default PartFour;
