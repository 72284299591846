import React, { useEffect, useState } from "react";
import contentful from "../../config/contentful";
import "./styles.css";
import Masonry from "react-masonry-css";
import { ReactComponent as SearchGlass } from "../../assets/icons/search (6).svg";
import commonColor from "../../theme/commonColor";
import LandingLayout from "../../components/landing/landingLayout";
import LoaderModal from "../../components/common/modal/loaderModal";
import EmptyNewsMessage from "../../components/tutor/tools/emptyNewsMessage";
import SideArticle from "../../components/content/preview/sideArticle";
import Swotbutton from "../../components/common/buttons/swotbutton";

export default function Swotnews() {
  const [content, setContent] = useState([]);
  const [isSearchActive, setIsSearchActive] = useState(true);
  const [searchString, setSearchString] = useState(null);
  const [filteredContent, setFilteredContent] = useState([]);
  const [hashtagContent, setHashtagContent] = useState([]);
  const [roleFilter, setRoleFilter] = useState([]);

  const [hashtags, setHashtags] = useState([]);
  const [activeHashtagFilters, setActiveHashtagFilters] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(true);

  const [skip, setSkip] = useState([0]);
  const [total, setTotal] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const pageLimit = 30;
  console.log("::Swotnews Rerender::");

  useEffect(() => {
    console.log(":::::EFFECT CALLING::::");
    getContent();
    getHashtags();
  }, []);

  const getContent = async () => {
    setLoadingMore(true);
    const skip = content.length;
    setLoading(true);
    contentful
      .getEntries({
        content_type: "post",
        limit: pageLimit,
        skip: skip,
      })
      .then(function (response) {
        setLoading(false);
        console.log("Swotnews - get content", response);
        if (response) {
          let roleBasedNews = [];

          response.items.forEach((element) => {
            let isPublic = element?.fields?.hashtags?.map(
              (hash) => hash?.fields?.name === "public"
            );
            if (isPublic && isPublic.includes(true)) {
              console.log("Public News:", element);
              roleBasedNews.push(element);
            }
          });

          setContent([...content, ...(roleBasedNews ?? [])]);
          setFilteredContent([...content, ...(roleBasedNews ?? [])]);
          setSkip(content.length);
          setTotal(roleBasedNews.length);
        }
      });
    setLoadingMore(false);
  };

  const loadMore = () => {
    if (!loadingMore && content.length < total) {
      getContent();
      setActiveHashtagFilters([]);
    }
  };

  const getHashtags = async () => {
    const response = await contentful.getEntries({
      content_type: "hashtag",
    });

    let hashtag = [];
    response.items.forEach((element) => {
      if (element?.fields?.name?.charAt(0) === "#") {
        let chkIfExists = hashtag.find(
          (o) => o?.fields?.name === element?.fields?.name
        );
        if (!chkIfExists) hashtag.push(element);
      }
    });

    console.log("NewsFeed - get hashtags", hashtag);
    setHashtags(hashtag);
  };

  const handleSearchTermChange = (searchTerm) => {
    console.log("calling search");

    setSearchText(searchTerm);

    if (searchTerm !== undefined) {
      let matchingContent;
      let filteringContent = content;
      if (searchTerm === "") {
        matchingContent = content;
        setFilteredContent(matchingContent);
        return;
      }

      if (searchTerm.trim().length === 0) {
        return;
      }

      if (activeHashtagFilters.length > 0) {
        filteringContent = hashtagContent;
      }

      matchingContent = filteringContent.filter((item) => {
        return item.fields.title
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });

      console.log("filterContent - matching content", matchingContent);
      setFilteredContent(matchingContent);
    }
  };

  useEffect(() => {
    if (activeHashtagFilters.length > 0) {
      console.log("NewsFeed - useEffect - filtering", activeHashtagFilters);

      const filteredPosts = content.filter((post) =>
        isContentLinkedToHashtags(post, activeHashtagFilters)
      );
      console.log("NewsFeed - useEffect - found matching posts", filteredPosts);
      setFilteredContent(filteredPosts);
      setHashtagContent(filteredPosts);
    } else {
      setFilteredContent(content);
      setHashtagContent(content);
    }
  }, [content, activeHashtagFilters]);

  const isContentLinkedToHashtags = (content, hashIds) => {
    if (!content.fields.hashtags) {
      return false;
    }

    const hashtags = content.fields.hashtags.map((hash) => hash.sys.id);
    // smallArray.some(c => bigArray.includes(c));

    const matching = hashIds.some((id) => hashtags.includes(id));
    return matching;
  };

  const toggleHashtagFilter = (hashtag) => {
    const hashId = hashtag.sys.id;
    console.log("toggleHashtagFilter - hashtag", hashId);

    if (activeHashtagFilters.includes(hashId)) {
      // remove it
      const newFilters = activeHashtagFilters.filter(
        (hashfilter) => hashfilter !== hashId
      );
      setActiveHashtagFilters(newFilters);
    } else {
      const newFilters = [...activeHashtagFilters, hashId];
      setActiveHashtagFilters(newFilters);
    }
  };
  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    800: 1,
  };

  const renderContent = (items) => {
    let textWidth = window.innerWidth / 4;
    let line = Math.round((textWidth / 100) * 1.5);
    console.log("Content Width:", textWidth, " Lines:", line);
    const contentBlocks = items.map(function (item, index) {
      return (
        <SideArticle item={item} index={index} />
      );
    });

    return contentBlocks;
  };

  return (
    <LandingLayout landingTitle="Swotnews">
      <div
        className="w-full h-full flex flex-row  justify-center items-center flex-wrap overflow-y-scroll pt-4">
        <div
          className="w-full h-full  pt-5 overflow-y-scroll"
          style={{
            display: `grid`,
            gridTemplateColumns: `1fr`,
            gridTemplateRows: `repeat(12, 1fr)`,
            gridColumnGap: `0px`,
            gridRowGap: `0px`,
          }}
        >
          <div
            className="w-full flex flex-col justify-start items-end"
            style={{
              gridArea: `1 / 1 / 13 / 2`,
            }}
          >
            <div
              className="text-right lg:text-left text-gray-600 px-5"
              style={{ width: "95%" }}
            >
              Curated news from our team
            </div>

            <form
              className="flex justify-end items-center px-5"
              style={{ width: "95%" }}
              onSubmit={(e) => {
                e.preventDefault();
                handleSearchTermChange();
                // send to server with e.g. `window.fetch`
              }}
            >
              {isSearchActive && (
                <div
                  className=" w-full mr-2"
                  style={{
                    height: "50px",
                    backgroundColor: commonColor.deepGreen,
                    opacity: 0.85,
                    borderRadius: "0.7rem",
                    zIndex: 1002,
                  }}
                >
                  <input
                    className="w-full h-full appearance-none focus:outline-none py-1 px-2"
                    style={{
                      color: "white",
                      backgroundColor: "transparent",
                      zIndex: 1002,
                    }}
                    id="search"
                    name="search"
                    type="text"
                    placeholder={"Search"}
                    onChange={(event) => {
                      console.log("onChange");
                      handleSearchTermChange(event.target.value);
                    }}
                  />
                </div>
              )}

              <button
                className="my-2 rounded-lg focus:outline-none"
                type="submit"
                style={{
                  backgroundColor: commonColor.deepGreen,
                  zIndex: 2001,
                  height: "50px",
                  width: "50px",
                }}
              >
                <SearchGlass
                  style={{
                    width: "50px",
                    height: "25px",
                    overflow: "hidden",
                    fill: "white",
                    backgroundColor: commonColor.deepGreen,
                    margin: "auto",
                  }}
                />
              </button>
            </form>
            {/* </div> */}
            <div
              className="flex justify-end items-center flex-wrap px-5"
              style={{ width: "95%" }}
            >
              {hashtags
                .filter(
                  (h) =>
                    (h.fields.name && h.fields.name !== "") ||
                    (Array.isArray(h.fields.categories) &&
                      h.fields.categories.length > 0 &&
                      h.fields.categories[0].fields.name &&
                      h.fields.categories[0].fields.name !== "")
                )
                .slice(0, 6)
                .map((hash) => (
                  <button
                    className="px-2 py-1 text-xs rounded-xl focus:outline-none mr-1 mb-1"
                    style={{
                      borderWidth: 1,
                      borderColor: commonColor.deepGreen,
                      color: `${activeHashtagFilters.includes(hash.sys.id)
                          ? "white"
                          : commonColor.deepGreen
                        }`,
                      backgroundColor: `${activeHashtagFilters.includes(hash.sys.id)
                          ? commonColor.deepGreen
                          : "white"
                        }`,
                    }}
                    onClick={() => toggleHashtagFilter(hash)}
                  >
                    {hash.fields.name
                      ? hash.fields.name
                      : hash.fields.categories[0].fields.name}
                  </button>
                ))}
            </div>
            <div className="w-full">
              {/* masonry */}
             

              {filteredContent.length <= 0 && !loading ? (
                <div className="w-full flex flex-col justify-start items-center pt-24 pb-24">
                  <div className="rounded overflow-hidden shadow-lg bg-white w-full  sm:w-4/6 md:max-w-screen-sm flex flex-col justify-start self-center" style={{
                    boxShadow: `0 10px 25px 0 rgba(0, 0, 0, 0.6)`,
                    borderRadius: 25
                  }}>
                    {/* No result found. Change your filters. */}
                    <EmptyNewsMessage
                      hashTags={hashtags}
                      searchTopics={activeHashtagFilters}
                      searchText={searchText}
                    />
                  </div>
                </div>
              ) : (
                
                <div
                  className=" h-full lg:px-5 pt-5 overflow-y-scroll float-right flex flex-col justify-end xl:justify-center"
                  style={{
                    width: "95%",
                  }}
                >
                  <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid pt-4"
                    columnClassName="my-masonry-grid_column"
                  >
                    {/* array of JSX items */}
                    {renderContent(filteredContent)}
                  </Masonry>
                  <div className="w-full px-8 py-2 flex justify-end items-center">
                    {content.length < total && (
                      <Swotbutton
                        text="Load More.."
                        loading={loadingMore}
                        onClick={() => {
                          loadMore();
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <LoaderModal isOpen={loading} />
    </LandingLayout>
  );
}


