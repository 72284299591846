import React from "react";
import { connect } from "react-redux";
import UserActions from "../../redux/user";
import commonColor from "../../theme/commonColor";
import SwotknotLogo from "../../assets/images/learn-logo-colour.svg";
import Step1 from "./steps/step1";
import Step2 from "./steps/step2";
import Step3 from "./steps/step3";
import Step4 from "./steps/step4";
import { setupTutor } from "../../services/api";
import { Navigate, useNavigate } from "react-router-dom";
import Swotbutton from "../../components/common/buttons/swotbutton";

import "./styles.css";
import { submitConsent } from "../../components/common/consent";
import { updateUser } from "../../services/userManagement";

import moment from "moment";
import { historyState } from "../../services/navigation/history";

class TutorSetup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 1,
      isAllDataSet: false,
      isPolicyVerify: false,
      setupLater: false,
      specialEducation: false,
      photoUrl: "",
      resumeUrl: "",
      subjectAreas: [],
      subjects: [],
      tutoringPreference: "",
      tutoringRegistration: "",
    };
  }
  saveProperty = (key, value) => {
    console.log("saveProperty -", key, value);
    this.setState({
      [key]: value,
    });
  };

  onNext = () => {
    this.setState((prevState) => ({
      currentStep: prevState.currentStep + 1,
    }));
    console.log("state", this.state)
  };

  // navigate = useNavigate();

  readyCheck = (flag) => {
    let result;
    result =
      this.state.introduceVideoUrl &&
      this.state.photoUrl &&
      this.state.resumeUrl &&
      //  this.state.subjectAreas &&
      // this.state.subjectAreas.length > 0 &&
      this.state.subjects &&
      this.state.subjects.length > 0 &&
      // this.props.user.quizDone &&
      this.state.tutoringPreference &&
      this.state.tutoringRegistration;
    //  }

    return result ? true : false;
  };

  // vidIntroPopup = () => {
  //   Swal.fire({
  //     title: "Why do we need this?",
  //     html: "<p>The short 1 min video will introduce your personality and will also be used for students to see how their new best tutor will look. </br></br> We want to ensure you are not a robot, although being a tutor sometimes feels like being one! By adding a photo, we can place an image to a name; your CV will impress us and allow us to verify your credentials.  </br></br> All this information is only used for verification purposes and part of your profile; l3arn will not use this for any other purpose, as indicated in the Privacy Policy you have agreed to.</p>",
  //     // text: "The short 1 min video will introduce your personality and will also be used for students to see how their new best tutor will look. \n We want to ensure you are not a robot, although being a tutor sometimes feels like being one! By adding a photo, we can place an image to a name; your CV will impress us and allow us to verify your credentials.  \n All this information is only used for verification purposes and part of your profile; l3arn will not use this for any other purpose, as indicated in the Privacy Policy you have agreed to.",
  //     confirmButtonText: "OK",
  //   });
  // }

  generateErrorArray = () => {
    const errorArray = [];
    if (!this.state.subjects || this.state.subjects.length === 0) {
      errorArray.push({ step: 1, message: "Subjects are missing" });
    }
    if (!this.state.tutoringPreference) {
      errorArray.push({
        step: 1,
        message: "Tutoring preference is missing",
      });
    }
    if (!this.state.tutoringRegistration) {
      errorArray.push({
        step: 1,
        message: "Tutoring registration is missing",
      });
    }
    if (!this.props.user.quizDone) {
      errorArray.push({ step: 2, message: "Quiz is not completed" });
    }
    if (!this.state.introduceVideoUrl) {
      errorArray.push({
        step: 3,
        message: "Introduction Video is missing",
      });
    }
    if (!this.state.photoUrl) {
      errorArray.push({ step: 3, message: "Photo is missing" });
    }
    if (!this.state.resumeUrl) {
      errorArray.push({ step: 3, message: "Resume is missing" });
    }
    return errorArray;
  };

  handleSubmit = async () => {
    console.log("handleSubmit was activated!", this.state);
    // disable the DONE button
    this.setState({
      submitted: true,
      setupDone: false,
      setupError: false,
    });

    try {
      const { uid, email, name } = this.props.user;
      await submitConsent(uid, email, name);
    } catch (error) {
      console.warn("handleSubmit - setupTutor error", error);
      this.setState({
        submitted: false,
        setupError: true,
      });
      return;
    }

    const result = await setupTutor({ ...this.state }, this.props.user.uid);
    console.log("handleSubmit - setupTutor result", result);

    if (result.ok) {
      const updatedUser = {
        ...this.props.user,
        setupStatus: "pending",
        photoUrl: this.state.photoUrl || "",
        setupCompletedAt: moment().unix(),
      };

      if (this.state.photoUrl) updateUser(updatedUser, true);
      // updateUser(
      //   {
      //     uid: updatedUser.uid,
      //     photoUrl: updatedUser.photoUrl,
      //     setupCompletedAt: updatedUser.setupCompletedAt,
      //   },
      //   true
      // );
      this.props.saveUser(updatedUser);
      this.setState({
        submitted: true,
        setupDone: true,
        setupError: false,
      });
      // historyState.navigate("/dashboard");
    } else {
      this.setState({
        submitted: false,
        setupDone: false,
        setupError: true,
      });
    }
  };

  actionButtons = () => {
    const { currentStep } = this.state;

    return (
      <div
        className={`h-full w-full flex items-center ${currentStep === 1 ? "justify-end" : "justify-between"
          }`}
      >
        {currentStep !== 1 &&
          (currentStep !== 2 || this.props.user.quizDone) && (
            <Swotbutton
              text={"Previous"}
              onClick={() => {
                this.setState({
                  currentStep: currentStep - 1,
                  isAllDataSet: this.readyCheck(),
                });
              }}
              style={{
                minWidth: "8rem",
                // marginBottom: '-14px'
              }}
            />
          )}

        {currentStep !== 4 &&
          (currentStep !== 2 || this.props.user.quizDone) && (
            <Swotbutton
              className={"px-4"}
              text={"Next"}
              onClick={() => {
                this.setState({
                  currentStep: currentStep + 1,
                  isAllDataSet: this.readyCheck(),
                });
                console.log('State', this.state)
              }}
              style={{
                minWidth: "8rem",
              }}
            />
          )}
      </div>
    );
  };

  stepButtons = () => {
    const { currentStep } = this.state;
    const step1Completed =
      !!this.state.subjects &&
      this.state.subjects.length > 0 &&
      !!this.state.tutoringPreference &&
      !!this.state.tutoringRegistration;
    const step2Completed = this.props.user.quizDone;
    const step3Completed =
      this.state.introduceVideoUrl != null &&
      this.state.photoUrl != null &&
      this.state.resumeUrl != null;

    return (
      <div className="stepsContainer h-full rounded-full flex lg:flex-col justify-around items-center">
        {[1, 2, 3, 4].map((value, index) => {
          return (
            <div
              key={index}
              className="w-16 sm:w-20 h-16 sm:h-20 content-center text-center grid grid-cols-1 grid-rows-1"
            >
              <div className="w-full h-full flex flex-col lg:flex-row justify-end items-center col-start-1 row-start-1">
                <div
                  className="semi-circle content-center text-center"
                  style={{
                    backgroundColor:
                      [
                        step1Completed,
                        step2Completed,
                        step3Completed,
                        this.state.isPolicyVerify,
                      ][value - 1] === true
                        ? commonColor.deepGreen
                        : commonColor.disable,
                  }}
                ></div>
              </div>

              <div className="w-full h-full p-2 flex flex-row justify-end items-center col-start-1 row-start-1">
                <button
                  className="w-full h-full focus:outline-none rounded-full bg-white content-center text-center text-3xl font-bold cursor-pointer"
                  style={{
                    borderColor: commonColor.deepGreen,
                    backgroundColor:
                      currentStep === value ? commonColor.deepPink : "white",
                    color: currentStep === value ? "white" : "black",
                  }}
                  onClick={() => {
                    this.setState({
                      currentStep: value,
                      isAllDataSet: this.readyCheck(),
                    });
                  }}
                >
                  {value}
                </button>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  stepsContent = () => {
    const { currentStep } = this.state;
    const errorArray = this.generateErrorArray();

    return (
      <div className="h-full w-full relative py-1 px-1 md:px-0">
        <Step1 saveProperty={this.saveProperty} currentStep={currentStep} />
        <Step2
          saveProperty={this.saveProperty}
          currentStep={currentStep}
          user={this.props.user}
          onNext={this.onNext}
        />
        <Step3
          saveProperty={this.saveProperty}
          currentStep={currentStep}
          user={this.props.user}
        />

        <Step4
          saveProperty={this.saveProperty}
          currentStep={currentStep}
          handleSubmit={this.handleSubmit}
          readyCheck={this.readyCheck}
          isAllDataSet={this.state.isAllDataSet}
          isPolicyVerify={this.state.isPolicyVerify}
          submitted={this.state.submitted}
          missingInfo={errorArray}
          goToStep={(step) => this.setState({ currentStep: step })}
        />
      </div>
    );
  };

  render() {
    const { currentStep } = this.state;

    return (
      <div
        className="w-full h-screen flex justify-center items-center h-screen-ios min-h-screen-ios gradient-background"
        style={{
          backgroundColor: commonColor.deepGreen,
        }}
      >
        {this.state.setupDone == true && this.state.submitted == true && (
          <Navigate to={"/dashboard"} replace={true} />
        )}

        <div className="w-full xl:w-3/4 lg:w-3/4 flex-col flex lg:h-screen  lg:h-screen-ios lg:min-h-screen-ios gap-y-2 lg:gap-y-4 justify-center items-center h-full px-4">
          <div className="max-w-lg hidden md:block">
            <p className="text-white text-center">
              Complete each step by clicking on the numbers and submitting your
              final setup steps in step 4
            </p>
          </div>
          <div
            className="w-full  max-w-3xl h-5/6 lg:h-7/10 flex flex-row  justify-center items-center"
            style={{
              maxHeight: "45rem",
            }}
          >
            <div className="gridParent  w-full h-full">
              {this.stepButtons()}

              <div
                className="formContainer  h-full lg:px-5 lg:py-2 pt-10 lg:pt-5 pb-4 px-4 lg:pl-12 bg-white"
                style={{
                  boxShadow: `0 10px 25px 0 rgba(0, 0, 0, 0.6)`,
                  borderRadius: 25,
                  overflowY: currentStep === 1 ? "visible" : "hidden",
                }}
              >
                <div className=" h-full">
                  <div className="logo-section hidden md:block">
                    <div className="flex h-full justify-center items-start">
                      <img
                        src={SwotknotLogo}
                        alt="Logo"
                        className="max-w-40 h-full pb-2 bg-transparent sm:visible invisible"
                      />
                    </div>
                  </div>
                  <div
                    className={`${currentStep === 2
                      ? "main-section-without-footer"
                      : "main-section"
                      }`}
                  >
                    {this.stepsContent()}
                  </div>

                  {currentStep !== 2 && (
                    <div className="footer">{this.actionButtons()}</div>
                  )}
                </div>
              </div>
              {/* <div
                  className="formContainer h-full lg:px-5 lg:pb-2 pt-10 lg:pt-2 px-5 bg-white"
                  style={{
                        boxShadow: `0 10px 25px 0 rgba(0, 0, 0, 0.6)`,
                        borderRadius: 25,
                        // overflowY: "scroll",
                        // height: "710px"
                        //height: '70%',
                      }}
                >
                  <div className=" h-full">
                    
                    <div className="flex justify-center items-center  h-1/10">
                      <img
                        src={SwotknotLogo}
                        alt="Logo"
                        className="w-40 pt-2 bg-transparent"
                      />
                    </div>
                  
                    <div className="h-full">
                      <div className="h-full w-full relative py-8 px-4 md:px-0">
        
                        <Step1
                          saveProperty={this.saveProperty}
                          currentStep={currentStep}
                        />
                        <Step2
                          saveProperty={this.saveProperty}
                          currentStep={currentStep}
                          user={this.props.user}
                        />
                        <Step3
                          saveProperty={this.saveProperty}
                          currentStep={currentStep}
                          user={this.props.user}
                        />
                        <Step4
                          saveProperty={this.saveProperty}
                          currentStep={currentStep}
                          handleSubmit={this.handleSubmit}
                          readyCheck={this.readyCheck}
                          isAllDataSet={this.state.isAllDataSet}
                          submitted={this.state.submitted}
                        />


                      </div>
                    </div>

                    <div className="flex md:flex-row md:justify-around space-y-4  justify-between items-center py-2"
                      // style={{
                      //   marginBottom: '35px'
                      // }}
                    >
                      {(currentStep !== 1 && (currentStep !== 2 || this.props.user.quizDone)) && <Swotbutton
                        text={"previous"}
                        onClick={() => {
                          this.setState({
                            currentStep: currentStep - 1,
                            isAllDataSet: this.readyCheck(),
                          });

                        }}
                        style={{
                          minWidth: "8rem",
                          marginBottom: '-14px'

                        }}
                      />}
                      {(currentStep !== 4 && (currentStep !== 2 || this.props.user.quizDone)) && <Swotbutton
                        text={"next"}
                        onClick={() => {
                          this.setState({
                            currentStep: currentStep + 1,
                            isAllDataSet: this.readyCheck(),
                          });

                        }}
                        style={{
                          minWidth: "8rem",
                        }}
                      />}
                    </div>

                  </div>
                
                </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TutorSetup);
