import { createClient } from "contentful";

const client = createClient({
  // This is the space ID. A space is like a project folder in Contentful terms
  space: "iudj7tr28avp",
  // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
  accessToken: "_exPOqpszUnJ6cOKSIcK7AbMcxoWs9AuwZowP0CHSWc"
});


export default client