import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFingerprint, faBookOpen } from "@fortawesome/free-solid-svg-icons";

import { H5PEditorUI } from "@lumieducation/h5p-react";
import { IContentListEntry, IContentService } from "../services/ContentService";
import "./ContentListEntryComponent.css";
import Swotbutton from "../../components/common/buttons/swotbutton";
import Swal from "sweetalert2";
import { IContentMetadata } from "@lumieducation/h5p-server";
import { createH5pContent } from "../../services/api";
import LoaderModal from "../../components/common/modal/loaderModal";

import CustomFadeLoader from "../../components/common/CustomFadeLoader";

import commonColor from "../../theme/commonColor";
import "../../h5p/H5pApp.css";

export default class CreateContentComponent extends React.Component<{
  userId: String;
  contentService: IContentService;
  newContent: boolean;
  contentEntry?: IContentListEntry;
  onSaved: (data?: IContentListEntry) => void;
  onClose: () => void;
}> {
  constructor(props: {
    userId: string;
    contentService: IContentService;
    newContent: boolean;
    contentEntry?: IContentListEntry;
    onSaved: (data?: IContentListEntry) => void;
    onClose: () => void;
  }) {
    super(props);
    this.state = {
      ...{
        // editing: props.data.contentId === "new",
        editing: true,
        saving: false,
        saved: false,
        loading: true,
        saveErrorMessage: "",
        saveError: false,
        showingCustomCopyright: false,
      },
      ...(props.newContent
        ? this.newContentState()
        : this.props.contentEntry
          ? this.editContentState()
          : {}),
    };
    this.h5pEditor = React.createRef();
    this.saveButton = React.createRef();
  }

  public state: {
    editing: boolean;
    loading: boolean;
    saved: boolean;
    saving: boolean;
    saveError: boolean;
    saveErrorMessage: string;
    showingCustomCopyright: boolean;
    contentEntry?: IContentListEntry;
  };

  private h5pEditor: React.RefObject<H5PEditorUI>;
  private saveButton: React.RefObject<HTMLButtonElement>;

  actionEditting = () => {
    return (
      <div className="flex">
        {this.state.editing ? (
          <div className="p-2">
            <Swotbutton
              text={this.state.saving ? "Saving.." : "Save"}
              className={
                this.state.saving || this.state.loading ? "disabled" : ""
              }
              disabled={this.state.saving || this.state.loading}
              onClick={() => this.save()}
            />
          </div>
        ) : undefined}

        {this.state.editing && !this.isNew() ? (
          <div className="p-2 text-sm">
            <Swotbutton
              text="Close"
              onClick={() => {
                this.close();
              }}
            />
          </div>
        ) : undefined}

        {this.state.editing && this.isNew() ? (
          <div className="p-2">
            <Swotbutton
              text="Cancel"
              onClick={() => {
                this.discard();
              }}
            />
          </div>
        ) : undefined}
      </div>
    );
  };

  public render(): React.ReactNode {
    return (
      <div
        className="w-full h-full p-2"
      // key={this.state.contentEntry.originalNewKey ?? this.state.contentEntry.contentId}
      >
        {this.state.editing && this.state.contentEntry !== undefined ? (
          <div
            className="w-full ml-auto mr-auto"
            style={{
              maxWidth: "960px",
            }}
          >
            <div className="w-full flex justify-start">
              <div className="p-2">
                <h3>{this.state.contentEntry.title}</h3>
                <div className="small text-xs">
                  {this.state.contentEntry.mainLibrary &&
                    this.state.contentEntry.mainLibrary.trim().length > 0 && (
                      <div className="me-2">
                        <FontAwesomeIcon icon={faBookOpen} className="me-1" />
                        {this.state.contentEntry.mainLibrary}
                      </div>
                    )}
                  {this.state.contentEntry.contentId &&
                    this.state.contentEntry.contentId !== "new" && (
                      <div className="me-2">
                        <FontAwesomeIcon
                          icon={faFingerprint}
                          className="me-1"
                        />
                        {this.state.contentEntry.contentId}
                      </div>
                    )}
                </div>
                {this.actionEditting()}
              </div>
            </div>

            <div className="relative w-full flex-col">
              {this.isNew() && this.state.loading && (
                <div className="w-full flex items-center justify-center absolute">
                  <CustomFadeLoader
                    radius="2"
                    loading={true}
                    color={commonColor.deepGreen}
                  />
                </div>
              )}
              <div className="w-full absolute">
                <H5PEditorUI
                  ref={this.h5pEditor}
                  contentId={this.state.contentEntry.contentId}
                  loadContentCallback={this.props.contentService.getEdit}
                  saveContentCallback={this.props.contentService.save}
                  onSaved={this.onSaved}
                  onLoaded={this.onEditorLoaded}
                  onSaveError={this.onSaveError}
                />
              </div>
            </div>

            {!this.isNew() && this.state.loading && (
              <div className="w-full flex items-center justify-center">
                <CustomFadeLoader
                  radius="2"
                  loading={true}
                  color={commonColor.deepGreen}
                />
              </div>
            )}
          </div>
        ) : (
          <div className="w-full h-full flex justify-center items-center">
            <Swotbutton
              text="Create New"
              onClick={() => {
                this.new();
              }}
            />
          </div>
        )}

        {/* <LoaderModal
          isOpen={this.state.saving}
          message={"Saving.."}
          onRequestClose={() => {
            // this.setState({ loading: false });
          }}
        /> */}
      </div>
    );
  }

  protected play() {
    // this.setState({ editing: false, playing: true });
  }

  protected edit() {
    this.setState({ editing: true });
  }

  protected close() {
    this.setState({ editing: false });
    this.props.onClose();
  }

  protected new() {
    this.setState(this.newContentState());
  }

  protected newContentState() {
    return {
      loading: true,
      editting: true,
      contentEntry: {
        contentId: "new",
        mainLibrary: undefined,
        title: "New Content",
        originalNewKey: `${this.props.userId ?? "new"}-${Date.now()}`,
      },
    };
  }

  protected editContentState() {
    return {
      loading: true,
      editting: true,
      contentEntry: this.props.contentEntry,
    };
  }

  private discard = () => {
    this.setState({
      loading: false,
      editting: false,
      contentEntry: undefined,
    });
    this.props.onClose();
  };

  protected showCopyrightCustom() {
    this.setState({ showingCustomCopyright: true });
  }

  protected closeCopyrightCustom() {
    this.setState({ showingCustomCopyright: false });
  }

  protected async save() {
    this.setState({ saving: true });
    try {

      const returnData = await this.h5pEditor.current?.save();
      if (returnData) {
        await this.props.onSaved({
          contentId: returnData.contentId,
          mainLibrary: returnData.metadata.mainLibrary,
          title: returnData.metadata.title,
          originalNewKey: this.state.contentEntry?.originalNewKey
        });
      }

    } catch (error) {
      console.log(error);
      // We ignore the error, as we subscribe to the 'save-error' and
      // 'validation-error' events.
    }
  }

  protected onSaveError = async (event) => {
    this.setState({
      saving: false,
      saved: false,
      saveError: true,
      saveErrorMessage: event.detail.message,
    });
    setTimeout(() => {
      this.setState({
        saveError: false,
      });
    }, 5000);
  };

  protected onSaved = async (contentId, metadata?: IContentMetadata) => {
    const lib = metadata?.mainLibrary;
    const title = metadata?.title;
    const response = await createH5pContent(
      this.props.userId,
      contentId,
      title,
      lib
    );
    this.setState({
      saving: false,
      saved: true,
    });
    this.props.onSaved(this.state.contentEntry);
    this.discard();

    // setTimeout(() => {
    this.setState({ saved: false });
    // }, 3000);

    Swal.fire({
      title: "Success!",
      text: this.props.newContent
        ? "Content created succesfully."
        : "Content saved successfully",
      icon: "success",
      confirmButtonText: "OK",
    });
    await this.props.onSaved({
      contentId: contentId,
      mainLibrary: metadata?.mainLibrary,
      title: metadata?.title,
      originalNewKey: this.state.contentEntry?.originalNewKey,
    });
  };

  protected onEditorLoaded = () => {
    this.setState({ loading: false });
  };

  private isNew() {
    return this.state.contentEntry?.contentId === "new";
  }
}
