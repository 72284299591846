import Config from '../config/debugConfig';

import Reactotron from '../config/reactotronConfig';
import { createStore, applyMiddleware, compose } from 'redux';
import Rehydration from '../services/rehydration';
import ReduxPersist from '../config/reduxPersist';
import createSagaMiddleware from 'redux-saga';


// creates the store
export default (rootReducer, rootSaga) => {


  /* ------------- Redux Configuration ------------- */

  const middleware = [];
  const enhancers = [];

  // /* ------------- Navigation Middleware ------------ */
  // const navigationMiddleware = createReactNavigationReduxMiddleware(
  //   // 'root',
  //   state => state.nav
  // );
  // middleware.push(navigationMiddleware);

  /* ------------- Saga Middleware ------------- */

  const sagaMonitor = Config.useReactotron ? console.tron.createSagaMonitor() : null;
  const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
  middleware.push(sagaMiddleware);

  /* ------------- Assemble Middleware ------------- */

  enhancers.push(applyMiddleware(...middleware));

  // if Reactotron is enabled (default for __DEV__), we'll create the store through Reactotron
  // console.log("CreteStore - Config.useReactotron ", Config.useReactotron)

  Config.useReactotron && enhancers.push(Reactotron.createEnhancer())
  //const createAppropriateStore = Config.useReactotron ? Reactotron.createStore : createStore;
  const store = createStore(rootReducer, {}, compose(...enhancers));

  // configure persistStore and check reducer version number
  if (ReduxPersist.active) {
    Rehydration.updateReducers(store);
  }

  // kick off root saga
  let sagasManager = sagaMiddleware.run(rootSaga);

  return {
    store,
    sagasManager,
    sagaMiddleware
  };
};
