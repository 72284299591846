import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { ClimbingBoxLoader } from "react-spinners";

function StripeWrapper({ accountId, children }) {
  const [stripeObject, setStripeObject] = useState(null);

  // This function will re-run if the accountId prop changes.
  useEffect(() => {
    const fetchStripeObject = async () => {
      // If there is no accountId, do not run the loadStripe function.
      if (accountId) {
        const res = await loadStripe(
          "pk_test_51JcPpxJ8uTGS94wiuslDk1EB6nRIZyDbK9USwzwwv5OnwzNiEbLmX2lCJGuPMY7dGFdp87EQQRDoquPOOOESeFEK003BBvpUbi",
          {
            stripeAccount: accountId,
          }
        );
        // When we have got the Stripe object, pass it into our useState.
        setStripeObject(res);
      }
    };
    fetchStripeObject();
  }, [accountId]);

  // If no Stripe object, do not render the Stripe Element.
  if (!stripeObject) {
    return (
      <ClimbingBoxLoader
        css={{
          background: "rgba(255,255,255,0.8)",
          position: "absolute",
          display: "block",
          width: "100%",
          height: "100%",
          bottom: "0",
          left: "0",
          "z-index": "9999",
        }}
      />
    );
  }

  // Once we have the Stripe object, load everything.
  return <Elements stripe={stripeObject}>{children}</Elements>;
}

export default StripeWrapper;
