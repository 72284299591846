import React from "react";
import classNames from "classnames";

export default function GutterHeader({ labels }) {
  return (
    <div className="flex justify-between">
      {labels.map((label, i) => {
        const { title, colorStyle = "primary" } = label;
        const width = `${100 / labels.length}%`;

        const className = classNames("text-white text-center rounded-full py-1 mb-4", {
          "mr-2": i < labels.length - 1,
          "md:mr-4": i < labels.length - 1,
          "bg-primary-500": colorStyle === "primary",
          "bg-primary-300": colorStyle === "secondary",
          "bg-primary-100": colorStyle === "tertiary",
          "": colorStyle === "transparent",
          "text-center": labels.length > 1,
          "text-start": labels.length === 1,
          "px-4": labels.length === 1,
        });

        return (
          <div key={i} className={className} style={{ width }}>
            {title}
          </div>
        );
      })}
    </div>
  );
}
