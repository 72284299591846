import React, { useEffect } from "react";
import { connect } from "react-redux";
import commonColor from "../../../theme/commonColor";

import { ReactComponent as Reading } from "../../../assets/icons/reading-book.svg";

const CourseListItem = ({ course, selected, setSelected, showNotificationIndicator, user }) => {
  useEffect(() => {}, [selected]);

  const courseTutorUsername = user.accountType === 'Tutor' ? user.username : course.tutorUsername

  return (
    <div
      className={`w-56 md:w-1/4 lg:w-1/4 2xl:w-1/5 flex-shrink-0 ${selected ? "px-1 py-0" : "px-3 py-1"} pb-2`}
      style={{
        // maxHeight: "15rem",
        minHeight: "8rem",
        minWidth: "10rem",
        maxWidth: "20rem",
        aspectRatio: "7/4"
      }}
    >
      <div
        className={`w-full h-full rounded-xl bg-white darker_shadow flex 
        flex-col items-start focus:outline-none relative  cursor-pointer ${selected ? "" : 'course-item'}`}
        onClick={() => setSelected(course)}
        style={{
          minHeight: "8rem",
          backgroundColor: selected ? commonColor.deepBlue : "white",
        }}
      >
        {
          showNotificationIndicator ? (
            <div
              style={{ backgroundColor: commonColor.deepPink, top: "-5px", left: "-5px" }}
              className="w-4 h-4 rounded-full absolute"
            />
          ) : null
        }
        <div
          className="py-3 px-2 pb-2 text-sm xl:text-lg"
          style={{
            color: selected ? "white" : "black",
          }}
        >
          {course.nameShort?.length > 18
            ? `${course.nameShort?.substring(0, 15)}...`
            : course.nameShort}
        </div>
        <div className="flex flex-col items-start justify-center">
          <div
            className="px-2 flex text-xs"
            style={{
              color: selected ? "white" : "black",
            }}
          >
            {course.subject && `${course.subject}`}
          </div>
          <div
            className="px-2 flex text-xs"
            style={{
              color: selected ? "white" : "black",
            }}
          >
            {course.subjectArea && `${course.subjectArea}`}
          </div>

          <div
            className="px-2 flex text-xs"
            style={{
              color: selected ? "white" : "black",
            }}
          >
            {course.rate && `$${course.rate}`}
          </div>
        </div>

        <div
          style={{ bottom: "5px", right: "5px" }}
          className="absolute"
        >
          <Reading
            style={{
              width: "1.5rem",
              height: "1.5rem",
              overflow: "hidden",
              fill: selected ? "white" : commonColor.deepPink,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CourseListItem);