import { createClient } from "contentful-management";

const clientManagement = createClient({
  // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
//  accessToken: 'CFPAT-As5HqSlNLi9O5_lWwNVQNlCkJILQ9eUjWSuycirS84Q',
    accessToken: 'CFPAT-oXZGrOlmsogYv3YDeXT7g4QdtfQwGpG3klOzmb5Wt1o',
});




export default clientManagement