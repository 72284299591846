import React, { useState, useEffect } from "react";
import Select from "../../common/input/select";
import Checkbox from "../../common/input/checkbox";
import SwotButton from "../../common/buttons/swotbutton";
import {
  getTutoringInfo,
  updateTutorTutoringInfo,
  getContentTypeOptions,
} from "../../../services/api";
import defaultCurriculumData from "../../../screens/tutorSetup/steps/defaultCurriculumData";
import Swal from "sweetalert2";

const TutoringInfo = ({ user }) => {
  const tutoringRegistrationOpts = [
    { value: "online", label: "Online" },
    // { value: "asynchronous", label: "Asynchronous" },
    // { value: "face-2-face", label: "Face to face" },
  ];
  const tutoringContentTypeOpts = [
    { value: "curriculum", label: "School Subjects" },
    { value: "extra curriculum", label: "Skill Training" },
  ];

  const tutoringPreferenceOpts = [
    { value: "groups", label: "Groups" },
    { value: "individuals", label: "Individuals" },
  ];

  const [info, setInfo] = useState();
  const [tutoringRegistration, setTutoringRegistration] = useState(
    tutoringRegistrationOpts[0]
  );
  const [tutoringPreference, setTutoringPreference] = useState(
    tutoringPreferenceOpts[0]
  );

  const [isLoading, setIsLoading] = useState(true);

  const [subjectAreas, setSubjectAreas] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [subjectAreaOptions, setSubjectAreaOptions] = useState([]);
  const [tutoringContentType, setTutoringContentType] = useState(
    tutoringContentTypeOpts[0]
  );
  const [subjects, setSubjects] = useState([]);
  const [contentTypeMaterial, setContentTypeMaterial] = useState(
    defaultCurriculumData
  );
  const [isSpecialEdu, setisSpecialEdu] = useState(false);

  useEffect(() => {
    if (!info) {
      getTutorInfo();
    } else {
      console.log(`useEffect  - info:`, info);
      setTutoringRegistration({ value: info.tutoring, label: info.tutoring });
      let preferredSubjectAreas = info.subjects.map((sub) => {
        return { value: sub.area, label: sub.area };
      });
      setSubjectAreas(preferredSubjectAreas);
      let preferredSubjects = info.subjects.map((sub) => sub.subject);

      const subjectSet = Array.from(new Set(preferredSubjects));

      const selectedSubjects = subjectSet.map((sub) => {
        return { value: sub, label: sub };
      });
      setSubjects(selectedSubjects);
      setisSpecialEdu(info.specialEducation);
      setTutoringPreference({ value: info.preference, label: info.preference });
      const contentType = info.subjects[0]
        ? info.subjects[0].tutoringCurriculum
        : tutoringContentTypeOpts[0].value;


      let contentLabel =  "Skill Training";
      
      if(contentType === "curriculum"){
        contentLabel = "School Subjects";
      }

      setTutoringContentType({
        value: contentType,
        label: contentLabel,
      });
    }
  }, [info, user.uid]);

  useEffect(() => {
    console.log(`useEffect 2 - changed subjects:`, subjects);
    displaySubjectAreas(subjects);
  }, [subjects]);

  useEffect(() => {}, [tutoringPreference, isSpecialEdu, tutoringRegistration]);

  useEffect(() => {
    const getData = async () => {
      console.log(`useEffect 1 - tutoringContentType:`, tutoringContentType);
      const data = await getDataForContentType(tutoringContentType);
      setContentTypeMaterial(data ? data : {});
      displaySubjects(data ? data : {});
    };

    getData();
  }, [tutoringContentType]);

  const getDataForContentType = async (contentType) => {
    setIsLoading(true);
    // Call BE for content subjets, areas
    let response = await getContentTypeOptions(
      contentType ? contentType.value === "curriculum" : true
    );
    // save to local state
    console.log("getDataForContentType - response", response);
    if (response && response.ok) {
      //setContentTypeMaterial(response.data)
      setIsLoading(false);
      return response.data;
    } else {
      return null;
    }
  };

  const getTutorInfo = async () => {
    const result = await getTutoringInfo(user.uid);
    setIsLoading(true);
    if (result) {
      console.log(
        "TutoringInfo - useEffect - got tutor personalInfo",
        result.data
      );
      setInfo(result.data);
      setIsLoading(false);
    }
  };

  const updateTutorInfo = async () => {
    console.log("Updating Tutoring Info!", subjectAreas, tutoringRegistration);
    const selectedSubjectAreas = subjectAreas.map((area) => area.value);
    let payload = {
      ...info,
      tutoringRegistration: tutoringRegistration.value,
      tutoringPreference: tutoringPreference.value,
      subjectAreas: selectedSubjectAreas,
      specialEducation: isSpecialEdu,
    };
    console.log("Updating Tutoring Info - payload", payload);
    const result = await updateTutorTutoringInfo(user.uid, payload);
    if (result) {
      console.log("Tutoring  - update tutor info - result", result);
    }
  };

  const displaySubjects = (data) => {
    setIsLoading(true); // Start loading
    let subjectOptions = [];
    let chosenSubjectAreaOptions = [];

    if (!data.subjects) {
      return;
    }

    for (const [key, value] of Object.entries(data.subjects)) {
      // console.log(`displaySubjects - found subjects ${key}:`, value);
      const subjectAreaOptions = value.areas.map((area) => {
        return { value: area, label: area };
      });
      // console.log(`displaySubjects - subjectAreaOptions:`, subjectAreaOptions);
      subjectOptions.push({ value: key, label: key });
      let subjectAreas = {
        label: key,
        options: subjectAreaOptions,
      };
      chosenSubjectAreaOptions.push(subjectAreas);
    }
    setSubjectOptions(subjectOptions);
    setSubjectAreaOptions(chosenSubjectAreaOptions);
  };

  const displaySubjectAreas = (subjects) => {
    // console.log(
    //   "displaySubjectAreas - subjects:",
    //   subjects,
    //   contentTypeMaterial
    // );

    let chosenSubjectAreaOptions = [];
    subjects.forEach((sub) => {
      const subject = contentTypeMaterial.subjects[sub.value];
      // console.log("displaySubjectAreas - selected subject:", sub, subject);
      if (subject) {
        let areaOptions = contentTypeMaterial.subjects[sub.value].areas.map(
          (area) => {
            return { value: area, label: area };
          }
        );
        let subjectAreas = {
          label: sub.value,
          options: areaOptions,
        };
        chosenSubjectAreaOptions.push(subjectAreas);
      }
    });

    setSubjectAreaOptions(chosenSubjectAreaOptions);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  return (
    <div
      className="h-full w-full  flex flex-col justify-around items-center pr-2"
      style={{ textTransform: "capitalize" }}
    >
      <div className="w-full flex flex-row justify-center ">
        <div className="text-sm w-1/3 lg:w-1/4 flex flex-row justify-end items-center">
          {"Tutoring"}
        </div>
        <div className="w-2/3 lg:w-3/4 flex flex-row justify-start px-2">
          <Select
            defaultValue={
              tutoringRegistration
                ? tutoringRegistration
                : tutoringRegistrationOpts[0]
            }
            value={
              tutoringRegistration
                ? tutoringRegistration
                : tutoringRegistrationOpts[0]
            }
            onChange={(value) => {
              console.log("selected setTutoringRegistration", value);
              setTutoringRegistration(value);
              // handleChange('tutoringRegistration', value.value)
            }}
            options={tutoringRegistrationOpts}
          />
        </div>
      </div>

      <div className="w-full flex flex-row justify-center">
        <div className="tutorContentText text-sm w-1/3 lg:w-1/4 flex flex-row justify-end items-center text-right">
          {"Tutoring Content"}
        </div>
        <div className="w-2/3 lg:w-3/4 flex flex-row justify-start px-2">
          <Select
            className="lg:w-full w-full"
            defaultValue={tutoringContentTypeOpts[0]}
            value={
              tutoringContentType
                ? tutoringContentType
                : tutoringContentTypeOpts[0]
            }
            onChange={(value) => {
              console.log("selected content type:", value);
              setTutoringContentType(value);
              //getDataForContentType(value)
            }}
            options={tutoringContentTypeOpts}
          />
        </div>
      </div>

      <div className="w-full flex flex-row justify-center">
        <div className="text-sm w-1/3 lg:w-1/4 flex flex-row justify-end items-center">
          {"Subjects"}
        </div>
        <div className="w-2/3 lg:w-3/4 flex flex-row justify-start px-2">
          <Select
            defaultValue={subjects}
            closeMenuOnSelect={false}
            value={subjects}
            onChange={(value) => {
              value = value ? value : [];
              console.log("selected subjects:", value);

              if (value.length <= 3) {
                setSubjects(value);
                const subjectsArray = value ? value.map((x) => x.value) : [];
                console.log("subject to be saved:", subjectsArray);
                // handleChange('subjects', subjectsArray)
              } else {
                Swal.fire({
                  title: "Failed!",
                  text: "Although we would love you to teach as many subjects you want, for setup purposes, please let us know your top three",
                  icon: "error",
                  confirmButtonText: "OK",
                });
              }
            }}
            isMulti
            options={subjectOptions}
          />
        </div>
      </div>

      <div className="w-full flex flex-row justify-center">
        <div className="text-sm w-1/3 lg:w-1/4 flex flex-row justify-end items-center">
          {"Subject Areas"}
        </div>
        <div className="w-2/3 lg:w-3/4 flex flex-row justify-start px-2">
          <Select
            value={subjectAreas}
            defaultValue={subjectAreas}
            options={
              isLoading
                ? [{ value: "", label: "Loading..." }]
                : subjectAreaOptions
            }
            isFormatGroupLabelUsed
            isMulti
            closeMenuOnSelect={false}
            isDisabled={isLoading} // Disable the select input while loading
            onChange={(value) => {
              if (!isLoading) {
                // Prevent changes while loading
                value = value ? value : [];
                console.log("selected subject Areas:", value);

                if (value.length <= 5) {
                  setSubjectAreas(value);
                  const subjectAreasArray = value
                    ? value.map((x) => x.value)
                    : [];
                  console.log("subject Areas to be saved:", subjectAreasArray);
                  // handleChange('subjectAreas', subjectAreasArray)
                } else {
                  Swal.fire({
                    title: "Failed!",
                    text: "You can not select more than 5 subject areas",
                    icon: "error",
                    confirmButtonText: "OK",
                  });
                }
              }
            }}
          />
        </div>
      </div>

      <div className="w-full flex flex-row justify-center">
        <div className="preferTeachText text-sm w-1/3 lg:w-1/4 flex flex-row justify-end items-center text-right">
          {"I prefer to teach"}
        </div>
        <div className="w-2/3 lg:w-3/4 flex flex-row justify-start px-2">
          <Select
            defaultValue={
              tutoringPreference
                ? tutoringPreference
                : tutoringPreferenceOpts[0]
            }
            value={
              tutoringPreference
                ? tutoringPreference
                : tutoringPreferenceOpts[0]
            }
            onChange={(value) => {
              console.log("selected setTutoringPreference", value);
              setTutoringPreference(value);
              // handleChange('tutoringPreference', value.value)
            }}
            options={tutoringPreferenceOpts}
          />
        </div>
      </div>
      <div className="w-full flex flex-row justify-center">
        <div className="text-sm w-1/3 lg:w-1/4 flex flex-row justify-end items-center"></div>
        <div className="w-2/3 lg:w-3/4 flex flex-row justify-start px-2">
          <Checkbox
            label={" Special Education"}
            isSelected={isSpecialEdu}
            onCheckboxChange={(changeEvent) => {
              setisSpecialEdu(!isSpecialEdu);
            }}
            key={"isSpecialEdu"}
          />
        </div>
      </div>
      <div className="m-4 md:m-0">
        <SwotButton text={"Update"} onClick={() => updateTutorInfo()} />
      </div>
    </div>
  );
};

export default TutoringInfo;
