import React from "react";
import { css } from "@emotion/react";
import { ClimbingBoxLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 2rem auto;
  border-color: white;
`;

const BoxLoader = ({ color, loading, size = 6 }:{color?: string, loading?: boolean, size?: number}) => {
  return (
    <ClimbingBoxLoader
      color={color ? color : "white"}
      loading={loading}
      css={override}
      size={size}
    />
  );
};

export default BoxLoader;
