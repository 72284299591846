import React from "react";
import "./debitcard.css";
import { ReactComponent as VrtIcon } from "../../../assets/icons/master-card.svg";
import { ReactComponent as KnotIcon } from "../../../assets/icons/knot-white.svg";
import LearnLogo from "../../../assets/images/learn-logo-colour.svg";
import moment from "moment";
const DebitCard = ({ accountId, name, createdAt }) => {
  const nameMaxCharacters = 20;
  return (
    <div
      className="w-full overflow-hidden"
      style={{
        maxWidth: "30rem",
      }}
    >
      <div className="aspectratio" data-ratio="16:10">
        <div>
          <div
            className="flex w-full h-full rounded-xl text-white"
            style={{
              backgroundColor: "#000000",
            }}
          >
            <div
              className="h-full flex flex-col text-xs sm:text-sm"
              style={{
                width: "86%",
              }}
            >
              <div
                className="flex justify-between items-center"
                style={{
                  height: "40%",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    paddingLeft: "5%",
                  }}
                >
                  {/* <VrtIcon
                    style={{
                      // width: "22%",
                      width: "30%",
                    }}
                  /> */}
                  <img
                    src={LearnLogo}
                    alt="IQ2Img"
                    style={{
                      width: "50%",
                    }}
                  />
                </div>
                <div
                  style={{
                    paddingRight: "14.5%",
                  }}
                >
                  <p className="text-white">Bank</p>
                </div>
              </div>
              <div
                className="flex flex-col justify-center items-start"
                style={{
                  height: "30%",
                  paddingLeft: "10%",
                  paddingRight: "10%",
                  width: "100%",
                }}
              >
                <p>Customer stripe ID</p>
                <p>{accountId}</p>
              </div>
              <div
                className="flex justify-between items-start"
                style={{
                  height: "30%",
                  width: "100%",
                  paddingLeft: "10%",
                  paddingRight: "10%",
                }}
              >
                <div className="flex flex-col items-start">
                  <p>Name</p>
                  <p>{name?.substring(0, nameMaxCharacters)}</p>
                </div>
                <div className="flex flex-col items-start">
                  <p>date created</p>
                  <p>
                    {createdAt && moment.unix(createdAt)?.format("MM/DD/YYYY")}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="h-full"
              style={{
                width: "14%",
              }}
            >
              <KnotIcon
                className=""
                style={{
                  height: "100%",
                  width: "240%",
                  marginRight: "-140%",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DebitCard;
