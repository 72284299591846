import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import StudentCalendar from "../student/calendar/calendar";
import commonColor from "../../theme/commonColor";
import Reminders from "../courses/reminders";
import Swotbutton from "../../components/common/buttons/swotbutton";
import { EventType } from "../../config/constants";
import moment from "moment";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { getOpenCoursesForStudent } from "../../services/api";
import { useWindowSize } from "../../utils/uiUtils";
import TimeSlot from "../calendar/timeSlot";
import GutterHeader from "../calendar/gutterHeader";
import CustomToolbar from "../calendar/customToolbar";
import FactOfTheDay from "../tutor/dashbaord/factOfTheDay";
import MaterialUploaderViewer from "../courses/materialUploaderViewer";
import CourseDefault1Icon from "../../assets/images/default-course1.jpg";
import FooterView from "../common/footerView";
import Select from "../common/input/select";
import { useTour } from "@reactour/tour";
import { tourStepsForStuent } from "../common/tourOverlay/tourSteps";
import { updateUser } from "../../services/userManagement";

const StudentDashboard = ({ user, notifications }) => {
  const navigate = useNavigate();
  
  const [selectedTab, setSelectedTab] = useState("lesson");
  const [courses, setCourses] = useState([]);
  const [myLessons, setMyLessons] = useState([]);
  const [init, setInit] = useState(false);

  const [selectedCourse, setSelectedCourse] = useState();
  const [calendarEvents, setCalendarEvents] = useState();
  const [joinSessionUrl, setJoinSessionUrl] = useState();
  const [joinSessionCourseId, setJoinSessionCourseId] = useState();
  const [courseResources, setCourseResources] = useState([]);
  const [courseAssignments, setCourseAssignments] = useState([]);

  const [width, height] = useWindowSize();
  const [showModal, setShowModal] = useState(false);
  const { setIsOpen, setSteps } = useTour();

  useEffect(() => {
    console.log("Calendar  events changed", calendarEvents);
  }, [selectedTab, selectedCourse, courses, width, height, calendarEvents]);

  useEffect(() => {
    const getCourseInfo = async () => {
      const result = await getOpenCoursesForStudent(user.uid);

      if (result?.data?.courses) {
        console.log("StudentDashboard - useEffect - getCourseInfo", result);

        const preparedCourses = prepareCourses(result.data.courses);
        setCalendarEvents(preparedCourses);

        const uniqueCourses = Array.from(
          new Set(preparedCourses.map((c) => c.courseId))
        ).map((id) => {
          return preparedCourses.find((c) => c.courseId === id);
        });
        setCourses(uniqueCourses);
        setSelectedCourse(uniqueCourses[0]);

        const studentCourses = uniqueCourses.map((course) => {
          return { value: course.courseId, label: course.nameShort };
        });

        setMyLessons(studentCourses);
        setInit(true);
      }
    };

    getCourseInfo();

    if (user.uid && !user.tourPopoverShown) {
      setSteps(tourStepsForStuent);
      setIsOpen(true);
      updateUser(
        {        
          ...user,
          uid: user.uid,
          tourPopoverShown: true,
        },
        true
      );
    }
  }, [user.uid]);

  useEffect(() => {
    console.log(
      "CourseLandingPage - useEffect - notifications updated",
      notifications
    );
    if (notifications) {
      if (notifications.timeStart && notifications.timeEnd) {
        const starttime = moment.unix(notifications.timeStart);
        const endtime = moment.unix(notifications.timeEnd);
        if (moment().isSameOrAfter(starttime) && moment().isBefore(endtime)) {
          setJoinSessionCourseId(notifications.courseId);
          setJoinSessionUrl(notifications.joinUrl);
        }
      }
    }
    // if (notifications !== null) {
    //   setJoinSessionUrl(notifications.joinUrl);
    //   setJoinSessionCourseId(notifications.courseId);
    // }
  }, [notifications]);

  const goToCourseSession = async () => {
    console.log("Going to class!");
    window.open(joinSessionUrl, "_blank");
  };

  const prepareCourses = (courses) => {
    // TODO: Add a check here for older open sessions
    // .filter((course) => course.sessionEnd >= moment().unix())
    let preparedCourses = courses.map((course) => {
      const day = moment.unix(course.sessionStart).format("ddd");
      const time = moment.unix(course.sessionStart).format("LT");
      const start = course.sessionStart;
      const end = course.sessionEnd;
      const title = course.nameShort;
      const type = course.type ? course.type : EventType.bookedCourseSession;
      const prepCourse = { ...course, day, time, start, end, title, type };
      return prepCourse;
    });
    console.log("StudentDashboard - prepareCourses", preparedCourses);
    return preparedCourses;
  };

  const renderSelectedTab = () => {
    if (!selectedCourse) {
      return !init ? (
        <div className="w-full h-full flex justify-center items-center">
          <div className="italic">"Loading..."</div>
        </div>
      ) : (
        <div className="w-full h-full flex  flex-col-reverse rounded-3xl relative">
          <img
            src={CourseDefault1Icon}
            alt="courseDefaultIcon"
            className="bg-orange-500 cover w-full h-full absolute"
            style={{
              height: "100%",
              width: "100%",
              display: "absolute",
              objectFit: "cover",
            }}
          />
          <div className="w-full p-2 flex justify-between z-[1] bg-white flex-col md:flex-row items-center md:items-start">
            <div className=" flex flex-col w-full md:w-1/2 text-center md:text-left mb-4 md:mb-0">
              <div
                className="px-4 flex text-sm"
                style={{
                  color: "black",
                }}
              >
                <span>
                  You have no lessons at this time. Search to find courses and
                  add lessons, to start learning.
                </span>
              </div>
            </div>
            <div className="h-full w-1/2 flex flex-col justify-end items-end">
              <div className=" md:pb-4 md:pr-4">
                <Swotbutton
                  text="Search Course"
                  onClick={() => navigate("/find-tutors")}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (selectedTab === "lesson") {
      return lessonWidget(selectedCourse);
    } else if (selectedTab === "assignments") {
      return (
        <div className="w-full h-full flex justify-center items-center p-2 relative rounded-3xl">
          <MaterialUploaderViewer
            courseId={selectedCourse.courseId}
            uploadPath={`courses/${selectedCourse.courseId}/material/assignments`}
            materialType="assignments"
            noItemsText="No assignments yet."
            itemsExisting={courseAssignments}
            updateCallback={(materialItems) => {
              setCourseAssignments(materialItems);
            }}
            readOnly={true}
          />
        </div>
      );
    } else if (selectedTab === "resources") {
      return (
        <div className="w-full h-full flex justify-center items-center p-2 relative rounded-3xl">
          {/* No resources yet. */}
          <MaterialUploaderViewer
            courseId={selectedCourse.courseId}
            uploadPath={`courses/${selectedCourse.courseId}/material/resources`}
            materialType="resources"
            noItemsText="No resources yet."
            itemsExisting={courseResources}
            updateCallback={(materialItems) => {
              setCourseResources(materialItems);
            }}
            readOnly={true}
          />
        </div>
      );
    }
  };

  const lessonSelector = (title) => {
    return myLessons.length > 1 ? (
      <div className="w-full h-full flex justify-start items-center">
        <div className="text-white">Courses</div>
        <div className="pl-4 flex-grow">
          <Select
            className=" w-full focus:outline-none"
            isRadiusUsed
            //value={{ value: selectedCourseId, label: selectedCourseNameShort }}
            // defaultValue={{
            //   value: courseDetails?.courseId,
            //   label: courseDetails?.nameShort,
            // }}
            value={
              selectedCourse
                ? {
                    value: selectedCourse.courseId,
                    label: selectedCourse.nameShort,
                  }
                : myLessons[0]
            }
            onChange={(value) => {
              const course = courses.find((c) => c.courseId === value.value);
              if (course) {
                setSelectedCourse(course);
              }
              // setCourseLoaded(false);
              // setSelectedCourseId(value.value);
            }}
            options={myLessons}
          />
        </div>
      </div>
    ) : null;
  };

  const lessonsWidget = () => (
    <WraperWithTitle
      title={selectedCourse ? `Course: ${selectedCourse.nameShort}` : "Courses"}
      headerWidget={lessonSelector()}
      screenWidth={width}
      contentClass={"md:flex-grow"}
    >
      <div className={`md:h-full w-full flex flex-col`}>
        <div className="w-full">
          {selectedCourse && (
            <div className="w-full h-full flex justify-start items-start  pl-2 xl:pl-12">
              <button
                className="pt-1 px-6 pb-1 text-center text-white text-xs sm:text-sm focus:outline-none"
                style={{
                  backgroundColor:
                    selectedTab === "lesson"
                      ? commonColor.deepPink
                      : "transparent",
                  borderTopLeftRadius: "1rem",
                  borderTopRightRadius: "1rem",
                }}
                onClick={() => setSelectedTab("lesson")}
              >
                Course
              </button>

              <button
                className="pt-1 px-6 pb-1 text-center text-white text-xs sm:text-sm focus:outline-none"
                style={{
                  backgroundColor:
                    selectedTab === "assignments"
                      ? commonColor.deepPink
                      : "transparent",
                  borderTopLeftRadius: "1rem",
                  borderTopRightRadius: "1rem",
                }}
                onClick={() => setSelectedTab("assignments")}
              >
                Assignments
              </button>

              <button
                className="pt-1 px-6 pb-1 text-center text-white text-xs sm:text-sm focus:outline-none"
                style={{
                  backgroundColor:
                    selectedTab === "resources"
                      ? commonColor.deepPink
                      : "transparent",
                  borderTopLeftRadius: "1rem",
                  borderTopRightRadius: "1rem",
                }}
                onClick={() => setSelectedTab("resources")}
              >
                Resources
              </button>
            </div>
          )}
        </div>
        <div className="w-full flex-grow">
          <div className="w-full h-80 md:h-96 bg-white lg:h-full rounded-2xl shadow-3xl  overflow-hidden">
            {renderSelectedTab()}
          </div>
        </div>
      </div>
      {/* </div> */}
    </WraperWithTitle>
  );

  const calendarWidget = (fullScreen) => (
    <WraperWithTitle
      title={"My Calendar"}
      screenWidth={width}
      contentClass={"bg-white md:flex-grow"}
    >
      <StudentCalendar
        events={calendarEvents}
        user={user}
        handleReminders={true}
        fullScreen={fullScreen}
        view={"day"}
        views={["day"]}
        lessonSelection={(courseId) => {
          const course = courses.find((c) => c.courseId === courseId);
          if (course) {
            setSelectedCourse(course);
          }
        }}
        calendarClassName="sk-calendar stripe-secondary"
        components={{
          timeSlotWrapper: (props) => (
            <TimeSlot
              // dateFormat="D MMMM YYYY"
              dateClass="hidden sm:block"
              {...props}
            />
          ),
          timeGutterHeader: () => (
            <>
              {/* <div className="sm:hidden block"> */}
              <div className="block">
                <GutterHeader
                  labels={[
                    {
                      title: "Time",
                      // colorStyle: "secondary",
                    },
                  ]}
                />
              </div>
            </>
          ),
          day: {
            header: () => (
              <div className="pl-2">
                <GutterHeader
                  labels={[{ title: "Event", colorStyle: "tertiary" }]}
                />
              </div>
            ),
          },
          toolbar: CustomToolbar,
        }}
      />
    </WraperWithTitle>
  );

  const reminderWidget = () => {
    return (
      <WraperWithTitle
        title={"Reminders"}
        screenWidth={width}
        contentClass={""}
      >
        <Reminders />
      </WraperWithTitle>
    );
  };

  const didYouKnowWidget = () => {
    return (
      <WraperWithTitle
        title={"Did you know?"}
        screenWidth={width}
        contentClass={"bg-white md:flex-grow h-60 lg:h-full"}
      >
        <FactOfTheDay />
      </WraperWithTitle>
    );
  };

  const lessonWidget = (selectedCourse) => {
    return (
      <div className="w-full h-full flex  flex-col rounded-3xl">
        <button
          className="w-full h-full bg-blue-500 focus:outline-none"
          style={{
            backgroundImage: `url(${selectedCourse.coursePhotoUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          onClick={() => {
            console.log("Go to course page", selectedCourse);
            // navigate("courses", {
            //   state: {
            //     courseId: selectedCourse.courseId,
            //   },
            // });
            navigate(`/courses/${selectedCourse.courseId}`);
          }}
        />
        <div className="w-full p-2 flex justify-between">
          <div className="w-1/2 flex flex-col">
            <div
              className="py-1 px-4 text-lg"
              style={{
                color: "black",
              }}
            >
              {selectedCourse.nameShort}
            </div>

            <div
              className="px-4 flex text-sm"
              style={{
                color: "black",
              }}
            >
              Time: {selectedCourse.time}
            </div>
            <div
              className="px-4 flex text-sm"
              style={{
                color: "black",
              }}
            >
              Day: {selectedCourse.day}
            </div>
            <button
              className="px-4 flex text-sm focus:outline-none text-left"
              style={{
                color: "black",
              }}
              onClick={() => {
                console.log("Go to tutor", selectedCourse.tutorFirebaseId);
              }}
            >
              Tutor: {selectedCourse.tutorUsername}
            </button>
          </div>
          <div className="h-full w-1/2 flex flex-col justify-end items-end">
            <div className=" pb-4 pr-4">
              {joinSessionUrl &&
              joinSessionCourseId === selectedCourse.courseId ? (
                <Swotbutton
                  text="Start lesson"
                  onClick={() => goToCourseSession()}
                />
              ) : (
                <Swotbutton
                  text={"No session yet"}
                  onClick={() => {
                    console.log("start course session", selectedCourse);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  // main body:-------------------------------------------------------
  return (
    <div className="w-full p-4 h-full flex flex-row justify-center flex-wrap overflow-y-scroll max-w-3k">
      <div className="w-full lg:h-[calc(100%-30px)] flex flex-col justify-start">
        <div className="w-full lg:h-3/5 flex flex-row justify-center flex-wrap">
          <div className="w-full lg:w-1/2 lg:h-full flex flex-col min-h-80">
            {lessonsWidget()}
          </div>
          <div className="w-full lg:w-1/2 lg:h-full flex flex-col hidden lg:block">
            {calendarWidget()}
          </div>
        </div>
        <div className="w-full lg:h-2/5 flex flex-row justify-center flex-wrap">
          <div className="w-full lg:w-1/2 lg:h-full min-h-40 flex flex-col">
            {reminderWidget()}
          </div>
          <div className="w-full lg:w-1/2 lg:h-full flex flex-col">
            {didYouKnowWidget()}
          </div>
        </div>

        <div className="w-full lg:hidden">{calendarWidget(true)}</div>
      </div>
      <FooterView />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  notifications: state.notifications.notifications,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(StudentDashboard);

// helper widgets:--------------------------------------------------
const WraperWithTitle = ({
  title,
  screenWidth,
  contentClass = "bg-white",
  headerWidget,
  children,
}) => (
  <div className="w-full h-full flex flex-col justify-start p-2 mb-4 lg:mb-0">
    <div className="w-full pb-2 px-2 h-12 flex items-center">
      {headerWidget ? (
        headerWidget
      ) : (
        <div className="text-white pb-2">{title}</div>
      )}
    </div>

    <div
      className={`darker_shadow  w-full overflow-hidden h-fit ${contentClass}`}
      style={{
        ...{ borderRadius: "1.5rem" },
        ...{
          ...(screenWidth >= 1024 ? { height: `calc(100% - 2.5rem)` } : {}),
        },
      }}
    >
      {children}
    </div>
  </div>
);

const NoContentMessage = ({ children }) => (
  <div className="w-full h-full flex justify-center items-center pl-8 pr-16 py-12 overflow-y-scroll">
    {children}
  </div>
);
