import React, { useState, useEffect } from "react";
import Swotbutton from "../../../components/common/buttons/swotbutton";
import {
  PrivacyPolicy,
  TermsCheckbox,
  TermsLink,
} from "../../../components/common/consent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Step4 = ({
  saveProperty,
  currentStep,
  handleSubmit,
  readyCheck,
  isAllDataSet,
  isPolicyVerify,
  submitted,
  missingInfo, // New prop to show missing information
  goToStep, // Function to navigate to a specific step
}) => {
  const [isVerify, setIsVerify] = useState(isPolicyVerify ?? false);
  const [isReady, setIsReady] = useState(isVerify && readyCheck());
  const [shownToasts, setShownToasts] = useState(new Set());

  useEffect(() => {
    console.log("Step4 - useEffect - isReady", isReady);
    setIsReady(isVerify && isAllDataSet && !submitted);
  }, [isVerify, isAllDataSet, submitted]);

  useEffect(() => {

    if (currentStep === 4 && missingInfo.length > 0) {
      // Group errors by step
      const errorsByStep = missingInfo.reduce((acc, info) => {
        if (!acc[info.step]) {
          acc[info.step] = [];
        }
        acc[info.step].push(info.message);
        return acc;
      }, {});

      console.log('Errors by step', errorsByStep);

      // Show a single toast for each step with aggregated errors
      Object.keys(errorsByStep).forEach(step => {
        const errorMessages = "Step" + step + ":<br/>" + errorsByStep[step].join('<br/>');
        toast.error(
          <div dangerouslySetInnerHTML={{ __html: errorMessages }} />, {
          onClick: () => goToStep(parseInt(step, 10)),
        });
        console.log('Showing toast for step', step);
        setShownToasts(prev => new Set(prev).add(step));
      });
    }
  }, [missingInfo, goToStep, currentStep]);

  useEffect(() => {
    // Reset the shownToasts when navigating away from step 4
    if (currentStep !== 4) {
      setShownToasts(new Set());
    }
  }, [currentStep]);

  if (currentStep !== 4) {
    return null;
  }

  return (
    <div className="h-full flex flex-col justify-start lg:px-10 items-center">
      <div className="w-full h-full flex flex-col overflow-y-scroll">
        <div className="w-full text-sm text-center pt-4 mb-4 mt-auto">
          {
            "Thank you for your application, we will review it as soon as possible and are looking forward to working together in innovating education!"
          }
        </div>
        {!submitted && (
          <div className="flex text-xs items-start">
            <span
              style={{
                marginTop: "4px",
              }}
            >
              <TermsCheckbox
                checked={isVerify}
                onChange={(changeEvent) => {
                  saveProperty("isPolicyVerify", !isVerify);
                  setIsVerify(!isVerify);
                }}
              />
            </span>
            <div className="text-sm">
              I verify that all the information submitted is true and that I
              have read the <TermsLink /> and <PrivacyPolicy />.{" "}
            </div>
          </div>
        )}
        <div className="w-full flex justify-end py-4 mb-auto">
          <Swotbutton
            text={"Done"}
            disabled={!isReady}
            onClick={handleSubmit}
            className="py-4 px-6"
          />
        </div>
      </div>
      {/* Toast Container for displaying toasts */}
      <ToastContainer bodyClassName="cursor-pointer" />
    </div>
  );
};

export default Step4;
