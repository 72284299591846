import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getTutorQuiz,
  postTutorQuiz,
  getQuizResults,
} from "../../../services/api";
import commonColor from "../../../theme/commonColor";
import UserActions from "../../../redux/user";
import QuestionStep from "../../student/setup/questionStep";
import Swotbutton from "../../common/buttons/swotbutton";
import Modal from "../../common/modal/modal";

const scrollToTopRef = (ref) => ref.scroll(0, 0);

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...{
        currentStep: 0,
        user: props.user,
        canMoveOn: false,
        obpDone: false,
        answers: {},
        showModal: false,
        results: [],
      },
      ...(props.data ?? {}),
    };
    this.divRef = React.createRef();
  }

  executeScrollToTop = () => {
    if (this.divRef.current) {
      scrollToTopRef(this.divRef.current);
    }
  };

  componentDidMount = () => {
    console.log("Tutor Wizard - componentDidMount - user", this.state.user);
    //call for questions
    const getQuiz = async () => {
      // XUhdWPW9SyZdibLzqsldhfQTyXE2
      const response = await getTutorQuiz(this.props.user.uid);
      //const response = await getTutorQuiz("XUhdWPW9SyZdibLzqsldhfQTyXE2");

      if (response && response.ok) {
        const fetchedQuiz = response.data;
        console.log("Tutor Wizard - useEffect - fetchedQuiz", fetchedQuiz.data);

        //TODO: save in local state
        this.setState({
          quiz: fetchedQuiz.data,
        });
      }
    };

    if (!this.state.user.quizDone && !this.state.quiz) {
      getQuiz();
    }
  };

  componentDidUpdate = () => {
    let data = this.state;
    //delete data.user;
    this.props.updateData && this.props.updateData(data);
  };

  setCanMoveOn = (screen, flag) => {
    this.setState({
      [screen]: flag,
      canMoveOn: flag,
    });
  };

  saveAnswer = (key, value) => {
    console.log("saveAnswer -", key, value);
    let updAnswers = this.state.answers;
    updAnswers[key] = value;

    this.setState({
      answers: updAnswers,
    });
  };

  handleSubmit = async () => {
    console.log("handleSubmit was activated! - answers", this.state.answers);
    // disable the DONE button
    //this.setCanMoveOn("locationInfo", false)
    this.setState({
      obpDone: true,
      showModal: true,
    });

    // create answersArray
    let answersArray = [];
    for (const [key, value] of Object.entries(this.state.answers)) {
      answersArray.push({
        question: key,
        answers: value,
      });
    }

    console.log("handleSubmit - created Array", answersArray);

    // submit api call here
    const result = await postTutorQuiz(this.props.user.uid, {
      quiz: answersArray,
    });
    console.log("handleSubmit - result", result);

    const response = await getQuizResults(this.props.user.uid);
    //const response = await getTutorQuiz("XUhdWPW9SyZdibLzqsldhfQTyXE2");

    if (response && response.ok) {
      const fetchedResults = response.data;
      console.log("Tutor Wizard - fetchedResults", fetchedResults);

      //TODO: save in local state
      this.setState({
        results: fetchedResults.data.slice(0, 4),
      });
    }

    // TODO: remove comments to saveUserToState when test modal is removed!!
    // this.saveUserToState()
  };

  saveUserToState = () => {
    // save quiz status for user
    const updatedUser = { ...this.state.user, quizDone: true };
    this.props.saveUser(updatedUser);
  };

  _next = () => {
    this.executeScrollToTop();
    let { currentStep, quiz } = this.state;
    console.log("Wizard - NEXT - current step", currentStep);

    currentStep =
      quiz && currentStep >= quiz.length ? quiz.length : currentStep + 1;
    this.setState({
      currentStep: currentStep,
      canMoveOn: false,
    });
  };

  _prev = () => {
    this.executeScrollToTop();
    let currentStep = this.state.currentStep;
    console.log("Wizard - Previous - current step", currentStep);

    currentStep = currentStep <= 0 ? 0 : currentStep - 1;
    this.setState({
      currentStep: currentStep,
      canMoveOn: true,
    });
  };

  /*
   * the functions for our button
   */
  previousButton() {
    let { currentStep, quiz } = this.state;

    if (currentStep !== 0) {
      return (
        <div className="w-1/2 flex flex-col justify-center items-start">
          <Swotbutton
            text={"Previous"}
            onClick={this._prev}
            disabled={this.state.obpDone}
          />
        </div>
      );
    }
    return <div className="w-1/2" />;
  }

  nextButton() {
    let { currentStep, quiz } = this.state;

    if (quiz && currentStep < quiz.length - 1) {
      return (
        <div className="w-1/2 flex flex-col justify-center items-end">
          <Swotbutton
            text={"Next"}
            onClick={this._next}
            disabled={!this.state.canMoveOn}
          />
        </div>
      );
    }
    return null;
  }

  doneButton() {
    const { currentStep, quiz } = this.state;

    if (quiz && currentStep === quiz.length - 1) {
      return (
        <div className="w-1/2 flex flex-col justify-center items-end">
          <Swotbutton
            text={"Done"}
            onClick={this.handleSubmit}
            disabled={!this.state.canMoveOn || this.state.obpDone}
          />
        </div>
      );
    }
    return null;
  }

  render() {
    const { quiz, currentStep, obpDone, showModal, results } = this.state;
    const MoreInfoTextTutor = () => {
      return (
        <div>
          <p className="mt-2 mb-1 italic text-sm">
            *For more information on your results, visit your profile.
          </p>
        </div>
      );
    };

    return (
      <React.Fragment>
        <div className="w-full h-full flex flex-col justify-between items-center">
          <Modal
            isOpen={showModal}
            // onAfterOpen={afterOpenModal}
            onRequestClose={() => this.setState({ showModal: false })}
            // contentLabel="Create new event"
            setMaxSize={true}
          >
            <div className="w-full h-full flex flex-col space-y-2 p-2 justify-around">
              <div className="pb-2 text-center font-bold">
                {" "}
                Thank you for doing the quiz, here are the results:
              </div>
              <div
                className="w-2/6 self-center bg-black h-0.5"
                style={{ margin: "1.5rem 0 1rem 0" }}
              ></div>
              {results.length > 0 ? (
                results.map((res) => (
                  <div>
                    {res.type}: {res.percentage.toFixed(0)}%{" "}
                  </div>
                ))
              ) : (
                <div> Calculating...</div>
              )}

              {results.length > 0 && <MoreInfoTextTutor />}
              <Swotbutton
                text={"Done"}
                onClick={() => this.saveUserToState()}
                style={{
                  margin: "2.5rem 0 0 0",
                  width: "50%",
                  alignSelf: "center",
                  padding: ".75rem 0",
                }}
              />
            </div>
          </Modal>

          <div
            className="px-5 w-full h-full flex flex-grow flex-col justify-start overflow-y-scroll"
            // style={{
            //   boxShadow: `0 10px 25px 0 rgba(0, 0, 0, 0.6)`,
            //   borderRadius: 25,
            //   //height: '70%',
            // }}
            ref={this.divRef}
          >
            {!this.state.user.quizDone && !obpDone && (
              <div className="w-full flex  justify-center items-start">
                <div className="text-xl lg:text-xl p-2">
                  Help us find students that match your teaching style.
                </div>
              </div>
            )}

            {!this.state.user.quizDone && !obpDone ? (
              <div className="w-full flex items-center justify-center">
                {quiz ? (
                  quiz.map((q, index) => (
                    <QuestionStep
                      key={q.questionId}
                      index={index}
                      isTeacher={true}
                      question={q}
                      currentStep={currentStep}
                      saveAnswer={this.saveAnswer}
                      setCanMoveOn={this.setCanMoveOn}
                      preSelected={
                        this.state.answers
                          ? this.state.answers[q.questionId]
                          : null
                      }
                    />
                  ))
                ) : (
                  <div> Getting Ready...</div>
                )}
              </div>
            ) : (
              <div className="w-full flex flex-grow items-center justify-center">
                <div className="italic">
                  Thank you for taking the time to answer these questions!
                </div>
              </div>
            )}
          </div>

          {!this.state.user.quizDone && !obpDone && (
            <div className="w-full flex flex-row justify-between bottom-0">
              {this.previousButton()}
              {this.nextButton()}
              {this.doneButton()}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Wizard);
