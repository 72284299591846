import { useEffect, useState } from "react";
import { connect } from "react-redux";
// import { ReactComponent as UpArrow } from "../../assets//icons/up_arrow.svg";
// import { ReactComponent as DownArrow } from "../../assets//icons/down_arrow.svg";

// Delete the below
import Select from "../../components/common/input/select";

import CustomFadeLoader from "../../components/common/CustomFadeLoader";
import MainPageWithMenu from "../../components/common/MainPageTemplate/mainPageWithMenu";
import Swotbutton from "../../components/common/buttons/swotbutton";
import axios from "axios";
import "./styles.css";
import { async } from "@firebase/util";

const TedSearch = ({ user }) => {
  const [searchValue, setSearchValue] = useState([""]);
  const [topicList, setTopicList] = useState([]);
  const [allTedData, setAllTedData] = useState([]);
  const [tedData, setTedData] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const dataLimit = 10;
  const defaultValue = "activism";
  // const [isClicked, setIsClicked] = useState(false);

  const TableInfo = () => {
    return (
      <>
        {strAscending.map(({ title, description, publish_date, url }) => (
          <tr
            key={title}
            className="w-full bg-white border-b"
          >
            <td className="py-4 px-6 text-sm font-medium text-gray-900">
              <a key={url} href={url} target="_blank">
                <p key={title}>{title}</p>
              </a>
            </td>
            <td className="py-4 px-6 text-sm text-gray-500">
              <p key={description}>{description}</p>
            </td>
            <td className="py-4 px-6 text-sm text-gray-500">
              <p key={publish_date}>{publish_date.split("T")[0]}</p>
            </td>
          </tr>
        ))}
      </>
    );
  };


  useEffect(() => {
    getTopicList();
  }, []);


  const getTopicList = async () => {

    let tmpList = [];

    const options = {
      method: 'GET',
      url: 'https://ted-talks-api.p.rapidapi.com/topics',
      headers: {
        'X-RapidAPI-Key': '7f965a4924mshc58beaa4c992045p1115c4jsna75aa05b0a9f',
        'X-RapidAPI-Host': 'ted-talks-api.p.rapidapi.com'
      }
    };

    axios.request(options).then(function (response) {
      let data = response.data.result.results;

      for (var i in data) {
        tmpList.push({
          value: data[i].slug,
          label: data[i].slug.split('_').join(' ')
        })
      }

      tmpList= tmpList.sort((a, b) => a.label.localeCompare(b.label))
      setTopicList(tmpList);

      tedAPI(defaultValue);
      console.log("topics:",tmpList);
    
    }).catch(function (error) {
      console.error(error);
    });
  };

  const tedAPI = async (selectedTopic) => {
    setIsLoading(true);

    /*

    var options = {
      method: "GET",
      url: "https://ted-talk-api.p.rapidapi.com/talks",
      params: { keyword: `${searchValue}`, views: "", likes: "", size: 100 },
      headers: {
        "x-rapidapi-host": "ted-talk-api.p.rapidapi.com",
        "x-rapidapi-key": "ba2a8e8e73msh3e1b997d7be4febp1fb197jsn35ce0a2288b0",
      },
    };
    await axios
      .request(options)
      .then(function (response) {
        setAllTedData(response.data);
        setTedData(response.data.slice(0, dataLimit));
        setIsLoading(false);
      })
      .catch(function (error) {
        console.error(error);
      });
      */

    const options = {
      method: 'GET',
      url: 'https://ted-talks-api.p.rapidapi.com/talks',
      params: {
        from_record_date: '2021-01-01',
        topic: selectedTopic
      },
      headers: {
        'X-RapidAPI-Key': '7f965a4924mshc58beaa4c992045p1115c4jsna75aa05b0a9f',
        'X-RapidAPI-Host': 'ted-talks-api.p.rapidapi.com'
      }
    };

    axios.request(options).then(function (response) {
      console.log("TEDS:", response);
      let data = response.data.result.results;

      console.log("teds:", data);

      setAllTedData(data);
      if (data.length > dataLimit) {
        setTedData(data.slice(0, dataLimit));
      }
      else {
        setTedData(data);
      }
      setIsLoading(false);

    }).catch(function (error) {
      console.error(error);
    });




  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    if (searchValue === "" && tedData.length === 0 && !isLoading) {
      console.log("default Search");
      tedAPI();
    }
  }, [isLoading]);

  const searchTed = async () => {
    await tedAPI();
  };

  // 👇️ sort by String property ASCENDING (A - Z)
  const strAscending = [...tedData].sort((a, b) =>
    a.title > b.title ? 1 : -1
  );

  const LoadMore = async () => {
    if (allTedData.length >= tedData.length) {
      let tempData = tedData;
      let upto = tedData.length + dataLimit;
      if (upto > allTedData.length) upto = allTedData.length;

      tempData = tedData.concat(allTedData.slice(tedData.length, upto));
      setTedData(tempData);
    }
  };

  return (
    <MainPageWithMenu user={user} menuSelected={"ted-search"}>
      <div>{tedData && <span>{tedData.title}</span>}</div>

      <div className="w-full h-full flex flex-col justify-start">
        <div className="w-full h-full flex flex-col justify-start overflow-y-scroll mt-4">
          <div
            className="bg-white p-5 w-full max-w-screen-sm flex flex-col justify-start self-center"
            style={{
              boxShadow: `0 10px 25px 0 rgba(0, 0, 0, 0.6)`,
              borderRadius: 25,
              //height: '70%',
            }}
          >
            <div className="w-full flex flex-col items-center justify-start">
              <div className="w-full flex flex-col justify-around">
                <div className="text-center">Select Topic for a Ted Video</div>
                <div className="flex flex-col justify-start items-center p-2">
                  <div className="w-full flex flex-row justify-center p-1">
                    <div className="w-2/3 flex flex-row justify-start px-2">
                      
                      <Select
                        placeholder={"Select Topic"}
                        className="w-full text-sm"
                        onChange={(value) => {
                          console.log("selected topic:", value);
                          tedAPI(value.value);
                        }}
                        defaultValue= {{value: defaultValue, label: defaultValue}}
                        options={topicList}
                      />


                    </div>
                  </div>
                  <div className="w-full flex justify-end">
                    {/* <Swotbutton
                      className="min-w-24"
                      text={"Search"}
                      onClick={searchTed}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="overflow-x-auto my-8 flex justify-center">
              <div className="inline-block py-2 w-full sm:w-4/5 lg:min-w-8/12 lg:w-8/12 sm:px-6 lg:px-8">
                <div className="overflow-hidden shadow-md sm:rounded-lg">
                  <table className="w-full min-w-full">
                  <colgroup>
                  <col className="w-1/3 sm:w-2/5" />
                  <col className="w-1/3 sm:w-2/5" />
                  <col className="w-1/3 sm:w-1/5" />
                  </colgroup>
                    <thead className="tableHead">
                      <tr className="w-full">
                        <th
                          scope="col"
                          className="font-bold py-3 px-6 text-sm tracking-wider text-left text-white uppercase"
                        >
                          Title
                        </th>
                        <th
                          scope="col"
                          className="font-bold py-3 px-6 text-sm tracking-wider text-left text-white uppercase"
                        >
                          Description
                        </th>

                        <th
                          scope="col"
                          className="font-bold py-3 px-6 text-sm tracking-wider text-left text-white uppercase flex items-center"
                        >
                          <p>Release Date</p>
                          {/* <button
                            onClick={() => setIsClicked((prev) => !prev)}
                          ></button> */}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isLoading ? (
                        <TableInfo />
                      ) : (
                        <>
                          <tr className="w-full bg-white border-b">
                            <td colSpan="3">
                              <CustomFadeLoader loading={true} color={"#000"} />
                            </td>
                          </tr>
                        </>
                      )}
                      {(allTedData.length > tedData.length) &&
                        <tr className="w-full bg-white border-b">
                          <td className=" px-6 text-sm text-gray-500 whitespace-nowrap">
                            Showing {tedData.length} out of {allTedData.length}.
                          </td>

                          <td colSpan="2" className="p-3 justify-right items-right text-right">
                            <Swotbutton
                              className="min-w-24"
                              text={"Load More"}
                              onClick={LoadMore}
                            />
                          </td>
                        </tr>
                      }

                      {(tedData.length === 0) &&
                        <tr className="bg-white border-b">
                          <td colSpan="3" className="p-3 justify-center items-center text-center">
                            No Result Found..
                          </td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainPageWithMenu>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TedSearch);
