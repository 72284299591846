import React, { useEffect, useReducer } from "react";
import { getContentTypeOptions } from "../../../../../services/api";
import Select from "../../../../../components/common/input/select";
import { useWindowSize } from "../../../../../utils/uiUtils";

const CourseStep2 = ({
  user,
  saveProperty,
  currentStep,
  courseDetails,
  mode,
  stateData,
}) => {
  const tutoringRegistrationOpts = [
    { value: "online", label: "Online" },
    // { value: "asynchronous", label: "Asynchronous" },
    // { value: "face-2-face", label: "Face to face" },
  ];

  const tutoringContentTypeOpts = [
    { value: "curriculum", label: "School Subjects" },
    { value: "extra curriculum", label: "Skill Training" },
  ];

  const tutoringPreferenceOpts = [
    { value: "groups", label: "Groups" },
    { value: "individuals", label: "Individuals" },
    { value: "specEdu", label: "Special Education" },
  ];

  // variables:-------------------------------------------------
  // const [localState, dispatchReducer] = useReducer();

  const reducerStateFn = (state, action) => {
    if (action.key) {
      if (action.key === "reset") {
        return action.value;
      }
      if (action.key === "shortName") {
        // isDataValid(false);
      }
      const updatedV = { ...state, ...{ [action.key]: action.value } };
      return updatedV;
    }
  };

  const [width, height] = useWindowSize();

  const [localState, dispatchReducer] = useReducer(reducerStateFn, {});

  // initialize:--------------------------------------------
  useEffect(() => {
    updateLocalData("reset", {
      errorMessage: null,
      tutoringPreference: stateData?.tutoringPreference ?? "group",
      tutoringRegistration: stateData?.tutoringRegistration ?? [],
      tutoringContentType: stateData?.tutoringContentType ?? null,
      subjects: stateData?.subjects ?? [],
      subjectAreas: stateData?.subjectAreas ?? [],
      subjectOptions: stateData?.subjectOptions ?? [],
      subjectAreaOptions: stateData?.subjectAreaOptions ?? [],
      contentTypeMaterials: stateData?.contentTypeMaterials ?? {},
      contentTypeMaterial: stateData?.contentTypeMaterial ?? {},
    });
  }, []);

  //
  // useEffects:--------------------------------------
  useEffect(() => {
    const vd = isDataValid(false);
    saveProperty("step2Completed", vd);
  }, [localState]);

  useEffect(() => {
    getContentTypeData();
  }, [localState?.tutoringContentType]);

  useEffect(() => {
    console.log(`useEffect 2 - changed subjects:`, localState?.subjects);
    displaySubjectAreas(localState?.subjects);
  }, [localState?.subjects]);

  //
  // helper functions:------------------------------------------------
  const handleChange = (key, value) => {
    saveProperty(key, value);
    updateLocalData(key, value);
  };

  const updateLocalData = (key, value) => {
    dispatchReducer({ key, value });
  };

  //
  //Data functions:--------------------------------------------------
  const getContentTypeData = async () => {
    const { tutoringContentType, contentTypeMaterials } = localState;
    console.log(`useEffect 1 - tutoringContentType:`, tutoringContentType);
    if (tutoringContentType?.value) {
      if (contentTypeMaterials[tutoringContentType.value]) {
        handleChange(
          "contentTypeMaterial",
          contentTypeMaterials[tutoringContentType.value]
        );
        displaySubjects(contentTypeMaterials[tutoringContentType.value]);
        return;
      }

      const data = await fetchDataForContentType(tutoringContentType);
      handleChange("contentTypeMaterial", data ? data : {});
      // setContentTypeMaterial(data ? data : {});
      displaySubjects(data ? data : {});
      displaySubjectAreas(localState?.subjects, data);
      if ((data && tutoringContentType?.value) || data?.type) {
        handleChange("contentTypeMaterials", {
          ...contentTypeMaterials,
          ...{ [tutoringContentType?.value || data.type]: data },
        });
      }
    }
  };

  const fetchDataForContentType = async (contentType) => {
    if (!contentType) {
      return null;
    }
    // Call BE for content subjets, areas
    let response = await getContentTypeOptions(
      contentType ? contentType.value === "curriculum" : true
    );
    // save to local state
    console.log("getDataForContentType - response", response);
    if (response && response.ok) {
      return response.data;
    } else {
      return null;
    }
  };

  const displaySubjectAreas = (subjects, contentData) => {
    if (!subjects) {
      return;
    }
    let contentTypeMaterial =
      contentData ?? localState?.contentTypeMaterial ?? [];
    let chosenSubjectAreaOptions = [];
    let contentSubjects = contentTypeMaterial.subjects;
    if (contentSubjects) {
      subjects.forEach((sub) => {
        const subject = contentSubjects[sub.value];
        console.log("displaySubjectAreas - selected subject:", sub, subject);
        if (subject) {
          let areaOptions = contentSubjects[sub.value].areas.map((area) => {
            return { value: area, label: area };
          });
          let subjectAreas = {
            label: sub.value,
            options: areaOptions,
          };
          chosenSubjectAreaOptions.push(subjectAreas);
        }
      });
    }
    handleChange("subjectAreaOptions", chosenSubjectAreaOptions);
  };

  const displaySubjects = (data) => {
    let subjectOptions = [];
    let chosenSubjectAreaOptions = [];
    if (!data.subjects) {
      return;
    }
    for (const [key, value] of Object.entries(data.subjects)) {
      const subjectAreaOptions = value.areas.map((area) => {
        return { value: area, label: area };
      });
      subjectOptions.push({ value: key, label: key });
      let subjectAreas = {
        label: key,
        options: subjectAreaOptions,
      };
      chosenSubjectAreaOptions.push(subjectAreas);
    }
    handleChange("subjectOptions", subjectOptions);
    handleChange("subjectAreaOptions", chosenSubjectAreaOptions);
  };

  //
  //---------------------------------------------------------------------------------
  const isDataValid = (showMessage = true) => {
    const { tutoringRegistration, subjects, subjectAreas, tutoringPreference } =
      localState;

    if (!tutoringRegistration?.value) {
      if (showMessage) {
        updateLocalData("errorMessage", "Course conduct type can't be empty");
      }
      return false;
    }
    if (!(subjectAreas ?? [])[0]?.value) {
      if (showMessage) {
        updateLocalData("errorMessage", "Subject Area can't be empty");
      }
      return false;
    }
    if (!tutoringPreference?.value) {
      if (showMessage) {
        updateLocalData("errorMessage", "Audience type can't be empty");
      }
      return false;
    }
    return true;
  };

  if (currentStep !== 2) {
    return null;
  }

  // widgets:--------------------------------------------------
  const errorWidgets = () => {
    return (
      <div className="w-full flex flex-col justify-start">
        {localState?.errorMessage && (
          <div className="text-sm bg-red-500 w-full text-white text-center mb-1">
            {localState?.errorMessage}
          </div>
        )}
      </div>
    );
  };
  //
  // main body:------------------------------------------------
  return (
    <div className="h-full flex flex-col justify-start">
      {errorWidgets()}
      <div
        className={`w-full h-full flex flex-col ${
          width < 1024 ? "overflow-y-scroll" : ""
        }`}
      >
        <div className="w-full mt-auto">
          <RowWidget title={"Course type"} isRequired={true}>
            <Select
              className="w-full"
              defaultValue={tutoringRegistrationOpts[0]}
              placeholder={"Select"}
              value={
                localState?.tutoringRegistration
                  ? localState?.tutoringRegistration
                  : tutoringRegistrationOpts[0]
              }
              onChange={(value) => {
                handleChange("tutoringRegistration", value);
              }}
              options={tutoringRegistrationOpts}
            />
          </RowWidget>
        </div>
        <RowWidget title={"Content"} isRequired={true}>
          <Select
            className="w-full"
            placeholder={"Select"}
            value={localState?.tutoringContentType}
            onChange={(value) => {
              console.log("selected subjects:", value);
              handleChange("tutoringContentType", value);
              handleChange("subjects", []);
              handleChange("subjectAreas", []);
            }}
            options={tutoringContentTypeOpts}
          />
        </RowWidget>

        <RowWidget title={"Subjects"} isRequired={true}>
          <Select
            className="w-full"
            placeholder={"Select"}
            value={localState.subjects}
            onChange={(value) => {
              console.log("selected subjects:", value);
              handleChange("subjects", value ? [value] : []);
              handleChange("subjectAreas", []);
            }}
            options={localState.subjectOptions}
          />
        </RowWidget>

        <RowWidget title={"Subject Area"} isRequired={true}>
          <Select
            className="w-full"
            placeholder={"Select"}
            value={localState.subjectAreas}
            options={localState.subjectAreaOptions}
            menuPlacement={`${width < 1024 ? "top" : "auto"}`}
            isFormatGroupLabelUsed
            onChange={(value) => {
              console.log("selected subject Areas:", value);
              handleChange("subjectAreas", value ? [value] : []);
            }}
          />
        </RowWidget>

        <div className="w-full mb-auto">
          <RowWidget title={"Audience type"} isRequired={true}>
            <Select
              className="w-full"
              placeholder={"Select"}
              value={localState.tutoringPreference}
              menuPlacement={`${width < 1024 ? "top" : "auto"}`}
              onChange={(value) => {
                handleChange("tutoringPreference", value);
              }}
              options={tutoringPreferenceOpts}
            />
          </RowWidget>
        </div>
      </div>
    </div>
  );
};

export default CourseStep2;

const RowWidget = ({ title, children, isRequired }) => {
  return (
    <div className="w-full flex flex-col md:flex-row justify-center py-2">
      <div className="text-gray-500 font-semibold text-sm md:w-32 w-full flex flex-row justify-start md:justify-end items-center px-2">
      {title} {isRequired && <sup>*</sup>}
      </div>
      <div className="lg:w-2/3 flex-grow flex flex-row justify-start px-2 py-1">
        {children}
      </div>
    </div>
  );
};
