import React, { useEffect, useState } from "react";
import { getAllTutors, setTutorApprovalStatus } from "../../services/api";
import TutorListItem from "./tutorListItem";
import commonColor from "../../theme/commonColor";
import FooterView from "../common/footerView";

const AdminDashboard = () => {
  const [tutorList, setTutorList] = useState([]);
  const [expandedTutor, setExpandedTutor] = useState();

  useEffect(() => {
    getTutors();
  }, []);

  const getTutors = async () => {
    const response = await getAllTutors();
    if (response && response.ok) {
      const fetchedTutors = response.data;
      console.log("AdminDashboard - useEffect - getTutors", fetchedTutors);
      const filteredTutors = fetchedTutors.filter((t) => t.approved === null || t.approved === undefined);
      setTutorList(filteredTutors);
    }
  };

  return (
    <div
      className="w-full rounded-2xl p-4 h-full
    flex flex-col "
    >
      <div className="flex justify-between items-middle">
        <div className="text-white">Admin Dashboard</div>
        <button
          className="border-white p-4 border-2 text-white rounded-2xl focus:outline-none"
          onClick={() => {
            getTutors();
          }}
        >
          Refresh
        </button>
      </div>

      <div className="p-4 flex flex-col flex-grow">
        <div className="text-white">Tutors pending approval</div>

        <div className="">
          {tutorList.map((tutor, index) => (
            <TutorListItem key={tutor.firebaseId ?? index} tutor={tutor} expandedTutorId={expandedTutor} setExpandedTutor={setExpandedTutor}/>
          ))}
        </div>
      </div>
      <FooterView />
    </div>
  );
};

export default AdminDashboard;
