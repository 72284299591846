import React, { useState } from "react";
import { ReactComponent as Parents } from "../../../assets/icons/family.svg";
import { ReactComponent as Student } from "../../../assets/icons/reading-book.svg";
import { ReactComponent as Teacher } from "../../../assets/icons/teacher.svg";
import { ReactComponent as SwotBuddy } from "../../../assets/icons/005-atom.svg";

import commonColor from "../../../theme/commonColor";

export default function UserType(props) {
  const [accountType, setAccountType] = useState();

  const saveAccountType = (type) => {
    setAccountType(type);
    props.saveProperty("accountType", type);
    props.setCanMoveOn("userType", true);
  };

  if (props.currentStep !== 1) {
    return null;
  }

  return (
    <div className="flex flex-col justify-around w-full h-full  lg:pb-2">
      <div className="flex justify-center w-full">
        <p className="text-center">What type of user are you?</p>
      </div>
      <div className="flex flex-wrap w-full">
         <div className="flex justify-center w-1/2 md:w-1/3">
          <button
            className="focus:outline-none"
            type="button"
            onClick={() => saveAccountType("SwotBuddy")}
          >
            <div className="flex flex-col p-2 align-middle">
              <div
                className="flex content-center self-center justify-center w-24 h-24 p-2 rounded-full shadow-lg lg:h-24 lg:w-24 hover:bg-gray-200"
                style={{
                  backgroundColor:
                    accountType === "SwotBuddy"
                      ? commonColor.sourCherry
                      : "white",
                }}
              >
                <Student
                  className="self-center "
                  style={{
                    fill:
                      accountType === "SwotBuddy"
                        ? "white"
                        : commonColor.deepGreen,
                    backgroundColor:
                      accountType === "SwotBuddy"
                        ? commonColor.sourCherry
                        : "white",
                    height: "3rem",
                    width: "3rem",
                    aspectRatio: 1,
                  }}
                />
              </div>
              <p className="p-1 text-center ">Student</p>
            </div>
          </button>
        </div> 

        <div className="flex justify-center w-1/2 md:w-1/3">
          <button
            className="focus:outline-none"
            type="button"
            onClick={() => saveAccountType("Tutor")}
          >
            <div className="flex flex-col p-2 align-middle">
              <div
                className="flex content-center self-center justify-center w-24 h-24 p-2 rounded-full shadow-lg lg:h-24 lg:w-24 hover:bg-gray-200"
                style={{
                  backgroundColor:
                    accountType === "Tutor" ? commonColor.sourCherry : "white",
                }}
              >
                <Teacher
                  className="self-center "
                  style={{
                    fill:
                      accountType === "Tutor" ? "white" : commonColor.deepGreen,
                    backgroundColor:
                      accountType === "Tutor"
                        ? commonColor.sourCherry
                        : "white",
                    height: "3rem",
                    width: "3rem",
                    aspectRatio: 1,
                  }}
                />
              </div>
              <p className="p-1 text-center ">Tutor</p>
            </div>
          </button>
        </div>
        <div className="flex justify-center w-1/2 md:w-1/3">
          <button
            className="focus:outline-none"
            type="button"
            onClick={() => saveAccountType("Parent")}
          >
            <div className="flex flex-col p-2 align-middle">
              <div
                className="flex content-center self-center justify-center w-24 h-24 p-2 rounded-full shadow-lg lg:h-24 lg:w-24 hover:bg-gray-200"
                style={{
                  backgroundColor:
                    accountType === "Parent" ? commonColor.sourCherry : "white",
                }}
              >
                <Parents
                  className="self-center "
                  style={{
                    fill:
                      accountType === "Parent"
                        ? "white"
                        : commonColor.deepGreen,
                    backgroundColor:
                      accountType === "Parent"
                        ? commonColor.sourCherry
                        : "white",
                    height: "3rem",
                    width: "3rem",
                    aspectRatio: 1,
                  }}
                />
              </div>
              <p className="p-1 text-center ">Parent</p>
            </div>
          </button>
        </div>
        {/* <div className="flex justify-center w-1/2 md:w-1/3">
          <button
            className="focus:outline-none"
            type="button"
            onClick={() => saveAccountType("SwotBuddy")}
          >
            <div className="flex flex-col p-2 align-middle">
              <div
                className="flex content-center self-center justify-center w-24 h-24 p-2 rounded-full shadow-lg lg:h-24 lg:w-24 hover:bg-gray-200"
                style={{
                  backgroundColor:
                    accountType === "SwotBuddy"
                      ? commonColor.sourCherry
                      : "white",
                }}
              >
                <SwotBuddy
                  className="self-center "
                  style={{
                    fill:
                      accountType === "SwotBuddy"
                        ? "white"
                        : commonColor.deepGreen,
                    backgroundColor:
                      accountType === "SwotBuddy"
                        ? commonColor.sourCherry
                        : "white",
                    height: "3rem",
                    width: "3rem",
                    aspectRatio: 1,
                  }}

                />
              </div>
              <p className="p-1 text-center ">Swotbuddy</p>
            </div>
          </button>
        </div> */}
      </div>
    </div>
  );
}
