import { toast } from "react-toastify";
import { stripeConnect, getStripeStatus, courseCheckout, getSubscriptionStatus } from "./api";

const subscriptionStatusMessages = {
  none: {
    type: toast.TYPE.ERROR,
    msg: "You are not yet subscribed",
  },
  pending: {
    type: toast.TYPE.INFO,
    msg: "Your subscribed is pending",
  },
  inactive: {
    type: toast.TYPE.WARNING,
    msg: "Some information is missing in your subscription account. Please update to make subscription active.",
  },
  incomplete: {
    type: toast.TYPE.INFO,
    msg: "Your subscription is pending.",
  },
  approved: {
    type: toast.TYPE.SUCCESS,
    msg: "you are subscribed",
  },
};

export const subscriptionStatusCheck = (status, trial, trial_remaining, subscription_remaining) => {
  if (trial && trial_remaining > 0) {
    return { type: toast.TYPE.INFO, msg: `You are on trial period which will expire in ${trial_remaining} days.` }
  } else if (status === false) {
    return { type: toast.TYPE.INFO, msg: "You are not yet subscribed" };
  } else if (status === true && subscription_remaining < 5) {
    return { type: toast.TYPE.SUCCESS, msg: `You are subscribed. Your subscription will expire in ${subscription_remaining} days.` };
  } else {
    return { type: toast.TYPE.INFO, msg: null };
  }
};

export const getUserSubscriptionStatus = async (firebaseId, userType) => {
  // console.log("getUserSubscriptionStatus", firebaseId, userType)
  return await getSubscriptionStatus(firebaseId, userType);
};



