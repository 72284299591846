import React, { useEffect } from 'react'
import { connect } from "react-redux";
import { firestore } from "../config/firebase";
import { collection, doc, onSnapshot } from 'firebase/firestore';
import NotificationsActions from "../redux/notifications";

const CourseContainer = (props) => {

    const userNotificationsRef = 
        doc(collection(firestore, "course-notifications"), props.courseId);

    useEffect(() => {
        console.log("courseContainer - subscribing for notifications... for course", props.courseId);
        let unsubscribeNotifications = onSnapshot(userNotificationsRef,
            (snapshot) => {
                const fetchedNotifications = snapshot.data();
                const updatedNotifications = { ...props.notifications, ...fetchedNotifications }
                console.log("courseContainer - updatedNotifications", updatedNotifications);
                props.saveNotifications(updatedNotifications);
            }
        );
        return () => {
            console.log("courseContainer - unsubscribing from notifications.");
            unsubscribeNotifications();
        };
    }, [props?.courseId]);

    return (
        <div className=" h-0 w-full flex">
            {props.children}
        </div>
    )

}

const mapStateToProps = (state) => ({
    notifications: state.notifications.notifications,
});

const mapDispatchToProps = (dispatch) => ({
    saveNotifications: (notifications) =>
        dispatch(NotificationsActions.saveNotificationsRequest(notifications)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseContainer);

