// blogs
import { useNavigate } from "react-router-dom";

import NewBlogDesign from "../components/newBlogDesign";

const PartTen = ({ showPopup }) => {
  const navigate = useNavigate();
  return (
    <div
      id="blogs"
      className="w-full h-full pt-20 py-40 flex flex-wrap justify-around px-8 md:px-20 2k:px-32 max-w-screen-2k"
    >
      <div className="h-fit mb-14 text-center">
        <p className="text-3xl text-gray-600 mb-2 ">Enlighten</p>
        <p className="my-8 max-w-[85ch]">
          <span className="text-[#9B3C66] font-semibold">
            {" "}
            Enlighten your mind with insightful articles.{" "}
          </span>
          Enlighten your mind with insightful articles, trend analyses, and
          educational strategies on our blog. Stay informed of the latest in
          smart learning innovation, designed for the inspired mind.
        </p>
        <a
          className="text-md text-gray-400 hover:text-gray-800 transition-all ease-in"
          href="/blogs"
          onClick={() => {
            navigate("/blogs", {
              state: "landingpage",
            });
          }}
        >
          See all blogs
        </a>
      </div>
      <NewBlogDesign showPopup={showPopup} />
    </div>
  );
};

export default PartTen;
