import apisauce from "apisauce";
import moment from "moment";
import * as CONSTANTS from "../config/apiConstants";
import { auth } from "../config/firebase";
import { getIdToken } from 'firebase/auth';
const api = apisauce.create({
  baseURL: CONSTANTS.baseURL,
});

const ambassaderApi = apisauce.create({
  baseURL: CONSTANTS.baseURL,
});

console.log(`api is ${CONSTANTS.baseURL}`);
const setHeaders = async () => {

  api.setHeader("Access-Control-Allow-Origin");
  if (auth && auth.currentUser) {
    const idToken = await getIdToken(auth.currentUser, /* forceRefresh */ true);
    api.setHeader("Authorization", "Bearer " + idToken);
    return idToken;
  } else {
    return null;
  }
};

const setAmbassaferHeaders = async () => {
  ambassaderApi.setHeader("Access-Control-Allow-Origin");
  if (localStorage.getItem("token")) {
    const idToken = localStorage.getItem("token");
    ambassaderApi.setHeader("Content-Type", "application/json");
    ambassaderApi.setHeader("Authorization", "Bearer " + idToken);
    return idToken;
  } else {
    return null;
  }
};

const getHeadersConfig = () => {
  return {
    ...api.headers,
    Accept: "application/json",
    "Content-Type": "application/json",
  };
};

export const saveUserOnBoardingAccount = async (userInfo) => {
  /**
  firebaseId *
  email *
  firstName *
  lastName *
  accountType * - student,tutor, parent, swotboddy
  gender *
  username *
  photoUrl *
  birthDate *
  stateId - optional
  cityId - optional
  schoolInfo - optional
   */

  console.log("postUserOnBoardingAccount - userInfo", userInfo);
  try {
    //const birthdate = moment(userInfo.birthday, 'DD-MM-YYYY').format()
    // const lastName = userInfo.name
    //   ? userInfo.name.slice(userInfo.name.indexOf(" "))
    //   : "";
    // const firstName = userInfo.name
    //   ? userInfo.name.slice(0, userInfo.name.indexOf(" "))
    //   : "";

    let tempName = userInfo.name.split(" ");

    let firstName = tempName[0]
    let lastName = tempName[1] || "";


    const payload = {
      accountType: userInfo.accountType,
      username: userInfo.username,
      name: userInfo.name,
      firstName: firstName,
      lastName: lastName,
      gender: userInfo.gender,
      birthDate: userInfo.birthday,
      photoUrl: userInfo.photoUrl,
      cloudStoragePhotoLocation: userInfo.cloudStoragePhotoLocation,
      email: userInfo.email,
      firebaseId: userInfo.uid,
      status: userInfo.status,
      dateActivated: moment().format(),
      stateId: userInfo.stateId,
      cityId: userInfo.cityId,
      cityName: userInfo.cityName,
      stateCode: userInfo.stateCode,
      countryName: userInfo.countryName,
      schoolInfo: userInfo.schoolInfo,
      affilateCode: userInfo.affilateCode
      // prefferedLanguage: userInfo.prefferedLanguage
    };

    await setHeaders();
    console.log("postUserOnBoardingAccount - payload", payload);

    return api.post(CONSTANTS.saveUserAccount, payload, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in saveUserOnBoardingAccount API call", e);
    //throw new Error('Error in saveUserOnBoardingAccount API call')
  }
};

export const resendVerificationEmail = async (email) => {
  await setHeaders();
  try {
    return api.post(
      CONSTANTS.resendVerificationEmail(),
      { email: email },
      {
        headers: getHeadersConfig(),
      }
    );
  } catch (e) {
    console.log("Error in getContentTypeOptions API", e);
  }
};

export const getContentTypeOptions = async (isCurriculum) => {
  try {
    await setHeaders();
    return api.get(
      CONSTANTS.getContentTypeOptions(isCurriculum),
      getHeadersConfig()
    );
  } catch (e) {
    console.log("Error in getContentTypeOptions API", e);
    //throw new Error('Error in getContentTypeOptions API')
  }
};

export const setupTutor = async (payload, userId) => {
  try {
    await setHeaders();
    return api.post(CONSTANTS.setupTutor(userId), payload, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in setupTutor API call", e);
    //throw new Error('Error in setupTutor API call')
  }
};


export const swotknotBuddy = async (payload) => {
  try {
    await setHeaders();
    return api.post(CONSTANTS.swotknotBuddy(), payload, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in setupTutor API call", e);
    //throw new Error('Error in setupTutor API call')
  }
};

export const deleteAccountRequest = async (firebaseId) => {
  try {
    await setHeaders();
    return api.post(CONSTANTS.deleteAccountRequest(firebaseId), {}, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in setupTutor API call", e);
    //throw new Error('Error in setupTutor API call')
  }
};

export const deleteUserRequest = async (firebaseId) => {
  try {
    await setHeaders();
    return api.delete(CONSTANTS.deleteUser(firebaseId), {}, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in deleteUserRequest API call", e);
    //throw new Error('Error in setupTutor API call')
  }
};


export const moodleRegisterUser = async () => { };

export const moodleSSOLogin = async (email) => {
  try {
    await setHeaders();
    //console.log('moodleSSOLogin API - input', email)
    return api.get(CONSTANTS.moodleSSOLogin(email), getHeadersConfig());
  } catch (e) {
    console.log("Error in moodleSSOLogin API", e);
    //throw new Error('Error in moodleSSOLogin API')
  }
};

export const topupWallet = async (payload, userId) => {
  try {
    // const idToken = await auth
    //   .currentUser
    //   .getIdToken(/* forceRefresh */ true)
    console.log("topupWallet - payload/userId", payload, userId);
    // api.setHeader('Authorization', 'Bearer ' + idToken)
    // api.setHeader('Access-Control-Allow-Origin')
    await setHeaders();
    return api.post(CONSTANTS.topupWallet(userId), payload, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in topupWallet API call", e);
    //throw new Error('Error in topupWallet API call')
  }
};

export const createStripeAccount = async (userId) => {
  try {
    await setHeaders();
    //console.log('createStripeAccount API - input', isCurriculum)
    return api.get(CONSTANTS.createStripeAccount(userId), getHeadersConfig());
  } catch (e) {
    console.log("Error in getContentTypeOptions API", e);
    //throw new Error('Error in createStripeAccount API')
  }
};


export const getStripeCountriesList = async () => {
  try {
    await setHeaders();
    //console.log('createStripeAccount API - input', isCurriculum)
    return api.get(CONSTANTS.getStripeCountriesList(), getHeadersConfig());
  } catch (e) {
    console.log("Error in getStripeCountriesList API", e);
    //throw new Error('Error in createStripeAccount API')
  }
};


export const confirmWalletTopUp = async (payload, userId) => {
  try {
    // const idToken = await auth
    //   .currentUser
    //   .getIdToken(/* forceRefresh */ true)
    console.log("confirmWalletTopUp - payload/userId", payload, userId);
    // api.setHeader('Authorization', 'Bearer ' + idToken)
    // api.setHeader('Access-Control-Allow-Origin')

    await setHeaders();
    return api.post(CONSTANTS.confirmWalletTopUp(userId), payload, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in confirmWalletTopUp API call", e);
    //throw new Error('Error in confirmWalletTopUp API call')
  }
};

export const getTutorPreferences = async (userId) => {
  try {
    await setHeaders();
    console.log("getTutorPreferences API - input", userId);
    return api.get(CONSTANTS.tutorPreferences(userId), getHeadersConfig());
  } catch (e) {
    console.log("Error in getTutorPreferences API", e);
    //throw new Error('Error in getTutorPreferences API')
  }
};


export const createBulkSlots = async (payload) => {
  try {
    console.log("createBulkSlots API - payload/userId", payload);
    await setHeaders();
    return api.post(CONSTANTS.createTutorBulkSlots(), payload, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in createBulkSlots API call", e);
    //throw new Error('Error in createCourse API call')
  }
};


/**
 *
 * for free courses add in payload: "type": "free"
 */
export const createCourse = async (payload, userId) => {
  try {
    // const idToken = await auth
    //   .currentUser
    //   .getIdToken(/* forceRefresh */ true)
    console.log("createCourse API - payload/userId", payload, userId);
    // api.setHeader('Authorization', 'Bearer ' + idToken)
    // api.setHeader('Access-Control-Allow-Origin')

    await setHeaders();
    return api.post(CONSTANTS.createCourse(userId), payload, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in createCourse API call", e);
    //throw new Error('Error in createCourse API call')
  }
};

/**
 * edit a course
 */
export const editCourse = async (payload, courseId) => {
  try {
    // const idToken = await auth
    //   .currentUser
    //   .getIdToken(/* forceRefresh */ true)
    console.log("editCourse API - payload/courseId", payload, courseId);
    // api.setHeader('Authorization', 'Bearer ' + idToken)
    // api.setHeader('Access-Control-Allow-Origin')

    await setHeaders();
    return api.post(CONSTANTS.editCourse(courseId), payload, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in editCourse API call", e);
    //throw new Error('Error in editCourse API call')
  }
};

export const getCourseExists = async (nameShort, userId) => {
  try {
    await setHeaders();
    return api.post(
      CONSTANTS.getCourseExists(userId),
      { nameShort },
      getHeadersConfig()
    );
  } catch (e) {
    console.log("Error in getCourseExists API", e);
    //throw new Error('Error in getCourseList API')
  }
};

export const getCourseList = async () => {
  try {
    await setHeaders();
    //console.log('getCourseList API - input', )
    return api.get(CONSTANTS.getAllCourses, getHeadersConfig());
  } catch (e) {
    console.log("Error in getCourseList API", e);
    //throw new Error('Error in getCourseList API')
  }
};

export const getCourseListOfTutor = async (userId) => {
  try {
    await setHeaders();
    console.log("getCourseListOfTutor API - input", userId);
    return api.get(CONSTANTS.getCourseListOfTutor(userId), getHeadersConfig());
  } catch (e) {
    console.log("Error in getCourseListOfTutor API", e);
    //throw new Error('Error in getCourseListOfTutor API')
  }
};

export const getCourseDetails = async (courseId) => {
  try {
    await setHeaders();
    console.log("getCourseDetails API - input", courseId);
    return api.get(CONSTANTS.getCourseDetails(courseId), getHeadersConfig());
  } catch (e) {
    console.log("Error in getCourseDetails API", e);
    //throw new Error('Error in getCourseDetails API')
  }
};

export const getCourseContents = async (courseId) => {
  try {
    await setHeaders();
    return api.get(CONSTANTS.getAllCurseContent(courseId), getHeadersConfig());
  } catch (e) {
    console.log("Error in getCourseListOfTutor API", e);
    //throw new Error('Error in getCourseListOfTutor API')
  }
};


export const getStudentRecording = async (courseId, studentId) => {
  try {
    await setHeaders();
    return api.get(CONSTANTS.getStudentRecording(courseId, studentId), getHeadersConfig());
  } catch (e) {
    console.log("Error in getCourseListOfTutor API", e);
    //throw new Error('Error in getCourseListOfTutor API')
  }
};

export const getTutorRecording = async (courseId) => {
  try {
    await setHeaders();
    return api.get(CONSTANTS.getTutorRecording(courseId), getHeadersConfig());
  } catch (e) {
    console.log("Error in getCourseListOfTutor API", e);
    //throw new Error('Error in getCourseListOfTutor API')
  }
};

export const addCourseContent = async (payload) => {
  try {
    await setHeaders();
    return api.post(CONSTANTS.courseContent(), payload, {
      ...api.headers,
      Accept: "application/json",
      "Content-Type": "application/json",
    });
  } catch (e) {
    console.log("Error in getCourseListOfTutor API", e);
    //throw new Error('Error in getCourseListOfTutor API')
  }
};

export const updateCourseContent = async (contentId, payload) => {
  try {
    await setHeaders();
    return api.put(CONSTANTS.courseContentById(contentId), payload, {
      ...api.headers,
      Accept: "application/json",
      "Content-Type": "application/json",
    });
  } catch (e) {
    console.log("Error in getCourseListOfTutor API", e);
    //throw new Error('Error in getCourseListOfTutor API')
  }
};

export const deleteCourseContent = async (contentId) => {
  try {
    await setHeaders();
    return api.delete(CONSTANTS.courseContentById(contentId), {
      ...api.headers,
      Accept: "application/json",
      "Content-Type": "application/json",
    });
  } catch (e) {
    console.log("Error in getCourseListOfTutor API", e);
    //throw new Error('Error in getCourseListOfTutor API')
  }
};

// Delete Course Button
export const deleteCourse = async (courseId) => {
  try {
    await setHeaders();
    return api.delete(CONSTANTS.deleteCourse(courseId), {
      ...api.headers,
      Accept: "application/json",
      "Content-Type": "application/json",
    });
  } catch (e) {
    console.log("Error in getCourseListOfTutor API", e);
    //throw new Error('Error in getCourseListOfTutor API')
  }
};
export const createTutorAvailabilitySlots = async (payload, userId) => {
  try {
    console.log(
      "createTutorAvailabilitySlots API - payload/userId",
      payload,
      userId
    );
    await setHeaders();
    return api.post(CONSTANTS.createTutorAvailabilitySlots(userId), payload, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in createTutorAvailabilitySlots API call", e);
    //throw new Error('Error in createTutorAvailabilitySlots API call')
  }
};

export const renameTutorAvailabilitySlots = async (payload, userId) => {
  try {
    console.log(
      "renameTutorAvailabilitySlots API - payload/userId",
      payload,
      userId
    );
    await setHeaders();
    return api.post(CONSTANTS.renameTutorAvailabilitySlots(userId), payload, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in renameTutorAvailabilitySlots API call", e);
    //throw new Error('Error in createTutorAvailabilitySlots API call')
  }
};


export const getTutorAvailabilitySlots = async (userId) => {
  try {
    await setHeaders();
    console.log("getTutorAvailabilitySlots API - input", userId);
    return api.get(CONSTANTS.getTutorAvailabilitySlots(userId), {
      ...api.headers,
      Accept: "application/json",
      "Content-Type": "application/json",
    });
  } catch (e) {
    console.log("Error in getTutorAvailabilitySlots API", e);
    //throw new Error('Error in getTutorAvailabilitySlots API')
  }
};

export const getSessionDetailsTutor = async (sessionId) => {
  try {
    await setHeaders();
    console.log("getSessionDetailsTutor API - input", sessionId);
    return api.get(CONSTANTS.getSessionDetailsTutor(sessionId), {
      ...api.headers,
      Accept: "application/json",
      "Content-Type": "application/json",
    });
  } catch (e) {
    console.log("Error in getSessionDetailsTutor API", e);
    //throw new Error('Error in getSessionDetailsTutor API')
  }
};

export const getStudentsForTutor = async (userId) => {
  try {
    await setHeaders();
    console.log("getStudentsForTutor API - input", userId);
    return api.get(CONSTANTS.getStudentsForTutor(userId), {
      ...api.headers,
      Accept: "application/json",
      "Content-Type": "application/json",
    });
  } catch (e) {
    console.log("Error in getStudentsForTutor API", e);
    //throw new Error('Error in getStudentsForTutor API')
  }
};

export const setNgoUserStatus = async (tutorId, payload) => {
  try {
    console.log(
      "setNgoUser API - payload/userId",
      payload,
      tutorId
    );

    await setHeaders();
    return api.post(CONSTANTS.setNgoUser(tutorId), payload, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in setNgoUser API call", e);
    //throw new Error('Error in setTutorApprovalStatus API call')
  }
};

export const setTutorApprovalStatus = async (tutorId, payload) => {
  try {
    console.log(
      "setTutorApprovalStatus API - payload/userId",
      payload,
      tutorId
    );

    await setHeaders();
    return api.post(CONSTANTS.setTutorApprovalStatus(tutorId), payload, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in setTutorApprovalStatus API call", e);
    //throw new Error('Error in setTutorApprovalStatus API call')
  }
};

export const getAllUsers = async (limit = 20, page = 1) => {
  try {
    await setHeaders();

    const queryParams = `?limit=${limit}&page=${page}`;
    const url = `${CONSTANTS.getAllUsers}${queryParams}`;

    return api.get(url, getHeadersConfig());
  } catch (e) {
    console.log("Error in getAllUsers API", e);
    //throw new Error('Error in getAllTutors API')
  }
};

export const searchUsers = async (searchString) => {
  try {
    await setHeaders();

    return api.get(CONSTANTS.searchUsers(searchString), getHeadersConfig());
  } catch (e) {
    console.log("Error in getTestUsers API", e);
    //throw new Error('Error in getAllTutors API')
  }
};

export const getAllTutors = async () => {
  try {
    await setHeaders();

    return api.get(CONSTANTS.getAllTutors, getHeadersConfig());
  } catch (e) {
    console.log("Error in getAllTutors API", e);
    //throw new Error('Error in getAllTutors API')
  }
};

export const getStudentQuiz = async (userId) => {
  try {
    await setHeaders();
    console.log("getStudentQuiz API - input", userId);
    return api.get(CONSTANTS.getStudentQuiz(userId), getHeadersConfig());
  } catch (e) {
    console.log("Error in getStudentQuiz API", e);
    //throw new Error('Error in getStudentQuiz API')
  }
};

export const postStudentQuiz = async (studentId, payload) => {
  try {
    console.log(
      "setTutorApprovalStatus API - payload/userId",
      payload,
      studentId
    );

    await setHeaders();
    return api.post(CONSTANTS.postStudentQuiz(studentId), payload, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in postStudentQuiz API call", e);
    //throw new Error('Error in postStudentQuiz API call')
  }
};

export const getTutorQuiz = async (userId) => {
  try {
    await setHeaders();
    console.log("getTutorQuiz API - input", userId);
    return api.get(CONSTANTS.getTutorQuiz(userId), getHeadersConfig());
  } catch (e) {
    console.log("Error in getTutorQuiz API", e);
    //throw new Error('Error in getTutorQuiz API')
  }
};

export const postTutorQuiz = async (tutorId, payload) => {
  try {
    console.log("postTutorQuiz API - payload/userId", payload, tutorId);

    await setHeaders();
    return api.post(CONSTANTS.postTutorQuiz(tutorId), payload, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in postTutorQuiz API call", e);
    //throw new Error('Error in postTutorQuiz API call')
  }
};

export const getQuizResults = async (userId) => {
  try {
    await setHeaders();
    console.log("getQuizResults API - input", userId);
    return api.get(CONSTANTS.getQuizResults(userId), getHeadersConfig());
  } catch (e) {
    console.log("Error in getQuizResults API", e);
    //throw new Error('Error in getQuizResults API')
  }
};

export const getFeaturedTutors = async () => {
  try {
    await setHeaders();
    return api.get(CONSTANTS.getFeaturedTutors(), {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in findTutors API call", e);
  }
};

export const findTutors = async (userId, payload) => {
  try {
    console.log("findTutors API - payload/userId", payload, userId);
    await setHeaders();
    return api.post(CONSTANTS.findTutors(userId), payload, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in findTutors API call", e);
  }
};

export const searchTutorsApi = async (keyword) => {
  try {
    console.log("searchTutors API - keyword", keyword);
    await setHeaders();
    return api.get(CONSTANTS.searchTutors(keyword), {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in searchTutors API call", e);
  }
};

export const getOpenCoursesForStudent = async (userId) => {
  try {
    await setHeaders();
    console.log("getOpenCoursesForStudent API - input", userId);
    return api.get(CONSTANTS.getOpenCoursesForStudent(userId), getHeadersConfig());
  } catch (e) {
    console.log("Error in getOpenCoursesForStudent API", e);
  }
};

export const getTutorAbout = async (userId) => {
  try {
    await setHeaders();
    console.log("getTutorAbout API - input", userId);
    return api.get(CONSTANTS.getTutorAbout(userId), getHeadersConfig());
  } catch (e) {
    console.log("Error in getTutorAbout API", e);
  }
};

export const getTutorCoursesWithLatestSession = async (userId) => {
  try {
    await setHeaders();
    console.log("getTutorCoursesWithLatestSession API - input", userId);
    return api.get(CONSTANTS.getTutorCoursesWithLatestSession(userId), getHeadersConfig());
  } catch (e) {
    console.log("Error in getTutorCoursesWithLatestSession API", e);
  }
};

export const getStudentEnrolledCourses = async (userId) => {
  try {
    await setHeaders();
    console.log("getStudentEnrolledCourses API - input", userId);
    return api.get(CONSTANTS.getStudentEnrolledCourses(userId), getHeadersConfig());
  } catch (e) {
    console.log("Error in getStudentEnrolledCourses API", e);
  }
};

export const bookCourseSession = async (studentId, payload) => {
  try {
    console.log("bookCourseSession API - payload/userId", payload, studentId);
    await setHeaders();
    return api.post(CONSTANTS.bookCourseSession(studentId), payload, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in bookCourseSession API call", e);
  }
};

export const getCourseStudentCalendarView = async (courseId, studentId) => {
  try {
    await setHeaders();
    console.log(
      "getCourseStudentCalendarView API - input",
      courseId,
      studentId
    );
    return api.get(
      studentId ? CONSTANTS.getCourseStudentCalendarView(courseId, studentId) :
        CONSTANTS.getCourseCalendarView(courseId),

      getHeadersConfig()
    );
  } catch (e) {
    console.log("Error in getCourseStudentCalendarView API", e);
    //throw new Error('Error in getCourseStudentCalendarView API')
  }
};

/**
 * 
 * @response {{
    "firstName": "teacher_0",
    "lastName": "9",
    "username": "teacher_09",
    "email": "sk_teacher_09@yopmail.com",
    "password": "undefined",
    "cityName": "Austin",
    "stateCode": "TX",
    "country": "USA"
}} tutorId 
 */
export const getTutorPersonalInfo = async (tutorId) => {
  try {
    await setHeaders();
    console.log("getTutorPersonalInfo API - input", tutorId);
    return api.get(CONSTANTS.getTutorPersonalInfo(tutorId), getHeadersConfig());
  } catch (e) {
    console.log("Error in getTutorPersonalInfo API", e);
    //throw new Error('Error in getTutorPersonalInfo API')
  }
};

/**
 * 
 * @response {{
    "tutoring": "online",
    "preference": "group",
    "specialEducation": false,
    "subjects": [
        {
            "subject": "Foreign Language",
            "area": "Ancient Greek",
            "tutoringCurriculum": "curriculum"
        },
        {
            "subject": "Foreign Language",
            "area": "Arabic",
            "tutoringCurriculum": "curriculum"
        },
        {
            "subject": "Foreign Language",
            "area": "Chinese",
            "tutoringCurriculum": "curriculum"
        }
    ]
}} tutorId 
 */
export const getTutoringInfo = async (tutorId) => {
  try {
    await setHeaders();
    console.log("getTutoringInfo API - input", tutorId);
    return api.get(CONSTANTS.getTutoringInfo(tutorId), getHeadersConfig());
  } catch (e) {
    console.log("Error in getTutoringInfo API", e);
    //throw new Error('Error in getTutoringInfo API')
  }
};

/**
 * 
 * @response {
    "resumeUrl": "https://firebasestorage.googleapis.com/v0/b/swotknot-dev.appspot.com/o/userData%2FyGWu5pMSgdPElKg88T76IG32myY2%2Fprofile%2Fresume.docx?alt=media&token=785047cf-68b9-4e23-90ac-2883d6603878",
    "introduceVideoUrl": "https://firebasestorage.googleapis.com/v0/b/swotknot-dev.appspot.com/o/userData%2FyGWu5pMSgdPElKg88T76IG32myY2%2Fprofile%2Fresume.docx?alt=media&token=785047cf-68b9-4e23-90ac-2883d6603878",
    "photoUrl": "https://firebasestorage.googleapis.com/v0/b/swotknot-dev.appspot.com/o/userData%2FyGWu5pMSgdPElKg88T76IG32myY2%2Fprofile%2Fprofile_photo.jpg?alt=media&token=ef595058-7442-40c7-bb2e-02d53a04be15"
}
 */
export const getTutorProfileDocuments = async (tutorId) => {
  try {
    await setHeaders();
    console.log("getTutorProfileDocuments API - input", tutorId);
    return api.get(CONSTANTS.getTutorProfileDocuments(tutorId), getHeadersConfig());
  } catch (e) {
    console.log("Error in getTutorProfileDocuments API", e);
    //throw new Error('Error in getTutorProfileDocuments API')
  }
};

/**
 * 
 * @response [
    {
        "type": "Loner",
        "percentage": 100
    },
  ]
 */
export const getTutorTeachingPercentages = async (tutorId) => {
  try {
    await setHeaders();
    console.log("getTutorTeachingPercentages API - input", tutorId);
    return api.get(CONSTANTS.getTutorTeachingPercentages(tutorId), getHeadersConfig());
  } catch (e) {
    console.log("Error in getTutorTeachingPercentages API", e);
    //throw new Error('Error in getTutorTeachingPercentages API')
  }
};

/**
 * 
 * @response {
    "firstName": "teacher_0",
    "lastName": "papadopoulos",
    "username": "teacher_09",
    "email": "sk_teacher_09@yopmail.com",
    "password": "pass123",
    "cityId": 538,
    "stateId": 1
}
 */
export const updateTutorPersonalInfo = async (tutorId, payload) => {
  try {
    console.log(
      "updateTutorPersonalInfo API - payload/userId",
      payload,
      tutorId
    );
    await setHeaders();
    return api.post(CONSTANTS.updateTutorPersonalInfo(tutorId), payload, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in updateTutorPersonalInfo API call", e);
    //throw new Error('Error in updateTutorPersonalInfo API call')
  }
};

/**
 * 
 * @response {
  "tutoringRegistration":"online",
  "tutoringPreference":"individual",
  "subjectAreas": ["American literature", "British literature"],
  "specialEducation": true
}
 */
export const updateTutorTutoringInfo = async (tutorId, payload) => {
  try {
    console.log(
      "updateTutorTutoringInfo API - payload/userId",
      payload,
      tutorId
    );
    await setHeaders();
    return api.post(CONSTANTS.updateTutorTutoringInfo(tutorId), payload, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in updateTutorTutoringInfo API call", e);
    //throw new Error('Error in updateTutorTutoringInfo API call')
  }
};

export const updateTutorProfileDocuments = async (tutorId, payload) => {
  try {
    console.log(
      "updateTutorProfileDocuments API - payload/userId",
      payload,
      tutorId
    );
    await setHeaders();
    return api.post(CONSTANTS.updateTutorProfileDocuments(tutorId), payload, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in updateTutorProfileDocuments API call", e);
    //throw new Error('Error in updateTutorProfileDocuments API call')
  }
};

export const getAllStates = async () => {
  try {
    await setHeaders();
    //console.log('getAllStates API - input', )
    return api.get(CONSTANTS.getAllStates, getHeadersConfig());
  } catch (e) {
    console.log("Error in getAllStates API", e);
    //throw new Error('Error in getAllStates API')
  }
};

export const getCitiesForState = async (state) => {
  try {
    await setHeaders();
    console.log("getCitiesForState API - input", state);
    return api.get(CONSTANTS.getCitiesForState(state), getHeadersConfig());
  } catch (e) {
    console.log("Error in getCitiesForState API", e);
    //throw new Error('Error in getCitiesForState API')
  }
};

export const getUserSettings = async (userId) => {
  try {
    await setHeaders();
    console.log("getUserSettings API - input", userId);
    return api.get(CONSTANTS.getUserSettings(userId), getHeadersConfig());
  } catch (e) {
    console.log("Error in getUserSettings API", e);
    //throw new Error('Error in getUserSettings API')
  }
};

/**
 * 
 * {
    "allowDesktopNotifications": true,
    "privateProfile": false,
    "allowEmailContact": true
}
 */
export const updateUserSettings = async (userId, payload) => {
  try {
    console.log("updateUserSettings API - payload/userId", payload, userId);
    await setHeaders();
    return api.post(CONSTANTS.updateUserSettings(userId), payload, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in updateUserSettings API call", e);
    //throw new Error('Error in updateUserSettings API call')
  }
};

/**
 * 
 * @returns {
    "courses": [
        {
            "courseId": 106,
            "nameFull": "ada test course",
            "nameShort": "ada test course",
            "subjectArea": "Animation",
            "photoUrl": "ajajkajajajajaj",
            "tutorUsername": "Coco",
            "tutorFirebaseId": "wLC2A01O3pdL5GZHuTTgiQN8c062",
            "type": "free"
        }
    ]
}
 */
export const getAllFreeCourses = async () => {
  try {
    await setHeaders();
    //console.log('getAllFreeCourses API - input', )
    return api.get(CONSTANTS.getAllFreeCourses, getHeadersConfig());
  } catch (e) {
    console.log("Error in getAllFreeCourses API", e);
    //throw new Error('Error in getAllFreeCourses API')
  }
};

/**
 * 
 * payload: {
  "sessionId": 19
}
 */

export const createRoomForSession = async (payload, userId) => {
  try {
    // Add logging for debugging
    console.log("createRoomForSession API - payload/userId", payload, userId);

    // Set headers for API request
    await setHeaders();

    // Make API call
    const response = await api.post(CONSTANTS.createRoomForSession(userId), payload, {
      headers: getHeadersConfig(),
    });
    console.log("response create room", response);
    return response
  } catch (error) {
    // Log the error for debugging
    console.error("Error in createRoomForSession API call:", error);

    // Throw a custom error or return an error object
    throw new Error('Error in createRoomForSession API call', error);
  }
};

/**
 * 
 * @param {*} userId 
 * @param {*} sessionId 
 * @returns eg. {
    "url": "https://bbb.swotknot.com/bigbluebutton/api/join?fullName=test01&meetingID=1&password=secret&checksum=e7a4ac66fe477191e86a587e5677d11315b9f116"
}
 */
export const studentJoinVideoSession = async (userId, sessionId) => {
  try {
    await setHeaders();
    console.log("studentJoinVideoSession API - input", userId, sessionId);
    return api.get(CONSTANTS.studentJoinVideoSession(userId, sessionId), getHeadersConfig());
  } catch (e) {
    console.log("Error in studentJoinVideoSession API", e);
    //throw new Error('Error in studentJoinVideoSession API')
  }
};

export const tutorJoinVideoSession = async (sessionId) => {
  try {
    await setHeaders();
    console.log("tutorJoinVideoSession API - input", sessionId);
    return api.get(
      CONSTANTS.tutorJoinVideoSession(sessionId),
      getHeadersConfig()
    );
  } catch (e) {
    console.log("Error in tutorJoinVideoSession API", e);
    //throw new Error('Error in tutorJoinVideoSession API')
  }
};

/**
 * {

  "courseId": 7,
  "bookEvent": 	{
      "title": "Java Webinar",
            "start": 1607562000,
            "end": 1607565600,
            "type": "webinar"
    }
}
 */

export const createWebinar = async (payload) => {
  try {
    // const idToken = await auth
    //   .currentUser
    //   .getIdToken(/* forceRefresh */ true)
    console.log("createWebinar API - payload", payload);
    // api.setHeader('Authorization', 'Bearer ' + idToken)
    // api.setHeader('Access-Control-Allow-Origin')

    await setHeaders();
    return api.post(CONSTANTS.createWebinar, payload, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in createWebinar API call", e);
    //throw new Error('Error in createWebinar API call')
  }
};

export const deleteWebinar = async (payload) => {
  try {
    // const idToken = await auth
    //   .currentUser
    //   .getIdToken(/* forceRefresh */ true)
    console.log("deleteWebinar API - payload", payload);
    // api.setHeader('Authorization', 'Bearer ' + idToken)
    // api.setHeader('Access-Control-Allow-Origin')

    await setHeaders();
    return api.post(CONSTANTS.deleteWebinar, payload, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in deleteWebinar API call", e);
    //throw new Error('Error in createWebinar API call')
  }
};

/**
example payload:

{
  "sessionId": 19
}
example response:

{
    "studentLink": "https://bbb.swotknot.com/bigbluebutton/api/join?fullName=participant&meetingID=1&password=supersecret&checksum=a9cb74190497448522fe8e64610e6ffba377a7fd"
} 
 */
export const enrollJoinWebinar = async (userId, payload) => {
  try {
    // const idToken = await auth
    //   .currentUser
    //   .getIdToken(/* forceRefresh */ true)
    console.log("enrollJoinWebinar API - payload", payload);
    // api.setHeader('Authorization', 'Bearer ' + idToken)
    // api.setHeader('Access-Control-Allow-Origin')

    await setHeaders();
    return api.post(CONSTANTS.enrollJoinWebinar(userId), payload, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in enrollJoinWebinar API call", e);
    //throw new Error('Error in enrollJoinWebinar API call')
  }
};

export const getSessionEnrolledDetails = async (sessionId) => {
  try {
    await setHeaders();
    console.log("getSessionEnrolledDetails API - input", sessionId);
    return api.get(
      CONSTANTS.getSessionEnrolledDetails(sessionId),
      getHeadersConfig()
    );
  } catch (e) {
    console.log("Error in getSessionEnrolledDetails API", e);
    //throw new Error('Error in getSessionEnrolledDetails API')
  }
};

export const postCourseMaterial = async (
  courseId,
  title,
  type,
  materialUrl
) => {
  try {
    const currentUser = auth.currentUser;
    await setHeaders();
    return api.post(
      `/action/tutor/${currentUser.uid}/courses/${courseId}/material`,
      {
        title,
        type,
        materialUrl,
      },
      getHeadersConfig()
    );
  } catch (e) {
    console.log("Error in getSessionEnrolledDetails API", e);
    throw new Error("Error in getSessionEnrolledDetails API");
  }
};

export const updateStudentProfileDocuments = async (tutorId, payload) => {
  try {
    console.log(
      "updateStudentProfileDocuments API - payload/userId",
      payload,
      tutorId
    );
    await setHeaders();
    return api.post(CONSTANTS.updateStudentDocuments(tutorId), payload, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in updateStudentProfileDocuments API call", e);
    //throw new Error('Error in updateStudentProfileDocuments API call')
  }
};

export const updateQuizResult = async (userId, payload) => {
  try {
    console.log("updateQuizResult API - payload/userId", payload, userId);
    await setHeaders();
    return api.post(CONSTANTS.updateQuizResult(userId), payload, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in updateQuizResult API call", e);
    //throw new Error('Error in updateStudentProfileDocuments API call')
  }
};

export const stripeConnect = async (firebaseId, country) => {
  try {
    console.log("stripeConnect API call");
    const baseUrl = window.location.origin;

    await setHeaders();
    return api.post(
      CONSTANTS.stripeConnect,
      {
        firebase_id: firebaseId,
        refresh_url: baseUrl + "/profile?stripe=failed",
        return_url: baseUrl + "/profile?stripe=success",
        country: country
        // refresh_url: baseUrl + "/stripeCreatedFailure",
        // return_url: baseUrl + "/stripeCreatedSuccess",
      },
      {
        headers: getHeadersConfig(),
      }
    );
  } catch (e) {
    console.log("Error in stripe connect API call", e);
  }
};

export const getSubscriptionStatus = async (firebaseId, userType) => {
  try {
    const baseUrl = window.location.origin;

    await setHeaders();
    return api.post(CONSTANTS.getSubscriptionStatus(firebaseId, userType),
      {
        firebase_id: firebaseId,
        cancel_url: baseUrl + "/profile?stripe=failed",
        success_url: baseUrl + "/profile?stripe=success",
        // refresh_url: baseUrl + "/stripeCreatedFailure",
        // return_url: baseUrl + "/stripeCreatedSuccess",
      }, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in getSubscriptionStatus API call", e);
  }
};


export const getStripeStatus = async (firebaseId) => {
  try {
    console.log("getStripeStatus API call", firebaseId);
    await setHeaders();
    return api.get(CONSTANTS.getStripeStatus(firebaseId), {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in getStripeStatus API call", e);
  }
};

export const getTutorPaymentTransactions = async (firebaseId) => {
  try {
    console.log("getTutorPaymentTransactions API call");
    await setHeaders();
    return api.get(CONSTANTS.getTutorPaymentTransactions(firebaseId), {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in getTutorPaymentTransactions API call", e);
  }
};

export const getStudentPaymentTransactions = async (firebaseId) => {
  try {
    console.log("getStudentPaymentTransactions API call");
    await setHeaders();
    return api.get(CONSTANTS.getStudentPaymentTransactions(firebaseId), {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in getStudentPaymentTransactions API call", e);
  }
};

export const requestRefund = async (paymentId, reason) => {
  try {
    console.log("requestRefund API call");
    await setHeaders();
    return api.post(
      CONSTANTS.requestRefund(paymentId),
      { reason: reason },
      {
        headers: getHeadersConfig(),
      }
    );
  } catch (e) {
    console.log("Error in requestRefund API call", e);
  }
};

export const getRefundRequests = async () => {
  try {
    console.log("getRefundRequests API call");
    await setHeaders();
    return api.get(CONSTANTS.getRefundRequests(), {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in requestRefund API call", e);
  }
};

export const approveRefund = async (transactionId) => {
  try {
    console.log("approveRefund API call");
    await setHeaders();
    return api.post(CONSTANTS.postApproveRefund(transactionId), {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in approveRefund API call", e);
  }
};

export const rejectRefund = async (transactionId, reason) => {
  try {
    console.log("rejectRefund API call");
    await setHeaders();
    return api.post(
      CONSTANTS.postRejectRefund(transactionId),
      { reason: reason },
      {
        headers: getHeadersConfig(),
      }
    );
  } catch (e) {
    console.log("Error in rejectRefund API call", e);
  }
};

export const getTutorEarningReport = async (firebaseId) => {
  try {
    console.log("getTutorEarningReport API call");
    await setHeaders();
    return api.get(CONSTANTS.getTutorEarningReport(firebaseId), {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in getTutorEarningReport API call", e);
  }
};

export const getStudentPaymentReport = async (firebaseId) => {
  try {
    console.log("getStudentPaymentReport API call");
    await setHeaders();
    return api.get(CONSTANTS.getStudentPaymentReport(firebaseId), {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in getStudentPaymentReport API call", e);
  }
};

export const getCoursePaymentDetails = async (courseId) => {
  try {
    console.log("getCoursePaymentDetails API call");
    await setHeaders();
    return api.get(CONSTANTS.getCoursePaymentDetails(courseId), {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in getCoursePaymentDetails API call", e);
  }
};

export const coursePaymentIntent = async (courseId, studentId) => {
  try {
    console.log("coursePaymentIntent API call");
    await setHeaders();
    return api.post(
      CONSTANTS.coursePaymentIntent(courseId, studentId),
      {},
      {
        headers: getHeadersConfig(),
      }
    );
  } catch (e) {
    console.log("Error in coursePaymentIntent API call", e);
  }
};

export const courseCheckout = async (courseId, studentId, payload) => {
  try {
    console.log("coursePaymentIntent API call");
    await setHeaders();
    return api.post(CONSTANTS.courseCheckout(courseId, studentId), payload, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in coursePaymentIntent API call", e);
  }
};

export const tutorCallRequest = async (payload) => {
  try {
    console.log("tutor Call Request API call");
    await setHeaders();
    return api.post(CONSTANTS.tutorCallRequest(), payload, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in tutor Call Request API call", e);
  }
};

//-----Beta Live-------//
export const signupBeta = async (payload) => {
  try {
    console.log("coursePaymentIntent API call");
    // await setHeaders();
    return api.post(CONSTANTS.createBetaTester(), payload, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  } catch (e) {
    console.log("Error in coursePaymentIntent API call", e);
  }
};


export const getBetatester = async () => {
  try {
    console.log("getBetatester API call");
    await setHeaders();
    return api.get(CONSTANTS.getBetaTester(), {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in getBetatester API call", e);
  }
};


export const ambassadorGetAllWithRef = async () => {
  try {
    console.log("ambassadorGetAllWithRef API call");
    await setHeaders();
    return api.get(CONSTANTS.ambassadorGetAllWithRef(), {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in ambassadorGetAllWithRef API call", e);
  }
};


//-----------H5P------------------------//
export const getH5pContentByTutor = async (tutorId) => {
  try {
    console.log("getH5pContentByTutor API call");
    await setHeaders();
    return api.get(CONSTANTS.getH5pContentByTutor(tutorId), {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in getH5pContentByTutor API call", e);
  }
};

export const getH5pContentByCourse = async (courseId) => {
  try {
    console.log("getH5pContentByCourse API call");
    await setHeaders();
    return api.get(CONSTANTS.getH5pContentByCourse(courseId), {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in getH5pContentByCourse API call", e);
  }
};

export const getH5pContentById = async (contentId) => {
  try {
    console.log("getH5pContentById API call");
    await setHeaders();
    return api.get(CONSTANTS.getH5pContentById(contentId), {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in getH5pContentById API call", e);
  }
};

export const assignH5pToCourse = async (contentId, courseId) => {
  try {
    const data = {
      "content_id": contentId,
      "course_id": courseId
    }
    console.log("assignH5pToCourse API call");
    await setHeaders();
    return api.post(CONSTANTS.asignH5pToCourse(),
      data, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in assignH5pToCourse API call", e);
  }
};

export const removeH5pFromCourse = async (itemId) => {
  try {
    console.log("removeH5pFromCourse API call");
    await setHeaders();
    return api.delete(CONSTANTS.removeH5pContentFromcourse(itemId),
      {
        headers: getHeadersConfig(),
      });
  } catch (e) {
    console.log("Error in removeH5pFromCourse API call", e);
  }
};

export const deleteH5pContent = async (contentId) => {
  try {
    console.log("deleteH5pContent API call");
    await setHeaders();
    return api.delete(CONSTANTS.deleteH5pContent(contentId),
      {
        headers: getHeadersConfig(),
      });
  } catch (e) {
    console.log("Error in deleteH5pContent API call", e);
  }
};

export const createH5pContent = async (tutorFirebaseId, contentId, contentTitle, libraryName) => {
  try {
    const data = {
      content_id: contentId,
      title: contentTitle,
      library_name: libraryName
    }
    console.log("createH5pContent API call");
    await setHeaders();
    return api.post(CONSTANTS.createH5pContent(tutorFirebaseId),
      data,
      {
        headers: getHeadersConfig(),
      });
  } catch (e) {
    console.log("Error in createH5pContent API call", e);
  }
};


export const updateH5pContent = async (contentId, title) => {
  try {
    const data = {
      title: title
    }
    console.log("updateH5pContent API call");
    await setHeaders();
    return api.put(CONSTANTS.updateH5pContent(contentId),
      data,
      {
        headers: getHeadersConfig(),
      });
  } catch (e) {
    console.log("Error in updateH5pContent API call", e);
  }
};

export const updateH5pToCourse = async (contentId, courseId) => {
  try {
    const data = {
      "content_id": contentId,
      "course_id": courseId
    }
    console.log("updateH5pToCourse API call");
    await setHeaders();
    return api.put(CONSTANTS.updateH5pCourse(),
      data, {
      headers: getHeadersConfig(),
    });
  } catch (e) {
    console.log("Error in updateH5pToCourse API call", e);
  }
};


export const ambassadorLogin = async (payload) => {
  try {
    console.log("ambassadorLogin", payload);

    return ambassaderApi.post(CONSTANTS.ambassadorLogin(), payload);
  } catch (e) {
    console.log("Error in ambassadorLogin API call", e);
    //throw new Error('Error in updateTutorPersonalInfo API call')
  }
};

export const addAambassador = async (payload) => {
  try {
    console.log("addAambassador", payload);

    await setAmbassaferHeaders();

    return ambassaderApi.post(CONSTANTS.ambassadorAdd(), payload);
  } catch (e) {
    console.log("Error in addAambassador API call", e);
    //throw new Error('Error in updateTutorPersonalInfo API call')
  }
};

export const buyCoupons = async (payload) => {
  try {
    console.log("buyCoupons", payload);

    await setAmbassaferHeaders();

    return ambassaderApi.post(CONSTANTS.buyCoupons(), payload);
  } catch (e) {
    console.log("Error in addAambassador API call", e);
    //throw new Error('Error in updateTutorPersonalInfo API call')
  }
};

export const ambassadorGetAll = async () => {
  try {
    console.log("ambassadorGetAll");

    await setAmbassaferHeaders();

    return ambassaderApi.get(CONSTANTS.ambassadorGetAll());
  } catch (e) {
    console.log("Error in ambassadorLogin API call", e);
    //throw new Error('Error in updateTutorPersonalInfo API call')
  }
};

export const ambassadorCoupons = async () => {
  try {
    console.log("ambassadorCoupons");

    await setAmbassaferHeaders();

    return ambassaderApi.get(CONSTANTS.ambassadorCoupons());
  } catch (e) {
    console.log("Error in ambassadorLogin API call", e);
    //throw new Error('Error in updateTutorPersonalInfo API call')
  }
};


export const ambassadorGetAffilate = async () => {
  try {
    console.log("ambassadorGetAffilate");

    await setAmbassaferHeaders();

    return ambassaderApi.get(CONSTANTS.ambassadorGetAffilate());
  } catch (e) {
    console.log("Error in ambassadorLogin API call", e);
    //throw new Error('Error in updateTutorPersonalInfo API call')
  }
};

