import React from "react";
import commonColor from "../../../theme/commonColor";
import { ReactComponent as Edit } from "../../../assets/icons/editing.svg";

const EditButton = ({
  color,
  onclick,
}) => {
  return (
    <button
      className="focus:outline-none focus:shadow-lg"
      onClick={() => {
        onclick && onclick()
      }}
    >
      <Edit
        style={{
          width: "1.5rem",
          height: "1.5rem",
          overflow: "hidden",
          fill: color ?? commonColor.deepPink,
        }}
      />
    </button>
  );
};

export default EditButton;
