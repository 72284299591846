import React from "react";
import commonColor from "../../theme/commonColor";
import { IChatService } from "../services/chatService";
import { ChatType, IChatGroup, MessageType } from "../models/chat_models";
import { toTitleCase } from "../../utils/generalUtils";
import GenericProfilePhoto from "../../assets/images/tutor_base.jpg";
import { ReactComponent as ImageIcon } from "../../assets/images/image-icon.svg";
import UserModel from "../../redux/redux-models/reduxUser";
import moment from "moment";
import ChatUserModel from "../models/chatUser";

export default class ChatListEntryComponent extends React.Component<{
  chatService: IChatService;
  data: IChatGroup;
  user: UserModel;
  activeChatId?: string;
  onClick: () => void;
  //   generateDownloadLink: (contentId: string) => string;
}> {
  constructor(props: {
    chatService: IChatService;
    data: IChatGroup;
    user: UserModel;
    activeChatId?: string;
    onClick: () => void;
    // manageCourse: (contentId: string) => void;
  }) {
    super(props);  
    this.state = {
      loading: true
    };
  }

  public state: {
    loading: boolean;
  };

  public render(): React.ReactNode {
    const { data, user } = this.props;
    return (
      <div key={data.id}>
        <div
          className="w-full cursor-pointer relative"
          onClick={this.props.onClick}
        >
          <div
            className="w-full flex flex-row justify-start items-start pb-4"
            style={{
              backgroundColor: `${
                this.props.activeChatId === data.id
                  ? "rgba(64, 114, 136, 0.3)"
                  : "white"
              }`,
              borderBottomStyle: "solid",
              borderBottomColor: "rgba(64, 114, 136, 0.3)",
              borderBottomWidth: "1px",
            }}
          >
            <div className="min-w-10 w-10 h-10 m-2 relative">
              <img
                className="w-auto rounded-full w-full h-full absolute"
                style={{ height: "100%", objectFit: "cover" }}
                src={
                  data.type == ChatType.PRIVATE &&
                  data.otherUser?.photoUrl
                    ? data.otherUser!.photoUrl
                    : GenericProfilePhoto
                }
                alt="userPhoto"
              />
              {data.recentMessage &&
                data.recentMessage.sentBy !== user.uid &&
                !data.seen && (
                  <div className="w-full flex justify-end absolute">
                    <div
                      className="w-2 h-2 rounded-full"
                      style={{ backgroundColor: commonColor.deepPink }}
                    ></div>
                  </div>
                )}
            </div>
            <div className="w-full flex flex-col pt-2 justify-start items-start">
              <span className="text-sm lg:text-md w-full font-bold break-all">
                {data.type == ChatType.PRIVATE ? (
                  <>
                    {toTitleCase(data.otherUser?.firstName ?? "")}
                    {toTitleCase(data.otherUser?.lastName ?? "")}
                  </>
                ) : (
                  <>{data.title ?? "Group Chat"}</>
                )}
              </span>
              <span className="text-xs lg:text-sm w-full">
                {moment
                  .unix(data.recentMessage?.sentAt ?? data.createdAt)
                  .format("MM/DD/YYYY hh:mm a")}
              </span>
              {data.recentMessage &&
              data.recentMessage.type === MessageType.IMAGE ? (
                <ImageIcon
                  className="contain rounded-xl h-10 w-10 p-2"
                  style={{
                    width: "auto",
                    overflow: "hidden",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <span className="text-sm w-full break-all">
                  {data.recentMessage?.messageText}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
