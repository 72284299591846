import Slider from "react-slick";
import rightArrow from "../assets/right-arrow.svg";
import leftArrow from "../assets/back.svg";

export default function SimpleSlider({ tutorImage }) {
  function CustomNextArrow(props) {
    const { onClick } = props;
    return (
      <button
        className="rounded-full bg-gray-50 shadow-2xl drop-shadow-lg place-items-center w-10 h-10 p-3 absolute z-10 | bottom-2 right-[35%]"
        onClick={onClick}
      >
        <img src={rightArrow} alt="Next" />
      </button>
    );
  }

  function CustomPrevArrow(props) {
    const { onClick } = props;
    return (
      <button
        className="rounded-full bg-gray-50 shadow-xl drop-shadow-lg place-items-center w-10 h-10 p-3 absolute z-10 | bottom-2 right-[50%]"
        onClick={onClick}
      >
        <img src={leftArrow} alt="Prev" />
      </button>
    );
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  const slides = [
    {
      text: "\"l3arn has been my personal superhero in my final high school year. It's like having a personal assistant that understands my learning habits, helping me maximize my study efficiency. Plus, I found the perfect tutor for AP Physics. I've seen a significant improvement in my grades and, more importantly, I feel less stressed!\"",
      name: "- Alexandra",
    },
    {
      text: "\"My daughter has always struggled with math, which was worrisome for me. Since we've started using l3arn, I found a tutor that fits her learning style perfectly. The integrated dashboard allows me to monitor her progress. The improvement I've seen in her grades and confidence is nothing short of amazing. Thank you, l3arn!\"",
      name: "- Foteini",
    },
    {
      text: '"I\'ve been tutoring English and French for a while, but never had a consistent student base. l3arn changed that. It helped me connect with students that match my teaching style. Plus, the platform handles all the administrative tasks, leaving me more time to focus on teaching. I love seeing my students grow!"',
      name: "- Claudia",
    },
    {
      text: "\"I was a bit hesitant when I first signed up, but now I can't imagine my life without it. I help students understand complex concepts, clarify doubts, and guide them with personalized study strategies. Seeing students succeed and knowing that I'm a part of their journey is the most rewarding feeling!\"",
      name: "- Harry",
    },
    {
      text: '"I decided to return to college after a 10-year break. Balancing work, family, and studies seemed impossible until I discovered l3arn. The AI-powered study support and the ability to find tutors that match my learning style has been a game-changer. I feel more confident and prepared for my exams than ever before."',
      name: "- Jennifer",
    },
    {
      text: '"As a tech enthusiast, the UI/UX of l3arn blew me away. The design is intuitive, making it easy for me to navigate the system and find exactly what I\'m looking for. It was straightforward to find a tutor, schedule a session, and even keep track of my progress."',
      name: "- Chris",
    },
    {
      text: "\"I'm not the most tech-savvy person, so I was initially apprehensive about using an online learning platform for my son. However, l3arn's UI/UX is so user-friendly that even I could figure it out! The features are clearly marked, and the process to find a tutor and schedule a session is incredibly simple. It's given me the confidence to engage more with digital platforms.\"",
      name: "- Aisha",
    },
  ];

  return (
    <div className="w-[22rem]  relative">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="flex flex-col mb-10 md:mb-0">
            <div>
              <p className="text-left w-full md:w-10/12 text-gray-600 mb-4 md:mb-8">
                {slide.text}
              </p>
            </div>
            <div className="flex items-center justify-start gap-4">
              {/* <img
                className="w-20 h-20 object-cover object-top rounded-full "
                src={slide.image}
                alt=""
              /> */}
              <div className="w-fit">
                <p className="text-base text-[#9C3967] contrast-50 text-left">
                  {slide.name}
                </p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
