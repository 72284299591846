import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  saveNotificationsRequest: ["notifications"],
  saveNotificationsSuccess: ["notifications"],
  saveNotificationsFailure: ["error"],
});

export const NotificationsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  notifications: {},
  fetching: false,
  error: null,
});

/* ------------- Reducers ------------- */

// we're attempting to save notifications
export const request = (state) => state.merge({ fetching: true, error: null });

// we've successfully saved notifications
export const success = (state, { notifications }) =>
  state.merge({ fetching: false, error: null, notifications });

// we've had a problem saving notifications
export const failure = (state, { error }) =>
  state.merge({ fetching: false, error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SAVE_NOTIFICATIONS_REQUEST]: request,
  [Types.SAVE_NOTIFICATIONS_SUCCESS]: success,
  [Types.SAVE_NOTIFICATIONS_FAILURE]: failure,
});

/* ------------- Selectors ------------- */
