import ChatUserModel from "./chatUser";

export enum ChatType {
    PRIVATE = 1,
    GROUP = 2,
  }

  export enum MessageType {
    TEXT = 1,
    IMAGE = 2,
    FILE = 3,
  }
  
  export interface IChatMessage {
    id?: string,
    messageText: string;
    sentAt: number;
    sentBy: string;
    sentTo?: string;
    type: number;
    fileType?: string;
    isLocal?: boolean;
  }
  
  export interface IChatGroupMessage {
    recentMessage: IChatMessage | null;
    seen: Boolean;
    // constructor({recentMessage, seen}) {
    //   this.recentMessage = recentMessage;
    //   this.seen = seen;
    // }
  }
  
  export interface IChatGroup extends IChatGroupMessage {
    id: string;
    name?: string;
    createdBy: string;
    members: string[];
    createdAt: number;
    modifiedAt: number;
    type: number;
    title: String | null;
   
    //Local use only
    otherUser?: ChatUserModel; //For Private chat only
    memberDetails?: {[id: string]:ChatUserModel} | null;

    // constructor({id, name, createdBy, members, createdAt, modifiedAt, type, recentMessage, seen}) {
    //   super({recentMessage, seen});
    //   this.id = id;
    //   this.createdAt = createdAt;
    //   this.members = members;
    //   this.modifiedAt = modifiedAt;
    //   this.type = type;
    //   this.createdBy = createdBy;
    // }
  }
  
