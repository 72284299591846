import React from "react";
import { SHOW_H5P } from "../../../config/constants";

const popoverWidth = 300;
const calculatePosition = (postionsProps) => (postionsProps.windowWidth < 1024 ? "bottom" : "right");

const createTourStep = (selector, title, description, position) => ({
  selector: `[data-tut="${selector}"]`,
  content: () => (
    <div className="flex flex-col justify-start">
      <h2>{title}:</h2>
      <p>{description}</p>
    </div>
  ),
  position: calculatePosition,
});

const tourStepCoursesTutor = createTourStep(
  "reactour__menu-courses",
  "Courses",
  "Here is where you can see the techniques you are currently providing and add classes.",
);

const tourStepCalendarTutor = createTourStep(
  "reactour__menu-calendar",
  "Calendar",
  "Book time slots for the lessons you provide. Courses can consist of one or more lessons.",
);

const tourStepProfile = createTourStep(
  "reactour__menu-profile",
  "Profile",
  "Your profile with info you can change. Includes transaction bar, learning type in the form of a pie chart, and more.",
);

const tourStepHome = createTourStep(
  "reactour__menu-home",
  "Dashboard",
  "Your home page with upcoming courses, calendar, reminders, and fun facts.",
);

const tourStepCourses = createTourStep(
  "reactour__menu-courses",
  "Courses",
  "See all your selected courses. Search for specific subjects and find available tutors.",
);

const tourStepFindTutors = createTourStep(
  "reactour__menu-findtutors",
  "Search Tutor",
  "Find tutors based on specific subjects. View tutor percentages matching your learning style.",
);

// const tourStepSpotify = createTourStep(
//   "reactour__menu-spotify",
//   "Spotify",
//   "Studying with background music. Choose from numerous playlists based on your learning style.",
// );

// const tourStepMentahHealth = createTourStep(
//   "reactour__menu-MentalHealth",
//   "Mental Health",
//   "Access free courses on mental health. Address mood improvement and safe harm prevention.",
// );

const tourStepChatGpt = createTourStep(
  "reactour__menu-chatgpt",
  "Advance Chat",
  "Advanced Chat is a powerful tool that allows you to get personalized assistance from our AI assistant.",
);

const tourStepSwotbuddy = createTourStep(
  "reactour__menu-swotbuddy",
  "Swotbuddy",
  "Swotbuddy helps you assess your strengths, weaknesses, opportunities, and threats (SWOT) related to your learning journey.",
);

const tourStepH5p = createTourStep(
  "reactour__menu-h5p",
  "My Contents",
  "Use H5P as a toolbox to create engaging learning activities. Quizzes, interactive videos, and more.",
);

const tourStepTools = createTourStep(
  "reactour__menu-tools",
  "Tools",
  "Dedicated to the latest educational news, articles, and TED videos.",
);

const tourStepFaqs = createTourStep(
  "reactour__menu-faqs",
  "FAQs",
  "Find answers to common questions. Contact our customer support for additional assistance.",
);

const tourStepLogout = createTourStep(
  "reactour__menu-logout",
  "Logout",
  "Need a break? Click the log-out button. Confirm to avoid accidental clicks.",
);

const tourStepsForStuent = [
  tourStepProfile,
  tourStepHome,
  tourStepCourses,
  tourStepFindTutors,
  // tourStepMentahHealth,
  tourStepFaqs,
  tourStepSwotbuddy,
  tourStepChatGpt,
  tourStepTools,
  // tourStepSpotify,
  tourStepLogout,
];

const tourStepsForTutor = [
  tourStepProfile,
  tourStepHome,
  tourStepCoursesTutor,
  // tourStepMentahHealth,
  ...(SHOW_H5P ? [tourStepH5p] : []),
  tourStepFaqs,
  tourStepChatGpt,
  tourStepCalendarTutor,
  tourStepTools,
  // tourStepSpotify,
  tourStepLogout,
];

export { tourStepsForStuent, tourStepsForTutor };