import React, { useState, useEffect } from "react";
import usePlacesAutocomplete, { getDetails } from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "./styles.css";
import commonColor from "../../../theme/commonColor";
import { isEducationalInstitute, extractLocation, getLocationName } from "../../../services/location";

const PlacesAutocomplete = ({ onSelect, searchType, cachedValue, classes, restrictToUSA }) => {


  const restrictions = () => {
    return restrictToUSA === true ? { country: 'us' } : {}
    // return {country: ["us"]} 
  }
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      componentRestrictions: restrictions(),
    },
    defaultValue: cachedValue,
    cache: restrictToUSA, //Dont use cached values for USA to avoid any cached place outside USA to be show up.
  });


  const [placeMsg, setPlaceMsg] = useState("");

  const [isNotPlaceValid, setIsNotPlaceValid] = useState(false);



  const renderPlaceBorderColor = () => {
    if (isNotPlaceValid) {
      return commonColor.error;
    } else if (value && !isNotPlaceValid) {
      return commonColor.correct;
    } else {
      return "#e2e8f0";
    }
  };



  const handleInput = (e) => {
    setValue(e.target.value);
  };


  const handleLoc = (e) => {
    console.log("location:",e);
    setIsNotPlaceValid(true);
    onSelect(false);
    setPlaceMsg("*please select valid location");
      
  };

  const handleSelect = (val) => {
    setValue(val, false);
    console.log("PlacesAutocomplete - user selected:", val);

    if (searchType === "school") {
      const result = data.filter((x) => x.description === val);

      if (isEducationalInstitute(result[0].types)) {
        setIsNotPlaceValid(false);
        setPlaceMsg("");
        console.log("PlacesAutocomplete - found suggestion object:", result, ' and place_id ', result[0].place_id);
        getDetails({ placeId: result[0].place_id }).then((details) => {
          console.log("PlacesAutocomplete - found details:", details);
          onSelect(details);
        });
      }
      else {
        setIsNotPlaceValid(true);
        setPlaceMsg("*selected location should be educational institute");
        onSelect(false);
      }


    } else {

      const result = data.filter((x) => x.description === val);
      console.log("PlacesAutocomplete - found suggestion object:", result, ' and place_id ', result[0].place_id);
      getDetails({ placeId: result[0].place_id }).then((details) => {
        let loc = extractLocation(details);

        if (!loc.cityName) {
          setIsNotPlaceValid(true);
          setPlaceMsg("*selected location should be city & country");
          onSelect(false);
        }
        else {
          setIsNotPlaceValid(false);
          setPlaceMsg("");
          let locName = getLocationName(loc);
          console.log("locName: ", locName);
          onSelect(locName);
        }
       });

    }
  };

  return (
    <>
      {isNotPlaceValid && (
        <div className="text-sm w-full text-red-600 text-left mb-1">
          {placeMsg}
        </div>
      )}
      <Combobox
        onSelect={handleSelect}
        aria-labelledby="sk-places"
        className={`pink ${classes}`}
        onChange={handleLoc}
      >

        <ComboboxInput value={value} onChange={handleInput} disabled={!ready} style={{ borderColor: renderPlaceBorderColor() }} placeholder="Search.." />
        <ComboboxPopover portal={false} style={{ position: "absolute", zIndex: "100", marginTop: "2rem", width: "100%" }}>
          <hr />
          <ComboboxList>
            {status === "OK" && data.length > 0 ? (
              data.map(({ id, description }) => (
                <ComboboxOption key={id} value={description} />
              ))
            ) : (
              <p
                style={{
                  margin: 0,
                  color: "#454545",
                  padding: "0.25rem 1rem 0.75rem 1rem",
                  fontStyle: "italic",
                }}
              >
                No results :(
              </p>
            )}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </>
  );
};

export default PlacesAutocomplete;
