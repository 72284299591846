// Import QUIZ cards
import LearningStyleQuiz from "../../learningStyleQuiz/learningStyleQuiz";

import { useInView, animated, useSpring } from "@react-spring/web";

const PartSix = () => {
  const itemSpring = useSpring({
    from: { y: 20, opacity: 0 },
    to: { y: 0, opacity: 1 },
  });

  const [ref, refSprings] = useInView(
    () => ({
      from: { y: 20, opacity: 0 },
      to: { y: 0, opacity: 1 },
    }),
    {
      root: null,
      rootMargin: "0px",
      once: true,
    }
  );

  const [cardContainerRef, cardContainerString] = useInView(
    () => ({
      from: { opacity: 0 },
      to: { opacity: 1 },
    }),
    {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
      once: true,
    }
  );
  return (
    <animated.div
      id={"unlock_potential"}
      className="bg-[#FFF8F8] text-center flex flex-col justify-between items-center mb-40 py-20"
      ref={ref}
      style={refSprings}
    >
      <p className="text-gray-600  text-3xl mt-2 lg:mt-8 w-full text-center pt-10 md:pt-0">
        Engage
      </p>
      <animated.p
        className="text-gray-500 text-lg mt-2 lg:mt-8 w-10/12 lg:w-6/12 md:mb-14 mb-8 pt-10 md:pt-0"
        style={itemSpring}
      >
        <span className="text-[#9B3C66] font-semibold">
          {" "}
          Engagement is the cornerstone of inspiration.{" "}
        </span>
        Connect with
        <span className="text-[#9B3C66] font-semibold">
          {" "}
          a global community
        </span>
        of learners and educators, and participate in interactive sessions that
        exemplify our vision of Smart Learning for Inspiring Minds. How does
        your mind get inspired for learning? Take the quiz and see. blueprint or
        optimal study patterns.{" "}
        <span className="text-[#9B3C66] font-semibold">Allow our AI</span> to
        uncover the most efficient and effective methods tailored for your
        learning. <br /> Take our quiz by selecting your age bracket.{" "}
      </animated.p>

      <animated.div
        ref={cardContainerRef}
        className="flex flex-col md:flex-row gap-20 md:gap-6 items-center mb-14 justify-around w-full p-2 md:px-4 lg:px-8 mt-8"
        style={cardContainerString}
      >
        <div>
          <LearningStyleQuiz></LearningStyleQuiz>
        </div>
      </animated.div>
    </animated.div>
  );
};

export default PartSix;
