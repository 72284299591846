import React, { useState } from "react";
import commonColor from "../../theme/commonColor";
import "./styles.css";
import LandingLayout from "../../components/landing/landingLayout";
import LandingCard from "../../components/landing/landingCard";
import Modal from "../../components/common/modal/modal";
import Swotbutton from "../../components/common/buttons/swotbutton";
import { signupBeta } from "../../services/api";
import LoaderModal from "../../components/common/modal/loaderModal";
import BetaSignupModal from "./betaSignupModal";
import { ReleaseMode, CURRENT_RELEASE_MODE } from "../../config/constants";

import useHightCalculated from "../../components/common/hooks/UseHeightCalculated";
import ReactCardFlip from "react-card-flip";
import infoIcon from "../../assets/images/quizCards/info-icon-m.png";
import checkMark from "../../assets/icons/check-mark.svg";
import evolutionIcon from "../../assets/images/information/evolutionIcon.svg";
import whatBuildingIcon from "../../assets/images/information/whatBuildingIcon.svg";
import forWhoIcon from "../../assets/images/information/forWhoIcon.svg";
import bulbIcon1 from "../../assets/images/information/bulbs-1.svg";
import bulbIcon2 from "../../assets/images/information/bulbs-2.svg";
import bulbIcon3 from "../../assets/images/information/bulbs-3.svg";

export default function Information() {
  const [showFormModal, setShowFormModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const hightCalculated = useHightCalculated(calculateCardHeight);

  const cardContent = [
    {
      title: "Evolution in education",
      text: [
        "Be a part of the evolution!",
        "Education and learning aren't about one person, one thing, or one way; It's about you, your path, people, desires, and individuality.",
        "A future you define for you! We strive to create an educational place that caters to you and your needs and dissolve the digital divide.",
        "Let's create a platform that engages and supports you, the entire world of learning, education, and unique experiences. A place here for whoever YOU are, wherever YOU are, whenever YOU need it for whatever YOU want to learn. Engage with us to create YOUR educational revolution.",
      ],
      cardImage: evolutionIcon,
      btnText: "Discover",
      btnTo: "/",
      btnColor: commonColor.deepPink,
      bulbIcon: bulbIcon1,
    },
    {
      title: "What are we building?",
      text: [
        "A space predicated on equality, quality and E-quality.",
        "A place anticipating the future of learning. A future of remote, unique, gamified, disruptive, individualized, engaged and adaptive education.",
        "A future you define for you! We strive to create an educational place that caters to you and your needs and dissolve the digital divide.",
      ],
      cardImage: whatBuildingIcon,
      btnText: "Curious",
      btnTo: "/what-we-are-building",
      btnColor: commonColor.deepBlue,
      bulbIcon: bulbIcon2,
    },
    {
      title: "For who?",
      text: [
        "Any student seeking to become a better version of themselves wants to learn differently or just needs assistance in education.",
        "For any tutor that wants to teach students in their unique way, in a safe place where creativity is encouraged Anyone, regardless of age, preference or beliefs, wants to be a part of the change in the way we see education.",
        "l3arn is for you to unbox education together as a team.",
      ],
      cardImage: forWhoIcon,
      btnText: "Sign Up",
      btnTo: "/on-boarding",
      btnColor: commonColor.deepPink,
      bulbIcon: bulbIcon3,
    },
  ];

  const Card = ({ cardText, bgImage, text, bulbIcon }) => {
    const [isFlipped, setIsFlipped] = useState(false);
    // console.log(project);
    return (
      <>
        <div>
          <p className="font-semibold pb-2 block lg:hidden sm:pb-4">
            {cardText}
          </p>
        </div>
        <ReactCardFlip
          isFlipped={isFlipped}
          flipDirection="horizontal"
          className="m-4"
        >
          <div
            className="relative card flex flex-col items-center brightness-75"
            style={{
              height: hightCalculated + "px",
            }}
          >
            <img
              className="forWhoCardImage h-full w-full object-cover"
              src={bgImage}
              alt="bgImage"
            />
            <p className="absolute cardCenterText text-gray-700 font-semibold text-2xl mt-32 ">
              {cardText}
            </p>
            <div
              className="absolute bottom-0 w-1/3 cursor-pointer"
              style={{ right: "-8px" }}
            >
              <img
                onClick={() => setIsFlipped((prev) => !prev)}
                src={infoIcon}
                className="object-contain w-full"
                alt="infoIcon"
              />
            </div>
          </div>

          <div
            className="relative card w-full"
            style={{
              height: hightCalculated + "px",
            }}
            onClick={() => setIsFlipped((prev) => !prev)}
          >
            <div className="flex flex-col flex-1 py-2 lg:px-4 sm:px-2 px-6  h-full">
              <div className="flex flex-1 flex-col px-4 xl:p-0 2xl:px-8 xl:pb-0 md:pb-0 pb-0 leading-4 text-gray-700 md:text-sm text-xs justify-center">
                <div className="w-full textCardNormal">
                  {text.map((item, i) => (
                    <p key={item}>{item}</p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </ReactCardFlip>
      </>
    );
  };

  const CardButtons = ({ btnText, btnUrl, btnTo, bgColor }) => {
    return (
      <div key={btnText} className="flex mt-6 justify-center w-full">
        <div className="ml-2 sm:ml-4">
          <Swotbutton text={btnText} to={btnTo} bgColor={bgColor}></Swotbutton>
        </div>
      </div>
    );
  };

  return (
    <LandingLayout landingTitle="Information">
      <div className="flex justify-start lg:justify-around items-center lg:flex-row flex-col w-full lg:w-11/12 pt-4 lg:h-75v">
        {cardContent.map((item, index) => (
          <div
            key={item.title}
            className="flex flex-col w-9/12 sm:w-6/12 items-center 2k:w-1/4 md:w-2/5 lg:w-1/3 xl:w-2/5 max-w-3xl p-4 lg:p-6 xl:p-4 2xl:py-5 2xl:px-8 2k:p-10"
          >
            <Card
              bgImage={item.cardImage}
              className="mx-6"
              cardText={item.title}
              text={item.text}
              bulbIcon={item.bulbIcon}
              key={`card-${item.title}`}
            />
            <CardButtons
              btnTo={item.btnTo}
              btnText={item.btnText}
              btnUrl={item.btnurl}
              bgColor={item.btnColor}
            />
          </div>
        ))}
      </div>
      <BetaSignupModal
        isOpen={!loading && showFormModal}
        onRequestClose={() => {
          setShowFormModal(false);
        }}
        showLoader={(status) => {
          setLoading(status);
        }}
      />
      {/* <BetaSignup onSubmit={onSubmit} /> */}
      {/* </BetaSignup> */}
      <LoaderModal
        isOpen={loading}
        onRequestClose={() => {
          setLoading(false);
        }}
      />
    </LandingLayout>
  );
}

const calculateCardHeight = (size) => {
  let w = size.width;
  let heightCalculated = 0;
  let minHeight = 550;
  if (window.innerWidth >= 2048) {
    // 2k
    heightCalculated = size.height * 0.7;
  } else if (window.innerWidth > 1680) {
    // 2.5 xl
    heightCalculated = size.height * 0.7;
  } else if (window.innerWidth >= 1536) {
    // 2xl
    heightCalculated = size.height * 0.7;
  } else if (window.innerWidth >= 1280) {
    // xl
    heightCalculated = size.height * 0.7;
  } else if (window.innerWidth >= 1024) {
    // lg
    heightCalculated = size.height * 0.7;
  } else if (window.innerWidth >= 768) {
    // md
    heightCalculated = size.height * 0.7;
  } else if (window.innerWidth >= 640) {
    // sm
    heightCalculated = size.height * 0.7;
  } else {
    w = 0;
    heightCalculated = size.height * 0.8;
    if (heightCalculated > size.width * 1.2) {
      heightCalculated = size.width * 1.2;
    }
    minHeight = 0;
  }
  return heightCalculated < minHeight
    ? minHeight
    : heightCalculated > 550
    ? 550
    : heightCalculated;
};
