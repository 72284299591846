import React, { useRef, useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "../../../components/common/input/select";
import Swotbutton from "../../../components/common/buttons/swotbutton";
import commonColor from "../../../theme/commonColor";
import AmbassadorNavBar from "../ambassadorNavBar";
import { addAambassador, ambassadorGetAffilate } from "../../../services/api";
import LoaderModal from "../../../components/common/modal/loaderModal";

import AsyncSelect from "react-select/async";
import axios from "axios";

import { countriesList } from "../../../services/countrieslist";
import "./styles.css"

const ImporterDashboard = () => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  // const [data, setData] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const [selectedCountry, setSelectedCountry] = React.useState();
  const [updatedCountry, setUpdatedCountry] = useState();

  const [affiliateCode, setAffiliateCode] = useState('');
  const [showAffiliatePopup, setShowAffiliatePopup] = useState(false);

  const selectRef = useRef();

  const loadOptions = async (inputValue) => {
    try {
      const res = await axios.get(
        `https://us-central1-swotknot-dev.cloudfunctions.net/account/course/search/${inputValue}`
      );
      const courses = res.data.data.map((course) => ({
        label: course.name_short,
        value: course.name_short,
      }));
      return courses;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const handleFocus = () => {
    selectRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const rolesOPt = [
    { value: "", label: "Select Role" },
    { value: "student", label: "Student" },
    { value: "tutor", label: "Tutor" },
    { value: "ambassador", label: "Ambassador" },
    { value: "chatgpt", label: "Chatgpt" },
    { value: "institute", label: "Institute" },
  ];

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/ambassador");
    }
  }, []);

  const reducerStateFn = (state, action) => {
    if (action.key) {
      if (action.key === "reset") {
        return action.value;
      }
      return { ...state, ...{ [action.key]: action.value } };
    }
  };

  const [formState, dispatchFormState] = useReducer(reducerStateFn, {});

  useEffect(() => {
    if (formState) {
      setIsValid(
        getFormValue("email", "isValid") &&
        getFormValue("role", "isValid") &&
        getFormValue("country", "isValid") &&
        getFormValue("name", "isValid") &&
        getFormValue("enrolled_date", "isValid")
      );
    }
  }, [formState]);

  const handleChange = (key, value) => {
    const actualValue = modifyValue(key, value);
    const isValidValue = validate(key, actualValue);
    const errorMessage = isValidValue ? null : getErrorMessage(key, value);

    const data = {
      key: key,
      value: actualValue,
      isValid: isValidValue,
      errorMessage: errorMessage,
      rawValue: value,
    };
    dispatchFormState({ key: key, value: data });
  };

  const getFormValue = (key, secondkey) => {
    if (formState && formState[key]) {
      return formState[key][secondkey];
    }
    return null;
  };

  const modifyValue = (key, value) => {
    if (key === "role") {
      return value.value;
    }
    return value;
  };

  const validate = (key, value) => {
    if (key === "email") {
      return isEmailValid(value);
    } else if (key === "role") {
      return value && value.trim().length > 0;
    } else if (key === "country") {
      return value && value.trim().length > 0;
    } else if (key === "name") {
      return value && value.trim().length >= 3;
    } else if (key === "enrolled_date") {
      return value && value.trim().length >= 3;
    } else if (key === "course_name") {
      return value && value.trim().length >= 3;
    } else if (key === "phone") {
      return true;
    }
    return true;
  };

  const getErrorMessage = (key, value) => {
    if (key === "email") {
      return "Please enter a valid email id";
    } else if (key === "role") {
      return "You must select a role";
    } else if (key === "name") {
      return "Please enter a valid name";
    } else if (key === "country") {
      return "A valid country is needed";
    } else if (key === "enrolled_date") {
      return "A valid date date is needed";
    } else if (key === "course_name") {
      return "A valid course name is needed";
    } else if (key === "phone") {
      return null;
    }
    return null;
  };

  const renderBorderColor = (key) => {
    const isvalid = getFormValue(key, "isValid");
    if (isValid) {
      return "border-correct/50 focus:border-correct";
    } else if (isValid === false) {
      return "border-error/50 focus:border-error";
    } else {
      return "border-lightGray/50 focus:border-lightGray";
    }
  };
  //---------Email validations------------//
  const isEmailValid = (email) => {
    if (!email || email.length === null || email.length === undefined) {
      return null;
    }

    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    return pattern.test(email);
  };

  const handleSubmit = async () => {
    // e.preventDefault();
    if (isValid) {
      const data = {
        email: getFormValue("email", "value"),
        role: getFormValue("role", "value"),
        phone: getFormValue("phone", "value"),
        name: getFormValue("name", "value"),
        country: getFormValue("country", "value"),
        course_name: getFormValue("course_name", "value"),
        enrolled_date: getFormValue("enrolled_date", "value"),
        status: "pending",
        commission_received: "0",
      };

      setIsLoading(true);
      const response = await addAambassador(data);
      if (response?.ok) {
        dispatchFormState({ key: "reset", value: null });
      }
      setIsLoading(false);
      console.log(response);
    }
  };
  const createChangeHandler = (field) => (selectedOption) => {
    handleChange(field, selectedOption ? selectedOption.value : "");
  };

  const handleCourseChange = createChangeHandler("course_name");
  const handleCountryChange = createChangeHandler("country");


  const handleAffiliateClick = async () => {
    try {

      const response = await ambassadorGetAffilate();
      const fetchedCode = response.data.affiliate_code;
      const windowLocationPrefix = window.location.origin;
      const codeWithPrefix = `${windowLocationPrefix}/on-boarding?affilate=${fetchedCode}`;

      setAffiliateCode(codeWithPrefix);
      setShowAffiliatePopup(true);
    } catch (error) {
      console.error('Error fetching affiliate code:', error);
    }
  };

  const handleAffiliateClose = () => {
    setShowAffiliatePopup(false);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(affiliateCode);
    setShowAffiliatePopup(false);
  };



  return (
    <div className="w-full h-screen flex flex-col items-center min-h-screen-ios gradient-background">
      <AmbassadorNavBar />

      {showAffiliatePopup && (
        <div className="affiliate-popup">
          <button className="close-button" onClick={handleAffiliateClose}>
            X
          </button>
          <p>Affiliate Code:</p>
          <p className="code">{affiliateCode}</p>
          <button className="copy-button" onClick={handleCopyLink}>
            Copy Link
          </button>
        </div>
      )}

      <div className="mb-8 mt-2 lg:mb-10 lg:mt-4 w-full flex flex-row justify-center px-8 text-white">
        <h2>Add a new lead</h2>
      </div>
      <div className="container mx-auto flex flex-col  overflow-y-scroll md:p-0 px-4 w-8/12 md:w-5/12 2k:w-3/12">
        {showPopup && (
          <div className="fixed top-0 right-0 bg-green-500 text-white p-4 rounded-bl-lg shadow-lg">
            New Data Saved!
          </div>
        )}
        <div className="w-full flex justify-between items-center">
          <Swotbutton
            text="Add New Lead"
            onClick={() => {
              dispatchFormState({ key: "reset", value: null });
            }}
          />

          <Swotbutton
            text="Get Affilate Link"
            onClick={handleAffiliateClick}
          />

          <Swotbutton
            text="Coupons"
            onClick={() => {
              navigate("/coupons");
            }}
          />

          <Swotbutton
            text="Show Records"
            onClick={() => {
              navigate("/ViewUsers");
            }}
          />
        </div>
        <div
          // onSubmit={handleSubmit}
          className="flex flex-col items-center mt-10 space-y-4 "
        >
          <div className="w-full mt-auto">
            <RowWidget title={"Role"}>
              <Select
                className="w-full"
                defaultValue={rolesOPt[0]}
                placeholder={"Select"}
                value={
                  getFormValue("role", "rawValue")
                    ? getFormValue("role", "rawValue")
                    : rolesOPt[0]
                }
                onChange={(item) => {
                  handleChange("role", item);
                }}
                options={rolesOPt}
              />
            </RowWidget>
          </div>

          <RowWidget title={"Name"}>
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={getFormValue("name", "value") ?? ""}
              onChange={(e) => {
                handleChange(e.target.name, e.target.value);
              }}
              className={`w-full bg-white appearance-none border-2 focus:outline-none border-lightGray focus:border-gray-300 px-2 py-1  ${renderBorderColor(
                "name"
              )}`}
              required
            />
          </RowWidget>

          <RowWidget title={"Email"}>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={getFormValue("email", "value") ?? ""}
              onChange={(e) => {
                handleChange(e.target.name, e.target.value);
              }}
              className={`w-full bg-white appearance-none border-2 focus:outline-none border-lightGray focus:border-gray-300 px-2 py-1  ${renderBorderColor(
                "email"
              )}`}
              required
            />
          </RowWidget>

          <RowWidget title={"Phone number (Optional)"}>

            <input
              className={`w-full bg-white appearance-none border-2 focus:outline-none border-lightGray focus:border-gray-300 px-2 py-1  ${renderBorderColor(
                "phone"
              )}`}
              id="phone"
              name="phone"
              type="text"
              placeholder={"Phone Number"}
              value={getFormValue("phone", "value") ?? ""}
              onChange={(e) => {
                handleChange(e.target.name, e.target.value);
              }}
              required
            />
          </RowWidget>

          <div className="w-full mt-auto">
            <RowWidget title={"Country"}>
              <Select
                className="w-full"
                value={selectedCountry}
                onFocus={handleFocus}
                placeholder="Select Country.."
                options={countriesList}
                onChange={handleCountryChange}
              />
            </RowWidget>
          </div>

          <RowWidget title={"Course Name (Optional)"}>

            <div className="w-full" ref={selectRef}>
              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={loadOptions}
                onFocus={handleFocus}
                onChange={handleCourseChange}
              />
            </div>
          </RowWidget>

          <RowWidget title={"Date"}>
            <input
              type="date"
              name="enrolled_date"
              placeholder="date"
              value={getFormValue("enrolled_date", "value") ?? ""}
              onChange={(e) => {
                handleChange(e.target.name, e.target.value);
              }}
              className={`w-full bg-white appearance-none border-2 focus:outline-none border-lightGray focus:border-gray-300 px-2 py-1  ${renderBorderColor(
                "enrolled_date"
              )}`}
              required
            />
          </RowWidget>
        </div>
        <div className="w-full flex justify-center items-center mt-4">
          <Swotbutton
            text="Save"
            bgColor={isValid ? commonColor.deepGreen : commonColor.disable}
            disabled={!isValid}
            onClick={() => {
              handleSubmit();
            }}
          />
        </div>
      </div>

      <LoaderModal isOpen={isLoading} />
    </div>
  );
};

export default ImporterDashboard;

const RowWidget = ({ title, children }) => {
  return (
    <div className="w-full flex flex-col  justify-center py-2">
      <div className="text-white font-semibold text-sm md:w-32 w-full flex flex-row justify-start mb-2 items-center px-2">
        {title}
      </div>
      <div className="flex-grow flex flex-row justify-start  items-center px-2 py-1">
        {children}
      </div>
    </div>
  );
};
