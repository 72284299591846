import React from "react";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import commonColor from "../../theme/commonColor";
import Swotbutton from "../common/buttons/swotbutton";

const TutorSetupPrompt = ({ user }) => {
  const navigate = useNavigate();
  return (
    <div className=" bg-white w-full rounded-lg p-4 my-4 mx-2 h-full flex flex-col justify-center items-center">
      <div className="text-center">
        Hello {user.username}, thank you for joining l3arn as a tutor.
      </div>
      <div className="text-center">Let's get started by setting you up!</div>
      <div className="w-full flex justify-center items-center py-4">
        <Swotbutton
          text={"Tutor Setup"}
          onClick={() => navigate("/tutor-setup")}
          disabled={false}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TutorSetupPrompt);
