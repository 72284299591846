import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MainPageWithMenu from "../../components/common/MainPageTemplate/mainPageWithMenu";
import BetatesterDashboard from "../../components/admin/betatester/BetatesterDashboard";
import { useNavigate } from "react-router-dom";

const BetatesterPage = ({ user }) => {
  console.log("Betatester", user);

  let navigate = useNavigate();
  useEffect(() => {
    if (user.accountType != "SwotAdmin") {
      navigate("/dashboard");
    }
  }, []);



  return (
    <MainPageWithMenu user={user} menuSelected={"betatester"}>
      <BetatesterDashboard />
    </MainPageWithMenu>
  );
};
const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BetatesterPage);
