import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  saveUnreadMessagesRequest: ["unreadMessages"],
  saveUnreadMessagesSuccess: ["unreadMessages"],
  saveUnreadMessagesFailure: ["error"],
});

export const UnreadMessagesTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  unreadMessages: [],
  fetching: false,
  error: null,
});

/* ------------- Reducers ------------- */

// we're attempting to save reminders
export const request = (state) => state.merge({ fetching: true, error: null });

// we've successfully saved reminders
export const success = (state, { unreadMessages }) =>
  state.merge({ fetching: false, error: null, unreadMessages });

// we've had a problem saving reminders
export const failure = (state, { error }) =>
  state.merge({ fetching: false, error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SAVE_UNREAD_MESSAGES_REQUEST]: request,
  [Types.SAVE_UNREAD_MESSAGES_SUCCESS]: success,
  [Types.SAVE_UNREAD_MESSAGES_FAILURE]: failure,
});

/* ------------- Selectors ------------- */
