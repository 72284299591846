export default class ChatUserModel {
  uid: string;
  firstName: string | null = null;
  lastName: string | null = null;
  city: string | null = null;
  accountType: string | null = null;
  photoUrl: string | null = null;
  public constructor({uid, firstName, lastName, city, accountType, photoUrl}) {
    this.uid = uid;
    this.firstName = firstName;
    this.lastName = lastName;
    this.city = city;
    this.accountType = accountType;
    this.photoUrl = photoUrl;
  }
  // public constructor(init?: Partial<ChatUserModel>) {
  //   Object.assign(this, init);
  // }
}
