import React from "react";
import { connect } from "react-redux";
import ContentCard from "../../components/guest/feed/contentCard";
import MainPageWithMenu from "../../components/common/MainPageTemplate/mainPageWithMenu";

const GuestFeed = ({ user }) => {
  return (
    <MainPageWithMenu user={user} menuSelected={"rope"}>
      <div className="w-full  p-4 h-full flex justify-center items-center flex-wrap overflow-y-scroll">
        <div
          className=" p-4 h-full"
          style={{
            minWidth: "14rem",
            maxWidth: "22rem",
            minHeight: "36em",
            maxHeight: "40rem",
          }}
        >
          <ContentCard
            title={"Your newsfeed"}
            text={`Making sure that you get your daily dosage of news. 
            We have a daily update of interesting reading, 
            tools a nd videos that will make sure you stay up to date with current media.`}
          />
        </div>
        <div
          className="p-4 h-full"
          style={{
            minWidth: "14rem",
            maxWidth: "22rem",
            minHeight: "36em",
            maxHeight: "40rem",
          }}
        >
          <ContentCard
            title={"Interesting facts about coding"}
            text={`Just feel like learning some random facts 
            that will make you look cool at the next quiz night 
            or when entering the Jeopardy contest?`}
          />
        </div>
        <div
          className="p-4 h-full"
          style={{
            minWidth: "14rem",
            maxWidth: "22rem",
            minHeight: "36em",
            maxHeight: "40rem",
          }}
        >
          <ContentCard
            title={"Flash cards"}
            text={`Need help remembering some of the basic 
            things you have learned in your lessons. We have it all covered.`}
          />
        </div>
        <div
          className="p-4 h-full"
          style={{
            minWidth: "14rem",
            maxWidth: "22rem",
            minHeight: "36em",
            maxHeight: "40rem",
          }}
        >
          <ContentCard
            title={"Want more than coding courses?"}
            text={`Your experience on this platform 
            can be extended into courses and lessons that
            will allow you to learn the way you learn best.`}
          />
        </div>
      </div>
    </MainPageWithMenu>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps, {})(GuestFeed);
