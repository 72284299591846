import React from "react";
import { FadeLoader } from "react-spinners";

const CustomFadeLoader = ({ radius, loading, color, textColor = "#ffffff" }: { radius: string; loading: boolean; color: string; textColor?: string; }) => {
  let myMessages = [
    "getting the planner...",
    "organizing our desk...",
    "getting our stationary...",
    "the pen fell...",
    "getting the cat off the keyboard…",
  ];
  let randomValue = myMessages[Math.floor(Math.random() * myMessages.length)];

  return (
    <div className="flex h-full justify-center flex-col items-center space-y-2">
      <p className="italic mb-4" style={{ color: textColor ? textColor : "#ffffff" }}>{randomValue}</p>
      <FadeLoader radius={radius} loading={loading} color={color} />
    </div>
  );
};
export default CustomFadeLoader;
