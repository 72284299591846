import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import contentful from "../../config/contentful";
import Masonry from "react-masonry-css";
import ShowMoreText from "react-show-more-text";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { TermsAndPrivacyPolicy } from "../../components/common/consent";
import "./styles.css";
import Swotbutton from "../../components/common/buttons/swotbutton";
import { useNavigate } from "react-router-dom";

const BlogFeed = ({ user }) => {
  let navigate = useNavigate();

  const [content, setContent] = useState([]);
  const [total, setTotal] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const pageLimit = 50;

  const breakpointColumnsObj = {
    default: 4,
    1900: 4,
    1400: 3,
    900: 2,
    768: 1,
  };

  useEffect(() => {
    getContent();
  }, []);

  const getContent = async () => {
    try {
      setLoadingMore(true);
      const skip = content.length;
      const response = await contentful.getEntries({
        content_type: "blogPost",
        limit: pageLimit,
        skip: skip,
      });

      console.log("blogPost", response);

      if (response) {
        setContent([...content, ...(response.items ?? [])]);
        setTotal(response.total);
      }
    } catch (e) {
      console.log(e);
    }
    setLoadingMore(false);
  };

  const loadMore = () => {
    if (!loadingMore && content.length < total) {
      getContent();
    }
  };

  const renderContent = (items) => {
    const contentBlocks = items.map(function (item) {
      console.log("Swotnews - found item", item);
      const blogTitle = item.fields?.title;
      const blogBody = item.fields?.blogBody?.content[0]?.content[0]?.value;
      const blogMediaUrl = item.fields.blogMedia[0]?.fields?.file?.url;
      return (
        <div className="w-full flex flex-col justify-center items-center">
          <div
            key={item.sys.id}
            // className="w-7/12 sm:w-10/12 md:w-7/12 mb-8  flex flex-col overflow-hidden  items-center justify-center"
            className="w-full flex flex-col overflow-hidden  items-center justify-center m-4 cursor-pointer"
            style={{
              // minWidth: "18rem",
              // maxWidth: "33rem",
              // minHeight: "33rem",
              // maxHeight: "40rem",
              boxShadow: `0px 5px 10px 0 rgba(0, 0, 0, 0.6)`,
              borderRadius: 25,
            }}
            onClick={() => {
              navigate(`/blogs/detail/${item.sys.id}`, {
                state: "blogs",
              });
            }}
          >
            {blogMediaUrl && (
              <div
                className="flex justify-center items-center overflow-hidden mb-3"
                style={{
                  // width: "100%",
                  //overflow: "hidden",
                  maxHeight: "18rem",
                  //minHeight: "30%"
                }}
              >
                <img
                  src={blogMediaUrl}
                  loading="lazy"
                  alt="l3arn"
                  className="contain"
                  style={{
                    // width: "100%",
                    overflow: "hidden",
                    // maxHeight: "50%",
                    // minHeight: "30%"
                  }}
                />
              </div>
            )}
            {blogTitle && (
              <div
                className=" px-3 py-2 text-lg font-bold"
                style={
                  {
                    // width: "100%",
                    //overflow: "hidden",
                    // maxHeight: "4rem",
                    //minHeight: "30%"
                  }
                }
              >
                {blogTitle}
              </div>
            )}
            {blogBody && (
              <span className="truncat-text text-sm mx-4 mb-4">{blogBody}</span>
            )}
            {item.fields.source && (
              <div
                className=" flex justify-between space-x-2 items-center py-2 px-3"
                style={{
                  // width: "100%",
                  //overflow: "hidden",
                  maxHeight: "2.5rem",
                  //minHeight: "30%"
                }}
              ></div>
            )}
          </div>
        </div>
      );
    });

    return contentBlocks;
  };

  return (
    <div className="xl:px-5 w-full h-full flex flex-col justify-start  overflow-y-scroll">
      <div className="w-full">
        {/* masonry */}
        {/* <div
          className="h-full  flex flex-col justify-start float-left"
          style={{
            width: "5%",
          }}
        ></div> */}
        <div
          className="w-full h-full lg:px-5 overflow-y-scroll flex flex-col justify-start"
          style={{
            width: "100%",
          }}
        >
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {/* array of JSX items */}
            {renderContent(content)}
          </Masonry>
          {/* </div> */}
          <div className="w-full px-8 py-2 flex justify-end items-center">
            {content.length < total && (
              <Swotbutton
                text="Load More.."
                loading={loadingMore}
                onClick={() => {
                  loadMore();
                }}
              />
            )}
          </div>
          <div className="text-center py-4">
            <TermsAndPrivacyPolicy />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  //   saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BlogFeed);
