import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MainPageWithMenu from "../../components/common/MainPageTemplate/mainPageWithMenu";
import ImporterDashboard from "../../components/admin/importer/ImporterDashboard";
import { useNavigate } from "react-router-dom";


const ImporterPage = ({ user }) => {
  console.log("ImporterPage", user);

  let navigate = useNavigate();
  useEffect(() => {
    if (user.accountType != "SwotAdmin") {
      navigate("/dashboard");
    }
  }, []);


  return (
    <MainPageWithMenu user={user} menuSelected={"importer"}>
      <ImporterDashboard />
    </MainPageWithMenu>
  );
};
const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ImporterPage);