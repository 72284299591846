import React, { useEffect, useState, useLayoutEffect } from "react";
import { connect } from "react-redux";
import { getCourseListOfTutor } from "../../services/api";
import commonColor from "../../theme/commonColor";
import AddCourse from "../../assets/images/createCourse.jpg";
import Swotbutton from "../common/buttons/swotbutton";
import { useWindowSize } from "../../utils/uiUtils";
import { ReactComponent as Add } from "../../assets/icons/add.svg";
import { useNavigate } from "react-router-dom";
const CourseListHorizontal = ({ user, setCoursesTotal }) => {
  const navigate = useNavigate();

  const [allCourses, setAllCourses] = useState([]);
  const [coursesInit, setCoursesInit] = useState(false);
  const [screenSize, setScreenSize] = useState();

  const [width, height] = useWindowSize();

  useEffect(() => {
    if (!coursesInit && user) {
      getAllCourses();
    }
  }, [coursesInit]);

  useEffect(() => {
    console.log("screen width changed", width);
  }, [width]);

  useEffect(() => {
    console.log("CourseList - useEffect -  courses found", allCourses);
  }, [allCourses]);

  const getAllCourses = async () => {
    const result = await getCourseListOfTutor(user.uid);
    if (result && result.data) {
      console.log("CourseList - useEffect - got courses", result.data);
      const fetchedCourses = result.data.courses;
      setAllCourses(fetchedCourses);
      setCoursesInit(true);
      setCoursesTotal(fetchedCourses.length);
    }
  };

  const renderCourses = () => {
    console.log(
      "CourseList - renderCourses -  courses found",
      allCourses.length
    );
    if (allCourses.length > 0) {
      /**
       *  "courseId": 2,
                  "nameFull": "marketing for apples",
                  "nameShort": "mark apple",
                  "subjectArea": "Marketing",
                  "photoUrl": "ajajkajajajajaj",
                  "tutorUsername": "Tasos"
       */
      return allCourses
        .filter((x, i) => i < 4)
        .map((course, i) => {
          return (
            <button
              key={course.courseId}
              className={`${
                i === 3 &&
                ((allCourses.length >= 4 && width < 1550) ||
                  (allCourses.length === 3 && width < 1350))
                  ? " hidden "
                  : " "
              }
                ${
                  i === 2 &&
                  ((allCourses.length >= 3 && width >= 1024 && width < 1350) ||
                    width < 860)
                    ? " hidden "
                    : " "
                }
              ${
                i === 1 && ((width >= 1024 && width < 1280) || width < 680)
                  ? " hidden "
                  : " "
                // i === 1 && width < 680 ? " hidden " : " "
              }
             
              mx-2 rounded-lg shadow-lg h-full w-40 flex flex-col focus:outline-none`}
              style={{
                borderRadius: "1.5rem",
                maxHeight: "15rem",
                minHeight: "12rem",
                minWidth: "8rem",
                maxWidth: "12rem",
              }}
              onClick={() => {
                // navigate("courses", {
                //   state: {
                //     currentView: "courseDetails",
                //     courseId: course.courseId,
                //   },
                // });
                navigate(`/courses/${course.courseId}`);
                // setCourseId(course.courseId)
                // setCurrentView("courseDetails")
              }}
            >
              <div
                className="h-1/2 w-full bg-red-200 flex justify-start"
                style={{
                  borderTopLeftRadius: "1.5rem",
                  borderTopRightRadius: "1.5rem",
                  backgroundImage: `url(${
                    course.photoUrl ?? course.videoThumbnailUrl
                  })`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              />
              <div className="w-full h-1/2 flex justify-start p-2 items-start">
                <div
                  className="text-sm text-center"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textTransform: "capitalize",
                  }}
                >
                  {course.nameFull ?? course.nameShort}
                </div>
                {/* <div className="text-xs text-right">{course.tutorUsername}</div> */}
              </div>
            </button>
          );
        });
    } else if (!coursesInit) {
      return (
        <div className="h-full flex justify-start items-center">
          <span className="pl-4">Fetching courses...</span>
        </div>
      );
    } else {
      return (
        <div className="h-full flex flex-col text-sm overflow-y-scroll">
          <div className="mt-auto mb-auto">
            At l3arn it is all about offering education in a way that allows any
            child, with any learning style to get the best of education we as
            tutors can offer. To make sure that students start attending your
            courses, please add the courses you want to teach.
            <br />
            The setup is simple and once you've done it, students will be able
            to attend your courses.
            <br /> <br />
            Remember to fill in as much of the information, even if it not
            required, it will just allow our clever AI to match you and students
            that think and learn the same way.
          </div>
        </div>
      );
    }
  };

  const allCoursesLength = allCourses.length;
  return (
    <div className=" h-full w-full flex flex-row justify-start items-center">
      <div className=" h-60 w-full flex flex-row justify-between items-center">
        <div className="flex-grow px-2 h-full flex justify-start items-start">
          <div className="h-full flex justify-start items-start py-2 sm:py-0">
            {renderCourses()}
            <button
              className={`${
                // width >= 760 && allCoursesLength <= 3 ? "hidden " : "  "

                width >= 760 && allCoursesLength > 4
                  ? " "
                  : width <= 760 && allCoursesLength >= 1
                  ? ""
                  : "hidden"
              }
               m-2 h-56 flex justify-center items-center focus:outline-none`}
              style={{
                borderRadius: "1.5rem",
                maxHeight: "15rem",
                minHeight: "12rem",
                minWidth: "4rem",
                color: commonColor.deepPink,
              }}
              onClick={() => {
                navigate("/courses", {
                  state: {
                    currentView: "courseList",
                  },
                });
                // setCourseId(course.courseId)
                // setCurrentView("courseDetails")
              }}
            >
              See All
            </button>
          </div>
        </div>
        {user.setupStatus === "approved" && (
          <div className=" h-full flex  flex-col justify-center items-end p-2">
            {width >= 760 ? (
              <div
                className={`mx-2 rounded-lg shadow-lg h-full w-40 flex flex-col focus:outline-none`}
                style={{
                  borderRadius: "1.5rem",
                  maxHeight: "15rem",
                  minHeight: "12rem",
                  minWidth: "8rem",
                  maxWidth: "12rem",
                  // border: "1px solid gray"
                }}
              >
                <div className="w-full h-full flex justify-center p-2 items-center">
                  <Swotbutton
                    text={"Add Course"}
                    onClick={() => {
                      // navigate("courses", {
                      //   state: {
                      //     currentView: "createCourse",
                      //   },
                      // });
                      navigate("/create-course");
                      // setCourseId(course.courseId)
                      // setCurrentView("courseDetails")
                    }}
                  />
                </div>
              </div>
            ) : (
              <button
                onClick={() => {
                  // navigate("courses", {
                  //   state: {
                  //     currentView: "createCourse",
                  //   },
                  // });
                  navigate("/create-course");

                  // setCourseId(course.courseId)
                  // setCurrentView("courseDetails")
                }}
              >
                <svg
                  style={{
                    fill: commonColor.deepPink,
                    height: "35px",
                    width: "35px",
                  }}
                  alt="addSvg"
                  viewBox="0 0 20 20"
                >
                  <title>add_circle</title>
                  <desc>Created with Sketch.</desc>
                  <g
                    id="Icons"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="Rounded"
                      transform="translate(-102.000000, -1484.000000)"
                    >
                      <g
                        id="Content"
                        transform="translate(100.000000, 1428.000000)"
                      >
                        <g
                          id="-Round-/-Content-/-add_circle"
                          transform="translate(0.000000, 54.000000)"
                        >
                          <g>
                            <polygon
                              id="Path"
                              points="0 0 24 0 24 24 0 24"
                            ></polygon>
                            <path
                              d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M16,13 L13,13 L13,16 C13,16.55 12.55,17 12,17 C11.45,17 11,16.55 11,16 L11,13 L8,13 C7.45,13 7,12.55 7,12 C7,11.45 7.45,11 8,11 L11,11 L11,8 C11,7.45 11.45,7 12,7 C12.55,7 13,7.45 13,8 L13,11 L16,11 C16.55,11 17,11.45 17,12 C17,12.55 16.55,13 16,13 Z"
                              id="🔹Icon-Color"
                              fill={commonColor.deepPink}
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseListHorizontal);
