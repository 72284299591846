import React, { useState, useEffect } from "react";
import whatWeBuildThumb from "../../assets/images/whatWeBuildImage2.png";
import whatWeBuildThumbMini from "../../assets/images/whatWeBuildImage_mini.png";

import ReactPlayer from "react-player/lazy";

import "./styles.css";
import LandingLayout from "../../components/landing/landingLayout";
import { calculateHeight } from "../../components/landing/landingCard";
import { useWindowSize } from "../../utils/uiUtils";
import LearningStyleQuiz from "../learningStyleQuiz/learningStyleQuiz";

const videoSourceWhatWeBuilding = "https://youtu.be/AX_j5yMoNx4";

export default function WhatWeBuilding() {
  const [width, height] = useWindowSize();

  const [title, setTitle] = useState("");

  useEffect(() => {
    if (window.location.pathname.includes("what-we-are-building"))
      setTitle("What we are building");
  });

  return (
    <LandingLayout landingTitle={title}>
      <>
        {window.location.pathname.includes("what-we-are-building") ===
          false && <LearningStyleQuiz></LearningStyleQuiz>}

        {window.location.pathname.includes("what-we-are-building") && (
          <>
            <div className="w-full text-center mb-2 ">
              <p className="text-md">Press play to explore more</p>
            </div>
            <div className="w-full h-9/10 flex flex-column justify-around items-center flex-wrap ">
              <div
                className="h-full mx-6 video-player"
                style={{
                  boxShadow: `0 10px 25px 0 rgba(0, 0, 0, 0.6)`,
                  borderRadius: 25,
                  overflow: "hidden",
                  gridArea: `2 / 1 / 11 / 2`,
                  height: "76vh",
                  width: "70%",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  // position: "absolute",
                }}
              >
                <ReactPlayer
                  url={videoSourceWhatWeBuilding}
                  width={"100%"}
                  height={"100%"}
                  playing
                  muted
                  controls
                  light={
                    width >= 1024 ? whatWeBuildThumb : whatWeBuildThumbMini
                  }
                />
                <div
                  className="mobileVideoBanner"
                  style={{
                    width: "100%",
                    background: "white",
                    textAlign: "center",
                    zIndex: "10",
                    position: "absolute",
                    bottom: "0px",
                    fontSize: "10px",
                    padding: "5px",
                  }}
                >
                  For optimum view - either use full-screen or rotate your
                  phone.
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </LandingLayout>
  );
}

const calculateCardHeight = (size) => {
  let heightCalculated = calculateHeight(size);
  if (heightCalculated > 0) {
    return heightCalculated;
  }
  if (window.innerWidth >= 768) {
    // md
    heightCalculated = 450;
  } else if (window.innerWidth >= 640) {
    // sm
    heightCalculated = 450;
  } else if (window.innerWidth > 0 && window.innerWidth < 640) {
    heightCalculated = 0;
  }
  return heightCalculated;
};
