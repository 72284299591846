import React, { memo } from "react";
import Select from "./input/select";


const TimeWidget = ({timeValue, onChange}) => {
  var timeDataOpt = [
    { value: 0, label: "12:00 AM" },
    { value: 1, label: "01:00 AM" },
    { value: 2, label: "02:00 AM" },
    { value: 3, label: "03:00 AM" },
    { value: 4, label: "04:00 AM" },
    { value: 5, label: "05:00 AM" },
    { value: 6, label: "06:00 AM" },
    { value: 7, label: "07:00 AM" },
    { value: 8, label: "08:00 AM" },
    { value: 9, label: "09:00 AM" },
    { value: 10, label: "10:00 AM" },
    { value: 11, label: "11:00 AM" },
    { value: 12, label: "12:00 PM" },
    { value: 13, label: "01:00 PM" },
    { value: 14, label: "02:00 PM" },
    { value: 15, label: "03:00 PM" },
    { value: 16, label: "04:00 PM" },
    { value: 17, label: "05:00 PM" },
    { value: 18, label: "06:00 PM" },
    { value: 19, label: "07:00 PM" },
    { value: 20, label: "08:00 PM" },
    { value: 21, label: "09:00 PM" },
    { value: 22, label: "10:00 PM" },
    { value: 23, label: "11:00 PM" },
  ];

  return (
    <Select
      className="w-40"
      placeholder={"--:--"}
      value={
        timeValue
          ? timeDataOpt.find((f) => f.value == timeValue)
          : { label: "--:--", value: -1 }
      }
      menuPlacement="top"
      onChange={(item) => {
        var hours = item.value;
        console.log("selected time:", hours < 0 ? null : hours);
        // handleChange("language", value);
        if(onChange != null) { onChange(hours); }
      }}
      options={timeDataOpt}
    />
  );
};


export default TimeWidget;
