import React, { useState, useEffect } from "react";
import PlaceSearch from "../../common/placeSearch/placeSearch";
import MaskedField from "react-masked-field";
import { NavigateFunction, useNavigate } from "react-router-dom";

import moment from "moment";
import Checkbox from "../../common/input/checkbox";
import { connect } from "react-redux";
import UserActions from "../../../redux/user";
import { ReactComponent as LocationIcon } from "../../../assets/icons/009-placeholder.svg";
import commonColor from "../../../theme/commonColor";
import {
  TermsCheckbox,
  TermsLink,
  GDPR,
  PrivacyPolicy,
  PrivacyPolicyInculdingGDPR,
} from "../../common/consent";

function LocationInfo(props) {
  const navigate = useNavigate();

  const [isUSACitizen, setIsUSACitizen] = useState(false);
  const [isAcceptTerms, setIsAcceptTerms] = useState(false);
  const [isAcceptPrivacyPolicy, setIsAcceptPrivacyPolicy] = useState(false);
  const [isAcceptGDPR, setIsAcceptGDPR] = useState(false);
  const [isPlaceSelected, setIsPlaceSelected] = useState(false);
  const [schoolInfo, setSchoolInfo] = useState("");
  const [livingInfo, setLivingInfo] = useState("");

  // const [successText, setSuccessText] = useState()

  useEffect(() => {
    if (props.currentStep === 4) {
      props.setCanMoveOn(
        "locationInfo",
        isAcceptTerms &&
          isPlaceSelected &&
          isAcceptPrivacyPolicy &&
          (isUSACitizen || isAcceptGDPR)
      );
    }
  }, [
    isAcceptTerms,
    isPlaceSelected,
    isUSACitizen,
    isAcceptPrivacyPolicy,
    isAcceptGDPR,
    props,
  ]);

  useEffect(() => {
    console.log("PROPS", props);
    if (props.user && props.user.status === "active") {
      navigate("/dashboard");
    } else if (props.user && props.user.status === "activation-pending") {
      navigate("/activation");
    }
  }, [props.user]);

  if (props.currentStep !== 4) {
    return null;
  }

  return (
    <React.Fragment>
      <div className=" w-full h-full flex-col flex justify-around">
        <div className="w-full flex justify-center pt-8">
          <p className="text-center italic">Nearly done</p>
        </div>
        <div className=" w-full flex-col flex justify-center">
          <div className="w-full flex flex-col justify-between">
            {livingInfo != null && livingInfo != "" && (
              <div className="w-full flex justify-start">
                <div className="w-3/12"></div>
                <div className="w-7/12 px-2">
                  <div className="w-full flex italic text-xs">{livingInfo}</div>
                </div>
              </div>
            )}

            <div className="w-full flex justify-start mb-2">
              <div className=" flex flex-row justify-end items-center w-3/12">
                Your city & country
              </div>
              <div className="w-7/12 px-2 py-1">
                <PlaceSearch
                  cachedValue={livingInfo}
                  onSelect={(value) => {
                    console.log(value);

                    if (value) {
                      props.savePlace("livingPlace", value);
                      setIsPlaceSelected(true);
                      setLivingInfo(value);
                    } else {
                      setIsPlaceSelected(false);
                    }
                  }}
                  restrictToUSA={isUSACitizen}
                />
              </div>
            </div>
            <div className="w-full  flex justify-start">
              <div className=" flex flex-row justify-end items-center flex-wrap w-3/12">
                <span>Your school</span>{" "}
                <span
                  style={{
                    opacity: "0.3",
                  }}
                >
                  (Optional)
                </span>
              </div>
              <div className="w-7/12 px-2 py-1">
                {/* {isUSACitizen ? ( */}
                <PlaceSearch
                  cachedValue={schoolInfo && schoolInfo.name}
                  searchType="school"
                  onSelect={(value) => {
                    if (value) {
                      props.savePlace("schoolInfo", value);
                      //   setIsPlaceSelected(true);
                      setSchoolInfo(value);
                    }
                  }}
                  restrictToUSA={isUSACitizen}
                />
              </div>
            </div>
          </div>
          <div className="py-4">
            <div className="py-2 md:w-1/4 float-left"></div>
            <div className="px-2 w-full md:w-3/4 float-left lg:float-right flex justify-start">
              <div className="text-left">I have read and agree to the</div>
            </div>

            <div className="py-2 md:w-1/4 float-left"></div>
            <div className="px-2 w-full md:w-3/4 float-left lg:float-right flex justify-start">
              <div className="items-start pt-1">
                <TermsCheckbox
                  checked={isAcceptTerms}
                  onChange={(changeEvent) => {
                    setIsAcceptTerms(!isAcceptTerms);
                  }}
                />
              </div>
              <div className="text-left">
                <TermsLink />{" "}
              </div>
            </div>
            <div className="py-2 md:w-1/4 float-left"></div>
            <div className="px-2 w-full md:w-3/4 float-left lg:float-right flex justify-start">
              <TermsCheckbox
                checked={isAcceptPrivacyPolicy}
                onChange={(changeEvent) => {
                  setIsAcceptPrivacyPolicy(!isAcceptPrivacyPolicy);
                  setIsAcceptGDPR(!isAcceptPrivacyPolicy);
                }}
              />
              <div>
                {isUSACitizen && <PrivacyPolicy />}
                {!isUSACitizen && <PrivacyPolicyInculdingGDPR />}
              </div>
            </div>
            {props.accountType === "Parent" && (
              <>
                <div className="py-2 md:w-1/4 float-left"></div>
                <div className="px-2 w-full md:w-3/4 float-left lg:float-right flex justify-start">
                  <TermsCheckbox className="items-start md:items-center" />
                  <div className="text-left md:text-center ">
                    <b>Student under the age of 13 will use this account</b>
                  </div>
                </div>
              </>
            )}
            {/* {!isUSACitizen && (
              <>
                <div className="py-2 md:w-1/4 float-left"></div>
                <div className="px-2 w-full md:w-3/4 float-left lg:float-right flex justify-start">
                  <TermsCheckbox
                    checked={isAcceptGDPR}
                    onChange={(changeEvent) => {
                      setIsAcceptGDPR(!isAcceptGDPR);
                    }}
                  />
                  <div>
                    <GDPR />.{" "}
                  </div>
                </div>
              </>
            )} */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationInfo);
