import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import AmbassadorNavBar from "./ambassadorNavBar";
import { ambassadorCoupons, buyCoupons } from "../../services/api";
import LoaderModal from "../../components/common/modal/loaderModal";
import commonColor from "../../theme/commonColor";
import Swotbutton from "../../components/common/buttons/swotbutton";

const Coupons = () => {
    let navigate = useNavigate();

    const [coupons, setCoupons] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!localStorage.getItem("token")) {
            navigate("/ambassador");
        } else {
            getCoupons();
            // setToken(localStorage.getItem("token"));
        }
    }, []);

    const getCoupons = async () => {
        setIsLoading(true);
        const response = await ambassadorCoupons();
        setIsLoading(false);
        if (response?.ok && response.data) {
            setCoupons(response.data.data);
            console.log("Data received: ", response.data);
        }
    };

    const handleBuyCoupon = async () => {
        try {

            const apiRequestBody = {
                success_url: window.location.href + "?status=success",
                cancel_url: window.location.href + "?status=cancel",
            };
            const response = await buyCoupons(apiRequestBody);
            if (response?.ok && response.data) {
                let checkoutLink = response.data.data.checkout_url;
                window.location = checkoutLink;
            }


        } catch (error) {
            console.error('Error fetching affiliate code:', error);
        }
    };

    return (
        <div className="w-full h-screen flex flex-col items-center min-h-screen-ios gradient-background ">
            <AmbassadorNavBar />
            <div className="mb-2 w-full flex flex-row justify-between px-8">
                <div className="w-20 hidden md:block"></div>
                <div className="flex justify-center items-center text-white">
                    <h2>Coupons</h2>
                </div>
                <Swotbutton
                    text="Buy Coupons"
                    bgColor={commonColor.deepGreen}
                    onClick={handleBuyCoupon}
                />
            </div>
            <div className="w-full flex flex-col overflow-y-scroll px-8">
                <table className="w-full mt-8 border-collapse bg-gray-200 rounded-lg overflow-hidden shadow-lg">
                    <thead
                        className="text-white"
                        style={{
                            background: commonColor.deepPink,
                        }}
                    >
                        <tr>
                            <th className="p-4 text-start">Id</th>
                            <th className="p-4 text-start">Coupon</th>
                            <th className="p-4 text-start">Payment Id</th>
                            <th className="p-4 text-start">Status</th>
                            <th className="p-4 text-start">Limit</th>
                            <th className="p-4 text-start">Used By</th>
                        </tr>
                    </thead>
                    <tbody>
                        {coupons && coupons.length > 0 ? (
                            coupons.map((item, index) => (
                                <tr
                                    key={index}
                                    className={index % 2 === 0 ? "bg-white" : "bg-gray-100"}
                                >
                                    <td className="p-4 border-t border-gray-300">{item.id}</td>
                                    <td className="p-4 border-t border-gray-300">{item.coupon}</td>
                                    <td className="p-4 border-t border-gray-300">{item.payment_id}</td>
                                    <td className="p-4 border-t border-gray-300">{item.status}</td>
                                    <td className="p-4 border-t border-gray-300">{item.total_limit}</td>
                                    <td className="p-4 border-t border-gray-300">{item.used_by}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colspan="4">
                                    <div className="w-full text-black flex justify-center">
                                        No data found
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <LoaderModal isOpen={isLoading} />
        </div>
    );
};

export default Coupons;
