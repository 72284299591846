import React from "react";
// import Startup from "./screens/startup/startup";
import createStore from "./redux";
import { Provider } from "react-redux";
import ReactGa from 'react-ga';
import { useEffect } from "react";
import { HelmetProvider } from 'react-helmet-async';
import AppRouter from "./screens/startup/appRouter";
import { BrowserRouter, useLocation, useNavigate } from "react-router-dom";


// create our store
export const store = createStore();
//6tylDxaJNVVAKbjoZ6VbnxZQXhx2const stripePromise = loadStripe(StripeTestKey);
// const ConnectedRoot = connect(
//   (state) => ({
//     state: state.reducer
//   }),
//   (dispatch) => ({
//     actions: bindActionCreators(screenActions, dispatch)
//   })
// )(Startup);

function App() {
   

  useEffect(() => {
    ReactGa.initialize("UA-199520615-1");
    //ReactGa.initialize("UA-249890856-1");
    ReactGa.pageview(window.location.pathname + window.location.search);
  },[]);


  const helmetContext = {};

      // anywhere in the react app (inside or outside components)

      // history.navigate = useNavigate();
      // history.location = useLocation();

  return (
    // <BrowserRouter>
    <HelmetProvider context={helmetContext}>
      <Provider store={store}>
        {/* <HelmetMetaData /> */}
        {/* <Startup /> */}
        {/* <CustomBrowserRouter basename={undefined}> */}
        <AppRouter />
        {/* </CustomBrowserRouter> */}
        {/* <MyReactRoutes /> */}
      </Provider>
     </HelmetProvider>
    //  </BrowserRouter>
  );
}

// allow reactotron overlay for fast design in dev mode
// export default DebugConfig.useReactotron ? console.tron.overlay(App) : App
export default App;
