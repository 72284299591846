import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

// The .js references are necessary for requireJs to work in the browser.
import {
  IContentService,
  IContentListEntry,
  IContentWithCourseEntry,
  IContentGroup,
} from "../services/ContentService";
import ContentListEntryComponent from "./ContentListEntryComponent";
import Modal from "../../components/common/modal/modal";
import ContentCourseListComponent from "./ContentCourseListComponent";
import {
  assignH5pToCourse,
  deleteH5pContent,
  getCourseListOfTutor,
  getH5pContentByTutor,
  removeH5pFromCourse,
} from "../../services/api";
import UserModel from "../../redux/redux-models/reduxUser";
import LoaderModal from "../../components/common/modal/loaderModal";
import Swal from "sweetalert2";

import CustomFadeLoader from "../../components/common/CustomFadeLoader";
import commonColor from "../../theme/commonColor";

export default class ContentList extends React.Component<{
  user: UserModel;
  contentService: IContentService;
  onPlay: (content: IContentListEntry) => void;
  onEdit: (content: IContentListEntry) => void;
}> {
  constructor(props: { 
    user: UserModel; 
    contentService: IContentService;
    onPlay: (content: IContentListEntry) => void;
    onEdit: (content: IContentListEntry) => void;
  }) {
    super(props);

    this.state = {
      contentList: [],
      groupContentList: [],
      courses: [],
      showingCoursePopup: false,
      isLoading: false,
      isDataUpdating: true,
    };
    this.contentService = props.contentService;
  }

  public state: {
    contentList: IContentWithCourseEntry[];
    groupContentList: IContentGroup[];
    courses: [];
    showingCoursePopup: boolean;
    contentManaging?: IContentGroup;
    isLoading: boolean;
    isDataUpdating: boolean;
  };

  protected contentService: IContentService;
  /**
   * Keeps track of newly created content to assign a key
   * @memberof ContentList
   */
  protected newCounter = 0;

  public async componentDidMount(): Promise<void> {
    this.updateList();
    // this.getContentsFromLocalServer();
    this.getAllCourses();
  }

  public render(): React.ReactNode {
    return (
      <div className="w-full p-2">
        {this.state.groupContentList.length === 0 && this.state.isDataUpdating && (
          <div className="w-full flex justify-center items-center">
            <CustomFadeLoader
              radius="2"
              loading={true}
              color={commonColor.deepGreen}
            />
          </div>
        )}

        {this.state.groupContentList.length === 0 &&
          !this.state.isDataUpdating && (
            <div className="w-full flex justify-center items-center">
              You have not created content yet, once you have created content, you will be able to assign, manage and view your created content here.
            </div>
          )}

        {this.state.groupContentList.length > 0 && (
          <div className="w-full flex flex-col">
            {this.state.groupContentList.map((data) => (
              <ContentListEntryComponent
                contentService={this.contentService}
                data={data}
                key={data.contentId}
                onPlay={this.props.onPlay}
                onEdit={this.props.onEdit}
                onDiscard={(value) => this.onDiscard(value)}
                onDelete={(value) => this.onDelete(value)}
                onSaved={(newData, oldData) => this.onSaved(newData, oldData)}
                generateDownloadLink={this.contentService.generateDownloadLink}
                manageCourse={(contentId) => {
                  this.setState({
                    contentManaging: data,
                    showingCoursePopup: true,
                  });
                }}
              />
            ))}
          </div>
        )}

        {this.coursesPopup()}
        <LoaderModal
          isOpen={this.state.isLoading}
          message={""}
          onRequestClose={() => {
            this.setState({ isLoading: false });
          }}
        />
      </div>
    );
  }

  protected coursesPopup = () => {
    return (
      <Modal
        isOpen={this.state.showingCoursePopup}
        onRequestClose={() => {
          this.setState({ showingCoursePopup: false });
        }}
        isCloseButtonShown={true}
        setMaxSize={false}
      >
        <div className="w-70v sm:w-70v md:w-70v lg:w-50v xl:w-40v h-90v">
          <ContentCourseListComponent
            content={this.state.contentManaging!}
            courses={this.state.courses}
            assignToCourse={(contentId, courseId) => {
              this.setState({ showingCoursePopup: false });
              this.assignCourse({ contentId: contentId, courseId: courseId });
            }}
            removeFromCourse={(itemId) => {
              this.setState({ showingCoursePopup: false });
              this.removeCourse(itemId);
            }}
          />
        </div>
      </Modal>
    );
  };

  getContentsFromLocalServer = async () => {
    const sContents = await this.contentService.list();
    const contents: IContentWithCourseEntry[] = sContents.map(
      (item) => {
        const cc: IContentWithCourseEntry = {
          id: 0,
          course_id: 0,
          content: item
        };
        return cc;
      }
    );
  
    const groupedData = contents.reduce((group, product) => {
      group[product.content.contentId] =
        group[product.content.contentId] ?? [];
      group[product.content.contentId].push(product);
      return group;
    }, {});

    const groupContents: IContentGroup[] = Object.keys(groupedData).map(
      (gId) => {
        const item = groupedData[gId];
        return { contentId: gId, contents: item };
      }
    );

    this.setState({
      contentList: contents ?? [],
      groupContentList: groupContents ?? [],
      // componentListKey: Date.now(),
    });
  };

  updateList = async () => {
    this.setState({ isDataUpdating: true });
    var result = await getH5pContentByTutor(this.props.user.uid);
    this.setState({ isDataUpdating: false });
    if ((result?.data?.status ?? "") === "SUCCESS") {
      const data = result?.data?.data ?? [];
      var contents: IContentWithCourseEntry[] = data.map((c) => {
        const d: IContentListEntry = {
          contentId: c.content_id,
          mainLibrary: c.library_name,
          title: c.title,
        };
        const cc: IContentWithCourseEntry = {
          id: c.id,
          content: d,
          course_id: c.course_id,
          course_name_short: c.course_name_short,
          course_name_full: c.course_name_full,
          created_at: c.created_at,
        };
        return cc;
      });

      const groupedData = contents.reduce((group, product) => {
        group[product.content.contentId] =
          group[product.content.contentId] ?? [];
        group[product.content.contentId].push(product);
        return group;
      }, {});

      const groupContents: IContentGroup[] = Object.keys(groupedData).map(
        (gId) => {
          const item = groupedData[gId];
          return { contentId: gId, contents: item };
        }
      );

      this.setState({
        contentList: contents ?? [],
        groupContentList: groupContents ?? [],
        // componentListKey: Date.now(),
      });
    }
  };

  getAllCourses = async () => {
    const result = await getCourseListOfTutor(this.props.user.uid);
    if (result) {
      console.log("CourseList - useEffect - got courses", result.data);
      // TODO: studentsEnroled: "0", is also coming back from the api. do we want to show it?
      this.setState({ courses: result?.data?.courses ?? [] });
    }
  };

  assignCourse = async ({
    contentId,
    courseId,
  }: {
    contentId: string;
    courseId: number;
  }) => {
    this.setState({ isLoading: true });
    const result = await assignH5pToCourse(contentId, courseId);
    this.setState({ isLoading: false });
    if (result?.data?.status === "SUCCESS") {
      Swal.fire({
        title: "Success!",
        text: "Successfully linked to the course.",
        icon: "success",
      });
      this.updateList();
    } else {
      Swal.fire({
        title: "Failed!",
        text: "An error occured.",
        icon: "error",
      });
    }
  };

  removeCourse = async (itemId) => {
    this.setState({ isLoading: true });
    const result = await removeH5pFromCourse(itemId);
    this.setState({ isLoading: false });
    if (result?.data?.status === "SUCCESS") {
      Swal.fire({
        title: "Success!",
        text: "Successfully de-linked to the course.",
        icon: "success",
      });
      this.updateList();
    } else {
      Swal.fire({
        title: "Failed!",
        text: "An error occured.",
        icon: "error",
      });
    }
  };

  protected new() {
    this.setState({
      contentList: [
        {
          contentId: "new",
          mainLibrary: undefined,
          title: "New H5P",
          originalNewKey: `new-${this.newCounter++}`,
        },
        ...this.state.contentList,
      ],
    });
  }

  protected onDiscard(content) {
    this.setState({
      contentList: this.state.contentList.filter((c) => c !== content),
    });
  }

  protected async onDelete(content: IContentListEntry) {
    if (!content.contentId) {
      return;
    }
    try {
      const result = await deleteH5pContent(content.contentId);
      if (result?.data?.status === "SUCCESS") {
        Swal.fire({
          title: "Success!",
          text: "Successfully deleted the content.",
          icon: "success",
        });
        this.updateList();
      } else {
        Swal.fire({
          title: "Failed!",
          text: "An error occured.",
          icon: "error",
        });
      }
      // await this.contentService.delete(content.contentId);
      // this.setState({
      //   contentList: this.state.contentList.filter(
      //     (c) => c.content !== content
      //   ),
      // });
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
    }
  }

  protected async onSaved(
    newData: IContentListEntry,
    oldData?: IContentListEntry
  ) {
    this.setState({
      contentList: this.state.contentList.map((c) => {
        if (c.content.contentId === oldData?.contentId) {
          c.content.title = newData.title;
          c.content.originalNewKey = newData.originalNewKey;
        }
        return c;
      }),
    });
  }
}
