import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import moment from "moment";
import UserActions from "../../redux/user";

// import NoReminders from "../../assets/images/no-reminders.png";
import NoReminders from "../../assets/images/reminder-human.png";
import NoRemindersGIF from "../../assets/images/reminder-human.gif";
import { STRIPE_REG_STATUSES } from "../../config/constants";
import { getCourseListOfTutor } from "../../services/api";
import contentful from "../../config/contentful";
import {
  getTutorStripeStatus,
  stripeRegisterAccount,
} from "../../services/paymentsManagement";
import LoadarModal from "../common/modal/loaderModal";
import { updateUser } from "../../services/userManagement";
import { MessageType } from "../../chat/models/chat_models";
import commonColor from "../../theme/commonColor";

const renderReminder = (reminder, user, registerStripe, navigate) => {
  if (!reminder) return;

  let reminderText = "Heads up! Check your classes and see what's up.";
  if (reminder.type === "session_24") {
    reminderText = `Hey! Just a reminder that you have ${reminder.shortName} tomorrow! 
    It’s a good time to brush up on what you have learned so that you can ask any questions 
    that you might have!`;
  } else if (reminder.type === "session_2") {
    reminderText = `Hey! Your class ${reminder.shortName} will start in about 2 hours! 
    Have a snack, drink some water and organize your notes! 
    Enjoy your class!`;
  } else if (reminder.type === "unread_message") {
    reminderText = `${reminder.message}`;
  } else if (
    reminder.type === "stripe_registration" ||
    reminder.type === "calendar_reminder" ||
    reminder.type === "contentful_notification"
  ) {
    reminderText = `${reminder.shortName} ${
      reminder.description ? `- ${reminder.description}` : ""
    }`;
  }
  return (
    <button
      key={reminder.shortName}
      className={`p-2 mx-4 min-h-12 text-sm border-2 rounded-2xl  mt-4 md:mt-0
      focus:outline-none bg-white mb-4 ${
        reminder.type === "contentful_notification" && "cursor-default"
      }`}
      style={{
        borderColor:
          reminder.type === "unread_message" ? commonColor.deepPink : "white",
      }}
      onClick={() => {
        console.log("reminder clicked", reminder);
        if (reminder.type === "calendar_reminder") {
          if (user?.accountType === "Tutor") {
            navigate("/calendar");
          }
        } else if (reminder.type === "stripe_registration") {
          registerStripe && registerStripe();
        } else if (reminder.type === "contentful_notification") {
          // registerStripe && registerStripe();
        } else if (reminder.type === "unread_message") {
          //TODO: goto inbox
          navigate("/profile/messages");
        } else {
          reminder.courseId && reminder.courseId > 0
            ? // navigate("courses", {
              //     state: {
              //       currentView: "courseDetails",
              //       courseId: reminder.courseId,
              //     },
              //   })
              navigate(`/courses/${reminder.courseId}`)
            : navigate("/courses");
        }
      }}
    >
      {reminderText}
    </button>
  );
};

const Reminders = ({
  reminders,
  calendarReminders,
  unreadMessages,
  user,
  saveUser,
}) => {
  const navigate = useNavigate();

  const [hover, setHover] = useState(false);
  const [myReminders, setMyReminders] = useState([]);
  const [contentfulNotifications, setContentfulNotifications] = useState([]);
  const [freeCoursesCount, setFreeCoursesCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [stripeLink, setStripeLink] = useState(null);

  useEffect(() => {
    console.log("reminders refreshed", reminders);
    let r = [];

    if (contentfulNotifications && contentfulNotifications.length > 0) {
      r = [...contentfulNotifications];
    }

    if (reminders && reminders.length > 0) {
      r = [...r, ...reminders];
    }
    if (calendarReminders && calendarReminders.length > 0) {
      r = [...r, ...calendarReminders];
    }

    var messageReminders = [];
    if (unreadMessages && unreadMessages.length > 0) {
      for (let index = 0; index < unreadMessages.length; index++) {
        const m = unreadMessages[index];
        if (m.recentMessage.type === MessageType.TEXT) {
          messageReminders.push({
            type: "unread_message",
            message: m.recentMessage.messageText,
          });
        }
      }
    }

    if (messageReminders.length > 0) {
      r = [...r, ...messageReminders];
    }

   /* if (
      !user?.ngoUser &&
      user?.accountType === "Tutor" &&
      user.stripeStatus !== STRIPE_REG_STATUSES.APPROVED
    ) {
      let notif = { type: "stripe_registration", shortName: "Missing Stripe" };
      if (user.stripeStatus === STRIPE_REG_STATUSES.INACTIVE) {
        notif["description"] =
          "Some information is missing in your stripe account. You need to update your Stripe account.";
        notif["shortName"] = "Missing Stripe Informaiton";
      }
      if (user.stripeStatus === STRIPE_REG_STATUSES.INCOMPLETE) {
        notif["description"] =
          "Your stripe account setup is incomplete. You need to complete your Stripe account.";
        notif["shortName"] = "Missing Stripe Informaiton";
      } else if (freeCoursesCount === 1) {
        notif["description"] =
          "You can create last free course, then you will need to create a Stripe account.";
      } else if (freeCoursesCount === 2) {
        notif["description"] =
          "You cannot create more than two free courses, you need to create a Stripe account.";
      } else {
        notif["description"] = "You are not yet registered with Stripe.";
      }
      r = [...[notif], ...r];
    } */
    setMyReminders(r);
  }, [
    reminders,
    calendarReminders,
    unreadMessages,
    contentfulNotifications,
    freeCoursesCount,
  ]);

  useEffect(() => {
    getFreeCoursesCount();
    getContent(user.accountType);
    if (
      user.accountType === "Tutor" &&
      user.stripeStatus !== STRIPE_REG_STATUSES.APPROVED
    ) {
      checkStatus();
    }
  }, [user.uid]);

  const getFreeCoursesCount = async () => {
    const result = await getCourseListOfTutor(user.uid);

    if (result?.data?.courses) {
      setFreeCoursesCount(
        result.data.courses.filter((course) => course.type === "free").length
      );
    }
  };

  const getContent = async (role) => {
    try {
      const response = await contentful.getEntries({
        content_type: "notification",
        "fields.role.sys.contentType.sys.id": "role",
        "fields.role.fields.name[match]": role,
      });
      console.log("notification", response);
      const items = response?.items;
      if (items && items.length > 0) {
        const notifs = items.reduce((result, item) => {
          const d = result;
          if (item.fields?.title || item.fields?.body) {
            d.push({
              type: "contentful_notification",
              shortName: item.fields?.title,
              description: item.fields?.body,
            });
          }
          return d;
        }, []);

        if (
          user.accountType.toLowerCase() === "tutor" &&
          user.setupStatus === "approved"
        ) {
          if (!user.welcomeMsgShownAt) {
            const updatedUser = {
              ...user,
              welcomeMsgShownAt: moment().unix(),
            };
            // saveUser(updatedUser)
            updateUser(
              {
                ...user,
                uid: user.uid,
                welcomeMsgShownAt: updatedUser.welcomeMsgShownAt,
              },
              true
            );
          } else {
            const diff = moment().diff(
              moment.unix(user.welcomeMsgShownAt),
              "hours"
            );
            const index = notifs.findIndex(
              (checkValue) =>
                checkValue.shortName.toLowerCase() === "welcome tutor"
            );
            if (diff >= 1 && index > -1) {
              notifs.splice(index, 1);
            }
          }
        }
        setContentfulNotifications(notifs);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const checkStatus = async () => {
    try {
      const result = await getTutorStripeStatus(user.uid);
      if (result?.data?.status === "ERROR") {
      } else {
        if (result?.data?.data) {
          if (result.data.data.login_link) {
            setStripeLink(result.data.data.login_link);
          }
        }
      }
    } catch (e) {}
  };

  const registerStripe = async () => {
    if (user?.accountType === "Tutor") {
      //Stripe
      if (user.stripeStatus === STRIPE_REG_STATUSES.INACTIVE) {
        if (stripeLink) {
          window.open(stripeLink, "_blank", "noopener,noreferrer");
        }
      } else {
        navigate("/profile");
        // setLoading(true);
        // stripeRegisterAccount(user.uid).finally(() => setLoading(false));
      }
    }
  };

  const handleHover = (value) => {
    setHover(value);
  };

  const imageSrc = hover ? NoRemindersGIF : NoReminders;

  return (
    <div
      className="w-full h-full flex flex-col justify-start overflow-y-scroll max-h-96 "
      onMouseEnter={handleHover.bind(null, true)}
      onMouseLeave={handleHover.bind(null, false)}
    >
      {myReminders && myReminders.length > 0 ? (
        <div className="w-full flex flex-col justify-start">
          {myReminders.map((rem) => {
            return renderReminder(rem, user, registerStripe, navigate);
          })}
        </div>
      ) : (
        <div className="w-full h-full flex flex-col justify-center bg-white rounded-2xl">
          <div className="w-full text-md sm:h-3/10 p-8">
            Use your calendar to add important events and leave it to l3arn to
            remind you.
          </div>
          <div className="sm:h-7/10 flex justify-end">
            <img
              src={imageSrc}
              alt="RocketPeople"
              className="  bg-transparent md:py-5 py-0"
              style={{
                height: "100%",
                display: "block",
              }}
            />
          </div>
        </div>
      )}
      <LoadarModal isOpen={loading} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  reminders: state.reminders.reminders,
  calendarReminders: state.calendarReminders.reminders,
  unreadMessages: state.unreadMessages.unreadMessages,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  // saveReminders: (reminders) =>
  //   dispatch(RemindersActions.saveRemindersRequest(reminders)),
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reminders);
