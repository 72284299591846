import React from "react";

const Checkbox = ({ label, isSelected, onCheckboxChange, textOnLeft = false }) => {
  const getLabel=(position)=> {
   if(textOnLeft){
      return position === "left" ? <div className="text-sm align-text-top">{label}</div> : ""
   } else {
    return  position === "left" ? "" : <div className="text-sm align-text-top">{label}</div>
   }
  }

  return (
  <div className="w-full flex justify-start items-center">
    
    {getLabel("left")}
    
    <input
      type="checkbox"
      name={label}
      checked={isSelected}
      onChange={onCheckboxChange}
      style={{
        left: 0,
        width: "1rem",
        marginRight: "0.5rem",
        marginBottom: 0

      }}
    />
        {getLabel("right")}

  </div>
);
    }

export default Checkbox;
