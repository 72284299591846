import React, { useEffect } from "react";

import commonColor from "../../../theme/commonColor";

const FaqListitem = ({ text, index, selected, onClick, whiteCell = false }) => {
  useEffect(() => { }, [selected]);

  const selectMe = () => {
    onClick(index);
  };
  return (
    <button
      className={`w-full flex justify-center items-center p-4 my-2 rounded-3xl shadow-2xl focus:outline-none ${selected === index ? "lg:ml-0" : "lg:ml-4"}`}
      style={{
        color: `${whiteCell ? (selected === index ? "white" : "black") : (selected === index ? "white" : "white")}`,
        backgroundColor: `${whiteCell ? (selected === index ? commonColor.sourCherry : "white") : (selected === index ? commonColor.sourCherry : commonColor.deepGreen)
          }`,
      }}
      onClick={() => selectMe()}
    >
      <div className="text-sm">{text}</div>
    </button>
  );
};

export default FaqListitem;
