import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  getContentTypeOptions,
  findTutors,
  getFeaturedTutors,
  searchTutorsApi,
} from "../../services/api";

import Swotbutton from "../../components/common/buttons/swotbutton";
import knotSm from "../../assets/images/learn-small.png";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { css } from "@emotion/react";
import FooterView from "../../components/common/footerView";

import CustomFadeLoader from "../../components/common/CustomFadeLoader";
import EmptyNewsMessage from "../../components/tutor/tools/emptyNewsMessage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Masonry from "react-masonry-css";
import commonColor from "../../theme/commonColor";
import LandingLayout from "../../components/landing/landingLayout";
import learnlogo from "../../assets/images/learn-logo-colour.svg";
import Button from "../newLanding/components/landingpageButton";
import searchTutors from "./searchTutors";

const override = css`
  display: block;
  margin: 2rem auto;
  border-color: white;
`;

const SearchTutorsOpen = ({ user }) => {
  const navigate = useNavigate();

  let [loading, setLoading] = useState(true);

  const breakpointColumnsObj = {
    default: 4,
    1900: 4,
    1400: 3,
    900: 2,
    768: 1,
  };

  const [courseName, setCourseName] = useState();
  // const [tutorName, setTutorName] = useState();
  const [matchingTutors, setMatchingTutors] = useState([]);
  const [featuredTutors, setFeaturedTutors] = useState([]);
  const [recentTutors, setRecentTutors] = useState([]);
  const [courseConduct, setCourseConduct] = useState([]);
  const [searchClicked, setSearchClicked] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const tutors = await fetchFeaturedTutors();
      setLoading(false);
      if (tutors) {
        setFeaturedTutors(tutors.featured ? tutors.featured : []);
        setRecentTutors(tutors.recent ? tutors.recent : []);
      }
    };
    getData();
  }, []);

  const fetchFeaturedTutors = async () => {
    const data = await getFeaturedTutors();
    const tutors = data?.data?.data;
    return tutors;
  };

  const displayContents = (tutors) => {
    return (
      <div className="xl:px-5 w-full h-full flex flex-col justify-start  overflow-y-scroll">
        <div className="w-full">
          {/* masonry */}
          <div
            className="w-full h-full lg:px-5 overflow-y-scroll flex flex-col justify-start"
            style={{
              width: "100%",
            }}
          >
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {/* array of JSX items */}
              {tutors.map((tut, tidx, bgImage) => {
                console.log("map: ", tut, tidx);

                return <Article key={tut.firebaseId} tutor={tut} />;
              })}
            </Masonry>
          </div>
        </div>
      </div>
    );
  };

  const searchBody = () => (
    // <LandingLayout landingTitle="Search Tutors" hideMenu={true}>
    <div className="w-full h-full flex flex-col justify-start">
      <div className="w-full h-full flex flex-col justify-start overflow-y-scroll px-4">
        <div
          className="bg-white mt-8 p-5 w-full max-w-screen-sm flex flex-col justify-start self-center"
          style={{
            boxShadow: `0 10px 25px 0 rgba(0, 0, 0, 0.6)`,
            borderRadius: 25,
          }}
        >
          <div className="w-full flex flex-col items-center justify-start">
            <div className="w-full flex flex-col justify-around">
              <div className="text-center mb-2">
                Find the tutor that matches your needs
              </div>

              <div className="flex flex-col justify-start items-center p-2">
                <div className="w-full flex flex-row items-center pb-1.5 justify-between">
                  <div className="w-2/3 flex flex-row justify-start px-2">
                    <input
                      className="w-full bg-white border-[1px] appearance-none border-gray-300 focus:outline-none focus:border-opacity-100 py-2 px-2 text-sm"
                      id="coursename"
                      name="coursename"
                      type="text"
                      placeholder={" Tutor or Course name"}
                      value={courseName ?? ""}
                      onChange={(event) => setCourseName(event.target.value)}
                    />
                  </div>
                  <div className="w-fit flex justify-end">
                    <Swotbutton
                      className="min-w-24"
                      text={"Search"}
                      shadow={true}
                      onClick={async () => {
                        if (courseName && courseName?.trim() !== "") {
                          console.log("Search tutors!", courseName);
                          setLoading(true);
                          let response = await searchTutorsApi(
                            courseName?.trim()
                          );
                          setLoading(false);
                          console.log(
                            "getDataForContentType - response",
                            response
                          );
                          if (response && response.ok) {
                            setSearchClicked(true);
                            const fetchedTutors = response.data?.tutors;
                            setMatchingTutors(fetchedTutors);
                          } else {
                            return null;
                          }
                        } else {
                          return null;
                        }
                      }}
                    />{" "}
                    {searchClicked && (
                      <Swotbutton
                        style={{
                          marginLeft: "10px",
                        }}
                        bgColor="gray"
                        text={"Clear"}
                        onClick={() => {
                          setSearchClicked(false);
                          setMatchingTutors([]);
                          setCourseName("");
                          setCourseConduct([]);
                        }}
                      />
                    )}
                  </div>
                </div>

                {/* <div className="w-full flex flex-row justify-center pb-1.5">
                    <div className="text-sm w-1/3 flex flex-row items-center">
                      {"Tutor Name"}
                    </div>
                    <div className="w-2/3 flex flex-row justify-start px-2">
                      <input
                        className="w-full bg-white border-[1px] appearance-none border-gray-300 focus:outline-none focus:border-opacity-100 py-2 px-2 text-sm"
                        id="tutorname"
                        name="tutorname"
                        type="text"
                        placeholder={"Tutor name"}
                        value={tutorName ?? ""}
                        onChange={(event) => setTutorName(event.target.value)}
                      />
                    </div>
                  </div> */}
              </div>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="w-full flex justify-center items-center mt-4">
            <CustomFadeLoader
              loading={loading}
              color={commonColor.deepGreen}
              textColor={commonColor.deepGreen}
            />
          </div>
        ) : (
          <div className="w-full flex flex-col justify-start items-start p-2">
            {!matchingTutors || matchingTutors.length === 0 ? (
              <div className="w-full flex flex-col justify-start items-start p-2">
                {searchClicked ? (
                  <div className="w-full flex flex-col justify-start items-center">
                    <div
                      className="rounded overflow-hidden shadow-lg bg-white w-full max-w-screen-sm flex flex-col justify-start self-center"
                      style={{
                        boxShadow: `0 10px 25px 0 rgba(0, 0, 0, 0.6)`,
                        borderRadius: 25,
                      }}
                    >
                      <EmptyNewsMessage />
                    </div>
                  </div>
                ) : (
                  // displayFeaturedTutors()
                  displayContents(recentTutors)
                )}
              </div>
            ) : (
              displayContents(matchingTutors)
            )}
          </div>
        )}
        <FooterView textColorClass={"text-black"} />
      </div>
    </div>
  );
  // </LandingLayout>

  return (
    <div className="">
      <div class="text-gray-600 body-font border-b-2 border-b-pink-800 w-11/12 mx-auto">
        <div class="flex flex-wrap p-5 flex-col md:flex-row items-center justify-between w-full">
          <a href="/">
            {/* <img className="w-40 h-auto" src={swotknotlogo} alt="l3arn-logo" /> */}
            <img className="w-40 h-auto" src={learnlogo} alt="l3arn-logo" />
          </a>
          <p className="text-gray-500 text-3xl">Our Tutors</p>
          <Button link="/sign-in" />
        </div>
      </div>

      {/* actual blogs */}
      <div className="w-full h-full flex flex-wrap justify-around  pl-1 max-w-screen-2k">
        {searchBody()}
      </div>
    </div>
  );
};

const Article = ({ tutor }) => {
  const articleRef = useRef(null);
  let navigate = useNavigate();
  if (tutor.relatedCourses[0].videoThumb == "undefined") {
    tutor.relatedCourses[0].videoThumb = null;
  }

  useEffect(() => {
    const article = articleRef.current;
    const handleMove = (e) => {
      const { x, y, width, height } = article.getBoundingClientRect();
      const cx = x + width / 2;
      const cy = y + height / 2;
      const { pageX, pageY } = e;

      const dx = (cx - pageX) / (width / 2);
      const dy = (cy - pageY) / (height / 2);

      const maxRotation = 8; // Maximum rotation angle in degrees
      const rotationMultiplier = 8; // Adjust this value for more/less rotation

      const rotateX = Math.max(
        Math.min(rotationMultiplier * dy * -1, maxRotation),
        -maxRotation
      );
      const rotateY = Math.max(
        Math.min(rotationMultiplier * dx, maxRotation),
        -maxRotation
      );

      article.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
    };

    const handleOut = () => {
      article.style.transform = "initial";
    };

    article.addEventListener("mousemove", handleMove);
    article.addEventListener("mouseout", handleOut);

    return () => {
      article.removeEventListener("mousemove", handleMove);
      article.removeEventListener("mouseout", handleOut);
    };
  }, []);

  const truncateSurname = (name) => {
    const names = name.split(" ");
    if (names.length <= 1) return name; // return as-is if there's no surname

    const firstName = names[0];
    const surname = names[1];

    const truncatedSurname =
      surname.length > 5 ? surname.slice(0, 5) + "…" : surname;

    return `${firstName} ${truncatedSurname}`;
  };
  const truncateCourseName = (course_fullname) => {
    if (course_fullname.length <= 15) return course_fullname; // return as-is if the name is within the limit

    return `${course_fullname.slice(0, 32)}...`; // truncate and add ellipsis
  };

  return (
    <div
      className="w-full flex justify-center items-center pt-7 mt-20"
      onClick={() => {
        if (
          tutor.relatedCourses &&
          tutor.relatedCourses.length > 0 &&
          tutor.relatedCourses[0].courseId
        ) {
          navigate(`/courses/${tutor.relatedCourses[0].courseId}`);
        } else {
        }
      }}
    >
      <div
        className="card_article flex flex-col align-center border-2 m-2 card_shadow"
        ref={articleRef}
      >
        <figure>
          <img
            src={tutor.photoUrl ? tutor.photoUrl : knotSm}
            loading="lazy"
            alt={tutor.username ? tutor.username : "tutor UserName Image"}
            className=""
          />
        </figure>
        <div>
          <span className="px-2 text-base pt-2 mb-2 block">
            <span className="font-semibold">Tutor Name: </span> <br />
            {tutor.name ? truncateSurname(tutor.name) : ""}
          </span>

          <span className="px-2 text-base mb-2 block">
            <span className="font-semibold ellipsis"> </span>
            <span className="courseNameWrap">
              {tutor.relatedCourses && tutor.relatedCourses.length > 0
                ? truncateCourseName(tutor.relatedCourses[0].courseFullName)
                : ""}
              <br />
              <b>
                {tutor.relatedCourses && tutor.relatedCourses.length > 0
                  ? " (" + tutor.relatedCourses[0].courseShortName + ")"
                  : ""}
              </b>
            </span>
          </span>

          <span className="px-2 text-base mb-2 block">
            <span className="font-semibold">Hourly Rate: </span> <br />
            {tutor.relatedCourses && tutor.relatedCourses.length > 0
              ? tutor.relatedCourses[0].rate != "0"
                ? "USD $" + tutor.relatedCourses[0].rate + " / hour"
                : "free"
              : ""}
          </span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SearchTutorsOpen);
