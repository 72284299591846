export const EventType = {
  bookedCourseSession: "bookedCourseSession",
  tutorAvailabilitySlot: "tutorAvailabilitySlot",
  deleteTutorAvailabilitySlot: "deleteTutorAvailabilitySlot",
  courseSessionBookedByOthers: "courseSessionBookedByOthers",
  groupCourseSession: "groupCourseSession",
  webinar: "webinar",
  reminder: "reminder"
};

export const ToolsMenuItem = {
  TechnicalTools: "Technical Tools",
  Libraries: "Libraries",
  Videos: "Videos",
  HowTo: "How to",
  NewsFeed: "News feed",
  StudyHelp: "Study help",
};

export const ACCOUNT_TYPES = {
  ADMIN: "Admin",
  TUTOR: "Tutor",
  STUDENT: "Student",
  SWOT_BUDDY: "SwotBuddy",
  SWOT_ADMIN: "SwotAdmin",
  GUEST_TUTOR: "GuestTutor",
  GUEST_STUDENT: "GuestStudent"
};

export const STRIPE_REG_STATUSES = {
  NONE: "none",
  PENDING: "pending",
  INACTIVE: "inactive", //stripe account needs some more info to become active
  INCOMPLETE: "incomplete", //stripe acocunt creation process is stopped meanwhile and not completed
  APPROVED: "approved",

};

export const ROUTES = {
  DASHBOARD: "dashboard",
};

export default {
  EventType: EventType,
};

export const ReleaseMode = {
  DEV: "dev",
  QA: "qa",
  PROD: "prod",
  BETA: "beta"
};

export const CURRENT_RELEASE_MODE = ReleaseMode.PROD;

export const StripeTestKey = 'pk_test_51OKHePLZBWtM85EJ9COGmdPoGDk0gFM2MXjmtfeSaEnXficbZgZ9Uw6I7KPvNSPjSMUBsMyYiqNFhFauGrthqXO700CplBr6KH';

export const SHOW_H5P = false;