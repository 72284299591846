const generateNowText = [
  {
    option: "Generate now",
    prompt: "Generate the assignment",
  },
];
const intentSelect = [
  {
    option: "Generate something related",
    prompt: "Generated something related",
    answer:
      "Do you want to emphasize certain skills like problem-solving or critical thinking?",
    response: [
      {
        option: "yes",
        prompt: "yes",
        answer: "What is the type of assignment you would like?",
        response: [
          {
            option: "Argumentative essay",
            prompt:
              "Generate an Argumentative essay, using all the input provided.",
            answer: "Finally provide any topic that you want added.",
            response: [...generateNowText],
          },
          {
            option: "Narrative essay",
            prompt: "Generate a narrative essay, using all the input provided.",
            answer: "Finally provide any topic that you want added.",
            response: [...generateNowText],
          },
          {
            option: "Descriptive essay",
            prompt:
              "Generate a descriptive essay, using all the input provided.",
            answer: "Finally provide any topic that you want added.",
            response: [...generateNowText],
          },
          {
            option: "Problem solving exercise with solution",
            prompt:
              "Generate a problem solving exercise with solution, using all the input provided.",
            answer: "Finally provide any topic that you want added.",
            response: [...generateNowText],
          },
          {
            option: "Research Assignment with questions",
            prompt:
              "Generate a Research Assignment with questions, using all the input provided.",
            answer: "Finally provide any topic that you want added.",
            response: [...generateNowText],
          },
          {
            option: "Creative Writing",
            prompt:
              "Generate a Creative Writing exercise, with the different prompts, using all the input provided.",
            answer: "Finally provide any topic that you want added.",
            response: [...generateNowText],
          },
          {
            option: "Project Ideas",
            prompt:
              "Generate a Project based exercise, with the objectives and possible approaches, using all the input provided.",
            answer: "Finally provide any topic that you want added.",
            response: [...generateNowText],
          },
          {
            option: "Debate Topic",
            prompt:
              "Generate a debate topic, with the set of arguments and counterarguments that students can use to prepare, using all the input provided.",
            answer: "Finally provide any topic that you want added.",
            response: [...generateNowText],
          },
          {
            option: "20 Multiple choice questions",
            prompt:
              "Generate a multiple choice set of 20 questions with answers, using all the input provided.",
            answer: "Finally provide any topic that you want added.",
            response: [...generateNowText],
          },
          {
            option: "20 fill in the blank questions",
            prompt:
              "Generate a fill in the blank set of 20 questions with answers, using all the input provided.",
            answer: "Finally provide any topic that you want added.",
            response: [...generateNowText],
          },
          {
            option: "Case study",
            prompt:
              "Generate hypothetical scenarios or case studies for students to analyze, using all the input provided.",
            answer: "Finally provide any topic that you want added.",
            response: [...generateNowText],
          },
          {
            option: "Art prompts",
            prompt:
              "Generate prompts for art projects, such as a description of a scene for students to draw or paint, using all the input provided.",
            answer: "Finally provide any topic that you want added.",
            response: [...generateNowText],
          },
        ],
      },
      {
        option: "no",
        prompt: "no",
        answer: "What is the type of assignment you would like?",
        response: [
          {
            option: "Argumentative essay",
            prompt:
              "Generate an Argumentative essay, using all the input provided.",
            answer: "Finally provide any topic that you want added.",
            response: [...generateNowText],
          },
          {
            option: "Narrative essay",
            prompt: "Generate a narrative essay, using all the input provided.",
            answer: "Finally provide any topic that you want added.",
            response: [...generateNowText],
          },
          {
            option: "Descriptive essay",
            prompt:
              "Generate a descriptive essay, using all the input provided.",
            answer: "Finally provide any topic that you want added.",
            response: [...generateNowText],
          },
          {
            option: "Problem solving exercise with solution",
            prompt:
              "Generate a problem solving exercise with solution, using all the input provided.",
            answer: "Finally provide any topic that you want added.",
            response: [...generateNowText],
          },
          {
            option: "Research Assignment with questions",
            prompt:
              "Generate a Research Assignment with questions, using all the input provided.",
            answer: "Finally provide any topic that you want added.",
            response: [...generateNowText],
          },
          {
            option: "Creative Writing",
            prompt:
              "Generate a Creative Writing exercise, with the different prompts, using all the input provided.",
            answer: "Finally provide any topic that you want added.",
            response: [...generateNowText],
          },
          {
            option: "Project Ideas",
            prompt:
              "Generate a Project based exercise, with the objectives and possible approaches, using all the input provided.",
            answer: "Finally provide any topic that you want added.",
            response: [...generateNowText],
          },
          {
            option: "Debate Topic",
            prompt:
              "Generate a debate topic, with the set of arguments and counterarguments that students can use to prepare, using all the input provided.",
            answer: "Finally provide any topic that you want added.",
            response: [...generateNowText],
          },
          {
            option: "20 Multiple choice questions",
            prompt:
              "Generate a multiple choice set of 20 questions with answers, using all the input provided.",
            answer: "Finally provide any topic that you want added.",
            response: [...generateNowText],
          },
          {
            option: "20 fill in the blank questions",
            prompt:
              "Generate a fill in the blank set of 20 questions with answers, using all the input provided.",
            answer: "Finally provide any topic that you want added.",
            response: [...generateNowText],
          },
          {
            option: "Case study",
            prompt:
              "Generate hypothetical scenarios or case studies for students to analyze, using all the input provided.",
            answer: "Finally provide any topic that you want added.",
            response: [...generateNowText],
          },
          {
            option: "Art prompts",
            prompt:
              "Generate prompts for art projects, such as a description of a scene for students to draw or paint, using all the input provided.",
            answer: "Finally provide any topic that you want added.",
            response: [...generateNowText],
          },
        ],
      },
    ],
  },
  {
    option: "I will provide what I want",
    prompt: "I will provide the topic",
    answer: "What is the type of assignment you would like?",
    response: [
      {
        option: "Argumentative essay",
        prompt:
          "Generate an Argumentative essay, using all the input provided.",
        answer: "Finally provide any topic that you want added.",
        response: [...generateNowText],
      },
      {
        option: "Narrative essay",
        prompt: "Generate a narrative essay, using all the input provided.",
        answer: "Finally provide any topic that you want added.",
        response: [...generateNowText],
      },
      {
        option: "Descriptive essay",
        prompt: "Generate a descriptive essay, using all the input provided.",
        answer: "Finally provide any topic that you want added.",
        response: [...generateNowText],
      },
      {
        option: "Problem solving exercise with solution",
        prompt:
          "Generate a problem solving exercise with solution, using all the input provided.",
        answer: "Finally provide any topic that you want added.",
        response: [...generateNowText],
      },
      {
        option: "Research Assignment with questions",
        prompt:
          "Generate a Research Assignment with questions, using all the input provided.",
        answer: "Finally provide any topic that you want added.",
        response: [...generateNowText],
      },
      {
        option: "Creative Writing",
        prompt:
          "Generate a Creative Writing exercise, with the different prompts, using all the input provided.",
        answer: "Finally provide any topic that you want added.",
        response: [...generateNowText],
      },
      {
        option: "Project Ideas",
        prompt:
          "Generate a Project based exercise, with the objectives and possible approaches, using all the input provided.",
        answer: "Finally provide any topic that you want added.",
        response: [...generateNowText],
      },
      {
        option: "Debate Topic",
        prompt:
          "Generate a debate topic, with the set of arguments and counterarguments that students can use to prepare, using all the input provided.",
        answer: "Finally provide any topic that you want added.",
        response: [...generateNowText],
      },
      {
        option: "20 Multiple choice questions",
        prompt:
          "Generate a multiple choice set of 20 questions with answers, using all the input provided.",
        answer: "Finally provide any topic that you want added.",
        response: [...generateNowText],
      },
      {
        option: "20 fill in the blank questions",
        prompt:
          "Generate a fill in the blank set of 20 questions with answers, using all the input provided.",
        answer: "Finally provide any topic that you want added.",
        response: [...generateNowText],
      },
      {
        option: "Case study",
        prompt:
          "Generate hypothetical scenarios or case studies for students to analyze, using all the input provided.",
        answer: "Finally provide any topic that you want added.",
        response: [...generateNowText],
      },
      {
        option: "Art prompts",
        prompt:
          "Generate prompts for art projects, such as a description of a scene for students to draw or paint, using all the input provided.",
        answer: "Finally provide any topic that you want added.",
        response: [...generateNowText],
      },
    ],
  },
];

const timeSelect = [
  // An hour
  {
    option: "An hour",
    prompt: "I want this to take the student an hour.",
    answer:
      "We are nearly there. Do you have any specific considerations or themes for the assignment? For example, are there any particular topics to cover such as renewable energy in Physics, or cloud computing in IT? Or perhaps focus on specific concepts like the conservation of energy in Science, or the rule of thirds in Visual Arts?",
    response: [...intentSelect],
  },
  //  2 hours
  {
    option: "2 hours",
    prompt: "I want this to take the student 2 hours.",
    answer:
      "We are nearly there. Do you have any specific considerations or themes for the assignment? For example, are there any particular topics to cover such as renewable energy in Physics, or cloud computing in IT? Or perhaps focus on specific concepts like the conservation of energy in Science, or the rule of thirds in Visual Arts?",
    response: [...intentSelect],
  },
  // A week
  {
    option: "a week",
    prompt: "I want this to take the student a week.",
    answer:
      "We are nearly there. Do you have any specific considerations or themes for the assignment? For example, are there any particular topics to cover such as renewable energy in Physics, or cloud computing in IT? Or perhaps focus on specific concepts like the conservation of energy in Science, or the rule of thirds in Visual Arts?",
    response: [...intentSelect],
  },
];

const levelSelect = [
  // Begginer
  {
    option: "Beginner",
    prompt: "I would like to create a beginner level assignment.",
    answer:
      "Although some assignments can be at different levels, it is also the time allocated to do each that will help me to give you guidance. How long should the assignment be? Please specify in terms of hours required to complete?",
    response: [...timeSelect],
  },
  // Intermidiate
  {
    option: "Intermediate",
    prompt: "I would like to create an intermediate level assignment.",
    answer:
      "Although some assignments can be at different levels, it is also the time allocated to do each that will help me to give you guidance. How long should the assignment be? Please specify in terms of hours required to complete?",
    response: [...timeSelect],
  },
  // Advanced
  {
    option: "Advanced",
    prompt: "I would like to create an advanced assignment.",
    answer:
      "Although some assignments can be at different levels, it is also the time allocated to do each that will help me to give you guidance. How long should the assignment be? Please specify in terms of hours required to complete?",
    response: [...timeSelect],
  },
];

const gradeSelection = [
  // Grade 1
  {
    option: "1",
    prompt: "Grade 1",
    answer:
      "Great, now that I know the grade, lets see the completxity level. Please select the complexity level of the assignment from the following options: Beginner, Intermediate, Advanced.",
    response: [...levelSelect],
  },
  // Grade 2
  {
    option: "2",
    prompt: "Grade 2",
    answer:
      "Great, now that I know the grade, lets see the completxity level. Please select the complexity level of the assignment from the following options: Beginner, Intermediate, Advanced.",
    response: [...levelSelect],
  },
  // Grade 3
  {
    option: "3",
    prompt: "Grade 3",
    answer:
      "Great, now that I know the grade, lets see the completxity level. Please select the complexity level of the assignment from the following options: Beginner, Intermediate, Advanced.",
    response: [...levelSelect],
  },
  // Grade 4
  {
    option: "4",
    prompt: "Grade 4",
    answer:
      "Great, now that I know the grade, lets see the completxity level. Please select the complexity level of the assignment from the following options: Beginner, Intermediate, Advanced.",
    response: [...levelSelect],
  },
  // Grade 5
  {
    option: "5",
    prompt: "Grade 5",
    answer:
      "Great, now that I know the grade, lets see the completxity level. Please select the complexity level of the assignment from the following options: Beginner, Intermediate, Advanced.",
    response: [...levelSelect],
  },
  // Grade 6
  {
    option: "6",
    prompt: "Grade 6",
    answer:
      "Great, now that I know the grade, lets see the completxity level. Please select the complexity level of the assignment from the following options: Beginner, Intermediate, Advanced.",
    response: [...levelSelect],
  },
  // Grade 7
  {
    option: "7",
    prompt: "Grade 7",
    answer:
      "Great, now that I know the grade, lets see the completxity level. Please select the complexity level of the assignment from the following options: Beginner, Intermediate, Advanced.",
    response: [...levelSelect],
  },
  // Grade 8
  {
    option: "8",
    prompt: "Grade 8",
    answer:
      "Great, now that I know the grade, lets see the completxity level. Please select the complexity level of the assignment from the following options: Beginner, Intermediate, Advanced.",
    response: [...levelSelect],
  },
  // Grade 9
  {
    option: "9",
    prompt: "Grade 9",
    answer:
      "Great, now that I know the grade, lets see the completxity level. Please select the complexity level of the assignment from the following options: Beginner, Intermediate, Advanced.",
    response: [...levelSelect],
  },
  // Grade 10
  {
    option: "10",
    prompt: "Grade 10",
    answer:
      "Great, now that I know the grade, lets see the completxity level. Please select the complexity level of the assignment from the following options: Beginner, Intermediate, Advanced.",
    response: [...levelSelect],
  },
  // Grade 11
  {
    option: "11",
    prompt: "Grade 11",
    answer:
      "Great, now that I know the grade, lets see the completxity level. Please select the complexity level of the assignment from the following options: Beginner, Intermediate, Advanced.",
    response: [...levelSelect],
  },
  // Grade 12
  {
    option: "12",
    prompt: "Grade 12",
    answer:
      "Great, now that I know the grade, lets see the completxity level. Please select the complexity level of the assignment from the following options: Beginner, Intermediate, Advanced.",
    response: [...levelSelect],
  },
];

// Hiding for now
// const levelOfDetails = [
//   {
//     option: "ADHD",
//     prompt:
//       "Break down information into smaller chunks. give steps and information in bullet points for the assignment, add to the assignment explanation steps on what to do",
//     answer: "now i know what kind of level of details you needed",
//     response: [...gradeSelection],
//   },
//   {
//     option: "Dyslexia",
//     prompt:
//       "Use clear and simple language. Avoid using long, complex sentences., Break up text with headings and subheadings where applicable, and provide examples to clarify concepts if needed",
//     answer: "now i know what kind of level of details you needed",
//     response: [...gradeSelection],
//   },
//   {
//     option: "Special Needs",
//     prompt:
//       "Use a friendly and supportive tone. Use simple and concrete language and suggest any visual aids",
//     answer: "now i know what kind of level of details you needed",
//     response: [...gradeSelection],
//   },
//   {
//     option: "Advanced Students",
//     prompt:
//       "Provide in-depth explanations and more complex information where applicable, Offer additional resources or references for further exploration to assist in a wider knowledge generation, create assignments that encourage critical thinking",
//     answer: "now i know what kind of level of details you needed",
//     response: [...gradeSelection],
//   },
// ];

export const preAsk = [
  {
    option: "Create an assignment",
    prompt:
      "Can you provide me with a list of subjects that you can provide guided assignment help?",
    answer:
      "Sure, let me guide you through the steps to generate a creative, innovative assignment. First up. Please select the subject area you want to create the assignment in",
    response: [
      //
      //
      // Science
      {
        option: "Science",
        prompt: "Of the fields within Science, which can you help me with?",
        answer:
          "I am capable as an AI NLP, to help with any subject area, but to make sure you get the correct answer, and also be of best help to the student, choose one of the subject areas below.",
        response: [
          {
            option: "General Science",
            prompt: "I want to create a General Science assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
          {
            option: "Biology",
            prompt: "I want to create a Biology assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
          {
            option: "Chemistry",
            prompt: "I want to create a Chemistry assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
          {
            option: "Physics",
            prompt: "I want to create a Physics assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
          {
            option: "Earth Science",
            prompt: "I want to create a Earth Science assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
          {
            option: "Environmental Science",
            prompt: "I want to create a Environmental Science assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
        ],
      },
      //
      //
      // Technology
      {
        option: "Technology",
        prompt: "Of the fields within Technology, which can you help me with?",
        answer:
          "I am capable as an AI NLP, to help with any subject area, but to make sure you get the correct answer, and also be of best help to the student, choose one of the subject areas below.",
        response: [
          {
            option: "Information Technology",
            prompt: "I want to create a Information Technology assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
          {
            option: "Electronics",
            prompt: "I want to create a Electronics assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
          {
            option: "AI and Machine Learning",
            prompt: "I want to create a AI and Machine Learning assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
          {
            option: "Digital Literacy",
            prompt: "I want to create a Digital Literacy assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
        ],
      },
      //
      //
      // Engineering
      {
        option: "Engineering",
        prompt: "Of the fields within Engineering, which can you help me with?",
        answer:
          "I am capable as an AI NLP, to help with any subject area, but to make sure you get the correct answer, and also be of best help to the student, choose one of the subject areas below.",
        response: [
          {
            option: "Mechanical Engineering",
            prompt: "I want to create a Mechanical Engineering assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
          {
            option: "Civil Engineering",
            prompt: "I want to create a Civil Engineering assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
          {
            option: "Electrical Engineering",
            prompt: "I want to create a Electrical Engineering assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
          {
            option: "Chemical Engineering",
            prompt: "I want to create a Chemical Engineering assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
          {
            option: "Computer Engineering",
            prompt: "I want to create a Computer Engineering assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
        ],
      },
      //
      //
      // Art
      {
        option: "Art",
        prompt: "Of the fields within Art, which can you help me with?",
        answer:
          "I am capable as an AI NLP, to help with any subject area, but to make sure you get the correct answer, and also be of best help to the student, choose one of the subject areas below.",
        response: [
          {
            option: "Visual Arts",
            prompt: "I want to create a Visual Arts assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
          {
            option: "Music Arts",
            prompt: "I want to create a Music Arts assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
          {
            option: "Theatre Arts",
            prompt: "I want to create a Theatre Arts assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
          {
            option: "Dance Arts",
            prompt: "I want to create a Dance Arts assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
          {
            option: "Film and Media",
            prompt: "I want to create a Film and Media assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
        ],
      },
      //
      //
      // Mathematics
      {
        option: "Mathematics",
        prompt: "Of the fields within Mathematics, which can you help me with?",
        answer:
          "I am capable as an AI NLP, to help with any subject area, but to make sure you get the correct answer, and also be of best help to the student, choose one of the subject areas below.",
        response: [
          {
            option: "Algebra",
            prompt: "I want to create a Algebra assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
          {
            option: "Geometry",
            prompt: "I want to create a Geometry assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
          {
            option: "Calculus",
            prompt: "I want to create a Calculus assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
          {
            option: "Statistics",
            prompt: "I want to create a Statistics assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
          {
            option: "Number Theory",
            prompt: "I want to create a Number Theory assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
        ],
      },
      //
      //
      // General
      {
        option: "General",
        prompt: "Of the fields within General, which can you help me with?",
        answer:
          "I am capable as an AI NLP, to help with any subject area, but to make sure you get the correct answer, and also be of best help to the student, choose one of the subject areas below.",
        response: [
          {
            option: "History",
            prompt: "I want to create a History assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
          {
            option: "Geography",
            prompt: "I want to create a Geography assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
          {
            option: "Spanish",
            prompt: "I want to create a Spanish assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
          {
            option: "Computer Science",
            prompt: "I want to create a Computer Science assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
          {
            option: "Home Economics",
            prompt: "I want to create a Home Economics assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
          {
            option: "Religion",
            prompt: "I want to create a Religion assignment.",
            answer:
              "Ok, let us start gathering more details. Please select a grade from 1 to 12.",
            response: [...gradeSelection],
          },
        ],
      },
    ],
  },
  {
    option: "AI assistant mode",
    prompt: "Act as a AI assistant",
    answer:
      "Sure, I can explain things to you as if I’m talking to a tutor. Here are some general tips for explaining concepts effectively:\n 1. Break things down into understandable pieces: When explaining a concept, it’s helpful to start by breaking it down into smaller, more manageable pieces. This can help the student understand the different components of the concept and how they fit together.\n 2. Use analogies and examples: Analogies and examples can be powerful tools for explaining complex concepts. When you compare a difficult concept to something the student already knows, it can make the concept more accessible and easier to understand.\n 3. Check for understanding: It’s important to periodically check that the student is understanding what you’re saying. One way to do this is to ask open-ended questions to see if the student can apply the concept in a new way.\n 4. Listen and adapt: Finally, it’s important to listen to the student’s questions and feedback, and adapt your explanations accordingly. If a student is having trouble understanding a particular aspect of a concept, for example, you may need to adjust your explanation or provide additional examples to help them grasp it better.\n By following these tips, you can help ensure that your explanations are clear, concise, and effective in helping the student learn and understand the material.",
  },
  {
    option: "Check Balance",
    prompt: "CHECK_TOKEN_LIMIT",
  },
];
