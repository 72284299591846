import React, { useEffect, useState } from 'react'
import { moodleSSOLogin } from "../../services/api"
import { connect } from 'react-redux'
import UserActions from "../../redux/user";

//const moodleUrl = "http://34.121.11.73/"
//const moodleUrl = "//moodle-dev.swotknot.com"

const School = (props) => {
  const [moodleUrl, setMoodleUrl] = useState()

  useEffect(() => {
    if (!moodleUrl) {
      loadSchool()
    }
  })

  const loadSchool = async () => {
    console.log("loadSchool - user email", props.user.email)
    const response = await moodleSSOLogin(props.user.email)
    if (response && response.ok) {
      setMoodleUrl(response.data.loginurl)
    }
  }

  return (
    <div className="w-full h-screen flex items-center justify-center">

      {moodleUrl ?
        <iframe
          className="w-full h-full"
          src={moodleUrl}
          title="moodle" />

        : <div className="text-5xl text-gray-500  w-full text-center ">
          Loading...
     </div>
      }

    </div>
  )
}
const mapStateToProps = (state) => ({
  user: state.user.user,
})

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) =>
    dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(School)
