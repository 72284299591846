import React, { useEffect, useReducer } from "react";
import commonColor from "../../../../../theme/commonColor";
import { getCourseExists } from "../../../../../services/api";
import Select from "../../../../../components/common/input/select";
import { languagesOpts } from "../../../../../config/languages";

const CourseStep1 = ({
  user,
  saveProperty,
  currentStep,
  courseDetails,
  mode,
  stateData,
  oldNameShort,
}) => {
  const defaultLanguage = { label: "English", value: "english" };

  // variables:-------------------------------------------------

  const reducerStateFn = (state, action) => {
    if (action.key) {
      if (action.key === "reset") {
        return action.value;
      }
      return { ...state, ...{ [action.key]: action.value } };
    }
  };

  const [localState, dispatchReducer] = useReducer(reducerStateFn, {});

  // initialize:--------------------------------------------
  useEffect(() => {
    updateLocalData("reset", stateData);
  }, []);

  useEffect(() => {
    const vd = isDataValid(false);
    saveProperty("step1Completed", vd);
  }, [localState]);

  const updateLocalData = (key, value) => {
    dispatchReducer({ key, value });
  };

  if (currentStep !== 1) {
    return null;
  }
  // functions:------------------------------------------------
  const handleChange = (key, value) => {
    if (key === "nameShort" && value && value.length > 0) {
      if (value.length > 25) {
        value = value.substring(0, 25);
      }
    }
    saveProperty(key, value);
    updateLocalData(key, value);
  };

  const checkNameShort = async () => {
    const { nameFull, nameShort, nameShortExists } = stateData;

    // only send the request if the short name exists
    // and it is not the same as the already saved one
    if (nameShort && nameShort !== oldNameShort) {
      const result = await getCourseExists(nameShort, user.uid);
      const exists = result?.data?.exist;
      console.log(`checkNameShort:`, nameShort, exists);
      handleChange("nameShortExists", exists);
      // if (!exists) {
      isDataValid(false);
      // }
      return exists;
    }
  };

  const isDataValid = (showMessage = true) => {
    const { nameFull, nameShort, summary, rate, nameShortExists } = stateData;

    if (!nameFull) {
      if (showMessage) {
        saveProperty("errorMessage", "Course Title can't be empty");
      }
      return false;
    }
    if (!nameShort) {
      if (showMessage) {
        saveProperty("errorMessage", "short name can't be empty");
      }
      return false;
    }
    if (nameShortExists) {
      return false;
    }

    if (!summary) {
      if (showMessage) {
        saveProperty("errorMessage", "Summary can't be empty");
      }
      return false;
    }

    if (
      !user?.ngoUser &&
      (!rate || !isNumeric(rate) || parseInt(Number(rate)) < 0)
    ) {
      if (showMessage) {
        saveProperty("errorMessage", "Enter a valid course hourly rate");
      }
      return false;
    }
    return true;
  };

  const isNumeric = (num) =>
    (typeof num === "number" ||
      (typeof num === "string" && num.trim() !== "")) &&
    !isNaN(num) &&
    !isNaN(+num);

  // widgets:--------------------------------------------------
  const errorWidgets = () => {
    return (
      <div className="w-full flex flex-col justify-start">
        {stateData?.nameShortExists && (
          <div className="text-sm bg-red-500 w-full text-white text-center mb-1">
            {"Short name already exists, please use a different name."}
          </div>
        )}
        {stateData?.errorMessage && (
          <div className="text-sm bg-red-500 w-full text-white text-center mb-1">
            {stateData?.errorMessage}
          </div>
        )}
      </div>
    );
  };
  //
  // main body:------------------------------------------------
  return (
    <div className="h-full flex flex-col justify-start">
      {errorWidgets()}
      <div className="w-full h-full flex flex-col overflow-y-scroll lg:overflow-y-hidden">
        <div className="w-full mt-auto">
          <RowWidget title={"Course Title"} isRequired={true}>
            <input
              required
              className="w-full bg-white appearance-none border-2 focus:outline-none border-lightGray focus:border-gray-300 px-2 py-1"
              style={{
                marginTop: "auto",
              }}
              id="title"
              key={"title"}
              name="title"
              type="text"
              placeholder={
                mode === "edit" ? stateData?.nameFull : "Enter course title"
              }
              value={stateData?.nameFull}
              onChange={(event) => {
                handleChange("nameFull", event.target.value);
              }}
              onBlur={(event) => {
                handleChange("nameFull", event.target.value);
              }}
            />
          </RowWidget>
        </div>

        <RowWidget title={"Short Name"} isRequired={true}>
          <input
            required
            className="w-full bg-white appearance-none border-2 focus:outline-none border-lightGray focus:border-gray-300 px-2 py-1"
            style={{
              ...(stateData?.nameShortExists === true
                ? { borderColor: commonColor.error }
                : {}),
            }}
            id="short"
            name="short"
            type="text"
            placeholder={
              mode === "edit" ? stateData?.nameShort : "Enter course short name"
            }
            value={stateData?.nameShort}
            onChange={(event) => {
              handleChange("nameShort", event.target.value);
              handleChange("nameShortExists", false);
            }}
            onBlur={(event) => {
              handleChange("nameShort", event.target.value);
              checkNameShort();
            }}
          />
        </RowWidget>

        <RowWidget title={"Summary"} isRequired={true}>
          <textarea
            required
            rows={4}
            className="w-full bg-white appearance-none border-2 focus:outline-none focus:border-opacity-100 rounded-lg"
            style={{
              resize: "none",
              paddingLeft: "12px",
              paddingTop: "5px",
            }}
            value={stateData?.summary}
            placeholder={
              mode === "edit"
                ? stateData?.summary
                : "Enter a paragraph describing the course"
            }
            onChange={(event) => {
              handleChange("summary", event.target.value);
            }}
          />
        </RowWidget>

        {!user?.ngoUser && (
          <RowWidget title={"Rate ($/hour)"} isRequired={true}>
            <input
              required
              className="w-full bg-white appearance-none border-2 focus:outline-none border-lightGray focus:border-gray-300 px-2 py-1"
              style={{
                marginBottom: "auto",
              }}
              id="rate"
              name="rate"
              type="number"
              placeholder={"Enter course's hourly rate in $"}
              value={(stateData?.rate ?? 0) >= 0 ? stateData?.rate : null}
              min="1"
              onChange={(e) => {
                handleChange("rate", e.target.value);
              }}
            />
          </RowWidget>
        )}

        <div className="w-full mb-auto">
          <RowWidget title={"Language"} isRequired={true}>
            <Select
              className="w-full"
              placeholder={"Select"}
              value={stateData?.language ?? defaultLanguage}
              menuPlacement="top"
              onChange={(value) => {
                console.log("selected language:", value);
                handleChange("language", value);
              }}
              options={languagesOpts}
            />
          </RowWidget>

          {/* <RowWidget title={"Language"}>
            <input
              className="w-full bg-white appearance-none border-2 focus:outline-none border-lightGray focus:border-gray-300"
              style={{
                marginBottom: "auto",
              }}
              id="language"
              name="language"
              type="text"
              value={stateData?.language}
              placeholder={"Enter course's language"}
              onChange={(e) => {
                handleChange("language", e.target.value);
              }}
            />
          </RowWidget> */}
        </div>
      </div>
    </div>
  );
};

export default CourseStep1;

const RowWidget = ({ title, children, isRequired }) => {
  return (
    <div className="w-full flex flex-col md:flex-row justify-center py-2">
      <div className="text-gray-500 font-semibold text-sm md:w-32 w-full flex flex-row justify-start md:justify-end items-center px-2">
        {title} {isRequired && <sup>*</sup>}
      </div>
      <div className="lg:w-2/3 flex-grow flex flex-row justify-start  items-center px-2 py-1">
        {children}
      </div>
    </div>
  );
};
