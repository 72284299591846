import React, { useState, useEffect } from "react";
import { getFeaturedTutors } from "../../../services/api";
import { useNavigate } from "react-router-dom";

import { animated, useInView, easings } from "@react-spring/web";

const PartSeven = () => {
  const [tutors, setTutors] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    const fetchTutors = async () => {
      try {
        const response = await getFeaturedTutors();
        const data = response.data.data.featured;
        if (isMounted) {
          setTutors(data);
        }
      } catch (error) {
        console.log("Error fetching tutors:", error);
      }
    };
    fetchTutors();
    return () => {
      isMounted = false;
    };
  }, []);

  const TutorCard = ({ tutor }) => {
    const [reff, refSprings] = useInView(
      () => ({
        from: { opacity: 0, y: 40, scale: 1 },
        to: {
          opacity: 1,
          y: 0,
          config: {
            bounce: 1,
            easing: easings.easeOutElastic,
          },
        },
      }),
      {
        root: null,
        // rootMargin: "-20px",
        threshold: 0.1,
        once: true,
      }
    );

    return (
      <div ref={reff}>
        <animated.div
          style={refSprings}
          className="w-[220px] text-start transition-all ease-in duration-300 brightness-90 hover:brightness-100 text-center"
        >
          <img
            className="h-10/12 w-full rounded-xl shadow-2xl scale-100 hover:scale-110 transition-all ease"
            src={tutor.photoUrl}
            alt="tutor_image"
          />
          <p className="text-gray-700 ml-6 mt-4 mb-2">
            {tutor.name} <br />
            <span className="ml-4 text-gray-600 inline-block">
              {tutor.relatedCourses[0].courseFullName}
            </span>
          </p>
        </animated.div>
      </div>
    );
  };

  return (
    <div id="your_tutors" className="flex flex-col">
      {/* search */}
      <div className="w-full flex flex-col items-center justify-center">
        <div className="mb-4">
          <button
            className="bg-[rgba(156,57,103,1)] mt-6 w-fit px-6 py-2 text-base text-white font-medium rounded-md shadow-md"
            onClick={() => {
              //TODO: goto search screen
              navigate("search-tutors");
            }}
          >
            Search our tutors
          </button>
        </div>
        <div className="w-fit text-center">
          <p className="text-gray-500 text-5xl">
            <span className="text-[#f56010b9] text-4xl ">
              find your best match
            </span>
            <br />{" "}
            <span className="text-gray-500  text-3xl mr-4">Your Tutor</span>
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-16 md:gap-0 lg:flex-row ml-2 mr-4 md:ml-4 md:mr-8 mb-40 justify-around items-center ">
        {/* left */}

        <div className="flex flex-col gap-6 md:gap-10 md:place-items-end place-items-center text-center">
          {tutors.slice(0, 2).map((tutor, index) => (
            <TutorCard key={index} tutor={tutor} />
          ))}
        </div>
        {/* middle */}
        <div className="flex flex-col gap-10 md:gap-2 mt-0 md:mt-10 ">
          {tutors.slice(2, 4).map((tutor, index) => (
            <TutorCard key={index} tutor={tutor} />
          ))}
        </div>
        {/* right */}
        <div className="flex flex-col gap-10 md:gap-2 mt-0 md:mt-20 ">
          {tutors.slice(4, 6).map((tutor, index) => (
            <TutorCard key={index} tutor={tutor} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PartSeven;
