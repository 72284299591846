import React, { useState, useEffect } from "react";
// import "react-big-calendar/lib/css/react-big-calendar.css";
import contentful from "../../../config/contentful";
import NoStudents from "../../../assets/images/nostudents.jpg";

const FactOfTheDay = () => {
  const [factOfDay, setFactOfDay] = useState();

  useEffect(() => {
    getFactofTheDay();
  }, []);

  const getFactofTheDay = async () => {
    const response = await contentful.getEntries({
      content_type: "fact",
    });
    console.log("Swotnews - get factOfTheDay", response);
    if (response?.items?.length > 0) {
      const r = response.items[0].fields;
      const title = r?.heading;
      const message = r?.text;
      const image = r?.coverImage?.fields?.file?.url;
      setFactOfDay({ title: title, message: message, image: image });
    }
  };

  return (
    <div className="w-full h-full flex md:flex-row bg-white rounded-3xl overflow-hidden flex-col">
      <div className="h-full md:w-2/5 xl:w-1/2 flex flex-col justify-end pt-3 md:pt-0">
        <img
          src={factOfDay?.image ?? NoStudents}
          alt="FactofTheDayImage"
          className=" bg-transparent"
          style={{
            height: "100%",
            display: "block",
            objectFit: factOfDay?.image !== null ? "cover" : "contain",
          }}
        />
      </div>
      
      <div className="h-full text-sm md:w-3/5 xl:w-1/2 flex flex-col items-center justify-start p-4 overflow-y-scroll">
        <div className="w-full text-lg text-center mt-auto">{factOfDay?.title ?? ""}</div>
        <div className="mb-auto">
          {factOfDay?.message ??
            "A Dragon has non to four legs claws scales and possibly spikes"}
        </div>
      </div>
    </div>
  );
};

export default FactOfTheDay;
