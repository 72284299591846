import React from "react";
import moment from "moment";

import { ReactComponent as Clock } from "../../assets/icons/time.svg";
import { ReactComponent as Reading } from "../../assets/icons/reading-book.svg";
import { ReactComponent as LightBulb } from "../../assets/icons/creative-process.svg";

import commonColor from "../../theme/commonColor";

const InterestingList = ({
  studentsTotal,
  coursesTotal,
  monthlyHoursTotal,
}) => {
  const currMonthName = moment().format("MMMM");

  return (
    <div className="w-full h-full flex flex-col justify-center text-black">
      <div className="w-full flex items-center p-2 my-4">
        <div>
          <Clock
            style={{
              width: "2rem",
              height: "2rem",
              overflow: "hidden",
              fill: commonColor.violet,
              //padding: "2rem"
            }}
          />
        </div>
        <div className="text-sm pl-4">
          {monthlyHoursTotal === 0
            ? `No teaching hour(s) for ${currMonthName} yet.`
            : `You have been teaching for ${monthlyHoursTotal} hour(s) in ${currMonthName}`}
        </div>
      </div>

      <div className="w-full flex items-center p-2 my-4">
        <div>
          <Reading
            style={{
              width: "2rem",
              height: "2rem",
              overflow: "hidden",
              fill: commonColor.deepGreen,
              //padding: "2rem"
            }}
          />
        </div>
        <div className="text-sm pl-4">
          {studentsTotal === 0
            ? "Students will start to attend soon."
            : `and have a total of ${studentsTotal} student(s)`}
        </div>
      </div>

      <div className="w-full flex items-center p-2 my-4">
        <div>
          <LightBulb
            style={{
              width: "2rem",
              height: "2rem",
              overflow: "hidden",
              fill: commonColor.sourCherry,
              //padding: "2rem"
            }}
          />
        </div>
        <div className="text-sm pl-4">
          {coursesTotal === 0
            ? "No courses are being taught at the moment."
            : `in ${coursesTotal} course(s)`}
        </div>
      </div>
    </div>
  );
};

export default InterestingList;
