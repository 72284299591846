import React from "react";

const ContentCard = ({ title, text, image }) => {
  const renderImage = () => {};

  const renderContext = () => {};

  return (
    <div className="shadow-2xl bg-white rounded-xl h-full w-full flex flex-col justify-between items-center">
      <div className="w-full p-1 h-3/5">Image</div>
      <div className="w-full h-2/5 p-4 flex flex-col items-start justify-start">
        <div className="">{title}</div>
        <div className="text-xs pt-4">{text}</div>
      </div>
    </div>
  );
};

export default ContentCard;
