import React, { useState } from "react";
import "./PersonaStyle.css";
import LandingLayout from "../../../components/landing/landingLayout";
import BetaSignupModal from ".././betaSignupModal";
import LoaderModal from "../../../components/common/modal/loaderModal";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Swotbutton from "../../../components/common/buttons/swotbutton.tsx";
import slideImage1 from "../../../assets/images/PersonaPageImages/ExamPrep/exam-prep-1.png";
import slideImage2 from "../../../assets/images/PersonaPageImages/ExamPrep/exam-prep-2.png";
import slideImage3 from "../../../assets/images/PersonaPageImages/ExamPrep/exam-prep-3.png";
import { useNavigate } from "react-router-dom";

const words = ["The Basics", "What we offer?", "Why l3arn?"];

const indicators = (index) => {
  return (
    <div className="text-base lg:text-xl font-semibold indicator">
      {words[index]}
    </div>
  );
};
const fadeProperties = {
  duration: 30000,
  pauseOnHover: true,
  autoplay: false,
  indicators: indicators,
  easing: "ease-out",
  canSwipe: true,
};

// <!-- Width of 16 by default, 32 on medium screens, and 48 on large screens -->
// <img class="w-16 md:w-32 lg:w-48" src="...">

const Slideshow = () => {
  let navigate = useNavigate();

  return (
    <div className="sm:pl-4 sm:pr-5 max-w-full h-full persona">
      <div className="slide-container m-6 sm:w-full w-11/12 ">
        <Slide className="relative" {...fadeProperties}>
          <div className="lg:flex-row flex-col  | each-slide-effect flex  items-center justify-center px-8 lg:px-10 mt-16 h-full  ">
            <div className="md:w-full flex items-center justify-center lg:w-9/12 xl:w-8/12">
              <div className="w-10/12">
                <p className="font-semibold mb-2 | text-base lg:text-xl  ">
                  Exams are a natural part of education
                </p>
                <p className="font-normal | text-base  lg:text-xl ">
                  The stress that accompanies them can lead to anxiety and even
                  panic attacks, a feeling that any student knows too well.
                  Finding someone to help with exam preparation, whether from
                  entry exams, English or university courses, can be an extra
                  headache.
                </p>
                <div className="flex flex-row mt-6 lg:justify-start justify-center items-center">
                  <Swotbutton
                    text="Enroll Now"
                    className="mr-4"
                    bgColor="#25627B"
                    onClick={() => {
                      navigate("/on-boarding");
                    }}
                  />
                  <Swotbutton
                    text="Learn More"
                    onClick={() => {
                      navigate("/");
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-auto mt-6">
              <img
                className="slideShowImg w-full pl-2 lg:pl-2 object-contain"
                src={slideImage1}
                alt="ExamePrep slideImagePic"
              />
            </div>
          </div>
          <div className="lg:flex-row flex-col | each-slide-effect flex  items-center justify-center px-8 lg:px-10 mt-16 h-full ">
            <div className="md:w-full flex items-center justify-center lg:w-9/12 xl:w-8/12">
              <div className="w-10/12">
                <p className="font-medium | text-base lg:text-xl">
                  Whether exams or midterms are coming up, we have your back
                  with an affordable approach
                  <span className="font-bold | text-base lg:text-xl">
                    &nbsp;without decreasing the quality;
                  </span>
                  l3arn has tutors that can assist you and give personalized
                  teaching regardless of the class size or the needs of their
                  students.
                </p>

                <div className="flex flex-row mt-6 lg:justify-start justify-center items-center">
                  <Swotbutton
                    text="Enroll Now"
                    className="mr-4"
                    bgColor="#25627B"
                    onClick={() => {
                      navigate("/on-boarding");
                    }}
                  />
                  <Swotbutton
                    text="Learn More"
                    onClick={() => {
                      navigate("/information");
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-auto">
              <img
                className="slideShowImg w-full pl-2 lg:pl-2 object-contain"
                src={slideImage2}
                alt="ExamePrep slideImage2"
              />
            </div>
          </div>
          <div className="lg:flex-row flex-col | each-slide-effect flex  items-center justify-center px-8 lg:px-10 mt-16 h-full ">
            <div className="md:w-full flex items-center justify-center lg:w-9/12 xl:w-8/12">
              <div className="w-10/12">
                <p className="font-bold | text-base lg:text-xl">
                  Additionally, you will get to know your learning style and
                  some tips on studying based on your learning style!
                </p>

                <div className="flex flex-row mt-6 lg:justify-start justify-center items-center">
                  <Swotbutton
                    text="Enroll Now"
                    className="mr-4"
                    bgColor="#25627B"
                    onClick={() => {
                      navigate("/on-boarding");
                    }}
                  />
                  <Swotbutton
                    text="Learn More"
                    onClick={() => {
                      navigate("/learner-type");
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-auto">
              <img
                className="slideShowImg w-full pl-2 lg:pl-2 object-contain"
                src={slideImage3}
                alt="ExamePrep slideImage3"
              />
            </div>
          </div>
        </Slide>
      </div>
    </div>
  );
};

export default function ExamPrep() {
  const [showFormModal, setShowFormModal] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <LandingLayout landingTitle="">
      <Slideshow />
      <BetaSignupModal
        isOpen={!loading && showFormModal}
        onRequestClose={() => {
          setShowFormModal(false);
        }}
        showLoader={(status) => {
          setLoading(status);
        }}
      />
      {/* <BetaSignup onSubmit={onSubmit} /> */}
      {/* </BetaSignup> */}
      <LoaderModal
        isOpen={loading}
        onRequestClose={() => {
          setLoading(false);
        }}
      />
    </LandingLayout>
  );
}
