import React, { memo } from "react";
import Card from "../../components/common/card";
import useHightCalculated from "./hooks/UseHeightCalculated";

const ResponsiveCard = memo(
  ({ children, className, style, calculateHeight }) => {
    const hightCalculated = useHightCalculated(calculateHeight);

    const divStyle =
      hightCalculated && hightCalculated > 0
        ? {
            height: hightCalculated + "px",
          }
        : "";

    return (
      <Card
        className={`${className ? className : ""}`}
        style={{
          ...{ ...style },
          ...{ ...divStyle },
        }}
      >
        {children}
      </Card>
    );
  }
);

export default ResponsiveCard;
