import React, { useEffect, useState } from "react";
import { getBetatester } from "../../../services/api";
import BetatesterListItem from "./BetatesterListItem";
import commonColor from "../../../theme/commonColor";
import FooterView from "../../common/footerView";

const BetatesterDashboard = () => {
  const [betatesterList, setBetatesterList] = useState([]);

  useEffect(() => {
    getBetatesterList();
  }, []);

  const getBetatesterList = async () => {
    const response = await getBetatester();
    if (response && response.data && response.data.status === "SUCCESS") {
      const fetchedItems = response.data.data;
      console.log("BetatesterDashboard - useEffect - getBetatester", fetchedItems);
      setBetatesterList(fetchedItems);
    }
  };

  return (
    <div
      className="w-full rounded-2xl p-4 h-full
    flex flex-col "
    >
      <div className="flex justify-between items-middle">
        <div className="text-white">Betatester Dashboard</div>
        <button
          className="border-white p-4 border-2 text-white rounded-2xl focus:outline-none"
          onClick={() => {
            getBetatesterList();
          }}
        >
          Refresh
        </button>
      </div>

      <div className="p-4 flex flex-col flex-grow">
        <div className="text-white">Betatester List</div>

        <div className="">
          {betatesterList.map((betatester, index) => (
            <BetatesterListItem key={betatester.id ?? index} betatester={betatester} />
          ))}
        </div>
        <FooterView />
      </div>
    </div>
  );
};

export default BetatesterDashboard;