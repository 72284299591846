import React, { useState, useEffect } from "react";
import contentful from "../../../config/contentful";
import commonColor from "../../../theme/commonColor";
import ReactPlayer from "react-player";
import Swotbutton from "../../../components/common/buttons/swotbutton";
import FooterView from "../../../components/common/footerView";
import { ReactComponent as SearchGlass } from "../../../assets/icons/search (6).svg";

// Images for faq Card
import cardImage1 from "../../../assets/images/FAQImages/img_card_1.svg";
import cardImage2 from "../../../assets/images/FAQImages/img_card_2.svg";
import cardImage3 from "../../../assets/images/FAQImages/img_card_3.svg";
import cardImage4 from "../../../assets/images/FAQImages/img_card_4.svg";
import cardImage5 from "../../../assets/images/FAQImages/img_card_5.svg";
import cardImage6 from "../../../assets/images/FAQImages/img_card_6.svg";
import cardImage7 from "../../../assets/images/FAQImages/img_card_7.svg";
import cardImage8 from "../../../assets/images/FAQImages/img_card_8.svg";
import cardImage9 from "../../../assets/images/FAQImages/img_card_9.svg";
import cardImage10 from "../../../assets/images/FAQImages/img_card_10.svg";
import cardImage11 from "../../../assets/images/FAQImages/img_card_11.svg";

import LandingLayout from "../../../components/landing/landingLayout";
import infoIcon from "../../../assets/images/quizCards/info-icon.png";
import Slider from "react-slick";
import ReactCardFlip from "react-card-flip";
import useHightCalculated from "../../../components/common/hooks/UseHeightCalculated";
import { useWindowSize } from "../../../utils/uiUtils";

import "./faq.css";
import Modal from "../../../components/common/modal/modal";

const faqImages = [
  { faqCardImage: "../../../assets/images/FAQImages/img_card_1.svg" },
  { faqCardImage: "../../../assets/images/FAQImages/img_card_2.svg" },
  { faqCardImage: "../../../assets/images/FAQImages/img_card_3.svg" },
  { faqCardImage: "../../../assets/images/FAQImages/img_card_4.svg" },
  { faqCardImage: "../../../assets/images/FAQImages/img_card_5.svg" },
  { faqCardImage: "../../../assets/images/FAQImages/img_card_6.svg" },
  { faqCardImage: "../../../assets/images/FAQImages/img_card_7.svg" },
  { faqCardImage: "../../../assets/images/FAQImages/img_card_8.svg" },
  { faqCardImage: "../../../assets/images/FAQImages/img_card_9.svg" },
  { faqCardImage: "../../../assets/images/FAQImages/img_card_10.svg" },
  { faqCardImage: "../../../assets/images/FAQImages/img_card_11.svg" },
];

const Landing_FAQ = () => {
  const [flippedIndex, setFlippedIndex] = useState(-1);
  const hightCalculated = useHightCalculated(calculateCardHeight);
  const [width, height] = useWindowSize();
  const [isSearchActive, setIsSearchActive] = useState(true);
  const [Cardcontent, setCardContent] = useState();
  const [searchText, setSearchText] = useState(null);
  const [filteredContent, setFilteredContent] = useState(CardContent);
  const [carousalInfinite, setCarousalInfinite] = useState(true);
  const [videoUrl, setVideoUrl] = useState(null);

  useEffect(() => {
    if (filteredContent.length == 1) {
      setCarousalInfinite(false);
    } else {
      if (width <= 640) {
        setCarousalInfinite(true);
      } else if (width <= 1024) {
        setCarousalInfinite(filteredContent.length > 2);
      } else if (width <= 1680) {
        setCarousalInfinite(filteredContent.length > 3);
      } else {
        setCarousalInfinite(filteredContent.length > 4);
      }
    }
  }, [width, filteredContent]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    //</p>autoplay: true,
    arrows: true,
    slidesToShow: width <= 640 ? 1 : width <= 1024 ? 2 : width <= 1680 ? 3 : 4,
    slidesToScroll:
      width <= 640 ? 1 : width <= 1024 ? 2 : width <= 1680 ? 3 : 4,
  };
  const toggleFlip = (index) => {
    setFlippedIndex(index);
  };

  const handleSearchTermChange = (searchTerm) => {
    if (searchTerm !== undefined) {
      let matchingContent;
      let filteringContent = CardContent;
      if (searchTerm === "" || searchTerm.trim().length === 0) {
        matchingContent = CardContent;
        setFilteredContent(matchingContent);

        return;
      }
      if (searchTerm.trim().length === 0) {
        return;
      }
      matchingContent = filteringContent.filter((item) => {
        return item.question.toLowerCase().includes(searchTerm.toLowerCase());
      });
      setFilteredContent(matchingContent);
    }
  };

  const carousalComponent = () => {
    return (
      <div className="w-full sm:pr-8 pl-8 pb-6">
        <Slider {...{ ...settings, infinite: carousalInfinite }}>
          {filteredContent.map((d, key) =>
            renderContent(key, {
              ...{
                ...{
                  id: d.index,
                  question: d.question,
                  answer: d.answer,
                  image: faqImages.faqCardImage,
                  linkname: d.linkname,
                  videos: d.videos,
                },
                ...(d.videos &&
                  d.videos.length > 0 && {
                    videoTitle: d.videos[0].title,
                    videoUrl: d.videos[0].url,
                  }),
                ...{ toggleFlip: toggleFlip },
              },
            })
          )}
        </Slider>
      </div>
    );
  };

  const renderContent = (
    key,
    { question, answer, linkname, image, toggleFlip, videos }
  ) => {
    return (
      <div
        key={key}
        className="flex flex-col w-full items-center 2k:w-1/4 md:w-1/2 lg:w-1/3 xl:w-1/4 max-w-3xl p-4 lg:p-8 xl:p-8 2xl:p-8 2k:p-12"
      >
        <ResultCard
          index={key}
          image={faqImages[0]}
          question={question}
          answer={answer}
          linkname={linkname}
          isFlipped={flippedIndex == key}
          toggleFlip={toggleFlip}
          hightCalculated={hightCalculated}
          videos={videos}
          openVideo={(url) => {
            //TODO: Open content
            setVideoUrl(url);
          }}
        />
      </div>
    );
  };

  const videoPlayer = (url) => {
    return (
      // <div className="flex flex-col flex-grow w-full h-30v lg:h-50v max-w-6xl px-2 lg:min-h-80  md:min-h-96">
      <div className="flex flex-col flex-grow w-full max-w-6xl px-2">
        <div className="aspectratio" data-ratio="16:9">
          <div
            className="h-full video-player bg-black player-height"
            style={{
              // boxShadow: `0 10px 25px 0 rgba(0, 0, 0, 0.6)`,
              borderRadius: 25,
              overflow: "hidden",
              // gridArea: `2 / 1 / 11 / 2`,
            }}
          >
            <ReactPlayer
              url={url}
              width={"100%"}
              height={"100%"}
              style={{
                borderRadius: 25,
              }}
              playing
              muted
              loop
              controls
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <LandingLayout landingTitle="FAQ">
      <div className="w-full flex flex-col items-center">
        <div className="w-full lg:w-11/12 2k:w-9/12 lg:mt-8 mt-4 max-w-screen-2k">
          <form
            className="flex justify-end items-center px-5"
            style={{ width: "95%" }}
            onSubmit={(e) => {
              e.preventDefault();
              // handleSearchTermChange();
              // send to server with e.g. `window.fetch`
            }}
          >
            {isSearchActive && (
              <div
                className=" w-full"
                style={{
                  height: "50px",
                  backgroundColor: commonColor.deepGreen,
                  opacity: 0.85,
                  borderRadius: "0.7rem",
                  zIndex: 1002,
                }}
              >
                <input
                  className="w-full h-full appearance-none focus:outline-none py-1 px-2"
                  style={{
                    color: "white",
                    backgroundColor: "transparent",
                    zIndex: 1002,
                  }}
                  id="search"
                  name="search"
                  type="text"
                  placeholder={"Search"}
                  onChange={(event) => {
                    handleSearchTermChange(event.target.value);
                  }}
                />
              </div>
            )}
          </form>
          <div className="xl:mx-10 mx-6 mt-8 flex flex-wrap justify-around  max-w-screen-2k">
            {carousalComponent()}
          </div>
        </div>

        <Modal
          isOpen={!!videoUrl}
          bgColor="bg-trasparent"
          onRequestClose={() => {
            setVideoUrl(null);
          }}
        >
          {videoUrl && (
            <div className="w-90v md:w-70v lg:w-50v text-center">
              {videoPlayer(videoUrl)}
              {/* <Viewer file={viewItem}  close={() => {
              setViewItem(null);
            }}/> */}
            </div>
          )}
        </Modal>
      </div>
    </LandingLayout>
  );
};

export default Landing_FAQ;

const ResultCard = ({
  index,
  question,
  image,
  answer,
  linkname,
  isFlipped,
  toggleFlip,
  hightCalculated,
  videos,
  openVideo,
}) => {
  return (
    <>
      <ReactCardFlip
        isFlipped={isFlipped}
        flipDirection="horizontal"
        className="w-full h-full"
      >
        <div
          className="relative card w-full flex flex-col"
          style={{
            height: hightCalculated + "px",
          }}
        >
          <div className="h-full w-full flex flex-col justify-center items-center">
            {/* <img className="" src={image} alt="cardImage1" /> */}
            <span className="w-full p-4 text-base text-center font-semibold">
              {question}
            </span>
          </div>

          <div className="absolute w-full bottom-0 flex flex-row justify-end">
            <button
              className="w-3/10"
              style={{
                outline: "none",
                boxShadow: "none",
              }}
              onClick={() => {
                toggleFlip(index);
              }}
            >
              <img
                src={infoIcon}
                alt="InfoIcon"
                className="object-contain w-full"
              />
            </button>
          </div>
        </div>
        <div
          className="relative card w-full"
          style={{
            height: hightCalculated + "px",
          }}
          onClick={() => {
            toggleFlip(-1);
          }}
        >
          <div className="h-full w-full flex flex-col justify-center items-center py-4">
            <div className="w-full h-full flex flex-col justify-start items-center px-4 touch-scroll">
              <div className="w-full flex items-center flex-col mt-auto mb-auto">
                <>
                  {linkname ? (
                    <>{linkify(answer, linkname)}</>
                  ) : (
                    <span className="w-full text-sm sm:text-base whitespace-pre-line">
                      {answer}
                    </span>
                  )}
                </>

                {videos && videos.length > 0 && (
                  <div className="w-full flex items-center justify-center flex-wrap">
                    {videos.map((item, key) => (
                      <div className="px-2 sm:px-4 py-2">
                        <Swotbutton
                          text={item.title}
                          onClick={() => {
                            openVideo && openVideo(item.url);
                          }}
                        ></Swotbutton>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </ReactCardFlip>
    </>
  );
};

const calculateCardHeight = (size) => {
  let w = size.width;
  let heightCalculated = 0;
  let minHeight = 500;
  if (window.innerWidth >= 2048) {
    // 2k
    heightCalculated = size.height * 0.7;
  } else if (window.innerWidth > 1680) {
    // 2.5 xl
    heightCalculated = size.height * 0.7;
  } else if (window.innerWidth >= 1536) {
    // 2xl
    heightCalculated = size.height * 0.7;
  } else if (window.innerWidth >= 1280) {
    // xl
    heightCalculated = size.height * 0.7;
  } else if (window.innerWidth >= 1024) {
    // lg
    heightCalculated = size.height * 0.7;
  } else if (window.innerWidth >= 768) {
    // md
    heightCalculated = size.height * 0.7;
  } else if (window.innerWidth >= 640) {
    // sm
    heightCalculated = size.height * 0.8;
  } else {
    w = 0;
    heightCalculated = size.height * 0.8;
    if (heightCalculated > size.width * 1.2) {
      heightCalculated = size.width * 1.2;
    }
    minHeight = 0;
  }
  return heightCalculated < minHeight
    ? minHeight
    : heightCalculated > 600
    ? 600
    : heightCalculated;
};

const linkify = (text, name) => {
  const urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  let splits = text.split(urlRegex);
  splits = splits.filter(
    (e) => e && e !== "" && e !== "http" && e !== "https" && e !== "mailto"
  );
  return (
    <span className="w-full text-sm sm:text-base whitespace-pre-line">
      {splits.map((e) =>
        e.match(urlRegex) ? (
          <a
            style={{
              color: commonColor.deepGreen,
              textDecoration: "underline",
            }}
            href={e}
            target="_blank"
          >
            {name || e}
          </a>
        ) : (
          e
        )
      )}
    </span>
  );
};

const CardButton = ({ btnText, btnUrl, btnIcon, btnTo, setVideoUrl }) => {
  return (
    <div key={btnText} className="flex mt-6 justify-between w-full">
      <div className="ml-2 sm:ml-4">
        <Swotbutton text={btnText} to={btnTo}></Swotbutton>
      </div>

      <div className="mr-2 sm:mr-4">
        {/* Cant figure out why SwotButton does not work here with IMG jsx */}
        <button
          onClick={() => {
            setVideoUrl && setVideoUrl(btnUrl);
            // openMod();
          }}
          className="min-w-12 shadow-lg md:py-1 md:px-3 text-xs py-2 px-2  
          m-auto"
          style={{
            borderRadius: "1.5rem",
            color: "white",
            backgroundColor: commonColor.deepPink,
          }}
        >
          <img
            style={{
              height: "25px",
              width: "35px",
            }}
            src={btnIcon}
            alt="btnIcon"
          />
        </button>
      </div>
    </div>
  );
};

const CardContent = [
  {
    question: "How can I find a tutor?",
    answer:
      "At l3arn, we want to make sure you get the tutor that matches your learning style, your budget, your preferred conduct, and even if you have special needs. There are two ways. You can do the questionnaire, and our very own AI will help match and find you the best tutor, or you can choose from our featured or closest to your location tutors.Once you have narrowed down your search, you can select the tutor, view the courses in more detail, and explore the available dates.",
  },
  {
    question: "Who can apply to be a tutor in l3arn?",
    answer:
      "Any person who is a certified education professional and believes in empowering students.",
  },
  {
    question: "I am a Tutor and I want to register in l3arn. How do I do that?",
    answer:
      "We have created a video (in 2 parts) showing all the required steps to do that. Please check them out.",
    videos: [
      {
        title: "Part 1",
        url: "https://www.youtube.com/watch?v=GNIhHlIb1rg",
      },
      {
        title: "Part 2",
        url: "https://www.youtube.com/watch?v=Rhq0GHXDr78",
      },
    ],
  },
  {
    question: "How can I delete my account?",
    answer: [
      "Click on your profile photo on the menu bar. This will take you to your profile settings.",
      "Navigate to the settings tab.",
      "Select the delete button and confirm that you would like to delete your account.",
      "You will receive an email that will confirm your request for the deletion of your account.",
      "Within 48 hours, you will receive confirmation that your account and all relevant data associated with your account have been deleted.",
    ],
  },
  {
    question: "What is a tutor style?",
    answer:
      "Each tutor has their style of teaching. The tools they use, the way they get students involved, and the way they convey knowledge. Having a matching tutor style to a student's learning technique is what adds the magic to education. Following is the video to the different tutor styles and the tools they use. ",
    linkname: "link",
    videos: [
      {
        title: "Watch here",
        url: "https://firebasestorage.googleapis.com/v0/b/swotknot.appspot.com/o/assets%2FtutorStyleDetails.pdf?alt=media&token=51e1fd7e-0e76-4cd7-86a6-3942acefdb99",
      },
    ],
  },
  {
    question: "What is a learning style?",
    answer:
      "Have you ever wondered why some people learn faster, or some can simply read the book and know it all? We all have our unique combination of learning styles on how we learn. It is essential not to put any child into a box of one learning style. We have eight different learning styles. After students complete our unique learning style quiz, they will receive their top 4 ways of learning new material percentage-wise. They can then get more information on how to study, stay focused, and, most importantly, embrace their unique learning style learning this way more about themselves.When choosing subjects, students can find tutors that match their learning style, embracing their learning uniqueness. https://firebasestorage.googleapis.com/v0/b/swotknot.appspot.com/o/assets%2FtutorStyleDetails.pdf?alt=media&token=51e1fd7e-0e76-4cd7-86a6-3942acefdb99",
    linkname: "read more",
  },
  {
    question: "How to use the Big Blue Button (BBB)?",
    answer: `Bigbluebutton (BBB) is a free software web conferencing system developed in a school, not a boardroom. Its intended use is online learning. BigBlueButton is an affiliate member of the Open Source Initiative.
    Tutor overview of how to improve online learning by creating a more cohesive online classroom experience for students.
    
    Student overview on how the tools work and how to take advantage of the BigBlueButton learning environment to meet and exceed their educational goals.`,
    videos: [
      {
        title: "Student BBB",
        url: "https://www.youtube.com/watch?v=oz9SUisurrA&t=409s",
      },
      {
        title: "Tutor BBB",
        url: "https://www.youtube.com/watch?v=Aw3Ajuy3kyk&t=3s",
      },
    ],
  },
  {
    question: "How to upload my assignment?",
    answer:
      "Navigate to your courses and find your assignments. There is an upload button for the assignments. Make sure you can see your work after you have uploaded it. We don't want you to lose all your hard work! We even advise you to open it online after you have uploaded it.",
  },
  {
    question: "How to use the calendar?",
    answer:
      "When you create a course, a student can then choose a timeslot that they want. That will mark your calendar automatically as not available for that time slot. As a tutor, simply click the time slots you are available or click and drag over a more extended period – these will mark all the slots you are available for any type of teaching. Once you are ready for the lesson, you can simply select the time slot and create a webinar that will take you to a session on our BBB platform. You can also remove a time slot by selecting it and use the remove button.As a student, your calendar will be updated with all your booked lessons. Once you are ready for the class, you will select the timeslots and join the webinar from there. It will automatically authenticate you, so no passwords or usernames are needed again.",
  },
  {
    question: "Is l3arn free?",
    answer: "All services of l3arn are free for the time being. Enjoy it!",
  },
  {
    question: "How can I contact you?",
    answer: `Our team is available most of the time, as we are continuously working on making l3arn the best experience for our users. However, please give us at least 24hours to respond to your requests.
\n
https://swotknot.atlassian.net/servicedesk/ with any issues, complaints, suggestions, or even just tell us about your experience.`,
    linkname: "Visit our Service Desk",
  },
];
