import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import { ReactComponent as Photofile } from "../../../assets/icons/portraits.svg";
import commonColor from "../../../theme/commonColor";
import Modal from "../../common/modal/modal";
import { readFile } from "../../../utils/fileUtils";
import imageCompression from "browser-image-compression";
import {
  uploadFilePromise,
  uploadPhotoPromise,
} from "../../../services/fileServices";
import {
  getTutorPersonalInfo,
  updateStudentProfileDocuments,
} from "../../../services/api.js";
import CircularProgressBar from "../../common/CircularProgressBar/CircularProgressBar";

const StudentDocuments = ({ user, saveUser }) => {
  const [photoError, setPhotoError] = useState(false);
  const [photoOK, setPhotoOK] = useState(false);
  const [photoUploadError, setPhotoUploadError] = useState(false);
  const [photoUploadProgress, setPhotoUploadProgress] = useState(0);
  const [info, setInfo] = useState();
  const [photofileClicked, setPhotofileClicked] = useState(null);

  useEffect(() => {
    console.log("StudentDocuments - useEffect - info updated", info);
    if (!info) {
      getStudentInfo();
    }
  }, [info, user]);

  const getStudentInfo = async () => {
    const result = await getTutorPersonalInfo(user.uid);
    if (result) {
      console.log(
        "StudentDocuments - useEffect - got student personal info",
        result.data
      );
      setInfo(result.data);
    }
  };

  // TODO this is still using tutor endpoints, maybe rename them or separate them
  const uploadPhoto = async (photo, file) => {
    // upload photo to firestore:
    try {
      const result = await uploadFilePromise(
        file,
        photo.type,
        photo.path,
        `profile_photo`,
        `userData/${user.uid}/profile`,
        (progress) => {
          setPhotoUploadProgress(Math.floor(progress));
        }
      );
      setPhotoUploadProgress(0);

      console.log("handleProfilePhotoInput - upload result:", result);
      setPhotoOK(true);
      setPhotoUploadError(false);
      let payload = {
        ...info,
      };
      payload.photoUrl = result.downloadUrl;
      payload.photoFileType = result.fileType;
      console.log("updateTutorProfileDocuments - payload", payload);
      setInfo(payload);
      updateStudentProfileDocuments(user.uid, payload);
      if (saveUser && user) {
        const updatedUser = { ...user, ...payload };
        saveUser(updatedUser);
      }
    } catch (e) {
      console.error("handleProfilePhotoInput - uploading error", e);
      setPhotoUploadError(true);
      setPhotoOK(false);
    }
  };

  const handleProfilePhotoInput = async (acceptedFiles) => {
    console.log("handleProfilePhotoInput - input files", acceptedFiles);
    let toBeUploaded = acceptedFiles[0];
    if (toBeUploaded.type.includes("image")) {
      setPhotoError(false);

      console.log(
        "handleProfilePhotoInput - file is Photo, OK, will read file."
      );
      //compress profile if required
      const imgSize = toBeUploaded.size / 1024 / 1024;
      const maxSizeMB = 1;
      if (imgSize > maxSizeMB) {
        var options = {
          maxSizeMB: maxSizeMB,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(toBeUploaded, options);
        console.log(
          `compressed profileImage size = ${compressedFile.size / 1024 / 1024}`
        );
        toBeUploaded = compressedFile;
      }

      readFile(toBeUploaded, uploadPhoto);
    } else {
      setPhotoError(true);
      setPhotoOK(false);
      console.log("handleProfilePhotoInput - file is NOT Photo, ERROR");
    }
  };

  return (
    <div className="h-full w-full flex justify-around">
      <Modal
        isOpen={!!photofileClicked}
        onRequestClose={() => {
          setPhotofileClicked(null);
        }}
        setMaxSize={true}
      >
        <div className="w-90v md:w-70v lg:w-50v h-80v text-center flex flex-col overflow-y-scroll">
          <div className="mt-auto mb-auto">
            {user.photoUrl ? (
              <img
                src={info?.photoUrl ? info.photoUrl : user.photoUrl}
                alt="infoPic"
                className="m-auto"
              />
            ) : (
              <div className="flex items-center justify-center">
                <Photofile
                  className="self-center"
                  style={{
                    fill: commonColor.deepGreen,
                    backgroundColor: "white",
                    height: "3rem",
                    width: "2.5rem",
                    aspectRatio: 1,
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </Modal>
      {photoError && (
        <div className="text-sm bg-red-500 w-full text-white text-center mb-1">
          {"Photo upload only accepts .png or .jpg files."}
        </div>
      )}
      {photoUploadError && (
        <div className="text-sm bg-red-500 w-full text-white text-center mb-1">
          {"Photo upload error, please try again."}
        </div>
      )}
      <div className="flex flex-col justify-between md:max-w-1/3">
        <div className="m-auto w-full flex justify-center  mt-4 max-h-2/3 overflow-hidden">
          {photoUploadProgress > 0 ? (
            <div className="flex items-center">
              <CircularProgressBar
                strokeWidth="5"
                sqSize="100"
                percentage={photoUploadProgress}
              />
            </div>
          ) : (
            <div
              className={`w-1/2 md:w-full flex items-center shadow-md m-1 overflow-hidden ${
                (info?.photoUrl || user.photoUrl) && "cursor-pointer"
              }`}
            >
              {user.photoUrl ? (
                <img
                  onClick={setPhotofileClicked}
                  src={info?.photoUrl ? info.photoUrl : user.photoUrl}
                  alt="InfoIcon"
                  className="contain w-full m-auto"
                />
              ) : (
                <div className="flex flex-col cursor-default">
                  <p className="text-xs pb-2">
                    Your photo is empty, please update it below.
                  </p>
                  <Photofile
                    className="self-center"
                    style={{
                      fill: commonColor.deepGreen,
                      backgroundColor: "white",
                      height: "3rem",
                      width: "2.5rem",
                      aspectRatio: 1,
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <div className="h-auto md:h-1/3 flex justify-center">
          <div className="flex flex-col justify-center h-32 items-center pt-8 md:pt-0">
            <div className="text-xs pb-2">{"Update your photo"}</div>
            <Dropzone onDrop={handleProfilePhotoInput}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div
                    className="w-32 flex flex-col items-center cursor-pointer focus:outline-none"
                    {...getRootProps()}
                  >
                    <Photofile
                      className="self-center"
                      style={{
                        fill: commonColor.deepGreen,
                        backgroundColor: "white",
                        height: "3rem",
                        width: "2.5rem",
                        aspectRatio: 1,
                      }}
                    />
                    <input
                      className="focus:outline-none"
                      {...getInputProps()}
                    />
                    <div className="text-xs text-center w-full">
                      {/* {photoOK && "Done!"} */}
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDocuments;
