import { connect } from "react-redux";
import MainPageWithMenu from "../../components/common/MainPageTemplate/mainPageWithMenu";
import UserManagementDashboard from "../../components/admin/userManagement/UserManagementDashboard";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const UserManagementPage = ({ user }) => {
  console.log("Betatester", user);

  let navigate = useNavigate();
  useEffect(() => {
    if (user?.accountType !== "SwotAdmin") {
      navigate("/dashboard");
    }
  }, [user?.accountType, navigate]);

  return (
    <MainPageWithMenu user={user} menuSelected={"user-management"}>
      <UserManagementDashboard />
    </MainPageWithMenu>
  );
};
const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementPage);
