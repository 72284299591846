// ...

import React, { useEffect, useState } from "react";
import { ambassadorGetAllWithRef } from "../../../services/api";
import AmbassadorsListItem from "./AmbassadorsListItem";
import "./styles.css";
import FooterView from "../../common/footerView";
import { createCSV } from "../../../utils/csvGenerator";
import SwotButton from "../../common/buttons/swotbutton";


const AmbassadorsDashboard = () => {
    const [ambassadors, setAmbassadors] = useState([]);
    const [exportData, setExportData] = useState([]);


    const fetchData = async () => {
        try {
            const response = await ambassadorGetAllWithRef();
            setAmbassadors(response.data);

            let tmpExport = [];

            response.data.map((ambassador, index) => {
                ambassador.ambassadors_ref.map((ref) => {
                    tmpExport.push({
                        email: ref.email,
                        role: ref.role,
                        refer_by: ref.refer_by,
                        phone: ref.phone,
                        status: ref.status,
                        commission_recevied: ref.commission_recevied,
                        course_name: ref.course_name,
                        enrolled_date: ref.enrolled_date,
                        country: ref.country,
                        name: ref.name,
                        refer_type: ref.refer_type,
                        ambassador_username: ambassador.username,
                        ambassador_email: ambassador.email
                    });
                });
            });

            console.log(tmpExport);
            setExportData(tmpExport);

        } catch (error) {
            console.error("Error fetching data", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div
            className="w-full rounded-2xl p-4 h-full  flex flex-col">
            <div className="flex justify-between items-middle">
                <div className="text-white">Ambassadors Dashboard</div>
                <SwotButton
                    text={"Download Ambassadors Data"}
                    onClick={() => {
                        createCSV(exportData, "all_ambassadors_data");
                    }}
                />

                <button
                    className="border-white p-4 border-2 text-white rounded-2xl focus:outline-none"
                    onClick={() => {
                        fetchData();
                    }}
                >
                    Refresh
                </button>
            </div>

            <div className="p-4 flex flex-col flex-grow">
                <div className="text-white">Ambassadors List</div>

                <div className="">
                    {ambassadors.map((ambassador, index) => (
                        <AmbassadorsListItem key={ambassadors.id ?? index} ambassador={ambassador} />
                    ))}
                </div>
                <FooterView />
            </div>
        </div>

    );
};

export default AmbassadorsDashboard;
