import React, { useEffect, useState } from "react";
import Modal from "../../../components/common/modal/modal";
import { ReactComponent as Del } from "../../../assets/icons/trash.svg";
import { ReactComponent as Edit } from "../../../assets/icons/editing.svg";
import commonColor from "../../../theme/commonColor";
import Swotbutton from "../../../components/common/buttons/swotbutton";

const CourseContentEditor = ({ courseId, contentItem, onSave }) => {
  const [courseTitle, setCourseTitle] = useState(contentItem?.title);
  const [courseDescription, setCourseDescription] = useState(contentItem?.body);

  const clickHandler = async () => {
    onSave && onSave(courseId, courseTitle, courseDescription, contentItem?.id);
  };

  return (
    <>
      <div
        className="h-60v w-11/12 md:h-70v lg:h-50v md:w-50v flex justify-center items-center"
        //   style={{
        //       width: "50vw",
        //       height:"60vh"
        //   }}
      >
        <div className="w-full h-full relative bg-white">
          <div className="w-full h-full flex pb-1 flex-col items-start justify-start">
            <div className="w-full">
              <p>Title:</p>
              <input
                type="text"
                className="my-1 py-2 w-full placeholder-white px-4 text-white rounded-md"
                style={{
                  backgroundColor: commonColor.deepBlue,
                }}
                name="courseTitle"
                value={courseTitle}
                placeholder="Title"
                id="courseTitle"
                onChange={(event) => setCourseTitle(event.target.value)}
              />
            </div>
            <div
              className="flex-grow w-full flex flex-col items-start justify-start"
              w-full
            >
              <p>Description:</p>
              <textarea
                type="textarea"
                className="my-1 py-2 w-full flex-grow placeholder-white px-4 text-white"
                style={{
                  backgroundColor: commonColor.deepBlue,
                }}
                name="textValue"
                value={courseDescription}
                placeholder="Description.."
                id="courseDescription"
                onChange={(event) => setCourseDescription(event.target.value)}
              />
            </div>
            <div className="w-full flex justify-start flex-row-reverse">
              <Swotbutton text={"Save"} onClick={clickHandler} />
            </div>
          </div>
        </div>
      </div>

      {/* <Modal
        isOpen={!!viewItem}
        onRequestClose={() => {
          setViewItem(null);
        }}
      >
        {viewItem && (
          <div className="w-full h-full text-center">
            <Viewer file={viewItem} />
          </div>
        )}
      </Modal> */}
    </>
  );
};

export default CourseContentEditor;
