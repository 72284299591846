import React from "react";
// import ListGroupItem from 'react-bootstrap/ListGroupItem';
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import Button from 'react-bootstrap/Button';
// import Overlay from 'react-bootstrap/Overlay';
// import Tooltip from 'react-bootstrap/Tooltip';
// import Dropdown from 'react-bootstrap/Dropdown';
// import Modal from 'react-bootstrap/Modal';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFingerprint,
  faBookOpen,
  faWindowClose,
  faSave,
  faCheck,
  faPlay,
  faPencilAlt,
  faFileDownload,
  faTrashAlt,
  faCopyright,
} from "@fortawesome/free-solid-svg-icons";

import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";

import {
  IContentGroup,
  IContentListEntry,
  IContentService,
} from "../services/ContentService";
import "./ContentListEntryComponent.css";
import Select from "../../components/common/input/select";
import { divide } from "lodash";
import Modal, { QuickModal } from "../../components/common/modal/modal";
import Swotbutton from "../../components/common/buttons/swotbutton";
import commonColor from "../../theme/commonColor";
import Swal from "sweetalert2";
import ContentCourseListComponent from "./ContentCourseListComponent";
import { IContentMetadata } from "@lumieducation/h5p-server";
import { updateH5pContent } from "../../services/api";

export default class ContentListEntryComponent extends React.Component<{
  contentService: IContentService;
  data: IContentGroup;
  onPlay: (content: IContentListEntry) => void;
  onEdit: (content: IContentListEntry) => void;
  onDelete: (content: IContentListEntry) => void;
  onDiscard: (content: IContentListEntry) => void;
  onSaved: (data: IContentListEntry, oldData?: IContentListEntry) => void;
  generateDownloadLink: (contentId: string) => string;
  manageCourse: (contentId: string) => void;
}> {
  constructor(props: {
    contentService: IContentService;
    data: IContentGroup;
    onPlay: (content: IContentListEntry) => void;
    onEdit: (content: IContentListEntry) => void;
    onDiscard: (content: IContentListEntry) => void;
    onDelete: (content: IContentListEntry) => void;
    onSaved: (data: IContentListEntry, oldData?: IContentListEntry) => void;
    generateDownloadLink: (contentId: string) => string;
    manageCourse: (contentId: string) => void;
  }) {
    super(props);
    this.state = {
      editing: props.data.contentId === "new",
      playing: false,
      saving: false,
      saved: false,
      loading: true,
      saveErrorMessage: "",
      saveError: false,
      showingCustomCopyright: false,
    };
    this.h5pEditor = React.createRef();
    this.saveButton = React.createRef();
    this.h5pPlayer = React.createRef();
  }

  public state: {
    editing: boolean;
    loading: boolean;
    playing: boolean;
    saved: boolean;
    saving: boolean;
    saveError: boolean;
    saveErrorMessage: string;
    showingCustomCopyright: boolean;
  };

  private h5pPlayer: React.RefObject<H5PPlayerUI>;
  private h5pEditor: React.RefObject<H5PEditorUI>;
  private saveButton: React.RefObject<HTMLButtonElement>;

  actionButtons = () => {
    return (
      //   <React.Fragment>
      <div className="flex">
        <div className="p-2 text-sm">
          <button
            // variant="success"
            onClick={() => {
              //this.play()
              this.props.onPlay(this.props.data);
            }}
          >
            <FontAwesomeIcon icon={faPlay} className="me-2" /> Play
          </button>
        </div>
        <div className="p-2 text-sm">
          <button
            // variant="secondary"
            onClick={() => {
              // this.edit()
              this.props.onEdit(this.props.data);
            }}
          >
            <FontAwesomeIcon icon={faPencilAlt} className="me-2" /> Edit
          </button>
        </div>{" "}
        <div className="p-2 text-sm">
          <a href={this.props.generateDownloadLink(this.props.data.contentId)}>
            <button>
              <FontAwesomeIcon icon={faFileDownload} className="me-2" />{" "}
              Download
            </button>
          </a>
        </div>
        <div className="p-2 text-sm">
          <button
            // variant="danger"
            onClick={() => {
              Swal.fire({
                title: this.props.data.contents[0].content.title,
                text: "Do you want to delete this content?",
                icon: "warning",
                showDenyButton: true,
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: "Cancel",
                denyButtonText: `Delete`,
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isDenied) {
                  this.props.onDelete(this.props.data.contents[0].content);
                }
              });
            }}
          >
            <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Delete
          </button>
        </div>
        {/* </React.Fragment> */}
      </div>
    );
  };

  actionEditting = () => {
    return (
      <div className="flex">
        {this.state.playing ? (
          <div className="p-2 text-sm">
            <Swotbutton
              text="Close"
              onClick={() => {
                this.close();
              }}
            />
          </div>
        ) : undefined}

        {this.state.editing ? (
          <div className="p-2">
            <Swotbutton
              text="Save"
              loading={this.state.saving}
              disabled={this.state.saving || this.state.loading}
              onClick={() => this.save()}
            />
          </div>
        ) : undefined}

        {this.state.editing && !this.isNew() ? (
          <div className="p-2 text-sm">
            <Swotbutton
              text="Close"
              onClick={() => {
                this.close();
              }}
            />
          </div>
        ) : undefined}

        {this.state.editing && this.isNew() ? (
          <div className="p-2  text-sm">
            <button
              // variant="light"
              onClick={() =>
                this.props.onDiscard(this.props.data.contents[0].content)
              }
            >
              <FontAwesomeIcon icon={faWindowClose} className="me-2" /> Discard
            </button>
          </div>
        ) : undefined}
      </div>
    );
  };

  public render(): React.ReactNode {
    const options = [
      { value: "Copyright", label: "Copyright" },
      { value: "Show in custom dialog", label: "Show in custom dialog" },
      {
        value: "Show in native H5P dialog",
        label: "Show in native H5P dialog",
      },
    ];

    const { data } = this.props;
    const coursesString = data.contents.reduce((previous, current) => {
      if (current.course_name_short) {
        return (
          previous + (previous !== "" ? ", " : "") + current.course_name_short
        );
      }
      return previous;
    }, "");
    const courses = data.contents
      .map((c) => c.course_name_short)
      .filter((element) => {
        return element !== null && element !== "" && element !== undefined;
      });

    return (
      // <ListGroupItem
      <div key={data.contentId}>
        {/* <Container> */}
        <div className="w-full">
          {/* <Row> */}
          <div className="w-full flex flex-col justify-start">
            <div className="px-2 pt-2 flex flex-col justify-start">
              <div className="font-bold text-md">
                {data.contents[0].content.title}
              </div>
              <div className="w-full flex justify-between items-start">
                <div className="small text-xs">
                  <div className="me-2">
                    <FontAwesomeIcon icon={faBookOpen} className="me-1" />
                    {data.contents[0].content.mainLibrary}
                  </div>
                  <div className="me-2">
                    <FontAwesomeIcon icon={faFingerprint} className="me-1" />
                    {data.contentId}
                  </div>
                </div>
                <div className="flex flex-col items-end">
                  <div className="text-black break-words max-w-28 sm:max-w-40 lg:max-w-60 text-sm pr-2">
                    {courses.join(", ")}
                  </div>
                  <Swotbutton
                    text={"Manage Courses"}
                    onClick={() => {
                      this.props.manageCourse(this.props.data.contentId);
                    }}
                  />
                </div>
              </div>
            </div>
            {this.state.editing || this.state.playing
              ? this.actionEditting()
              : !this.isNew()
                ? this.actionButtons()
                : undefined}
          </div>
        </div>
        {/* </Container> */}
        {/*
        {this.state.editing ? (
          <div
            className={
              this.props.data.contentId !== "new" && this.state.loading
                ? "loading"
                : ""
            }
          >
            <H5PEditorUI
              ref={this.h5pEditor}
              contentId={this.props.data.contentId}
              loadContentCallback={this.props.contentService.getEdit}
              saveContentCallback={this.props.contentService.save}
              onSaved={this.onSaved}
              onLoaded={this.onEditorLoaded}
              onSaveError={this.onSaveError}
            />
          </div>
        ) : undefined}
        {this.state.playing ? (
          <div className={this.state.loading ? "loading" : ""}>
            <H5PPlayerUI
              ref={this.h5pPlayer}
              contentId={this.props.data.contentId}
              loadContentCallback={this.props.contentService.getPlay}
              onInitialized={this.onPlayerInitialized}
              onxAPIStatement={(statement: any, context: any, event) =>
                console.log(statement, context, event)
              }
            />
            <div
              style={{
                visibility: this.state.loading ? "visible" : "collapse",
              }}
              className="spinner-border spinner-border-sm m-2"
              role="status"
            ></div>
          </div>
        ) : undefined}
        */}

        <QuickModal
          isOpen={this.state.showingCustomCopyright}
          onRequestClose={() => {
            this.setState({ showingCustomCopyright: false });
          }}
          title={"Copyright information"}
          isCloseButtonShown={true}
          firstButtonTitle={"Close"}
        >
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  this.h5pPlayer.current?.getCopyrightHtml() ??
                  "No copyright information",
              }}
            ></div>
          </div>

          <div>
            <button
              onClick={() => {
                this.closeCopyrightCustom();
              }}
            >
              Close
            </button>
          </div>
        </QuickModal>
      </div>
    );
  }

  protected play() {
    this.setState({ editing: false, playing: true });
  }

  protected edit() {
    this.setState({ editing: true, playing: false });
  }

  protected close() {
    this.setState({ editing: false, playing: false });
  }

  protected showCopyrightCustom() {
    this.setState({ showingCustomCopyright: true });
  }

  protected closeCopyrightCustom() {
    this.setState({ showingCustomCopyright: false });
  }

  protected showCopyrightNative() {
    if (this.h5pPlayer.current)
      this.h5pPlayer.current.showCopyright();
  }

  private onPlayerInitialized() {
    this.setState({ loading: false });
  };

  protected async save() {
    this.setState({ saving: true });
    try {
      const returnData = await this.h5pEditor.current?.save();
      if (returnData) {
        var oldData = this.props.data.contents[0].content;
        await this.props.onSaved(
          {
            contentId: returnData.contentId,
            mainLibrary: returnData.metadata.mainLibrary,
            title: returnData.metadata.title,
            originalNewKey: oldData.originalNewKey,
          },
          oldData
        );
      }
    } catch (error) {
      // We ignore the error, as we subscribe to the 'save-error' and
      // 'validation-error' events.
    }
  }

  protected onSaveError = async (event) => {
    this.setState({
      saving: false,
      saved: false,
      saveError: true,
      saveErrorMessage: event.detail.message,
    });
    setTimeout(() => {
      this.setState({
        saveError: false,
      });
    }, 5000);
  };

  protected onSaved = async (contentId, metadata?: IContentMetadata) => {
    const lib = metadata?.mainLibrary;
    const title = metadata?.title;
    const response = await updateH5pContent(contentId, title);
    this.setState({
      saving: false,
      saved: false,
    });
    this.close();
  };

  protected onEditorLoaded = () => {
    this.setState({ loading: false });
  };

  private isNew() {
    return this.props.data.contentId === "new";
  }
}
