import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import commonColor from "../../../theme/commonColor";
import Swotbutton from "../../../components/common/buttons/swotbutton";
import Select from "../../common/input/select";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useWindowSize } from "../../../utils/uiUtils";
import CourseListItem from "../../student/dashboard/CourseListItem";

import {
  getAllFreeCourses,
  getCourseDetails,
  studentJoinVideoSession,
} from "../../../services/api";
import ReactPlayer from "react-player/lazy";
import moment from "moment";

const GuestStudentDashboard = ({ user }) => {
  let navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("lesson");
  const [courses, setCourses] = useState([]);
  const [courseDetails, setCourseDetails] = useState();
  const [courseLoaded, setCourseLoaded] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState();
  const [selectedCourseNameShort, setSelectedCourseNameShort] = useState();
  const [myLessons, setMyLessons] = useState([]);
  const [latestSession, setLatestSession] = useState();

  const [width, height] = useWindowSize();

  useEffect(() => {
    const getFreeCourses = async () => {
      //const result = await getOpenCoursesForStudent(user.uid);
      // TODO: this is a test account, change.
      const result = await getAllFreeCourses();
      if (result) {
        console.log(
          "GuestStudentDashboard - useEffect - getFreeCourses",
          result
        );

        const fetchedCourses = result.data.courses;
        setCourses(fetchedCourses);

        if (fetchedCourses && fetchedCourses.length > 0) {
          setSelectedCourseId(fetchedCourses[0].courseId);
          setSelectedCourseNameShort(fetchedCourses[0].nameShort);

          const studentCourses = result.data.courses.map((course) => {
            return { value: course.courseId, label: course.nameShort };
          });
          setMyLessons(studentCourses);
        }
      }
    };

    getFreeCourses();
  }, [user.uid]);

  useEffect(() => { }, [width, height, latestSession]);

  useEffect(() => {
    console.log(
      "CourseDetails - useEffect - selectedCourseId / courseLoaded",
      selectedCourseId,
      courseLoaded
    );
    const getCourseInfo = async () => {
      const result = await getCourseDetails(selectedCourseId);
      if (result) {
        console.log(
          "CourseDetails - useEffect - got course details",
          result.data
        );
        setCourseDetails(result.data);
        setSelectedCourseNameShort(result.data.nameShort);
        setCourseLoaded(true);
      }
    };

    if (!courseLoaded) {
      getCourseInfo();
    }

    // TODO: get latest session info (webinars)
    // setLatestSession({
    //   sessionId: 16,
    //   start: moment.now(),
    // });
  }, [selectedCourseId, courseLoaded]);

  const renderWebinarButton = () => {
    if (latestSession && moment().isSameOrAfter(latestSession.start)) {
      return (
        <button
          className="focus:outline-none focus:shadow-lg"
          style={{
            bottom: "1rem",
            right: "1rem",
            position: "absolute",
          }}
        >
          <Swotbutton text="go to class" onClick={() => goToCourseSession()} />
        </button>
      );
    } else {
      return (
        <button
          disabled
          className="focus:outline-none focus:shadow-lg"
          style={{
            bottom: "1rem",
            right: "1rem",
            position: "absolute",
          }}
        >
          <Swotbutton text="No webinar at the moment" />
        </button>
      );
    }
  };

  const renderCourseMaterial = () => {
    console.log("renderCourseMaterial -  courses found", courses.length);
    if (courses.length > 0) {
      /**
       *  "courseId": 2,
                  "nameFull": "marketing for apples",
                  "nameShort": "mark apple",
                  "subjectArea": "Marketing",
                  "photoUrl": "ajajkajajajajaj",
                  "tutorUsername": "Tasos"
       */
      return courses.map((course) => {
        return (
          <CourseListItem
            course={course}
            selected={false}
            setSelected={() => { }}
          />
        );
      });
    } else if (!courseLoaded) {
      return <div>Fetching material...</div>;
    } else {
      return <div>There are is no material at the moment.</div>;
    }
  };

  const goToCourseSession = async () => {
    console.log("Going to class!");
    const result = await studentJoinVideoSession(
      user.uid,
      latestSession.sessionId
    );

    if (result.ok) {
      console.log("goToCourseSession - result", result);
    }
  };

  return (
    <div className=" h-full w-full flex flex-col justify-between overflow-y-scroll">
      {courseLoaded ? (
        <div className=" h-full w-full flex flex-wrap justify-center">
          <div className="h-7/10 w-11/12 lg:w-3/4 xl:w-1/2 flex flex-col">
            <div
              className=" w-full flex justify-between items-center"
              style={{
                height: "10%",
              }}
            >
              <div className="w-1/4 text-white px-2">Lesson</div>
              <div className="w-3/4 py-1">
                <Select
                  className=" w-full focus:outline-none"
                  isRadiusUsed
                  //value={{ value: selectedCourseId, label: selectedCourseNameShort }}
                  defaultValue={{
                    value: courseDetails?.courseId,
                    label: courseDetails?.nameShort,
                  }}
                  value={
                    selectedCourseNameShort
                      ? {
                        value: selectedCourseId,
                        label: selectedCourseNameShort,
                      }
                      : myLessons[0]
                  }
                  onChange={(value) => {
                    console.log("CourseDetails- select- value", value);
                    setCourseLoaded(false);
                    setSelectedCourseId(value.value);
                    // setTutoringRegistration(value)
                    // handleChange('tutoringRegistration', value.value)
                  }}
                  options={myLessons}
                />
              </div>
            </div>

            <div
              className="w-full  bg-transparent darker_shadow"
              style={{
                borderRadius: "1.5rem",
                height: "85%",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <ReactPlayer
                url="https://www.youtube.com/watch?v=saQBW9Xs5eI"
                width={"100%"}
                height={"100%"}
                style={{
                  borderRadius: 25,
                }}
              />
              {renderWebinarButton()}
            </div>
          </div>

          <div className="h-7/10 w-11/12 lg:w-3/4 xl:w-1/2 flex flex-col">
            <div className="h-full w-full xl:px-6">
              <div
                className="w-full flex justify-start items-end pl-6"
                style={{
                  height: "10%",
                }}
              >
                <button
                  className="w-1/4 pt-2 px-2 pb-1 text-center text-white text-sm focus:outline-none"
                  style={{
                    backgroundColor: commonColor.deepPink,
                    borderTopLeftRadius: "1.5rem",
                    borderTopRightRadius: "1.5rem",
                  }}
                // onClick={() => setTopSelected("Schedule")}
                >
                  About
                </button>
              </div>
              <div
                className="w-full h-9/10 bg-white darker_shadow overflow-y-scroll"
                style={{
                  borderRadius: "1.5rem",
                  height: "85%",
                }}
              >
                <div className="w-full h-full p-4 flex flex-col items-start justify-start">
                  <div className="">Curriculum</div>
                  <div className="text-xs pt-4">
                    Content...Content...Content...Content...Content...Content...
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full h-3/7 flex justify-center items-start flex-wrap overflow-x-scroll">
            {renderCourseMaterial()}
          </div>
        </div>
      ) : (
        <div className="italic">Loading course...</div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps, {})(GuestStudentDashboard);
