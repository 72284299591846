import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import UserActions from "../../redux/user";
import closeIcn from "../../assets/images/quizCards/cross-icon.png";
import tickIcn from "../../assets/images/quizCards/tick-icon.png";
import shareIcon from "../../assets/images/quizCards/icon.png";
import infoIcon from "../../assets/images/quizCards/info-icon.png";
import DotIndicator from "../../components/common/dotIndicators";
import ReactTinderCard from "../../components/reactTinderCard/reactTinderCard";
import commonColor from "../../theme/commonColor";
import Swotbutton from "../../components/common/buttons/swotbutton";
import ReactCardFlip from "react-card-flip";
import "./learningStyleQuiz.css";
import ShareModal from "../../components/common/share/shareModal";
import LandingTitle from "../../components/landing/landingTitle";
import { useNavigate } from "react-router-dom";

const QuizCardBox = (props) => {
  const q =
    (props.cardType ?? "swipe") === "select"
      ? props.questionaire.questions ?? []
      : [...(props.questionaire.questions ?? [])].reverse();
  const [questionaire, setQuestionaire] = useState(q);
  const [questions, setQuestions] = useState(props.questionaire.questions);
  const [alreadyRemoved, setAlreadyRemoved] = useState([]);
  const [characters, setCharacters] = useState(questions);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [cardType, setCardType] = useState(props.cardType ?? "swipe");
  const [isFlipped, setisFlipped] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [shareSubject, setShareSubject] = useState();
  const [shareMessage, setShareMessage] = useState();
  const [cardClicked, setCardClicked] = useState(false);
  const navigate = useNavigate();

  const childRefs = useMemo(
    () =>
      Array(questions.length)
        .fill(0)
        .map((i) => React.createRef()),
    [questions]
  );

  if (props.questionaire.key !== questionaire.key) {
    setAlreadyRemoved([]);
    setQuestionaire(props.questionaire);
    setQuestions(q);
    setCharacters(q);
    setCurrentIndex(0);
    setCardType(props.cardType ?? "swipe");
  }

  const swiped = (direction, keyToDelete) => {
    console.log("removing: " + keyToDelete);
    var removedArr = alreadyRemoved;
    removedArr.push(keyToDelete);
    setAlreadyRemoved(removedArr);
    const cardsLeft = characters.filter(
      (question) => !alreadyRemoved.includes(question.key)
    );
    setCurrentIndex(questions.length - cardsLeft.length);
    props.swiped(questionaire.key, keyToDelete, direction === "right");
    if (cardsLeft.length === 0 && props.allCardsSwiped) {
      // setTimeout(() => {
      props.allCardsSwiped(questionaire.key);
      // }, 1500);
    }
  };

  const outOfFrame = (key) => {
    console.log(key + " left the screen!");
    const cardsLeft = characters.filter(
      (question) => !alreadyRemoved.includes(question.key)
    );
    if (cardsLeft.length > 0) {
      setCharacters(cardsLeft);
    } else {
      console.log("");
    }
  };

  const swipe = (dir) => {
    const cardsLeft = characters.filter(
      (question) => !alreadyRemoved.includes(question.key)
    );
    if (cardsLeft.length) {
      const toBeRemoved = cardsLeft[cardsLeft.length - 1].key; // Find the card object to be removed
      const index = questions
        .map((question) => question.key)
        .indexOf(toBeRemoved); // Find the index of which to make the reference to
      if (childRefs[index] && childRefs[index].current) {
        var removedArr = alreadyRemoved;
        removedArr.push(toBeRemoved);
        setAlreadyRemoved(removedArr);
        childRefs[index].current.swipe(dir); // Swipe the card!
      }
    }
  };

  // function importAll(r) {
  //   let images = {};
  //   r.keys().map((item, index) => {
  //     images[item.replace("./", "")] = r(item).default;
  //   });
  //   return images;
  // }

  const shareInfo = () => {
    setShareSubject(`${questionaire.title}`);
    setShareMessage(
      `I am a ${questionaire.title}. Want to find out what type of learner you are? follow the link`
    );
    setOpenShare(true);
  };

  return (
    <div
      className="w-full h-full "
      style={{
        userSelect: "none",
      }}
    >
      <link
        href="https://fonts.googleapis.com/css?family=Damion&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Alatsi&display=swap"
        rel="stylesheet"
      />

      <div className="flex flex-col justify-center items-center w-full">
        {/* {cardType === "select" ? (
          <div className="w-full flex justify-end">
            <p
              className="mt-6  text-md text-gray-500 text-center lg:text-right align-text-bottom lg:h-6 h-auto pr-8"
              style={{
                fontSize: "1.25rem",
              }}
            >
              Learner Type
            </p>
          </div>
        ) : (
          ""
        )}

 */}
        <div
          className={
            "flex flex-col justify-center items-center mb-8 lg:mb-4 mt-4 text-sm p-0.5" +
            (cardType === "select" ? "" : " cardHeader")
          }
        >
          <span
            className="text-center"
            style={{
              whiteSpace: "pre-line",
            }}
          >
            {questionaire?.title}
          </span>
        </div>
        <div
          className={
            "text-sm relative mt-4 flex-grow flex justify-center items-center w-full md:w-3/4  " +
            (cardType === "select" ? "" : " quizCardHeight cardContainer")
          }
        >
          {cardType === "select" && (
            <SelectionBoxCard
              data={characters}
              stackIndex={0}
              onSelection={(item) => {
                props.swiped(questionaire.key, item.key, true);
              }}
            />
          )}

          {cardType === "result" && (
            //   <CardContent
            //   title={"testing"}
            //   Icon={questionaire.icon}
            // />

            <ResultCard
              Icon={questionaire.icon}
              message={questionaire.message}
              isFlipped={isFlipped}
              shareClick={() => {
                // setShowModal(true);
                shareInfo();
              }}
              infoClick={() => {
                setisFlipped(!isFlipped);
              }}
              toggleFlip={() => {
                setisFlipped(!isFlipped);
              }}
            />
          )}
          {cardType === "swipe" &&
            [...characters].map((item, index) => (
              <ReactTinderCard
                ref={childRefs[index]}
                className="swipe w-full h-full"
                key={item.key}
                onSwipe={(dir) => swiped(dir, item.key)}
                onCardLeftScreen={() => outOfFrame(item.key)}
                preventSwipe={[
                  ...["up", "down"],
                  ...(props.preventSwipe ? props.preventSwipe : []),
                ]}
              >
                <CardContent
                  title={item.title}
                  Icon={item.imageIcon}
                  stackIndex={characters.length - 1 - index}
                />
              </ReactTinderCard>
            ))}

          {cardType === "swipe" && (
            <div className="absolute w-full bottom-0 flex flex-row justify-between opacity-0">
              <button
                className="w-3/10"
                onClick={() => {
                  if (
                    props.preventSwipe &&
                    props.preventSwipe.includes("left")
                  ) {
                    return;
                  }
                  swipe("left");
                  setButtonsDisabled(true);
                  setTimeout(() => setButtonsDisabled(false), 1000);
                }}
                disabled={buttonsDisabled}
              >
                <img
                  src={closeIcn}
                  alt="closeIcn"
                  className="object-contain w-full"
                />
              </button>
              <button
                className="w-3/10"
                onClick={() => {
                  if (
                    props.preventSwipe &&
                    props.preventSwipe.includes("right")
                  ) {
                    return;
                  }
                  swipe("right");
                  setButtonsDisabled(true);
                  setTimeout(() => setButtonsDisabled(false), 1000);
                }}
                disabled={buttonsDisabled}
              >
                <img
                  src={tickIcn}
                  alt="tickIcn"
                  className="object-contain w-full"
                />
              </button>
            </div>
          )}
        </div>
        <div className="cardFooter flex flex-col justify-center items-center">
          {cardType === "swipe" && (
            <div className="flex items-center">
              <DotIndicator
                count={questions.length}
                currentIndex={currentIndex}
                radius={15}
                activeRadius={20}
              />
            </div>
          )}
          {cardType === "result" && (
            <div className="flex items-center justify-center">
              <span className="text-center text-sm mt-10">
                If you want to have a better profile on the
                <br />
                type of learner you are,{" "}
                <a
                    onClick={() => {
                      navigate("/sign-in");
                    }}
                    style={{
                      color: commonColor.deepGreen,
                      textDecoration: "underline",
                      cursor: "pointer",
                       fontWeight: "bold",
                        fontSize: "medium"
                    }}
                  >
                    SignUp here
                  </a>
                
                <div className="flex justify-center w-full mt-4">
                  <div className="ml-2 sm:ml-4">
                    <Swotbutton
                      text={"Reset"}
                      // to={"/learner-type-new"}
                      bgColor={commonColor.deepPink}
                      onClick={() => {
                        if (props.reset) {
                          props.reset();
                        }
                      }}
                    ></Swotbutton>
                    
                  </div>
                </div>
              </span>
              {/* <span className="text-center">
                We are sure you want to learn even more about what your learner profile is. <br />Why not visit <a
                    href="https://www.swotknot.com"
                    target="_blank"
                    style={{
                      color: commonColor.deepGreen,
                      textDecoration: "under-line",
                    }}
                  >
                    swotknot.com
                  </a> to find out more about knowing your learning style can allow you to learn better? 
                </span> */}
            </div>
          )}
        </div>
        {/* TODO */}
        <div>
          {cardType === "swipe" ? (
            <span className="italic text-sm">
              Swipe right if you agree, left if you don't
            </span>
          ) : null}
        </div>
      </div>

      <ShareModal
        subject={shareSubject}
        message={shareMessage}
        open={openShare}
        onRequestClose={() => {
          setOpenShare(false);
        }}
      />
    </div>
  );
};

const CardContent = ({ title, Icon, stackIndex }) => {
  return (
    <div
      className={`relative card w-full h-full ${
        stackIndex === 1 && "-rotate-3 -translate-y-3"
      } ${stackIndex === 2 && "rotate-3 -translate-y-6"}`}
    >
      <div
        className="h-4/5 w-full flex flex-col justify-center items-center"
        style={{
          cursor: "grabbing",
        }}
      >
        <div className="w-full h-full flex flex-col justify-start items-center p-4">
          <div className="w-4/5 h-3/10 text-center">
            <span
              className="w-full text-center"
              style={{
                whiteSpace: "pre-line",
              }}
            >
              {title}
            </span>
          </div>
          <div className="w-full h-7/10 flex justify-center">
            {/* <CardIcon1 className="w-full" /> */}
            <Icon className="w-4/5" />
          </div>
        </div>
      </div>
      <div className="absolute w-full bottom-0 flex flex-row justify-between">
        <button className="w-3/10">
          <img
            src={closeIcn}
            alt="closeIcn"
            className="object-contain w-full"
          />
        </button>
        <button className="w-3/10">
          <img src={tickIcn} alt="tickIcn" className="object-contain w-full" />
        </button>
      </div>
    </div>
  );
};

const SelectionBoxCard = ({ data, stackIndex, onSelection }) => {
  return (
    <div className="w-full h-full">
      <div className="h-full w-full flex justify-center items-center">
        <div className="w-full flex-col sm:flex-row h-full flex justify-around items-center gap-4 flex-wrap">
          {data.map((item, index) => (
            <div
              className="flex flex-col justify-start items-center cursor-pointer mb-4 sm:mb-0"
              onClick={() => {
                onSelection && onSelection(item);
              }}
            >
              <div>
                <span className="font-semibold text-base sm:text-sm">
                  {item.title}
                </span>
              </div>
              <div className="bg-white relative ageCard brightness-75">
                {/* <img src={item.img} /> */}
                <item.imageIcon className="w-full ageImage" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const ResultCard = ({
  Icon,
  message,
  isFlipped,
  shareClick,
  infoClick,
  toggleFlip,
}) => {
  return (
    <>
      <ReactCardFlip
        isFlipped={isFlipped}
        flipDirection="horizontal"
        className="w-full h-full"
      >
        <div
          className="relative card w-full h-full"
          style={{
            marginTop: "-12px",
          }}
        >
          <div className="h-4/5 w-full flex flex-col justify-center items-center">
            <div className="w-full h-full flex flex-col justify-start items-center p-4">
              <div className="w-full h-full flex justify-center">
                <Icon className="w-full h-full" />
                {/* <span> Image will be here</span> */}
              </div>
            </div>
          </div>
          <div className="absolute w-full bottom-0 flex flex-row justify-between">
            <button
              className="w-3/10"
              style={{
                outline: "none",
                boxShadow: "none",
                opacity: 0
              }}
              onClick={() => {
                // shareClick();
              }}
            >
              <img
                src={shareIcon}
                alt="shareIcon"
                className="object-contain w-full"
              />
            </button>
            <button
              className="w-3/10"
              style={{
                outline: "none",
                boxShadow: "none",
              }}
              onClick={() => {
                infoClick();
              }}
            >
              <img
                src={infoIcon}
                alt="infoIcon"
                className="object-contain w-full"
              />
            </button>
          </div>
        </div>
        <div
          className="relative card w-full h-full"
          style={{
            marginTop: "-12px",
          }}
          onClick={() => {
            toggleFlip();
          }}
        >
          <div className="h-full w-full flex flex-col justify-center items-center">
            <div className="w-full h-full flex flex-col justify-start items-center p-4">
              <div className="w-full h-full flex justify-start items-center flex-col overflow-y-scroll mt-2">
                <span className="text-sm sm:text-base">{message}</span>
              </div>
            </div>
          </div>
        </div>
      </ReactCardFlip>
      <div></div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizCardBox);
