import moment from "moment";
import React from "react";

export default function TimeSlot({
  value,
  children,
  dateFormat,
  timeFormat = "hh A",
  dateClass = "",
  timeClass = "",
}) {
  if (!children?.props?.children) return null;

  const date = moment(value);
  return (
    // <div className="flex flex-1 items-center px-2">
    //   {dateFormat && <div className={`w-1/2 ${dateClass}`}>{date.format(dateFormat)}</div>}
    //   <div className={`w-1/2 text-center ${timeClass}`}>{date.format(timeFormat)}</div>
    // </div>

    <div className="flex flex-row-reverse flex-1 items-center px-2 pr-4">
      <div className={`text-center ${timeClass}`}>
        {date.format(timeFormat)}
      </div>
    </div>
  );
}
