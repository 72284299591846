import React, { useState } from "react";
import "./PersonaStyle.css";
import LandingLayout from "../../../components/landing/landingLayout";
import BetaSignupModal from ".././betaSignupModal";
import LoaderModal from "../../../components/common/modal/loaderModal";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Swotbutton from "../../../components/common/buttons/swotbutton.tsx";
import slideImage1 from "../../../assets/images/PersonaPageImages/ParentDif/parent-diff-1.png";
import slideImage2 from "../../../assets/images/PersonaPageImages/ParentDif/parent-diff-2.png";
import slideImage3 from "../../../assets/images/PersonaPageImages/ParentDif/parent-diff3.png";
import { useNavigate } from "react-router-dom";

const words = ["The Basics", "What we offer?", "Why l3arn?"];

const indicators = (index) => {
  return (
    <div className="text-base lg:text-xl font-semibold indicator">
      {words[index]}
    </div>
  );
};
const fadeProperties = {
  duration: 30000,
  pauseOnHover: true,
  autoplay: false,
  indicators: indicators,
  easing: "ease-out",
  canSwipe: true,
};

// <!-- Width of 16 by default, 32 on medium screens, and 48 on large screens -->
// <img class="w-16 md:w-32 lg:w-48" src="...">

const Slideshow = () => {
  let navigate = useNavigate();

  return (
    <div className="sm:pl-4 sm:pr-5 max-w-full h-full persona">
      <div className="slide-container m-6 sm:w-full w-11/12 ">
        <Slide className="relative" {...fadeProperties}>
          <div className="lg:flex-row flex-col | each-slide-effect flex  items-center justify-center px-8 lg:px-10 mt-16 h-full  ">
            <div className="md:w-full flex items-center justify-center lg:w-9/12 xl:w-8/12">
              <div className="w-10/12">
                <p className="font-bold | text-base  lg:text-xl ">
                  Being a parent is a rewarding but sometimes challenging job.
                </p>
                <p className="font-normal | text-base  lg:text-xl  ">
                  You want to give your child the absolute best, and sometimes
                  the options can seem overwhelming, especially if you’re going
                  to help them excel in specific classes or, in general, want to
                  give them that extra something to help them not only in
                  education but for their future.
                </p>
                <p className="font-medium | text-base  lg:text-xl ">
                  The online options are numerous, but you are also scared that
                  they can’t be trusted and expensive.
                </p>
                <div className="flex flex-row mt-6 lg:justify-start justify-center items-center">
                  <Swotbutton
                    text="Enroll Now"
                    className="mr-4"
                    bgColor="#25627B"
                    onClick={() => {
                      navigate("/on-boarding");
                    }}
                  />
                  <Swotbutton
                    text="Learn More"
                    onClick={() => {
                      navigate("/");
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-auto mt-6">
              <img
                className="slideShowImg w-full pl-2 lg:pl-2 object-contain"
                src={slideImage1}
                alt="ChildLearning Difference SlideImage1"
              />
            </div>
          </div>
          <div className="lg:flex-row flex-col | each-slide-effect flex  items-center justify-center px-8 lg:px-10 mt-16 h-full ">
            <div className="md:w-full flex items-center justify-center lg:w-9/12 xl:w-8/12">
              <div className="w-10/12">
                <p className="font-bold | text-base lg:text-xl">
                  • Learning style
                </p>
                <p className="font-bold | text-base lg:text-xl">
                  • Special education
                </p>
                <p className="font-bold | text-base lg:text-xl">
                  • Tools to support
                </p>
                <p className="font-bold | text-base lg:text-xl">
                  • Personalised learning
                </p>
                <p className="font-bold | text-base lg:text-xl">
                  • Support group with other parents
                </p>
                <p className="font-bold | text-base lg:text-xl">
                  • Constant update by tutors on your childs performance
                </p>
                <p className="font-bold | text-base lg:text-xl">
                  • Mental health support
                </p>
                <div className="flex flex-row mt-6 lg:justify-start justify-center items-center">
                  <Swotbutton
                    text="Enroll Now"
                    className="mr-4"
                    bgColor="#25627B"
                    onClick={() => {
                      navigate("/on-boarding");
                    }}
                  />
                  <Swotbutton
                    text="Learn More"
                    onClick={() => {
                      navigate("/information");
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-auto">
              <img
                className="slideShowImg w-full pl-2 lg:pl-2 object-contain"
                src={slideImage2}
                alt="ChildLearning Difference SlideImage2"
              />
            </div>
          </div>
          <div className="lg:flex-row flex-col | each-slide-effect flex  items-center justify-center px-8 lg:px-10 mt-16 h-full ">
            <div className="md:w-full flex items-center justify-center lg:w-10/12 xl:w-9/12">
              <div className="w-10/12">
                <p className="font-normal | text-base lg:text-xl">
                  Here at l3arn, we want to offer the best for any parent and
                  child regardless of their financial situation and needs.
                </p>
                <p className="font-bold | text-base lg:text-xl">
                  Not only is it affordable with unmatched quality, but we also
                  work as a community to offer the best support you need as a
                  parent.
                </p>
                <p className="font-normal | text-base lg:text-xl">
                  We connect you with tutors that you can ask and discuss what
                  you need for your child and create a support system for them
                  and for you.
                </p>
                <div className="flex flex-row mt-6 lg:justify-start justify-center items-center">
                  <Swotbutton
                    text="Enroll Now"
                    className="mr-4"
                    bgColor="#25627B"
                    onClick={() => {
                      navigate("/on-boarding");
                    }}
                  />
                  <Swotbutton
                    text="Learn More"
                    onClick={() => {
                      navigate("/learner-type");
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-auto">
              <img
                className="slideShowImg w-full pl-2 lg:pl-2 object-contain"
                src={slideImage3}
                alt="ChildLearning Difference SlideImage3"
              />
            </div>
          </div>
        </Slide>
      </div>
    </div>
  );
};

export default function HomeSchooling() {
  const [showFormModal, setShowFormModal] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <LandingLayout landingTitle="">
      <Slideshow />
      <BetaSignupModal
        isOpen={!loading && showFormModal}
        onRequestClose={() => {
          setShowFormModal(false);
        }}
        showLoader={(status) => {
          setLoading(status);
        }}
      />
      {/* <BetaSignup onSubmit={onSubmit} /> */}
      {/* </BetaSignup> */}
      <LoaderModal
        isOpen={loading}
        onRequestClose={() => {
          setLoading(false);
        }}
      />
    </LandingLayout>
  );
}
