import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module'
import App from './App';
import * as serviceWorker from './serviceWorker';
import './assets/fonts/Poppins-Regular.ttf'
import './calendar.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './index.css';
import './styles.css'

const tagManagerArgs = {
  gtmId: 'G-9TPH64RLEN'
  //gtmId: 'GTM-PL33K7P',
  // dataLayer: {
  //   gaMeasurementId: process.env.NODE_ENV !== 'production' ? 'G-BJ8Q445NH9' : '',
  //   domain: 'swotknot'
  // }
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
