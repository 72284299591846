import {ref,uploadBytesResumable, listAll, getDownloadURL} from "firebase/storage";
import { storage } from "../config/firebase";
import { postCourseMaterial } from "./api";
var storageRef = ref(storage);
/*
const firebaseStorageUpload = async (file, path = "") => {
  var ref = ref(storageRef, `${path}/${file.name}`);
  await uploadBytes(ref, file);
  return await getDownloadURL(ref);
};*/

export const firebaseGetFileList = async (path) => {
  var listRef = ref(storageRef, path);

  // Find all the prefixes and items.
  return listAll(listRef);
};

export const courseMaterialUpload = async (
  courseId,
  file,
  path,
  materialType,
  progressCallback
) => {
  try {
    // const url = await firebaseStorageUpload(file, path);
    const result = await uploadFilePromise(file, materialType, path, (progress) => {
      const p = Math.floor(progress);
      progressCallback && progressCallback(p >= 100 ? 99 : p);
    });
    if (result?.downloadUrl) {
      await postCourseMaterial(courseId, file.name, materialType, result.downloadUrl);
      progressCallback && progressCallback(100);
    }
  } catch (error) {
    throw error;
  }
};

export const uploadFilePromise = (file, materialType, path, progressCallback) => {
  // var ref = storageRef.child(`${path}/${file.name}`);
  // await ref.put(file);
  // return await ref.getDownloadURL();

  return new Promise((resolve, reject) => {
   var uploadTask = uploadBytesResumable(ref(storageRef, `${path}/${file.name}`), file, file.type ? { contentType: file.type } : null);
    // var uploadTask = storageRef
    //   .child(`${path}/${file.name}`)
    //   .put(file, { contentType: type });

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        progressCallback && progressCallback(progress);
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused": // or 'paused'
            console.log("Upload is paused");
            break;
          case "running": // or 'running'
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        reject();
      },
      () => {
        console.log(
          "uploadPhotoPromise -  firebase promise returned:",
          uploadTask.snapshot
        );

        getDownloadURL(uploadTask.snapshot.ref).then(function (downloadURL) {
          console.log("uploadPhotoPromise - File available at", downloadURL);
          resolve({
            fileType: uploadTask.snapshot.metadata.contentType.substring(
              uploadTask.snapshot.metadata.contentType.lastIndexOf("/") + 1
            ),
            downloadUrl: downloadURL,
          });
        });
      }
    );
  });
};
