import ReduxPersist from '../config/reduxPersist'
import localForage from 'localforage';
import { persistStore } from 'redux-persist'
import DebugConfig from '../config/debugConfig'

const updateReducers = (store) => {
  const reducerVersion = ReduxPersist.reducerVersion

  // Check to ensure latest reducer version
  localForage.getItem('reducerVersion')
    .then((localVersion) => {
      if (localVersion !== reducerVersion) {
        if (DebugConfig.useReactotron) {
          console.tron.display({
            name: 'PURGE',
            value: {
              'Old Version:': localVersion,
              'New Version:': reducerVersion
            },
            preview: 'Reducer Version Change Detected',
            important: true
          })
        }
        // Purge store
        persistStore(store, null).purge()
        localForage.setItem('reducerVersion', reducerVersion)
      } else {
        persistStore(store, null)
      }
    })
    .catch(() => {
      persistStore(store, null)
      localForage.setItem('reducerVersion', reducerVersion)
    })
}

export default { updateReducers }