import React, { useEffect, useState } from "react";
import {
  NavigateFunction,
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { ReactComponent as LoadingIcon } from "../../assets//icons/loading_icon.svg";
import { connect } from "react-redux";
import "./styles.css";

import IconOne from "../../assets/icons/advanced-icon.svg";
import defaultUserPhotoUrl from "../../assets/icons/user.svg";

// import BoxLoader from "../../components/common/BoxLoader";
import MainPageWithMenu from "../../components/common/MainPageTemplate/mainPageWithMenu";
import Swotbutton from "../../components/common/buttons/swotbutton";

import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
  Avatar,
} from "@chatscope/chat-ui-kit-react";

import { swotknotBuddy } from "../../services/api";
import Swal from "sweetalert2";
import { set } from "lodash";
import { preAsk } from "./preAsk";

import TextLoader from "./textLoader/textLoader";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
  },
  section: {
    margin: 10,
    padding: 10,
  },
  viewer: {
    width: window.innerWidth / 1.5, //the pdf viewer will take up all of the width and height
    height: window.innerHeight / 1.5,
  },
});

const ChatPrompt = ({ user }) => {
  let [searchParams, _] = useSearchParams();

  const systemMessage = [
    //  Explain things like you're talking to a software professional with 5 years of experience.
    // "Explain things like you're talking to a 10 year old child.
    {
      prompt: {
        role: "system",
        content: "Explain things like i am 5 year old child.",
      },
      type: "a child",
    },
    {
      prompt: {
        role: "system",
        content: "Explain things like you're talking to a student.",
      },
      type: "a student",
    },
    {
      prompt: {
        role: "system",
        content: "Explain things like you're talking to a newbie.",
      },
      type: "a newbie",
    },
    {
      prompt: {
        role: "system",
        content: "Explain things like you're talking to a expert.",
      },
      type: "an expert",
    },
    {
      prompt: {
        role: "system",
        content: "Explain things like you're talking to a Tutor.",
      },
      type: "an Tutor",
    },
  ];

  const askMore = {
    role: "system",
    content: "if you have any questions ask before generate response, Ok?",
  };

  const [messages, setMessages] = useState([
    {
      message: "Hello, I'm l3arn AI assistant! Ask me anything!",
      sentTime: "just now",
      sender: "ChatGPT",
    },
  ]);
  const [isTyping, setIsTyping] = useState(false);
  const [showPreAsk, setShowPreAsk] = useState(false);
  const [preAskIndex, setPreAskIndex] = useState(-1);
  const [askTree, setAskTree] = useState(preAsk);
  const [talkLevelIndex, setTalkLevelIndex] = useState(-1);
  const [payment, setPayment] = useState(false);
  const [talkingMode, setTalkingMode] = useState(false);
  const [isListening, setListening] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [isGeneratingAssignment, setGeneratingAssignment] = useState(false);
  const [assignement, setAssignement] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [assignementMode, setAssignementMode] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleMessageSend = () => {
    console.log("Sending message:", messageText);
    handleSend(messageText);
    setMessageText("");
    console.log("USER:", user.accountType);
  };

  const handleInputChange = (text) => {
    setMessageText(text);
  };

  const handleSpeachMessage = (text) => {
    setMessageText(text);
    console.log("Sending message:", text);
    handleSend(text);
    setMessageText("");
    //handleMessageSend();
  };

  let recognition = null;

  const startSpeechRecognition = () => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    recognition = new SpeechRecognition();
    recognition.lang = "en-US";
    recognition.start();

    recognition.onstart = () => {
      console.log("Speech recognition started.");
      setListening(true);
    };

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      console.log("Speech recognition result:", transcript);
      console.log("newMessages sending via voice: ", messages);
      handleSpeachMessage(transcript);
      // Restart recognition after each recognized sentence
      /*
      if (event.results[event.results.length - 1].isFinal) {
        recognition.stop();
        startSpeechRecognition();
      }
      */
    };

    recognition.onend = () => {
      console.log("Speech recognition ended.");
      setListening(false);
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
    };
  };

  const stopSpeechRecognition = () => {
    if (recognition) {
      recognition.stop();
      recognition = null;
    }
  };

  useEffect(() => {
    if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
      if (talkingMode && talkLevelIndex != -1) {
        startSpeechRecognition();
        return () => {
          stopSpeechRecognition();
        };
      } else {
        stopSpeechRecognition();
      }
    } else {
      console.log("Web Speech API is not supported in this browser.");
    }
  }, [talkingMode]);

  const handleTalking = () => {
    setTalkingMode(!talkingMode);
  };

  useEffect(() => {
    console.log("messages", messages);

    if (messages.length == 0) handleSend("Ignore all previous instructions");
  }, [messages]);

  useEffect(() => {
    let paymentStatus = searchParams.get("status");
    if (paymentStatus == "success") {
      setTalkLevelIndex(0);
      if (!payment) {
        Swal.fire({
          title: "Success!",
          text: "Payment done for the study buddy!",
          icon: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "#407288",
        }).then(() => {
          handleSend("CHECK_TOKEN_LIMIT");
        });
      }
      setPayment(true);
    }
  }, [payment]);

  useEffect(() => {
    if (user.accountType == "Tutor") {
      setTalkLevelIndex(4);
      setShowPreAsk(true);
    } else {
      setTalkLevelIndex(1);
      setShowPreAsk(false);
    }
  }, []);

  let isAssignment = false;
  const handleSend = async (message) => {
    const newMessage = {
      message,
      direction: "outgoing",
      sender: "user",
    };

    const newMessages = [...messages, newMessage];
    console.log("newMessages: ", newMessages);

    setMessages(newMessages);
    setIsTyping(true);
    setShowPreAsk(false);
    await processMessageToChatGPT(newMessages);
  };

  const processPreAsk = async (i) => {
    console.log("Pre Asked: ", i, askTree[i]);
    setShowPreAsk(false);
    setAskTree(askTree[i]?.response);

    if (askTree[i]?.option == "Generate now") {
      setGeneratingAssignment(true);
      isAssignment = true;
    }

    if (askTree[i]?.option == "Create an assignment") {
      setAssignementMode(true);
    }

    if (askTree[i]?.answer) {
      const newMessage = {
        message: askTree[i].prompt,
        direction: "outgoing",
        sender: "user",
      };

      const answer = {
        message: askTree[i]?.answer,
        sender: "ChatGPT",
      };

      const newMessages = [...messages, newMessage, answer];
      setMessages(newMessages);
      if (user.accountType == "Tutor") {
        setShowPreAsk(true);
      }
    } else {
      handleSend(askTree[i].prompt);
    }
  };

  const clear = async () => {
    setAskTree(preAsk);
    setAssignementMode(false);
    setMessages([]);
  };

  const selectRole = async (i) => {
    setTalkLevelIndex(i);
    if (talkingMode) {
      startSpeechRecognition();
    }
  };

  async function processMessageToChatGPT(chatMessages) {
    // messages is an array of messages
    // Format messages for chatGPT API
    // API is expecting objects in format of { role: "user" or "assistant", "content": "message here"}
    // So we need to reformat

    let apiMessages = chatMessages.map((messageObject) => {
      let role = "";
      if (messageObject.sender === "ChatGPT") {
        role = "assistant";
      } else {
        role = "user";
      }
      return { role: role, content: messageObject.message };
    });

    let converstion = [];
    if (talkLevelIndex != -1) {
      converstion = [
        systemMessage[talkLevelIndex].prompt, // The system message DEFINES the logic of our chatGPT
        ...apiMessages, // The messages from our chat with ChatGPT
        //askMore
      ];
    } else {
      converstion = apiMessages;
    }

    const apiRequestBody = {
      user: user.username,
      client: "app",
      prompts: converstion,
      success_url: window.location.href + "?status=success",
      cancel_url: window.location.href + "?status=cancel",
      assignement: isAssignment,
    };

    console.log("Request Body:", apiRequestBody);
    await swotknotBuddy(apiRequestBody).then((res) => {
      if (res) {
        let responseMsg = res.data;
        console.log(responseMsg.data);

        if (responseMsg?.payment_link) {
          Swal.fire({
            title: "Token Limit Reached",
            text: "You have reached your limit of questions, to be able to continue, please buy more time with your study buddy just in 99cent.",
            icon: "warning",
            focusConfirm: true,
            confirmButtonColor: "#27ae60",
            confirmButtonText: "Buy Now",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location = responseMsg.checkout_url;
            }
          });
        } else {
          console.log("newMessages before setting response: ", chatMessages);
          setMessages([
            ...chatMessages,
            {
              message: responseMsg.data,
              sender: "ChatGPT",
            },
          ]);
          setIsTyping(false);

          if (user.accountType == "Tutor") {
            setShowPreAsk(true);
          }

          if (isAssignment) {
            setAssignement(responseMsg.data);
            openModal();
          }

          setGeneratingAssignment(false);
          setAssignementMode(false);
          isAssignment = false;

          console.log("newMessages after getting response: ", messages);

          if (talkingMode) {
            if ("speechSynthesis" in window) {
              var msg = new SpeechSynthesisUtterance(responseMsg.data);
              window.speechSynthesis.speak(msg);
              msg.onend = () => {
                console.log("Speech synthesis completed");
                startSpeechRecognition();
              };
            } else {
              console.log("Web Speech API is not supported in this browser.");
            }
          }
        }
      }
    });

    // Get the request body set up with the model we plan to use
    // and the messages which we formatted above. We add a system message in the front to'
    // determine how we want chatGPT to act.
    /*
               
                console.log("converstion:", converstion);
        
                const apiRequestBody = {
                    "model": "gpt-3.5-turbo",
                    "messages": converstion
                }
        
                await fetch("https://api.openai.com/v1/chat/completions",
                    {
                        method: "POST",
                        headers: {
                            "Authorization": "Bearer " + API_KEY,
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(apiRequestBody)
                    }).then((data) => {
                        return data.json();
                    }).then((data) => {
                        console.log(data);
                        setMessages([...chatMessages, {
                            message: data.choices[0].message.content,
                            sender: "ChatGPT"
                        }]);
                        setIsTyping(false);
                        setShowPreAsk(true);
                    });
                    */
  }

  return (
    <MainPageWithMenu user={user} menuSelected={"chat-prompt"}>
      {isGeneratingAssignment ? (
        <div className="popup">
          {/* <div className="loading-text">Generating Assignment..</div> */}
          <TextLoader />
        </div>
      ) : null}

      {isOpen && (
        <div className="modal">
          <div className="modal-content">
            <PDFViewer style={styles.viewer}>
              <Document>
                <Page size="A4" style={styles.page}>
                  <View style={styles.section}>
                    <Text>{assignement}</Text>
                  </View>
                </Page>
              </Document>
            </PDFViewer>
            <button onClick={closeModal}>Close</button>
          </div>
        </div>
      )}

      <div style={{ position: "relative", height: "80vh", width: "100%" }}>
        {!assignementMode && (
          <div>
            <span className="sliderTitle">Talking Mode:</span>
            <label>
              <input
                className="sliderbox"
                type="checkbox"
                checked={talkingMode}
                onChange={handleTalking}
              />
              <span className="slider" />
            </label>
            {isListening && (
              <span className="listeningIndicator">Listening...</span>
            )}
          </div>
        )}

        <MainContainer style={{ borderRadius: "5px" }}>
          <ChatContainer>
            <MessageList
              scrollBehavior="smooth"
              typingIndicator={
                isTyping ? (
                  <TypingIndicator content="l3arn AI assistant is typing" />
                ) : null
              }
            >
              {talkLevelIndex != -1 ? (
                <span>
                  {messages.map((message, i) => {
                    console.log(message);
                    return (
                      <>
                        <Message key={i} model={message}>
                          avatar=
                          {message.sender === "user" ? (
                            user.photoUrl ? (
                              <Avatar src={user.photoUrl} name="User" />
                            ) : (
                              <Avatar src={defaultUserPhotoUrl} name="User" />
                            )
                          ) : (
                            <Avatar
                              src={IconOne}
                              name="Chatbot"
                              status="available"
                            />
                          )}
                        </Message>
                      </>
                    );
                  })}

                  {showPreAsk && (
                    <div className="predefined flex flex-row justify-end">
                      {askTree
                        ? askTree.map((ask, i) => {
                            return (
                              <div
                                className="predefineList"
                                onClick={() => processPreAsk(i)}
                              >
                                {ask.option}
                              </div>
                            );
                          })
                        : null}
                      <div
                        className="predefineList clearBtn"
                        onClick={() => clear()}
                      >
                        Clear
                      </div>
                    </div>
                  )}
                </span>
              ) : (
                <div className="selectRole">
                  <h1>I am</h1>
                  {systemMessage.map((role, i) => {
                    return (
                      <div className="eachRole" onClick={() => selectRole(i)}>
                        {role.type}
                      </div>
                    );
                  })}
                </div>
              )}
            </MessageList>

            <MessageInput
              attachButton={false}
              placeholder="Type message here"
              // onSend={handleSend}
              value={messageText}
              onChange={handleInputChange}
              onSend={handleMessageSend}
            />
          </ChatContainer>
        </MainContainer>
      </div>
    </MainPageWithMenu>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ChatPrompt);
