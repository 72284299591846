import React from "react";
import { connect } from "react-redux";

const TutorPending = ({ user }) => {
  return (
    <div className=" bg-white w-full rounded-lg p-4 my-4 mx-2 h-full flex flex-col justify-center items-center">
      <div className="text-center">
        Hi {user.username}, your application is still being reviewed.
      </div>
      <div className="text-center">
        Thank you for applying to l3arn, we will let you know as soon as we
        review your case.
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TutorPending);
