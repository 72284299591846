import { getGooglePlaceId, getPlaceInfo } from "./api";
import axios from "axios";
import { countriesList } from "./countrieslist";
import { citiesList } from "./citieslist";


export const parseLivingLocation = (loc) => {
  const locData = loc.trim().split(',')
  console.log("parseLivingLocation city, state, Country", locData[0], locData[1], locData[2])

  return {
    cityName: locData[0] ? locData[0].trim() : null,
    countryName: locData[2] ? locData[2].trim() : locData[1].trim(),
    stateCode: locData[2] ? locData[1].trim() : null,
  }
}

export const isEducationalInstitute = (types) => {

  let isNotEducationa = types.filter((x) =>
    x.includes("tourist_attraction") || x.includes("shopping_mall") || x.includes("park") || x.includes("health") || x.includes("hospital")
  );

  let isEducational = types.filter((x) =>
    x.includes("school") || x.includes("university") || x.includes("establishment") || x.includes("premise")
  );

  return (isEducational.length > 0 && isNotEducationa.length === 0);
}

export const extractLocation = (details) => {

  let admin1 = details.address_components.filter((x) => x.types.includes("administrative_area_level_1"));
  admin1 = admin1.length > 0 && admin1[0].long_name;

  let admin2 = details.address_components.filter((x) => x.types.includes("administrative_area_level_2"));
  admin2 = admin2.length > 0 && admin2[0].long_name;

  let states = admin1 || admin2;

 
  let isValidCity = details.types.filter((x) =>
     x.includes("school") || x.includes("university") || x.includes("establishment") || x.includes("premise") || x.includes("tourist_attraction") || x.includes("shopping_mall") || x.includes("park") || x.includes("health") || x.includes("hospital")
  );

  console.log("isValidCity: ", isValidCity);

  let cityNames;

  if(isValidCity.length == 0){
    cityNames = details.address_components.filter((x) => x.types.includes("locality"));
    cityNames = cityNames.length > 0 && cityNames[0].long_name;
  }

  let countryNames = details.address_components.filter((x) => x.types.includes("country"));
  countryNames = countryNames.length > 0 && countryNames[0].short_name;

  console.log("Locality: ", cityNames, " , countryName: ", countryNames, " , state: ", states)

  return {
    cityName: cityNames,
    countryName: countryNames,
    stateCode: states,
  }

}

export const getLocationName = (loc) => {

  console.log("loc", loc);

  if (loc.countryName === "USA") {
    return loc.cityName + ", " + loc.stateCode + ", " + loc.countryName;
  }
  else {
    return loc.cityName + ", " + loc.countryName;
  }
}


export const getCountryNameByCityName = async (cityName) => {
  const options = {
    method: 'GET',
    url: 'https://opentripmap-places-v1.p.rapidapi.com/en/places/geoname',
    params: { name: cityName },
    headers: {
      'X-RapidAPI-Key': '7f965a4924mshc58beaa4c992045p1115c4jsna75aa05b0a9f',
      'X-RapidAPI-Host': 'opentripmap-places-v1.p.rapidapi.com'
    }
  };
  let response = await axios.request(options)
  console.log("getting Country Name Axio:", response.data.country);
  return response.data.country;
}


export const getCitiesListByCountryName = (countryCode) => {
  
  let countryName = countriesList.find(o => o.value === countryCode);
  let citiesNameList = citiesList[countryName.label]
  let citiesOpt =[];

  citiesNameList.forEach(city => {
    citiesOpt.push({
      label: city,
      value: city
    })
    
  });

  return citiesOpt;
}

