import React, { useState, useEffect } from "react";
import knotSm from "../../../assets/images/learn-small.png";
import { ACCOUNT_TYPES } from "../../../config/constants";
import commonColor from "../../../theme/commonColor";
import { QuickModal } from "../../common/modal/modal";
import UserInfoPanel from "../../tutor/profile/userInfoPanel";
import { ReactComponent as UserIcon } from "../../../assets/icons/user.svg";

const UserListItem = ({
  user,
  setExpandedUser,
  expandedUserId,
  deleteUser,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);

  const deleteUserConfirmation = async () => {
    setConfirmDelete(true);
  };

  const showuserInfo = () => {
    console.log("User Data::", user);
    setExpandedUser(
      expandedUserId === user.firebase_id ? null : user.firebase_id
    );
  };

  const renderDeleteButton = () => {
    return (
      <div className=" flex justify-between items-center ml-auto">
        <button
          className="focus:outline-none hover:shadow-outline bg-red-600 py-2 px-4
             text-white mx-2 rounded-2xl"
          onClick={() => deleteUserConfirmation()}
        >
          Delete
        </button>
      </div>
    );
  };

  return (
    <div className="w-full bg-transparent flex flex-col justify-start focus:outline-none pb-4">
      <QuickModal
        isOpen={confirmDelete}
        onRequestClose={() => {
          setConfirmDelete(false);
        }}
        title={`${user?.first_name} ${user?.last_name}`}
        message={"Do you want to delete the user?"}
        firstButtonTitle={"Cancel"}
        secondButtonTitle={"Delete"}
        secondButtonStyle={{
          color: commonColor.deepPink,
          border: `1px solid ${commonColor.deepPink}`,
        }}
        onSecondButtonClick={() => {
          setConfirmDelete(false);
          deleteUser && deleteUser(user?.firebase_id);
        }}
        onFirstButtonClick={() => {
          setConfirmDelete(false);
        }}
      />
      <div
        className="shadow-lg rounded-2xl bg-white h-full text-xs px-3 py-2 flex flex-col md:flex-row justify-start items-start md:items-center max-w-full lg:max-w-60r"
        style={
          {
            // maxWidth: "60rem",
            //   height: "80px",
            //   marginTop: "1rem",
          }
        }
      >
        <div className="flex justify-start max-w-full">
          <div className=" h-full flex items-center w-16">
            {user.photo_url &&
            user.photo_url !== "" &&
            user.photo_url !== "null" ? (
              <img
                // class="contain"
                loading="lazy"
                src={user.photo_url}
                alt="userPhoto"
                style={{}}
                className="rounded-full shadow-lg h-12 w-12 bg-white object-cover"
              />
            ) : (
              <UserIcon className="rounded-full shadow-lg h-10 w-10 bg-white" />
            )}
          </div>
          <div className="flex justify-start flex-wrap">
            <div className="pr-4 flex justify-start items-center break-all">
              {user.first_name}
            </div>
            <div className=" pr-4 flex justify-start items-center break-all">
              {user.last_name}
            </div>
            <div className="text-center pr-4 flex justify-center items-center">
              {user.email}
            </div>
            {user.ngo_user ? (
              <div className="text-center pr-4 flex justify-center items-center">
                <span
                  style={{
                    color: commonColor.deepPink,
                  }}
                >
                  - NGO
                </span>
              </div>
            ) : user.accountType === ACCOUNT_TYPES.TUTOR ? (
              <div className="text-center pr-4 flex justify-center items-center">
                <span
                  style={{
                    color: commonColor.deepPink,
                  }}
                >
                  - {user.accountType}{" "}
                </span>
              </div>
            ) : (
              ""
            )}

            {user.accountType === ACCOUNT_TYPES.TUTOR &&
              user.tutor_approval == true && (
                <div className="text-center pr-4 flex justify-center items-center">
                  <span
                    style={{
                      color: commonColor.flatGreen,
                    }}
                  >
                    - APPROVED
                  </span>
                </div>
              )}

            {user.accountType === ACCOUNT_TYPES.TUTOR &&
              user.tutor_approval == false && (
                <div className="text-center pr-4 flex justify-center items-center">
                  <span
                    style={{
                      color: commonColor.error,
                    }}
                  >
                    - REJECTED
                  </span>
                </div>
              )}

            {user.accountType === ACCOUNT_TYPES.TUTOR &&
              user.tutor_approval == null && (
                <div className="text-center pr-4 flex justify-center items-center">
                  <span
                    style={{
                      color: commonColor.deepGreen,
                    }}
                  >
                    - UNDER REVIEW
                  </span>
                </div>
              )}

            {user.accountType === ACCOUNT_TYPES.TUTOR &&
              user.tutor_approval == "pending" && (
                <div className="text-center pr-4 flex justify-center items-center">
                  <span
                    style={{
                      color: commonColor.disable,
                    }}
                  >
                    - PENDING
                  </span>
                </div>
              )}
          </div>
        </div>

        <div className="flex flex-row justify-end md:justify-between flex-grow pt-2 md:pt-0">
          <button
            className="focus:outline-none hover:shadow-outline bg-blue-600 py-2 px-4
             text-white mx-2 rounded-2xl"
            onClick={() => showuserInfo()}
          >
            Show Info
          </button>
          {renderDeleteButton()}
        </div>
      </div>
      {expandedUserId === user?.firebase_id && (
        <div
          style={{
            //   marginTop: "90px",
            // width: "54%",
            maxWidth: "50rem",
            //   height: "55vh",
            // minHeight: "25rem",
            //   position: "absolute",
            transition: "opacity 1s",
            display: "flex",
          }}
        >
          <UserInfoPanel tutor={user} viewOnly={true} />
        </div>
      )}
    </div>
  );
};

export default UserListItem;
