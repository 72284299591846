import React from "react";
import moment from "moment";
import knotSm from "../../../assets/images/learn-small.png";

const RefundItemDetails = ({ refundItem }) => {
  return (
    <div className="w-full flex flex-col justify-start items-center shadow-lg rounded-2xl bg-white px-3">
      <div className="flex flex-col justify-start items-center">
        <p className="text-md">
          <b> Refund Details</b>
        </p>
        <br />
        <p>
          <b>Transaction Id:</b> {refundItem.transaction_id}
        </p>
        <p>
          <b>Reason of refund:</b> {refundItem.refund_reason}
        </p>
        <p>
          <b>Amount:</b> {refundItem.amount} USD
        </p>
        <p>
          <b>Commission:</b> {refundItem.commission} USD
        </p>
        <p>
          <b>Tutor Email:</b> {refundItem.tutor_email}
        </p>
        <p>
          <b>Student Email:</b> {refundItem.student_email}
        </p>
        <p>
          <b>Date/Time:</b> {moment.unix(refundItem.commission).toString()}
        </p>
        <p>
          <b>Booking Start (Date/Time):</b>{" "}
          {moment.unix(refundItem.booking_start).toString()}
        </p>
        <p>
          <b>Booking End (Date/Time):</b>{" "}
          {moment.unix(refundItem.booking_end).toString()}
        </p>
        <p>
          <b>Booking Title:</b> {refundItem.booking_title}
        </p>
        <p>
          <b>Refund Request (Date/Time):</b>{" "}
          {moment.unix(refundItem.refund_request_time).toString()}
        </p>
        <p>
          <b>Session Status:</b> {refundItem.session_status}
        </p>
        <p>
          <b>Session Joined:</b> {refundItem.session_joined ? "yes" : "no"}
        </p>
      </div>
      <div className="flex flex-row justify-between">
        {refundItem.tutor && (
          <div className="flex justify-start items-center">
            <img
              // class="contain"
              src={
                refundItem.tutor?.photoUrl ? refundItem.tutor.photoUrl : knotSm
              }
              alt="tutorphoto"
              style={{}}
              className="rounded-full shadow-lg h-12 w-12 bg-white object-cover"
            />
            <div className="flex flex-col justify-start">
              <p>{refundItem.tutor.fullname}</p>
              <p>{refundItem.tutor.emailId}</p>
            </div>
          </div>
        )}
        {refundItem.student && (
          <div className="flex justify-end items-center">
            <div className="flex flex-col justify-end">
              <p>{refundItem.student.fullname}</p>
              <p>{refundItem.student.emailId}</p>
            </div>
            <img
              // class="contain"
              src={
                refundItem.student?.photoUrl
                  ? refundItem.student.photoUrl
                  : knotSm
              }
              alt="Refund Items Details Image"
              style={{}}
              className="rounded-full shadow-lg h-12 w-12 bg-white object-cover"
            />
          </div>
        )}
      </div>
      {refundItem.course && (
        <div className="flex flex-col justify-start items-center">
          <p>Course Nname: {refundItem.course.coursename}</p>
          <p>Course Id: {refundItem.course_id}</p>
        </div>
      )}
    </div>
  );
};

export default RefundItemDetails;
