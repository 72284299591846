import { useEffect, useState } from "react";
import { connect } from "react-redux";
import MainPageWithMenu from "../../components/common/MainPageTemplate/mainPageWithMenu";

import NewsFeed from "../../components/tutor/tools/newsFeed";
// import TechnicalTools from "../../components/tutor/tools/technicalTools";
const TutorTools = ({ user }) => {
  const [currentToolsView, setCurrentToolsView] = useState("News feed");

  useEffect(() => {
    console.log("TutorTools - useEffect currentToolsView", currentToolsView);
  }, [currentToolsView]);

  // const renderCurrentToolsView = () => {
  //   switch (currentToolsView) {
  //     case "News feed":
  //       return <NewsFeed setCurrentToolsView={setCurrentToolsView} showAdvanceTool={true}/>;

  //     default:
  //       return <TechnicalTools setCurrentToolsView={setCurrentToolsView} />;
  //   }
  // };

  return (
    <MainPageWithMenu user={user} menuSelected={"tutorTools"}>
      {/* {currentToolsView === "News feed" ? ( */}
      {/* <Swotbutton text="Advance Tool" onClick={ () => { navigate("advance-tools") }}/> */}
        <NewsFeed setCurrentToolsView={setCurrentToolsView}  showAdvanceTool={true}/>
      {/* ) : ( */}
         {/* <TechnicalTools setCurrentToolsView={setCurrentToolsView} /> */}
       {/* )} */}
    </MainPageWithMenu>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TutorTools);
