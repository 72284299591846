

const data = {
  "type": "curriculum",
  "subjects": {
    "Business": {
      "name": "Business",
      "areas": [
        "Accounting",
        "Business law",
        "Business management",
        "Consumer education",
        "Entrepreneurial skills",
        "Introduction to business",
        "Marketing",
        "Personal finance"
      ]
    },
    "Computer Science/Information Technology": {
      "name": "Computer Science/Information Technology",
      "areas": [
        "Animation",
        "App development",
        "Audio production",
        "Computer literacy",
        "Computer programming",
        "Computer repair",
        "Computer science",
        "Film production",
        "Graphic design",
        "Media studies",
        "Media technology",
        "Music production",
        "Typing",
        "Video game development",
        "Web design",
        "Web programming",
        "Word processing"
      ]
    },
    "English": {
      "name": "English",
      "areas": [
        "American literature",
        "Ancient Literature",
        "British literature",
        "Classical literature",
        "Communication skills",
        "Composition",
        "Contemporary literature",
        "Creative writing",
        "Debate",
        "English I",
        "English II",
        "English III",
        "English IV",
        "English language and composition",
        "English literature and composition",
        "ESL � English as second language",
        "Film as Literature",
        "Grammar",
        "Heroes, Myth and Legend",
        "Humanities",
        "Journalism",
        "Literary analysis",
        "Medieval Literature",
        "Modern literature",
        "Photojournalism",
        "Poetry",
        "Popular literature",
        "Publishing Skills",
        "Remedial English",
        "Renaissance Literature",
        "Rhetoric",
        "Shakespeare",
        "Short Story",
        "Speech Therapy",
        "Technical writing",
        "Vocabulary",
        "Works of Shakespeare",
        "World literature",
        "Written and oral communication"
      ]
    },
    "Family and Consumer Science": {
      "name": "Family and Consumer Science",
      "areas": [
        "Chemistry of foods",
        "CPR training",
        "Culinary arts",
        "Early childhood development",
        "Early childhood education",
        "Family studies",
        "Fashion and retail merchandising",
        "Fashion construction",
        "Home economics",
        "Interior design",
        "Nutrition"
      ]
    },
    "Foreign Language": {
      "name": "Foreign Language",
      "areas": [
        "American Sign Language",
        "Ancient Greek",
        "Arabic",
        "Chinese",
        "French",
        "German",
        "Hebrew",
        "Italian",
        "Japanese",
        "Korean",
        "Latin",
        "Portuguese",
        "Russian",
        "Spanish"
      ]
    },
    "Math": {
      "name": "Math",
      "areas": [
        "Algebra 1",
        "Algebra 2",
        "Business Math",
        "Calculus",
        "Computer math",
        "Consumer math",
        "Fundamental Math or Basic Math",
        "Fundamentals of math",
        "Geometry",
        "Integrated math",
        "Intermediate Algebra",
        "Introduction to Algebra",
        "Math applications",
        "Multivariable calculus",
        "Practical math",
        "Precalculus",
        "Pre-calculus",
        "Probability",
        "Quantitative literacy",
        "Remedial Math",
        "Statistics",
        "Trigonometry"
      ]
    },
    "Performing Arts": {
      "name": "Performing Arts",
      "areas": [
        "Choir",
        "Concert band",
        "Dance",
        "Drama",
        "Jazz band",
        "Marching band",
        "Music theory",
        "Orchestra",
        "Percussion",
        "Piano",
        "Theater technology",
        "World music"
      ]
    },
    "Physical Education": {
      "name": "Physical Education",
      "areas": [
        "Adapted P.E",
        "Aerobics",
        "Archery",
        "Basic First Aid and Safety",
        "Bowling",
        "Cycling",
        "Drill Team, Honor Guard, Pageantry, Flag, Cheer",
        "Equestrian Skills",
        "Fencing",
        "Figure skating",
        "Golf",
        "Gymnastics",
        "Health",
        "Healthful Living  Personal Health",
        "Hiking",
        "Ice Skating",
        "Lifeguard training",
        "Martial Arts",
        "Outdoor Survival Skills",
        "Physical Fitness",
        "Pilates",
        "Racket sports",
        "Rock Climbing",
        "Specialized sports",
        "Swimming",
        "Team Sports (Soccer, volleyball, football, etc)",
        "Track and Field",
        "Weight training",
        "Weightlifting",
        "Yoga"
      ]
    },
    "Science": {
      "name": "Science",
      "areas": [
        "Agriculture",
        "Astronomy",
        "Biology",
        "Botany",
        "Chemistry",
        "Earth science",
        "Electronics",
        "Environmental science",
        "Environmental studies",
        "Forensic science",
        "Geology",
        "Marine biology",
        "Oceanography",
        "Physical science",
        "Physics",
        "Zoology",
        "General Science",
        "Organic Chemistry",
        "Meteorology",
        "Animal Science",
        "Equine Science",
        "Veterinary Science",
        "Ecology",
        "Gardening",
        "Food Science"
      ]
    },
    "Social Studies": {
      "name": "Social Studies",
      "areas": [
        "Cultural anthropology",
        "Current events",
        "European history",
        "Geography",
        "Global studies",
        "Human geography",
        "International relations",
        "Law",
        "Macroeconomics",
        "Microeconomics",
        "Modern world studies",
        "Physical anthropology",
        "Political studies",
        "Psychology",
        "Religious studies",
        "Sociology",
        "US government",
        "US history",
        "Women`s studies",
        "World history",
        "World politics",
        "World religions"
      ]
    },
    "Visual Arts": {
      "name": "Visual Arts",
      "areas": [
        "3-D art",
        "Art history",
        "Ceramics",
        "Digital media",
        "Drawing",
        "Jewelry design",
        "Painting",
        "Photography",
        "Printmaking",
        "Sculpture"
      ]
    },
    "Vocational Education": {
      "name": "Vocational Education",
      "areas": [
        "Auto body repair",
        "Auto mechanics",
        "Building construction",
        "Computer-aided drafting",
        "Cosmetology",
        "Criminal justice",
        "Drafting",
        "Driver education",
        "FFA (Future Farmers of America)",
        "Fire science",
        "Heating and cooling systems",
        "Hospitality and tourism",
        "JROTC (Junior Reserve Officers` Training Corps)",
        "Leather Working",
        "Metal Shop",
        "Metal Work",
        "Metalworking",
        "Networking",
        "Plumbing",
        "Production technology",
        "Refrigeration fundamentals",
        "Robotics",
        "Small Engine Mechanics",
        "Woodshop",
        "Woodworking"
      ]
    },
    "Arts": {
      "name": "Arts",
      "areas": [
        "Studio art: 2-D design",
        "Studio art: 3-D design",
        "Studio art: drawing",
        "English Language and Composition",
        "English Literature and Composition"
      ]
    },
    "History & Social Science": {
      "name": "History & Social Science",
      "areas": [
        "Comparative government and politics",
        "Human Geography",
        "United States Government and Politics",
        "United States History",
        "World History",
        "Math & Computer Science",
        "Calculus AB",
        "Calculus BC",
        "Computer Science A",
        "Computer Science Principles",
        "Ancient Civilizations",
        "Medieval and Renaissance",
        "U.S. History and Government",
        "Ancient History",
        "Medieval History",
        "Greek and Roman History",
        "Renaissance History with US History",
        "Modern History with US History",
        "World Geography",
        "World Current Events",
        "Government",
        "Civics",
        "Economics",
        "Political Science",
        "Social Sciences",
        "Anthropology",
        "Genealogy",
        "Philosophy"
      ]
    },
    "Sciences": {
      "name": "Sciences",
      "areas": [
        "Environmental Science",
        "Physics 1: Algebra-Based",
        "Physics 2: Algebra-Based",
        "Physics C: Electricity and Magnetism"
      ]
    },
    "World Languages and Cultures": {
      "name": "World Languages and Cultures",
      "areas": [
        "Chinese Language and Culture",
        "French Language and Culture",
        "German Language and Culture",
        "Italian Language and Culture",
        "Japanese Language and Culture",
        "Spanish Language and Culture",
        "Spanish Literature and Culture"
      ]
    },
    "CORE": {
      "name": "CORE",
      "areas": [
        "Reading",
        "Speech and Debate",
        "Basic Math",
        "Algebra"
      ]
    },
    "LOGIC": {
      "name": "LOGIC",
      "areas": [
        "Logic I",
        "Logic II",
        "Critical Thinking"
      ]
    },
    "FINE ARTS": {
      "name": "FINE ARTS",
      "areas": [
        "Art Appreciation",
        "Art I",
        "Art II",
        "Art III",
        "Art IV",
        "Classical Music Studies",
        "Improvisational Theater",
        "Music",
        "Music Appreciation",
        "Music Fundamentals",
        "Music History",
        "Pottery",
        "Theatre Arts",
        "Voice"
      ]
    },
    "APPLIED ARTS": {
      "name": "APPLIED ARTS",
      "areas": [
        "Computer Aided Design",
        "Film Production",
        "History of Film",
        "Videography"
      ]
    }
  }
}


export default data