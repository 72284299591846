import { storage } from "../config/firebase";
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable, uploadString } from "firebase/storage";

export const uploadPhotoPromise = (file, type, photoUri, filename, refPath) => {
  console.log("uploadPhotoPromise - photoUri:", photoUri);
  // const uri = decodeURI(photoUri);
  //const uri = photoUri;
  //console.log('uploadPhotoPromise - decodedUri:', uri)
  return new Promise((resolve, reject) => {
    // for some reason png does not work, it gets uploaded, sent to the api but never fetched. leaving as hardcoded jpg for now
    // ATTENTION the same function is used for upload profile pic, CV and video, meaning that the exntension is not correct
    // but lets wait for BE to see what happen
    // TODO suggestion here is to remove ext completely
    const ext = "jpg";

    uploadBytes(ref(ref(storage, refPath), `${filename}.${ext}`), file, { contentType: type })
      .then(function (snapshot) {
        console.log(
          "uploadPhotoPromise -  firebase promise returned:",
          snapshot
        );
        getDownloadURL(snapshot.ref).then(function (downloadURL) {
          console.log("uploadPhotoPromise - File available at", downloadURL);
          resolve({
            fileType: snapshot.metadata.contentType.substring(
              snapshot.metadata.contentType.lastIndexOf("/") + 1
            ),
            downloadUrl: downloadURL,
          });
        });
      })
      .catch(function (error) {
        console.log("uploadPhotoPromise - ", error.message);
        reject();
      });
  });
};


export const uploadFilePromise = (
  file,
  type,
  photoUri,
  filename,
  refPath,
  progressCallback,
  defaultExt = "jpg"
) => {
  console.log("uploadPhotoPromise - photoUri:", photoUri);
  // const uri = decodeURI(photoUri);
  const uri = photoUri;
  //console.log('uploadPhotoPromise - decodedUri:', uri)
  return new Promise((resolve, reject) => {
    // for some reason png does not work, it gets uploaded, sent to the api but never fetched. leaving as hardcoded jpg for now
    // ATTENTION the same function is used for upload profile pic, CV and video, meaning that the exntension is not correct
    // but lets wait for BE to see what happen
    // TODO suggestion here is to remove ext completely
    // const defaultExt = "jpg";
    let ext = type.split("/").pop();
    if (uri) {
      ext = uri.substring(uri.lastIndexOf(".") + 1);
    }
    if (ext === "png") {
      ext = "jpg";
    }


    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    var uploadTask = uploadBytesResumable(ref(ref(storage, refPath), `${filename}.${ext ?? defaultExt}`), file, { contentType: type });
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        progressCallback && progressCallback(progress);
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused": // or 'paused'
            console.log("Upload is paused");
            break;
          case "running": // or 'running'
            console.log("Upload is running");
            break;
            default:
              break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        console.log("uploadPhotoPromise - ", error.message);
        reject();
      },
      () => {
        console.log(
          "uploadPhotoPromise -  firebase promise returned:",
          uploadTask.snapshot
        );

        getDownloadURL(uploadTask.snapshot.ref).then(function (downloadURL) {
          console.log("uploadPhotoPromise - File available at", downloadURL);
          resolve({
            fileType: uploadTask.snapshot.metadata.contentType.substring(
              uploadTask.snapshot.metadata.contentType.lastIndexOf("/") + 1
            ),
            downloadUrl: downloadURL,
          });
        });
      }
    );
  });
};

export const uploadBase64FilePromise = (
  file,
  type,
  filename,
  ext,
  refPath,
  progressCallback
) => {
  return new Promise((resolve, reject) => {
    const defaultExt = "jpg";

    const fileRef = ref(ref(storage, refPath), `${filename}.${ext ?? defaultExt}`);
    uploadString(fileRef, file, "data_url", { contentType: type })
    .then((snapshot) => {
      console.log("uploadBase64FilePromise - Success", snapshot );
      getDownloadURL(fileRef).then(function (downloadURL) {
        console.log("uploadPhotoPromise - File available at", downloadURL);
        resolve({
          fileType: snapshot.metadata.contentType.substring(
            snapshot.metadata.contentType.lastIndexOf("/") + 1
          ),
          downloadUrl: downloadURL,
        });
      });
    })
    .catch((error) =>{
      console.log("uploadBase64FilePromise - failed", error.message);
      reject();
    });
  });
};
