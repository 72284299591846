import ChatUserModel from "../models/chatUser";
import GenericProfilePhoto from "../../assets/images/tutor_base.jpg";
import { toTitleCase } from "../../utils/generalUtils";
import React, { useState } from "react";
import Swotbutton from "../../components/common/buttons/swotbutton";
import { ChatService } from "../services/chatService";
import { IChatGroup } from "../models/chat_models";
import UserModel from "../../redux/redux-models/reduxUser";

const ChatGroupWidget = ({
  group,
  me,
}: {
  group: IChatGroup;
  me: UserModel;
}) => {
  return (
    <div className="w-full flex flex-row justify-start items-center">
      <div className="w-12 h-12 min-w-12 m-2 rounded-full">
        <img
          className="w-auto rounded-sm  h-full"
          style={{ height: "90%", objectFit: "contain" }}
          src={GenericProfilePhoto}
          alt="groupPhoto"
        />
      </div>
      <div className="flex flex-col pb-2 justify-start items-start overflow-hidden">
        <span className="text-md font-bold break-all truncate">
          {toTitleCase(group.title)}
        </span>

        {group.memberDetails && group.memberDetails[group.createdBy] && (
          <span className="text-md break-all truncate">
            <span className="italic">Group Owner:</span>
            {group.memberDetails[group.createdBy].uid == me.uid ? (
              "Myself"
            ) : (
              <>
                {toTitleCase(group.memberDetails[group.createdBy].firstName)}
                {toTitleCase(group.memberDetails[group.createdBy].lastName)}
              </>
            )}
          </span>
        )}
      </div>
    </div>
  );
};

export default ChatGroupWidget;
