import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player/lazy";
import "./styles.css";
import UserActions from "../../../redux/user";
import Select from "../../common/input/select";
import TimeSlot from "../../calendar/timeSlot";
import {
  getCourseDetails,
  getTutorAbout,
  getStudentEnrolledCourses,
  getCourseStudentCalendarView,
  getCourseContents,
  bookCourseSession,
  getOpenCoursesForStudent,
  getTutorAvailabilitySlots,
} from "../../../services/api";
import { capitalize, useWindowSize } from "../../../utils/uiUtils";
import moment from "moment";
import Swotbutton from "../../common/buttons/swotbutton";
import commonColor from "../../../theme/commonColor";
import StudentList from "../../tutor/studentList";
import StudentCalendar from "../calendar/calendar";
import TutorAbout from "./tutorAbout";
import GenericProfilePhoto from "../../../assets/images/tutor_base.jpg";
import GutterHeader from "../../calendar/gutterHeader";
import AgendaEvent from "../../calendar/agendaEvent";
import CourseContainer from "../../../containers/courseContainer";
import CourseContent from "../../../screens/courses/coursesTutor/CourseContent";
import MaterialUploaderViewer from "../../courses/materialUploaderViewer";
import CourseMaterial from "../../courses/courseMaterial";

import CustomFadeLoader from "../../common/CustomFadeLoader";
import CustomToolbar from "../../calendar/customToolbar";
import TutorAboutVertical from "./TutorAboutVertical";
import Swal from "sweetalert2";
import { ReactComponent as About } from "../../../assets/images/courses/about.svg";
import { ReactComponent as Content } from "../../../assets/images/courses/content.svg";
import { ReactComponent as Resources } from "../../../assets/images/courses/resources.svg";
import { ReactComponent as Schedule } from "../../../assets/images/courses/schedule.svg";
import { ReactComponent as Student } from "../../../assets/images/courses/student.svg";
import FooterView from "../../common/footerView";
import { EventType } from "../../../config/constants";
import calendar from "../calendar/calendar";
import Modal from "../../common/modal/modal";
import NewMessageComponent from "../../../chat/components/NewMessageComponent";
import ChatUserModel from "../../../chat/models/chatUser";

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const CourseDetailsUnauthorized = ({
  courseId,
  paymentStatus,
  guid,
  showPopover,
}) => {
  const navigate = useNavigate();
  const [courseDetails, setCourseDetails] = useState();
  const [courseLoaded, setCourseLoaded] = useState(false);
  const [noCoursFound, setNoCoursFound] = useState(false);
  const [studentCourses, setStudentCourses] = useState([]);
  const [myLessons, setMyLessons] = useState([]);
  const [topSelected, setTopSelected] = useState("Schedule");
  const [bottomSelected, setBottomSelected] = useState();
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [fetchMyCourses, setFetchMyCourses] = useState(courseId ? null : true);
  const [courseCalendarInfo, setCourseCalendarInfo] = useState();
  const [courseCalendarEvents, setCourseCalenderEvents] = useState([]);
  const [selectedCourseNameShort, setSelectedCourseNameShort] = useState();
  const [tutorAbout, setTutorAbout] = useState();
  const [calendarNeedsRefresh, setCalendarNeedsRefresh] = useState(true);
  const [eligibleSession, setEligibleSession] = useState();
  const [joinSessionUrl, setJoinSessionUrl] = useState();
  const [joinSessionCourseId, setJoinSessionCourseId] = useState();
  const [courseContent, setCourseContent] = useState([]);
  const [courseAssignments, setCourseAssignments] = useState([]);
  const [courseResources, setCourseResources] = useState([]);
  const [width, height] = useWindowSize();
  const [sendMessageToTutor, setSendMessageToTutor] = useState(false);

  useEffect(() => {
    if (selectedCourseId != courseId) {
      setCourseLoaded(false);
      setSelectedCourseId(courseId ? courseId : null);
    }
  }, [courseId]);

  useEffect(() => {
    const getCurseContent = async () => {
      const result = await getCourseContents(selectedCourseId);
      if (result && result.data && Array.isArray(result.data.data)) {
        console.log(
          "CourseLandingPage - useEffect - got tutorAbout",
          result.data
        );

        setCourseContent(
          result.data.data.filter((i) => i.content_type === "content")
        );
      }
    };
    selectedCourseId && getCurseContent();
  }, [selectedCourseId]);


  const getTutorSlots = async (tutorId) => {
    const result = await getTutorAvailabilitySlots(tutorId);
    if (result && result.ok) {

      const fetchedEvents = result.data.events;
      let filteredEvents;

      // if (course) {
      filteredEvents = fetchedEvents.filter((evt) => evt.nameShort === courseDetails.nameShort || evt.type === EventType.tutorAvailabilitySlot || evt.type === EventType.webinar);
      // } else {
      //   filteredEvents = fetchedEvents;
      // }

      ///Fiters the duplictes events
      // filteredEvents = filteredEvents === null ? filteredEvents : Array.from(new Set(filteredEvents.map(e => e.start)))
      // .map(id => {
      //   return filteredEvents.find(e => e.start === id)
      // })

      const calendarEvents = filteredEvents.map((evt) => {
        let updatedEvent = { ...evt };
        updatedEvent.start = evt.start;
        updatedEvent.end = evt.end;
        updatedEvent.title =
          (evt.type === EventType.groupCourseSession ||
            evt.type === EventType.bookedCourseSession) &&
            evt.nameShort
            ? evt.nameShort
            : evt.title;
        return updatedEvent;
      });
      console.log("getTutorSlots - calendarEvents", calendarEvents);
      setCourseCalenderEvents(filteredEvents);

      // if (setMonthlyHoursTotal) {
      //   console.log("getTutorSlots - getting this month's hours...");
      //   const bookedSessions = fetchedEvents.filter(
      //     (evt) =>
      //       (evt.type === EventType.bookedCourseSession ||
      //         evt.type === EventType.groupCourseSession) &&
      //       moment.unix(evt.start).isSame(moment(), "month")
      //   );

      //   setMonthlyHoursTotal(bookedSessions.length);
      // }
    } else {
      console.log("getTutorSlots - result ERROR ", result);
    }
  };

  const getTutorAboutInfo = async () => {
    const result = await getTutorAbout(courseDetails.tutorFirebaseId);
    if (result) {
      console.log(
        "CourseLandingPage - useEffect - got tutorAbout",
        result.data
      );
      setTutorAbout(result.data?.about);
    }
  };

  useEffect(() => {
    if (courseDetails && !tutorAbout) {
      getTutorAboutInfo();
      getTutorSlots(courseDetails.tutorFirebaseId);
    }
  }, [courseDetails, tutorAbout]);

  useEffect(() => {
    console.log(
      "CourseDetails - useEffect - selectedCourseId / courseLoaded",
      selectedCourseId,
      courseLoaded
    );
    const getCourseInfo = async () => {
      const result = await getCourseDetails(selectedCourseId);
      if (result && result.data) {
        console.log(
          "CourseDetails - useEffect - got course details",
          result.data
        );
        setCourseDetails(result.data);
        setSelectedCourseNameShort(result.data.nameShort);
        setCourseLoaded(true);
      }
    };

    if (!courseLoaded && selectedCourseId) {
      getCourseInfo();
    }
  }, [selectedCourseId, courseLoaded]);

  useEffect(() => {
    // setTopSelected("Schedule")
    setBottomSelected("About");

    // TODO: getCoursesOfStudent, decide if student is enrolled in course and show the relevant parts.

    // if (user.accountType === "Student") {
    // getStudentCourses();
    // }
  }, [selectedCourseId]);

  const refreshCoursesPage = () => {
    if (courseDetails?.tutorFirebaseId) {
      getTutorSlots(courseDetails.tutorFirebaseId);
    }
  };

  useEffect(() => {
    if (studentCourses && studentCourses.length > 0) {
      let studentLessons = studentCourses.map((course) => {
        const ce = courseCalendarEvents?.find((c) => c.courseId === course.courseId);
        const timeValue = ce ? " - " + ce.day + " " + ce.time : "";
        return { value: course.courseId, label: course.nameShort + timeValue };
      });

      if (courseCalendarEvents) {
        const futureLessons = courseCalendarEvents.reduce(
          (previousValue, currentValue) => {
            const f = studentLessons.find(
              (l) => l.value === currentValue.courseId
            );
            if (f) {
              previousValue.push(f);
            }
            return previousValue;
          },
          []
        );

        const oldLessons = studentLessons.filter((el) => {
          return !futureLessons.find((f) => f.value === el.value);
        });
        studentLessons = [...futureLessons, ...oldLessons];
      }
      setMyLessons(studentLessons);
      console.log(
        "CourseDetails - getStudentCourses - studentCourses + selectedCourseId",
        studentLessons,
        selectedCourseId
      );
      const matchingCourse = studentCourses.filter(
        (c) => c.courseId === selectedCourseId
      );

      if (!courseId && !selectedCourseId) {
        courseId = studentLessons[0].value;
        setSelectedCourseId(studentLessons[0].value);
      }
    }
  }, [studentCourses, courseCalendarEvents]);

  const renderTutoringPreference = (course) => {
    if (course.tutoringPreference === "specEdu") {
      return <div className="font-bold"> {"Special Education"}</div>;
    } else if (course.tutoringPreference === "individuals") {
      return <div className="font-bold"> {"Individuals"}</div>;
    } else {
      return <div className="font-bold"> {"Groups"}</div>;
    }
  };

  const renderBottomSelectedTab = () => {
    if (bottomSelected === "About") {
      return (
        <div className="w-full h-full flex flex-col justify-start items-start p-4 relative overflow-y-scroll">
          <div className="text-lg break-all">
            {`${courseDetails.subject} - ${courseDetails.subjectArea}`}{" "}
            <span className="pb-2 italic text-sm">
              by{" "}
              {tutorAbout
                ? `${toTitleCase(tutorAbout.firstName)} ${toTitleCase(
                  tutorAbout.lastName
                )} ${!courseDetails.language
                  ? ""
                  : `taught in  ${toTitleCase(courseDetails.language)}`
                }`
                : `${courseDetails.tutorUsername}  ${!courseDetails.language
                  ? ""
                  : `taught in ${toTitleCase(courseDetails.language)}`
                }`}
            </span>
            {courseDetails.rate != 0 ? `, Rate: ${courseDetails.rate}$/hr` : ''}
          </div>
          <div className="flex flex-start break-all">
            {renderTutoringPreference(courseDetails)}
          </div>
          <div className="text-xs pt-4">{courseDetails.summary}</div>
          {/* <button
            className="focus:outline-none focus:shadow-lg"
            style={{
              bottom: "0.75rem",
              right: "0.75rem",
              position: "absolute",
            }}
          >
            <Edit
              style={{
                width: "1.5rem",
                height: "1.5rem",
                overflow: "hidden",
                fill: commonColor.deepPink,
              }}
            />
          </button> */}
        </div>
      );
    } else if (bottomSelected === "Content") {
      return (
        <div className="w-full h-full flex justify-center items-center p-2 relative">
          {/* No content yet. */}
          {/* <button
            className="focus:outline-none focus:shadow-lg"
            style={{
              bottom: "0.75rem",
              right: "0.75rem",
              position: "absolute",
            }}
          >
            <Edit
              style={{
                width: "1.5rem",
                height: "1.5rem",
                overflow: "hidden",
                fill: commonColor.deepPink,
              }}
            />
          </button> */}
          <CourseContent
            courseId={courseId}
            items={courseContent}
            noItemsText="No lesson yet."
          />
        </div>
      );
    }
    //  else if (bottomSelected === "Resources") {
    //   return (
    //     <div className="w-full h-full flex justify-center items-center p-2 relative">
    //       <MaterialUploaderViewer
    //         courseId={courseId}
    //         uploadPath={`courses/${courseId}/material/resources`}
    //         materialType="resources"
    //         noItemsText="No resources yet."
    //         itemsExisting={courseResources}
    //         updateCallback={(materialItems) => {
    //           setCourseResources(materialItems);
    //         }}
    //         readOnly={true}
    //       />
    //     </div>
    //   );
    // }
  };

  const goToCourseSession = async () => {
    console.log("Going to class!");
    window.open(joinSessionUrl, "_blank");
  };

  const registerAlert = () => {
    Swal.fire({
      title: "Register",
      text: "You need to sign-in/sign-up to interact.",
      icon: "info",
      denyButtonText: "SignIn",
      denyButtonColor: "#407288",
      confirmButtonText: "SignUp",
      confirmButtonColor: "#407288",
      showDenyButton: true,
      showCancelButton: false,
      showConfirmButton: true,
    }).then((result) => {
      if (result.isDenied) {
        //TODO; login
        navigate("/sign-in");
      } else if (result.isConfirmed) {
        navigate("/on-boarding");
      }
    });
  };

  return (
    <div className=" h-full w-full flex flex-col justify-between overflow-y-scroll">
      {/* {courseDetails && <CourseContainer courseId={`"${selectedCourseId}"`} />} */}
      {courseDetails && <CourseContainer courseId={`${selectedCourseId}`} />}
      {courseLoaded ? (
        <div className="h-full w-full flex flex-wrap justify-center pb-4">
          <div className="lg:h-full w-11/12 lg:w-2/4 xl:w-1/2 flex flex-col">
            <div className="lg:h-7/10 w-full px-2 lg:min-h-0">
              <div className=" w-full flex justify-between items-center text-black pb-4 mt-2">
                <div className="pl-4 text-themeDeepGreen text-ellipsis">
                  {" "}
                  {courseDetails ? courseDetails.nameFull : "Mock lesson title"}
                </div>
              </div>

              <div
                className="w-full  h-60 md:h-80 lg:h-17/20 bg-transparent darker_shadow"
                style={{
                  borderRadius: "1.5rem",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                {courseDetails?.videoUrl &&
                  courseDetails?.videoUrl !== "undefined" &&
                  courseDetails?.videoUrl !== "null" ? (
                  <ReactPlayer
                    url={courseDetails?.videoUrl}
                    width={"100%"}
                    height={"100%"}
                    style={{
                      borderRadius: 25,
                      backgroundColor: "black",
                    }}
                    playing={true}
                    controls={true}
                    light={
                      courseDetails?.videoThumbnailUrl
                        ? courseDetails?.videoThumbnailUrl
                        : true
                    }
                  />
                ) : courseDetails?.photoUrl ? (
                  <img
                    src={courseDetails.photoUrl}
                    alt="courseDetailPhoto"
                    width={"100%"}
                    height={"100%"}
                    style={{
                      borderRadius: 25,
                      objectFit: "cover",
                      minWidth: "100%",
                      minHeight: "100%",
                    }}
                  />
                ) : (
                  <ReactPlayer
                    url="https://www.youtube.com/watch?v=saQBW9Xs5eI"
                    width={"100%"}
                    height={"100%"}
                    style={{
                      borderRadius: 25,
                    }}
                  />
                )}

              </div>
            </div>

            <div className="lg:h-3/10 w-full px-2 pt-6 lg:pt-2 lg:min-h-0">
              <div
                className="lg:h-1/5 w-full flex justify-start items-end pl-6"
                style={
                  {
                    // height: "15%"
                  }
                }
              >
                <button
                  className={`w-1/4 pt-2 px-2 pb-1 text-center ${bottomSelected === "About" ? "text-white" : "text-black"
                    } text-sm focus:outline-none`}
                  style={{
                    backgroundColor:
                      bottomSelected === "About"
                        ? commonColor.deepPink
                        : "transparent",
                    borderTopLeftRadius: "1.5rem",
                    borderTopRightRadius: "1.5rem",
                  }}
                  onClick={() => setBottomSelected("About")}
                >
                  <div className="flex justify-center items-center">
                    <About className="w-4" />
                    <p className="hidden ml-2 md:block">About</p>
                  </div>
                </button>

                <button
                  className={`w-1/4 pt-2 px-2 pb-1 text-center ${bottomSelected === "Content" ? "text-white" : "text-black"
                    } text-sm focus:outline-none`}
                  style={{
                    backgroundColor:
                      bottomSelected === "Content"
                        ? commonColor.deepPink
                        : "transparent",
                    borderTopLeftRadius: "1.5rem",
                    borderTopRightRadius: "1.5rem",
                  }}
                  onClick={() => setBottomSelected("Content")}
                >
                  <div className="flex justify-center items-center">
                    <Content className="w-4" />
                    <p className="hidden ml-2 md:block">Lesson</p>
                  </div>
                </button>

                {/* <button
                  className={`w-1/4 pt-2 px-2 pb-1 text-center ${bottomSelected === "Resources" ? "text-white" : "text-black"
                    } text-sm focus:outline-none`}
                  style={{
                    backgroundColor:
                      bottomSelected === "Resources"
                        ? commonColor.deepPink
                        : "transparent",
                    borderTopLeftRadius: "1.5rem",
                    borderTopRightRadius: "1.5rem",
                  }}
                  onClick={() => setBottomSelected("Resources")}
                >
                  <div className="flex justify-center items-center">
                    <Resources className="w-4" />
                    <p className="hidden ml-2 md:block">Resources</p>
                  </div>
                </button> */}
              </div>
              <div
                className="w-full h-60 md-h-80 lg:h-4/5 bg-white darker_shadow flex items-center"
                style={{
                  borderRadius: "1.5rem",
                }}
              >
                {courseDetails && renderBottomSelectedTab()}
              </div>
            </div>
          </div>

          <div
            className="lg:h-full w-11/12 lg:w-2/4 xl:w-1/2 flex flex-col mb-4 lg:mb-0"
            style={{}}
          >
            <div className="h-full w-full flex flex-col">
              <div className="w-full h-full flex flex-col px-2 space-y-2 sm:space-y-0">
                <div className=" w-full lg:h-[40px] flex justify-between items-center text-white pt-4 lg:pt-0 pb-4 mt-2">
                  <div className="pl-4 text-themeDeepGreen">The tutor</div>
                </div>
                <div className="w-full flex flex-col px-2 lg:h-[calc(100%-40px)] ">
                  <div className="w-full flex flex-col lg:h-[18rem] darker_shadow rounded-2xl">
                    <div className="w-full lg:h-full flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 my-2 lg:my-0">
                      <div
                        // className="w-full lg:h-full rounded-2xl shadow-2xl bg-white md:flex-grow"
                        className="w-full lg:h-full md:flex-grow"
                        style={
                          {
                            // minHeight: "25rem",
                          }
                        }
                      >
                        {tutorAbout && (
                          <>
                            <div className="w-full h-full sm:hidden">
                              <TutorAboutVertical
                                about={tutorAbout}
                                courseDetails={courseDetails}
                                registerAlert={() => {
                                  registerAlert();
                                }}
                              />
                            </div>
                            <div className="w-full h-full hidden sm:block">
                              <TutorAbout
                                about={tutorAbout}
                                courseDetails={courseDetails}
                                registerAlert={() => {
                                  registerAlert();
                                }}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* <div className="w-full flex-grow pl-2 pr-4 md:h-3/5 xl:h-7/10"> */}
                  <div className="w-full flex-grow lg:h-[calc(100%-18rem)]">
                    {/* <div className="w-full flex-grow Calendar-height"> */}
                    <div className="w-full h-full pb-4 md:pb-0">
                      {" "}
                      <div className="pl-4 pt-2 text-themeDeepGreen lg:h-[40px]">
                        The tutors calendar
                      </div>
                      <div className="w-full lg:h-[calc(100%-40px)] rounded-2xl bg-white darker_shadow">
                        <StudentCalendar
                          view={"day"}
                          views={["day"]}
                          course={courseDetails}
                          events={courseCalendarEvents}
                          refreshCourse={() => refreshCoursesPage()}
                          setEligibleSession={setEligibleSession}
                          setJoinSessionUrl={setJoinSessionUrl}
                          calendarClassName="sk-calendar"
                          components={{
                            timeSlotWrapper: (props) => <TimeSlot {...props} />,
                            timeGutterHeader: () => (
                              <>
                                <div className="block">
                                  <GutterHeader
                                    labels={[
                                      {
                                        title: "Time",
                                      },
                                    ]}
                                  />
                                </div>
                              </>
                            ),
                            day: {
                              header: () => (
                                <div className="pl-2">
                                  <GutterHeader
                                    labels={[
                                      {
                                        title: "Lesson",
                                        colorStyle: "tertiary",
                                      },
                                    ]}
                                  />
                                </div>
                              ),
                            },
                            toolbar: CustomToolbar,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : noCoursFound ? (
        <div className="flex h-full justify-center flex-col items-center space-y-4">
          <p className="text-white">No course Found</p>
          <Swotbutton
            text={"Search Course"}
            onClick={() => navigate("/find-tutors")}
          />
        </div>
      ) : (
        <CustomFadeLoader radius="2" loading={true} color="#FFFFFF" />
      )}
    </div>
  );
};

export default CourseDetailsUnauthorized;
