import React, { useState, useRef, useEffect, useCallback } from "react";
import ReactPlayer from "react-player/lazy";
import Webcam from "react-webcam";
import commonColor from "../../../theme/commonColor";
import Swotbutton from "../../common/buttons/swotbutton";
import Select from "../../common/input/select";
import SwotWallet from "./swotWallet";

const WebcamWidget = ({ callback }) => {
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [openRecorder, setOpenRecorder] = useState(false);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [videoLocalUrl, setVideoLocalUrl] = useState(null);

  const [deviceId, setDeviceId] = useState({});
  const [devices, setDevices] = useState([]);
  const [device, setDevice] = useState(null);
  const [deviceOptions, setDeviceOptions] = useState([]);
  const [selectedDevceOption, setSelectedDeviceOption] = useState();

  const handleStartCaptureClick = useCallback(() => {
    setVideoLocalUrl(null);
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const handleDownload = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = "react-webcam-stream-capture.webm";
      a.click();
      discardVideo();
    }
  }, [recordedChunks]);

  const discardVideo = () => {
    if (videoLocalUrl) {
      window.URL.revokeObjectURL(videoLocalUrl);
    }
    setRecordedChunks([]);
  };

  const createVideoUrl = () => {
    if (recordedChunks.length) {
      const blob = getVideoBlob();
      const url = URL.createObjectURL(blob);
      setVideoLocalUrl(url);
    }
  };

  const getVideoBlob = () => {
    if (recordedChunks.length) {
      return new Blob(recordedChunks, {
        type: "video/webm",
      });
    }
    return null;
  };

  useEffect(() => {
    if (videoLocalUrl) {
      window.URL.revokeObjectURL(videoLocalUrl);
    }
    if (recordedChunks.length) {
      createVideoUrl();
    }
  }, [recordedChunks]);

  const handleDevices = useCallback(
    (mediaDevices) => {
      const mDevices = mediaDevices.filter(({ kind }) => kind === "videoinput");
      setDevices(mDevices);
      if (mDevices && mDevices.length > 0) {
        setDevice(mDevices[0]);
        setDeviceId(mDevices[0].deviceId);

        const dOptions = [];
        mDevices.map((d, key) => {
          dOptions.push({
            value: d.label || `Device ${key + 1}`,
            label: d.label || `Device ${key + 1}`,
            id: d.deviceId,
          });
        });
        // dOptions = [{label: "Label 1", value: "Value 1"}, {label: "Label 2", value: "Value 2"}, {label: "Label 3", value: "Value 3"}]
        setDeviceOptions(dOptions);
        setSelectedDeviceOption(dOptions[0]);
      }
    },
    [setDevices]
  );

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  return (
    <>
      <div className="w-full sm:w-1/2 sm:pr-1 py-1">
        <Select
          className="w-full"
          defaultValue={selectedDevceOption && selectedDevceOption}
          value={selectedDevceOption && selectedDevceOption}
          onChange={(value) => {
            console.log("selected device", value);
            setSelectedDeviceOption(value);
            setDeviceId(value.id);
            setDevice(
              devices.find((element) => {
                return element.deviceId === value.id;
              })
            );
            // handleChange('tutoringRegistration', value.value)
          }}
          options={deviceOptions}
        />
      </div>
      {recordedChunks.length > 0 && !capturing && videoLocalUrl ? (
        <ReactPlayer
          url={videoLocalUrl}
          width={"100%"}
          height={"80%"}
          onError={() => { }}
          controls={true}
          playing
          autoPlay
        />
      ) : (
        // <Webcam audio={true} ref={webcamRef} />
        <div className="w-full">
          {/* {devices.map((device, key) => ( */}
          {device ? (
            <div>
              <Webcam
                audio={true}
                muted={true}
                ref={webcamRef}
                videoConstraints={{ deviceId: device.deviceId }}
              />
              {/* {device.label || `Device ${deviceId}`} */}
            </div>
          ) : (
            <div>No Camera found</div>
          )}
          {/* ))} */}
        </div>
      )}
      {recordedChunks.length > 0 ? (
        <div className="w-full flex justify-center items-center pt-2 pb-2">
          <Swotbutton
            bgColor={commonColor.deepGreen}
            onClick={() => {
              const videoBlob = getVideoBlob();
              discardVideo();
              callback && callback(videoBlob);
            }}
            className="mr-2"
            text=" Use It"
          />

          <Swotbutton
            onClick={() => {
              discardVideo();
            }}
            className="ml-2"
            text="Discard"
          />
          {/* <button
              onClick={() => {
                handleDownload();
              }}
            >
              Download
            </button> */}
        </div>
      ) : (
        <div className="w-full flex justify-center items-center pt-2 pb-2">
          {capturing ? (
            <Swotbutton onClick={handleStopCaptureClick} text="STOP" />
          ) : (
            <Swotbutton
              bgColor={commonColor.deepGreen}
              onClick={handleStartCaptureClick}
              text="Start Capture"
            />
          )}
        </div>
      )}
    </>
  );
};

export default WebcamWidget;
