import React, { useState, useEffect, useCallback } from "react";
import {
  Calendar,
  momentLocalizer,
  dateFnsLocalizer,
  Views,
} from "react-big-calendar";
import moment from "moment";
import "moment/locale/en-gb";

import "react-big-calendar/lib/css/react-big-calendar.css";
import Modal from "../common/modal/modal";
import "../common/calendar/calendar.css";
import commonColor from "../../theme/commonColor";
import CustomToolbar from "../calendar/customToolbar";
import { STRIPE_REG_STATUSES } from "../../config/constants";
import {
  create30MinutesTimeslots,
  createHourlyTimeslots,
  eventsOverlap,
} from "../../services/calendar";
import {
  createTutorAvailabilitySlots,
  getTutorAvailabilitySlots,
  getSessionDetailsTutor,
  createWebinar,
  deleteWebinar,
  renameTutorAvailabilitySlots,
} from "../../services/api";
import { connect } from "react-redux";
import { EventType } from "../../config/constants";
import Swotbutton from "../common/buttons/swotbutton";
import { createBBBRoom } from "../../services/videoCall";
import { NavigateFunction, useNavigate, Link } from "react-router-dom";
import { updateRefreshCalendar } from "../../services/notifications";
import { addReminder, deleteReminder } from "../../services/reminders";
import { ReactComponent as Drag } from "../../assets/icons/calendar/drag.svg";
import { ReactComponent as Tap } from "../../assets/icons/calendar/tap.svg";
import { ReactComponent as Touch } from "../../assets/icons/calendar/touch-screen.svg";

import Swal from "sweetalert2";
import { isMobileDevice, useWindowSize } from "../../utils/uiUtils";

const TutorCalendar = ({
  user,
  view,
  views,
  course,
  setMonthlyHoursTotal,
  calendarClassName,
  toolbar,
  components,
  notifications,
  calendarReminders,
  fullScreen,
}) => {
  const [events, setEvents] = useState([]);
  const [reminderEvents, setReminderEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedRange, setSelectedRange] = useState();
  const [selectedEvent, setSelectedEvent] = useState();
  const [selectedSession, setSelectedSession] = useState();
  const [isRoomCreated, setIsRoomCreated] = useState(false);
  const [isRoomLoading, setIsRoomLoading] = useState(false);
  const [joinUrl, setJoinUrl] = useState();
  const [webinarPressed, setWebinarPressed] = useState(false);
  const [renamePressed, setRenamePressed] = useState(false);
  const [webinarTitle, setWebinarTitle] = useState();
  const [renameTitle, setRenameTitle] = useState();
  const [needRefresh, setNeedRefresh] = useState(false);
  const [isWebinarCreated, setIsWebinarCreated] = useState(false);
  const [isWebinarCreating, setIsWebinarCreating] = useState(false);
  const [webinarCreationError, setWebinarCreationError] = useState(false);
  const [reminderTitle, setReminderTitle] = useState();
  const [reminderDescription, setReminderDescription] = useState();
  const [showReminderModel, setShowReminderModel] = useState(false);

  const [isMonthView, setIsMonthView] = useState(false); //used for styling only.
  const [calendarViews, setCalendarViews] = useState(["month", "day", "week"]);
  const [calendarDefaultView, setCalendarDefaultView] = useState("week");
  const [width, height] = useWindowSize();
  const [calendarView, setCalendarView] = useState(Views.WEEK);
  const onCalendarViewChange = useCallback(
    (newView) => setCalendarView(newView),
    [setCalendarView]
  );

  moment.locale("en", {
    week: {
      dow: 1, //Monday is the first day of the week.
    },
  });

  const localizer = momentLocalizer(moment);

  const modalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "50%",
      height: "60%",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const getTutorSlots = async () => {
    const result = await getTutorAvailabilitySlots(user.uid);
    if (result && result.ok) {
      console.log(
        "getTutorSlots - course /  result",
        course,
        result.data.events
      );

      const fetchedEvents = result.data.events;
      let filteredEvents;

      if (course) {
        filteredEvents = fetchedEvents.filter(
          (evt) =>
            evt.nameShort === course.nameShort ||
            evt.type === EventType.tutorAvailabilitySlot ||
            evt.type === EventType.webinar
        );
      } else {
        filteredEvents = fetchedEvents;
      }

      ///Fiters the duplictes events
      // filteredEvents = filteredEvents === null ? filteredEvents : Array.from(new Set(filteredEvents.map(e => e.start)))
      // .map(id => {
      //   return filteredEvents.find(e => e.start === id)
      // })

      const calendarEvents = filteredEvents.map((evt) => {
        let updatedEvent = { ...evt };
        updatedEvent.start = moment.unix(evt.start).toDate();
        updatedEvent.end = moment.unix(evt.end).toDate();
        updatedEvent.title =
          (evt.type === EventType.groupCourseSession ||
            evt.type === EventType.bookedCourseSession) &&
            evt.nameShort
            ? evt.nameShort
            : evt.title;
        return updatedEvent;
      });
      console.log("getTutorSlots - calendarEvents", calendarEvents);
      setEvents(calendarEvents);

      if (setMonthlyHoursTotal) {
        console.log("getTutorSlots - getting this month's hours...");
        const bookedSessions = fetchedEvents.filter(
          (evt) =>
            (evt.type === EventType.bookedCourseSession ||
              evt.type === EventType.groupCourseSession) &&
            moment.unix(evt.start).isSame(moment(), "month")
        );

        setMonthlyHoursTotal(bookedSessions.length);
      }
    } else {
      console.log("getTutorSlots - result ERROR ", result);
    }
  };

  useEffect(() => {
    if (width && width > 0) {
      if (width < 1024) {
        setIsMonthView(false);
        setCalendarDefaultView("day");
        setCalendarView(Views.DAY);
        setCalendarViews(["day"]);
      } else {
        setCalendarDefaultView(view ? view : "week");
        if (view) {
          setCalendarView(view);
        }
        setCalendarViews(views ? views : ["month", "day", "week"]);
      }
    }
  }, [width, view]);

  useEffect(() => {
    getTutorSlots();
  }, []);

  useEffect(() => {
    console.log("useEffect - Tutor Calendar -  notifications", notifications);
    if (notifications?.refreshCalendar || notifications?.roomCreated) {
      getTutorSlots();
      updateRefreshCalendar(user.uid, false);
    }
  }, [notifications]);

  useEffect(() => {
    console.log("useEffect - Tutor Calendar -  reminders", calendarReminders);
    if (calendarReminders && calendarReminders.length) {
      const rEvents = calendarReminders.map((rem) => {
        return {
          start: moment.unix(rem.start).toDate(),
          end: moment.unix(rem.end).toDate(),
          title: rem.shortName,
          type: EventType.reminder,
          reminderId: rem.id,
          description: rem.description,
        };
      });
      setReminderEvents(rEvents);
    }
  }, [calendarReminders]);

  useEffect(() => {
    let updatedEvents = [];
    if (events && events.length > 0) {
      updatedEvents = [...events];
    }
    if (reminderEvents && reminderEvents.length > 0) {
      updatedEvents = [...updatedEvents, ...reminderEvents];
    }
    setAllEvents(updatedEvents);
  }, [reminderEvents, events]);

  useEffect(() => {
    console.log("useEffect - events", events, joinUrl);
    if (selectedSession?.sessionId && !joinUrl) {
      const bbbCalurl = localStorage.getItem(
        "bbb_" + selectedSession.sessionId
      );
      if (bbbCalurl) {
        setJoinUrl(bbbCalurl);
        setIsRoomCreated(true);
      }
    }
  }, [
    events,
    joinUrl,
    isRoomLoading,
    isRoomCreated,
    selectedSession,
    webinarPressed,
    renamePressed,
  ]);

  const eventPropGetter = (event = {}, start, end, isSelected) => {
    const { type } = event;

    let style = {
      textAlign: "center",
      backgroundColor: "unset",
      // border: 1,
      borderRadius: 0,
      borderWidth: "0.5px",
      color: "black",
    };

    switch (type) {
      case EventType.tutorAvailabilitySlot:
        style.backgroundColor = "rgb(64, 114, 136, 0.7)";
        style.color = "white";
        style.borderColor = "rgb(64, 114, 136, 1.0)";
        break;
      case EventType.courseSessionBookedByOthers:
      case EventType.bookedCourseSession:
        style.backgroundColor = "rgb(182, 185, 223, 0.7)";
        style.color = "white";
        style.borderColor = "rgb(182, 185, 223, 1.0)";
        break;
      case EventType.webinar:
        style.backgroundColor = "rgb(153, 59, 100, 0.7)";
        style.color = "white";
        style.borderColor = "rgb(153, 59, 100, 0.9)";
        break;
      case EventType.reminder:
        style.backgroundColor = "rgb(46, 113, 213, 0.7)";
        style.color = "white";
        style.borderColor = "rgb(46, 113, 213, 0.9)";
        break;
      default:
        break;
    }

    return {
      style,
    };
  };

  const checkIfClick = (start, end) => {
    const slots = create30MinutesTimeslots(start, end);
    console.log("TutorCalendar - checkIfClick", slots);
    if (slots.length == 1) {
      return true;
    }
  };

  const checkAddReminder = (slot) => {
    const dNow = Math.round(new Date().getTime() / 1000);
    const dStart = Math.round(slot.start.getTime() / 1000);
    if (dStart >= dNow) {
      setSelectedRange(slot);
      setReminderTitle(null);
      setReminderDescription(null);
      setShowReminderModel(true);
    }
  };

  const selectSlot = (slot) => {
    console.log("TutorCalendar - selectSlot", slot);
    if (
      (slot.action === "click" || slot.action === "doubleClick") &&
      slot.start &&
      slot.end
    ) {
      checkAddReminder(slot);
    } else if (slot.action === "select" && slot.start && slot.end) {
      if (checkIfClick(slot.start, slot.end)) {
        checkAddReminder(slot);
      } else {
        setSelectedRange(slot);
        createAvailabilitySlot(slot);
      }
    }
  };

  const createAvailabilitySlot = (range) => {
    const hourlySlots = createHourlyTimeslots(
      range.start,
      range.end,
      EventType.tutorAvailabilitySlot,
      "Available for tutoring"
    );
    console.log(
      "TutorCalendar - createAvailabilitySlot - hourlySlots",
      hourlySlots
    );
    // check if an event in the same hourly slot already exists!
    const checkedHourlySlots = hourlySlots.filter((evt) => {
      const overlaps = events.filter((event) => eventsOverlap(evt, event));
      console.log(
        "TutorCalendar - createAvailabilitySlot - hourlySlots.filter",
        evt,
        overlaps
      );
      return overlaps.length === 0;
    });

    console.log(
      "createAvailabilitySlot - checkedHourlySlots",
      checkedHourlySlots
    );
    const updatedAvailabilitySlots = [...events, ...checkedHourlySlots];

    if (
      !user.ngoUser &&
      updatedAvailabilitySlots.length > 2 &&
      user.stripeStatus !== STRIPE_REG_STATUSES.APPROVED
    ) {
    /*  if (user.stripeStatus === STRIPE_REG_STATUSES.INACTIVE) {
        Swal.fire({
          title: "Complete Stripe Registration",
          text: "You can only create two free lessons. To be able to start teaching in full, please complete your Stripe registration.",
          icon: "info",
          confirmButtonText: "OK",
          buttonColor: "#DD6B55",
        });
      } else {
        Swal.fire({
          title: "Stripe Registration Required",
          text: "You can only create two free lessons. To be able to start teaching in full, please complete your Stripe registration.",
          icon: "info",
          confirmButtonText: "OK",
        });
      }
      return;
      */
    }
    setEvents(updatedAvailabilitySlots);
    setShowModal(false);
    // persistAvailabilitySlots(updatedAvailabilitySlots);
    persistAvailabilitySlots(checkedHourlySlots);
  };

  const persistAvailabilitySlots = (slots) => {
    const unixSlots = slots.map((evt) => {
      return {
        title: evt.title,
        type: evt.type,
        start: moment(evt.start).unix(),
        end: moment(evt.end).unix(),
      };
    });

    createTutorAvailabilitySlots({ events: unixSlots }, user.uid);
  };

  const removeEvent = () => {
    let updatedEvents = events.filter(
      (evt) => evt.start !== selectedEvent.start
    );

    let eventToDelete = selectedEvent;
    eventToDelete.type = EventType.deleteTutorAvailabilitySlot;
    setEvents(updatedEvents);
    persistAvailabilitySlots([eventToDelete]);
    setShowModal(false);
  };

  const removeReminder = () => {
    if (selectedEvent.reminderId) {
      let updatedEvents = reminderEvents.filter(
        (evt) =>
          !(
            evt.type === EventType.reminder &&
            evt.reminderId === selectedEvent.reminderId
          )
      );

      setReminderEvents(updatedEvents);
      //remove reminder from server
      deleteReminder(user.uid, selectedEvent.reminderId);
      setShowModal(false);
    }
  };

  const handleSelectedEvent = async (event, e) => {
    console.log("handleSelectedEvent - input:", event);
    setWebinarPressed(false);
    setRenamePressed(false);
    if (
      event.type === EventType.bookedCourseSession ||
      event.type === EventType.groupCourseSession ||
      event.type === EventType.webinar
    ) {
      const response = await getSessionDetailsTutor(event.sessionId);
      console.log("handleSelectedEvent - response", response.data);
      if (response) {
        const session = {
          ...response.data.session,
          ...event,
        };
        setSelectedSession(session);
      }
    } else if (event.type === EventType.reminder) {
      if (
        calendarReminders &&
        calendarReminders.length > 0 &&
        event.reminderId
      ) {
        const reminderFound = calendarReminders.find(
          (r) => r.id === event.reminderId
        );
        if (!reminderFound) {
          return;
        }
      }
    }

    setSelectedEvent(event);
    setShowModal(true);
  };

  const createCallRoom = async () => {
    setIsRoomLoading(true);
    const callUrl = await createBBBRoom(
      { sessionId: selectedSession.sessionId },
      user.uid
    );
    console.log("join url", callUrl);
    setJoinUrl(callUrl);
    setIsRoomCreated(true);
    setIsRoomLoading(false);

    localStorage.setItem("bbb_" + selectedSession.sessionId, callUrl);
  };

  const deleteWebinarMain = async () => {
    //Delete webinar

    const payload = {
      courseId: course.courseId,
      bookEvent: {
        title: webinarTitle,
        start: moment(selectedEvent.start).unix(),
        end: moment(selectedEvent.end).unix(),
        type: "webinar",
      },
    };
    console.log("deleteWebinar:", payload);
    const result = await deleteWebinar(payload);

    if (result.ok) {
      console.log("delete webinar - result", result);
      const updatedEvent = { ...selectedEvent };
      getTutorSlots();
      closeModal();
    }
  };

  const goToCourseSession = async () => {
    console.log("Going to class!");
    window.open(joinUrl, "_blank");
  };

  const renderModalAction = (isWebinar) => {
    // const eligible = true;
    const eligible = decideIfSessionIsCallEligible(selectedSession);
    if (isRoomCreated && joinUrl) {
      console.log("JOIN URL = ", joinUrl)
      // return <Swotbutton text={"Join Call"} onClick={() => joinCall()} />;
      return (
        <div>
          <a
            href={joinUrl}
            style={{ overflowX: "visible", color: "green" }}
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
          >
            Join Lesson
          </a>
        </div>
      );
    } else if (isRoomLoading) {
      return <div>Creating Room...</div>;
    } else if (eligible && !isRoomCreated && !isRoomLoading) {
      return isWebinar ? (
        <div className="flex flex-col">
          <Swotbutton text={"Create room"} onClick={() => createCallRoom()} />{" "}
          <Swotbutton
            text={"Delete Free Lesson"}
            onClick={() => deleteWebinarMain()}
          />{" "}
        </div>
      ) : (
        <Swotbutton text={"Create room"} onClick={() => createCallRoom()} />
      );
    } else {
      return isWebinar ? (
        <div className="flex flex-col">
          <div>Nothing to do at the moment.</div>
          <Swotbutton
            text={"Delete Free Lesson"}
            onClick={() => deleteWebinarMain()}
          />{" "}
        </div>
      ) : (
        <div>Nothing to do at the moment.</div>
      );
    }
  };

  const decideIfSessionIsCallEligible = (session) => {
    // check moment now with the start event time.

    var ss = moment(session.start).add(-10, "m");
    return moment().isSameOrAfter(ss) && moment().isBefore(session.end);
  };

  const createNewWebinar = async () => {
    if (!course) {
      return;
    }
    setIsWebinarCreating(true);
    console.log("creating lesson", webinarTitle, selectedEvent, course);
    const payload = {
      courseId: course.courseId,
      bookEvent: {
        title: webinarTitle,
        start: moment(selectedEvent.start).unix(),
        end: moment(selectedEvent.end).unix(),
        type: "webinar",
      },
    };
    const result = await createWebinar(payload);

    if (result.ok) {
      console.log("created webinar - result", result);
      const updatedEvent = { ...selectedEvent };
      getTutorSlots();
      setIsWebinarCreated(true);
    } else {
      setWebinarCreationError(true);
    }
    setIsWebinarCreating(false);
  };

  const renameSlot = async () => {
    var updatedEvents = events;

    const objIndex = updatedEvents.findIndex(
      (evt) => evt.start === selectedEvent.start
    );

    if (objIndex >= 0) {
      updatedEvents[objIndex].title = renameTitle;
      setEvents(updatedEvents);

      const slt = updatedEvents[objIndex];

      const unixSlot = {
        title: slt.title,
        type: slt.type,
        start: moment(slt.start).unix(),
        end: moment(slt.end).unix(),
      };

      renameTutorAvailabilitySlots(unixSlot, user.uid);
    }

    setShowModal(false);
  };

  const closeModal = () => {
    setShowModal(false);
    setIsWebinarCreated(false);
    setIsWebinarCreating(false);
    setWebinarCreationError(false);
    setShowReminderModel(false);
    setReminderTitle();
    setReminderDescription();
  };

  const saveReminder = () => {
    setShowReminderModel(false);
    const reminderId = moment(selectedRange.start).format("YYYY-MM-DD-HH-mm");

    var reminder = {
      id: reminderId,
      shortName: reminderTitle,
      description: reminderDescription,
      start: Math.round(selectedRange.start.getTime() / 1000),
      end: Math.round(selectedRange.end.getTime() / 1000),
      type: "calendar_reminder",
    };

    //TODO: check if this reminder exists already
    addReminder(user.uid, reminder, reminderId);
    console.log("");
  };
  /**
   * "session": {
          "firstName": "Anthony",
          "lastName": " Demetriades",
          "photoUrl": "siwqdjwdwdjwdwo",
          "firebaseId": "kDq3xlLcL3Vln07EYELvppt4UeH3",
          "registration": "online",
          "status": "pending"
      }
   */
  const renderSessionModal = () => {
    if (selectedSession) {
      return (
        <div
          className="h-full flex flex-col justify-start items-center"
          style={{
            zIndex: 10001,
          }}
        >
          <button className="my-4 text-center font-bold focus:outline-none">
            {selectedEvent.nameShort}
          </button>
          <div className="w-full my-4 flex flex-col items-center justify-start lg:flex-row lg:space-x-4 lg:justify-between">
            <div>
              <b>From:</b> {moment(selectedSession.start).format("LLL")}
            </div>
            <div>
              <b>To:</b> {moment(selectedSession.end).format("LLL")}
            </div>
          </div>
          <div className="w-full my-4 flex flex-row justify-between">
            <div
              className="pr-4"
              style={{
                wordBreak: "break-all",
              }}
            >
              With: {selectedSession.firstName} {selectedSession.lastName}
            </div>
            {renderModalAction()}
          </div>
          <div className="w-full my-4 flex flex-row justify-between">
            <div>Status: {selectedSession.status} </div>
            <div>To be conducted: {selectedSession.registration} </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="h-full flex flex-col justify-center items-center">
          <button className="text-center font-bold focus:outline-none">
            Go to {selectedEvent.nameShort}
          </button>
        </div>
      );
    }
  };

  const renderModalContent = () => {
    if (
      selectedEvent &&
      (selectedEvent.type === EventType.bookedCourseSession ||
        selectedEvent.type === EventType.groupCourseSession)
    ) {
      return renderSessionModal();
    }

    if (
      selectedEvent &&
      selectedEvent.type === EventType.tutorAvailabilitySlot
    ) {
      return (
        <div className="w-full h-full flex flex-col justify-around">
          <div className="text-center px-4 py-4 text-sm  italic ">
            {/* Availability timeslot */}
            {selectedEvent.title}
            <br />
            <br />
            From: {selectedEvent && moment(selectedEvent.start).format("LLL")}
            <br />
            <br />
            To: {selectedEvent && moment(selectedEvent.end).format("LLL")}
          </div>
          {webinarPressed ? (
            <div className="w-full flex flex-row justify-around py-2">
              {isWebinarCreating && (
                <div className="italic text-center">Creating...</div>
              )}
              {!isWebinarCreating && !isWebinarCreated && (
                <div className="w-full flex flex-row justify-around py-2">
                  {/* <div className="lg:w-1/3 w-2/5 flex flex-row justify-end items-center"> */}

                  {/* <div className="lg:w-2/3 w-3/5 flex flex-row justify-start px-2 py-1"> */}
                  <div className="flex  px-2 py-1 space-x-2 flex-col lg:flex-row justify-center items-center md:justify-start gap-4 md:gap-1">
                    <p className="w-fit">Lesson Title</p>
                    <input
                      className="flex-grow bg-white appearance-none border-2 focus:outline-none 
   focus:border-opacity-100 py-2 px-2 m-0"
                      id="title"
                      name="title"
                      type="text"
                      placeholder={"Enter lesson title"}
                      value={webinarTitle}
                      onChange={(event) => setWebinarTitle(event.target.value)}
                    />
                    <button
                      className="text-green-500 hover:text-green-600 
border-green-500  hover:border-green-600 border p-2 rounded-lg focus:outline-none"
                      onClick={() => createNewWebinar()}
                    >
                      Create
                    </button>
                  </div>
                </div>
              )}
              {isWebinarCreated && (
                <div className="italic text-center">Free Lesson Created</div>
              )}
              {webinarCreationError && (
                <div className="italic text-center">
                  Somethin went wrong. Please try again.
                </div>
              )}
            </div>
          ) : renamePressed ? (
            <div className="w-full flex flex-row justify-around py-2">
              <div className="w-full flex flex-row justify-around py-2">
                {/* <div className="lg:w-1/3 w-2/5 flex flex-row justify-end items-center"> */}
                {/* <div className="lg:w-2/3 w-3/5 flex flex-row justify-start px-2 py-1"> */}
                <div className="flex  px-2 py-1 space-x-2 flex-col lg:flex-row justify-center items-center md:justify-start gap-4 md:gap-1">
                  <p className="w-fit">Lesson Title</p>
                  <input
                    className="flex-grow bg-white appearance-none border-2 focus:outline-none 
   focus:border-opacity-100 py-2 px-2 m-0"
                    id="title"
                    name="title"
                    type="text"
                    placeholder={"Enter lesson title"}
                    value={webinarTitle}
                    onChange={(event) => setRenameTitle(event.target.value)}
                  />
                  <button
                    className="text-green-500 hover:text-green-600 
border-green-500  hover:border-green-600 border py-2 px-4 md:p-2 md:px-2 rounded-lg focus:outline-none"
                    onClick={() => renameSlot()}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex justify-around sm:justify-around px-4 py-4 flex-col space-y-2 sm:space-y-0 sm:space-x-4 sm:flex-row">
              {course && (
                <button
                  className="text-green-500 hover:text-green-600 
border-green-500  hover:border-green-600 border p-2 rounded-lg focus:outline-none"
                  onClick={() => setWebinarPressed(true)}
                >
                  Free lesson
                </button>
              )}
              <button
                className="text-green-500 hover:text-green-600 
border-green-500  hover:border-green-600 border p-2 rounded-lg focus:outline-none"
                onClick={() => setRenamePressed(true)}
              >
                Rename
              </button>
              <button
                className="text-red-500 hover:text-red-600 
border-red-500  hover:border-red-600 border p-2 rounded-lg focus:outline-none"
                onClick={() => removeEvent()}
              >
                Remove
              </button>
            </div>
          )}
        </div>
      );
    }

    if (selectedEvent && selectedEvent.type === EventType.webinar) {
      return (
        <div className="w-full h-full flex flex-col justify-around">
          <button className="my-4 text-center font-bold focus:outline-none">
            {selectedEvent.nameShort}
          </button>
          <div className="text-center px-4 py-4 text-lg">
            Lesson: {selectedEvent.title}
          </div>
          <div className="w-full my-4 flex flex-row justify-center">
            {renderModalAction(true)}
          </div>
        </div>
      );
    }

    if (selectedEvent && selectedEvent.type === EventType.reminder) {
      return (
        <div className="w-full h-full flex flex-col justify-around">
          <div className="text-center px-4 pt-4 text-sm">
            Reminder: {selectedEvent.title}
          </div>
          <div className="text-center px-4 pb-4 text-sm italic">
            {selectedEvent.description ?? ""}
          </div>
          <div className="flex justify-around sm:justify-around px-4 py-4 flex-col space-y-2 sm:space-y-0 sm:space-x-4 sm:flex-row">
            <button
              className="text-red-500 hover:text-red-600 
border-red-500  hover:border-red-600 border p-2 rounded-lg focus:outline-none"
              onClick={() => removeReminder()}
            >
              Remove
            </button>
            <button
              className="text-gray-700 hover:text-gray-800 
border-gray-700  hover:border-gray-800 border p-2 rounded-lg focus:outline-none"
              onClick={() => closeModal(true)}
            >
              Cancel
            </button>
          </div>
        </div>
      );
    }
  };

  const renderReminderModelContent = () => {
    return (
      <div>
        <div className="w-full h-full flex flex-col justify-around py-2 space-y-2">
          <div className="text-center px-4 py-4 text-sm  italic ">
            Set Reminder
            <br />
            <br />
            Start: {selectedRange && moment(selectedRange.start).format("LLL")}
            <br />
            End: {selectedRange && moment(selectedRange.end).format("LLL")}
          </div>
          <div className="w-full">
            <input
              className="w-full bg-white appearance-none border-2 focus:outline-none 
   focus:border-opacity-100 py-2 px-2 m-0"
              id="title"
              name="title"
              type="text"
              placeholder={"Enter title"}
              value={reminderTitle}
              onChange={(event) => {
                setReminderTitle(event.target.value);
              }}
            />
          </div>
          <div className="w-full">
            <input
              className="w-full flex-grow bg-white appearance-none border-2 focus:outline-none 
   focus:border-opacity-100 py-2 px-2 m-0"
              id="description"
              name="description"
              type="text"
              placeholder={"Enter description"}
              value={reminderDescription}
              onChange={(event) => {
                setReminderDescription(event.target.value);
              }}
            />
          </div>
          <div className="flex justify-around sm:justify-around px-4 py-2 flex-col space-y-2 sm:space-y-0 sm:space-x-4 sm:flex-row">
            {/* {course && ( */}
            <button
              className={`${
                reminderTitle && reminderTitle?.trim().length > 0
                  ? "text-green-500 hover:text-green-600 border-green-500 hover:border-green-600"
                  : "text-gray-300 hover:text-gray-300 border-gray-300"
              } border p-2 rounded-lg focus:outline-none`}
              onClick={() => {
                saveReminder();
              }}
              disabled={!reminderTitle || reminderTitle?.trim().length === 0}
            >
              Set Reminder
            </button>
            {/* )} */}
            <button
              className="text-gray-500 hover:text-gray-600 border-gray-500 hover:border-gray-600 border p-2 rounded-lg focus:outline-none"
              onClick={() => {
                closeModal();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`w-full ${!fullScreen ? "h-full" : ""} ${
        isMonthView ? "h-full" : ""
      } rounded-lg p-4 flex flex-col justify-start overflow-y-hidden bg-white mt-6`}
    >
      {view !== "day" && (
        <div className="text-lg text-center w-full h-1/10 py-2">
          Select the timeslots that you are available to tutor
          <p className="text-sm italic mt-1">
            * Your course can consist out of one or more lessons. Select
            timeslots that you are available to teach lessons.
          </p>
        </div>
      )}
      <div
        className={`w-full ${view !== "day" ? "h-9/10" : "h-[calc(100%-7rem)]"
          } pt-1 pb-2`}
      >
        {/* <div className="w-full overflow-y-scroll py-2"> */}
        <Calendar
          localizer={localizer}
          defaultDate={new Date()}
          defaultView={calendarDefaultView}
          // defaultView={view ? view : width < 1024 ? "day" : "week"}
          events={allEvents}
          // style={{ height: "90vh" }}
          selectable={true}
          scrollToTime={new Date()}
          //step={60}
          //timeslots={1}
          onSelectSlot={selectSlot}
          onSelectEvent={handleSelectedEvent}
          eventPropGetter={eventPropGetter}
          views={calendarViews}
          view={calendarView}
          // views={views ? views : width < 1024 ? [("day")] : [("month", "day", "week")]}
          // views={views ? views : width < 768 ? [("day")] : width < 1024 ? [("day","week")] : [("month", "day", "week")]}
          onView={(v) => {
            console.log("" + v);
            setIsMonthView(v === "month");
            onCalendarViewChange(v);
          }}
          className={calendarClassName}
          toolbar={toolbar}
          components={components}
        />
      </div>
      {view && (
        <div
          className={`h-[7rem] grid ${
            // isMobileDevice() ? "grid-cols-2" :
            "grid-cols-2"
            } gap-1 content-center ...`}
        >
          <div>
            <Drag />
          </div>
          <div>
            <Tap />
          </div>
          {/* {!isMobileDevice() && ( */}
          {/* <div>
            <Touch />
          </div> */}
          {/* )} */}
          <div
            className="w-full text-xs"
            style={{ padding: "8px", paddingTop: "0px" }}
          >
            <p className="text-center">Set time available to teach</p>
          </div>
          <div
            className="w-full text-xs"
            style={{ padding: "8px", paddingTop: "0px" }}
          >
            <p className="text-center">Set a reminder</p>
          </div>
          {/* {!isMobileDevice() && ( */}
          {/* <div
            className="w-full text-xs"
            style={{ padding: "8px", paddingTop: "0px" }}
          >
            <p className="text-center"></p>
          </div>
          )} */}
        </div>
      )}

      <Modal
        isOpen={showModal}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => closeModal()}
        // contentLabel="Create new event"
        setMaxSize={true}
      >
        {renderModalContent()}
      </Modal>

      <Modal
        isOpen={showReminderModel}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => {
          closeModal();
        }}
      // contentLabel="Create new event"
      >
        {renderReminderModelContent()}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  notifications: state.notifications.notifications,
  calendarReminders: state.calendarReminders.reminders,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TutorCalendar);
