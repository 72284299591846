import React, { useEffect, useState } from "react";
import Swotbutton from "../../components/common/buttons/swotbutton";
import { assignH5pToCourse, removeH5pFromCourse } from "../../services/api";
import commonColor from "../../theme/commonColor";
import {
  IContentGroup,
  IContentWithCourseEntry,
} from "../services/ContentService";

interface CourseModel {
  courseId: number;
  nameFull?: string;
  nameShort: string;
  photoUrl?: string;
  rate?: number;
  studentsEnroled?: string;
  subjectArea?: string;
  summary?: string;
  tutorFirebaseId?: string;
  type?: string;
  videoThumbnailUrl?: string;
  videoUrl?: string;
}
const ContentCourseListComponent = ({
  content,
  courses,
  assignToCourse,
  removeFromCourse,
}: {
  content: IContentGroup;
  courses: [];
  assignToCourse: (contentId: string, courseId: number) => void;
  removeFromCourse: (itemId: number) => void;
}) => {
  const [linkedCourses, setLinkedCourses] = useState([]);
  const [otherCourses, setOtherCourses] = useState([]);

  useEffect(() => {
    const existingCourses = content.contents.map((c) => c.course_id);
    const lc = courses.filter((c: CourseModel) =>
      existingCourses.includes(c.courseId)
    );
    const oc = courses.filter(
      (c: CourseModel) => !existingCourses.includes(c.courseId)
    );
    setLinkedCourses(lc);
    setOtherCourses(oc);
  }, []);

  const Section = ({
    title,
    items,
    isLinkedCourses,
  }: {
    title: string;
    items: any[];
    isLinkedCourses: boolean;
  }) => {
    return (
      <div className="w-full flex flex-col pb-4 max-h-full">
        <div className="w-full">
          <h2>{title}</h2>
        </div>
        <div className="w-full">
          {items?.map((value, index) => {
            return (
              <Row
                id={"" + index}
                courseId={value.courseId}
                courseName={value.nameShort}
                isLinkedCourses={isLinkedCourses}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const Row = ({
    id,
    courseId,
    courseName,
    isLinkedCourses,
  }: {
    id: string;
    courseId: number;
    courseName: string;
    isLinkedCourses: boolean;
  }) => {
    return (
      <div id={id} className="w-full flex justify-between py-2">
        <div className="break-all mr-2">{courseName}</div>
        <div>
          {!isLinkedCourses ? (
            <Swotbutton
              text="Assign"
              bgColor={commonColor.deepGreen}
              onClick={() => {
                assignToCourse(content.contentId, courseId);
              }}
            />
          ) : (
            <Swotbutton
              text="Remove"
              onClick={() => {
                removeCourse(courseId);
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const assignCourse = async (courseId, contentId) => {
    const result = await assignH5pToCourse(contentId, courseId);
  };

  const removeCourse = async (courseId) => {
    const item = content.contents.find((c) => c.course_id === courseId);
    if (item?.id) {
      removeFromCourse(item.id);
    }
    //TODO: assign course with the contentId, and update it locally
  };

  return (
    <div className="w-full flex flex-col h-full overflow-y-scroll">
      {linkedCourses.length > 0 && (
        <Section title="Linked Course" items={linkedCourses} isLinkedCourses={true} />
      )}
      {otherCourses.length > 0 && (
        <Section title= {linkedCourses.length > 0 ? "Other courses" : "courses"} items={otherCourses} isLinkedCourses={false} />
      )}
      {linkedCourses.length === 0 && otherCourses.length === 0 && (
        <div>No course found to link with.</div>
      )}
    </div>
  );
};

export default ContentCourseListComponent;
