import React, { useEffect, useState } from "react";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Label } from "recharts";
import moment from "moment";
import _ from "lodash";
import Select from "../../common/input/select";
import { useWindowSize } from "../../../utils/uiUtils";

const TutorStatistics = ({ user, transactions }) => {
  const defaultGraphOption = {
    value: GraphPeriod.DAY,
    label: GraphPeriod.DAY,
  };

  const defaultCourseOption = {
    value: "All",
    label: "All",
  };

  const [graphOptions, setGraphOptions] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [graphBy, setGraphBy] = useState(defaultGraphOption.value);
  const [courseBy, setCourseBy] = useState(defaultCourseOption.value);
  const [graphData, setGraphData] = useState([]);
  const [width, height] = useWindowSize();
  const [graphWidth, setGraphWidth] = useState(600);

  //run only once. Initialze data needed
  useEffect(() => {
    const getGraphOptions = async () => {
      const options = Object.values(GraphPeriod).map((g) => {
        return { value: g, label: g };
      });
      setGraphOptions(options);
    };
    getGraphOptions();
  }, []);

  //Update the course-dropdown when transacion updates
  useEffect(() => {
    const getSubjectOptions = async () => {
      const courses = transactions?.map((t) => t.
      course_shortname) ?? [];
      const uniqueCourses = _.uniq(courses);
      let options = uniqueCourses.map((c) => {
        return { value: c, label: c };
      });
      options = [defaultCourseOption].concat(options);
      setCourseOptions(options);
    };
    getSubjectOptions();
  }, [transactions]);

  //Update graph-data whenever courseDropdown or graphtypeDropdown values changes
  useEffect(() => {
    const processData = async () => {
      const filteredTrans =
        !courseBy || courseBy === "All"
          ? transactions
          : (transactions ?? []).filter((g) => g.
          course_shortname === courseBy);
      const groupedResults = _.groupBy(filteredTrans ?? [], (result) =>
        moment
          .unix(result["created_timestamp"])
          .startOf(graphBy)
          .format("YYYYMMDD")
      );
      const keys = Object.keys(groupedResults).sort();
      // const sortedArray  = array.sort((a,b) => new Moment(a.date).format('YYYYMMDD') - new Moment(b.date).format('YYYYMMDD'))

      let d = _.map(keys, (key) => {
        let group = groupedResults[key];
        let gItem = group[0];
        const name =
          graphBy === GraphPeriod.month
            ? moment(key, "YYYYMMDD").format("MMM/YY")
            : graphBy === GraphPeriod.year
            ? moment(key, "YYYYMMDD").format("YYYY")
            : moment(key, "YYYYMMDD").format("MM/DD");

        let item = {
          name: name,
          uv: _.sumBy(group, "amount"),
        };
        return item;
      });

      setGraphData(d);

      //Note: This is a fix for iOS. Don't remove this duplicate setData line
      setTimeout(() => {
        setGraphData(d.map(d => { return {name: d.name, uv: d.uv}}));
      }, 500);
      
    };

    processData();
  }, [courseBy, graphBy]);

  //Calculate widht-of graph according to screen width
  useEffect(() => {
    let wrapperWidth= document.getElementById("chart-wrapper").offsetWidth
    console.log("screen size changed", width, height, wrapperWidth);
    setGraphWidth(wrapperWidth - 30);
  /*
    if (width < 1280) {
      const widthCalcualted = (width / 100) * (width < 1024 ? 80 : 75);
      
      console.log("width: ",width," calculated width: ",widthCalcualted);

      if (graphWidth !== widthCalcualted) {
        setGraphWidth(widthCalcualted);
      }
    } else {
      const widthCalcualted = (width / 100) * 30;
      console.log("width: ",width," calculated width: ",widthCalcualted);

      setGraphWidth(widthCalcualted);
    }
    */
  }, [width]);

  const CustomizedXAxisTick = (props) => {
    const { x, y, payload } = props;
  
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {payload.value}
        </text>
      </g>
    );
  };

  const CustomizedYAxisTick = (props) => {
    const { x, y, payload } = props;
  
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          // transform="rotate(-35)"
        >
          ${payload.value}
        </text>
      </g>
    );
  };
  const renderLineChart = () => { 
    return <LineChart width={graphWidth} height={360} data={graphData}>
      <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
      <XAxis dataKey="name" scale="band" height={60} tick={<CustomizedXAxisTick />} >
         <Label value={graphBy.toUpperCase()} position='insideBottom' offset={0} />
         </XAxis>
        <YAxis label={{ value: 'Payments', angle: 0, position: 'insideLeft', angle: '-90'}} tick={<CustomizedYAxisTick />}  />
      <Line
        type="monotone"
        dataKey="uv"
        stroke="#8884d8"
        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
      />
      {/* <Line type="monotone" dataKey="pv" stroke="#82ca9d" /> */}
    </LineChart>
  };

  return (
    <div className="w-full h-full flex flex-col justify-start items-center overflow-y-scroll">
      <div className="w-full flex justify-around p-4 mt-1 mt-autp">
        <div className="w-1/2 flex flex-row justify-start px-2">
          <Select
            placeholder={defaultCourseOption.label}
            className="w-full text-sm"
            defaultValue={defaultCourseOption}
            closeMenuOnSelect={true}
            // value={defaultGraphOption}
            onChange={(value) => {
              setCourseBy(value?.value ?? defaultCourseOption.value);
            }}
            //isMulti
            options={courseOptions}
          />
        </div>

        <div className="w-1/2 flex flex-row justify-start px-2">
          <Select
            placeholder={defaultGraphOption.label}
            className="w-full text-sm"
            defaultValue={defaultGraphOption}
            closeMenuOnSelect={true}
            // value={defaultGraphOption}
            onChange={(value) => {
              setGraphBy(value?.value ?? defaultGraphOption.value);
            }}
            //isMulti
            options={graphOptions}
          />
        </div>
      </div>

        {!transactions.length ? 
        <div className="mt-2 py-1 px-12 text-center">You have not done any courses yet, once you start your journey, come back to view your statistics.</div>
      : '' }
      <div className="w-full flex-grow flex justify-center items-center mb-auto" id="chart-wrapper">
        {renderLineChart()}
      </div>
    </div>
  );
};

const GraphPeriod = {
  DAY: "day",
  // week: "isoWeek", //week
  week: "week", //week
  month: "month",
  year: "year",
};
export default TutorStatistics;
