import { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { auth } from "../../config/firebase";
import { confirmPasswordReset } from "firebase/auth";
import commonColor from "../../theme/commonColor";
// import { ReactComponent as PlayButton } from "../../assets/icons/play-button.svg";
import SwotknotLogo from "../../assets/images/learn-logo-colour.svg";
import owl from "../../assets/images/owl.jpg";
import { ReactComponent as Eye } from "../../assets/icons/eye.svg"; // For show password
import { ReactComponent as Hidden } from "../../assets/icons/invisible.svg"; // For hide password

const SetNewPassword = (props) => {
  const navigate = useNavigate();
  // const urlParams = new URLSearchParams(window.location.search);
  let [urlParams, _] = useSearchParams();
  const oobCode = urlParams.get("oobCode");
  const authMode = urlParams.get("mode");
  console.log("authMode:", authMode, " oobCode:", oobCode);

  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [passwordValidationError, setPasswordValidationError] = useState(null);

  // For show/hide password
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [confPasswordShown, setConfPasswordShown] = useState(false);
  const togglePasswordVisibility = (type) => {
    if (type === "new") {
      setNewPasswordShown(newPasswordShown ? false : true);
    } else {
      setConfPasswordShown(confPasswordShown ? false : true);
    }
  };

  const [passwordError, setPasswordError] = useState(false);
  const [passwordOk, setPasswordOk] = useState(false);

  const confirmPassword = () => {
    if (password.length < 8) {
      setPasswordValidationError(
        "Password needs to be at least 8 characters long."
      );
      setPasswordError(true);
      return;
    } else if (
      !/^(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=((.*[\W])|(.*_)){1,}).{8,}$/.test(
        password
      )
    ) {
      setPasswordValidationError(
        "Password must have at least one letter, one number and one special character"
      );
      setPasswordError(true);
      return;
    } else if (confirmedPassword !== password) {
      setPasswordValidationError(
        "ConfirmPassword & NewPasswords are NOT the same!"
      );
      setPasswordError(true);
      return;
    } else {
      setPasswordValidationError(null);
      setPasswordError(false);
      setPasswordOk(true);
      return;
    }
  };

  const renderPassBorderColor = () => {
    if (
      (confirmedPassword && passwordError) ||
      (password && passwordValidationError)
    ) {
      return commonColor.error;
    } else {
      return "#e2e8f0";
    }
  };

  const buttonStateColor = () => {
    const btnColor = passwordOk ? commonColor.deepBlue : commonColor.disable;
    return btnColor;
  };

  const setFirebasePassword = async(event) => {
    event && event.preventDefault();
    await confirmPasswordReset(auth,oobCode, password)
      .then(function () {
        // Success
        navigate("/sign-in");
      })
      .catch(function (err) {
        console.log(err.message);
        setPasswordValidationError(err.message);
        setPasswordError(true);
      });
  };

  return (
    <>
      <div className="w-full h-screen overflow-y-scroll">
        <div className="xl:w-1/3 lg:w-1/2 w-full pt-8 lg:float-left h-1/2 lg:h-full items-center justify-center flex xl:bg-white lg:bg-transparent ">
          <div className="bg-white/75 xl:bg-white/100 rounded-lg w-10/12 md:w-9/12 sm:w-10/12">
            <div>
              <div
                style={{
                  gridArea: `1 / 1 / 2 / 25`,
                  backgroundColor: "transparent",
                }}
              >
                <div className=" py-3 flex justify-center items-center lg:h-1/6 h-1/10 bg-transparent">
                  <img
                    src={SwotknotLogo}
                    alt="IQ2Logo"
                    className="w-40 pt-2 bg-transparent"
                  />
                </div>
                <div className="pt-10 text-center font-semibold">
                  Reset your password
                </div>
              </div>
            </div>

            <div className=" py-4 ">
              {
                <div
                  className="py-4 w-full text-center mb-3"
                  style={{
                    color: "#e53e3e",
                  }}
                >
                  {passwordValidationError}
                </div>
              }

              <form onSubmit={setFirebasePassword}>
                <div className="w-full ">
                  <div>
                    <div className="relative">
                      <input
                        className="my-1 py-2 w-full white px-4 text-gray-800  rounded-md placeholder-gray-800 relative"
                        style={{
                          borderColor: renderPassBorderColor(),
                          backgroundColor: commonColor.babyBlue,
                        }}
                        id="password"
                        name="password"
                        type={newPasswordShown ? "text" : "password"} // Show/hide pass
                        placeholder={"New Password"}
                        onBlur={confirmPassword}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div className="self-start bg-transparent justify-center flex">
                        <button
                          className="px-2 py-3 focus:outline-none  absolute left-full top-0"
                          onClick={(event) => {
                            event.preventDefault();
                            togglePasswordVisibility("new");
                          }}
                        >
                          {newPasswordShown ? (
                            <Eye
                              className="rounded-full h-6 w-6 hover:bg-gray-200 bg-transparent"
                              style={{
                                fill: commonColor.deepBlue,
                              }}
                            />
                          ) : (
                            <Hidden
                              className="rounded-full h-6 w-6 hover:bg-gray-200 bg-transparent"
                              style={{
                                fill: commonColor.deepBlue,
                              }}
                            />
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="relative">
                      <input
                        className="my-1 py-2 w-full white px-4 text-gray-800  rounded-md placeholder-gray-800 relative "
                        style={{
                          borderColor: renderPassBorderColor(),
                          backgroundColor: commonColor.babyBlue,
                        }}
                        id="confirmPassword"
                        name="confirmPassword"
                        type={confPasswordShown ? "text" : "password"} // Show/hide pass
                        placeholder={"Confirm Password"}
                        onBlur={confirmPassword}
                        value={confirmedPassword}
                        onChange={(e) => setConfirmedPassword(e.target.value)}
                      />
                      <div className="self-end bg-transparent justify-center flex">
                        <button
                          className="px-2 py-3 focus:outline-none absolute left-full top-0"
                          onClick={(event) => {
                            event.preventDefault();
                            togglePasswordVisibility("conf");
                          }}
                        >
                          {confPasswordShown ? (
                            <Eye
                              className="rounded-full h-6 w-6 hover:bg-gray-200 bg-transparent"
                              style={{
                                fill: commonColor.deepBlue,
                              }}
                            />
                          ) : (
                            <Hidden
                              className="rounded-full h-6 w-6 hover:bg-gray-200 bg-transparent"
                              style={{
                                fill: commonColor.deepBlue,
                              }}
                            />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="self-center bg-transparent justify-center flex">
                    <button
                      className="bg-[#A93823] hover:bg-[#801b17] cursor-pointer  text-white py-2 px-4 rounded-md w-[9rem] mt-10"
                      type="submit"
                      disabled={!passwordOk}
                    >
                      Set Password
                    </button>
                  </div>
                </div>
              </form>
              <div className="pt-10 text-center">
                Suddenly remembered?{" "}
                <Link
                  to="/sign-in"
                  className="text-blue-500 hover:text-blue-600"
                >
                  Log in now!
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="h-1/2 lg:h-full xl:w-2/3 lg:w-1/2 w-full  lg:float-right">
          <div
            className="h-full bg-sea flex flex-col justify-around"
            style={{
              boxShadow: `0 10px 25px 0 rgba(0, 0, 0, 0.6)`,
              borderRadius: 25,
            }}
          >
            <div className="flex h-full justify-center items-end">
              <div className="w-full h-full">
                <img
                  className="object-cover w-full h-full"
                  src={owl}
                  alt="owl_img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SetNewPassword;
