import { useNavigate } from "react-router-dom";
import Swotbutton from "../../components/common/buttons/swotbutton";
import SwotknotLogo from "../../assets/images/swotknot_white.png";

const AmbassadorNavBar = () => {
  let navigate = useNavigate();

  return (
    <div className="w-full flex flex-row menu_shadow justify-between bg-[#4D5171] items-center h-20 mb-8 px-6">
      <img src={SwotknotLogo} alt="SwotLogo" className="h-12 w-auto" />
      <b style={{ color: "white" }}>{localStorage.getItem("ambassador_email")}</b>

      <Swotbutton
        text="Home"
        onClick={() => {
          navigate("/userUpload");
        }}
      />

      <Swotbutton
        text="Logout"
        onClick={() => {
          localStorage.setItem("ambassador_email", "");
          localStorage.setItem("token", "");
          navigate("/ambassador");
        }}
      />


    </div >
  );
};

export default AmbassadorNavBar;
