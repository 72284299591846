import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MainPageWithMenu from "../../components/common/MainPageTemplate/mainPageWithMenu";
import RefundsDashboard from "../../components/admin/refund/refundsDashboard";
import { useNavigate } from "react-router-dom";

const RefundPage = ({ user }) => {
  console.log("Refunds", user);

  let navigate = useNavigate();
  useEffect(() => {
    if (user.accountType != "SwotAdmin") {
      navigate("/dashboard");
    }
  }, []);


  return (
    <MainPageWithMenu user={user} menuSelected={"refunds"}>
      <RefundsDashboard />
    </MainPageWithMenu>
  );
};
const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RefundPage);
