import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { ReduxState } from "../../redux/redux-models/redux_state";
import UserActions from "../../redux/user";
import UserModel from "../../redux/redux-models/reduxUser";
import { ACCOUNT_TYPES } from "../../config/constants";
import { ChatService, IChatService } from "../services/chatService";
import { IChatGroup } from "../models/chat_models";
import ChatListEntryComponent from "./ChatListEntryComponent";

interface Props extends PropsFromRedux {}

class ChatListComponent extends React.Component<{
user: UserModel;
chatService: IChatService;
chatGroups: IChatGroup[];
activeChatId?: string;
openChat: (chatGroup: IChatGroup) => void;
// onPlay: (content: IContentListEntry) => void;
// onEdit: (content: IContentListEntry) => void;
}> {
  constructor(props: { 
    user: UserModel; 
    chatService: IChatService;
    chatGroups: IChatGroup[];
    activeChatId?: string;
    openChat: (chatGroup: IChatGroup) => void;
    // onPlay: (content: IContentListEntry) => void;
    // onEdit: (content: IContentListEntry) => void;
  }) {
    super(props);

    this.state = {
      chatGroups: props.chatGroups,
      isLoading: false,
      isDataUpdating: true,
    };

    this.chatService = props.chatService;
  }


  public state: {
    chatGroups: IChatGroup[];
    isLoading: boolean;
    isDataUpdating: boolean;
  };

  protected chatService: IChatService;


  public async componentDidMount(): Promise<void> {}

  render() {
    return <div className="flex flex-col">
      {this.props.chatGroups.map((g) => (
         <ChatListEntryComponent 
         user={this.props.user}
         key={g.id}
         data={g}
         chatService={this.chatService}
         activeChatId={this.props.activeChatId}
         onClick={() => {
          this.props.openChat(g);
         }}
         />
      ))}
    </div>;
  }
}

function mapStateToProps(state: ReduxState): { user: UserModel } {
  return {
    user: state.user.user,
  };
}
const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

// export default connector(ChatListComponent);
export default ChatListComponent;
