import { toast } from "react-toastify";
import { stripeConnect, getStripeStatus, courseCheckout } from "./api";

const stripeStatusMessages = {
  none: {
    type: toast.TYPE.ERROR,
    msg: "You are not yet registered with Stripe",
  },
  pending: {
    type: toast.TYPE.INFO,
    msg: "Your registration with Stripe is pending",
  },
  inactive: {
    type: toast.TYPE.WARNING,
    msg: "Some information is missing in your stripe account. Please update to make stripe active.",
  },
  incomplete: {
    type: toast.TYPE.INFO,
    msg: "Your registration with Stripe is pending.",
  },
  approved: {
    type: toast.TYPE.SUCCESS,
    msg: "you are registered with stripe",
  },
};

export const stripeStatusCheck = (status) => {
  return stripeStatusMessages[status];
};

export const getTutorStripeStatus = async (firebaseId) => {
  return await getStripeStatus(firebaseId);
};

export const stripeRegisterAccount = async (firebaseId, newTab, country) => {
  // const res1 = await getStripeStatus(firebaseId);
  // console.log(res1);
  const res = await stripeConnect(firebaseId, country);
  if (res.data?.status && res.data.status === "SUCCESS") {
    //console.log(res.data);
    try {
      const {
        data: { url },
      } = res.data;

      if (url) {
        window.open(url, newTab ? "_blank" : "_self", "noopener,noreferrer");
      }

      return Promise.resolve("SUCCESS");
    } catch (e) {
      console.log("stripeRegisterAccount error", e);

      return Promise.reject(e);
    }
  }
};

export const coursePayment = async (courseId, studentId, guid, booking) => {
  try {
    console.log("coursePayment API call");
    const baseUrl = window.location.origin;
    const succesUrl = baseUrl + "/courses/" + courseId + "?guid=" + guid + "&payment=success";
    const cancelUrl = baseUrl + "/courses/" + courseId + "?guid=" + guid + "&payment=cancel";
    const payload = {
      success_url: succesUrl,
      cancel_url: cancelUrl,
      booking: booking
      // success_url:
      //   baseUrl + "/stripeCheckoutSuccess",
      //   cancel_url: baseUrl + "/stripeCheckoutFailure",
    };
    return courseCheckout(courseId, studentId, payload);
  } catch (e) {
    console.log("Error in coursePayment API call", e);
  }
};



//----------------Helper functions to save/remove the bookingPaylod in localStorage, to be used during payment flow ---------//

export const getSavedBookingPayload = (userId, guid) => {
  if (userId && guid && localStorage.getItem(userId)) {
    const savedData = JSON.parse(localStorage.getItem(userId));
    if (savedData) {
      return savedData[guid];
    }
  }
  return null;
};

export const saveBookingPayload = (userId, guid, payload) => {
  if (userId && guid && payload) {
    if (localStorage.getItem(userId)) {
      const savedData = JSON.parse(localStorage.getItem(userId));
      savedData[guid] = payload;
      localStorage.setItem(userId, JSON.stringify(savedData));
    } else {
      localStorage.setItem(userId, JSON.stringify({ [guid]: payload }));
    }
  }
};

export const removeSavedBookingPayload = (userId, guid) => {
  if (userId && guid && localStorage.getItem(userId)) {
    const savedData = JSON.parse(localStorage.getItem(userId));
    if (savedData && savedData[guid]) {
      delete savedData[guid];
      localStorage.setItem(userId, JSON.stringify(savedData));
    }
  }
};
