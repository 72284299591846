import React, { useState, useEffect } from "react";
import { momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../common/calendar/calendar.css";
import CourseListHorizontal from "../../courses/courseListHorizontal";
import InterestingFacts from "../../courses/interestingList";
import Reminders from "../../courses/reminders";
import TutorCalendar from "../calendar";
import StudentList from "../studentList";
import { connect } from "react-redux";
import { useWindowSize } from "../../../utils/uiUtils";
import TimeSlot from "../../calendar/timeSlot";
import GutterHeader from "../../calendar/gutterHeader";
import CustomToolbar from "../../calendar/customToolbar";
import { getTutorStripeStatus } from "../../../services/paymentsManagement";
import FactOfTheDay from "./factOfTheDay";
import FooterView from "../../common/footerView";
import { tourStepsForTutor } from "../../common/tourOverlay/tourSteps";
import UserActions from "../../../redux/user";
import { useTour } from "@reactour/tour";
import { updateUser } from "../../../services/userManagement";

const localizer = momentLocalizer(moment);

const TutorDashboard = ({ user }) => {
  const [studentsTotal, setStudentsTotal] = useState(0);
  const [monthlyHoursTotal, setMonthlyHoursTotal] = useState(0);
  const [coursesTotal, setCoursesTotal] = useState(0);
  // const [factOfDay, setFactOfDay] = useState();
  const [width, height] = useWindowSize();
  const { setIsOpen, setSteps } = useTour();

  useEffect(() => {
    console.log("screen size changed", width, height);
  }, [width, height]);

  useEffect(() => {
    if(user.uid && !user.tourPopoverShown) {
      setSteps(tourStepsForTutor)
      setIsOpen(true)
      updateUser({
        ...user,
        uid: user.uid,
        tourPopoverShown: true},true);
    }
  }, [user.uid]);

  useEffect(() => {
    // getFactofTheDay();

    const checkStatus = async () => {
      console.log(
        "StripeCreatedSuccess -  useEffect - checking stripe status",
        user
      );
      try {
        const result = await getTutorStripeStatus(user.uid);
        if (result.data.status === "ERROR") {
          console.error(
            "Dashboard -  useEffect - checking stripe status Error",
            result
          );
        } else {
          console.log(
            "Dashboard -  useEffect - checking stripe status SUCCESS",
            result
          );
        }
      } catch (e) {
        console.error(
          "Dashboard -  useEffect - checking stripe status Error",
          user,
          e
        );
      }
    };
    checkStatus();
  }, []);

  // const getFactofTheDay = async () => {
  //   const response = await contentful.getEntries({
  //     content_type: "fact",
  //   });
  //   console.log("Swotnews - get factOfTheDay", response);
  //   if (response?.items?.length > 0) {
  //     console.log("User Role ::", user?.accountType.toLowerCase());
  //     const r = response.items[0].fields;
  //     const title = r?.heading;
  //     const message = r?.text;
  //     const image = r?.coverImage?.fields?.file?.url;
  //     setFactOfDay({ title: title, message: message, image: image });
  //   }
  // };

  // section widgets:----------------------------------------------------
  const calendarWidget = () => {
    return (
      <WraperWithTitle title={"Today's Schedule"} screenWidth={width}>
        <TutorCalendar
          user={user}
          view={"day"}
          views={["day"]}
          setMonthlyHoursTotal={setMonthlyHoursTotal}
          calendarClassName="sk-calendar"
          components={{
            timeSlotWrapper: TimeSlot,
            timeGutterHeader: () => (
              <GutterHeader labels={[{ title: "Time" }]} />
            ),
            day: {
              header: () => (
                <div className="pl-2">
                  <GutterHeader
                    labels={[{ title: "Event", colorStyle: "tertiary" }]}
                  />
                </div>
              ),
            },
            toolbar: CustomToolbar,
          }}
        />
      </WraperWithTitle>
    );
  };

  const courseListWidget = () => {
    return (
      <WraperWithTitle title={"Courses"}  screenWidth={width} contentClass={"bg-white min-h-56"}>
        <CourseListHorizontal setCoursesTotal={setCoursesTotal} />
      </WraperWithTitle>
    );
  };

  const intrestingFactWidget = () => {
    return (
      <WraperWithTitle  screenWidth={width} title={"My statistics"}>
        <InterestingFacts
          studentsTotal={studentsTotal}
          coursesTotal={coursesTotal}
          monthlyHoursTotal={monthlyHoursTotal}
        />
      </WraperWithTitle>
    );
  };

  const didYouKnowWidget = () => {
    return (
      <WraperWithTitle
        title={"Did you know?"}
        screenWidth={width}
        contentClass={"bg-white md:flex-grow h-60 lg:h-full"}
      >
        <FactOfTheDay />
      </WraperWithTitle>
    );
  };

  const myStudentsWidget = () => {
    return (
      <WraperWithTitle title={"My students"}  screenWidth={width} contentClass={""}>
        <StudentList setStudentsTotal={setStudentsTotal} />
      </WraperWithTitle>
    );
  };

  const reminderWidget = () => {
    return (
      <WraperWithTitle title={"Reminders"}  screenWidth={width} contentClass={""}>
        <Reminders />
      </WraperWithTitle>
    );
  };

  // main body:---------------------------------------------------------------
  return (
    <div className="w-full flex flex-col justify-start overflow-h-scroll max-w-3k">
      <div className="w-full flex flex-col justify-start">
        <div
          className="w-full lg:h-40r rounded-lg flex flex-col justify-start lg:flex-row flex-wrap"
          style={{}}
        >
          <div className="w-full lg:w-3/5 xl:w-7/10 lg:h-full">
            <div className="w-full lg:h-1/2">{courseListWidget()}</div>

            <div className="w-full md:h-72 lg:h-1/2 flex flex-row flex-wrap">
              <div className="flex-grow md:w-1/2 lg:w-3/5 xl:w-7/10 lg:h-full">
                {didYouKnowWidget()}
              </div>

              <div className="w-full md:w-1/2 lg:w-2/5 xl:w-3/10 lg:h-full">
                {intrestingFactWidget()}
              </div>
            </div>
          </div>

          <div className="w-full hidden lg:block lg:w-2/5 xl:w-3/10 h-full">
            {calendarWidget()}
          </div>
        </div>

        <div
          className="w-full lg:h-80 xl:96 rounded-lg flex justify-startflex-row flex-wrap"
          style={{}}
        >
          <div className="w-full lg:w-1/2 lg:h-full min-h-40">
            {myStudentsWidget()}
          </div>

          <div className="w-full lg:w-1/2 lg:h-full min-h-40">
            {reminderWidget()}
          </div>
        </div>

        <div className="w-full lg:hidden">{calendarWidget()}</div>

        <FooterView />

      </div>
    </div>
  );
};

// helper widgets:----------------------------------------------------
const WraperWithTitle = ({
  title,
  screenWidth,
  contentClass = "bg-white",
  children,
}) => {
  return (
    <div className="w-full h-full flex flex-col justify-start p-2 lg:mb-0">
      <div className="w-full text-white pb-2 px-2 h-10">{title}</div>
      <div
        className={`darker_shadow  w-full overflow-hidden ${contentClass}`}
        style={{
          ...{ borderRadius: "1.5rem" },
          ...{
            ...(screenWidth >= 1024 ? { height: `calc(100% - 2.5rem)` } : {}),
          },
        }}
      >
        {children}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TutorDashboard);
