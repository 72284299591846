import React, { CSSProperties, ReactNode } from "react";
import classNames from "classnames";
import commonColor from "../../../theme/commonColor";
import LoaderButton from "./loaderbutton";
import { Link } from "react-router-dom";

const Swotbutton = ({
  text = "New Button",
  onClick = undefined,
  disabled = false,
  bgColor = commonColor.deepPink,
  style,
  className,
  to = "",
  children,
  shadow = false,
  loading = false,
}: {
  text?: string;
  onClick?: Function;
  disabled?: boolean;
  bgColor?: string;
  style?: CSSProperties;
  className?: string;
  to?: string;
  children?: unknown;
  shadow?: boolean;
  loading?: boolean;
}) => {
  const linkClassNames = classNames(
    "shadow-lg py-2 px-4 text-xs hover:shadow-outline focus:outline-none text-center inline-block min-w-24 sm:min-w-1/2 md:min-w-2/5 lg:min-w-2/5 xl:min-w-1/4",
    {
      "opacity-25": disabled,
    }
  );

  const styles = {
    borderRadius: "1.5rem",
    color: "white",
    backgroundColor: bgColor,
    // minWidth: "8rem",
  };

  return loading ? (
    <LoaderButton
      bgColor={bgColor}
      style={style}
      className={className}
      shadow={shadow}
    />
  ) : to ? (
    <Link
      className={linkClassNames}
      style={{ ...styles, ...(style ?? {}) }}
      to={to}
    >
      { text ? text : children && children as ReactNode ? children as ReactNode : ""}
      
      {/* {text || children} */}
    </Link>
  ) : (
    <button
      className={`min-w-24 shadow-lg md:py-2 md:px-4 my-2 text-sm xl:text-md py-2 hover:shadow-outline px-2  rounded-md
      focus:outline-none ${disabled && "opacity-25"} ${
        className ? className : ""
      }`}
      style={{
        ...{
          borderRadius: "0.375remrem",
          color: "white",
          backgroundColor: bgColor,
          boxShadow: shadow
            ? `2px 2px 5px 1px rgba(0, 0, 0, 0.3)`
            : "0px 5px 5px 0px rgba(92, 92, 92, 0.137)",
        },
        ...{ ...(style ?? {}) },
      }}
      onClick={() => onClick && onClick()}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default Swotbutton;
