import React, { useEffect, useState } from "react";
import { ACCOUNT_TYPES } from "../../../config/constants";
import {
  getAllUsers,
  deleteUserRequest,
  setTutorApprovalStatus,
} from "../../../services/api";
import commonColor from "../../../theme/commonColor";
import LoaderModal from "../../common/modal/loaderModal";
import UserListItem from "./UserListItem";
import { ReactComponent as SearchGlass } from "../../../assets/icons/001-search.svg";

import "./usermanagement.css";
import FooterView from "../../common/footerView";
import Select from "../../common/input/select";
import { createCSV } from "../../../utils/csvGenerator";
import SwotButton from "../../common/buttons/swotbutton";


const UserManagementDashboard = () => {
  const [usersList, setUsersList] = useState([]);
  const [tutorList, setTutorList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [pendingTutorList, setPendingTutorList] = useState([]);
  const [expandedUser, setExpandedUser] = useState(null);
  const [searchText, setSearchText] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [tutorStatus, setTutorStatus] = useState(null);
  const [exportList, setExportList] = useState([]);
  const [page, setPageNumber] = useState(1);
  const tutorStatusOps = [
    { value: true, label: "APPROVED" },
    { value: false, label: "REJECTED" },
    { value: null, label: "UNDER REVIEW" },
    { value: "pending", label: "PENDING" }
  ];

  useEffect(() => {
    getUsers();
  }, []);

  const handleScroll = (event) => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
    if (bottom && !isLoading) {
      getUsers();
    }
  };

  // useEffect(() => {
  //   console.log("Page Effect");
  //   if (page > 1) {
  //     getUsers();
  //     console.log("Fetching users for page", page);
  //   }
  // }, [page]);

  useEffect(() => {
    if (usersList) {
      const searchString = (searchText ?? "").toLowerCase().trim();
      if (searchString === "") {
        setFilteredList(usersList);
        return;
      }

      const result = usersList.filter((item) => {
        return ((item.first_name ?? "").toLowerCase().includes(searchString) ||
          (item.last_name ?? "").toLowerCase().includes(searchString) ||
          (item.email ?? "").toLowerCase().includes(searchString) ||
          (item.ngo_user && "ngo".includes(searchString)) ||
          (item.accountType === ACCOUNT_TYPES.TUTOR && "tutor".includes(searchString)) ||
          (item.accountType === ACCOUNT_TYPES.ADMIN && "admin".includes(searchString)) ||
          (item.accountType === ACCOUNT_TYPES.STUDENT && "student".includes(searchString)));
      });
      setFilteredList(result);
    }
  }, [usersList, searchText]);

  const generateExportData = (userlist) => {

    let list = [];
    for (let i in userlist) {
      let tmp = {
        firebase_id: userlist[i].firebase_id,
        email: userlist[i].email,
        username: userlist[i].username,
        name: userlist[i].last_name + " " + userlist[i].first_name,
        type: (userlist[i].account_type_id == 2 ? "tutor" : "student"),
        city_name: userlist[i].city_name,
        tutor_status: ""
      }

      if (userlist[i].account_type_id == 2) {
        if (userlist[i].tutor_approval == true) tmp.tutor_status = "APPROVED";
        if (userlist[i].tutor_approval == false) tmp.tutor_status = "REJECTED";
        if (userlist[i].tutor_approval == null) tmp.tutor_status = "UNDER REVIEW";
        if (userlist[i].tutor_approval == "pending") tmp.tutor_status = "PENDING";
      }

      list.push(tmp)
    }

    setExportList(list);

  }
  console.log("page", page);


  const getUsers = async () => {
    if (isLoading || usersList.length % 20 !== 0) {
      // If already loading or all users are loaded, return
      return;
    }

    setIsLoading(true);
    const response = await getAllUsers(20, page);
    console.log("response with page " + response)
    console.log("Fetching users with page", page);
    setIsLoading(false);

    if (
      response &&
      response.ok &&
      response.data?.status === "SUCCESS" &&
      response.data?.data
    ) {
      const fetchedUsers = response.data.data;
      generateExportData(fetchedUsers);

      const mappedUsers = fetchedUsers.map((user) => ({
        ...user,
        uid: user.firebase_id,
        accountType:
          user.account_type_id === 2
            ? ACCOUNT_TYPES.TUTOR
            : user.account_type_id === 5
              ? ACCOUNT_TYPES.ADMIN
              : ACCOUNT_TYPES.STUDENT,
      }));

      // Filter admins and tutors
      const filteredUsers = mappedUsers.filter(
        (user) => user.accountType !== ACCOUNT_TYPES.ADMIN
      );

      // Separate tutors and students
      const filteredTutors = filteredUsers.filter(
        (user) => user.accountType !== ACCOUNT_TYPES.TUTOR
      );
      const filteredNotApprovedTutors = filteredTutors.filter(
        (user) => user.status === "pending"
      );

      // Sort users
      const sortedUsers = filteredUsers.sort((a, b) => a.status === "pending");

      // Update state
      setPendingTutorList(filteredNotApprovedTutors);
      setTutorList(filteredTutors);
      setUsersList((prevUsers) => [...prevUsers, ...sortedUsers]);
      setPageNumber((prevPage) => prevPage + 1);
    }
  };


  const deleteUser = async (firebaseId) => {
    if (!firebaseId || isLoading) {
      return;
    }
    setIsLoading(true);
    const response = await deleteUserRequest(firebaseId);
    setIsLoading(false);
    if (response && response.ok && response.data?.status === "SUCCESS") {
      getUsers();
    } else {
      //TODO: show error message
    }
  };

  const handleSearchTermChange = (searchTerm) => {
    setSearchText((searchTerm ?? "").trim());
  };

  const loadMoreUsers = () => {
    getUsers();
  };

  const searchWidget = () => {
    return (

      <div className="bg-white rounded-2xl shadow-2xl w-full pt-2 lg:h-full lg:pt-0 flex flex-col"
        style={{
          maxWidth: "60rem"
        }}>


        <div className="w-full p-2 flex">

          <div
            className=" w-full flex justify-between"
            style={{
              height: "3rem",
              backgroundColor: "rgba(64, 114, 136, 0.35)",
              borderRadius: "0.7rem",
              //zIndex: 1002,
            }}
          >
            <input
              className=" h-full appearance-none focus:outline-none py-1 px-2"
              style={{
                //color: "white",
                backgroundColor: "transparent",
                zIndex: 1002,
                width: "87%",
                border: 0,
              }}
              id="search"
              name="search"
              type="text"
              placeholder={"Search"}
              //onBlur={() => setIsSearchActive(false)}
              // value={""}
              onChange={(event) => {
                setTutorStatus(null);
                handleSearchTermChange(event.target.value);
              }}
            />
            <button
              className="rounded-xl p-2 focus:outline-none flex justify-center items-center"
              style={{
                backgroundColor: commonColor.deepGreen,

                zIndex: 1010,
                aspectRatio: "1 / 1",
              }}
            >
              <SearchGlass
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  overflow: "hidden",
                  fill: "white",
                }}
              />
            </button>
          </div>
          <div className="w-1/2 flex flex-row justify-start px-2">
            <Select
              className=" h-full w-full appearance-none focus:outline-none py-1 px-2"
              style={{
                //color: "white",
                backgroundColor: "transparent",
                zIndex: 1002,
                border: 0,
              }}
              value={tutorStatus}
              placeholder={"Tutor Status"}
              closeMenuOnSelect={true}
              onChange={(e) => {
                setTutorStatus(e);
                console.log(e.value);
                const result = usersList.filter((item) => {
                  return (item.accountType === ACCOUNT_TYPES.TUTOR && item.tutor_approval == e.value)
                });
                setFilteredList(result);
              }}
              //isMulti
              options={tutorStatusOps}
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div
      className="w-full rounded-2xl p-4 h-full
    flex flex-col "
    >
      <LoaderModal isOpen={isLoading} />

      <div className="search-section w-full flex flex-col">
        <div className="w-full flex justify-between items-middle">
          <div className="text-white">Admin Dashboard</div>
          <SwotButton
            text={"Download User Data"}
            onClick={() => {
              createCSV(exportList, "all_users_data");
            }}
          />
          <button
            className="border-white p-4 border-2 text-white rounded-2xl focus:outline-none"
            onClick={() => {
              getUsers();
            }}
          >
            Refresh
          </button>
        </div>

        <div className="w-full p-4 flex flex-col">
          <div className="text-white">User Management</div>


          {searchWidget()}
        </div>
      </div>

      <div className="w-full list-section p-4 flex flex-col overflow-y-scroll" onScroll={handleScroll}>
        {/* <div className="text-white">User Management</div> */}

        {/* {searchWidget()} */}
        {/* <div className="h-9/10 overflow-y-scroll"> */}
        {/* <div className="overflow-y-scroll"> */}
        {filteredList.map((user, index) => (
          <UserListItem
            key={user.firebase_id ?? index}
            user={user}
            setExpandedUser={setExpandedUser}
            expandedUserId={expandedUser}
            deleteUser={deleteUser}
          />
        ))}
        {isLoading && <p>Loading...</p>}
      </div>
      <FooterView />
    </div>
  );
};

export default UserManagementDashboard;
