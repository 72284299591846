import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFingerprint,
  faBookOpen,
  faWindowClose,
  faSave,
  faCheck,
  faPlay,
  faPencilAlt,
  faFileDownload,
  faTrashAlt,
  faCopyright,
} from "@fortawesome/free-solid-svg-icons";

import { H5PEditorUI, H5PPlayerUI } from "@lumieducation/h5p-react";

import { ContentService, IContentListEntry, IContentService } from "../services/ContentService";
import "./ContentListEntryComponent.css";
import Select from "../../components/common/input/select";
import { divide } from "lodash";
import Modal, { QuickModal } from "../../components/common/modal/modal";
import Swotbutton from "../../components/common/buttons/swotbutton";
import commonColor from "../../theme/commonColor";
import Swal from "sweetalert2";
import ContentCourseListComponent from "./ContentCourseListComponent";

export default class ContentViewComponent extends React.Component<{
  data: IContentListEntry;
}> {
  constructor(props: {
    data: IContentListEntry;
  }) {
    super(props);
    this.state = {
      editing: false,
      playing: true,
      saving: false,
      saved: false,
      loading: true,
      saveErrorMessage: "",
      saveError: false,
      showingCustomCopyright: false,
      showingCoursePopup: false,
    };
    this.h5pPlayer = React.createRef();
    this.contentService = new ContentService("/h5p");
  }

  public state: {
    editing: boolean;
    loading: boolean;
    playing: boolean;
    saved: boolean;
    saving: boolean;
    saveError: boolean;
    saveErrorMessage: string;
    showingCustomCopyright: boolean;
    showingCoursePopup: boolean;
  };

  private contentService: IContentService;

  private h5pPlayer: React.RefObject<H5PPlayerUI>;

  public render(): React.ReactNode {
    const options = [
      { value: "Copyright", label: "Copyright" },
      { value: "Show in custom dialog", label: "Show in custom dialog" },
      {
        value: "Show in native H5P dialog",
        label: "Show in native H5P dialog",
      },
    ];

    console.log("Main H5p Data:",this.props.data);

    return (
      <div className="w-full h-full" key={this.props.data.originalNewKey ?? this.props.data.contentId}>
        <div className="w-full">
          <div className="w-full flex justify-between">
            <div className="p-2">
              <h3>{this.props.data.title}</h3>
              <div className="small text-xs">
                <div className="me-2">
                  <FontAwesomeIcon icon={faBookOpen} className="me-1" />
                  {this.props.data.mainLibrary}
                </div>
                <div className="me-2">
                  <FontAwesomeIcon icon={faFingerprint} className="me-1" />
                  {this.props.data.contentId}
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.playing ? (
          <div className={`${this.state.loading ? "loading" : ""} overflow-y-scroll`}>
            <H5PPlayerUI
              ref={this.h5pPlayer}
              contentId={this.props.data.contentId}
              loadContentCallback={this.contentService.getPlay}
              onInitialized={this.onPlayerInitialized}
              onxAPIStatement={(statement: any, context: any, event) =>
                console.log(statement, context, event)
              }
            />
            <div
              style={{
                visibility: this.state.loading ? "visible" : "collapse",
              }}
              className="spinner-border spinner-border-sm m-2"
              role="status"
            ></div>
          </div>
        ) : undefined}

        <QuickModal
          isOpen={this.state.showingCustomCopyright}
          onRequestClose={() => {
            this.setState({ showingCustomCopyright: false });
          }}
          title={"Copyright information"}
          isCloseButtonShown={true}
          firstButtonTitle={"Close"}
        >
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  this.h5pPlayer.current?.getCopyrightHtml() ??
                  "No copyright information",
              }}
            ></div>
          </div>

          <div>
            <button 
              onClick={() => {
                this.closeCopyrightCustom();
              }}
            >
              Close
            </button>
          </div>
        </QuickModal>
      </div>
    );
  }

  protected play() {
    this.setState({ editing: false, playing: true });
  }

  protected close() {
    this.setState({ editing: false, playing: false });
  }

  protected showCopyrightCustom() {
    this.setState({ showingCustomCopyright: true });
  }

  protected closeCopyrightCustom() {
    this.setState({ showingCustomCopyright: false });
  }

  protected showCopyrightNative() {
    if(this.h5pPlayer.current)
     this.h5pPlayer.current.showCopyright();
  }

  private onPlayerInitialized = () => {
    this.setState({ loading: false });
  };

  protected onEditorLoaded = () => {
    this.setState({ loading: false });
  };
}
