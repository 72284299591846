import { useEffect, useState } from "react";
import { connect } from "react-redux";
import UserActions from "../../../redux/user";
import commonColor from "../../../theme/commonColor";
import { ReactComponent as PlayButton } from "../../../assets/icons/play-button.svg";
import LearnLogo from "../../../assets/images/learn-logo-colour.svg";
import { ReactComponent as Eye } from "../../../assets/icons/eye.svg";
import { ReactComponent as Hidden } from "../../../assets/icons/invisible.svg";
import { useNavigate } from "react-router-dom";
import { ambassadorLogin } from "../../../services/api";
import LoaderModal from "../../../components/common/modal/loaderModal";

// const SignInAmbassador = (props: { saveUser: any; }) => {
const SignInAmbassador = () => {
  let navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [error, setError] = useState("");

  const [isValid, setIsValid] = useState(false);
  const [emailOk, setEmailOk] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/userUpload");
    }
  }, []);

  useEffect(() => {
    console.log("useEffect - login error:", error);
    //auth.signOut()
  }, [error]);

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(value);
      isEmailValid();
    } else if (name === "userPassword") {
      setPassword(value);
      isPasswordValid();
    }
    setIsValid(validate() ?? false);
  };

  const validate = () => {
    return isEmailValid() && isPasswordValid();
  };

  //---------Email validations------------//
  const isEmailValid = () => {
    if (!email || email.length === null || email.length === undefined) {
      return null;
    }

    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    return pattern.test(email);
  };

  const validateEmail = () => {
    var validation = isEmailValid();
    setEmailOk(false);
    console.log("validateEmail -  email", email);
    if (!validation) {
      // setEmailError(false);
    }
  };

  const renderEmailBorderColor = () => {
    const validation = isEmailValid();
    if (validation === false) {
      return commonColor.error;
    } else if (validation === true) {
      return commonColor.correct;
    } else {
      return "#e2e8f0";
    }
  };

  const isPasswordValid = () => {
    if (password.trim().length < 6) {
      return false;
    }
    return true;
  };

  const login = async () => {
    try {
      console.log("logging....");

      if (email == "") {
        setError("Please enter your email");
        return;
      } else if (password == "") {
        setError("Please enter your password");
        return;
      }

      setIsLoading(true);

      let response = await ambassadorLogin(JSON.stringify({ email, password }));

      setIsLoading(false);

      if (response?.ok && response.data && response.data.token) {
        setError("");
        // Save the token to local storage or state, e.g., setToken(data.token);
        console.log("Login successful", response.data);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("ambassador_email", email);
        navigate("/userUpload");
      } else {
        setError("Invalid Username or Password");
        console.log("Login failed");
      }
    } catch (error) {
      console.error("Error occurred during login:", error);
    }
  };

  return (
    <div className="w-full h-screen overflow-y-scrol h-screen-ios min-h-screen-ios">
      <div className="w-full pt-8 lg:float-left h-1/2 lg:h-full items-center justify-center flex xl:bg-white lg:bg-transparent ">
        <div className="bg-white/75 xl:bg-white/100 rounded-lg w-10/12 lg:w-5/12">
          <div
            style={{
              display: `grid`,
              gridTemplateColumns: `repeat(24, 1fr)`,
              gridTemplateRows: `1fr`,
              gridColumnGap: `0px`,
              gridRowGap: `0px`,
            }}
          >
            <div
              className="mt-6 sm:mt-0"
              style={{
                gridArea: `1 / 1 / 2 / 25 `,
                backgroundColor: "transparent",
              }}
            >
              <div className=" py-3 flex justify-center items-center lg:h-1/6 h-1/10 bg-transparent">
                <img
                  src={LearnLogo}
                  alt="Logo"
                  className="w-40 pt-2 bg-transparent cursor-pointer "
                  onClick={() => {
                    navigate("/");
                  }}
                />
              </div>
            </div>
          </div>

          <div>
            <p className="text-center">
              Welcome l3arn Ambassador! <br /> Please use your credentials to
              log in
            </p>
          </div>

          <div className=" py-4 ">
            {error && (
              <div className="py-4 bg-red-600 w-full text-white text-center mb-3">
                {error}
              </div>
            )}
            <form>
              <div
                className="w-full "
                style={{
                  display: `grid`,
                  gridTemplateColumns: `repeat(24, 1fr)`,
                  gridTemplateRows: `1fr`,
                  gridColumnGap: `0px`,
                  gridRowGap: `0px`,
                }}
              >
                <div style={{ gridArea: `1 / 4 / 2 / 22` }}>
                  <input
                    type="email"
                    className="my-1 py-2 w-full placeholder-white px-4 text-white rounded-md"
                    style={{
                      backgroundColor: commonColor.deepBlue,
                    }}
                    name="userEmail"
                    value={email}
                    placeholder="email"
                    id="userEmail"
                    onChange={(event) => onChangeHandler(event)}
                  />

                  <input
                    type={passwordShown ? "text" : "password"}
                    className="my-1 py-2 w-full placeholder-white px-4 text-white rounded-md"
                    style={{
                      backgroundColor: commonColor.deepBlue,
                    }}
                    name="userPassword"
                    value={password}
                    placeholder="password"
                    id="userPassword"
                    onChange={(event) => onChangeHandler(event)}
                  />
                </div>
                <div
                  className="self-center bg-transparent justify-center flex"
                  style={{ gridArea: `1 / 19 / 2 / 25` }}
                >
                  <button
                    className="h-16 w-16 flex items-center justify-center rounded-full bg-white
                    focus:outline-none"
                    //  disabled={!isValid}
                    onClick={(e) => {
                      e.preventDefault();
                      console.log("loging In");
                      login();
                    }}
                  >
                    <PlayButton
                      className="h-14 w-14 rounded-full "
                      style={{
                        fill: commonColor.deepBlue,
                        width: "3rem",
                        height: "3rem",
                        //stroke: "white",
                        backgroundColor: "white",
                      }}
                    />
                  </button>
                </div>
                <div
                  className="self-end bg-transparent justify-center flex"
                  style={{ gridArea: `1 / 1 / 1 / 4` }}
                >
                  <button
                    className="px-2 py-3 focus:outline-none"
                    onClick={(event) => {
                      event.preventDefault();
                      togglePasswordVisibility();
                    }}
                  >
                    {passwordShown ? (
                      <Eye
                        className="rounded-full h-6 w-6 hover:bg-gray-200 bg-transparent"
                        style={{
                          fill: commonColor.deepBlue,
                        }}
                      />
                    ) : (
                      <Hidden
                        className="rounded-full h-6 w-6 hover:bg-gray-200 bg-transparent"
                        style={{
                          fill: commonColor.deepBlue,
                        }}
                      />
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <LoaderModal isOpen={isLoading} message={null} onRequestClose={null} />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(SignInAmbassador);
