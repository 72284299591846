import React, { memo } from "react";
import PlaystoreLogo from "../assets/playstore.svg";
import AppleLogo from "../assets/apple.svg";
import ImageOne from "../assets/qw.png";
import Button from "../components/landingpageButton";
import { useSpring, animated } from "@react-spring/web";

const StoreButton = memo(
  ({ logo, textOne, textOneColor, textTwo, textTwoColor, bgClass }) => (
    <div
      className={`${bgClass} p-2 text-left lg:px-4 flex gap-2 rounded-md shadow-lg justify-center items-center hover:`}
    >
      <img src={logo} alt="Logo" className="w-4 h-4 | lg:w-8 lg:h-8" />
      <div>
        <p className="text-gray-500 text-sm">
          {" "}
          {/* Display "On the" only for desktop */}
          <span className="md:hidden">Download</span>{" "}
          {/* Display "Download on the" only for desktop */}
          <span className={`text-sm hidden md:block ${textTwoColor}`}>
            {textOne}
          </span>
        </p>
        <p className={`text-sm ${textOneColor}`}>{textTwo}</p>
      </div>
    </div>
  )
);

const PartOne = memo(({ loading }) => {
  const spring = useSpring({
    from: { y: "0", opacity: 0 },
    to: loading ? {} : { y: "20", opacity: 1 },
  });
  return (
    <animated.div
      id="home_landing"
      className="flex flex-col lg:flex-row justify-around items-center md:pl-10 md:pr-0 px-4 pb-4 w-full rounded-xl bg-[rgb(255,248,248)] h-fit md:h-full pt-14 md:pt-0 gap-0 lg:gap-10"
      style={{
        ...spring,
      }}
    >
      <div className="w-full sm:w-11/12 md:w-10/12 lg:w-9/12 ">
        <div className="flex flex-col items-center mt-8">
          <p className="text-2xl mb:text-3xl text-gray-700 max-w-[40ch] min-w-[20ch] mt-4 lg:mt-0 text-center">
            l3arn: Your All-in-One Destination for Tutoring, AI, and Educational
            Support
          </p>
          <Button link="/sign-in" />
        </div>

        <div className="lg:mt-52 mt-20 mb-10  lg:mb-0 flex gap-4 justify-center items-center flex-col">
          <div className="w-fit">
            {" "}
            <p className="text-gray-500 ">Coming soon on</p>
          </div>
          <div className="flex gap-4 w-fit flex-wrap justify-center">
            {" "}
            <StoreButton
              logo={PlaystoreLogo}
              textOne="Download"
              textTwo="Google Play"
              bgClass="bg-[#474747]"
              textOneColor="text-white"
              textTwoColor="text-gray-200"
            />
            <StoreButton
              logo={AppleLogo}
              textOne="Get it on"
              textTwo="Apple Store"
              bgClass="border-black border-[2px]"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-center relative transform transition-all duration-200 ease-out md:pb-0 pb-20 mt-16 md:mt-0">
        <img
          className="w-9/12 h-auto "
          src={ImageOne}
          alt="image_girl_with_headsets"
        />
      </div>
    </animated.div>
  );
});

export default PartOne;
