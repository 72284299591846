import { useEffect, useState } from "react";
import { connect } from "react-redux";
import MainPageWithMenu from "../../components/common/MainPageTemplate/mainPageWithMenu";

import NewsFeed from "../../components/tutor/tools/newsFeed";

const StudentTools = ({ user }) => {
  const [currentToolsView, setCurrentToolsView] = useState("News feed");

  useEffect(() => {
    console.log("StudentTools - useEffect currentToolsView", currentToolsView);
  }, [currentToolsView]);

  return (
    <MainPageWithMenu user={user} menuSelected={"studentTools"}>
      {/* {currentToolsView === "News feed" ? ( */}
        <NewsFeed setCurrentToolsView={setCurrentToolsView} showAdvanceTool={false} />
      {/* ) : ( */}
        {/* <TechnicalTools setCurrentToolsView={setCurrentToolsView} /> */}
      {/* )} */}
    </MainPageWithMenu>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(StudentTools);
