import React, { useState } from "react";
import "./PersonaStyle.css";
import LandingLayout from "../../../components/landing/landingLayout";
import BetaSignupModal from ".././betaSignupModal";
import LoaderModal from "../../../components/common/modal/loaderModal";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Swotbutton from "../../../components/common/buttons/swotbutton.tsx";
import slideImage1 from "../../../assets/images/PersonaPageImages/HomeSchooling/homechool-1.png";
import slideImage2 from "../../../assets/images/PersonaPageImages/HomeSchooling/hoemschool-2.png";
import slideImage3 from "../../../assets/images/PersonaPageImages/HomeSchooling/homeschool-3.png";
import { useNavigate } from "react-router-dom";

const words = ["The Basics", "What we offer?", "Why l3arn?"];

const indicators = (index) => {
  return (
    <div className="text-base lg:text-xl font-semibold indicator">
      {words[index]}
    </div>
  );
};
const fadeProperties = {
  duration: 30000,
  pauseOnHover: true,
  autoplay: false,
  indicators: indicators,
  easing: "ease-out",
  canSwipe: true,
};

// <!-- Width of 16 by default, 32 on medium screens, and 48 on large screens -->
// <img class="w-16 md:w-32 lg:w-48" src="...">

const Slideshow = () => {
  const navigate = useNavigate();
  return (
    <div className="sm:pl-4 sm:pr-5 max-w-full h-full persona">
      <div className="slide-container m-6 sm:w-full w-11/12 ">
        <Slide className="relative" {...fadeProperties}>
          <div className="lg:flex-row flex-col | each-slide-effect flex  items-center justify-center px-8 lg:px-10 mt-16 h-full  ">
            <div className="md:w-full flex items-center justify-center lg:w-9/12 xl:w-8/12">
              <div className="w-9/12">
                <p className="font-bold | text-xl  lg:text-2xl ">
                  Homeschooling
                </p>
                <br />
                <p className="font-semibold | text-base  lg:text-xl ">
                  can be a great experience with many benefits, such as
                  individuals becoming productive members of society. But the
                  struggles of tutoring at home can quickly become frustrating
                  and create problems.
                </p>
                <p className="font-normal | text-base lg:text-xl ">
                  Issues such as time management, lack of motivation, keeping
                  your child engaged, and isolation can take a toll on both
                  parent and child.
                </p>
                <div className="flex flex-row mt-6 lg:justify-start justify-center items-center">
                  <Swotbutton
                    text="Enroll Now"
                    className="mr-4"
                    bgColor="#25627B"
                    onClick={() => {
                      navigate("/on-boarding");
                    }}
                  />
                  <Swotbutton
                    text="Learn More"
                    onClick={() => {
                      navigate("/");
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-auto mt-6">
              <img
                className="slideShowImg w-full pl-2 lg:pl-2 object-contain"
                src={slideImage1}
                alt="HomeSchooling slideImage1"
              />
            </div>
          </div>
          <div className="lg:flex-row flex-col | each-slide-effect flex  items-center justify-center px-8 lg:px-10 mt-16 h-full ">
            <div className="md:w-full flex items-center justify-center lg:w-9/12 xl:w-8/12">
              <div className="w-10/12">
                <p className="font-semibold | text-lg  lg:text-xl mb-4 ">
                  That’s where we come in. At l3arn, you can get a homeschool
                  tutor that provides the following:
                </p>
                <ul className="list-disc ml-6">
                  <li className="font-medium mb-1 | text-base lg:text-xl">
                    A flexible learning schedule
                  </li>
                  <li className="font-medium mb-1 | text-base lg:text-xl">
                    A controlled environment
                  </li>
                  <li className="font-medium mb-1 | text-base lg:text-xl">
                    A supportive learning environment for a child who is gifted
                    or who has learning difficulties
                  </li>
                  <li className="font-medium mb-1 | text-base lg:text-xl">
                    A less traditional and more child-centred approach to
                    education
                  </li>
                  <li className="font-medium | text-base lg:text-xl">
                    New educational philosophies or practices
                  </li>
                </ul>

                <div className="flex flex-row mt-6 lg:justify-start justify-center items-center">
                  <Swotbutton
                    text="Enroll Now"
                    className="mr-4"
                    bgColor="#25627B"
                    onClick={() => {
                      navigate("/on-boarding");
                    }}
                  />
                  <Swotbutton
                    text="Learn More"
                    onClick={() => {
                      navigate("/information");
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-auto">
              <img
                className="slideShowImg w-full pl-2 lg:pl-2 object-contain"
                src={slideImage2}
                alt="HomeSchooling slideImage2"
              />
            </div>
          </div>
          <div className="lg:flex-row flex-col | each-slide-effect flex  items-center justify-center px-8 lg:px-10 mt-16 h-full ">
            <div className="md:w-full flex items-center justify-center lg:w-9/12 xl:w-8/12">
              <div className="w-9/12">
                <p className="font-medium | text-base lg:text-xl mb-4">
                  Homeschool can be a great experience having many benefits, But
                  the struggles and tutoring at home can quickly become
                  overwhelming.
                </p>
                <p className="font-bold | text-base lg:text-xl">
                  At l3arn, you can connect with other tutors to discuss, ask
                  questions and create a support system for your children and
                  yourself.
                </p>

                <div className="flex flex-row mt-6 lg:justify-start justify-center items-center">
                  <Swotbutton
                    text="Enroll Now"
                    className="mr-4"
                    bgColor="#25627B"
                    onClick={() => {
                      navigate("/on-boarding");
                    }}
                  />
                  <Swotbutton
                    text="Learn More"
                    onClick={() => {
                      navigate("/learner-type");
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-auto">
              <img
                className="slideShowImg w-full pl-2 lg:pl-2 object-contain"
                src={slideImage3}
                alt="HomeSchooling slideImage3"
              />
            </div>
          </div>
        </Slide>
      </div>
    </div>
  );
};

const HomeSchooling = () => {
  const navigate = useNavigate();
  const [showFormModal, setShowFormModal] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <LandingLayout landingTitle="">
      <Slideshow />
      <BetaSignupModal
        isOpen={!loading && showFormModal}
        onRequestClose={() => {
          setShowFormModal(false);
        }}
        showLoader={(status) => {
          setLoading(status);
        }}
      />
      {/* <BetaSignup onSubmit={onSubmit} /> */}
      {/* </BetaSignup> */}
      <LoaderModal
        isOpen={loading}
        onRequestClose={() => {
          setLoading(false);
        }}
      />
    </LandingLayout>
  );
};

export default HomeSchooling;
