import React, { useEffect, useState } from "react";
import { usStates } from "../../../config/usStates";
import {
  getCitiesForState,
  getTutorPersonalInfo,
  updateTutorPersonalInfo,
} from "../../../services/api";
import commonColor from "../../../theme/commonColor";
import SwotButton from "../../common/buttons/swotbutton";
import { ReactComponent as UserIcon } from "../../../assets/icons/user.svg";
import Select from "../../common/input/select";
import "./styles.css";
import { countriesList } from "../../../services/countrieslist";
import { getCountryNameByCityName } from "../../../services/location";
import { getCitiesListByCountryName } from "../../../services/location";


const PersonalInfo = ({ user, viewOnly }) => {
  const [info, setInfo] = useState();
  const [updatedFirstname, setUpdatedFirstname] = useState();
  const [updatedLastname, setUpdatedLastname] = useState();
  const [updatedUsername, setUpdatedUsername] = useState();
  const [updatedPassword, setUpdatedPassword] = useState();
  const [updatedStreetname, setUpdatedStreetname] = useState();
  const [updatedCityOrState, setUpdatedCityOrState] = useState();
  const [updatedPostalCode, setUpdatedPostalCode] = useState();
  const [updatedCountry, setUpdatedCountry] = useState();
  const [states, setStates] = useState(usStates);
  const [selectedState, setSelectedState] = useState();
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [selectedCity, setSelectedCity] = useState();
  const [statesOptions, setStatesOptions] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = React.useState();

  const nameMaxCharacters = 56;

  useEffect(() => {
    if (!info) {
      getTutorInfo();
    }
  }, [info, user.uid]);

  useEffect(() => {
    console.log("viewOnly", viewOnly);
    const stateOptions = [];
    usStates.map((st) => {
      if (st.stateName !== "default") {
        stateOptions.push({
          value: st.stateName,
          label: st.stateName,
          id: st.id,
        });
      }
    });

    console.log("stateOptions", stateOptions);

    setStatesOptions(stateOptions);
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDFZO6X2-rBzRgKTzEageApu3R5Rj1JxkA&libraries=places`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    // fetch cities of State

    const getCitiesOfState = async (state) => {
      const result = await getCitiesForState(state);
      const fetchedCities = result.data?.map((city) => {
        return {
          ...city,
          label: city.cityName,
          value: city.cityName,
        };
      });
      console.log(
        "PersonalInfo - useEffect - got cities for state",
        state,
        fetchedCities
      );
      setCitiesOptions(fetchedCities);
    };

    selectedState && getCitiesOfState(selectedState.value);
  }, [selectedState]);

  useEffect(() => {
    if (info) {
      if (info.lastName && !updatedLastname)
        setUpdatedLastname(info.lastName.trim());

      if (info.firstName && !updatedFirstname)
        setUpdatedFirstname(info.firstName);
    }
    console.log(
      "PersonalInfo - useEffect - updated",
      updatedFirstname,
      updatedLastname,
      updatedUsername,
      updatedPassword,
      updatedStreetname,
      updatedCityOrState,
      updatedPostalCode,
      updatedCountry
    );
  }, [
    updatedCityOrState,
    updatedCountry,
    updatedFirstname,
    updatedLastname,
    updatedPassword,
    updatedPostalCode,
    updatedStreetname,
    updatedUsername,
    selectedCity,
    selectedState,
    info
  ]);


  const getTutorInfo = async () => {
    const result = await getTutorPersonalInfo(user.uid);
    if (result && result.ok) {

      if (result.data.country == 'NonUSA') {
        let findMainCountryName = await getCountryNameByCityName(result.data.cityName);
        console.log("Main Country Name:", findMainCountryName);
        if (findMainCountryName == 'US')
          result.data.country = 'USA';
      }

      console.log(
        "PersonalInfo - useEffect - got tutor personalInfo",
        result.data
      );
      setInfo(result.data);

      const preSelectedStateId = result.data.stateId;
      const preSelectedState = usStates.filter(
        (st) => st.id === preSelectedStateId
      );
      console.log("getTutorInfo - preSelectedState", preSelectedState);

      if (preSelectedState[0]) {
        if (result.data.country && result.data.country == "USA")
          setSelectedState({
            value: preSelectedState[0].stateName,
            label: preSelectedState[0].stateName,
            id: preSelectedState[0].id,
          });
      }

      const preselectedCity = {
        cityId: result.data.cityId,
        cityName: result.data.cityName,
        label: result.data.cityName,
        value: result.data.cityName,
      };
      console.log("getTutorInfo - preselectedCity", preselectedCity, " Country: ", result.data.country);
      setSelectedCity(preselectedCity);

      if (result.data.country) {

        let tmpCountryName;

        if (result.data.country != "USA") {
          let cities = getCitiesListByCountryName(result.data.country);
          setCitiesOptions(cities);
        }

        if (result.data.country !== "NonUSA") {
          tmpCountryName = result.data.country
        }
        else {
          let findCountryName = await getCountryNameByCityName(result.data.cityName);
          console.log("getting Country Name:", findCountryName);
          tmpCountryName = findCountryName;
          setUpdatedCountry(tmpCountryName);
        }

        let seletedCountryOpt = countriesList.find(o => o.value === tmpCountryName);
        console.log("seletedCountryOpt :", seletedCountryOpt);
        setSelectedCountry(seletedCountryOpt);

      }

    }
  };


  const updateTutorInfo = async () => {
    console.log("Updating personal Info!");

    let payload = {
      ...info,
    };

    if (updatedFirstname) {
      payload.firstName = updatedFirstname;
    }
    if (updatedLastname) {
      payload.lastName = updatedLastname;
    }
    if (updatedPassword) {
      payload.password = updatedPassword;
    }
    if (updatedUsername) {
      payload.username = updatedUsername;
    }
    if (selectedState) {
      payload.stateId = selectedState.id;
    }
    if (selectedCity) {
      payload.cityName = selectedCity.value;
      payload.cityId = selectedCity.cityId;
    }
    if (updatedPostalCode) {
      payload.postalCode = updatedPostalCode;
    }
    if (updatedCountry) {
      payload.country = updatedCountry;
    }
    setIsLoading(true);
    const result = await updateTutorPersonalInfo(user.uid, payload);
    if (result) {
      console.log("PersonalInfo - update tutor info - result", result);
    }
    setIsLoading(false);
  };

  return (
    <div className="flex flex-col items-center w-full h-full overflow-y-scroll sm:flex-row md:pr-4 sm:overflow-y-hidden">


      <div
        className={`w-11/12 sm:w-4/12 h-full ${viewOnly ? "md:h-3/5" : "md:h-6/12"
          } flex md:flex-row flex-col pb-5 justify-start items-start`}
      >


        <div className="flex items-center justify-center w-full h-auto md:w-10/12">
          {!viewOnly && (
            <div
              className="flex items-center justify-center w-1/3 shadow-2xl sm:w-10/12 md:w-2/3 sm:max-w-full md:max-w-fitContent min-h-fit rounded-3xl"
              // className="flex items-center justify-center w-1/3 h-full shadow-2xl rounded-3xl max-w-1/3 md:max-w-full"
              style={{
                backgroundColor: commonColor.deepGreen,
                // maxWidth: "fit-content",
                aspectRatio: "1/1",
                // marginTop: "1.5rem",
              }}
            >
              {((info?.photoUrl && info?.photoUrl !== "null") || (user?.photoUrl && user?.photoUrl !== "null")) ? (
                <img
                  style={{
                    // height: "12rem",
                    objectFit: "cover",
                  }}
                  className="w-full h-full rounded-3xl"
                  src={info?.photoUrl ? info?.photoUrl : user?.photoUrl}
                  alt="InfoIcon"
                // className="w-auto h-full bg-white rounded-full shadow-lg"
                />
              ) : (
                <UserIcon
                  className="h-full p-4 rounded-3xl"
                  style={{
                    fill: commonColor.deepPink,
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>





      {/* Right Bottom Side */}
      <div
        className={`w-10/12 ${viewOnly ? "sm:h-6/12" : "sm:h-full"
          }  flex flex-col justify-start items-center sm:overflow-y-scroll`}
      >
        {/* <div className="flex justify-end w-full">
          <div className="flex items-center justify-end w-2/5 text-sm lg:w-1/3">
            Address
          </div>
          <div className="flex justify-end w-full px-2 lg:w-2/3">
            <input
              className="w-full px-2 py-1 bg-white border-2 appearance-none focus:outline-none focus:border-opacity-100"
              id="streetname"
              name="streetname"
              type="text"
              placeholder={
                info && info.streetname
                  ? info.streetname
                  : "Enter your address"
              }
              value={updatedStreetname}
              onChange={(event) => setUpdatedStreetname(event.target.value)}
            />
          </div>
        
        </div> */}

        <div className="flex flex-col items-center justify-around w-full h-full pt-5 pr-12 md:pt-0">
          <div className="flex flex-col justify-end w-full pt-4">
            <div className="flex flex-row items-center w-3/5 text-sm font-semibold lg:w-2/5">
              First Name
            </div>
            <div className="flex justify-start w-full px-2 lg:w-4/5">
              <input
                className="profile_input | w-full bg-slate-50 appearance-none
                py-1 px-2 text-sm  border-none mt-1"
                id="firstname"
                name="firstname"
                type="text"
                placeholder={info && info.firstName ? info.firstName : "Enter your first name"}
                value={updatedFirstname}
                onChange={(event) => {
                  let updatedValue = event.target.value;
                  if (updatedValue && updatedValue.length > nameMaxCharacters) {
                    updatedValue = updatedValue.substring(0, nameMaxCharacters);
                  }
                  setUpdatedFirstname(updatedValue);
                }}
              />
            </div>
          </div>
          <div className="flex flex-col justify-end w-full pt-4">
            <div className="flex flex-row items-center w-3/5 text-sm font-semibold lg:w-2/5">
              Last Name
            </div>
            <div className="flex justify-start w-full px-2 lg:w-4/5">
              <input
                className="profile_input | w-full bg-slate-50 appearance-none
                py-1 px-2 text-sm  border-none mt-1"
                id="lastname"
                name="lastname"
                type="text"
                placeholder={
                  info && info.lastName ? info.lastName.trim() : "Enter your last name"
                }
                value={updatedLastname}
                onChange={(event) => {
                  let updatedValue = event.target.value;
                  if (updatedValue && updatedValue.length > nameMaxCharacters) {
                    updatedValue = updatedValue.substring(0, nameMaxCharacters);
                  }
                  setUpdatedLastname(updatedValue);
                }}
              />
            </div>
          </div>
          <div className="flex flex-col justify-end w-full pt-4">
            <div className="flex flex-row items-center w-2/5 text-sm font-semibold lg:w-1/5 ">
              Username
            </div>
            <div className="flex justify-start w-full px-2 lg:w-4/5">
              <input
                className="w-full px-2 py-1 mt-1 text-sm bg-gray-300 border-2 appearance-none cursor-not-allowed profile_input focus:outline-none focus:border-opacity-100"
                id="username"
                name="username"
                type="text"
                placeholder="Enter your username"
                // placeholder={info && info.username ? info.username : "Enter your username"}
                value={info && info.username}
                onChange={(event) => setUpdatedUsername(event.target.value)}
                disabled={true}
              />
            </div>
          </div>
          <div className="flex flex-col justify-end w-full pt-4">
            <div className="flex flex-row items-center w-2/5 text-sm font-semibold lg:w-1/5">
              Email
            </div>
            <div className="flex justify-start w-full px-2 lg:w-4/5">
              <input
                className="w-full px-2 py-1 mt-1 text-sm bg-gray-300 border-2 appearance-none cursor-not-allowed profile_input focus:outline-none focus:border-opacity-100"
                id="email"
                name="email"
                type="text"
                placeholder="Enter your email"
                //placeholder={info && info.email ? info.email : user.email}
                value={info ? info.email : user.email}
                disabled={true}
              // onChange={handleFullnameChange}
              />
            </div>
          </div>
          {info && info.password && info.password !== "undefined" && (
            <div className="flex flex-col justify-end w-full pt-3">
              <div className="flex flex-row items-center w-2/5 text-sm lg:w-1/5">
                Password
              </div>
              <div className="flex justify-start w-full px-2 lg:w-4/5">
                <input
                  className="w-full px-2 py-1 text-sm bg-gray-300 border-2 appearance-none cursor-not-allowed profile_input focus:outline-none focus:border-opacity-100"
                  id="password"
                  name="password"
                  type="password"
                  placeholder={
                    info && info.password
                      ? info.password
                      : "Enter your password"
                  }
                  value={updatedPassword}
                  onChange={(event) => setUpdatedPassword(event.target.value)}
                />
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-col items-center justify-around w-full h-full pb-1 pr-12 mt-3 md:pb-0 sm:mb-2 mb-14" >
          <div className="flex flex-col justify-end w-full mt-6">
            <div className="flex items-center w-2/5 text-sm font-semibold lg:w-1/3">
              Postal code
            </div>
            <div className="flex justify-end w-full px-2 lg:w-2/3">
              <input
                className="profile_input | w-full bg-slate-50 appearance-none
                py-1 px-2 text-sm  border-none mt-1"
                id="postalCode"
                name="postalCode"
                type="text"
                placeholder={
                  info && info.postalCode
                    ? info.postalCode
                    : "Enter your postal code"
                }
                value={updatedPostalCode}
                onChange={(event) => setUpdatedPostalCode(event.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-col justify-end w-full pt-4">
            <div className="flex items-center w-2/5 text-sm font-semibold lg:w-1/3">
              Country
            </div>
            <div className="flex justify-end w-full px-2 lg:w-2/3">

              <Select
                className="w-full px-2 mt-1 text-sm bg-slate-50py-1"
                value={selectedCountry}
                onChange={(value) => {
                  console.log("selected Country", value);
                  setSelectedCountry(value);
                  setUpdatedCountry(value.value);

                  let cities = {};
                  if (value.value != "USA") {
                    cities = getCitiesListByCountryName(value.value);
                    setCitiesOptions(cities);
                  }
                  setSelectedCity(cities[0]);

                }}
                placeholder="Select Country.."
                options={countriesList}
              />

              {/* <input
                className="w-full px-2 py-1 bg-white border-2 appearance-none focus:outline-none focus:border-opacity-100"
                id="country"
                name="country"
                type="text"
                placeholder={
                  info && info.country
                    ? info.country
                    : "Country you live in, eg. USA"
                }
                value={updatedCountry}
                onChange={(event) => setUpdatedCountry(event.target.value)}
              /> */}
            </div>
          </div>
          <div className="flex flex-col justify-end w-full pt-4 content-wrap">

            <span className="flex items-center text-sm font-semibold">
              City/Area
            </span>
            <div className="flex flex-col justify-end w-full px-2 lg:w-2/3 sm:flex-row">

              <div className="flex flex-row w-full py-1 sm:w-1/2 sm:pr-1">

                <div className="w-full">
                  <Select
                    className="w-full text-sm"
                    defaultValue={selectedCity && selectedCity}
                    value={selectedCity && selectedCity}
                    onChange={(value) => {
                      console.log("selected city", value);
                      setSelectedCity(value);
                      console.log("selectedCountry: ", selectedCountry);

                      // handleChange('tutoringRegistration', value.value)
                    }}
                    options={citiesOptions}
                  />

                </div>
              </div>

              <div className="flex flex-row w-full py-1 sm:w-1/2 sm:pl-1 ">
                {selectedCountry?.value === 'USA' &&
                  <span className="flex items-center justify-end p-2 text-sm font-semibold">
                    State
                  </span>
                }
                <div className="w-4/5">
                  {selectedCountry?.value === 'USA' &&
                    <Select
                      className="w-full text-sm"
                      defaultValue={selectedState && selectedState}
                      value={selectedState && selectedState}
                      onChange={(value) => {
                        console.log("selected state", value);
                        setSelectedState(value);
                        // handleChange('tutoringRegistration', value.value)
                      }}
                      options={statesOptions}
                    />
                  }
                </div>

              </div>

              {/* <input
                    className="w-full px-2 py-1 bg-white border-2 appearance-none lg:w-4/5 focus:outline-none focus:border-opacity-100"
                    id="cityOrState"
                    name="cityOrState"
                    type="text"
                    placeholder={
                      info && (info.cityName || info.stateCode)
                        ? `${info.cityName}, ${info.stateCode}`
                        : "Enter where you live, eg. Austin, TX"
                    }
                    value={updatedCityOrState}
                    onChange={(event) => setUpdatedCityOrState(event.target.value)}
                  /> */}
              {/* <PlaceSearch
                    // cachedValue={livingInfo}
                    onSelect={(value) => {
                      console.log("city/state", value);
                      // setIsPlaceSelected(true)
                      // setLivingInfo(value)
                    }}
                    classes={"w-full lg:w-4/5"}
              /> */}
            </div>
          </div>

        </div>
        {!viewOnly && (
          <div className="flex items-center justify-end w-full mr-12 sm:mr-4">
            <SwotButton
              text={"Update"}
              onClick={() => updateTutorInfo()}
              loading={isLoading}
            />
          </div>

        )}
      </div>
      {/* Right Bottom Side End */}




    </div>
  );
};

export default PersonalInfo;
