import React from "react";
import googleSrc from "./google.svg";

const GoogleButton = ({ onClick = () => {} }) => {
  return (
    <button
      className="flex rounded-md border w-full justify-center items-center p-2 bg-white hover:bg-blue-100 h-12"
      onClick={onClick}
      style={style}
    >
      <div className="w-8 p-1">
        <img src={googleSrc} alt="google" />
      </div>
      <div className="pl-2">Sign in with Google</div>
    </button>
  );
};

const style = {};

export default GoogleButton;
