import React, { useState } from "react";
import "./PersonaStyle.css";
import LandingLayout from "../../../components/landing/landingLayout";
import BetaSignupModal from ".././betaSignupModal";
import LoaderModal from "../../../components/common/modal/loaderModal";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Swotbutton from "../../../components/common/buttons/swotbutton.tsx";
import slideImage1 from "../../../assets/images/PersonaPageImages/StudentsLearningDf/learning-diff-1.png";
import slideImage2 from "../../../assets/images/PersonaPageImages/StudentsLearningDf/learning-diff-2.png";
import slideImage3 from "../../../assets/images/PersonaPageImages/StudentsLearningDf/learning-diff-3.png";
import { useNavigate } from "react-router-dom";

const words = ["The Basics", "What we offer?", "Why l3arn?"];

const indicators = (index) => {
  return (
    <div className="text-base  lg:text-xl font-semibold indicator">
      {words[index]}
    </div>
  );
};
const fadeProperties = {
  duration: 30000,
  pauseOnHover: true,
  autoplay: false,
  indicators: indicators,
  easing: "ease-out",
  canSwipe: true,
};

// <!-- Width of 16 by default, 32 on medium screens, and 48 on large screens -->
// <img class="w-16 md:w-32 lg:w-48" src="...">

const Slideshow = () => {
  let navigate = useNavigate();

  return (
    <div className="sm:pl-4 sm:pr-5 max-w-full h-full persona">
      <div className="slide-container m-6 sm:w-full w-11/12 ">
        <Slide className="relative" {...fadeProperties}>
          <div className="lg:flex-row flex-col | each-slide-effect flex  items-center justify-center px-8 lg:px-10 mt-16 h-full  ">
            <div className="md:w-full flex items-center justify-center lg:w-9/12 xl:w-8/12">
              <div className="w-10/12">
                <p className="font-medium mb-2 | text-base  lg:text-xl  ">
                  Most, if not all, students might get overwhelmed with a
                  subject at school.
                </p>
                <p className="font-normal | text-base  lg:text-xl ">
                  The problem is that schools usually teach things in a specific
                  learning style and must consistently tailor their teaching to
                  the needs of a student in need. But what even is the right
                  learning style for you? To top that, 1 to 1 can be expensive,
                  with the frustration accelerating.
                </p>
                <div className="flex flex-row mt-6 lg:justify-start justify-center items-center">
                  <Swotbutton
                    text="Enroll Now"
                    className="mr-4"
                    bgColor="#25627B"
                    onClick={() => {
                      navigate("/on-boarding");
                    }}
                  />
                  <Swotbutton
                    text="Learn More"
                    onClick={() => {
                      navigate("/");
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-auto mt-6">
              <img
                className="slideShowImg w-full pl-2 lg:pl-2 object-contain"
                src={slideImage1}
                alt="Student Learning Difference SlideImage1"
              />
            </div>
          </div>
          <div className="lg:flex-row flex-col | each-slide-effect flex  items-center justify-center px-8 lg:px-10 mt-16 h-full ">
            <div className="md:w-full flex items-center justify-center lg:w-9/12 xl:w-8/12">
              <div className="w-10/12">
                <p className="font-normal | text-base lg:text-xl">
                  At l3arn, we offer a platform for{" "}
                  <span className="font-semibold">everyone</span> regardless of
                  their financial background, but with the quality that
                  challenges any expensive institute,{" "}
                  <span className="font-semibold">
                    {" "}
                    with tutors from all over the world with experience and
                    passion for changing the way we see learning.
                  </span>{" "}
                </p>

                <div className="flex flex-row mt-6 lg:justify-start justify-center items-center">
                  <Swotbutton
                    text="Enroll Now"
                    className="mr-4"
                    bgColor="#25627B"
                    onClick={() => {
                      navigate("/on-boarding");
                    }}
                  />
                  <Swotbutton
                    text="Learn More"
                    onClick={() => {
                      navigate("/information");
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-auto">
              <img
                className="slideShowImg w-full pl-2 lg:pl-2 object-contain"
                src={slideImage2}
                alt="Student Learning Difference SlideImage2"
              />
            </div>
          </div>
          <div className="lg:flex-row flex-col | each-slide-effect flex  items-center justify-center px-8 lg:px-10 mt-16 h-full ">
            <div className="md:w-full flex items-center justify-center lg:w-9/12 xl:w-8/12">
              <div className="w-10/12">
                <p className="font-semibold | text-base lg:text-xl">
                  After years of research and experience, we have created a quiz
                  to determine your learning style so that students can
                  recognize their strengths and build on them rather than fixate
                  on their weaknesses.
                </p>
                <p className="font-medium | text-base lg:text-xl">
                  The best part is you can choose a tutor based on their
                  tutoring style so that they can match you making learning more
                  accessible and engaging!
                </p>

                <div className="flex flex-row mt-6 lg:justify-start justify-center items-center">
                  <Swotbutton
                    text="Enroll Now"
                    className="mr-4"
                    bgColor="#25627B"
                    onClick={() => {
                      navigate("/on-boarding");
                    }}
                  />
                  <Swotbutton
                    text="Learn More"
                    onClick={() => {
                      navigate("/learner-type");
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-auto">
              <img
                className="slideShowImg w-full pl-2 lg:pl-2 object-contain"
                src={slideImage3}
                alt="Student Learning Difference SlideImage3"
              />
            </div>
          </div>
        </Slide>
      </div>
    </div>
  );
};

export default function StLearningDf() {
  const [showFormModal, setShowFormModal] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <LandingLayout landingTitle="">
      <Slideshow />
      <BetaSignupModal
        isOpen={!loading && showFormModal}
        onRequestClose={() => {
          setShowFormModal(false);
        }}
        showLoader={(status) => {
          setLoading(status);
        }}
      />
      {/* <BetaSignup onSubmit={onSubmit} /> */}
      {/* </BetaSignup> */}
      <LoaderModal
        isOpen={loading}
        onRequestClose={() => {
          setLoading(false);
        }}
      />
    </LandingLayout>
  );
}
