import React, { useState } from "react";
import "./PersonaStyle.css";
import LandingLayout from "../../../components/landing/landingLayout";
import BetaSignupModal from ".././betaSignupModal";
import LoaderModal from "../../../components/common/modal/loaderModal";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Swotbutton from "../../../components/common/buttons/swotbutton.tsx";
import slideImage1 from "../../../assets/images/PersonaPageImages/Tutor/tutor-1.png";
import slideImage2 from "../../../assets/images/PersonaPageImages/Tutor/tutor-2.png";
import slideImage3 from "../../../assets/images/PersonaPageImages/Tutor/tutor-3.png";
import { useNavigate } from "react-router-dom";

const words = ["The Basics", "What we offer?", "Why l3arn?"];

const indicators = (index) => {
  return (
    <div className="text-base lg:text-xl font-semibold indicator">
      {words[index]}
    </div>
  );
};
const fadeProperties = {
  duration: 30000,
  pauseOnHover: true,
  autoplay: false,
  indicators: indicators,
  easing: "ease-out",
  canSwipe: true,
};

// <!-- Width of 16 by default, 32 on medium screens, and 48 on large screens -->
// <img class="w-16 md:w-32 lg:w-48" src="...">

const Slideshow = () => {
  let navigate = useNavigate();

  return (
    <div className="sm:pl-4 sm:pr-5 max-w-full h-full persona">
      <div className="slide-container m-6 sm:w-full w-11/12 ">
        <Slide className="relative" {...fadeProperties}>
          <div className="lg:flex-row flex-col | each-slide-effect flex  items-center justify-center px-8 lg:px-10 mt-16 h-full  ">
            <div className="md:w-full flex items-center justify-center lg:w-9/12 xl:w-8/12">
              <div className="w-10/12">
                <p className="font-bold | text-base lg:text-xl  ">
                  Being an educator is essential because you are our children’s
                  future, sculpting their way of thinking and preparing them for
                  the world.
                </p>
                <p className="font-normal | text-base  lg:text-xl ">
                  As rewarding as it is, it does come with challenges.
                  Unfortunately, tutors are widely underpaid. In most cases,
                  they don’t have the opportunity to be as creative as they
                  want, which can be frustrating and lead to underperforming due
                  to stress, pressure and the inability to be their authentic
                  self.
                </p>

                <div className="flex flex-row mt-6 lg:justify-start justify-center items-center">
                  <Swotbutton
                    text="Enroll Now"
                    className="mr-4"
                    bgColor="#25627B"
                    onClick={() => {
                      navigate("/on-boarding");
                    }}
                  />
                  <Swotbutton
                    text="Learn More"
                    onClick={() => {
                      navigate("/");
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-auto mt-6">
              <img
                className="slideShowImg w-full pl-2 lg:pl-2 object-contain"
                src={slideImage1}
                alt="ForWho slideImage1"
              />
            </div>
          </div>
          <div className="lg:flex-row flex-col | each-slide-effect flex  items-center justify-center px-8 lg:px-10 mt-16 h-full ">
            <div className="md:w-full flex items-center justify-center lg:w-9/12 xl:w-8/12">
              <div className="w-10/12">
                <p className="font-bold | text-base lg:text-xl">
                  • An all in one platform
                </p>
                <p className="font-bold | text-base lg:text-xl">
                  • You can choose the rate you want to charge
                </p>
                <p className="font-bold | text-base lg:text-xl">
                  • Constant IT support
                </p>
                <p className="font-bold | text-base lg:text-xl">
                  • Marketing team
                </p>
                <p className="font-bold | text-base lg:text-xl">
                  • Work from anywhere in the world
                </p>
                <p className="font-bold | text-base lg:text-xl">
                  • Keeping you updated with the latest trends in education
                </p>
                <p className="font-bold | text-base lg:text-xl">
                  • Support through out your journey
                </p>
                <p className="font-bold | text-base lg:text-xl">
                  • Content development
                </p>
                <div className="flex flex-row mt-6 lg:justify-start justify-center items-center">
                  <Swotbutton
                    text="Enroll Now"
                    className="mr-4"
                    bgColor="#25627B"
                    onClick={() => {
                      navigate("/on-boarding");
                    }}
                  />
                  <Swotbutton
                    text="Learn More"
                    onClick={() => {
                      navigate("/information");
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-auto">
              <img
                className="slideShowImg w-full pl-2 lg:pl-2 object-contain"
                src={slideImage2}
                alt="ForWho slideImage2"
              />
            </div>
          </div>
          <div className="lg:flex-row flex-col | each-slide-effect flex  items-center justify-center px-8 lg:px-10 mt-16 h-full ">
            <div className="md:w-full flex items-center justify-center lg:w-9/12 xl:w-8/12">
              <div className="w-10/12">
                <p className="font-bold | text-base lg:text-xl">
                  At l3arn, we see ourselves as the vessel for tutors to use to
                  become the educator they have always believed they could be!
                </p>
                <p className="font-normal | text-base lg:text-xl">
                  You set your hours and the rate you want to charge, allowing
                  you to be financially independent. We provide you with tools
                  to create pioneering courses while having the support of our
                  team step by step. You have your marketing and consultant team
                  a hand to guide you through your journey to be the true you.
                  Monthly, you will be updated with the latest educational
                  trends to always be on top of the game. For the first time,
                  you will not be another tutor on an educational platform but a
                  tutor with a name, a purpose an individual that matters.
                </p>

                <div className="flex flex-row mt-6 lg:justify-start justify-center items-center">
                  <Swotbutton
                    text="Enroll Now"
                    className="mr-4"
                    bgColor="#25627B"
                    onClick={() => {
                      navigate("/on-boarding");
                    }}
                  />
                  <Swotbutton
                    text="Learn More"
                    onClick={() => {
                      navigate("/learner-type");
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-auto">
              <img
                className="slideShowImg w-full pl-2 lg:pl-2 object-contain"
                src={slideImage3}
                alt="ForWho slideImage3"
              />
            </div>
          </div>
        </Slide>
      </div>
    </div>
  );
};

export default function HomeSchooling() {
  const [showFormModal, setShowFormModal] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <LandingLayout landingTitle="">
      <Slideshow />
      <BetaSignupModal
        isOpen={!loading && showFormModal}
        onRequestClose={() => {
          setShowFormModal(false);
        }}
        showLoader={(status) => {
          setLoading(status);
        }}
      />
      {/* <BetaSignup onSubmit={onSubmit} /> */}
      {/* </BetaSignup> */}
      <LoaderModal
        isOpen={loading}
        onRequestClose={() => {
          setLoading(false);
        }}
      />
    </LandingLayout>
  );
}
