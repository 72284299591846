import { useEffect, useState } from "react";
import { connect } from "react-redux";
import contentful from "../../../config/contentful";
import { ReactComponent as SearchGlass } from "../../../assets/icons/001-search.svg";
import { ReactComponent as TedIcon } from "../../../assets/icons/ted.svg";
import commonColor from "../../../theme/commonColor";
import MainArticle from "../../../components/content/preview/mainArticle";
import SideArticle from "../../content/preview/sideArticle";
import LoaderModal from "../../../components/common/modal/loaderModal";
import Masonry from "react-masonry-css";
import "./styles.css";
import FooterView from "../../common/footerView";
import Swotbutton from "../../common/buttons/swotbutton";
import { useNavigate } from "react-router-dom";
import EmptyNewsMessage from "./emptyNewsMessage";

const NewsFeed = ({ user, showAdvanceTool }) => {
  const navigate = useNavigate();
  const [content, setContent] = useState([]);
  const [filteredContent, setFilteredContent] = useState([]);
  const [hashtagContent, setHashtagContent] = useState([]);

  const [hashtags, setHashtags] = useState([]);
  const [activeHashtagFilters, setActiveHashtagFilters] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [loading, setLoading] = useState(false);

  const [skip, setSkip] = useState([0]);
  const [total, setTotal] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const pageLimit = 30;
  console.log("::Swotnews Rerender::");

  useEffect(() => {
    console.log(":::::EFFECT CALLING::::");
    getContent();
    getHashtags();
  }, []);

  const getContent = async () => {
    setLoadingMore(true);
    const skip = content.length;
    setLoading(true);
    contentful
      .getEntries({
        content_type: "post",
        limit: pageLimit,
        skip: skip,
      })
      .then(function (response) {
        setLoading(false);
        console.log("Swotnews - get content", response);
        if (response) {
          let roleBasedNews = [];

          console.log("User Role ::", user?.accountType.toLowerCase());
          response.items.forEach((element) => {
            let isPublic = element?.fields?.hashtags?.map(
              (hash) => hash?.fields?.name === user?.accountType.toLowerCase()
            );
            if (isPublic && isPublic.includes(true)) {
              console.log(user?.accountType.toLowerCase(), " News:", element);
              roleBasedNews.push(element);
            }
          });

          setContent([...content, ...(roleBasedNews ?? [])]);
          setFilteredContent([...content, ...(roleBasedNews ?? [])]);
          setSkip(content.length);
          setTotal(roleBasedNews.length);
        }
      });
    setLoadingMore(false);
  };

  const getHashtags = async () => {
    const response = await contentful.getEntries({
      content_type: "hashtag",
    });

    let hashtag = [];
    response.items.forEach((element) => {
      if (element?.fields?.name?.charAt(0) === "#") {
        let chkIfExists = hashtag.find(
          (o) => o?.fields?.name === element?.fields?.name
        );
        if (!chkIfExists) hashtag.push(element);
      }
    });

    console.log("NewsFeed - get hashtags", hashtag);
    setHashtags(hashtag);
  };

  const handleSearchTermChange = (searchTerm) => {
    searchTerm = searchTerm.target.value;
    console.log("calling search");
    setSearchText(searchTerm);
    if (searchTerm !== undefined) {
      let matchingContent;
      let filteringContent = content;
      if (searchTerm === "") {
        matchingContent = content;
        setFilteredContent(matchingContent);
        return;
      }

      if (searchTerm.trim().length === 0) {
        return;
      }

      if (activeHashtagFilters.length > 0) {
        filteringContent = hashtagContent;
      }

      matchingContent = filteringContent.filter((item) => {
        return item.fields.title
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });

      console.log("filterContent - matching content", matchingContent);
      setFilteredContent(matchingContent);
    }
  };

  useEffect(() => {
    if (activeHashtagFilters.length > 0) {
      console.log("NewsFeed - useEffect - filtering", activeHashtagFilters);

      const filteredPosts = content.filter((post) =>
        isContentLinkedToHashtags(post, activeHashtagFilters)
      );
      console.log("NewsFeed - useEffect - found matching posts", filteredPosts);
      setFilteredContent(filteredPosts);
      setHashtagContent(filteredPosts);
    } else {
      setFilteredContent(content);
      setHashtagContent(content);
    }
  }, [content, activeHashtagFilters]);

  const isContentLinkedToHashtags = (content, hashIds) => {
    if (!content.fields.hashtags) {
      return false;
    }

    const hashtags = content.fields.hashtags.map((hash) => hash.sys.id);
    // smallArray.some(c => bigArray.includes(c));

    const matching = hashIds.some((id) => hashtags.includes(id));
    return matching;
  };

  const toggleHashtagFilter = (hashtag) => {
    const hashId = hashtag.sys.id;
    console.log("toggleHashtagFilter - hashtag", hashId);

    if (activeHashtagFilters.includes(hashId)) {
      // remove it
      const newFilters = activeHashtagFilters.filter(
        (hashfilter) => hashfilter !== hashId
      );
      setActiveHashtagFilters(newFilters);
    } else {
      const newFilters = [...activeHashtagFilters, hashId];
      setActiveHashtagFilters(newFilters);
    }
  };

  const breakpointColumnsObj = {
    default: 5,
    1900: 4,
    1450: 3,
    1100: 2,
    768: 1,
  };

  return (
    <div className="h-full">
      <div className="xl:px-5  w-full h-auto flex flex-col justify-start  overflow-y-scroll">
        <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 lg:justify-between lg:p-4">
          <div
            className="flex flex-col justify-start items-center w-full lg:w-1/2 
        xl:w-1/3 lg:pr-2"
          >
            {/* <div className="bg-white rounded-2xl shadow-2xl w-full h-40 pt-2 lg:h-full lg:pt-0 flex flex-col"> */}
            <div className="bg-white rounded-2xl darker_shadow w-full pt-2 lg:h-full lg:pt-0 flex flex-col">
              <div className="w-full p-2 flex">
                <div
                  className=" w-full flex justify-between"
                  style={{
                    height: "3rem",
                    backgroundColor: "rgba(64, 114, 136, 0.35)",
                    borderRadius: "0.7rem",
                    //zIndex: 1002,
                  }}
                >
                  <input 
                    className=" h-full appearance-none focus:outline-none py-1 px-2"
                    style={{
                      //color: "white",
                      backgroundColor: "transparent",
                      zIndex: 1002,
                      width: "87%",
                      border: 0,
                    }}
                    id="search"
                    name="search"
                    type="text"
                    placeholder={"Search"}
                    //onBlur={() => setIsSearchActive(false)}
                    // value={""}
                    onChange={handleSearchTermChange}
                  />
                  <button
                    className="rounded-xl p-2 focus:outline-none flex justify-center items-center"
                    style={{
                      backgroundColor: commonColor.deepGreen,

                      zIndex: 1010,
                      aspectRatio: "1 / 1",
                    }}
                  >
                    <SearchGlass
                      style={{
                        width: "1.5rem",
                        height: "1.5rem",
                        overflow: "hidden",
                        fill: "white",
                      }}
                    />
                  </button>
                </div>

                <button
                  className="rounded-xl p-2 focus:outline-none flex justify-center items-center ml-4"
                  onClick={() => {
                    navigate("/ted-search");
                  }}
                  style={{
                    backgroundColor: commonColor.deepGreen,
                    zIndex: 1010,
                    aspectRatio: "1 / 1",
                  }}
                >
                  <TedIcon
                    style={{
                      width: "2rem",
                      height: "2rem",
                      overflow: "hidden",
                    }}
                  />
                </button>
              </div>
              <div className="px-2 flex flex-wrap">
                {hashtags
                  .filter(
                    (h) =>
                      (h.fields.name && h.fields.name !== "") ||
                      (Array.isArray(h.fields.categories) &&
                        h.fields.categories.length > 0 &&
                        h.fields.categories[0].fields.name &&
                        h.fields.categories[0].fields.name !== "")
                  )
                  .slice(0, 6)
                  .map((hash) => (
                    <button
                      className="px-2 py-1 text-xs rounded-xl focus:outline-none mr-1 mb-1"
                      style={{
                        borderWidth: 1,
                        borderColor: commonColor.deepGreen,
                        color: `${activeHashtagFilters.includes(hash.sys.id)
                            ? "white"
                            : commonColor.deepGreen
                          }`,
                        backgroundColor: `${activeHashtagFilters.includes(hash.sys.id)
                            ? commonColor.deepGreen
                            : "white"
                          }`,
                      }}
                      onClick={() => toggleHashtagFilter(hash)}
                    >
                      {hash.fields.name
                        ? hash.fields.name
                        : hash.fields.categories[0].fields.name}
                    </button>
                  ))}
                {showAdvanceTool && (
                  <Swotbutton
                    style={{
                      marginBottom: "4px",
                    }}
                    text="Advanced Tool"
                    onClick={() => {
                      navigate("/advance-tools");
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          {filteredContent.length > 0 && (
            <>
              <div className="hidden md:flex w-full lg:w-1/2 flex-grow flex justify-center p-0 lg:my-0">
                <MainArticle item={filteredContent[0]} />
              </div>
              <div className="md:hidden w-full  flex justify-center p-0">
                <SideArticle item={filteredContent[0]} index={0} />
              </div>
            </>
          )}
        </div>

        <div className="w-full lg:px-4">
          {filteredContent.length <= 0 && !loading ? (
            <div className="w-full flex flex-col justify-start items-center pt-8 pb-24 ">
              <div className="rounded overflow-hidden shadow-lg bg-white w-full max-w-screen-sm flex flex-col justify-start self-center" style={{
                boxShadow: `0 10px 25px 0 rgba(0, 0, 0, 0.6)`,
                borderRadius: 25,
              }}>
                {/* No result found. Change your filters. */}
                <EmptyNewsMessage
                  hashTags={hashtags}
                  searchTopics={activeHashtagFilters}
                  searchText={searchText}
                />
              </div>
            </div>
          ) : (
            <div
              className="pt-5 overflow-y-scroll float-right flex justify-end xl:justify-center"
              style={{
                width: "100%",
              }}
            >
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid pt-4"
                columnClassName="my-masonry-grid_column"
              >
                {filteredContent.length > 1 &&
                  filteredContent.slice(1).map((item, index) => (
                    <div className="w-full py-2">
                      <SideArticle item={item} index={index} />
                    </div>
                  ))}
              </Masonry>
            </div>
          )}
        </div>
        <LoaderModal isOpen={loading} />
      </div>

      <div className="w-full absolute bottom-0"></div>
      <FooterView />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NewsFeed);
