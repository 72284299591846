import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import {getFirestore, doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase, ref, set, onValue, onDisconnect, serverTimestamp as dbServerTimestamp, query } from "firebase/database";
import { CURRENT_RELEASE_MODE, ReleaseMode } from "./constants";


const prodConfig = {
  apiKey: "AIzaSyA2EKW5XoCDHORNFy_U8e9DCaeUdIH_NV0",
  authDomain: "swotknot.firebaseapp.com",
  databaseURL: "https://swotknot.firebaseio.com",
  projectId: "swotknot",
  storageBucket: "swotknot.appspot.com",
  messagingSenderId: "333730604360",
  appId: "1:333730604360:web:6e16876f9f0fcbdab97f7d",
  measurementId: "G-VMGXBT0DNZ",
};

const devConfig = {
  apiKey: "AIzaSyDFZO6X2-rBzRgKTzEageApu3R5Rj1JxkA",
  authDomain: "swotknot-dev.firebaseapp.com",
  databaseURL: "https://swotknot-dev.firebaseio.com",
  projectId: "swotknot-dev",
  storageBucket: "swotknot-dev.appspot.com",
  messagingSenderId: "1083298584752",
  appId: "1:1083298584752:web:cda39aad53c1c74cee4375",
  measurementId: "G-3XTXRFDDDF",
};

const qaConfig = {
  apiKey: "AIzaSyCK5y9Yf0tTcXPe75FuyRycArq8A21i-tI",
  authDomain: "swotknot-qa.firebaseapp.com",
  databaseURL: "https://swotknot-qa.firebaseio.com",
  projectId: "swotknot-qa",
  storageBucket: "swotknot-qa.appspot.com",
  messagingSenderId: "36997783237",
  appId: "1:36997783237:web:a7cf7cb9b8ddb0a4badaac",
  measurementId: "G-LY50P8LSN2",
};

const env = CURRENT_RELEASE_MODE;
let firebaseConfig = devConfig;

switch (env) {
  case "dev":
    firebaseConfig = devConfig;
    break;
  case "qa":
    firebaseConfig = qaConfig;
    break;
  case "prod":
    firebaseConfig = prodConfig;
    break;
  default:
    break;
}

const app = initializeApp(firebaseConfig);
// 1-authentication
// 2-Firestore
// 3-Storage

export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const database = getDatabase(app);
export const storage = getStorage(app);

console.log("FIREBASE INITIALISED - ", auth);

// Google SignIn
// export const googleAuth = new getAuth(app).GoogleAuthProvider();
export const googleAuth = new GoogleAuthProvider();

// export const signInWithGoogle = (callback) => {
//   auth.signInWithPopup(googleAuth)
//     .then(result => {
//       var user = result.user;
//       console.log("signInWithGoogle", user)
//       callback && callback(user);
//     })
//     .catch(function (error) {
//       // Handle Errors here.

//       console.error("signInWithGoogle ERROR", error)
//     });
// };

// Facebook SignIn
 const facebookAuth = new FacebookAuthProvider();
 facebookAuth.addScope('user_birthday');
 facebookAuth.addScope('email');
 export {facebookAuth};
// export const signInWithFacebook = (callback) => {
//   auth.signInWithPopup(facebookAuth)
//     .then(function (result) {
//       // This gives you a Facebook Access Token. You can use it to access the Facebook API.
//       var token = result.credential.accessToken;
//       // The signed-in user info.
//       var user = result.user;
//       console.log("signInWithFacebook", user)
//       callback && callback(user);
//       // ...
//     }).catch(function (error) {
//       // Handle Errors here.
//       // var errorCode = error.code;
//       // var errorMessage = error.message;
//       // // The email of the user's account used.
//       // var email = error.email;
//       // // The firebase.auth.AuthCredential type that was used.
//       // var credential = error.credential;
//       // ...
//       console.error("signInWithFacebook ERROR", error)
//     });
// };

export const generateUserDocument = async (user, additionalData) => {
  if (!user) return;


  const userRef = doc(firestore, `users/${user.uid}`);
  const snapshot = await getDoc(userRef);

  if (!snapshot.exists()) {
    try {
      await setDoc(userRef, { ...user, ...additionalData });
    } catch (error) {
      console.error("Error creating user document", error);
    }
  }
  return await getUserDocument(user.uid);
};

const getUserDocument = async (uid) => {
  if (!uid) return null;
  try {
      const userDocument = await getDoc(doc(firestore, `users/${uid}`));
    return {
      uid,
      ...userDocument.data(),
    };
  } catch (error) {
    console.error("Error fetching user", error);
  }
};


export const manageUserStatus = async () => {
  var uid = auth.currentUser?.uid;
  var userStatusFirestoreRef = doc(firestore, '/status/' + uid);
  var userStatusDatabaseRef = ref(database, '/status/' + uid);

  var isOfflineForFirestore = {
    state: 'offline',
    last_changed: serverTimestamp(),
  };

  var isOnlineForFirestore = {
    state: 'online',
    last_changed: serverTimestamp(),
  };

  var isOfflineForDatabase = {
    state: 'offline',
    last_changed: dbServerTimestamp(),
  };

  var isOnlineForDatabase = {
    state: 'online',
    last_changed: dbServerTimestamp(),
  };

  onValue(query(ref(database, '.info/connected')), (snapshot) => {
    if (snapshot.val() === false) {
      setDoc(userStatusFirestoreRef, isOfflineForFirestore);
      return;
    };

    onDisconnect(userStatusDatabaseRef).set(isOfflineForDatabase).then(function () {
      set(userStatusDatabaseRef, isOnlineForDatabase);
      setDoc(userStatusFirestoreRef, isOnlineForFirestore);
    });
  });
};

export const setOffline = async () => {
  var uid = auth.currentUser.uid;
  
  var userStatusFirestoreRef = doc(firestore, '/status/' + uid);
  var userStatusDatabaseRef = ref(database, '/status/' + uid);

  var isOfflineForFirestore = {
    state: 'offline',
    last_changed: serverTimestamp(),
  };

  var isOfflineForDatabase = {
    state: 'offline',
    last_changed: dbServerTimestamp(),
  };

  set(userStatusDatabaseRef, isOfflineForDatabase);
  setDoc(userStatusFirestoreRef, isOfflineForFirestore);
};
