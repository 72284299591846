import React from "react";
import Circle from "./circle";
import commonColor from "../../theme/commonColor";

const DotIndicator = (props) => {
  const greenColor = commonColor.deepGreen
  const yellowColor = "#f3831a"
  const redColor = "#d13f1a"
  const grayColor = "#a0adb9"
  

  const currentIndex = props.currentIndex ?? 0;
  const activeColor = props.activeColor ?? greenColor;
  const passiveColor = props.passiveColor ?? grayColor;
  const defaultRadius = props.radius ?? 15;
  const activeRadius = props.activeRadius ?? props.radius ?? 20;
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex items-center">
        {[...Array(props.count ?? 0)].map((_, i) =>
          currentIndex === i ? (
            <Circle key={i} bgColor={activeColor} radius={activeRadius} />
          ) : (
            <Circle key={i} bgColor={passiveColor} radius={defaultRadius}/>
          )
        )}
      </div>
    </div>
  );
};

export default DotIndicator;
