import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MainPageWithMenu from "../../components/common/MainPageTemplate/mainPageWithMenu";
import BetatesterDashboard from "../../components/admin/ambassadors/AmbassadorsDashboard";
import { useNavigate } from "react-router-dom";

const AmbassadorsPage = ({ user }) => {
    console.log("Ambassadors Page", user);

    let navigate = useNavigate();
    useEffect(() => {
        if (user.accountType != "SwotAdmin") {
            navigate("/dashboard");
        }
    }, []);



    return (
        <MainPageWithMenu user={user} menuSelected={"ambassadors"}>
            <BetatesterDashboard />
        </MainPageWithMenu>
    );
};
const mapStateToProps = (state) => ({
    user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AmbassadorsPage);
