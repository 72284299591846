import React from 'react'
import facebookIcon from './facebook-icon.png'

const FacebookButton = ({ onClick = () => { } }) => {
  return <button
    className='flex rounded-md w-full justify-center items-center p-2 h-12 text-white'
    onClick={onClick}
    style={style}>
    <div className='w-8 p-1'><img src={facebookIcon} alt='facebook' /></div>
    <div className='pl-2'>Sign in with Facebook</div>
  </button>
}

const style = {backgroundColor: '#4267B2'}

export default FacebookButton