import React, { useState } from "react";
import {
  signInWithGoogle,
  signInWithFacebook,
} from "../../../services/authentication";
import { fetchCurrentUser } from "../../../services/userManagement";
import UserActions from "../../../redux/user";
import { connect } from "react-redux";
import { signUpUser, updateUser } from "../../../services/userManagement";
import commonColor from "../../../theme/commonColor";
import { ReactComponent as Google } from "../../../assets/icons/008-google.svg";
import { ReactComponent as Facebook } from "../../../assets/icons/007-facebook.svg";
import { ReactComponent as EmailIcon } from "../../../assets/icons/010-email.svg";
import GoogleButton from "../../authentication/buttons/googleButton";
import FacebookButton from "../../authentication/buttons/facebookButton";

const SignUp = ({ user, saveUser, currentStep, next, setCanMoveOn }) => {
  const toNextScreen = async (inputUser) => {
    const signupUser = inputUser ? inputUser : user;
    console.log("toNextScreen", signupUser);
    if (signupUser) {
      const userSnapshot = await fetchCurrentUser();
      const fetchedUser = userSnapshot.data();
      let onBoardingUser;
      console.log("toNextScreen - user fetched: ", fetchedUser);

      if (fetchedUser) {
        onBoardingUser = { status: "pending", ...fetchedUser };
      } else {
        onBoardingUser = {
          username: signupUser.displayName,
          fullname: signupUser.displayName,
          photoUrl: signupUser.photoURL,
          email: signupUser.email,
          status: "pending",
        };
      }

      if (!fetchedUser) {
        const newUser = await signUpUser(signupUser, saveUser);
      } else {
        await updateUser(onBoardingUser);
        saveUser(onBoardingUser);
      }

      setCanMoveOn("signUp", true);
    }
    //saveUser(user);
    next();
  };

  if (currentStep !== 2) {
    return null;
  }

  // if (user) {
  //   next()
  // }

  return (
    <div className=" w-full h-full flex-col flex lg:pb-2 justify-around items-center">
      <div className="w-full flex justify-center">
        <p className="text-center italic">How will you sign in?</p>
      </div>
      <div className="lg:w-1/2 w-3/4 flex flex-col justify-center py-2 flex-wrap">
        <div className="w-full pb-4">
          <GoogleButton onClick={() => signInWithGoogle(toNextScreen)} />
        </div>

        <div className="w-full">
          <FacebookButton onClick={() => signInWithFacebook(toNextScreen)} />
        </div>

        <div className="w-full" style={{ marginTop: "15px" }}>
          <button
            className="flex rounded-md w-full justify-center items-center p-2 h-12 text-white"
            style={{
              backgroundColor: commonColor.sourCherry,
              //gridArea: `3 / 4 / 4 / 22`
            }}
            onClick={() => toNextScreen()}
          >
            <div className="w-8 p-1">
              <EmailIcon
                className="h-12 w-12 rounded-full"
                style={{
                  fill: "white",
                  width: "1.75rem",
                  height: "1.5rem",
                  //stroke: "white",
                  backgroundColor: "transparent",
                }}
              />
            </div>
            <div className="pl-2">Sign in with Email</div>
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
