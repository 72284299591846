import React, { useState } from "react";

import "./styles.css";
import LandingLayout from "../../components/landing/landingLayout";
import BetaSignupModal from "./betaSignupModal";
import LoaderModal from "../../components/common/modal/loaderModal";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import sideLogo from "../../assets/icons/knot.svg";
import slideImage1 from "../../assets/images/SlideShowImages/whatIsSK.png";
import slideImage2 from "../../assets/images/SlideShowImages/Learning.png";
import slideImage3 from "../../assets/images/SlideShowImages/toolbox.png";
import slideImage4 from "../../assets/images/SlideShowImages/Remote.png";
import slideImage5 from "../../assets/images/SlideShowImages/WholeSect.png";
import slideImage6 from "../../assets/images/SlideShowImages/wholeSection.png";

const slideText = [
  {
    title: "What is l3arn?",
    subtitle: "Simplified, unified",
    paragraph:
      "l3arn is a powerful toolbox for educators that helps increase teaching efficiency and student engagement. Its integration with ChatGPT offers personalized recommendations for students to find a tutor that matches their unique learning style. ChatGPT can provide study support, clarifying concepts and offering suggestions. l3arn also optimizes remote learning and online tutor booking, with a user-friendly interface for booking sessions with qualified tutors.",
    src: slideImage1,
  },

  {
    title: "Learning and Teaching Affinities",
    subtitle: " Finding many possibilities to improve outcomes",
    paragraph:
      "Your affinity for learning and teaching comes in many different flavors.  l3arn helps you identify the most complementary learning and teaching methodology that works for you and the subject. This allows l3arn to assist in creating self-awareness and maximize learning and teaching abilities.",
    src: slideImage2,
  },

  {
    title: "The Toolbox",
    subtitle: "One tool to rule them all",
    paragraph:
      "With just one login, you have access to over 18 educational tools (and more on the way) including traditional tools like scheduling, assignments, video conferencing and messaging as well as innovative and creative tools that you may not have used before.",
    paragraph1:
      "No certification or concerns about being a tech guru are required to use the tools. Keep your students engaged and leave the power points behind. All the tools are included; no extra fee or subscription is required when you log on!",

    src: slideImage3,
  },

  {
    title: "Enhancing Remote Education",
    subtitle: "No limits to teaching and learning",
    paragraph:
      "Our platform maximizes the benefits to educators and learners, including integrated conferencing and whiteboard capabilities, designed to function at even the lowest internet connections. It offers more than screen sharing, including on-the-spot quizzes, whiteboard collaboration and notebook sharing.",
    paragraph1:
      "  With our no added cost tools, you can create your content, own, manage and eventually sell it—a space where (soon) you will have access to curated and collaborative content.",
    src: slideImage4,
  },
  {
    title: "Whole Person Education",
    subtitle: " Learning, living and being",
    paragraph:
      "We believe learning and teaching happen best when we are socially, emotionally and mentally supported. We recognize that 1 in 4 students experience depression or anxiety and want to help students and educators in their education and teaching and their mental, social and emotional health.",
    paragraph1:
      "We partnered with kokocare, an organization that seeks to prevent suicide and make mental health assistance accessible to everyone and has succeeded with other large online content providers. We are actively seeking partnerships and great ideas to create the best possible online learning and teaching experience for the whole person.",
    src: slideImage5,
  },

  {
    title: "Engage the Change with us",
    subtitle: "Embrace the learner and teacher within",
    paragraph:
      "Remote and online education and teaching are progressing, and we want to be the tip of the spear on this journey. We want to experience lifelong development. We see artificial intelligence, machine learning, augmented reality, virtual reality, microlearning, NFTs, smart contracts, and on and on. We embrace these trends and have created a solid foundation within our platform to expand and adapt to these trends. We invite you on a journey of lifelong learning as well. ",
    paragraph1:
      "We want you as a combination of teacher and student to constantly grow, expand and stay informed with the latest trends in e-education. We hope to offer courses to meet the advancement in technology and support a wide range of skill-building regardless of your knowledge of technology; whether someone is a beginner or has advanced skills, these courses will keep you informed and competitive.",
    src: slideImage6,
  },
];
const fadeProperties = {
  duration: 8000,
  pauseOnHover: true,
  autoplay: true,
  easing: "ease-out",
  canSwipe: true,
  indicators: true,
};

const Slideshow = () => {
  //width < 600 ? this : that
  return (
    <div className="sm:pl-16 sm:pr-10 max-w-full">
      <div className="slide-container m-6 sm:w-full w-11/12">
        <Slide {...fadeProperties}>
          {slideText.map((slideSources, index) => (
            <div
              key={index}
              className="text-gray-700 each-slide flex flex-col lg:flex-row items-center md:items-center justify-center h-full"
            >
              <div className=" md:flex hidden lg:flex-col flex-row justify-between items-end sideInfo w-10/12 h-fit lg:w-24 border-b-4 lg:border-b-0 lg:border-r-4 border-black">
                <div className="sideInfo_logoSlide sm:pb-2 sm:pl-2 pr-2 pt-2">
                  <img
                    src={sideLogo}
                    alt="sideLogo"
                    className="w-10 hidden md:block"
                  />
                </div>

                <div className="sideInfo_texts mr-4 w-8">
                  <p className="text-2xl font-semibold w-max">
                    Education - Unboxed
                  </p>
                </div>

                <div className="sideInfo_numbering pr-4 md:block hidden">
                  <p className="text-3xl font-bold tracking-wide">
                    0{index + 1}
                  </p>
                </div>
              </div>

              <div className="textAndImage w-10/12 flex flex-1 flex-col lg:flex-row">
                <div className="textSlide flex justify-center w-full lg:w-8/12 xl:w-6/12 flex-col pl-2 lg:pl-0">
                  <div className="pl-2 lg:pl-8">
                    <p className="text-2xl md:text-4xl font-semibold pt-4 uppercase">
                      {slideSources.title}
                    </p>
                    <p className="italic text-lg md:text-2xl font-medium pt-0 lg:pt-2">
                      {slideSources.subtitle}
                    </p>
                  </div>

                  <p className="text-md md:text-base font-medium pb-4 lg:pb-16 pl-2 lg:pl-8 pt-4 w-full md:w-10/12">
                    {slideSources.paragraph}
                    <br />
                    <br />
                    {slideSources.paragraph1
                      ? `${slideSources.paragraph1}`
                      : null}
                  </p>
                </div>

                <div className="imageSlide lg:w-1/3 w-full">
                  <img
                    key={index}
                    className="slideShowImg w-full pl-2 lg:pl-2 object-contain"
                    src={slideSources.src}
                    alt={slideSources.src}
                  />
                </div>
              </div>
            </div>
          ))}
        </Slide>
      </div>
    </div>
  );
};

export default function EducationEvo() {
  const [showFormModal, setShowFormModal] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <LandingLayout landingTitle="">
      <Slideshow />
      <BetaSignupModal
        isOpen={!loading && showFormModal}
        onRequestClose={() => {
          setShowFormModal(false);
        }}
        showLoader={(status) => {
          setLoading(status);
        }}
      />
      {/* <BetaSignup onSubmit={onSubmit} /> */}
      {/* </BetaSignup> */}
      <LoaderModal
        isOpen={loading}
        onRequestClose={() => {
          setLoading(false);
        }}
      />
    </LandingLayout>
  );
}
