import {firestore} from '../config/firebase';
import { collection, doc , deleteDoc, setDoc} from 'firebase/firestore';
export const addReminder = async (userId, data, reminderId) => {
    const userReminderRef = doc(collection(firestore, `user-reminders/${userId}/calendar-events`), reminderId);
    return await setDoc(userReminderRef, data)
}


export const deleteReminder = async (userId, reminderId) => {
    const userReminderRef = doc(collection(firestore, `user-reminders/${userId}/calendar-events`),reminderId);
    return await deleteDoc(userReminderRef)
}
