import React, { useEffect, useState } from "react";
import knotSm from "../../assets/images/learn-small.png";
import { ReactComponent as InboxIcon } from "../../assets/images/inbox.svg";
import { getStudentsForTutor } from "../../services/api";
import { connect } from "react-redux";
import commonColor from "../../theme/commonColor";
import { NavigateFunction, useNavigate } from "react-router-dom";
import "./studentList.css";
import NewMessageComponent from "../../chat/components/NewMessageComponent";
import Modal from "../common/modal/modal";
import ChatUserModel from "../../chat/models/chatUser";

const StudentList = ({ user, course, setStudentsTotal }) => {
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);
  const [sendMessageToTutor, setSendMessageToTutor] = useState();

  useEffect(() => {
    const getStudents = async () => {
      const response = await getStudentsForTutor(user.uid);
      if (response && response.ok) {
        const fetchedStudents = response.data.students;
        console.log("StudentList - useEffect - getStudents", fetchedStudents);
        setStudentsTotal && setStudentsTotal(fetchedStudents.length);
        if (course) {
          const courseStudents = fetchedStudents.filter((std) =>
            std.courses.includes(course)
          );
          setStudents(courseStudents);
        } else {
          setStudents(fetchedStudents);
        }
      }
    };

    getStudents();
  }, []);

  useEffect(() => {}, [students]);

  const renderStudents = () => {
    return students.map((std, idx) => (
      <button
        key={idx}
        className="w-full bg-transparent flex justify-start focus:outline-none min-h-16 pt-2 pb-2 px-2"
      >
        <div className="h-full flex justify-center items-center  studentlist-image">
          <img
            src={std.photoUrl && std.photoUrl != "null" ? std.photoUrl : knotSm}
            alt="studentPhoto"
            className="rounded-full shadow-lg h-12 w-12 bg-white"
          />
        </div>

        <div className="h-full studentlist-detail md:pl-8 lg:pl-2 xl:pl-8">
          <div
            className={`w-full h-full flex justify-between items-center text-sm px-3 ${
              course ? " bg-gray-400" : "shadow-lg rounded-2xl bg-white"
            }`}
          >
            <div className="mr-8 text-ellipsis min-w-2/4">
              {std.firstName} {std.lastName}
            </div>
            {/* <div className=" w-1/3  flex justify-start">{std.lastName}</div> */}
            <div className="text-ellipsis min-w-1/4 text-right">
              <div className="flex justify-end items-center">
                {" "}
                <span className="text-ellipsis">
                  {course
                    ? course
                    : std.courses && std.courses.length > 0
                    ? std.courses[0]
                    : ""}{" "}
                </span>
                <InboxIcon
                  fill={commonColor.deepGreen}
                  stroke={commonColor.deepPink}
                  className="cursor-pointer min-w-8 w-8 h-8 mx-1"
                  onClick={() => {
                    // navigate("/profile/messages");
                    sendMessage(std);
                  }}
                />
              </div>
            </div>
          </div>
          {/* )} */}
        </div>
      </button>
    ));
  };

  const sendMessage = (student) => {
    if (student.firebaseId) {
      const sendTo = new ChatUserModel({
        uid: student.firebaseId,
        firstName: student?.firstName,
        lastName: student?.lastName,
        photoUrl: student?.photoUrl,
        city: student?.cityName,
      });
      setSendMessageToTutor(sendTo);
    }
  };

  const sendMessageModelWidget = () => {
    return (
      <Modal
        isOpen={sendMessageToTutor !== null && sendMessageToTutor != undefined}
        // bgColor="bg-trasparent"
        onRequestClose={() => {
          setSendMessageToTutor(null);
        }}
      >
        <div className="w-90v md:w-70v lg:w-50v max-h-80v text-center">
          {sendMessageToTutor && (
            <NewMessageComponent
              myUserId={user.uid}
              toUserDetails={sendMessageToTutor}
              close={() => {
                setSendMessageToTutor(null);
              }}
            />
          )}
        </div>
      </Modal>
    );
  };

  return (
    <div className=" h-full w-full flex flex-col justify-start">
      {students.length === 0 ? (
        <div className=" w-full h-full bg-white rounded-3xl p-4">
          <div className="w-full text-sm xl:h-1/2">
            No students are yet part of your courses. Don't worry, once you have
            set up your courses students will start recognising your profile.{" "}
            <br />
            If the process seems to be too slow, perhaps add more information to
            your course and we are sure you will be discovered soon.
          </div>
        </div>
      ) : (
        <div className="w-full h-full flex flex-col justify-start overflow-y-scroll max-h-96 xl:max-h-screen xl:h-screen-ios xl:min-h-screen-ios">
          {renderStudents()}
          {sendMessageModelWidget()}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(StudentList);
