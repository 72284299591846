import React, { useEffect, useState } from "react";
import moment from "moment";


const CourseRecording = ({ recordings, noItemsText, courseShortName,openRecordingHandler }) => {

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
      };
    
    return (
        <>
            <div className="h-full w-full flex flex-col justify-start">
                <div className="w-full h-full flex flex-col flex-grow items-start justify-start p-4 overflow-y-scroll">
                    {recordings.length === 0
                        ?
                        <div className="w-full h-full flex justify-center items-center">
                            {noItemsText}
                        </div>
                        : recordings.map((item, index) => {
                            return (
                                <button key={index} onClick={() => openInNewTab(item.url)}>
                                {item.name} - {courseShortName} - {moment.unix(item.startTime.toString().substring(0, 10)).format("MM-DD-YYYY HH:MM")}
                              </button>
                            )
                        })
                    }
                </div>
                
            </div>

        </>
    );
};

export default CourseRecording;
