import React, { useEffect, useState } from "react";
import { getRefundRequests } from "../../../services/api";
import RefundListItem from "./refundListItem";
import commonColor from "../../../theme/commonColor";
import FooterView from "../../common/footerView";

const RefundsDashboard = () => {
  const [refundList, setRefundList] = useState([]);

  useEffect(() => {
    getRefunds();
  }, []);

  const getRefunds = async () => {
    const response = await getRefundRequests();
    if (response && response.data && response.data.status === "SUCCESS") {
      const fetchedItems = response.data.data;
      console.log("refundsDashboard - useEffect - getRefunds", fetchedItems);
      // const filteredFetchedItems = fetchedItems.filter((t) => t.approved === null);
      setRefundList(fetchedItems);
    }
  };

  return (
    <div
      className="w-full rounded-2xl p-4 h-full
    flex flex-col"
    >
      <div className="flex justify-between items-middle">
        <div className="text-white">Refund Dashboard</div>
        <button
          className="border-white p-4 border-2 text-white rounded-2xl focus:outline-none"
          onClick={() => {
            getRefunds();
          }}
        >
          Refresh
        </button>
      </div>

      <div className="p-4 flex flex-col flex-grow">
        <div className="text-white">pending refund for approval</div>

        <div className="">
          {refundList.map((refundItem, index) => (
            <RefundListItem key={refundItem.transaction_id ?? index} refundItem={refundItem} />
          ))}
        </div>
        <FooterView />
      </div>
    </div>
  );
};

export default RefundsDashboard;