import { firestore } from '../config/firebase';
import { collection, doc, getDoc, setDoc } from 'firebase/firestore';
export const updateRefreshCalendar = async (userId, flag) => {

    let studentNotificationsRef = doc(collection(firestore, 'user-notifications'), userId);
    var getStudentDoc = await getDoc(studentNotificationsRef)
    if (getStudentDoc.data() === undefined) {
        let flags = {
            refreshCalendar: flag
        }
        return await setDoc(doc(collection(firestore, 'user-notifications'), userId), flags, { merge: true });
    } else {
        console.log(getStudentDoc.data())
        let updatedObj = getStudentDoc.data()
        console.log('Updating user-notifications properties in firestore for user with firebaseId = ' + userId)
        updatedObj["refreshCalendar"] = flag;
        return await setDoc(doc(collection(firestore, 'user-notifications'), userId), { ...updatedObj }, { merge: true });
    }
}