import React from "react";
import BlogFeed from "../blog/blogFeed";
import LandingLayout from "../../components/landing/landingLayout";
import learnlogo from "../../assets/images/learn-logo-colour.svg";
import Button from "../newLanding/components/landingpageButton";
import {
  Link,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { baseURL } from "../../config/apiConstants";

const BlogPosts = () => {
  //TODO: read params. If user is loggedIn, show the loggedIn version of blog page
  const navigate = useNavigate();
  const location = useLocation();
  const received = useLocation().state;

  return (
    <div className="">
      <div class="text-gray-600 body-font border-b-2 border-b-pink-800 w-11/12 mx-auto">
        <div class="flex flex-wrap p-5 flex-col md:flex-row items-center justify-between w-full cursor-pointer">
          <a
            onClick={() => {
              navigate("/");
            }}
          >
            <img className="w-40 h-auto" src={learnlogo} alt="l3arn-logo" />
          </a>
          <div className="text-center w-fit">
            <p className="text-gray-500 text-3xl text-center">Our Blogs </p>
            <a
              className="text-gray-400 text-base hover:text-gray-700 cursor-pointer"
              onClick={() => {
                // if(received == "landingpage") {
                //   navigate(-1, {state: "blogs"});
                // } else {
                navigate("/", { state: "blogs" });
                // }
              }}
            >
              Back to blog preview
            </a>
          </div>

          <Button link="/sign-in" />
        </div>
      </div>

      {/* actual blogs */}
      <div className="w-full h-full flex flex-wrap justify-around sm:pl-24 sm:pr-10 pl-1 ">
        <BlogFeed />
      </div>
    </div>
  );
};

export default BlogPosts;
