import { takeLatest, all } from "redux-saga/effects";
import API from "../services/api";

import DebugConfig from "../config/debugConfig";

/* ------------- Types ------------- */

import { UserTypes } from "../redux/user";
import { AuthUserTypes } from "../redux/authUser";
import { CalendarTypes } from "../redux/calendar";
import { NotificationsTypes } from "../redux/notifications";
import { UnreadMessagesTypes } from "../redux/unreadMessages";
import { RemindersTypes } from "../redux/reminders";
import { CalendarRemindersTypes } from "../redux/calendarReminders";

/* ------------- Sagas ------------- */

// import { startup } from './startup';
import { saveUser } from "./user";
import { saveAuthUser } from "./authUser";
import { saveCalendar } from "./calendar";
import { saveNotifications } from "./notifications";
import { saveUnreadMessages } from "./unreadMessages";
import { saveReminders } from "./reminders";
import { saveCalendarReminders } from "./calendarReminders";

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
//const api = DebugConfig.useFixtures ? FixtureAPI : API.create()

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    takeLatest(UserTypes.SAVE_USER_REQUEST, saveUser),
    takeLatest(AuthUserTypes.SAVE_AUTH_USER_REQUEST, saveAuthUser),
    takeLatest(CalendarTypes.SAVE_CALENDAR_REQUEST, saveCalendar),
    takeLatest(NotificationsTypes.SAVE_NOTIFICATIONS_REQUEST, saveNotifications),
    takeLatest(UnreadMessagesTypes.SAVE_UNREAD_MESSAGES_REQUEST, saveUnreadMessages),
    takeLatest(RemindersTypes.SAVE_REMINDERS_REQUEST, saveReminders),
    takeLatest(CalendarRemindersTypes.SAVE_CALENDAR_REMINDERS_REQUEST, saveCalendarReminders),
  ]);
}
