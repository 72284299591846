import React, { useState, useEffect } from 'react';
import { loadertexts } from './loadertexts';
import './textLoader.css';

const TextLoader = () => {
    const [textList, setTextList] = useState([]);
    const [currentText, setCurrentText] = useState('');


    useEffect(() => {
        console.log("loadertexts:", loadertexts);
        const generateRandomText = () => {
            const randomIndex = Math.floor(Math.random() * loadertexts.length);
            setCurrentText(loadertexts[randomIndex]);
        };

        generateRandomText();

        const timer = setInterval(generateRandomText, 3000); // Delay in milliseconds

        return () => {
            clearInterval(timer);
        };
    }, [loadertexts]);


    return (
        <div className="text-loader">
            <h1>{currentText}</h1>
        </div>
    );
};

export default TextLoader;