import React, { useEffect, useState } from "react";
import SwotknotLogo from "../../assets/images/learn-logo-colour.svg";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import UserActions from "../../redux/user";
import Swotbutton from "../../components/common/buttons/swotbutton";
import { auth } from "../../config/firebase";
import { signOut } from "firebase/auth";
import { resendVerificationEmail } from "../../services/api";
import LoaderButton from "../../components/common/buttons/loaderbutton";
import commonColor from "../../theme/commonColor";

function EmailActivationPrompt(props) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [confirmationSent, setConfirmationSent] = useState(false);
  const [timer, setTimer] = useState(0); // State to track countdown timer

  useEffect(() => {
    if (props.user && props.user.status === "active") {
      navigate("/dashboard");
    } else {
      resendConfirmationEmailOnLoad(); // Call resendVerificationEmail on load
    }
  }, [props.user]);

  // Function to handle resending the verification email
  const resendConfirmationEmail = async () => {
    const email = props.user.email;
    if (email) {
      setLoading(true);
      const data = await resendVerificationEmail(email);
      setLoading(false);
      if (data && data.status === 200) {
        setConfirmationSent(true);
        startCountdown(30); // Start 30-second countdown
      }
    }
  };

  // Call this on page load to automatically resend the confirmation email
  const resendConfirmationEmailOnLoad = async () => {
    const email = props.user?.email;
    if (email) {
      setLoading(true);
      const data = await resendVerificationEmail(email);
      setLoading(false);
      if (data && data.status === 200) {
        setConfirmationSent(true);
        startCountdown(30); // Start 30-second countdown
      }
    }
  };

  // Function to start the countdown timer
  const startCountdown = (seconds) => {
    setTimer(seconds);
    const countdownInterval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(countdownInterval);
          setConfirmationSent(false); // Re-enable resend button after countdown
        }
        return prevTimer - 1;
      });
    }, 1000); // Update every second
  };

  return (
    <div className="w-full pt-18 flex flex-col">
      <div className="w-full flex flex-col justify-center items-center">
        <div className="py-8">
          <img
            src={SwotknotLogo}
            alt="Logo"
            className="w-40 pt-2 bg-transparent"
          />
        </div>
        <div className="p-6">
          <p className="text-center text-sm">
            {"One more step to complete your subscription to l3arn."}
          </p>
          <p className="text-center text-sm">
            {
              "A confirmation email has been sent to the email account you have chosen to use for l3arn."
            }
          </p>
          <p className="text-center text-sm">
            {
              "Follow the link and start exploring the new way of online education."
            }
          </p>
        </div>
        <div className="py-8">
          <Swotbutton
            className={"px-4"}
            text={"Browse l3arn"}
            onClick={() => {
              props.saveUser(null);
              signOut(auth);
              navigate("/");
            }}
          />
        </div>
        <div className="py-8">
          {confirmationSent ? (
            <Swotbutton
              disabled
              className={"px-4"}
              bgColor={commonColor.deepGreen}
              text={`Resend Verification Email in (${timer}s)`}
            />
          ) : loading ? (
            <LoaderButton bgColor={commonColor.deepGreen} shadow={true} />
          ) : (
            <Swotbutton
              className={"px-4"}
              bgColor={commonColor.deepGreen}
              text={"Resend verification Email"}
              onClick={resendConfirmationEmail}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailActivationPrompt);