import React from 'react'
import ReactSelect from 'react-select'
import commonColor from '../../../theme/commonColor';

const customSelectStyles = (isRadiusUsed) => ({
  option: (base, state) => ({
    ...base,
    ...(state.isSelected && {
      background: commonColor.deepGreen,
    })
  }),
  ...(isRadiusUsed && {
    control: (provided) => ({
      ...provided,
      borderRadius: "1.2rem",
    }),
  })
})

const formatGroupLabel = (data) => (
  <div>
    <span>{data.label}</span>
    <span> {data.options.length}</span>
  </div>
)

const Select = ({
  defaultValue,
  value,
  onChange,
  onInputChange,
  options,
  placeholder,
  className = 'lg:w-full w-full',
  menuPlacement = 'auto',
  closeMenuOnSelect,
  isMulti,
  isFormatGroupLabelUsed,
  isRadiusUsed,
  isClearable,
  isSearchable
}) => {
  return (
    <ReactSelect
      menuPlacement={menuPlacement}
      className={className}
      placeholder={placeholder}
      defaultValue={defaultValue}
      value={value}
      key={value}
      onChange={onChange}
      onInputChange={onInputChange}
      options={options}
      closeMenuOnSelect={closeMenuOnSelect}
      isMulti={isMulti}
      isClearable={isClearable}
      isSearchable={isSearchable}
      formatGroupLabel={isFormatGroupLabelUsed && formatGroupLabel}
      styles={customSelectStyles(isRadiusUsed)}
    />
  )
}

export default Select
