import React from "react";
import { connect } from 'react-redux'
import { getStudentQuiz, postStudentQuiz, getQuizResults } from "../../../services/api"
import commonColor from "../../../theme/commonColor"
import UserActions from "../../../redux/user";
import QuestionStep from "./questionStep"
import Swotbutton from '../../common/buttons/swotbutton'
import Modal from '../../common/modal/modal';
import { ACCOUNT_TYPES } from "../../../config/constants";

class Wizard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentStep: 0,
      user: props.user,
      canMoveOn: false,
      obpDone: false,
      answers: {},
      showModal: false,
      results: []
    }
  }

  componentDidMount = () => {
    console.log('Student Wizard - componentDidMount - user', this.state.user)
    //call for questions
    const getQuiz = async () => {
      // XUhdWPW9SyZdibLzqsldhfQTyXE2
      const response = await getStudentQuiz(this.props.user.uid);
      //const response = await getStudentQuiz("XUhdWPW9SyZdibLzqsldhfQTyXE2");

      if (response && response.ok) {
        const fetchedQuiz = response.data
        console.log('Student Wizard - useEffect - fetchedQuiz', fetchedQuiz?.data)

        //TODO: save in local state
        this.setState({
          quiz: fetchedQuiz?.data
        })

      }
    }

    if (!this.state.user.quizDone) {
      getQuiz()
    }
  }

  setCanMoveOn = (screen, flag) => {
    this.setState({
      [screen]: flag,
      canMoveOn: flag
    })
  }

  saveAnswer = (key, value) => {
    console.log("saveAnswer -", key, value)
    let updAnswers = this.state.answers
    updAnswers[key] = value

    this.setState({
      answers: updAnswers
    })
  }

  handleSubmit = async () => {
    this.setState({
      obpDone: true,
      showModal: true
    })

    let answersArray = [];
    for (const [key, value] of Object.entries(this.state.answers)) {
      answersArray.push({
        "question": key,
        "answers": value
      })
    }

    console.log("handleSubmit - created Array", answersArray)

    // submit api call here
    const result = await postStudentQuiz(this.props.user.uid, { quiz: answersArray })
    console.log("handleSubmit - result", result)

    const response = await getQuizResults(this.props.user.uid);
    //const response = await getTutorQuiz("XUhdWPW9SyZdibLzqsldhfQTyXE2");

    if (response && response.ok) {
      const fetchedResults = response.data
      console.log('Student Wizard - fetchedResults', fetchedResults)

      //TODO: save in local state
      this.setState({
        results: fetchedResults.data.slice(0, 4)
      })
    }
  }

  _next = () => {
    let { currentStep, quiz } = this.state;
    console.log("Wizard - NEXT - current step", currentStep)

    currentStep = (quiz && currentStep >= quiz.length) ? quiz.length : currentStep + 1
    this.setState({
      currentStep: currentStep,
      canMoveOn: false
    })
  }

  _prev = () => {
    let currentStep = this.state.currentStep
    console.log("Wizard - Previous - current step", currentStep)

    currentStep = currentStep <= 0 ? 0 : currentStep - 1
    this.setState({
      currentStep: currentStep,
      canMoveOn: true
    })
  }

  /*
  * the functions for our button
  */
  previousButton() {
    let { currentStep, quiz } = this.state;

    if (currentStep !== 0) {
      return (
        <div className="w-1/2 flex flex-col justify-center items-start">
          <Swotbutton
            text={"Previous"}
            onClick={this._prev}
            disabled={this.state.obpDone}
          />
        </div>
      )
    }
    return (<div className="w-1/2" />);
  }

  nextButton() {
    let { currentStep, quiz } = this.state;

    if (quiz && currentStep < quiz.length - 1) {
      return (
        <div className="w-1/2 flex flex-col justify-center items-end">
          <Swotbutton
            text={"Next"}
            onClick={this._next}
            disabled={!this.state.canMoveOn}
          />
        </div>
      )
    }
    return null;
  }

  doneButton() {
    const { currentStep, quiz } = this.state;

    if (quiz && currentStep === (quiz.length - 1)) {
      return (
        <div className="w-1/2 flex flex-col justify-center items-end">
          <Swotbutton
            text={"Done"}
            onClick={this.handleSubmit}
            disabled={!this.state.canMoveOn || this.state.obpDone}
          />
        </div>
      )
    }
    return null;
  }

  render() {
    const { quiz, currentStep, obpDone, showModal, results } = this.state
 const MoreInfoTextStudent = () => {
      return(
        <div>
          <p className="mt-2 mb-1 italic text-sm">
             *For more information on your results, visit your profile.
          </p>
        </div>
      )
    }
    return (
      <React.Fragment>
        <div className="w-full h-9/10 flex flex-col items-start">
          <Modal
            isOpen={showModal}
            // onAfterOpen={afterOpenModal}
            onRequestClose={() => {
              // save quiz status for user
              const updatedUser = { ...this.state.user, quizDone: true }
              if (this.state.user.accountType === ACCOUNT_TYPES.SWOT_BUDDY) {
                 updatedUser = { ...updatedUser, swotBuddySetup: "personalized" }
              }
              this.props.saveUser(updatedUser)
              this.setState({ showModal: false })
            }}
          // contentLabel="Create new event"
          >
            <div className="w-full h-full flex flex-col space-y-2 justify-around">
              <div className="pb-2 text-center font-bold"> Thank you for doing the quiz, here are the results:</div>
              <div className="w-2/6 self-center bg-black h-0.5" style={{margin: "1.5rem 0px 1rem"}}></div>
              {
                results.length > 0 ?
                  results.map(res => (
                      <div className="py-2">{res.type}: {res.percentage.toFixed(0)}% </div>
                  ))
                  :
                  <div> Calculating...</div>
              }
              {results.length > 0 && 
              <MoreInfoTextStudent/>
              }
              <Swotbutton
                text={"Go to Dashboard"}
                onClick={() => {
                  // save quiz status for user
                  const updatedUser = { ...this.state.user, quizDone: true }
                  this.props.saveUser(updatedUser)
                }}
              />
            </div>
          </Modal>

          <div className="bg-white px-5 w-full h-full flex flex-col justify-between"
            style={{
              boxShadow: `0 10px 25px 0 rgba(0, 0, 0, 0.6)`,
              borderRadius: 25,
              //height: '70%',
            }}
          >

            <div className="w-full flex flex-col flex-grow justify-start items-center overflow-y-scroll">

              {
                (!this.state.user.quizDone && !obpDone) &&
                <div className="w-full flex justify-center">
                  <div className="text-xl pt-2 pb-1">Help us design your learning experience</div>
                </div>
              }

              {
                (!this.state.user.quizDone && !obpDone) ?
                  <div className="w-full flex-grow flex flex-col items-center justify-start">

                    {
                      quiz ? quiz.map((q, index) => (
                        <QuestionStep
                          key={q.questionId}
                          index={index}
                          question={q}
                          currentStep={currentStep}
                          saveAnswer={this.saveAnswer}
                          setCanMoveOn={this.setCanMoveOn}
                        />
                      ))
                        :
                        <div className="h-full flex items-center justify-center"> Getting Ready...</div>
                    }

                  </div> :
                  <div className="h-4/6 w-full flex items-center justify-center">
                    <div className="italic">Thank you for taking the time to answer these questions!</div>
                  </div>
              }
            </div>
            {
              (!this.state.user.quizDone && !obpDone) &&
              <div className="w-full flex flex-row justify-between pt-1 py-6">
                {this.previousButton()}
                {this.nextButton()}
                {this.doneButton()}
              </div>
            }
          </div>
        </div>

      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
})

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) =>
    dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Wizard);

