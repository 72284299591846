import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import UserActions from "../../../../redux/user";
import MainPageWithMenu from "../../../../components/common/MainPageTemplate/mainPageWithMenu";
import CourseSetup from "./courseWizard/courseSetup";
import { useLocation, globalHistory, useNavigate, useParams} from "react-router-dom";

export const EditCourse = ({ user, location }) => {
  const mode = "edit";
  const params = useParams();
  const navigate = useNavigate();

  const [selectedCourseId, setSelectedCourseId] = useState();

  if (params.courseId !== selectedCourseId) {
    setSelectedCourseId(params.courseId);
  }

  const shouldEdit = () => {
    return !(
      !params ||
      !(params.courseId) ||
      !user ||
      !user.uid ||
      !(user.accountType === "Tutor" || user.accountType === "GuestTutor")
    );
  };

  if (!shouldEdit()) {
    navigate("/courses");
  }

  const renderCurrentView = () => {
    return <CourseSetup 
    mode="edit"
    selectedCourseId={params.courseId}/>;
  };

  return (
    // <LoaderOverlay loading={loading}>
    <MainPageWithMenu user={user} menuSelected={"library"}>
      <div
        className=" h-full"
        style={{
          borderRadius: "1.5rem",
          width: "100%",
        }}
      >
        {renderCurrentView()}
      </div>
    </MainPageWithMenu>
    // </LoaderOverlay>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCourse);
