import React from "react";

const LandingTitle = ({ text }) => {
  return (
    <div
      className="w-full text-md mt-2  text-gray-500 text-center lg:text-right align-text-bottom pt-4 px-4 lg:px-8 bottom-0  lg:h-6 h-auto"
      style={{ fontSize: "1.25rem" }}
    >
      {text}
    </div>
  );
};

export default LandingTitle;
