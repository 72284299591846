import React, { useState, useEffect, useReducer } from "react";
import Calendar from "react-calendar";
import moment from "moment";
import Checkbox from "../../../../../components/common/input/checkbox";
import { QuickModal } from "../../../../../components/common/modal/modal";
import commonColor from "../../../../../theme/commonColor";

import { set } from "lodash";
import TimeWidget from "../../../../../components/common/timeWidget";

const CourseStep3 = ({
  user,
  saveProperty,
  currentStep,
  courseDetails,
  mode,
  stateData,
}) => {
  const [openInfoModal, setOpenInfoModal] = useState(false);

  // variables:-------------------------------------------------
  const reducerStateFn = (state, action) => {
    if (action.key) {
      if (action.key === "reset") {
        return action.value;
      }
      const updatedV = { ...state, ...{ [action.key]: action.value } };
      return updatedV;
    }
  };

  const [localState, dispatchReducer] = useReducer(reducerStateFn, {});

  // initialize:--------------------------------------------
  useEffect(() => {
    updateLocalData("reset", stateData);
  }, []);

  useEffect(() => {
    const vd = isDataValid(true);
    saveProperty("step3Completed", vd);
  }, [localState]);

  // functions:------------------------------------------------
  const handleChange = (key, value) => {
    saveProperty(key, value);
    updateLocalData(key, value);
  };

  const updateLocalData = (key, value) => {
    dispatchReducer({ key, value });
  };

  const toTimeString = (hours) => {
    if (hours < 10) return "0" + hours + ":00";

    return hours + ":00";
  };

  const isValidTime = (startTimeArg, endTimeArg) => {
    startTimeArg = startTimeArg || 0;
    endTimeArg = endTimeArg || 0;

    console.log("startTime: ", startTimeArg, " endTime: ", endTimeArg);

    if (startTimeArg == 0 && endTimeArg == 0) {
      saveProperty("errorMessage", "Please set availability time");
      return false;
    } else if (startTimeArg == 0) {
      saveProperty("errorMessage", "Please set availability Start time");
      return false;
    } else if (endTimeArg == 0) {
      saveProperty("errorMessage", "Please set availability End time");
      return false;
    } else if (endTimeArg < startTimeArg) {
      saveProperty(
        "errorMessage",
        "Start time should be greater than End time"
      );
      return false;
    } else if (startTimeArg != 0 && endTimeArg != 0) {
      saveProperty("errorMessage", null);
      return true;
    }
  };

  const isDataValid = (showMessage = true) => {
    const { formattedStartDate, formattedEndDate } = stateData;

    saveProperty("errorMessage", null);

    if (stateData?.addManualSlots) {
      return true;
    } else {
      if (!formattedStartDate || !formattedEndDate) {
        return false;
      }

      if (moment(formattedEndDate) < moment(formattedStartDate)) {
        if (showMessage) {
          saveProperty(
            "errorMessage",
            "End date can't be smaller then start date."
          );
        }
        return false;
      }

      const currFormatedStartDate = moment(new Date()).format("YYYY[-]MM[-]DD");
      if (
        (mode !== "edit" &&
          moment(formattedStartDate) < moment(currFormatedStartDate)) ||
        moment(formattedEndDate) < moment(currFormatedStartDate)
      ) {
        if (showMessage) {
          saveProperty("errorMessage", "You can't select a date in past.");
        }
        return false;
      }

      if (!isValidTime(stateData?.startTime, stateData?.endTime)) {
        return false;
      }

      return true;
    }
  };

  if (currentStep !== 3) {
    return null;
  }
  // widgets:--------------------------------------------------
  const errorWidgets = () => {
    return (
      <div className="w-full flex flex-col justify-start">
        {stateData?.errorMessage && (
          <div className="text-sm bg-red-500 w-full text-white text-center mb-1">
            {stateData?.errorMessage}
          </div>
        )}
      </div>
    );
  };
  //
  // main body:------------------------------------------------
  return (
    <div className="h-full flex flex-col justify-start">
      <span className="p-4 text-sm flexyar justify-center align-center">
        You can{" "}
        <span className="font-semibold underline">
          select, deselect and change
        </span>{" "}
        times always later in the course calendar.<sup>*</sup>
      </span>
      {errorWidgets()}

      <QuickModal
        isOpen={openInfoModal}
        onRequestClose={() => {
          setOpenInfoModal(false);
        }}
        title={`Manual Slots Adding`}
        message={
          "if you want to add manual time slot goto calendar after course creation and click and drag to add your availbility slots"
        }
        firstButtonTitle={"OK"}
        secondButtonStyle={{
          color: commonColor.deepPink,
          border: `1px solid ${commonColor.deepPink}`,
        }}
        onFirstButtonClick={() => {
          setOpenInfoModal(false);
        }}
      />

      <div className="overflow-y-scroll h-full flex flex-col justify-center items-center md:justify-center">
        <div className="w-full h-full">
          <div className=" flex gap-4 sm:gap-2 flex-col ">
            <div className="w-full lg:w-3/4 flex flex-row justify-start px-2">
              <Checkbox
                label={"Manually add availbility slots"}
                isSelected={localState?.addManualSlots}
                onCheckboxChange={(changeEvent) => {
                  handleChange("addManualSlots", !localState?.addManualSlots);
                  setOpenInfoModal(!localState?.addManualSlots);
                  if (localState?.addingAutoSlots)
                    handleChange("addingAutoSlots", localState?.addManualSlots);
                }}
                key={"allowDesktopNotifications"}
              />
            </div>
            <div className="w-full lg:w-3/4 flex flex-row justify-start px-2 mb-4 sm:mb-0">
              <Checkbox
                label={"Bulk availbility slots adding"}
                isSelected={localState?.addingAutoSlots}
                onCheckboxChange={(changeEvent) => {
                  handleChange("addingAutoSlots", !localState?.addingAutoSlots);
                  if (localState?.addManualSlots)
                    handleChange("addManualSlots", localState?.addingAutoSlots);
                }}
                key={"allowContactViaEmail"}
              />
            </div>
          </div>

          {stateData?.addingAutoSlots && (
            <>
              <div className="w-full flex flex-col md:flex-row justify-start py-2">
                <div className="w-full md:w-1/2 flex flex-col justify-start align-center px-2 mb-8">
                  <div className="w-full flex font-semibold justify-center md:justify-start text-gray-500 text-sm ">
                    Start day of course
                  </div>
                  <div className="w-full flex justify-center md:justify-start">
                    <Calendar
                      calendarType={"US"}
                      minDate={stateData?.minStartDate ?? new Date()}
                      onChange={(value) => {
                        const formatedStartDate =
                          moment(value).format("YYYY[-]MM[-]DD");
                        console.log("Course start date:", formatedStartDate);
                        handleChange("startDate", value);
                        handleChange("formattedStartDate", formatedStartDate);
                        // isDataValid(false)
                      }}
                      value={localState?.startDate}
                      tileClassName={({ date }) => {
                        const selected = moment(date);
                        const saved = moment(localState?.startDate);

                        return selected.isSame(saved, "day")
                          ? "rounded bg-primary-400 white"
                          : "";
                      }}
                      className="rounded-lg shadow-lg bg-white text-xs sm:text-sm p-2"
                      nextLabel={
                        <span className="text-lg">{">"}&nbsp;&nbsp;</span>
                      }
                      prevLabel={
                        <span className="text-lg">&nbsp;&nbsp;{"<"}</span>
                      }
                      next2Label={<span className="text-lg">{">>"} </span>}
                      prev2Label={<span className="text-lg">{"<<"} </span>}
                    />
                  </div>
                </div>

                <div className="w-full md:w-1/2 flex flex-col justify-start px-2">
                  <div className="w-full flex font-semibold justify-center md:justify-start text-gray-500 text-sm ">
                    Last day of course
                  </div>
                  <div className="w-full flex justify-center md:justify-start">
                    <Calendar
                      calendarType={"US"}
                      minDate={stateData?.minStartDate ?? new Date()}
                      onChange={(value) => {
                        const formatedEndDate =
                          moment(value).format("YYYY[-]MM[-]DD");
                        console.log("Course end date:", formatedEndDate);
                        handleChange("endDate", value);
                        handleChange("formattedEndDate", formatedEndDate);
                      }}
                      value={localState?.endDate}
                      tileClassName={({ date }) => {
                        const selected = moment(date);
                        const saved = moment(localState?.endDate);

                        return selected.isSame(saved, "day")
                          ? "rounded bg-primary-400 white"
                          : "";
                      }}
                      className="rounded-lg shadow-lg bg-white text-xs sm:text-sm p-2"
                      nextLabel={
                        <span className="text-lg">{">"}&nbsp;&nbsp;</span>
                      }
                      prevLabel={
                        <span className="text-lg">&nbsp;&nbsp;{"<"}</span>
                      }
                      next2Label={<span className="text-lg">{">>"} </span>}
                      prev2Label={<span className="text-lg">{"<<"} </span>}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col md:flex-row justify-start py-2">
                <div className="w-full md:w-1/2 flex flex-col justify-start align-center px-2 mb-8">
                  <div className="w-full flex font-semibold justify-center md:justify-start text-gray-500 text-sm ">
                    Start Time
                  </div>
                  <div className="w-full flex justify-center md:justify-start">
                    {/* <input
                      className="bg-white appearance-none border-2 focus:outline-none border-lightGray focus:border-gray-300"
                      type="time"
                      step="3600"
                      value={toTimeString(localState?.startTime) ?? "00:00"}
                      onChange={(e) => {
                        console.log("Start ::", e.target.value);
                        let hours = e.target.value.split(":");
                        hours = parseInt(hours[0]);
                        isValidTime(hours, localState?.endTime);
                        handleChange("startTime", hours);
                      }}
                    /> */}
                    <TimeWidget
                      timeValue={localState?.startTime}
                      onChange={(value) => {
                        isValidTime(value, localState?.endTime);
                        handleChange("startTime", value);
                      }}
                    />
                  </div>
                </div>

                <div className="w-full md:w-1/2 flex flex-col justify-start px-2">
                  <div className="w-full flex font-semibold justify-center md:justify-start text-gray-500 text-sm ">
                    End Time
                  </div>
                  <div className="w-full flex justify-center md:justify-start">
                    {/* <input
                      className="bg-white appearance-none border-2 focus:outline-none border-lightGray focus:border-gray-300"
                      type="time"
                      step="3600"
                      value={toTimeString(localState?.endTime)}
                      onChange={(e) => {
                        console.log("End ::", e.target.value);
                        let hours = e.target.value.split(":");
                        hours = parseInt(hours[0]);
                        isValidTime(localState?.startTime, hours);
                        handleChange("endTime", hours);
                      }}
                    /> */}

                    <TimeWidget
                      timeValue={localState?.endTime}
                      onChange={(value) => {
                        isValidTime(localState?.endTime, value);
                        handleChange("endTime", value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseStep3;

const RowWidget = ({ title, children }) => {
  return (
    <div className="w-full flex flex-col md:flex-row justify-center py-2">
      <div className="text-gray-500 font-semibold text-sm md:w-32 w-full flex flex-row justify-start md:justify-end items-center px-2">
        {title}
      </div>
      <div className="lg:w-2/3 flex-grow flex flex-row justify-start px-2 py-1">
        {children}
      </div>
    </div>
  );
};
