import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  saveUserRequest: ['user'],
  saveUserSuccess: ['user'],
  saveUserFailure: ['error']
});

export const UserTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  user: null,
  fetching: false,
  error: null
});

/* ------------- Reducers ------------- */

// we're attempting to save user 
export const request = state => state.merge({ fetching: true, error: null });

// we've successfully saved user 
export const success = (state, { user }) =>
  state.merge({ fetching: false, error: null, user });

// we've had a problem saving user 
export const failure = (state, { error }) =>
  state.merge({ fetching: false, error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SAVE_USER_REQUEST]: request,
  [Types.SAVE_USER_SUCCESS]: success,
  [Types.SAVE_USER_FAILURE]: failure
});

/* ------------- Selectors ------------- */
