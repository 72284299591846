import React, { useEffect, useState } from "react";
import commonColor from "../../../theme/commonColor";
import SwotButton from "../../common/buttons/swotbutton";
import Modal from "../../common/modal/modal";
import moment from "moment";
import { createCSV } from "../../../utils/csvGenerator";

const Transactions = ({ user, transactions }) => {
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  // const statusColor = {
  //   succeeded: commonColor.flatGreen,
  //   pending: commonColor.sourCherry,
  //   refund_requested: commonColor.error,
  //   refund_rejected: commonColor.deepPink,
  //   refunded: commonColor.violet,
  // };

  const cell = (trans, index) => {
    return (
      <div className="w-full flex flex-col justify-start text-sm">
        <div
          className="w-full flex justify-between my-1 rounded-3xl cursor-pointer"
          style={
            {
              // backgroundColor: statusColor[trans.status] ?? commonColor.lilac,
              // backgroundColor: commonColor.lilac,
            }
          }
          onClick={() => {
            setSelectedTransaction(trans);
          }}
        >
          <div className="w-2/12 py-1 px-3 text-xs">
            {moment.unix(trans.created_timestamp).format("MM-DD-YYYY")}
          </div>
          <div className="w-4/12 py-1 px-3 break-all text-xs">
            {trans.course_shortname} / {trans.student_first_name ?? ""}{" "}
            {trans.student_last_name ?? ""}
          </div>
          <div className="w-3/12 py-1 px-3 text-xs">{trans.amount} (USD)</div>
          <div className="w-3/12 py-1 px-3 text-xs">{trans.commission} (USD)</div>
        </div>
        <div
          className="w-full px-8 py-2">
          <div
            className="w-full bg-gray-300"
            style={{ height: "1px" }}
          ></div>
        </div>
      </div>
    );

    // <div
    //   className="w-full flex justify-between my-1 rounded-3xl"
    //   style={{
    //     backgroundColor: "white",
    //   }}
    // >
    //   <div className="w-3/12  py-1 px-3  text-sm">Date / time</div>
    //   <div className="w-4/12  py-1 px-3  text-sm">Description</div>
    //   <div className="w-3/12  py-1 px-3  text-sm">Amount (USD)</div>
    // </div>
  };

  return (
    <div className="w-full h-full flex flex-col justify-between items-center p-4">
      <div className="w-full flex flex-col justify-start items-center pt-4">
        <div className="w-full flex justify-between my-4 text-sm">
          <div
            className="rounded-3xl py-1 text-white my-auto text-center w-20 sm:w-3/12 sm:py-1 sm:px-2 text-xs"
            style={{
              backgroundColor: commonColor.deepBlue,
            }}
          >
            Date
          </div>
          <div
            className="w-4/12 rounded-3xl py-1 px-3 text-white my-auto text-center text-xs"
            style={{
              backgroundColor: commonColor.deepBlue,
            }}
          >
            Course / Student
          </div>


          <div
            className="w-3/12 rounded-3xl py-1 px-3 text-white my-auto text-center text-xs"
            style={{
              backgroundColor: commonColor.deepBlue,
            }}
          >
            Course Charge
          </div>

          <div
            className="w-3/12 rounded-3xl py-1 px-3 text-white my-auto text-center text-xs"
            style={{
              backgroundColor: commonColor.deepBlue,
            }}
          >
            SK Commission
          </div>

        </div>
      </div>
      <div className="w-full flex flex-col justify-start flex-grow items-center pb-4 overflow-y-scroll">
        {transactions.map((trans, index) => cell(trans, index))}
        {!transactions.length ? <div className="p-10 mt-4">You have not had any paid lessons till now, once you do start paying for your lessons, here you will find all transactions to keep track of what you are paying. </div> : ""}
      </div>
      <div className={`w-full flex justify-around p-2 ${(transactions && transactions.length > 0) ? "block" : "hidden"}`}>
        <SwotButton
          text={"Download"}
          onClick={() => {
            console.log("Download transactions!");
            createCSV(transactions, "tutor_transactions");
          }}
        />
        {/* <SwotButton
          text={"Full details"}
          onClick={() => console.log("Transactions full details!")}
          bgColor={commonColor.deepBlue}
        /> */}
      </div>
      <div className="flex text-xs">
        <div>
          <p>
            <b>
              To view a detailed breakdown of your transactions, visit your stripe account.
              &nbsp;
            </b>
          </p>
        </div>
      </div>

      <Modal
        isOpen={selectedTransaction !== null && selectedTransaction !== undefined}
        onRequestClose={() => {
          setSelectedTransaction(null);
        }}
      >
        {selectedTransaction && (
          <div className="flex flex-col">
            <div className="flex">
              <div>
                <p>
                  <b>Transaction Id:</b> {selectedTransaction.transaction_id}
                </p>
              </div>
            </div>
            <div className="flex">
              <div>
                <p>
                  <b>Date/Time:</b>{" "}
                  {moment
                    .unix(selectedTransaction.created_timestamp)
                    .toString()}
                </p>
              </div>
            </div>
            <div className="flex">
              <div>
                <p>
                  <b>Status:</b> {selectedTransaction.status}
                </p>
              </div>
            </div>
            <br />
            {selectedTransaction.refund_reason && (
              <div className="flex">
                <div>
                  <p>
                    <b>Refund Reason:</b> {selectedTransaction.refund_reason}
                  </p>
                </div>
              </div>
            )}

            <div className="flex">
              <div>
                <p>
                  <b>Student Email:</b> {selectedTransaction.student_email}
                </p>
              </div>
            </div>
            <div className="flex">
              <div>
                <p>
                  <b>Course Id:</b> {selectedTransaction.course_id}
                </p>
              </div>
            </div>
            <div className="flex">
              <div>
                <p>
                  <b>Course Name:</b> {selectedTransaction.course_fullname}
                </p>
              </div>
            </div>

            <div className="flex">
              <div>
                <p>
                  <b>Course Shortname:</b> {selectedTransaction.course_shortname}
                </p>
              </div>
            </div>

            {selectedTransaction.refund_response &&
              selectedTransaction.status === "refund_rejected" && (
                <div className="flex">
                  <div>
                    <p>
                      <b>Refund Rejection Reason:</b>{" "}
                      {selectedTransaction.refund_response}
                    </p>
                  </div>
                </div>
              )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Transactions;
