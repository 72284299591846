import { useEffect, useState } from "react";
import { connect } from "react-redux";
import UserActions from "../../redux/user";
import CourseList from "./coursesTutor/courseList";
import CourseDetails from "./courseDetails";
import MainPageWithMenu from "../../components/common/MainPageTemplate/mainPageWithMenu";
import StudentCourseLandingPage from "../../components/student/course/courseLandingPage";
import { getSavedBookingPayload } from "../../services/paymentsManagement";
import Swotbutton from "../../components/common/buttons/swotbutton";
import { NavigateFunction, useNavigate, useParams, useLocation, useSearchParams } from "react-router-dom";
import { useUserAuth } from "../../services/userManagement";
import CourseDetailsUnauthorized from "../../components/student/course/CourseDetailsUnauthorized";
import LandingLayout from "../../components/landing/landingLayout";
import CustomFadeLoader from "../../components/common/CustomFadeLoader";
import commonColor from "../../theme/commonColor";
import { useWindowSize } from "../../utils/uiUtils";
import { ACCOUNT_TYPES } from "../../config/constants";

export const CoursesDashboard = ({ user, llocation }) => {
  const [popoverWidget, setPopoverWidget] = useState();

  const authUser = useUserAuth(null);
  const [width, height] = useWindowSize();
  const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  const params = useParams();
  let [searchParams, _] = useSearchParams();

  const renderCurrentView = () => {
    if (user.accountType === "Tutor" || user.accountType === "GuestTutor") {
      const cId = params.courseId ?? location.state?.courseId;
      if (cId) {
        return location.state?.courseSummary ? (
          <StudentCourseLandingPage courseId={cId} user={user} />
        ) : (
          <CourseDetails
            courseId={cId}
            user={user}
            showPopover={(widget) => {
              showPopover(widget);
            }}
          />
        );
      } else {
        return <CourseList />;
      }
    } else if (user.accountType === ACCOUNT_TYPES.STUDENT || user.accountType === ACCOUNT_TYPES.SWOT_BUDDY) {
      //If querry-params has guid and paymentStatus, it means its comming from stripe-payment.
      //So we get booking details against this booking from localStorage, and make the booking
      const guid = searchParams.get("guid");
      const paymentStatus = searchParams.get("payment");
      let savedCourseId = null;
      if (guid && paymentStatus && user?.uid) {
        const s = getSavedBookingPayload(user.uid, guid);
        savedCourseId = s?.courseId;
      }
      console.log(
        "navigating to StudentCourseLandingPage - courseId",
        params.courseId ?? location.state?.courseId
      );

      const cId = params.courseId ?? location.state?.courseId ?? savedCourseId;
      return (
        <StudentCourseLandingPage
          courseId={cId}
          user={user}
          paymentStatus={paymentStatus}
          guid={guid}
          showPopover={(widget) => {
            showPopover(widget);
          }}
        />
      );
    }
  };

  const closePopover = () => {
    setPopoverWidget(null);
  };

  const showPopover = (child) => {
    const w = (
      <div className="w-full flex-col justify-start">
        <div className="w-full p-2 flex justify-end">
          <Swotbutton
            text="close"
            onClick={() => {
              closePopover();
            }}
          />
        </div>
        {child}
      </div>
    );
    setPopoverWidget(w);
  };

  return (
    <div>
      {user && authUser ? (
        <MainPageWithMenu
          user={user}
          menuSelected={"library"}
          popoverContent={popoverWidget}
          closePopover={() => {
            closePopover();
          }}
        >
          <div
            className=" h-full w-full"
            style={{
              borderRadius: "1.5rem",
              // width: "100%",
            }}
          >
            {renderCurrentView()}
          </div>
        </MainPageWithMenu>
      ) : user ? (
        <div className="h-screen w-screen">
          <div className="w-full h-full flex justify-center bg-white text-black items-center">
            <CustomFadeLoader loading={true} color={commonColor.deepGreen} />
          </div>
        </div>
      ) : (
        <LandingLayout landingTitle="Course Details" hideMenu={true}>
          <CourseDetailsUnauthorized
            courseId={params.courseId ?? location.state?.courseId}
          />
        </LandingLayout>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CoursesDashboard);
