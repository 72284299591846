import React, { useEffect, useState } from "react";
import FileViewer from "react-file-viewer";
import Modal from "../common/modal/modal";
import {
  courseMaterialUpload,
  firebaseGetFileList,
} from "../../services/storage";
import Uploader from "../common/uploader";
import { ReactComponent as Eye } from "../../assets/icons/eye.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download-icon.svg";
import { ReactComponent as Del } from "../../assets/icons/trash.svg";
import CircularProgressBar from "../common/CircularProgressBar/CircularProgressBar";
import { QuickModal } from "../common/modal/modal";
import LoaderModal from "../common/modal/loaderModal";
import { getDownloadURL, deleteObject } from "firebase/storage";
const MaterialUploaderViewer = ({
  courseId,
  uploadPath,
  materialType,
  addButtonText,
  noItemsText,
  updateCallback,
  itemsExisting,
  readOnly = false,
  lockContent = false,
}) => {
  const [items, setItems] = useState([]);
  const [viewItem, setViewItem] = useState(null);
  const [reset, setReset] = useState(true);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [deletingItem, setDeletingItem] = useState();
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    setReset(true);
  }, [courseId, materialType]);

  useEffect(() => {
    setItems(itemsExisting ?? []);
  }, [itemsExisting]);

  useEffect(() => {
    if (!reset) return;
    setReset(false);
    firebaseGetFileList(uploadPath).then((res) => {
      console.log(uploadPath, res);
      setItems(res.items || []);
      updateCallback && updateCallback(res.items);
    });
  }, [uploadPath, reset]);

  const deleteHandler = async (item) => {
    try {
      setDeleting(true);
      await deleteObject(item);
      setDeleting(false);
      setReset(true);
    } catch (e) {
      console.error("Error: " + e);
      setDeleting(false);
    }
  };

  const viewItemHandler = (item) => {
    const filetype = item.name.split(".").pop().toLowerCase();
    if (fileTypeRendererDict[filetype]) {
      setViewItem(item);
    } else {
      try {
        getDownloadURL(item).then((url) => window.open(url, "_blank"));
      } catch (e) {
        console.error("Error: " + e);
      }
    }
  };

  const downloadItemHandler = (item) => {
    const filename = item.name;
    const filetype = filename.split(".").pop().toLowerCase();
    getDownloadURL(item).then((url) => {
      // This can be downloaded directly:
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = (event) => {
        const blob = xhr.response;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      };
      xhr.open("GET", url);
      xhr.send();
    });
  };

  const uploadHandler = async (file) => {
    await courseMaterialUpload(
      courseId,
      file,
      uploadPath,
      materialType,
      (progress) => {
        setUploadProgress(Math.floor(progress));
      }
    );
    setUploadProgress(0);
    setReset(true);
  };

  const contentBlock = () => {
    return (
      <div className="w-full h-full overflow-scroll">
        {!readOnly && !lockContent && uploadProgress > 0 ? (
          <div className="flex w-full h-full items-center justify-center">
            <CircularProgressBar
              strokeWidth="5"
              sqSize="80"
              percentage={uploadProgress}
            />
          </div>
        ) : items.length === 0 ? (
          <div className="w-full h-full flex justify-center items-center">
            {noItemsText}
          </div>
        ) : (
          items.map((item, index) => {
            return (
              <div key={index} className="flex pb-1">
                <div
                  className="text-ellipsis overflow-hidden max-w-xs"
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item.name}
                </div>
                {!lockContent && (
                  <>
                    <div
                      className="cursor-pointer text-primary-400 px-1"
                      onClick={viewItemHandler.bind(null, item)}
                    >
                      <Eye className="h-6 w-6 fill-current" />
                    </div>
                    <div
                      className="cursor-pointer text-primary-400 px-1"
                      onClick={downloadItemHandler.bind(null, item)}
                    >
                      <DownloadIcon className="h-5 w-6 fill-current" />
                    </div>
                  </>
                )}
                {!readOnly && !lockContent && (
                  <div
                    className="cursor-pointer text-primary-200 px-1"
                    onClick={() => {
                      setDeletingItem(item);
                      // deleteHandler.bind(null, item)
                    }}
                  >
                    <Del className="h-6 w-6 fill-current" />
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>
    );
  };
  return (
    <>
      {readOnly ? (
        <div className="w-full h-full flex flex-col justify-start items-start p-4">
          {contentBlock()}
        </div>
      ) : (
        <Uploader
          showButton
          buttonText={addButtonText}
          dropzoneClassName="absolute h-4/5"
          dropzoneStyle={{ width: "95%" }}
          uploadCallback={uploadHandler}
        >
          {contentBlock()}
        </Uploader>
      )}
      <Modal
        isOpen={!!viewItem}
        bgColor="bg-trasparent"
        onRequestClose={() => {
          setViewItem(null);
        }}
        isCloseButtonShown={true}
      >
        {viewItem && (
          <div className="w-90v md:w-70v lg:w-50v h-80v text-center">
            <Viewer
              file={viewItem}
              close={() => {
                setViewItem(null);
              }}
            />
          </div>
        )}
      </Modal>
      <QuickModal
        isOpen={deletingItem !== null && deletingItem !== undefined}
        title={"Delete Item"}
        message={"You are about to delete the item."}
        onRequestClose={() => {
          setDeletingItem(null);
        }}
        firstButtonTitle={"Delete"}
        secondButtonTitle={"Cancel"}
        onFirstButtonClick={() => {
          const item = deletingItem;
          setDeletingItem(null);
          deleteHandler(item);
        }}
        onSecondButtonClick={() => {
          setDeletingItem(null);
        }}
      ></QuickModal>
      <LoaderModal isOpen={deleting} message={"Please wait..."} />
    </>
  );
};

const Viewer = ({ file, close }) => {
  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (!file) return;
    try {
      getDownloadURL(file).then((e) => setUrl(e));
    } catch (e) {
      console.error("Error: " + e);
      //close && close();
    }
  }, [file]);

  const filetype = file?.name?.split(".").pop().toLowerCase();

  return url ? (
    <FileViewer fileType={filetype} filePath={url} onError={console.log} />
  ) : null;
};

const fileTypeRendererDict = {
  bmp: true,
  jpg: true,
  jpeg: true,
  gif: true,
  png: true,
  pdf: true,
  csv: true,
  xslx: true,
  docx: true,
  mp4: true,
  webm: true,
  mp3: true,
};

export default MaterialUploaderViewer;
