import React, { useState, useEffect } from "react";
import { generateUserDocument, auth } from "../../../config/firebase";
import { fetchSignInMethodsForEmail } from "firebase/auth";
import UserActions from "../../../redux/user";
import { connect } from "react-redux";
import { signUpUser } from "../../../services/userManagement";
import commonColor from "../../../theme/commonColor";
import MaskedField from "react-masked-field";
import moment from "moment";
import { ReactComponent as Eye } from "../../../assets/icons/eye.svg";
import { ReactComponent as Hidden } from "../../../assets/icons/invisible.svg";

const UserInfo = ({
  user,
  saveUser,
  currentStep,
  saveProperty,
  handleChange,
  isStudent,
  setCanMoveOn,
  accountType,
}) => {
  const [birthday, setBirthday] = useState();
  const [birthdayMsg, setBirthdayMsg] = useState(
    "Birthdate should be a valid mm/dd/yyyy."
  );

  const [isBirthdayValid, setIsBirthdayValid] = useState(false);
  const [username, setUsername] = useState();
  const [fullname, setFullname] = useState();
  const [email, setEmail] = useState();

  const [password, setPassword] = useState();
  const [confirmedPassword, setConfirmedPassword] = useState();
  const [passwordOk, setPasswordOk] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [fullnameValidationError, setFullnameValidationError] = useState(null);
  const [usernameValidationError, setUsernameValidationError] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [emailOk, setEmailOk] = useState(false);
  const [birthdayComplete, setBirthdayComplete] = useState(false);
  const [passwordValidationError, setPasswordValidationError] = useState(null);

  const [showPopup, setShowPopup] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  useEffect(() => {
    console.log("useEffect - user, accountType", user, accountType);
    if (currentStep === 3) {
      setCanMoveOn("userInfo", moveOnCheck());
    }
  }, [
    user,
    birthday,
    isBirthdayValid,
    username,
    fullname,
    email,
    emailExists,
    passwordOk,
    accountType,
    currentStep,
  ]);

  const moveOnCheck = () => {
    console.log(
      "moveOnCheck ",
      isBirthdayValid,
      username,
      fullname,
      passwordOk,
      user
    );

    let moveOn = false;
    if (!user) {
      moveOn =
        isBirthdayValid &&
        isUsernameValid() &&
        isFullnameValid() &&
        isEmailValid() &&
        !emailExists &&
        passwordOk;
    } else {
      moveOn = isBirthdayValid && (username && username.length) > 0;
    }

    console.log("moveOnCheck ", moveOn);
    return moveOn;
  };

  const renderBorderColor = (validation) => {
    return validation === false
      ? "border-error/50 focus:border-error"
      : validation === true
      ? "border-correct/50 focus:border-correct"
      : "border-lightGray/50 focus:border-lightGray";
  };

  //---Fullname validation----------//
  const isFullnameValid = () => {
    if (!fullname || fullname.length === 0) {
      return null;
    }
    var pattern = new RegExp(/^[a-zA-Z\s]*$/);
    if (fullname.length < 3 || !pattern.test(fullname)) {
      return false;
    }
    return true;
  };

  const validateFullname = () => {
    setFullnameValidationError(null);
    if (isFullnameValid() === false) {
      setFullnameValidationError("Please enter a valid name.");
    }
  };

  const renderFullnameBorderColor = () => {
    var validation = isFullnameValid();
    return !validation
      ? "#e2e8f0"
      : validation === false
      ? commonColor.error
      : commonColor.correct;
  };

  const handleFullnameChange = (event) => {
    setFullnameValidationError(null);
    const tempFullname = event.target.value;
    //Max character limits for firstname and lastname
    if (tempFullname && tempFullname.length > 100) {
      tempFullname = tempFullname.substring(0, 100);
    }
    setFullname(tempFullname);
    saveProperty("name", tempFullname);
  };

  //---Username validation----------//
  const isUsernameValid = () => {
    if (!username || username.length === 0) {
      return null;
    }
    var pattern = new RegExp(/^(?=.*[a-zA-Z]).{3,24}$/);
    if (username.length < 3 || !pattern.test(username)) {
      return false;
    }
    return true;
  };
  const validateUsername = () => {
    setUsernameValidationError(null);
    if (isUsernameValid() === false) {
      setUsernameValidationError("Please enter a valid username.");
    }
  };

  const renderUsernameBorderColor = () => {
    var validation = isUsernameValid();
    return !validation
      ? "#e2e8f0"
      : validation === false
      ? commonColor.error
      : commonColor.correct;
  };

  const handleUserNameChange = (event) => {
    setUsernameValidationError(null);
    const tempUsername = event.target.value;
    setUsername(tempUsername);
    if (tempUsername && tempUsername.length > 1) {
      saveProperty("username", tempUsername);
    }
  };

  //---------Email validations------------//
  const isEmailValid = () => {
    if (!email || email.length === null || email.length === undefined) {
      return null;
    }

    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    return pattern.test(email);
  };

  const validateEmail = () => {
    var validation = isEmailValid();
    setEmailOk(false);
    console.log("validateEmail -  email", email);
    if (!validation) {
      setEmailError(false);
      setEmailExists(false);
      return;
    }
    if (validation === false) {
      setEmailError(true);
    } else {
      setEmailError(false);
      fetchSignInMethodsForEmail(auth, email).then((providers) => {
        if (providers.length === 0) {
          setEmailExists(false);
          setEmailOk(true);
          // this email hasn't signed up yet
          saveProperty("email", email);
        } else {
          // has signed up
          setEmailExists(true);
        }
      });
    }
  };

  const handleEmailChange = (event) => {
    console.log("handleEmailChange - ", event.target.value);

    const emailSoFar = event.target.value;
    if (!emailSoFar || emailSoFar.length === 0) {
      setEmailError(false);
      setEmailExists(false);
    }
    setEmail(emailSoFar);
  };

  const renderEmailBorderColor = () => {
    const validation = isEmailValid();
    if (validation === false) {
      return commonColor.error;
    } else if (validation === true) {
      return commonColor.correct;
    } else {
      return "#e2e8f0";
    }
  };

  const confirmPassword = () => {
    if (password) {
      if (password.length < 8) {
        setPasswordValidationError(
          "Password needs to be at least 8 characters long."
        );
        setPasswordOk(false);
        return;
      }
      if (
        //!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_/\-])[A-Za-z\d@$!%*#?&_/\-]{8,}$/
        !/^(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=((.*[\W])|(.*_)){1,}).{8,}$/.test(
          password
        )
      ) {
        setPasswordValidationError(
          "Password must have at least one letter, one number and one special character"
        );
        setPasswordOk(false);
        return;
      }
    }

    setPasswordValidationError(null);

    if (!password || !confirmedPassword) {
      setPasswordOk(false);
      setPasswordValidationError(null);
      return;
    }

    if (confirmedPassword === password) {
      console.log("confirmPassword -  passwords are the same!");
      setPasswordError(false);
      setPasswordOk(true);
      saveProperty("password", password);
    } else {
      console.log("confirmPassword -  passwords are NOT the same!");
      setPasswordError(true);
    }
  };

  const validateBirthday = (text) => {
    setBirthdayComplete(false);
    //const text = event.target.value
    setIsBirthdayValid(false);
    console.log("handleInputChange  ", text);
    setBirthday(text);
    const proposedBirthday = moment(text, "DD/MM/YYYY");
    if (moment(text, "DD/MM/YYYY", true).isValid()) {
      // check date should be at most 18 years before now and at least 95 years before.

      let minAge, maxAge;

      // if (isStudent) {
      if (accountType === "Tutor") {
        minAge = moment().subtract(18, "years");
      } else {
        minAge = moment().subtract(2, "years");
      }
      //   maxAge = moment().subtract(20, 'years')
      // } else {
      //   minAge = moment().subtract(20, 'years')
      maxAge = moment().subtract(70, "years");
      // }
      // accountType
      const isDateAcceptable =
        proposedBirthday.isSameOrBefore(minAge) &&
        proposedBirthday.isSameOrAfter(maxAge);

      if (proposedBirthday.isSameOrBefore(minAge)) {
        setBirthdayMsg("Age must be under 70");
      } else if (proposedBirthday.isSameOrAfter(maxAge)) {
        setBirthdayMsg("Age must be greater than 3");
        if (accountType === "Tutor") setBirthdayMsg("Tutor age must be 18+");
      }

      console.log(
        "handleInputChange  ",
        isDateAcceptable,
        proposedBirthday,
        minAge,
        maxAge
      );
      setIsBirthdayValid(isDateAcceptable);
      setBirthdayComplete(true);
      saveProperty("birthday", proposedBirthday.format());
    } else {
      setIsBirthdayValid(false);
      setBirthdayMsg("Birthdate should be a valid mm/dd/yyyy.");
    }
  };

  const handlePassChange = (event) => {
    const key = event.target.name;
    const pass = event.target.value;
    console.log("handlePassChange", key, pass);
    key === "password" ? setPassword(pass) : setConfirmedPassword(pass);
  };

  const renderPassBorderColor = () => {
    if (
      (confirmedPassword && passwordError) ||
      (password && passwordValidationError)
    ) {
      return "border-error/50 focus:border-error";
    } else if (passwordOk) {
      return "border-correct/50 focus:border-correct";
    } else {
      return "border-lightGray/50 focus:border-lightGray";
    }
  };

  const renderBirthdayBorderColor = () => {
    return birthday && !isBirthdayValid
      ? "border-error/50 focus:border-error"
      : birthday && isBirthdayValid
      ? "border-correct/50 focus:border-correct"
      : "border-lightGray/50 focus:border-lightGray";
  };

  if (currentStep !== 3) {
    return null;
  }

  return (
    <div
      className=" h-full flex-col flex justify-around items-center overflow-y-scroll min-h-48 pt-4"
      style={{
        height: "106%",
      }}
    >
      <div className="w-full flex justify-center">
        <p className="text-center italic pt-8">
          Tell us a little bit about yourself
        </p>
      </div>
      {fullnameValidationError && (
        <div className="text-sm bg-red-500 w-full text-white text-center mb-1">
          {fullnameValidationError}
        </div>
      )}
      {usernameValidationError && (
        <div className="text-sm bg-red-500 w-full text-white text-center mb-1">
          {usernameValidationError}
        </div>
      )}
      {confirmedPassword && passwordError && (
        <div className="text-sm bg-red-500 w-full text-white text-center mb-1">
          {"Passwords are not the same."}
        </div>
      )}
      {emailError && (
        <div className="text-sm bg-red-500 w-full text-white text-center mb-1">
          {"Please enter valid email address."}
        </div>
      )}
      {emailExists && (
        <div className="text-sm bg-red-500 w-full text-white text-center mb-1">
          {"This email is already used."}
        </div>
      )}
      {birthday && !isBirthdayValid && birthdayComplete && (
        <div className="text-sm bg-red-500 w-full text-white text-center mb-1">
          {birthdayMsg}
        </div>
      )}
      {passwordValidationError && (
        <div className="text-sm bg-red-500 w-full text-white text-center mb-1">
          {passwordValidationError}
        </div>
      )}

      <div className="w-full flex flex-col justify-center flex-wrap">
        <div className="w-full flex flex-row justify-center">
          <div className="mr-1 text-sm lg:w-1/6 w-1/5 flex flex-row justify-end items-center text-right">
            {accountType === "Parent" ? "Student name" : "Name"}
          </div>
          <div className="lg:w-2/3 w-3/5 flex flex-row justify-start px-2 py-1">
            <input
              className={`lg:w-4/5 w-full bg-white appearance-none border-2 focus:outline-none py-1 px-2 ${renderBorderColor(
                isFullnameValid()
              )}`}
              id="name"
              name="name"
              type="text"
              placeholder={
                user && user.name ? user.name : "Enter your full name"
              }
              value={fullname}
              onBlur={validateFullname}
              onChange={handleFullnameChange}
            />
          </div>
        </div>
        {!user && (
          <div className="w-full flex flex-row justify-center">
            <div className="mr-1 text-sm lg:w-1/6 w-1/5 flex flex-row justify-end items-center text-right">
              {accountType === "Parent" ? "Parent email" : "Email"}
            </div>
            <div className="lg:w-2/3 w-3/5 flex flex-row justify-start px-2 py-1">
              <input
                className={`lg:w-4/5 w-full bg-white appearance-none border-2 focus:outline-none py-1 px-2 ${renderBorderColor(
                  isEmailValid() && !emailExists
                )}`}
                id="email"
                name="email"
                type={"email"}
                placeholder={
                  user && user.email ? user.email : "Enter your email"
                }
                disabled={user && user.email}
                value={email}
                onBlur={validateEmail}
                onChange={handleEmailChange}
              />
            </div>
          </div>
        )}

        {!user && (
          <div className="w-full flex flex-row justify-center">
            <div className="mr-1 text-sm lg:w-1/6 w-1/5 flex flex-row justify-end items-center text-right">
              Password
            </div>
            <div className="lg:w-2/3 w-3/5 flex flex-row justify-start px-2 py-1">
              <input
                className={`lg:w-4/5 w-4/5 bg-white appearance-none border-2 focus:outline-none py-1 px-2 ${renderPassBorderColor()}`}
                id="password"
                name="password"
                type={passwordShown ? "text" : "password"}
                placeholder={"Enter password"}
                onBlur={confirmPassword}
                value={password}
                onChange={(event) => handlePassChange(event)}
              />
              <button
                className="w-1/5 px-2 focus:outline-none"
                onClick={togglePasswordVisibility}
                style={{
                  color: `${
                    passwordShown
                      ? commonColor.sourCherry
                      : commonColor.deepGreen
                  }`,
                }}
              >
                {/* {passwordShown ? "Hide" : "Show"} */}
                {passwordShown ? (
                  <Eye
                    className="rounded-full h-6 w-6 
                hover:bg-gray-200"
                    style={{
                      fill: "black",

                      backgroundColor: "white",
                    }}
                  />
                ) : (
                  <Hidden
                    className="rounded-full h-6 w-6   
                hover:bg-gray-200"
                    style={{
                      fill: "black",

                      backgroundColor: "white",
                    }}
                  />
                )}
              </button>
            </div>
          </div>
        )}
        {!user && (
          <div className="w-full flex flex-row justify-center">
            <div className="mr-1 text-sm lg:w-1/6 w-1/5 flex flex-row justify-end items-center text-right">
              Confirm Password
            </div>
            <div className="lg:w-2/3 w-3/5 flex flex-row justify-start px-2 py-1">
              <input
                className={`lg:w-4/5 w-full bg-white appearance-none border-2 focus:outline-none py-1 px-2 ${renderPassBorderColor()}`}
                id="confirmPassword"
                name="confirmPassword"
                type={passwordShown ? "text" : "password"}
                placeholder={"Re-enter password"}
                onBlur={confirmPassword}
                value={confirmedPassword}
                onChange={(event) => handlePassChange(event)}
              />
            </div>
          </div>
        )}

        {accountType && (
          <div className="w-full flex flex-row justify-center">
            <div className="mr-1 text-sm lg:w-1/6 w-1/5 flex flex-row justify-end items-center text-right">
              {accountType === "Parent" ? "Student username" : "Username"}
            </div>
            <div className="lg:w-2/3 w-3/5 flex flex-row justify-start px-2 py-1">
              <input
                className={`lg:w-4/5 w-full bg-white appearance-none border-2 focus:outline-none py-1 px-2 ${renderBorderColor(
                  isUsernameValid()
                )}`}
                id="username"
                name="name"
                type="text"
                placeholder={"How to call you"}
                value={username}
                onBlur={validateUsername}
                onChange={handleUserNameChange}
              />
            </div>
          </div>
        )}

        {accountType && (
          <div className="w-full flex flex-row justify-center">
            <div className="mr-1 text-sm lg:w-1/6 w-1/5 flex flex-row justify-end items-center text-right">
              {accountType === "Parent"
                ? "Student birth date"
                : "Date of Birth"}
            </div>
            <div className="relative lg:w-2/3 w-3/5 flex flex-row justify-start px-2 py-1">
              <MaskedField
                className={`border-2 py-1 px-2 lg:w-4/5 w-full text-neutral-600 relative ${renderBirthdayBorderColor()}`}
                placeholder={"dd/mm/yyyy"}
                mask="99/99/9999"
                onFocus={() => setShowPopup(true)}
                onBlur={() => setShowPopup(false)}
                onComplete={(date) => {
                  validateBirthday(date);
                  setBirthdayComplete(true);
                }}
                onChange={(event) => validateBirthday(event.target.value)}
                value={birthday}
              />
              {showPopup && (
                <div className=" text-sm font-semibold text-neutral-400 absolute left-5 top-11 ">
                  dd/mm/yyyy
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
