import React, { useEffect, useState } from "react";
import commonColor from "../../../theme/commonColor";

const QuestionStep = ({
  index,
  question,
  currentStep,
  saveAnswer,
  setCanMoveOn,
  isTeacher = false,
  preSelected
}) => {
  const [selected, setSelected] = useState([]);
  //console.log("QuestionStep - input", index, currentStep, question)

  useEffect(() => {
    if (index === currentStep) {
      setCanMoveOn(question.questionId, selected.length > 0);
    }
  }, [currentStep, selected]);

  useEffect(() => {}, [selected]);

  useEffect(() => {
    if(preSelected && selected.length === 0) {
      setSelected(preSelected);
    }
  }, []);

  if (index !== currentStep) {
    return null;
  }

  const moveOnCheck = () => {
    console.log("moveOnCheck ", question.questionId, selected);

    let moveOn = false;

    moveOn = selected.length > 0;

    console.log("moveOnCheck ", moveOn);
    return moveOn;
  };

  const selectORDisselectAnswer = (answerId, isLast) => {
    console.log(
      "selectORDisselectAnswer:",
      answerId,
      selected,
      isTeacher,
      isLast
    );
    let updSelected = [...selected];
    let newSelected;

    if (updSelected.includes(answerId)) {
      console.log("selectORDisselectAnswer - removing", answerId);
      newSelected = updSelected.filter((ansr) => ansr !== answerId);
      setSelected(newSelected);
      updSelected = [...newSelected];
    } else {
      if (isTeacher && !isLast) {
        // make sure to remove "non of the above"
        const nonAbove = question.answers[question.answers.length - 1];
        const nonAboveIndex = updSelected.indexOf(nonAbove.answerId);
        console.log(
          "selectORDisselectAnswer - nonAbove + index",
          nonAbove,
          nonAboveIndex
        );
        if (nonAboveIndex !== -1) {
          console.log(
            "selectORDisselectAnswer - splicing nonAboveindex",
            nonAboveIndex
          );
          updSelected.splice(nonAboveIndex, 1);
        }
      }
      if (isTeacher && isLast) {
        updSelected = [];
        updSelected.push(answerId);
        setSelected(updSelected);
      } else {
        if (question.questionType === "non-exclusive") {
          console.log("selectORDisselectAnswer - adding", answerId);
          updSelected.push(answerId);
          setSelected(updSelected);
        } else {
          console.log("selectORDisselectAnswer - selecting", [answerId]);
          updSelected = [];
          updSelected.push(answerId);
          setSelected(updSelected);
        }
      }
    }

    saveAnswer(question.questionId, updSelected);
  };

  return (
    <div
      className="w-full h-full flex flex-col justify-start"
      // style={{
      //   maxHeight: "80%"
      // }}
    >
      <div className="w-full flex justify-center items-center py-2">
        <div className="italic py-2">{question.questionText}</div>
      </div>

      <div
        className="w-full flex flex-wrap items-center justify-center"
        // style={{
        //   height: "20rem",
        // }}
      >
        {question.answers.map((ansr, index) => (
          <div key={ansr.answerId} className="w-1/2  flex items-center justify-center">
            <button
              // key={ansr.answerId}
              className="min-h-16 flex-1 hover:shadow-outline focus:outline-none  mx-4 my-2 p-4 
                rounded-xl text-sm"
              style={{
                color: `${
                  selected.includes(ansr.answerId) ? "white" : "black"
                }`,
                backgroundColor: `${
                  selected.includes(ansr.answerId)
                    ? commonColor.sourCherry
                    : "white"
                }`,
                boxShadow: `0 3px 10px -3px rgba(0, 0, 0, 0.6)`,             
              }}
              onClick={() =>
                selectORDisselectAnswer(
                  ansr.answerId,
                  index === question.answers.length - 1
                )
              }
            >
              {ansr.answerText}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuestionStep;
