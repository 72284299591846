import { useState, useEffect } from "react";

import GenericProfilePhoto from "../../../assets/images/tutor_base.jpg";
import { ReactComponent as RequestCall } from "../../../assets/images/courses/phone-1.svg";
import swal from "sweetalert2";
import { tutorCallRequest } from "../../../services/api";
import { firestore } from "../../../config/firebase";
import { collection, doc, setDoc, onSnapshot } from "firebase/firestore";
import Swotbutton from "../../common/buttons/swotbutton";

import { getCountryNameByCityName } from "../../../services/location";
import { countriesList } from "../../../services/countrieslist";
// Dektop View

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}
const TutorAbout = ({
  user,
  about,
  courseDetails,
  sendMessage,
  registerAlert,
}) => {
  const [isOnline, setIsOnline] = useState(false);
  const [countryName, setCountryName] = useState("");

  const getCountryName = async () => {
    if (about.countryName) {
      let tmpCountryName;

      if (about.countryName !== "NonUSA") {
        tmpCountryName = about.countryName;
      } else {
        let findCountryName = await getCountryNameByCityName(about.cityName);
        console.log("getting Country Name:", findCountryName);
        tmpCountryName = findCountryName;
      }

      let seletedCountryOpt = countriesList.find(
        (o) => o.value === tmpCountryName
      );
      console.log("seletedCountryOpt :", seletedCountryOpt.label);
      setCountryName(seletedCountryOpt.label);
    }
  };

  useEffect(() => {
    if (courseDetails?.tutorFirebaseId) {
      let tutorStatusFirestoreRef = doc(
        firestore,
        "/status/" + courseDetails?.tutorFirebaseId
      );
      onSnapshot(tutorStatusFirestoreRef, function (doc) {
        // tutorStatusFirestoreRef.onSnapshot(function (doc) {
        if (doc?.data()) {
          var isTutorOnline = doc?.data()?.state === "online";
          setIsOnline(isTutorOnline);
        }
      });
    }

    getCountryName();
  }, [isOnline]);

  const makeCallRequest = () => {
    swal
      .fire({
        title: "Call Request",
        text: "Are you sure you want to send a call request to this tutor?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const baseUrl = window.location.href;
          const payload = {
            fallback: baseUrl,
            tutor_username: courseDetails?.tutorUsername,
            student_username: user?.username,
            tutor_firebase_id: courseDetails?.tutorFirebaseId,
          };
          let res = await tutorCallRequest(payload);

          console.log("payload:", payload, " res:", res);

          if (res?.data?.status === "SUCCESS") {
            await setDoc(
              doc(
                collection(firestore, "call-request"),
                courseDetails?.tutorFirebaseId
              ),
              {
                tutor_meet_link: res?.data?.data.tutor_meet_link,
                student_meet_link: res?.data?.data.student_meet_link,
                student_username: user?.username,
                tutor_username: courseDetails?.tutorUsername,
                state: "pending",
              },
              { merge: true }
            ).then(() => {
              window.location.href = res?.data?.data.student_meet_link;
            });
          } else {
            swal.fire({
              title: "Error",
              text: "Unable to make call request",
              icon: "error",
              showCancelButton: true,
              cancelButtonColor: "#d33",
            });
          }
        }
      });
  };

  console.log("ABOUT:", about, courseDetails, user);
  return (
    <div
      className="w-full h-full flex bg-white  rounded-2xl overflow-y-scroll"
      style={{ height: "95%" }}
    >
      <div className="tutorInfoPhoto w-1/3 sm:w-2/5 pt-2 pl-2 h-10/12 ">
        <img
          className="w-auto rounded-2xl"
          style={{ height: "90%", objectFit: "cover" }}
          src={
            about && about.tutorPhotoUrl
              ? about.tutorPhotoUrl
              : GenericProfilePhoto
          }
          alt="tutorPhoto"
        />
      </div>

      <div className="p-3 w-full">
        {isOnline && (
          <span
            style={{
              float: "left",
              marginRight: "5px",
              marginTop: "5px",
              cursor: "pointer",
            }}
          >
            <RequestCall
              style={{ float: "left" }}
              onClick={makeCallRequest}
              className="w-4"
            />
            <svg
              style={{ float: "left", marginRight: "5px" }}
              height="18"
              width="18"
              className="blinking"
            >
              <circle cx="9" cy="9" r="6" fill="#2ecc71" />
            </svg>
          </span>
        )}

        {!isOnline && (
          <span
            style={{
              cursor: "pointer",
            }}
          >
            <svg
              style={{ float: "left", marginTop: "5px" }}
              height="18"
              width="18"
              className="blinking"
            >
              <circle cx="9" cy="9" r="6" fill="#e74c3c" />
            </svg>
          </span>
        )}

        <p className="mb-2">
          <span className="font-bold text-lg pb-1">About the tutor </span>
        </p>

        <div>
          <p>
            <br />
            <span>
              <span className="font-bold">Fullname:</span>{" "}
              {toTitleCase(about.firstName)} {toTitleCase(about.lastName)}
            </span>

            <br />
            {about.email && (
              <span>
                <span className="font-bold">Contact: </span> {about.email}
              </span>
            )}
            <br />
            {/* <span>
              <span className="font-bold">Students enrolled: </span>
              {about.studentsEnrolled}
            </span>
            <br /> */}
            <span>
              <span className="font-bold ">Courses: </span> {about.courseCount}
            </span>
          </p>

          <p className="text-sm mt-4">
            <span className="font-bold">From: </span> {about.cityName}
            {about.cityName && about.stateCode ? ", " : ""}
            {about.stateCode}
            {", " + countryName}
            <span className="font-bold">
              {about.teachingStyle ? "Teaching Style" : ""}
            </span>
            <span className="">
              {about.teachingStyle ? about.teachingStyle : ""}
            </span>
            <br />
            <span className="italic text-xs pt-4">
              Special Education is{" "}
              {about.specialEducation ? "supported" : "not supported"} by{" "}
              {about.firstName}
            </span>
          </p>
        </div>
        <div className="flex">
          <div className="p-1">
            <Swotbutton
              text="Send Message"
              onClick={() => {
                if (registerAlert) {
                  registerAlert();
                } else {
                  sendMessage && sendMessage();
                }
              }}
            />
          </div>

          {isOnline && (
            <div className="p-1">
              <Swotbutton
                text="Call"
                onClick={() => {
                  if (registerAlert) {
                    registerAlert();
                  } else {
                    makeCallRequest();
                  }
                  //sendMessage && sendMessage();
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TutorAbout;
