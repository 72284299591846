import React from "react";
import LandingLayout from "../../components/landing/landingLayout";

export default function Subscription() {
  return (
    <LandingLayout>
      <div className="text-lg h-full flex items-center justify-center">
        {/* <CheckoutForm /> */}
        Subscription plans comming soon!
      </div>
    </LandingLayout>
  );
}
